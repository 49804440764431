import { ShiftType } from '@enums/enum';

export interface ShiftHoursInterface {
    id: string;
    hours: string[];
}

export class ShiftConstants {
    public static morningShiftStart: string = '06:00:00';
    public static afternoonShiftStart: string = '15:00:00';
    public static eveningShiftStart: string = '17:00:00';
    public static morningShiftEnd: string = '14:00:00';
    public static afternoonShiftEnd: string = '23:00:00';
    public static eveningShiftEnd: string = '23:00:00';

    public static slotCols: ShiftHoursInterface[] = [
        {
            id: ShiftType.MORNING,
            hours: [
                '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00',
            ]
        },
        {
            id: ShiftType.MORNING_1,
            hours: [
                '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00',
            ]
        },
        {
            id: ShiftType.MORNING_2,
            hours: [
                '06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00',
            ]
        },
        {
            id: ShiftType.AFTERNOON,
            hours: [
                '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00',
            ],
        },
        {
            id: ShiftType.EVENING,
            hours: [
                '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00'
            ]
        },
    ];
}
