import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RolesService } from './roles.service';

@Injectable()
export class PermissionsResolver implements Resolve<any> {
    constructor(private rolesService: RolesService) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.rolesService.getPermissions();
    }
}
