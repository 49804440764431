import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

import { RoutesService } from '@routes/services/routes.service';

@Injectable()
export class RouteResolver implements Resolve<any> {
    constructor(private routesService: RoutesService) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.routesService.getRoute(params.id);
    }
}
