import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditUserComponent } from './components/edit-user.component';
import { UsersComponent } from './components/users.component';
import { RolesResolver } from './services/roles.resolver';
import { RolesComponent } from './components/roles/roles.component';
import { EditRoleComponent } from './components/roles/edit-role.component';
import { FindRoleResolver } from './services/find-role.resolve';
import { RolesMainComponent } from './components/roles/role-main.component';
import { PermissionsResolver } from './services/permissions.resolver';
import { ProxyComponent } from '@shared/components/proxy.component';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';

const usersRoutes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        canActivate: [PermissionGuardService],
        data: {
            expectedPermission: [P.Permissions.User_view]
        },
        children: [
            { path: '', component: UsersComponent },
            {
                path: 'new/:action', component: EditUserComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.AddUser'
                },
                resolve: { roles: RolesResolver },
            },
            {
                path: ':userId/:action', component: EditUserComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.EditUser'
                },
                resolve: { roles: RolesResolver },
            },
            {
                path: 'roles', component: RolesMainComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Roles'
                },
                children: [
                    {
                        path: '', component: RolesComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.Roles'
                        },
                        resolve: { roles: RolesResolver },
                    },
                    {
                        path: 'new/create', component: EditRoleComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.CreateRole'
                        },
                        resolve: {
                            permissions: PermissionsResolver
                        }
                    },
                    {
                        path: ':roleId/edit', component: EditRoleComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.EditRole'
                        },
                        resolve: {
                            role: FindRoleResolver,
                            permissions: PermissionsResolver
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(usersRoutes)],
    exports: [RouterModule],
    providers: []
})

export class UsersRouting { }
