import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DriversService } from '@drivers/services/drivers.service';

@Injectable()
export class DriversAllResolver implements Resolve<any> {
    constructor(private driversService: DriversService) {}
    public resolve() {
        return this.driversService.getAllDrivers();
    }
}
