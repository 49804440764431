import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Token } from '@entities/token';
import { User } from '@entities/user';
import { Roles } from '@enums/enum';
import { NavService } from '@shared/services/nav.sevice';

import { Login } from './authentication/entities/authentication';
import { environment } from '@environment';
import { Permissions } from '@enums/permissions';
import { difference } from 'lodash';
import { LoginProvider } from '@interfaces/login-provider.interface';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private static AUTH: string = 'auth/v2/authenticate';
    private static RENEW: string = 'auth/v1/renew';
    private static EXTERNALS_PROVIDERS: string = 'auth/v1/providers';
    private static LOGIN_VIA_EXTERNAL_PROVIDER: string = 'auth/oauth/v2.0/login';


    private readonly notifier: NotifierService;
    private readonly tag = '[AuthenticationService]';
    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public authorizedUser: User;

    public currentUser: Observable<User> = this.currentUser$.asObservable();
    

    constructor(
        private http: HttpClient, 
        private navService: NavService, 
        private notifierService: NotifierService
    ) {

        this.notifier = notifierService;

        try {
            this.handleNewSessionToken(localStorage.getItem('user'));
        } catch (e) {
            console.error(this.tag, e);
        }
    }

    public login(credentials: Login): Observable<boolean> {
        const endpoint = `${this.host}${this.prefix}/${AuthenticationService.AUTH}`;

        return this.http.post(endpoint, credentials).pipe(
            map((response: any) => {
                const sessionToken = response['token'];

                if (sessionToken) {
                    this.handleNewSessionToken(sessionToken);
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    public loginViaExternalProvider(token: string): Observable<boolean> {
        const endpoint = `${this.host}${this.prefix}/${AuthenticationService.LOGIN_VIA_EXTERNAL_PROVIDER}`;
        return this.http.post(endpoint, token).pipe(
            map((response: any) => {
                const sessionToken = response['token'];

                console.log(response);

                if (sessionToken) {
                    this.handleNewSessionToken(sessionToken);
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    private handleNewSessionToken(sessionToken) {
        try {
            const user = User.fromSessionToken(sessionToken);
            localStorage.setItem('user', user.serialize());

            this.authorizedUser = user;
            this.currentUser$.next(user);
        } catch (e) {
            console.warn(this.tag, 'Cannot deserialize sessionToken', sessionToken, window.location.href);
            localStorage.removeItem('user');
            if (!window.location.href.includes('/login')) {
                this.redirectToLoginPage();
            }
        }
    }

    public renew(): Observable<any> {
        const endpoint = `${this.host}${this.prefix}/${AuthenticationService.RENEW}`;

        return this.http
            .post(
                endpoint,
                {},
                {
                    headers: new HttpHeaders({
                        Authorization: `Bearer ${this.authorizedUser.sessionToken}`
                    })
                }
            )
            .pipe(
                map((response: Object) => {
                    const sessionToken: string = response['token'];

                    if (sessionToken) {
                        this.handleNewSessionToken(sessionToken);
                        return true;
                    } else {
                        return false;
                    }
                })
            );
    }

    public externalProviders(): Observable<LoginProvider[]> {
        const endpoint = `${this.host}${this.prefix}/${AuthenticationService.EXTERNALS_PROVIDERS}`;
        return this.http.get<LoginProvider[]>(endpoint);
    }

    public redirectAfterLogin(startpage?: string | undefined) {
        
        if (startpage) {
            this.navService.goToPage(`/${startpage}`);
            return;
        }

        if (this.checkPrivileges([Permissions.Route_view])) {
            this.navService.goToPage('/routes');
        } else if (this.checkPrivileges([Permissions.User_view])) {
            this.navService.goToPage('/users');
        } else if (this.checkPrivileges([Permissions.Partner_view])) {
            this.navService.goToPage('/partner/list');
        } else if (this.checkPrivileges([Permissions.Apm_view])) {
            this.navService.goToPage('/apm/list');
        } else if (this.checkPrivileges([Permissions.Calendar_view])) {
            this.navService.goToPage('/calendar');
        } else if (this.checkPrivileges([Permissions.Analytics_stats2_view])) {
            this.navService.goToPage('/analytics/dashboard');
        } else if (this.checkPrivileges([Permissions.Delivery_view])) {
            this.navService.goToPage('/deliveries');
        } else {
            this.navService.goToPage('/routes');
        }
    }

    public redirectTo403Page() {
        this.navService.goToPage('/403');
    }

    public redirectToLoginPage() {
        this.navService.goToPage('/login');
    }

    public getToken(): Token {
        return this.authorizedUser.token;
    }

    public checkPrivileges = (roles: Permissions[]) => {
        return (difference(roles, this.authorizedUser.authorities).length === 0);
    };

    public unauthorized = () => this.signOut(true);

    public signOut(reload: boolean = false): void {
        localStorage.removeItem('user');

        this.authorizedUser = null;
        this.currentUser$.next(null);

        if (reload) {
            this.navService.goToPage('/login');
        }
    }
}
