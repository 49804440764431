import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { UtilsService } from '@services/utils.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ShiftSettings } from '@entities/shift-settings';
import { CutoffSolutions } from '../interfaces/cutoff-solutions.interface';
import { map } from 'rxjs/internal/operators/map';
import { VisualiserService } from './visualiser.service';

@Injectable({
  providedIn: 'root'
})
export class CutoffService {

  private static GET_SOLUTIONS = `${environment.api.url}${environment.api.prefix}` + '/shiftsolutions/v1/solution/${shiftId}';

  private static GET_SOLUTIONS_2 = `${environment.api.url}${environment.api.prefix}` + '/shifts/v1/solution/${shiftId}/${version}';

  private static GET_SHIFT = `${environment.api.url}${environment.api.prefix}` + '/shifts/v1/${shiftId}';
  private static PROMOTE_SOLUTION = `${environment.api.url}${environment.api.prefix}` + '/shiftsolutions/v1/solution/${shiftId}/${version}/promote/${solutionId}/${finalize}';
  private static GET_SOLUTION = `${environment.api.url}${environment.api.prefix}` + '/shifts/v1/solution/${shiftId}/${version}/${solutionId}';

  constructor(
    private readonly _http: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly visualiserService: VisualiserService
  ) { }

  public getSolutionsList(shift, cutoff: string): Observable<CutoffSolutions[]> {
    const endpoint = this.utilsService.interpolate(`${CutoffService.GET_SOLUTIONS}?cutoff=${cutoff}`, {shiftId: shift.id, cutoff});
    return this._http.get<CutoffSolutions[]>(endpoint);
  }

  public getSolutionDetails(shift: ShiftSettings, shiftId: string, version: number, solutionId: string): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${CutoffService.GET_SOLUTION}`, {shiftId, version, solutionId});
    return this._http.get(endpoint).pipe(
      map((res) => {
        console.log({results: [res], shift: shift});
        return this.visualiserService.generateOptimizeResult({results: [res], shift: shift})
      })
    );
  }

  public getShift(shiftId: string): Observable<ShiftSettings> {
    const endpoint = this.utilsService.interpolate(`${CutoffService.GET_SHIFT}`, {shiftId});
    return this._http.get<ShiftSettings>(endpoint);
  }

  public promoteSolution(shiftId: string, version: number, solutionId: string, finalize: boolean): Observable<any> {
    const endpoint = this.utilsService.interpolate(`${CutoffService.PROMOTE_SOLUTION}`, {shiftId, version, solutionId, finalize});
    return this._http.post<ShiftSettings>(endpoint, null);
  }
  
}
