<ng-container *ngIf="(integration$ | async) as integration">
    <form *ngIf="!loader" [formGroup]="form">
        <div class="shift-settings">
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Slots' | translate}}:</label>
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of slotControls" class="item mg-r-10">
                    <ng-container *ngTemplateOutlet="control; context: { form: form, item: item }"></ng-container>
                </div> 
            </div>
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Booking time informations' | translate}}:</label>
            <p class="tx-11 tx-color-03">{{'When the booking window is unrestricted (No), it is possible to book into the shift as soon as the shift is created_When the booking window is restricted, the booking window opens a specific number of days in advance of the shift, at the configured local time' | translate}}</p>
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of bookingControls" class="item mg-r-10">
                    <ng-container *ngTemplateOutlet="control; context: { form: form, item: item }"></ng-container>
                </div> 
            </div>
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Automatic planning' | translate}}:</label>
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of cutoffBooleanControls" class="item mg-r-10">
                    <ng-container *ngTemplateOutlet="control; context: { form: form, item: item }"></ng-container>
                </div> 
            </div>
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Cutoff control' | translate}}:</label>
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of cutoffControls" class="item mg-r-10">
                    <ng-container *ngTemplateOutlet="control; context: { form: form, item: item }"></ng-container>
                </div> 
            </div> 
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Route configuration' | translate}}:</label>
            <p class="tx-11 tx-color-03">{{'Route numbers must always be unique accross all shifts per depot per day_For this reason you can configure what the starting number for the first route in the shift is_The last route in the shift is calculated by multiplying the maximum nuber of routes in the shift with the maximum number of reloads and adding that to the starting number' | translate}}</p>
            <p class="tx-11 tx-color-03">{{'For example, if there are no reloads a starting number of 300 and a maximum number of 100 means the routes 301 to 396 (4 routes are reserved) and the next shift can start at 400' | translate}}</p>
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of routeControls" class="item mg-r-10">
                    <ng-container *ngTemplateOutlet="control; context: { form: form, item: item }"></ng-container>
                </div> 
            </div>
        </div>

        <div class="actions d-flex justify-content-between">
            <button [routerLink]="['/calendar/shift-planning']" class="btn btn-white tx-11s">{{'Back to shifts list' | translate}}</button>
            <div class="d-flex justify-content-between">
                <button [routerLink]="['../step-2']" class="btn btn-sm btn-warning pd-x-15 btn-uppercase mg-l-5">{{'Previous step' | translate}}</button>
                <button type="submit" (click)="update()" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Next' | translate}}</button>
            </div>
        </div>
    </form>
</ng-container>

<ng-template let-form="form" let-item="item" #control>
    <div [formGroup]="form">
   
        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
            <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
            <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                    [style.width.px]="13" name="info"></i-feather>
            </ng-container>
        </label>

        <div [ngSwitch]="item">
            <div *ngSwitchCase="'shiftType'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option *ngFor="let value of integrationShiftType" [ngValue]="value">{{ value | translate }}</option>
                </select>
                <p *ngIf="mode === 'create'" class="tx-13 text-success">{{'Notice: The shift type cannot be changed in the future' | translate}}</p>
            </div>
            <div *ngSwitchCase="'bookingWindowRestricted'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'autoPromoteSolution'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'autoFinalizeSolution'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'allowDeliveryImport'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'fulfillmentIntegrationEnabled'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'isServiceShift'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'offset'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option value="-1">{{ 'Day before' | translate }}</option>
                    <option value="-2">{{ 'Two days before' | translate }}</option>
                    <option value="-3">{{ 'Three days before' | translate }}</option>
                    <option value="0">{{ 'Same day' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'bookingWindowDayOffset'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option *ngFor="let i of daysLabel" [value]="-i">
                        <ng-container [ngSwitch]="i">
                            <span *ngSwitchCase="-0"> {{ 'OffsetBefore.'+i | translate }} </span>
                            <span *ngSwitchCase="-1"> {{ 'OffsetBefore.'+i | translate }} </span>
                            <span *ngSwitchDefault>{{i}} {{ 'OffsetBefore.label' | translate }}</span>
                        </ng-container>
                    </option>
                    <option *ngFor="let i of monthsLabel" [value]="-i | monthOffsetPipe">
                        <ng-container [ngSwitch]="i">
                            <span *ngSwitchCase="0"> {{ 'OffsetMonthBefore.'+i | translate }} </span>
                            <span *ngSwitchCase="-1"> {{ 'OffsetMonthBefore.'+i | translate }} </span>
                            <span *ngSwitchCase="-2"> {{ 'OffsetMonthBefore.'+i | translate }} </span>
                            <span *ngSwitchCase="-3"> {{ 'OffsetMonthBefore.'+i | translate }} </span>
                            <span *ngSwitchCase="-4"> {{ 'OffsetMonthBefore.'+i | translate }} </span>
                            <span *ngSwitchCase="-12"> {{ 'OffsetMonthBefore.'+i | translate }} </span>
                            <span *ngSwitchDefault>{{i}} {{ 'OffsetMonthBefore.label' | translate }}</span>
                        </ng-container>
                    </option>     
                </select>
            </div>
            

            <div *ngSwitchDefault>
                
                <input type="number" min="0" [formControlName]="item" class="form-control w-100" />
            </div>
        </div>

        <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
            {{ "The field value is too long" | translate }}!
        </div>

        <div *ngIf="form.get(item).hasError('min') && form.get(item).touched" class="error-color">
            {{ "The entered value exceeds the defined range" | translate }}!
        </div>

        <div *ngIf="form.get(item).hasError('max') && form.get(item).touched" class="error-color">
            {{ "The entered value exceeds the defined range" | translate }}!
        </div>

        <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
            {{ "The field is required" | translate }}!
        </div>
    </div>

</ng-template>