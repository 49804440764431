<layout-default>
  <div header>
    <div toolbar>
      <h4>
        <i-feather name="navigation"></i-feather>{{'Dashboard history' | translate}}
      </h4>
    </div>

    <div *ngIf="(depots$ | async)" toolbar>
      <date-range-picker class="flex-grow-1 pic-apm-maint-slot" [extendedOption]="false"
        (changedDateRange)="changedDateRange($event)" [defaultDateRange]="dateRange"></date-range-picker>
      <div (click)="advandeSearchWindow = !advandeSearchWindow" class="btn-toggle">
        <i-feather name="chevron-down"></i-feather> {{'Expand' | translate}}
      </div>
    </div>

    <ng-container *ngIf="advandeSearchWindow">
      <div class="picker" toolbar>
        <ng-container [formGroup]="form">

          <div class="label">
            <label>{{ 'Depots' | translate }}:</label>
          </div>

          <div class="input-control">
            <ng-multiselect-dropdown id="data-range-select-days-of-week" class="form-control"
              [placeholder]="'Select depots' | translate" [data]="dropdownList" [(ngModel)]="selectedItems"
              [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings">
            </ng-multiselect-dropdown>
          </div>

          <div class="label">
            <label>{{ 'Aggregation' | translate }}:</label>
          </div>

          <div class="input-control">
            <select class="custom-select" [(ngModel)]="selectedAggregationItems" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let item of dropdownAggregationList" [value]="item">{{'HistoryDashboardPartitions.' + item | translate}}</option>
            </select>
          </div>

          <button type="button" class="btn btn-brand-01" (click)="loadData()">
            {{'Load data' | translate }}
          </button>

        </ng-container>

      </div>
    </ng-container>

    <hr />
  </div>

  <div content>
    <div class="row">

      <ng-container *ngIf="(data$ | async) as punctuality else noDataTmpl">

        <div class="depot box" *ngFor="let data of punctuality | keyvalue">

          <p class="depotName">
              <span *ngIf="data.value[0]?.date as date" class="badge badge-warning">{{date | date: 'd.MM.yyyy'}}</span>
              <span *ngIf="data.value[0]?.name && selectedAggregationItems.includes(DashboardHistoryPartitions.Shift)" class="badge badge-info">{{data.value[0]?.name}}</span>
          </p>

          <div class="shift-charts headers">
            <span></span>
            <span>{{'Delivery' | translate}}</span>
            <span>{{'Fulfillment' | translate}}</span>
            <span>{{'Loading' | translate}}</span>
          </div>

          <div class="shift" *ngFor="let item of data.value">

            <div class="shift-charts">
              <span class="shiftName">
                <span 
                 *ngIf="item?.shift as shift"
                  [containerClass]="'pretty-tooltip'" 
                  placement="right" 
                  [tooltip]="shift">{{shift.slice(0,12)}}</span>

                <span *ngIf="!selectedAggregationItems.includes(DashboardHistoryPartitions.Shift)">{{item.name}}</span>
              </span>

              <app-dashboard-chart [quantityName]="'deliveries'" [chart]="item.deliveryPunctuality" [showTotals]="true"
                [showPartials]="true">
              </app-dashboard-chart>

              <app-dashboard-chart [chart]="item.fulfillmentPunctuality" [quantityName]="'routes'" [showTotals]="true"
                [showPartials]="true">
              </app-dashboard-chart>

              <app-dashboard-chart [chart]="item.loadingPunctuality" [quantityName]="'routes'" [showTotals]="true"
                [showPartials]="true">
              </app-dashboard-chart>
            </div>
          </div>
        </div>
      </ng-container>


      <ng-template #noDataTmpl>

        <div *ngIf="loader" class="load-spinner spinner" [style.height.vh]="74">
          <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
          <p>{{ 'Please, wait!' | translate }}</p>
        </div>
  
        <div *ngIf="!loader" class="empty-data" [style.height.vh]="74">
          <i-feather name="alert-circle"></i-feather>
          <div class="tx-11 mg-l-5">{{ 'No data to display' | translate }}</div>
        </div>
      </ng-template>
      
     

    </div>

  </div>
</layout-default>


<!-- {{'startOffset' | translate}} -->
<!-- {{'completeOffset' | translate}} -->
<!-- {{'startsWithin' | translate}} -->
<!-- {{'endsWithin' | translate}} -->
<!-- {{'HistoryDashboardPartitions.ALL' | translate}} -->
<!-- {{'HistoryDashboardPartitions.DATE' | translate}} -->
<!-- {{'HistoryDashboardPartitions.DEPOT' | translate}} -->
<!-- {{'HistoryDashboardPartitions.DATE,DEPOT' | translate}} -->
<!-- {{'HistoryDashboardPartitions.SHIFT,DEPOT' | translate}} -->
<!-- {{'HistoryDashboardPartitions.DEPOT,SHIFT,DATE' | translate}} -->
