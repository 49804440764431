
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';

@Injectable()
export class VehicleTypeResolver implements Resolve<any> {
    constructor(private vehicleTypeService: VehicleTypesService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.vehicleTypeService.findOne(params['vehicleTypeId']);
    }
}
