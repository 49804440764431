import { Component } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { AppService } from '@services/app.service';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
    selector: 'layout-default',
    template: `
        <div [style.width.%]="(help) ? 75 : 100" class="wrapper">
            <div class="header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div id="content" class="content">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
        <div *ngIf="help" class="sidebar sidebar-help" @animateSidebar>
            <sidebar-with-header>
                <div header class="vans-drivers-header d-flex justify-content-between align-items-center">
                    <h6 class="tx-13 tx-spacng-1 tx-uppercase tx-semibold mg-b-0 d-inline btn-drivers active">{{'Help' | translate}}</h6>
                    <button (click)="close()" class="btn btn-link"><i-feather name="X"></i-feather></button>
                </div>
                <div content [innerHTML]="help"></div>
            </sidebar-with-header>
        </div>
    `,
    styleUrls: ['layout-default.component.scss'],
    animations: [
        trigger('animateSidebar', [
            transition(':enter', [
                style({ 'flex-basis': '0%' }),
                animate('100ms', style({ 'flex-basis': '25%' })),
            ]),
            transition(':leave', [
                animate('100ms', style({ 'flex-basis': 0 }))
            ])
        ]),
    ]
})

export class LayoutDefaultComponent {
    public help: SafeHtml;

    constructor(
        private readonly appService: AppService,
        private readonly domSanitizer: DomSanitizer
    ) {}
    
    public ngOnInit() {
        this.appService.help.subscribe(
            (text) => {
                this.help = this.domSanitizer.bypassSecurityTrustHtml(text);
            }
        )
    }

    public close() {
        this.help = undefined;
    }
}
