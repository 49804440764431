
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DepotService } from './depot.service';

@Injectable()
export class DepotAssignedVansResolver implements Resolve<any> {
  constructor(private depotService: DepotService) { }
  public resolve(route: ActivatedRouteSnapshot) {
    const params = route.params;
    return this.depotService.getAssignVansToDepot(params['depotId']);
  }
}
