import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CrudListComponent } from '@shared/components/cruds/crud-list.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { PageableResponse } from '@entities/pagable-response';
import { CommentService } from '../../services/comment.service';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { CommentStatus, CommentType } from '@enums/enum';
import { Comment } from '../../interfaces/comment.interface';
import { CommentUpdate } from '../../interfaces/comment-update.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
})
export class CommentListComponent extends CrudListComponent<Comment, string> implements OnInit {

    @ViewChild('editCommentTmpl', { static: true }) private editCommentTmpl: TemplateRef<any>;
    
    public columns = [
        { id: 'id', prop: 'id', name: this.translateService.instant('Id'), flexGrow: 1, sortable: true },
        { id: 'status', prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
        { id: 'comment', prop: 'comment', name: this.translateService.instant('Comment'), flexGrow: 5, sortable: true },
        { id: 'author', prop: 'author', name: this.translateService.instant('Author'), flexGrow: 4, sortable: true },
        { id: 'approver', prop: 'approver', name: this.translateService.instant('Approver'), flexGrow: 2, sortable: true },
        { id: 'modifiedAt', prop: 'modifiedAt', name: this.translateService.instant('Modified at'), flexGrow: 2, sortable: true },
        { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 3, sortable: false },
    ];

    public commentStatus = CommentStatus;
    public commentStatusOptions = Object.values(CommentStatus);
    public totalElements = 20;
    public editable = {};
    private modalArgs: {values: {comment: Comment | {}} } = { values: {comment: {}} };

    public form: FormGroup;
    public isExternalPaging = true;
    public fullWord = false;

    constructor(
        private readonly commentService: CommentService,
        protected readonly translateService: TranslateService,
        protected readonly navigationRoute: ActivatedRoute,
        protected readonly modalService: BsModalService,
        protected readonly notifierService: NotifierService,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder
    ) {
        super(commentService, translateService, navigationRoute, modalService, notifierService);
    }

    public ngOnInit() {
        Object.assign(this.queryParams, {showAllDepots: true, statusses : CommentStatus.NEW, sort: 'createdAt'})

        this.list$ = this.commentService.list$.pipe(
            tap((list: PageableResponse<Comment>) => (this.totalElements = list.totalElements)),
            tap((list: PageableResponse<Comment>) => (this.rows = list.content))
        );

        this.commentService.fetchAll(this.queryParams);
        this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

        this.rowClass = (row) => {
            return { 'row-modified': row.id.toString() === this.modifiedRow };
        };

        this.form = this.formBuilder.group({
            q: [null],
        });

        this.form
            .get('q')
            .valueChanges.pipe(distinctUntilChanged(), debounceTime(800))
            .subscribe((q) => this.search(q));

        this.loader = false;
    }

    public search(filterPhrase: string) {
        console.log(filterPhrase.length);
        if (!filterPhrase.length) {
            this.isExternalPaging = true;
            this.queryParams.page = 0;
            this.commentService.fetchAll(this.queryParams);
            return;
        }

        this.isExternalPaging = false;
        this.commentService.search(filterPhrase, CommentType.LOCATION_DRIVER_COMMENT, this.fullWord).subscribe((list: Comment[]) => {
            this.totalElements = 1000;
            this.rows = list;
        });
    }

    public modelChanged() {
        this.commentService.fetchAll(this.queryParams);
    }

    public markAsToEdit(comment: Comment) {
        if (this.isExternalPaging) {
            this.editable[comment.id] = { id: comment.id, comment: comment.comment, source: comment.source };
            this.modalArgs.values = { comment: comment };
            this.modalRef = this.modalService.show(this.editCommentTmpl, { class: 'modal-md' });
        } else {
            this.editable[comment.source] = { id: comment.source, comment: comment.comment, source: comment.source };
            this.modalArgs.values = { comment: comment };
            this.modalRef = this.modalService.show(this.editCommentTmpl, { class: 'modal-md' });
        }
        console.log(this.editable);
    }

    public reviewComment(comment: Comment, reject = false) {

        let updateDto: CommentUpdate | undefined;

        if (this.isExternalPaging) {
            updateDto = {
                id: this.editable[comment.id] ? this.editable[comment.id].id : comment.id,
                comment: (this.modalArgs.values.comment['comment']) ? this.modalArgs.values.comment['comment'] : !reject ? comment.comment : comment.current,
                version: comment.version,
                status: !reject ? (this.modalArgs.values.comment['comment']) ? CommentStatus.APPROVED_WITH_EDITS : CommentStatus.APPROVED : CommentStatus.REJECTED,
            };

            this.commentService.approve(updateDto).subscribe(() => {
                this.commentService.fetchAll(this.queryParams);
                this.notifierService.notify('success', this.translateService.instant('The comment has been updated successfully!'));
                this.modalRef.hide();
                this.modalArgs.values = {comment: {}}
            });
        } else {
            updateDto = {
                comment: !reject ? (this.modalArgs.values.comment['comment']) ? this.modalArgs.values.comment['comment'] : this.editable[comment.source] ? this.editable[comment.source].comment : comment.comment : '',
                source: comment.source || undefined,
                type: comment.type || undefined,
            };

            this.commentService.approveDirect(updateDto).subscribe(() => {
                if (this.editable[updateDto.source]) {
                    delete this.editable[updateDto.source];
                }

                this.rows = this.rows.filter((c: Comment) => c.source !== updateDto.source);
                this.notifierService.notify('success', this.translateService.instant('The comment has been updated successfully!'));
                this.modalRef.hide();
                this.modalArgs.values = {comment: {}}
            });
        }
    }
}
