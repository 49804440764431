import { serializeType } from '@shared/functions/serializeType.function';
import { Type } from 'class-transformer';

export class Punctuality {
    public EARLIER: number;
    public TOTAL:   number;
    public ON_TIME: number;
    public DELAYED: number;
}

export class ShiftPunctuality {
    @Type(serializeType(Punctuality))
    public punctuality: Punctuality;
    public routeId:     string;
}
