
        <layout-default>
            <div header>
                <div toolbar>
                    <h4><i-feather name="map-pin"></i-feather> {{ 'Locations' | translate }}</h4>
                </div>
                <hr>
            </div>

            <div content>
                <ngx-datatable class="bootstrap fullscreen"
                    #datatable
                    [rows]="rows"
                    [columns]="columns"
                    [columnMode]="'flex'"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [rowHeight]="'auto'"
                    [rowClass]="rowClass"
                    [externalPaging]="true"
                    [count]="pagable.totalElements"
                    [offset]="pagable.number"
                    [limit]="pagable.size"
                    (page)="setPage($event)"
                    (activate)="goToDetails($event)"
                >
                </ngx-datatable>

                <div *ngIf="isLoading" class="locations-loader" [style.top.px]="datatable.headerHeight" [style.height.px]="datatable.element.offsetHeight - datatable.headerHeight">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                    <p>{{ 'Please, wait!' | translate }}</p>
                </div>
            </div>
        </layout-default>

        <ng-template #addressHeader let-row="row" let-value="value">
            {{ 'Address' | translate }}
        </ng-template>
        <ng-template #addressItem let-row="row" let-value="value">
            <span class="address">{{ row.location.getAddressAsRaw() }}</span><br>
            <span class="address original">{{ row.location.getOriginalAddressAsRaw() }}</span>
        </ng-template>

        <ng-template #geocoding let-row="row" let-value="value">
            {{ row.location.geocodingStatus | translate }}
        </ng-template>
        <ng-template #status let-row="row" let-value="value">
            {{ row.location.status | translate }}
        </ng-template>

        <!--
        {{'PARTIAL' | translate}}
        {{'VERIFIED' | translate}}
        {{'UNVERIFIED' | translate}}
        -->
    