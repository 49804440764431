<layout-default>
    <div header>
        <div toolbar>
            <h4 appCheckClient [client]="friscoClient"><i-feather name="users"></i-feather> {{'Drivers and schedule' | translate}}</h4>
            <h4 appCheckClient [client]="inpostClient"><i-feather name="users"></i-feather> {{'Couriers' | translate}}</h4>
        </div>

        <div toolbar>
            <button id="drivers-btn-availability-frisco" appCheckClient [client]="friscoClient" routerLink="/drivers/availability" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Drivers availability' | translate}}</button>
            <button id="drivers-btn-availability-inpost"appCheckClient [client]="inpostClient" routerLink="/drivers/availability" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Couriers availability' | translate}}</button>

            <button id="drivers-btn-create-frisco" appCheckClient [client]="friscoClient" (click)="goToAddPage()" class="btn btn-sm btn-white btn-uppercase ">
                <i-feather name="plus"></i-feather> {{'Add new driver' | translate}}
            </button>

            <button id="drivers-btn-create-inpost" appCheckClient [client]="inpostClient" (click)="goToAddPage()" class="btn btn-sm btn-white btn-uppercase ">
                <i-feather name="plus"></i-feather> {{'Add new courier' | translate}}
            </button>

            <button id="drivers-btn-go-to-barcode" (click)="goToBarcodesPage()" class="btn btn-sm btn-white btn-uppercase">
                <i-feather name="menu"></i-feather> Kody kreskowe
            </button>

            <div id="drivers-btn-select-by-status" class="filter-select">
                <select class="custom-select" (change)="applyFiltering()" [(ngModel)]="selectedDriverStatus">
                    <option *ngFor="let status of driverStatus" [value]="status">{{status | translate}}</option>
                </select>
            </div>

            <div id="drivers-btn-searchbar" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>

        <hr>
    </div>

    <div content>
        <ngx-datatable class="bootstrap table"
            id="drivers-table"
            *ngIf="!loader"
            [rows]="rows"
            [columns]="columns"
            (activate)="goToDetails($event)"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="30"
            [reorderable]="true"
            [messages]="tableMessages"
            [rowClass]="rowClass">

                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [name]="col.name" [prop]="col.prop" [sortable]="col.sortable">
                        <div>{{ col.name | translate }}</div>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <span id="drivers-table-col-{{col.id}}" *ngIf="isNum(col.prop)">{{order(row)}}.</span>
                            <span id="drivers-table-col-{{col.id}}" *ngIf="isColumnToTranslate(col.prop)">{{value | translate}}</span>
                            <span id="drivers-table-col-{{col.id}}" *ngIf="!isColumnToTranslate(col.prop) && !isNum(col.prop)">{{value}}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
        </div>
    </div>
</layout-default>

<!--
{{'Data has been updated' | translate}}
{{'JUNIOR' | translate}}
{{'MEDIOR' | translate}}
{{'SENIOR' | translate}}
{{'PESEL' | translate}}
{{'OTHER' | translate}}
{{'ACTIVE' | translate}}
{{'INACTIVE' | translate}}
{{'FRISCO' | translate}}
{{'BASIC' | translate}}
{{'AVAILABLE' | translate}}
{{'INSERVICE' | translate}}
{{'RETIRED' | translate}}
{{'INVOICED' | translate}}
{{'UA' | translate}}
{{'PL' | translate}}
-->
