import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { Warehouse } from '@enums/enum';

@Injectable()
export class CanDeactivateSimulator implements CanDeactivate<any> {

    public canDeactivate(): boolean {
        localStorage.setItem('depot', Warehouse.WAW);
        return true;
    }
}
