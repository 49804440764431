import { Component, OnInit } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Fulfilment } from '@calendar/interafces/fulfilment.interface';
import { ShiftPlanningWizardService } from '@calendar/services/shift-planning-wizard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { PageableResponse } from '@entities/pagable-response';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { NUMERIC_PATTREN } from '@shared/constants/utils.constants';
import { ShiftStatus } from '@enums/enum';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shift-planning-edit-fulfilment',
  templateUrl: './shift-planning-edit-fulfilment.component.html',
  styleUrls: ['./shift-planning-edit-fulfilment.component.scss']
})
export class ShiftPlanningEditFulfilmentComponent implements OnInit {

  public fulfilment$: Observable<Fulfilment>;
  public vehicleTypes$: Observable<VehicleTypes[]>;
  public vehicleTypes: VehicleTypes[];

  private shiftDefaultSettings: ShiftUpdateDto; 
  private shift;
  public statusType = ShiftStatus;

  public form: FormGroup;
  public maxProductLinesCheckbox: boolean = true;
  public fleet: FormArray;
  public daysLabel = Array.from(Array(31).keys());
  public monthsLabel = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  public regularControlsNumber: string[] = [ 'reloadTimeMinutes', 'shiftStartMarginMinutes', 'shiftEndMarginMinutes'];
  public deliveryLimits: string[] = [ 'maximumDeliveries'];
  public deliveryTimeControls: string[] = [ 'boxGroupSize', 'boxGroupTime', 'initialTime', 'parkingTime', 'parkingTimeThreshold', 'paymentTime'];
  public shiftControls : string[] = ['startTime', 'endTime'];
  public departureControls = ['departureWaveInitialSize', 'departureWaveInterval', 'departureWaveSize'];
  public boleanControls = ['lockDeliveryOrder'];

  
  constructor(
    private readonly shiftPlanningWizardService: ShiftPlanningWizardService,
    private readonly formBuilder: FormBuilder,
    private readonly navigationRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly vehicleTypesService: VehicleTypesService
  ) { }

  public ngOnInit() {

    this.fulfilment$ = this.shiftPlanningWizardService.getModel().pipe(
      tap(a => console.log('m: ', a)),
      tap((shiftPlanning: ShiftPlanning) => this.shiftDefaultSettings = shiftPlanning.defaultSettings),
      tap((shiftPlanning: ShiftPlanning) => this.shift = shiftPlanning),
      map((shiftPlanning: ShiftPlanning) => shiftPlanning.defaultSettings.shiftFulfillmentData),
      tap((fulfilment: Fulfilment) => this.initForm(fulfilment))
    );

    this.vehicleTypesService.fetchAll();
    this.vehicleTypes$ = this.vehicleTypesService.list$.pipe(map((pageable: PageableResponse<VehicleTypes>) => {
      this.vehicleTypes = pageable.content;
      return this.vehicleTypes;
    }));

  }

  public initForm(fulfilment: Fulfilment) {
    this.form = this.formBuilder.group({
      departureWaveInitialSize: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
      departureWaveInterval: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
      departureWaveSize: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
      endTime: [null, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]], 
      fleet: this.formBuilder.array([]),
      label: ['', Validators.required], 
      maximumDeliveries: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
      maximumProductLines: [null, Validators.required], 
      reloadTimeMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      shiftEndMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
      shiftStartMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
      startTime: [null, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]], 
      status: [null, Validators.required],
      shiftDateOffset: [0, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      lockDeliveryOrder: [false, [Validators.required]],

      deliveryTime: this.formBuilder.group({
        boxGroupSize: [null, [Validators.required]],
        boxGroupTime: [null, [Validators.required]],
        initialTime: [null, [Validators.required]],
        parkingTime: [null, [Validators.required]],
        parkingTimeThreshold: [null, [Validators.required]],
        paymentTime: [0, [Validators.required]],
      }),
      version: [],

    })

    this.form.get('status').setValue(this.shiftDefaultSettings.shiftStatusData.status);

    this.maxProductLinesCheckbox = (fulfilment.maximumProductLines !== -1)

    this.fleet = <FormArray>this.form.controls['fleet'];
    fulfilment.fleet.forEach((key: ShiftFleet) => this.fleet.push(this.patchFleet(key)));
    
    this.form.patchValue(fulfilment);

    this.form.get('label').setValue(this.shift.shiftName);
  }

  private patchFleet(fleet?: ShiftFleet): AbstractControl {
    return this.formBuilder.group({
      vehicleTypeId: [(fleet) ? fleet.vehicleTypeId : null, Validators.required],
      max: [(fleet) ? fleet.max : null, Validators.required],
    });
  }

  public addFleet(): void {
    this.fleet.push(this.patchFleet());
  }

  public removeVanLimit(i): void {
    (<FormArray>this.form.controls['fleet']).removeAt(i);
  }

  public maxProductLinesCheckboxFun(): void {
    this.maxProductLinesCheckbox = !this.maxProductLinesCheckbox;
    if (!this.maxProductLinesCheckbox) {
      this.form.get('maximumProductLines').setValue(-1);
    }
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.shift.labels.${property}`);
  }

  public update() {
    
    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    if (this.form.invalid) {      
      return;
    }

    if (this.form.valid) {
      
      this.shiftPlanningWizardService.updateModel({
        defaultSettings: {
          shiftFulfillmentData: this.form.getRawValue() as Partial<Fulfilment>,
          shiftIntegrationData: this.shiftDefaultSettings.shiftIntegrationData,
          shiftRoutingData: this.shiftDefaultSettings.shiftRoutingData,
          shiftCutoffData: this.shiftDefaultSettings.shiftCutoffData,
          shiftId: this.shiftDefaultSettings.shiftId,
          shiftStatusData: {
            status: this.form.getRawValue().status,
            version: this.form.getRawValue().version
          },
          version: this.shiftDefaultSettings.version
        } as Partial<ShiftUpdateDto>
      } as Partial<ShiftPlanning>);

      this.router.navigate(['../step-3'], { relativeTo: this.navigationRoute });
    } else {
      console.log(this.form.errors);
    }
  }
}
