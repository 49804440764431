<form [formGroup]="form" class="form">
    <div class="form-row">
        <div class="form-group col-md-2">
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10" for="drivingTime">{{'Driving time' | translate}} ({{'minutes' | translate}})</label>
            <input type="number" class="form-control" formControlName="drivingTime">
        </div>
        <div class="form-group col-md-2">
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10" for="deliveryCount">{{'Delivery count' | translate}}</label>
            <input type="number" class="form-control" formControlName="deliveriesCount">
        </div>
        <div class="form-group col-md-2">
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10" for="deliveryCount">{{'Time precision' | translate}}</label>
            <input type="number" class="form-control" formControlName="timePrecision">
        </div>
        <div class="form-group col-md-2">
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10" for="drivingFromPercent">{{'Driving to delivery' | translate}} (%)</label>
            <input type="number" class="form-control" formControlName="drivingToPercent">
        </div>
        <div class="form-group col-md-2">
            <div class="form-group">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10" for="deliveryCount">&nbsp;</label>

                <button (click)="enableDeliveryManagementMode()" [disabled]="!form.valid" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">
                    <span>{{ 'Find crazy deliveries' | translate }}</span>
                </button>
            </div>
        </div>
        <div class="form-group col-md-2">
            <div class="form-group">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10" for="deliveryCount">&nbsp;</label>
            </div>
        </div>
    </div>
</form>

<app-visualiser-routes-planning 
    *ngIf="deliveryManagementSettings" 
    [deliveryManagementSettings]="deliveryManagementSettings">
</app-visualiser-routes-planning>
