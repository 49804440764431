import { Address } from '@entities/address';
import { Coordinates } from '@entities/coordinates';
import { serializeType } from '@shared/functions/serializeType.function';
import { Type } from 'class-transformer/decorators';

export class Apm {
    @Type(serializeType(Address))
    public address:        Address;

    @Type(serializeType(Coordinates))
    public coordinates:    Coordinates;
    public code:           string;
    public id:             string;
    public lcount:         number;
    public mcount:         number;
    public name:           string;
    public ncount:         number;
    public ocount:         number;
    public operatingHours: string;
    public status:         ApmStatus;
    public type:           string;
    public version:        number;

    get capacityLabel(): string {
        return `${this.lcount}/${this.mcount}/${this.ncount}/${this.ocount}`;
    }
}

export enum ApmStatus {
    AVAILABLE = "AVAILABLE",
    NOT_AVAILABLE = "NOT_AVAILABLE"
}
