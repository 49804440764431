import { Pipe, PipeTransform } from '@angular/core';
import { CutoffSolutions } from '../interfaces/cutoff-solutions.interface';
import { countBy } from 'lodash';
import { FinderEvent } from '@analytics/entities/finder-event';

@Pipe({
    name: 'findFinderEventPipe',
    pure: true
})
export class FindFinderEventPipe implements PipeTransform {
    public transform(events: FinderEvent[], type: string): FinderEvent {
        return events.find( (e: FinderEvent) => e.data.status === type);
    }
}
