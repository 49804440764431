import * as _ from 'lodash';

import { Entity } from '@entities/Entity';
import { Serializable } from '@entities/Serializable';
import { DriverAppLang, DriverExperience, DriverNationality, DriverSocialNumberType, DriverStatus } from '@enums/enum';
import { Van } from '@hardware/entities/van';

export class Driver extends Entity implements Serializable<Driver> {
    public appLang: string = DriverAppLang.PL;
    public clazz = 'DRIVER';
    public coolomatCode: string = '';
    public driversLicenseId: string = '';
    public email: string = '';
    public employeeId: string = '';
    public experience: string = DriverExperience.JUNIOR;
    public firstName: string = '';
    public id: string = '';
    public joinedAt: string = '';
    public lastname: string = '';
    public nationality: string = DriverNationality.PL;
    public passwordHash: string = '';
    public phoneNumber: string = '';
    public pin: string = '';
    public pinCode: string = '';
    public preferredVan: Van | null = null;
    public socialNumber: string = '';
    public socialNumberType: string = DriverSocialNumberType.PESEL;
    public status: string = DriverStatus.ACTIVE;
    public version: string = '';

    get label() {
        return `${this.firstName} ${this.lastname} [${this.employeeId}]`;
    }

    get fullName() {
        return `${this.firstName} ${this.lastname}`;
    }

    get isDefined() {
        return this.id !== undefined && !_.isEmpty(this.id);
    }

    public deserialize(data: any) {
        this.id = _.get(data, 'id');
        this.employeeId = _.get(data, 'employeeId');
        this.status = _.get(data, 'status');
        this.firstName = _.get(data, 'firstName', '');
        this.lastname = _.get(data, 'lastname', '');
        this.pinCode = _.get(data, 'pinCode', '');
        this.socialNumberType = _.get(data, 'socialNumberType');
        this.socialNumber = _.get(data, 'socialNumber');
        this.driversLicenseId = _.get(data, 'driversLicenseId');
        this.phoneNumber = _.get(data, 'phoneNumber');
        this.email = _.get(data, 'email');
        this.nationality = _.get(data, 'nationality', '');
        this.appLang = _.get(data, 'appLang');
        this.joinedAt = _.get(data, 'joinedAt');
        this.experience = _.get(data, 'experience', 'medium');
        this.version = _.get(data, 'version');
        const vanData = _.get(data, 'preferredVan');
        const pin = _.get(data, 'passwordHash');
        this.coolomatCode = _.get(data, 'coolomatCode');

        if (pin) {
            this.passwordHash = pin;
        }
        // todo remove cleaning of prefix
        if (vanData) {
            this.preferredVan = new Van().deserialize(vanData);
        }

        return this;
    }

    public serialize() {
        const serialized = {
            id: this.id,
            employeeId: this.employeeId,
            status: this.status,
            firstName: this.firstName,
            lastname: this.lastname,
            pinCode: this.pinCode,
            socialNumberType: this.socialNumberType,
            socialNumber: this.socialNumber,
            driversLicenseId: this.driversLicenseId,
            phoneNumber: this.phoneNumber,
            email: this.email,
            nationality: this.nationality,
            appLang: this.appLang,
            joinedAt: this.joinedAt,
            experience: this.experience,
            coolomatCode: this.coolomatCode,
            version: this.version
        };

        if (this.passwordHash.length > 0) {
            serialized['passwordHash'] = this.passwordHash;
        }

        if (this.preferredVan) {
            serialized['preferredVan'] = this.preferredVan.serialize();
        }

        return serialized;
    }

    get getDriverBarcodeValue() {
        return `${this.employeeId}X${this.pinCode}`;
    }

    public get driverName() {
        return this.firstName != null && this.lastname ? `${this.firstName} ${this.lastname}` : ''
    };
}
