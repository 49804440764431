import { Component, OnInit } from '@angular/core';
import { CrudListComponent } from '@shared/components/cruds/crud-list.component';
import { ProfanityDict } from '../../interfaces/profanity-dict.interface';
import { ProfanityFilterService } from '../../services/profanity-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { PageableResponse } from '@entities/pagable-response';
import { tap } from 'rxjs/operators';
import { Permissions } from '@enums/permissions';

@Component({
  selector: 'app-profanity-filter',
  templateUrl: './profanity-filter.component.html',
  styleUrls: ['./profanity-filter.component.scss']
})
export class ProfanityFilterComponent extends CrudListComponent<ProfanityDict, string> implements OnInit {

  public columns = [
    { id: 'id', prop: 'id', name: this.translateService.instant('Id'), flexGrow: 2, sortable: true },
    { id: 'word', prop: 'word', name: this.translateService.instant('Word'), flexGrow: 4, sortable: true },
    { id: 'modifiedAt', prop: 'modifiedAt', name: this.translateService.instant('Modified at'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1, sortable: false},
  ];

  public Permissions = Permissions;

  constructor(
    private readonly profanityFilter: ProfanityFilterService,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly notifierService: NotifierService,
    private readonly router: Router
  ) {
    super(profanityFilter, translateService, navigationRoute, modalService, notifierService)
   }

  public ngOnInit() {
    this.list$ = this.profanityFilter.list$.pipe(
      tap(a => console.log(a)),
      tap((list: PageableResponse<ProfanityDict>) => this.rows = list.content)
    );

    this.profanityFilter.fetchAll(this.queryParams);
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id.toString() === this.modifiedRow };
    };

    this.loader = false;
  }

}
