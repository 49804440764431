import { Pipe, PipeTransform } from '@angular/core';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { RouteExt } from '@entities/route-ext';

@Pipe({
    name: 'dividePerVansPipe',
    pure: true
})
export class DividePerVansPipe implements PipeTransform {

    public transform(optimizeRoute: OptimizeRoutes, value: number): string {
        const routes: number = optimizeRoute.routes.filter((r: RouteExt) => r.deliveriesCount).length;
        return value ? (value / routes).toFixed(2) : "0";
    }
}
