import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { ShiftPlanningWizardService } from '@calendar/services/shift-planning-wizard.service';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { tap, map, switchMap, catchError } from 'rxjs/operators';
import { NUMERIC_PATTREN } from '@shared/constants/utils.constants';
import { ShiftsService } from '@services/shifts.service';
import { Planning } from '@entities/planning';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Routing } from '@calendar/interafces/routing.interface';
import * as _ from 'lodash';
import { VanLimitInterface } from '@calendar/interafces/vanLimit.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { UtilsService } from '@services/utils.service';
import * as moment from 'moment';
import { DepotService } from 'src/app/locations/services/depot.service';
import { LocalStorageService } from '@services/local-storage.service';
import { Depot } from '@interfaces/depot.interface';
import { DepotVirtual } from '@interfaces/depot-virtual.interface';
import { ShiftPlanningType, RouteStrategyType } from '@enums/enum';
import { VirtualDepotConfig } from '@calendar/interafces/virtual-depot-config.interface';

@Component({
  selector: 'app-shift-planning-edit-routing',
  templateUrl: './shift-planning-edit-routing.component.html',
  styleUrls: ['./shift-planning-edit-routing.component.scss']
})
export class ShiftPlanningEditRoutingComponent implements OnInit {

  public routing$: Observable<Routing>;
  public planningSequence$: Observable<Planning[]>;
  public routeStrategies$: Observable<string[]>;

  private routing: Routing;

  private shiftDefaultSettings: ShiftUpdateDto;
  public form: FormGroup;
  public regularControlsNumber: string[] = ['expensiveDeliveryThreshold', 'expensiveDeliveryThresholdTime', 'maxCostFactor', 'loadFactor', 'routeMargin', 'routeMaximumReloads', 'slotStartMarginMinutes', 'slotEndMarginMinutes', 'shiftMaximumSegments', 'reloadPercentage']
  public regularControlsBoolean: string[] = [ 'includeReturnInPlanning', 'removePlanning', 'automaticCutoff'];
  private vanLimits: FormArray;
  private slotLimits: FormArray;
  public submitted: boolean = false;
  public formErrorsMessages;
  public mode: string;
  public virtualDepotConfigCtrl: FormArray;
  private depot: Depot;
  public shiftPlanTypeValues = Object.values(ShiftPlanningType);
  public routeStrategyType = Object.values(RouteStrategyType);


  constructor(
    private readonly shiftPlanningWizardService: ShiftPlanningWizardService,
    private readonly formBuilder: FormBuilder,
    private readonly navigationRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly shiftService: ShiftsService,
    private readonly notifierService: NotifierService,
    private readonly translateService: TranslateService,
    private readonly utilsService: UtilsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly depotService: DepotService,
    private readonly localStorageService: LocalStorageService
    ) { }

  public ngOnInit() {
    this.mode = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'action')

    this.routing$ = this.depotService.findOne(this.localStorageService.getDepot()).pipe(
      tap((depot: Depot) => this.depot = depot),
      switchMap(() => this.shiftPlanningWizardService.getModel()),
      tap(a => console.log('m: ', a)),
      tap((shiftPlanning: ShiftPlanning) => this.routing = shiftPlanning.defaultSettings.shiftRoutingData),
      tap((shiftPlanning: ShiftPlanning) => this.shiftDefaultSettings = shiftPlanning.defaultSettings),
      map((shiftPlanning: ShiftPlanning) => shiftPlanning.defaultSettings.shiftRoutingData),
      tap(a => console.log('m2: ', this.shiftDefaultSettings)),

      tap((routing: Routing) => this.initForm(routing))
    );
  }

  public initForm(routing: Routing) {
    this.form = this.formBuilder.group({

      expensiveDeliveryThreshold: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      expensiveDeliveryThresholdTime: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      maxCostFactor: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      loadFactor: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      routeMargin: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      routeMaximumReloads: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      slotEndMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      shiftMaximumSegments: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      slotStartMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      reloadPercentage: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(0), Validators.max(100)]],
      
      
      includeReturnInPlanning: [null, [Validators.required]],
      removePlanning: [null, [Validators.required]],
      automaticCutoff: [null, [Validators.required]],
      //autoFulfill: [null, [Validators.required]],

      planningSequence: [null, [Validators.required]],
      routeStrategy: [null, [Validators.required]],
      shiftplanType: [null, [Validators.required]],
      virtualDepotConfig: this.formBuilder.array([]),
      slotLimits: this.formBuilder.array([]),
      vanLimits: this.formBuilder.array([]),
      version: [],
    });

    this.planningSequence$ = this.shiftService.loadPlanningSequence();
    this.routeStrategies$ = this.shiftService.loadRouteStrategyDictionary();

    this.vanLimits = <FormArray>this.form.controls['vanLimits'];

    routing.vanLimits.forEach((item) => this.vanLimits.push(this.patchValuesVanLimit(item)));
    
    this.slotLimits = <FormArray>this.form.controls['slotLimits'];
    _.keys(routing.slotLimits).forEach((key) => this.slotLimits.push(this.patchValuesSlotLimit(key)));


    this.virtualDepotConfigCtrl = <FormArray>this.form.controls['virtualDepotConfig'];
    // this.virtualDepotConfigCtrl = (virtualDepotConfigCtrl) ? virtualDepotConfigCtrl : this.formBuilder.array([]);
    if (this.depot.virtualDepots && this.depot.virtualDepots.length) {
      this.depot.virtualDepots.forEach((item: DepotVirtual) => this.virtualDepotConfigCtrl.push(this.patchValues(item.id, item.name)));
    }

    const r = _.cloneDeep(routing);
    delete r.vanLimits;
    delete r.slotLimits;
    delete r.virtualDepotConfig;

    this.form.patchValue(r);

    console.log(this.form, this.virtualDepotConfigCtrl);

  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.shift.labels.${property}`);
  }

  private patchValuesVanLimit(item?: VanLimitInterface): AbstractControl {
    return this.formBuilder.group({
      usedPercentage: [item ? item.usedPercentage : 0, Validators.required],
      blockedSlots: [item ? item.blockedSlots : 0, Validators.required],
    })
  }

  private patchValues(id: number, name: string): AbstractControl {
    const enabled = (this.routing && this.routing.hasOwnProperty('virtualDepotConfig')) ? (this.routing.virtualDepotConfig != null && this.routing.virtualDepotConfig.find(i => i.virtualDepotId === id) !== undefined) ? true : false : false;
    const vd: VirtualDepotConfig = (this.routing.virtualDepotConfig) ? this.routing.virtualDepotConfig.find(i => i.virtualDepotId === id) : undefined;
    return this.formBuilder.group({
      routeStrategy: [vd ? vd.routeStrategy : RouteStrategyType.Count],
      routes: [vd ? vd.routes : 0],
      isExclusive: [vd ? vd.isExclusive : false],
      name: [name],
      virtualDepotId: [id],
      enabled: [enabled],
    })
  }


  public removeVanLimit(i): void {
    (<FormArray>this.form.controls['vanLimits']).removeAt(i);
  }

  public removeSlotLimit(i): void {
    (<FormArray>this.form.controls['slotLimits']).removeAt(i);
  }

  public addSlotLimit(): void {
    if (this.slotLimits.length === 0) {
      const startTime = moment('2000-01-01 ' + this.shiftDefaultSettings.shiftFulfillmentData.startTime);
      const endTime = moment('2000-01-01 ' + this.shiftDefaultSettings.shiftFulfillmentData.endTime);
      const overlaping = this.shiftDefaultSettings.shiftIntegrationData.slotOverLapping;
      const diff = endTime.diff(startTime) / 1000 / 60 / 60;

      do {
        this.slotLimits.push(this.patchValuesSlotLimit(startTime.format('HH:mm')));
        startTime.add(overlaping, 'minutes');
      } while (startTime <= _.cloneDeep(endTime).add(-this.shiftDefaultSettings.shiftIntegrationData.slotDuration, 'minutes'));

    } else {
      this.slotLimits.push(this.patchValuesSlotLimit());
    }
  }

  public addVanLimit(): void {
    
    if (this.vanLimits.controls.length) {
      this.vanLimits.push(this.patchValuesVanLimit());
    } else {
      const item: VanLimitInterface = { blockedSlots: 0, usedPercentage: 0 }
      this.vanLimits.push(this.patchValuesVanLimit(item));

      const item2: VanLimitInterface = { blockedSlots: 0, usedPercentage: 100 }
      this.vanLimits.push(this.patchValuesVanLimit(item2));
    }

    this.changeDetectorRef.detectChanges();
  }

  private patchValuesSlotLimit(key?: string): AbstractControl {
    return this.formBuilder.group({
      key: [key ? key : 0, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}')]],
      value: [(key && this.routing.slotLimits[key]) ? this.routing.slotLimits[key] : 0, Validators.required],
    })
  }

  public update() {
    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    if (this.form.valid) {

      const vanLimitObj = {};
      this.slotLimits.value.forEach(item => vanLimitObj[item['key']] = item['value']);
      const rawshiftRoutingData = this.form.getRawValue();
      delete rawshiftRoutingData['slotLimits'];
      rawshiftRoutingData['slotLimits'] = vanLimitObj;

      const virtualDepotConfig: VirtualDepotConfig[] = [];
      rawshiftRoutingData['virtualDepotConfig'].forEach((o: VirtualDepotConfig) => {
        if (o['enabled']) {
          virtualDepotConfig.push({
            routeStrategy: o.routeStrategy,
            routes: o.routes,
            virtualDepotId: o.virtualDepotId,
            isExclusive: o.isExclusive
          })
        }
      })
      rawshiftRoutingData['virtualDepotConfig'] = virtualDepotConfig;


      this.shiftPlanningWizardService.updateModel({
        defaultSettings: {
          shiftFulfillmentData: this.shiftDefaultSettings.shiftFulfillmentData,
          shiftIntegrationData: this.shiftDefaultSettings.shiftIntegrationData,
          shiftRoutingData: rawshiftRoutingData as Routing,
          shiftStatusData: this.shiftDefaultSettings.shiftStatusData,
          shiftCutoffData: this.shiftDefaultSettings.shiftCutoffData,
          version: this.shiftDefaultSettings.version
        } as Partial<ShiftUpdateDto>
      } as Partial<ShiftPlanning>);



      this.shiftPlanningWizardService.getModel().pipe(
        catchError((error: HttpErrorResponse) => {
          throw (error);
        }),
        switchMap((shiftPlanning: ShiftPlanning) => {
          return this.shiftService.updateShiftPlanning(shiftPlanning);
        })
      ).subscribe(
        (shiftPlanning: ShiftPlanning) => {
          this.notifierService.notify('success', this.translateService.instant('The shift planning has been updated!'));
          this.router.navigate(['/calendar/shift-planning', {modifiedRow: shiftPlanning.id}]);
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        }
      )
      
    } else {
      console.log(this.form);
    }
    
  }

  public create(): void {

    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    if (this.form.valid) {

      const vanLimitObj = {};
      this.slotLimits.value.forEach(item => vanLimitObj[item['key']] = item['value']);
      const rawshiftRoutingData = this.form.getRawValue();
      delete rawshiftRoutingData['slotLimits'];
      rawshiftRoutingData['slotLimits'] = vanLimitObj;

      const virtualDepotConfig: VirtualDepotConfig[] = [];
      rawshiftRoutingData['virtualDepotConfig'].forEach((o: VirtualDepotConfig) => {
        if (o['enabled']) {
          virtualDepotConfig.push({
            routeStrategy: o.routeStrategy,
            routes: o.routes,
            virtualDepotId: o.virtualDepotId,
            isExclusive: o.isExclusive
          })
        }
      })
      rawshiftRoutingData['virtualDepotConfig'] = virtualDepotConfig;


      this.shiftPlanningWizardService.updateModel({
        defaultSettings: {
          shiftFulfillmentData: this.shiftDefaultSettings.shiftFulfillmentData,
          shiftIntegrationData: this.shiftDefaultSettings.shiftIntegrationData,
          shiftRoutingData: rawshiftRoutingData as Routing,
          shiftStatusData: this.shiftDefaultSettings.shiftStatusData,
          shiftCutoffData: this.shiftDefaultSettings.shiftCutoffData,
          shiftId: this.shiftDefaultSettings.shiftId,
          version: this.shiftDefaultSettings.version
        } as Partial<ShiftUpdateDto>
      } as Partial<ShiftPlanning>);

      this.shiftPlanningWizardService.getModel().pipe(
        catchError((error: HttpErrorResponse) => {
          throw (error);
        }),
        switchMap((shiftPlanning: ShiftPlanning) => {
          return this.shiftService.createShiftPlanning(shiftPlanning);
        })
      ).subscribe(
        (shiftPlanning: ShiftPlanning) => {
          this.notifierService.notify('success', this.translateService.instant('The shift planning has been updated!'));
          this.router.navigate(['/calendar/shift-planning', {modifiedRow: shiftPlanning.id}]);
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.formErrorsMessages = error;
          this.submitted = false;
        }
      )
      
    } else {
      console.log(this.form);
    }
  }

}
