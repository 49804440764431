<ng-container *ngIf="(shiftCutoffData$ | async) as cutoffs">
    <form [formGroup]="form">
        <div class="shift-settings">

            <!-- <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Shift
                planning' | translate}}:</label> -->


            <ng-container formArrayName="shiftCutoffData">
                <ng-container *ngFor="let cutoff of formShiftCutoffData.controls; let i = index" [formGroupName]="i">


                    <h3><strong>{{'Cutoff configuration' | translate}}: {{i + 1}}</strong></h3>

                    <label
                        class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Automatic
                        planning' | translate}}:</label>
                    <div class="d-flex justify-content-start flex-wrap">
                        <div *ngFor="let item of cutoffBooleanControls" class="item mg-r-10">
                            <ng-container
                                *ngTemplateOutlet="control; context: { form: cutoff, item: item }"></ng-container>
                        </div>
                    </div>

                    <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Cutoff
                        control' | translate}}:</label>
                    <div class="d-flex justify-content-start flex-wrap">
                        <div *ngFor="let item of cutoffControls" class="item mg-r-10">
                            <ng-container
                                *ngTemplateOutlet="control; context: { form: cutoff, item: item }"></ng-container>
                        </div>
                    </div>

                    <label
                        class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Shift
                        limits' | translate}}:</label>
                    <div class="d-flex justify-content-start flex-wrap">

                        <div id="shift-plannin-edit-fulfilment-maximumDeliveries" class="item mg-r-10">
                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                                <span>{{'shiftProperty.maximumDeliveries' | translate}}:&nbsp;</span>
                                <ng-container *ngIf="'maximumDeliveries' | tooltipLabelPipe:'shift' as tooltip">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                        [style.width.px]="13" name="info"></i-feather>
                                </ng-container>
                            </label>

                            <div class="d-flex align-items-center justify-content-between" [style.height.px]="36">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input"
                                        [checked]="cutoff.value.maximumDeliveries > -1" id="maximumDeliveries{{i}}"
                                        (change)="maximumDeliveriesCheckboxFun(i, $event)">
                                    <label class="custom-control-label" for="maximumDeliveries{{i}}">&nbsp;</label>
                                </div>
                                <div *ngIf="cutoff.value.maximumDeliveries > -1">
                                    <input type="number" formControlName="maximumDeliveries"
                                        class="form-control w-100 flex-grow-1" />
                                </div>
                            </div>
                        </div>

                        <div id="shift-plannin-edit-fulfilment-maximumProductLines" class="item mg-r-10">
                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                                <span>{{'shiftProperty.maximumProductLines' | translate}}:&nbsp;</span>
                                <ng-container *ngIf="'maximumProductLines' | tooltipLabelPipe:'shift' as tooltip">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                        [style.width.px]="13" name="info"></i-feather>
                                </ng-container>
                            </label>

                            <div class="d-flex align-items-center justify-content-between" [style.height.px]="36">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input"
                                        [checked]="cutoff.value.maximumProductLines > -1" id="customSwitch{{i}}"
                                        (change)="maxProductLinesCheckboxFun(i, $event)">
                                    <label class="custom-control-label" for="customSwitch{{i}}">&nbsp;</label>
                                </div>
                                <div *ngIf="cutoff.value.maximumProductLines > -1">
                                    <input type="number" formControlName="maximumProductLines"
                                        class="form-control w-100 flex-grow-1" />
                                </div>
                            </div>
                        </div>

                        <div id="shift-plannin-edit-fulfilment-maximumRoutes" class="item mg-r-10">
                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                                <span>{{'shiftProperty.maximumRoutes' | translate}}:&nbsp;</span>
                                <ng-container *ngIf="'maximumRoutes' | tooltipLabelPipe:'shift' as tooltip">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                        [style.width.px]="13" name="info"></i-feather>
                                </ng-container>
                            </label>

                            <div class="d-flex align-items-center justify-content-between" [style.height.px]="36">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input"
                                        [checked]="cutoff.value.maximumRoutes > -1" id="maximumRoutes{{i}}"
                                        (change)="maximumRoutesCheckboxFun(i, $event)">
                                    <label class="custom-control-label" for="maximumRoutes{{i}}">&nbsp;</label>
                                </div>
                                <div *ngIf="cutoff.value.maximumRoutes > -1">
                                    <input type="number" formControlName="maximumRoutes"
                                        class="form-control w-100 flex-grow-1" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <button class="btn btn-danger tx-11" type="button" (click)="deleteCutoff(i)">
                        {{'Delete cutoff' | translate}}</button>

                    <hr /><br />
                </ng-container>
            </ng-container>


            <button class="btn btn-warning tx-11" type="button" (click)="addNewCutoffElement()">
                {{'Add new cutoff configuration' | translate}}</button>
        </div>

        <div class="actions d-flex justify-content-between">
            <button [routerLink]="['/calendar/shift-planning']" class="btn btn-white tx-11s">{{'Back to shifts list' |
                translate}}</button>
            <div class="d-flex justify-content-between">
                <button [routerLink]="['../step-3']"
                    class="btn btn-sm btn-warning pd-x-15 btn-uppercase mg-l-5">{{'Previous step' | translate}}</button>
                <button type="submit" (click)="update()"
                    class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Next' | translate}}</button>
            </div>
        </div>

    </form>

</ng-container>

<ng-template let-form="form" let-item="item" #control>
    <div [formGroup]="form">

        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
            <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
            <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                    name="info"></i-feather>
            </ng-container>
        </label>

        <div [ngSwitch]="item">
            <div *ngSwitchCase="'shiftType'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option *ngFor="let value of integrationShiftType" [ngValue]="value">{{ value | translate }}
                    </option>
                </select>
                <p *ngIf="mode === 'create'" class="tx-13 text-success">{{'Notice: The shift type cannot be changed in
                    the future' | translate}}</p>
            </div>
            <div *ngSwitchCase="'bookingWindowRestricted'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'autoPromoteSolution'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'autoFinalizeSolution'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'autoGenerateSolutions'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'fulfillmentIntegrationEnabled'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'isServiceShift'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'offsetDays'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option value="-1">{{ 'Day before' | translate }}</option>
                    <option value="-2">{{ 'Two days before' | translate }}</option>
                    <option value="-3">{{ 'Three days before' | translate }}</option>
                    <option value="0">{{ 'Same day' | translate }}</option>
                </select>
            </div>
            <div *ngSwitchCase="'status'">
                <select [id]="item" class="custom-select" [formControlName]="item">
                    <option value="OPEN">{{ 'Open' | translate }}</option>
                    <option value="IN_PROGRESS">{{ 'IN_PROGRESS' | translate }}</option>
                    <option value="COMPLETED">{{ 'COMPLETED' | translate }}</option>
                </select>
            </div>

            <div *ngSwitchCase="'time'">
                <input type="text" [formControlName]="item" class="form-control w-100" />
            </div>

            <div *ngSwitchDefault>

                <input type="number" min="0" [formControlName]="item" class="form-control w-100" />
            </div>
        </div>

        <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
            {{ "The field value is too long" | translate }}!
        </div>

        <div *ngIf="form.get(item).hasError('min') && form.get(item).touched" class="error-color">
            {{ "The entered value exceeds the defined range" | translate }}!
        </div>

        <div *ngIf="form.get(item).hasError('max') && form.get(item).touched" class="error-color">
            {{ "The entered value exceeds the defined range" | translate }}!
        </div>

        <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
            {{ "The field is required" | translate }}!
        </div>
    </div>

</ng-template>