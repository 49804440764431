import { Component, ElementRef, Input, NgZone, OnDestroy, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';

import { Slot } from '@entities/slot';
import { CustomerType } from '@enums/enum';

import { VisualiserService } from '../services/visualiser.service';
import { Depot } from '@interfaces/depot.interface';
import { DepotService } from 'src/app/locations/services/depot.service';
import { LocalStorageService } from '@services/local-storage.service';
import { tap, switchMap } from 'rxjs/operators';
import { Coordinates } from '@interfaces/coordinates.interface';
import { environment } from '@environment';
import { ShiftSummaryStats } from '../interfaces/shift-summary-stats.interface';

@Component({
    selector: 'app-booking-availability-content',
    templateUrl: './booking-availability-content.component.html',
    styleUrls: ['booking-availability.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingAvailabilityContentComponent implements OnInit, OnDestroy {
    @Input() private set date(date) {
        this._date = date;
        this.checkAvailability();
    }

    private customers = [];
    private cNew = {
        version: 1,
        createdAt: '2018-12-17T23:24:05.000+0000',
        modifiedAt: '2018-12-17T23:24:05.000+0000',
        id: 'location:e5858f84-5094-40dc-86eb-368c6fd1a078',
        ref: '001445',
        customerId: 'customer:147abef0-debd-4209-9099-2d2622cc45ad',
        label: null,
        rawAddress: 'Józefa Chłopickiego 7/9, Warszawa-Rembertów 04-314, PL',
        remarks: '',
        status: 'VERIFIED',
        locationType: 'REGULAR',
        geocodingStatus: 'ENCODED',
        areaId: null,
        additional: {
            companyName: null,
            flatNO: '29',
            lift: false,
            staircaseNO: '',
            floor: 2,
            contactName: '',
            contactPhoneNO: null,
            domofonCode: null,
            instructions: null
        },
        address: {
            town: 'Warszawa',
            zip: '00-001',
            district: null,
            voivodeship: null,
            country: 'Poland',
            street: 'Józefa Chłopickiego',
            houseNO: '7/9'
        },
        originalAddress: {
            town: 'Warszawa-Rembertów',
            zip: '04-314',
            district: null,
            voivodeship: null,
            country: 'Poland',
            street: 'Józefa Chłopickiego',
            houseNO: '7/9'
        },
        estimatedDeliveryDutation: 'PT10M',
        coordinates: {
            lat: 52.24570199999999,
            lng: 21.1041409
        },
        customer: {
            version: 1,
            createdAt: '2018-12-17T23:29:39.000+0000',
            modifiedAt: '2018-12-17T23:29:39.000+0000',
            id: 'customer:147abef0-debd-4209-9099-2d2622cc45ad',
            ref: '001445',
            firstName: 'Anna',
            lastName: 'ENGELKING',
            status: 'NEW',
            phoneNr: '660310793',
            phone2Nr: '2',
            preferredLocationId: null,
            label: 'Anna ENGELKING'
        }
    };

    private checkAvailableSlots: Subscription;
    private slots = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

    public assetsPath = 'assets/img';
    public markerPath = `${this.assetsPath}/markers`;
    public environment = environment;
    public _date = moment().add(1, 'days');
    public locale = null;
    public loading = false;
    public depot$: Observable<Depot>;
    public slotsResults: Array<Slot[]> = null;
    public searchControl: FormControl;
    public shiftSummaryStats: ShiftSummaryStats[];
    public slotCounts: { [key: string]: number } = {};

    constructor(
        private readonly translate: TranslateService, 
        private readonly visuliserService: VisualiserService, 
        private readonly mapsAPILoader: MapsAPILoader, 
        private readonly ngZone: NgZone,
        private readonly depotService: DepotService,
        private readonly localStorageService: LocalStorageService,
        private readonly changeDetector: ChangeDetectorRef

    ) {
        this.locale = translate.currentLang === 'en' ? enLocale : plLocale;
    }

    public ngOnInit(): void {
        this.depot$ = this.depotService.findOne(this.localStorageService.getDepot()).pipe(
            tap((depot: Depot) => {
                depot.testLocations.forEach((location: Coordinates, i: number) => {
                    this.customers.push([location.lat, location.lng, '000029', 'NEW', i]);
                    this.checkAvailability();
                }); 
            })
        )
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    private checkAvailability(): void {
        if (!this.customers.length) {
            return;
        }

        this.loading = true;
        this.unsubscribe();
        const date = moment(this._date).format('YYYY-MM-DD');
        
        this.checkAvailableSlots = 
            this.visuliserService.getShiftStats(date, this.localStorageService.getDepot()).pipe(
                switchMap((stats: ShiftSummaryStats[]) => {
                    this.shiftSummaryStats = stats;
                    return this.visuliserService.checkAvailableSlots(date, this.cNew, this.customers);
                })
            ).subscribe((slots: Array<Slot[]>) => {
                console.log('stats', this.shiftSummaryStats);
                this.shiftSummaryStats.forEach((el: ShiftSummaryStats) => {
                    Object.assign(this.slotCounts, el.stats.slotCounts)
                })
                console.log('stats', this.slotCounts);

                this.slotsResults = slots;
                this.loading = false;
                this.changeDetector.detectChanges();
            });
            
    }

    private getAvailabilityFactor(index: string, startSlot: string): string {
        const splited = startSlot.split(':');
        const parsedStartSlot = `${('0' + splited[0]).slice(-2)}:${splited[1]}:${splited[2]}`;
        const slot: Slot = _.find(this.slotsResults[index], (s: Slot) => s.startTime === parsedStartSlot);

        if (_.isUndefined(slot)) {
            return '';
        }
        const cost = slot.cost ? `${slot.cost}` : '';
        return `<strong>${slot.availability.toFixed(2)}</strong>`;
    }

    private getColor(index: string, startSlot: string): string {
        const splited = startSlot.split(':');
        const parsedStartSlot = `${('0' + splited[0]).slice(-2)}:${splited[1]}:${splited[2]}`;
        const slot: Slot = _.find(this.slotsResults[index], (s: Slot) => s.startTime === parsedStartSlot);

        const red = '#D94343';
        const orange = '#3C733C';
        const green = '#3C733C';
        const yellow = '#3C733C';

        if (_.isUndefined(slot)) {
            return red;
        }

        if (moment().isAfter(moment(slot.cutOff).add(-2, 'hours')) && slot.availability) {
            return green;
        }

        if (!slot.availability) {
            return red;
        }

        if (slot.availability <= 0.05 && this.customers[index]['3'] === CustomerType.NEW) {
            return orange;
        } else if (slot.availability > 0.05 && slot.availability <= 0.25 && (this.customers[index]['3'] === CustomerType.NEW || this.customers[index]['3'] === CustomerType.VIP)) {
            return yellow;
        } else if (slot.availability > 0.25) {
            return green;
        } else {
            return red;
        }
    }

    public placeMarker(event) {
        this.customers.push([event.coords.lat, event.coords.lng, '000029', 'NEW', this.customers.length]);
        this.checkAvailability();
      }

    public findStats(slot: string) {
        const result = this.shiftSummaryStats.find((el: ShiftSummaryStats) => el.shift.startTime === ((slot.toString().length === 2) ? slot + ':00:00' : ('0' + slot) + ':00:00'));
        return result ? result : undefined;
    }

    public tooltipLabel(shiftSummaryStats: ShiftSummaryStats){
        const fleetMax = _.sumBy(shiftSummaryStats.shift.fleet, (f) => f.max);
        return `` + 
            `${this.translate.instant('Delivery count')}: ${shiftSummaryStats.stats.deliveryCount}\n` + 
            `${this.translate.instant('Delivery limit')}: ${shiftSummaryStats.stats.deliveryLimit}\n` + 
            `${this.translate.instant('Box count')}: ${shiftSummaryStats.stats.boxCount}\n` + 
            `${this.translate.instant('Box limit')}: ${shiftSummaryStats.stats.boxLimit}\n` + 
            `${this.translate.instant('Current routes')}: ${shiftSummaryStats.routeCount}\n` + 
            `${this.translate.instant('Available routes')}: ${fleetMax}\n` + 
            `${this.translate.instant('Current route usage')}: ${((shiftSummaryStats.routeCount * 100) / fleetMax).toFixed(2)}%\n`;
    }

    private unsubscribe() {
        if (this.checkAvailableSlots) {
            this.checkAvailableSlots.unsubscribe();
        }
    }
}
