export const locationsData = [
    ['21.04161512', '52.13960318', 'Warszawa,02-795,Kazury S.,20'],
    ['21.01830211', '52.14878441', 'Warszawa,02-801,Puławska,383'],
    ['21.04577113', '52.14025334', 'Warszawa,02-795,Kazury S.,6'],
    ['21.02613739', '52.15656842', 'Warszawa,02-784,Pięciolinii,1'],
    ['21.03041547', '52.16237089', 'Warszawa,02-787,Symfonii,5'],
    ['21.07372397', '52.13229157', 'Warszawa,02-796,Wąwozowa,3'],
    ['21.02070678', '52.15995907', 'Warszawa,02-785,Surowieckiego W.,8'],
    ['21.02725236', '52.15838166', 'Warszawa,02-785,Wiolinowa,3'],
    ['21.03577836', '52.15982878', 'Warszawa,02-786,Zamiany,12'],
    ['21.07285129', '52.13668808', 'Warszawa,02-786,Rosoła J.,22'],
    ['21.05113577', '52.15675125', 'Warszawa,02-777,Kopcińskiego S.,9'],
    ['21.03568217', '52.15503535', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,83'],
    ['21.02925759', '52.14424744', 'Warszawa,02-811,Makolągwy,11'],
    ['21.01891752', '52.14104641', 'Warszawa,02-801,Puławska,435A'],
    ['21.0647621', '52.12830135', 'Warszawa,02-798,Na Przyzbie,3'],
    ['21.01304029', '52.15341052', 'Warszawa,02-822,Poleczki,9A'],
    ['21.07503389', '52.13058459', 'Warszawa,02-796,Dembego S.,10'],
    ['21.03801857', '52.14871803', 'Warszawa,02-776,Miklaszewskiego,12'],
    ['21.00021602', '52.15586681', 'Warszawa,02-822,Poleczki,31A'],
    ['21.05734747', '52.14973064', 'Warszawa,02-778,Grzegorzewskiej M.,2'],
    ['21.06260126', '52.14349565', 'Warszawa,02-792,Lasek Brzozowy,10'],
    ['20.99528722', '52.15472291', 'Warszawa,02-823,Osmańska,14'],
    ['21.02880281', '52.16081626', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,98'],
    ['21.02749415', '52.10845767', 'Warszawa,02-892,Bażancia,34'],
    ['20.99254873', '52.16337573', 'Warszawa,02-699,Kłobucka,8B'],
    ['21.0155167', '52.14010045', 'Warszawa,02-829,Taneczna,18C'],
    ['21.020187', '52.15028656', 'Warszawa,02-806,Rybitwy,4'],
    ['21.05328515', '52.14321983', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,47'],
    ['21.00906011', '52.13868537', 'Warszawa,02-830,Gawota,16C'],
    ['21.03383428', '52.14287129', 'Warszawa,02-775,Alternatywy,6'],
    ['21.02132715', '52.1370078', 'Warszawa,02-817,Białozora,37'],
    ['21.0508455', '52.13822935', 'Warszawa,02-793,Belgradzka,52'],
    ['21.06056778', '52.15427321', 'Warszawa,02-797,Kokosowa,49'],
    ['20.99529018', '52.15399077', 'Warszawa,02-823,Osmańska,12'],
    ['21.07086822', '52.13526576', 'Warszawa,02-796,Jeżewskiego K.,3A'],
    ['21.02556706', '52.15692483', 'Warszawa,02-784,Pięciolinii,3'],
    ['20.98072953', '52.13236807', 'Warszawa,02-867,Baletowa,175'],
    ['21.05415184', '52.1354479', 'Warszawa,02-793,Małej Łąki,8'],
    ['21.04298631', '52.14447278', 'Warszawa,02-776,Dereniowa,7'],
    ['21.06509063', '52.13486959', 'Warszawa,02-796,Wańkowicza M.,6'],
    ['21.06975445', '52.13186709', 'Warszawa,02-796,Wąwozowa,6'],
    ['21.02405086', '52.13557167', 'Warszawa,02-836,Kormoranów,1'],
    ['21.06431409', '52.13154765', 'Warszawa,02-796,Wąwozowa,18'],
    ['21.05855601', '52.13953307', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,36'],
    ['20.99393736', '52.1639889', 'Warszawa,02-699,Kłobucka,8D'],
    ['21.06022224', '52.13333607', 'Warszawa,02-793,Płk. Baló Z.,4'],
    ['21.02004549', '52.15370314', 'Warszawa,02-784,Janowskiego A.,50'],
    ['21.02659602', '52.15275412', 'Warszawa,02-784,Dunikowskiego X.,10'],
    ['21.05605919', '52.15004881', 'Warszawa,02-778,Grzegorzewskiej M.,5'],
    ['21.03485331', '52.15551931', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,85'],
    ['21.07421025', '52.13310744', 'Warszawa,02-796,Wąwozowa,2'],
    ['21.05487683', '52.13757966', 'Warszawa,02-793,Małcużyńskiego W.,7'],
    ['21.03418999', '52.15721359', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,86'],
    ['21.05895919', '52.1389066', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,36A'],
    ['21.05529891', '52.15497953', 'Warszawa,02-776,Nowoursynowska,153'],
    ['21.00084418', '52.14475419', 'Warszawa,02-850,Kądziołeczki,52B'],
    ['21.05036929', '52.14086464', 'Warszawa,02-791,Na Uboczu,10'],
    ['21.06416926', '52.12995765', 'Warszawa,02-798,Za Łąkami,3'],
    ['21.02002272', '52.14150807', 'Warszawa,02-812,Ibisa,12'],
    ['21.05759474', '52.15387214', 'Warszawa,02-797,Reprezentacyjna,4'],
    ['21.0150338', '52.14670785', 'Warszawa,02-829,Taneczna,57/59'],
    ['20.99357133', '52.16197792', 'Warszawa,02-699,Złoty Potok,1'],
    ['20.99811496', '52.15521446', 'Warszawa,02-822,Poleczki,33'],
    ['21.0498159', '52.13626147', 'Warszawa,02-791,Stryjeńskich,19'],
    ['21.04122663', '52.15847463', 'Warszawa,02-786,Al. Rodowicza Anody J.,22'],
    ['21.00074788', '52.14448561', 'Warszawa,02-850,Kądziołeczki,50'],
    ['21.02737847', '52.13598062', 'Warszawa,02-818,Kanarkowa,13'],
    ['21.05488807', '52.13705177', 'Warszawa,02-793,Małcużyńskiego W.,5'],
    ['21.03497792', '52.14445375', 'Warszawa,02-776,Gandhi I.,35'],
    ['21.00123882', '52.12617516', 'Warszawa,02-863,Jeziorki,14A'],
    ['21.03736207', '52.14815889', 'Warszawa,02-781,Pileckiego W.,130A'],
    ['21.06473089', '52.14213486', 'Warszawa,02-793,Belgradzka,4'],
    ['20.99792151', '52.14282371', 'Warszawa,02-849,Krasnowolska,82R'],
    ['21.01997956', '52.13191154', 'Warszawa,02-838,Raniuszka,22'],
    ['20.99262505', '52.1650404', 'Warszawa,02-699,Kłobucka,10'],
    ['21.02517635', '52.14345998', 'Warszawa,02-781,Roentgena W.,43'],
    ['21.0590089', '52.1355744', 'Warszawa,02-793,Przy Bażantarni,13'],
    ['21.03047553', '52.15110476', 'Warszawa,02-784,Dembowskiego E.,17'],
    ['21.05012182', '52.14322596', 'Warszawa,02-791,Braci Wagów,20'],
    ['21.03185651', '52.15090345', 'Warszawa,02-784,Dembowskiego E.,19'],
    ['21.06555937', '52.14068161', 'Warszawa,02-793,Lokajskiego E.,22'],
    ['21.03709918', '52.14762938', 'Warszawa,02-781,Pileckiego W.,130'],
    ['21.00494901', '52.1554282', 'Warszawa,02-822,Galopu,1'],
    ['21.03325381', '52.14261624', 'Warszawa,02-775,Alternatywy,7'],
    ['20.99638181', '52.15388285', 'Warszawa,02-823,Salsy,2'],
    ['21.03412194', '52.14162571', 'Warszawa,02-778,Płaskowickiej F.,44'],
    ['21.01916458', '52.14742598', 'Warszawa,02-809,Mysikrólika,18'],
    ['21.04400337', '52.13850033', 'Warszawa,02-795,Kazury S.,26'],
    ['21.0306473', '52.15214565', 'Warszawa,02-784,Dembowskiego E.,7'],
    ['21.01614238', '52.13125767', 'Warszawa,02-858,Transportowców,8'],
    ['21.05094539', '52.14561364', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,51'],
    ['21.05996726', '52.1315022', 'Warszawa,02-796,Wąwozowa,28'],
    ['21.0632695', '52.1463804', 'Warszawa,02-790,Pachnąca,81'],
    ['21.06670486', '52.12859385', 'Warszawa,02-798,Kabacki Dukt,6'],
    ['21.0206342', '52.16141178', 'Warszawa,02-785,Surowieckiego W.,2'],
    ['21.06663385', '52.13291866', 'Warszawa,02-796,Wańkowicza M.,2'],
    ['21.04056637', '52.15822866', 'Warszawa,02-786,Al. Rodowicza Anody J.,22A'],
    ['21.07345802', '52.13181643', 'Warszawa,02-796,Dembego S.,9'],
    ['21.06724251', '52.13479295', 'Warszawa,02-796,Jeżewskiego K.,5F'],
    ['21.03182785', '52.15473349', 'Warszawa,02-784,Herbsta S.,4'],
    ['21.06712374', '52.12817647', 'Warszawa,02-798,Kabacki Dukt,4'],
    ['21.01413826', '52.12994461', 'Warszawa,02-862,Muzyczna,30'],
    ['21.05646062', '52.12961662', 'Warszawa,02-798,Ekologiczna,17'],
    ['21.05343136', '52.1407066', 'Warszawa,02-791,Meander,5'],
    ['21.0307272', '52.14395257', 'Warszawa,02-811,Makolągwy,3A'],
    ['21.06213946', '52.14139018', 'Warszawa,02-793,Belgradzka,10'],
    ['21.05389373', '52.15870257', 'Warszawa,02-777,Przybylskiego Cz.,1'],
    ['21.01154427', '52.15381253', 'Warszawa,02-822,Poleczki,13'],
    ['21.01768168', '52.11851', 'Warszawa,02-884,Puławska,474'],
    ['21.01512216', '52.14433211', 'Warszawa,02-829,Taneczna,39A'],
    ['21.04841699', '52.13254638', 'Warszawa,02-798,Jaworowa,8'],
    ['21.06572464', '52.13407105', 'Warszawa,02-796,Wańkowicza M.,4'],
    ['21.00342083', '52.15203475', 'Warszawa,02-826,Poloneza,89B'],
    ['21.04970594', '52.15242697', 'Warszawa,02-777,Szolc-Rogozińskiego,21'],
    ['21.04840907', '52.13431171', 'Warszawa,02-798,Jaworowa,49'],
    ['21.04460496', '52.13397069', 'Warszawa,02-834,Perkalowa,6'],
    ['21.04972283', '52.14232615', 'Warszawa,02-791,Braci Wagów,11'],
    ['21.0258308', '52.15739158', 'Warszawa,02-784,Pięciolinii,6'],
    ['21.0302421', '52.15599038', 'Warszawa,02-784,Herbsta S.,2A'],
    ['21.01316482', '52.11968159', 'Warszawa,02-862,Farbiarska,7'],
    ['21.07839846', '52.1285142', 'Warszawa,02-796,Relaksowa,33'],
    ['21.04530444', '52.14003409', 'Warszawa,02-795,Kazury S.,7'],
    ['20.99613703', '52.15520299', 'Warszawa,02-822,Poleczki,35'],
    ['21.00982838', '52.11095585', 'Warszawa,02-871,Karczunkowska,46'],
    ['21.05745202', '52.14683564', 'Warszawa,02-792,Lanciego F.,14'],
    ['21.0567756', '52.15405906', 'Warszawa,02-797,Nowoursynowska,154A'],
    ['21.03647631', '52.15641402', 'Warszawa,02-786,Bacewiczówny G.,6'],
    ['21.03742503', '52.15145627', 'Warszawa,02-776,Malinowskiego E.,5'],
    ['21.05446187', '52.13075244', 'Warszawa,02-833,Wełniana,5B'],
    ['21.02925182', '52.15782077', 'Warszawa,02-784,Pasaż Ursynowski,11'],
    ['21.06929402', '52.13409957', 'Warszawa,02-796,Bronikowskiego K.,51'],
    ['21.01956757', '52.14167958', 'Warszawa,02-812,Ibisa,14'],
    ['21.02112062', '52.13495965', 'Warszawa,02-836,Modraszki,3A'],
    ['21.01969634', '52.12582797', 'Warszawa,02-842,Rataja M.,19'],
    ['21.0302312', '52.15828022', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,101'],
    ['20.99340546', '52.13749659', 'Warszawa,02-821,Hołubcowa,51'],
    ['21.01152731', '52.12261941', 'Warszawa,02-862,Farbiarska,25'],
    ['21.03045104', '52.14227344', 'Warszawa,02-778,Płaskowickiej F.,46'],
    ['21.01915272', '52.13689044', 'Warszawa,02-816,Rajskich Ptaków,49'],
    ['21.04769146', '52.13305426', 'Warszawa,02-798,Jaworowa,25'],
    ['21.04277171', '52.1709739', 'Warszawa,02-768,Fosa,31'],
    ['21.02776928', '52.15440093', 'Warszawa,02-784,Sosnowskiego,1'],
    ['21.05096244', '52.15009778', 'Warszawa,02-777,Cynamonowa,19'],
    ['21.00077', '52.14475419', 'Warszawa,02-850,Kądziołeczki,52'],
    ['21.01166944', '52.12936787', 'Warszawa,02-862,Farbiarska,42D'],
    ['21.05786706', '52.15215432', 'Warszawa,02-776,Nowoursynowska,145D'],
    ['21.05532912', '52.14070024', 'Warszawa,02-791,Meander,6'],
    ['21.05793996', '52.1315065', 'Warszawa,02-796,Wąwozowa,32'],
    ['21.01583988', '52.16513128', 'Warszawa,02-684,Puławska,266'],
    ['21.04117524', '52.14680927', 'Warszawa,02-776,Gandhi I.,27'],
    ['21.01870719', '52.13128479', 'Warszawa,02-844,Puławska,491'],
    ['21.03587273', '52.15677784', 'Warszawa,02-786,Jastrzębowskiego W. B.,4A'],
    ['20.98987885', '52.10722617', 'Warszawa,02-873,Kurantów,13'],
    ['21.01210245', '52.13656753', 'Warszawa,02-830,Gawota,11'],
    ['21.03140954', '52.15842844', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,92'],
    ['21.05751983', '52.14381192', 'Warszawa,02-792,Lanciego F.,7'],
    ['20.99256167', '52.16278767', 'Warszawa,02-699,Kłobucka,8A'],
    ['21.06807392', '52.13551781', 'Warszawa,02-796,Jeżewskiego K.,7'],
    ['20.99261216', '52.16387779', 'Warszawa,02-699,Kłobucka,8C'],
    ['21.01667781', '52.15542671', 'Warszawa,02-819,Puławska,270'],
    ['21.02839146', '52.14862686', 'Warszawa,02-781,Łukaszczyka P.,33'],
    ['21.04230415', '52.14737067', 'Warszawa,02-776,Gandhi I.,11'],
    ['21.05106838', '52.13517542', 'Warszawa,02-791,Stryjeńskich,17'],
    ['21.05089974', '52.15114702', 'Warszawa,02-777,Szolc-Rogozińskiego,11'],
    ['21.04919056', '52.14695259', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,55'],
    ['21.05622688', '52.1306395', 'Warszawa,02-886,Rybałtów,20'],
    ['21.05209', '52.161021', 'Warszawa,02-776,Nowoursynowska,162/17'],
    ['21.05681036', '52.1536024', 'Warszawa,02-776,Nowoursynowska,147'],
    ['21.06724817', '52.12753246', 'Warszawa,02-798,Kabacki Dukt,2'],
    ['21.00655508', '52.15220255', 'Warszawa,02-826,Poloneza,91'],
    ['21.05577044', '52.15876648', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,28A'],
    ['21.01699808', '52.13675523', 'Warszawa,02-819,Puławska,366'],
    ['21.03052734', '52.15906926', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,94'],
    ['21.06376163', '52.13814849', 'Warszawa,02-793,Raabego H.,2B'],
    ['21.05030768', '52.15703489', 'Warszawa,02-777,Kopcińskiego S.,16'],
    ['21.02427284', '52.15103071', 'Warszawa,02-781,Pileckiego W.,65'],
    ['21.05640158', '52.15451375', 'Warszawa,02-797,Nowoursynowska,156A'],
    ['21.01399683', '52.1158762', 'Warszawa,02-875,Klarnecistów,7'],
    ['21.03455993', '52.15667913', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,84'],
    ['21.01716813', '52.13779841', 'Warszawa,02-845,Puławska,358'],
    ['21.05231984', '52.14895002', 'Warszawa,02-777,Kulczyńskiego S.,16'],
    ['21.03586036', '52.15771942', 'Warszawa,02-786,Zamiany,6'],
    ['21.01663808', '52.15040926', 'Warszawa,02-829,Mączeńskiego Z.,13'],
    ['21.01326253', '52.15431999', 'Warszawa,02-822,Poleczki,12A'],
    ['21.0457257', '52.14621384', 'Warszawa,02-776,Warchałowskiego E.,6'],
    ['21.06850509', '52.13483069', 'Warszawa,02-796,Jeżewskiego K.,5D'],
    ['21.0621242', '52.14426839', 'Warszawa,02-796,Mandarynki,4'],
    ['21.04402653', '52.16797385', 'Warszawa,02-787,Nowoursynowska,172'],
    ['21.04196551', '52.15019775', 'Warszawa,02-776,Hawajska,10'],
    ['20.99344582', '52.16877813', 'Warszawa,02-699,Kłobucka,23E'],
    ['21.06525814', '52.14174064', 'Warszawa,02-793,Belgradzka,3'],
    ['21.07194541', '52.13322875', 'Warszawa,02-796,Dembego S.,19'],
    ['21.01309298', '52.13003657', 'Warszawa,02-860,Czterech Wiatrów,32'],
    ['21.04569488', '52.14902951', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,61'],
    ['21.05701318', '52.13418662', 'Warszawa,02-793,Villardczyków,10'],
    ['21.04067463', '52.1385814', 'Warszawa,02-781,Pileckiego W.,112'],
    ['21.02815279', '52.14680636', 'Warszawa,02-781,Roentgena W.,16'],
    ['21.01711012', '52.1428388', 'Warszawa,02-845,Puławska,326'],
    ['21.01835465', '52.14714819', 'Warszawa,02-801,Puławska,395'],
    ['20.99770989', '52.14073051', 'Warszawa,02-851,Kadryla,18'],
    ['21.05349539', '52.13589027', 'Warszawa,02-793,Małej Łąki,5'],
    ['21.07216169', '52.13007407', 'Warszawa,02-796,Zaruby J.,11'],
    ['21.01083055', '52.13385006', 'Warszawa,02-856,Ludwinowska,19'],
    ['21.04944362', '52.14147339', 'Warszawa,02-791,Na Uboczu,16'],
    ['21.02590163', '52.1413911', 'Warszawa,02-801,Puławska,427'],
    ['21.02579986', '52.16372049', 'Warszawa,02-785,Koński Jar,6'],
    ['21.05676763', '52.14566053', 'Warszawa,02-792,Lanciego F.,12'],
    ['21.06005735', '52.13006468', 'Warszawa,02-798,Mielczarskiego R.,7'],
    ['21.00464306', '52.15685683', 'Warszawa,02-820,Wyczółki,51'],
    ['21.02641719', '52.14475314', 'Warszawa,02-811,Makolągwy,21'],
    ['21.00601661', '52.12268017', 'Warszawa,02-867,Baletowa,29D'],
    ['21.06314517', '52.13352026', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,15'],
    ['21.02376668', '52.1532216', 'Warszawa,02-784,Arctowskiego H.,28'],
    ['20.99487577', '52.16923568', 'Warszawa,02-699,Kłobucka,25'],
    ['21.01173043', '52.13661569', 'Warszawa,02-830,Gawota,9G'],
    ['21.05988243', '52.14411502', 'Warszawa,02-792,Lasek Brzozowy,13'],
    ['21.05930133', '52.12982919', 'Warszawa,02-798,Ekologiczna,14'],
    ['21.07282583', '52.12919701', 'Warszawa,02-796,Zaruby J.,9'],
    ['21.05641399', '52.14315348', 'Warszawa,02-792,Lanciego F.,9H'],
    ['21.00464759', '52.15683965', 'Warszawa,02-820,Wyczółki,51/53'],
    ['21.05433147', '52.13256417', 'Warszawa,02-791,Stryjeńskich,13A'],
    ['21.04152919', '52.14611865', 'Warszawa,02-776,Hirszfelda L.,16'],
    ['21.02080832', '52.15806125', 'Warszawa,02-785,Surowieckiego W.,12'],
    ['21.01948964', '52.163215', 'Warszawa,02-781,Paszkiewicza L.,4'],
    ['21.03528872', '52.14941401', 'Warszawa,02-776,Miklaszewskiego,13'],
    ['21.03727997', '52.14357153', 'Warszawa,02-781,Pileckiego W.,105'],
    ['21.01091299', '52.11026897', 'Warszawa,02-871,Karczunkowska,35'],
    ['21.05334634', '52.14467254', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,54'],
    ['21.0321535', '52.15701258', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,97'],
    ['21.06007299', '52.1293227', 'Warszawa,02-798,Mielczarskiego R.,5'],
    ['21.03775964', '52.15801083', 'Warszawa,02-786,Związku Walki Młodych,9'],
    ['21.04825829', '52.1375821', 'Warszawa,02-791,Stryjeńskich,21'],
    ['21.05252634', '52.14391889', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,49'],
    ['21.0596543', '52.13439787', 'Warszawa,02-793,Villardczyków,3'],
    ['21.04656817', '52.14175687', 'Warszawa,02-791,Na Uboczu,28A'],
    ['21.04170676', '52.15477139', 'Warszawa,02-777,Cynamonowa,53'],
    ['21.05204028', '52.13837982', 'Warszawa,02-793,Belgradzka,50'],
    ['21.03035938', '52.1532425', 'Warszawa,02-784,Herbsta S.,11'],
    ['21.02815376', '52.15318639', 'Warszawa,02-784,Dunikowskiego X.,5'],
    ['21.06954234', '52.13372524', 'Warszawa,02-796,Bronikowskiego K.,23'],
    ['21.01348445', '52.14734815', 'Warszawa,02-829,Taneczna,59E'],
    ['21.06538113', '52.13377648', 'Warszawa,02-796,Wańkowicza M.,5A'],
    ['21.07524727', '52.12787764', 'Warszawa,02-796,Zaruby J.,2'],
    ['21.03885511', '52.16427972', 'Warszawa,02-787,Chłapowskiego D.,11'],
    ['21.06844437', '52.13541987', 'Warszawa,02-796,Jeżewskiego K.,7A'],
    ['21.05247949', '52.13234981', 'Warszawa,02-791,Stryjeńskich,15d'],
    ['21.01438347', '52.14569303', 'Warszawa,02-849,Walczyka,2A'],
    ['21.04303989', '52.14751954', 'Warszawa,02-776,Dereniowa,12'],
    ['21.02586548', '52.16499766', 'Warszawa,02-785,Koński Jar,10'],
    ['21.0350049', '52.14258625', 'Warszawa,02-775,Alternatywy,5'],
    ['21.0237243', '52.14876695', 'Warszawa,02-803,Bekasów,46D'],
    ['21.04644193', '52.14969349', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,60'],
    ['21.05558213', '52.12827888', 'Warszawa,02-886,Rybałtów,1'],
    ['21.01567157', '52.14437784', 'Warszawa,02-828,Pięciu Stawów,4'],
    ['21.06099088', '52.13094338', 'Warszawa,02-796,Wąwozowa,25'],
    ['21.06370578', '52.12797628', 'Warszawa,02-798,Polnej Róży,02-Apr'],
    ['21.0484298', '52.14456127', 'Warszawa,02-776,Wasilkowskiego J.,1A'],
    ['21.03806757', '52.15142011', 'Warszawa,02-776,Malinowskiego E.,6'],
    ['21.02461612', '52.15259464', 'Warszawa,02-784,Dunikowskiego X.,20'],
    ['21.0570075', '52.15074701', 'Warszawa,02-778,Grzegorzewskiej M.,6'],
    ['20.99416738', '52.15536945', 'Warszawa,02-823,Osmańska,9'],
    ['21.05604068', '52.15991935', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,54B'],
    ['21.07158915', '52.13055782', 'Warszawa,02-796,Zaruby J.,13'],
    ['21.01327829', '52.10551086', 'Warszawa,02-882,Katarynki,24'],
    ['21.06641563', '52.13987794', 'Warszawa,02-793,Lokajskiego E.,12'],
    ['21.0519069', '52.13741809', 'Warszawa,02-793,Małej Łąki,19'],
    ['21.05371706', '52.15056366', 'Warszawa,02-777,Szolc-Rogozińskiego,6'],
    ['20.99262344', '52.16832473', 'Warszawa,02-699,Kłobucka,23D'],
    ['21.0068084', '52.12384909', 'Warszawa,02-867,Baletowa,30'],
    ['21.05340983', '52.13743376', 'Warszawa,02-793,Małej Łąki,76'],
    ['21.02395389', '52.15883446', 'Warszawa,02-785,Puszczyka,8'],
    ['21.03848393', '52.16081174', 'Warszawa,02-786,Związku Walki Młodych,24'],
    ['21.01308157', '52.14275538', 'Warszawa,02-830,Rumby,19'],
    ['21.03621663', '52.15830987', 'Warszawa,02-786,Zamiany,8'],
    ['21.05266759', '52.13649843', 'Warszawa,02-793,Małej Łąki,9'],
    ['21.05389067', '52.1298094', 'Warszawa,02-835,Elegancka,8'],
    ['21.01477989', '52.13772355', 'Warszawa,02-830,Gawota,2'],
    ['21.0401534', '52.16836899', 'Warszawa,02-787,Fort Służew,1'],
    ['21.07440334', '52.13056119', 'Warszawa,02-796,Dembego S.,5'],
    ['21.07494563', '52.12985665', 'Warszawa,02-796,Dembego S.,3'],
    ['21.06507882', '52.132733', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,20A'],
    ['21.05885043', '52.1523873', 'Warszawa,02-797,Nowoursynowska,132'],
    ['21.06683828', '52.12755344', 'Warszawa,02-798,Kabacki Dukt,1'],
    ['21.02209655', '52.15059059', 'Warszawa,02-803,Bekasów,59'],
    ['21.05632022', '52.14641223', 'Warszawa,02-792,Lanciego F.,16'],
    ['21.06118341', '52.12985515', 'Warszawa,02-798,Ekologiczna,8'],
    ['21.03141818', '52.14921473', 'Warszawa,02-781,Czapli,13'],
    ['21.03028598', '52.1437267', 'Warszawa,02-811,Pustułeczki,8'],
    ['21.02275136', '52.15815555', 'Warszawa,02-785,Puszczyka,5'],
    ['21.05071657', '52.14903643', 'Warszawa,02-777,Kulczyńskiego S.,22'],
    ['21.0219324', '52.11104232', 'Warszawa,02-887,Karmazynowa,7N'],
    ['21.05293917', '52.14729504', 'Warszawa,02-777,Kulczyńskiego S.,8'],
    ['21.04810002', '52.13263142', 'Warszawa,02-798,Jaworowa,4'],
    ['21.04813385', '52.14766211', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,57'],
    ['21.04656279', '52.15501461', 'Warszawa,02-776,Strzeleckiego,8'],
    ['21.02694155', '52.16102447', 'Warszawa,02-785,Wiolinowa,12'],
    ['21.04688578', '52.13812903', 'Warszawa,02-795,Kazury S.,2B'],
    ['21.0409513', '52.14789099', 'Warszawa,02-776,Miklaszewskiego,2'],
    ['21.02806301', '52.13725191', 'Warszawa,02-815,Żołny,21'],
    ['21.05542289', '52.14275683', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,48'],
    ['21.02976276', '52.1640832', 'Warszawa,02-787,Koncertowa,6'],
    ['21.05080073', '52.14023214', 'Warszawa,02-791,Na Uboczu,6'],
    ['21.03463021', '52.16289972', 'Warszawa,02-787,Okaryny,1'],
    ['21.05231286', '52.13102389', 'Warszawa,02-833,Wełniana,27E'],
    ['21.04211403', '52.16946328', 'Warszawa,02-768,Fosa,41A'],
    ['21.03493537', '52.16687797', 'Warszawa,02-786,Al. Rodowicza Anody J.,3'],
    ['21.05093432', '52.14766656', 'Warszawa,02-777,Benedykta Polaka,5'],
    ['21.01745485', '52.127303', 'Warszawa,02-884,Puławska,424'],
    ['21.00356555', '52.15579175', 'Warszawa,02-822,Poleczki,40'],
    ['21.05985397', '52.15022313', 'Warszawa,02-776,Nowoursynowska,139R'],
    ['21.02259221', '52.16249391', 'Warszawa,02-785,Nutki,03-May'],
    ['21.05252334', '52.13282284', 'Warszawa,02-791,Stryjeńskich,15b'],
    ['21.02246583', '52.1296106', 'Warszawa,02-838,Kajakowa,11'],
    ['21.01881101', '52.16316499', 'Warszawa,02-781,Paszkiewicza L.,6'],
    ['21.06219835', '52.13446171', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,19'],
    ['21.00940601', '52.12131296', 'Warszawa,02-878,Głęboka,31'],
    ['21.05434512', '52.13991468', 'Warszawa,02-791,Meander,2A'],
    ['21.05815177', '52.13437375', 'Warszawa,02-793,Villardczyków,8'],
    ['21.04268768', '52.14899704', 'Warszawa,02-776,Hawajska,1'],
    ['21.04739726', '52.13262079', 'Warszawa,02-798,Jaworowa,11'],
    ['21.03819781', '52.15909114', 'Warszawa,02-786,Związku Walki Młodych,13'],
    ['21.02077055', '52.15590339', 'Warszawa,02-784,Romera E.,10'],
    ['21.07203753', '52.13241766', 'Warszawa,02-796,Wąwozowa,4'],
    ['21.03223827', '52.15793627', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,90'],
    ['21.05999289', '52.1411197', 'Warszawa,02-793,Belgradzka,18'],
    ['21.01158918', '52.13598389', 'Warszawa,02-830,Gawota,9L'],
    ['21.05741908', '52.14492132', 'Warszawa,02-792,Lanciego F.,10H'],
    ['21.00820589', '52.11003623', 'Warszawa,02-871,Karczunkowska,49'],
    ['21.02055789', '52.16402757', 'Warszawa,02-781,Zaolziańska,5'],
    ['20.99748729', '52.16515935', 'Warszawa,02-699,Taborowa,4'],
    ['21.0117515', '52.13477255', 'Warszawa,02-856,Ludwinowska,22-May'],
    ['21.00238072', '52.15479227', 'Warszawa,02-822,Poleczki,23'],
    ['21.04978052', '52.14025431', 'Warszawa,02-791,Na Uboczu,8'],
    ['21.02095832', '52.13818316', 'Warszawa,02-815,Żołny,57'],
    ['21.0453992', '52.14576876', 'Warszawa,02-776,Warchałowskiego E.,9'],
    ['21.02534561', '52.16301578', 'Warszawa,02-785,Koński Jar,3'],
    ['21.0572379', '52.1388498', 'Warszawa,02-793,Żabińskiego J.,16'],
    ['21.08239537', '52.12471352', 'Warszawa,02-797,Nowoursynowska,29A'],
    ['21.03621532', '52.10850747', 'Warszawa,02-892,Bażancia,8'],
    ['21.03400773', '52.16332206', 'Warszawa,02-787,Okaryny,1A'],
    ['21.03586975', '52.15020456', 'Warszawa,02-776,Dybowskiego B.,1'],
    ['21.047033', '52.165041', 'Warszawa,02-787,Nowoursynowska,166/3'],
    ['21.05434557', '52.15991135', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,56'],
    ['21.03964888', '52.15666107', 'Warszawa,02-786,Lachmana W.,6'],
    ['21.05790734', '52.12959369', 'Warszawa,02-798,Ekologiczna,15'],
    ['21.07276874', '52.13389151', 'Warszawa,02-796,Dembego S.,22A'],
    ['21.02988388', '52.1657769', 'Warszawa,02-787,Koncertowa,10'],
    ['21.03888288', '52.1525862', 'Warszawa,02-776,Hawajska,21'],
    ['21.06371714', '52.14072354', 'Warszawa,02-793,Lokajskiego E.,26'],
    ['21.04129764', '52.15698645', 'Warszawa,02-786,Lachmana W.,4'],
    ['21.01096154', '52.14504177', 'Warszawa,02-849,Krasnowolska,20D'],
    ['21.07233832', '52.13196582', 'Warszawa,02-796,Wąwozowa,9'],
    ['21.05558532', '52.12856519', 'Warszawa,02-886,Rybałtów,3'],
    ['21.01037113', '52.11965474', 'Warszawa,02-862,Farbiarska,7E'],
    ['21.05553017', '52.14631392', 'Warszawa,02-792,Lanciego F.,19'],
    ['20.99396577', '52.1673798', 'Warszawa,02-699,Kłobucka,18'],
    ['21.01713443', '52.13565465', 'Warszawa,02-819,Puławska,370'],
    ['21.06617273', '52.12918824', 'Warszawa,02-798,Kabacki Dukt,8'],
    ['21.039074', '52.163571', 'Warszawa,02-787,Nowoursynowska,161I'],
    ['21.02132292', '52.14647608', 'Warszawa,02-803,Bekasów,18'],
    ['21.02816281', '52.14978905', 'Warszawa,02-804,Gżegżółki,12'],
    ['21.01850205', '52.14177977', 'Warszawa,02-801,Puławska,431'],
    ['21.0521116', '52.13903521', 'Warszawa,02-791,Braci Wagów,1'],
    ['21.031737', '52.159714', 'Warszawa,02-787,Stokłosy,2'],
    ['21.02239526', '52.13273723', 'Warszawa,02-837,Bogatki,10A'],
    ['21.05275382', '52.14147778', 'Warszawa,02-791,Meander,13'],
    ['21.04513503', '52.14687937', 'Warszawa,02-776,Warchałowskiego E.,13'],
    ['21.0152755', '52.14360881', 'Warszawa,02-829,Taneczna,33'],
    ['21.07404243', '52.13630034', 'Warszawa,02-786,Rosoła J.,22B'],
    ['21.05710909', '52.14443065', 'Warszawa,02-792,Lanciego F.,11'],
    ['21.01564225', '52.14087305', 'Warszawa,02-829,Taneczna,18'],
    ['21.05845434', '52.14354054', 'Warszawa,02-792,Lanciego F.,10E'],
    ['21.02463776', '52.14377848', 'Warszawa,02-781,Roentgena W.,44'],
    ['21.09134128', '52.11663024', 'Warszawa,02-973,Maślaków,1'],
    ['21.04623184', '52.14350198', 'Warszawa,02-776,Warchałowskiego E.,1'],
    ['21.01854989', '52.13606965', 'Warszawa,02-844,Puławska,467'],
    ['21.01574993', '52.14962963', 'Warszawa,02-829,Samsonowska,4'],
    ['21.06639146', '52.12857467', 'Warszawa,02-798,Kabacki Dukt,5'],
    ['21.06161438', '52.12856116', 'Warszawa,02-798,Pod Lipą,1'],
    ['21.01333881', '52.13844622', 'Warszawa,02-830,Gawota,6A'],
    ['21.02329927', '52.13029508', 'Warszawa,02-838,Kajakowa,11B'],
    ['21.02718936', '52.14996103', 'Warszawa,02-808,Zięby,40A'],
    ['21.06038402', '52.15016731', 'Warszawa,02-776,Nowoursynowska,139'],
    ['21.02820966', '52.15006226', 'Warszawa,02-804,Gżegżółki,4'],
    ['21.05637888', '52.13249081', 'Warszawa,02-791,Stryjeńskich,6'],
    ['21.00034517', '52.14383233', 'Warszawa,02-850,Kądziołeczki,69'],
    ['20.99272971', '52.16705318', 'Warszawa,02-699,Kłobucka,21'],
    ['21.04901133', '52.14077479', 'Warszawa,02-791,Na Uboczu,12'],
    ['21.05863765', '52.14941574', 'Warszawa,02-796,Rosoła J.,55'],
    ['21.06795737', '52.13465054', 'Warszawa,02-796,Jeżewskiego K.,5E'],
    ['21.07742214', '52.12928699', 'Warszawa,02-796,Iwanowa-Szajnowicza J.,1'],
    ['21.03669164', '52.1426889', 'Warszawa,02-775,Alternatywy,1'],
    ['20.99217978', '52.16133751', 'Warszawa,02-699,Kłobucka,11'],
    ['21.00532734', '52.1546892', 'Warszawa,02-822,Poleczki,21'],
    ['21.01410823', '52.12419995', 'Warszawa,02-846,Sporna,14'],
    ['21.04187373', '52.17026488', 'Warszawa,02-768,Fosa,37'],
    ['21.03529669', '52.15791892', 'Warszawa,02-786,Zamiany,5'],
    ['21.05219734', '52.14200003', 'Warszawa,02-791,Meander,17'],
    ['21.06496559', '52.1391686', 'Warszawa,02-793,Lokajskiego E.,8'],
    ['21.01652694', '52.1241485', 'Warszawa,02-847,Klubowa,1E'],
    ['21.04992192', '52.14925203', 'Warszawa,02-777,Kulczyńskiego S.,24'],
    ['21.02991509', '52.13708671', 'Warszawa,02-815,Żołny,13'],
    ['21.06815263', '52.13163427', 'Warszawa,02-796,Wąwozowa,8'],
    ['21.00302793', '52.15772816', 'Warszawa,02-820,Łączyny,3'],
    ['21.06829265', '52.14087335', 'Warszawa,02-786,Rosoła J.,36'],
    ['21.04388989', '52.1453824', 'Warszawa,02-776,Dereniowa,6'],
    ['21.0598501', '52.15523894', 'Warszawa,02-797,Kokosowa,40'],
    ['20.99615738', '52.16238179', 'Warszawa,02-699,Pieskowa Skała,9'],
    ['21.06084964', '52.12947212', 'Warszawa,02-798,Mielczarskiego R.,10'],
    ['20.99422515', '52.16634418', 'Warszawa,02-699,Kłobucka,18D'],
    ['21.04228594', '52.1504629', 'Warszawa,02-776,Hawajska,8'],
    ['21.05172998', '52.13098237', 'Warszawa,02-833,Wełniana,31F'],
    ['21.01031685', '52.11577137', 'Warszawa,02-868,Sarabandy,54A'],
    ['21.01764324', '52.12026035', 'Warszawa,02-884,Puławska,466'],
    ['21.02597327', '52.16221569', 'Warszawa,02-785,Koński Jar,1'],
    ['21.01161572', '52.13396852', 'Warszawa,02-856,Ludwinowska,17'],
    ['21.02043208', '52.15479621', 'Warszawa,02-784,Janowskiego A.,13'],
    ['21.065719', '52.137435', 'Warszawa,02-793,Przy Bażantarni,7'],
    ['21.04696377', '52.14592653', 'Warszawa,02-778,Szczuki,2'],
    ['21.01576672', '52.14249941', 'Warszawa,02-829,Taneczna,30'],
    ['21.00622726', '52.15523577', 'Warszawa,02-820,Wyczółki,29'],
    ['21.06041302', '52.15441982', 'Warszawa,02-797,Kokosowa,51'],
    ['21.06115922', '52.13046299', 'Warszawa,02-798,Lisi Jar,6'],
    ['21.00299291', '52.1579343', 'Warszawa,02-820,Łączyny,4'],
    ['20.99450662', '52.16238779', 'Warszawa,02-699,Jurajska,4'],
    ['21.06336995', '52.12850745', 'Warszawa,02-798,Polnej Róży,1'],
    ['21.05384719', '52.15438408', 'Warszawa,02-786,Rosoła J.,58'],
    ['21.02563998', '52.15079014', 'Warszawa,02-781,Pileckiego W.,67'],
    ['21.0520099', '52.14669254', 'Warszawa,02-777,Benedykta Polaka,1'],
    ['21.06367229', '52.1285651', 'Warszawa,02-798,Polnej Róży,6'],
    ['20.9943544', '52.16861915', 'Warszawa,02-699,Kłobucka,23C'],
    ['21.046438', '52.160913', 'Warszawa,02-787,Nowoursynowska,161/33'],
    ['21.03852013', '52.15995084', 'Warszawa,02-786,Związku Walki Młodych,18'],
    ['21.04498163', '52.14300984', 'Warszawa,02-774,Dereniowa,2'],
    ['21.04547763', '52.15131613', 'Warszawa,02-777,Polinezyjska,4'],
    ['21.06126653', '52.14546266', 'Warszawa,02-796,Mandarynki,12'],
    ['21.05575386', '52.14431555', 'Warszawa,02-796,Migdałowa,10'],
    ['21.02793738', '52.14309854', 'Warszawa,02-781,Roentgena W.,43K'],
    ['21.04857857', '52.14634046', 'Warszawa,02-776,Wasilkowskiego J.,8'],
    ['21.048486', '52.165065', 'Warszawa,02-787,Nowoursynowska,166/5'],
    ['21.01718438', '52.14415084', 'Warszawa,02-845,Puławska,320'],
    ['21.06226149', '52.13095222', 'Warszawa,02-796,Wąwozowa,23'],
    ['21.06023089', '52.13596592', 'Warszawa,02-793,Przy Bażantarni,11'],
    ['21.03519145', '52.15192409', 'Warszawa,02-784,Kardasia K. Orkana,2'],
    ['21.02402952', '52.10992449', 'Warszawa,02-891,Jaskółcza,4'],
    ['21.02185514', '52.12960895', 'Warszawa,02-838,Kajakowa,15'],
    ['21.03208677', '52.16149875', 'Warszawa,02-787,Wokalna,4'],
    ['20.99487261', '52.16317687', 'Warszawa,02-699,Jurajska,3A'],
    ['21.02068495', '52.15882975', 'Warszawa,02-785,Surowieckiego W.,10'],
    ['21.01168248', '52.14431979', 'Warszawa,02-849,Krasnowolska,15'],
    ['21.03342354', '52.15871995', 'Warszawa,02-787,Pasaż Stokłosy,3'],
    ['21.05803563', '52.13709319', 'Warszawa,02-793,Żabińskiego J.,8'],
    ['21.01914979', '52.1504642', 'Warszawa,02-806,Rybitwy,8'],
    ['21.05368507', '52.13549564', 'Warszawa,02-793,Małej Łąki,1'],
    ['21.06333929', '52.1290997', 'Warszawa,02-798,Polnej Róży,9'],
    ['21.00165738', '52.14475681', 'Warszawa,02-850,Kądziołeczki,52K'],
    ['21.039699', '52.13838184', 'Warszawa,02-781,Pileckiego W.,110'],
    ['21.01418297', '52.13023535', 'Warszawa,02-862,Muzyczna,34'],
    ['21.05186907', '52.14728455', 'Warszawa,02-777,Benedykta Polaka,3'],
    ['21.02006637', '52.14762986', 'Warszawa,02-807,Bociania,19'],
    ['21.07586545', '52.12955434', 'Warszawa,02-796,Dembego S.,8'],
    ['21.04828683', '52.14388802', 'Warszawa,02-778,Płaskowickiej F.,22'],
    ['21.02738605', '52.15975606', 'Warszawa,02-785,Wiolinowa,8'],
    ['21.05974391', '52.13093744', 'Warszawa,02-796,Wąwozowa,27'],
    ['21.06140629', '52.14117716', 'Warszawa,02-793,Belgradzka,12'],
    ['21.03493593', '52.15441597', 'Warszawa,02-784,Pasaż Ursynowski,3'],
    ['21.04417522', '52.15176744', 'Warszawa,02-777,Polinezyjska,3'],
    ['21.02383558', '52.14755664', 'Warszawa,02-808,Zięby,4'],
    ['21.02676341', '52.15667097', 'Warszawa,02-784,Pięciolinii,2'],
    ['21.0206432', '52.16081845', 'Warszawa,02-785,Surowieckiego W.,4'],
    ['21.03025765', '52.14856496', 'Warszawa,02-781,Św. M. Kolbego,14'],
    ['21.04515282', '52.14100886', 'Warszawa,02-795,Kazury S.,10'],
    ['21.06531161', '52.13249141', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,20'],
    ['21.03526939', '52.16119526', 'Warszawa,02-786,Zamiany,16'],
    ['21.02701265', '52.15245087', 'Warszawa,02-784,Dunikowskiego X.,7'],
    ['21.04946505', '52.14270753', 'Warszawa,02-791,Braci Wagów,13A'],
    ['21.022932', '52.14450779', 'Warszawa,02-811,Pustułeczki,28'],
    ['21.06772701', '52.14141205', 'Warszawa,02-786,Rosoła J.,40'],
    ['21.0593965', '52.14445538', 'Warszawa,02-792,Lasek Brzozowy,15'],
    ['21.01131799', '52.12933585', 'Warszawa,02-862,Farbiarska,69'],
    ['21.06383573', '52.14695', 'Warszawa,02-790,Pachnąca,63'],
    ['21.05769332', '52.15290395', 'Warszawa,02-776,Nowoursynowska,145F'],
    ['21.06418268', '52.13093314', 'Warszawa,02-796,Wąwozowa,19'],
    ['21.0418301', '52.14307426', 'Warszawa,02-776,Hirszfelda L.,6'],
    ['21.05965209', '52.13365668', 'Warszawa,02-793,Boglarczyków,17'],
    ['21.01358852', '52.13307845', 'Warszawa,02-856,Ludwinowska,19B'],
    ['21.05584595', '52.15868803', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,28'],
    ['21.02060526', '52.16215185', 'Warszawa,02-781,Zaolziańska,1'],
    ['21.0294498', '52.16145798', 'Warszawa,02-787,Pasaż Stokłosy,11'],
    ['21.0130653', '52.10709037', 'Warszawa,02-881,Pozytywki,13'],
    ['21.03570588', '52.14881551', 'Warszawa,02-776,Amundsena R.,3'],
    ['21.06119988', '52.12816171', 'Warszawa,02-798,Pod Lipą,2'],
    ['21.05015081', '52.14634348', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,53'],
    ['21.03303903', '52.1542693', 'Warszawa,02-784,Dembowskiego E.,2'],
    ['21.05477549', '52.12980188', 'Warszawa,02-835,Nowoczesna,1'],
    ['21.01972853', '52.16442529', 'Warszawa,02-781,Zawadowskiego W.,4'],
    ['21.020549', '52.12669153', 'Warszawa,02-842,Maryli,17'],
    ['20.99290911', '52.16673403', 'Warszawa,02-699,Kłobucka,14'],
    ['21.04211282', '52.16368793', 'Warszawa,02-787,Nowoursynowska,161E'],
    ['21.01865892', '52.13334105', 'Warszawa,02-844,Puławska,481'],
    ['21.0166752', '52.13144578', 'Warszawa,02-858,Transportowców,10C'],
    ['21.0551911', '52.14832762', 'Warszawa,02-777,Kulczyńskiego S.,14'],
    ['21.06180097', '52.14355369', 'Warszawa,02-792,Lasek Brzozowy,12'],
    ['21.0593368', '52.14518543', 'Warszawa,02-792,Lasek Brzozowy,16'],
    ['21.06489468', '52.13051114', 'Warszawa,02-798,Kabacki Dukt,18'],
    ['21.0540681', '52.14401127', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,52'],
    ['21.0365137', '52.14513808', 'Warszawa,02-781,Pileckiego W.,101'],
    ['21.02516803', '52.14514747', 'Warszawa,02-811,Makolągwy,12'],
    ['21.03897259', '52.14721301', 'Warszawa,02-776,Marco Polo,1'],
    ['21.01431314', '52.1427366', 'Warszawa,02-830,Samby,10'],
    ['21.04436298', '52.14417764', 'Warszawa,02-776,Dereniowa,2C'],
    ['21.01138303', '52.14468625', 'Warszawa,02-849,Krasnowolska,20'],
    ['21.03531333', '52.1602433', 'Warszawa,02-786,Zamiany,11'],
    ['21.06965362', '52.13505662', 'Warszawa,02-796,Jeżewskiego K.,5C'],
    ['21.06301901', '52.14158337', 'Warszawa,02-793,Belgradzka,8'],
    ['21.04519326', '52.14073067', 'Warszawa,02-795,Kazury S.,8'],
    ['21.01267463', '52.137971', 'Warszawa,02-830,Gawota,6B'],
    ['21.07066882', '52.13148564', 'Warszawa,02-796,Wąwozowa,11'],
    ['21.04706528', '52.14101745', 'Warszawa,02-791,Na Uboczu,26'],
    ['21.01274588', '52.15448516', 'Warszawa,02-822,Poleczki,12'],
    ['20.99113846', '52.1089177', 'Warszawa,02-873,Kurantów,26'],
    ['21.05859152', '52.14699401', 'Warszawa,02-796,Migdałowa,4'],
    ['21.03765903', '52.14172106', 'Warszawa,02-781,Pileckiego W.,111'],
    ['21.03560312', '52.14295938', 'Warszawa,02-775,Alternatywy,4'],
    ['21.06334978', '52.13227093', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,11'],
    ['21.01868167', '52.13358653', 'Warszawa,02-844,Puławska,479'],
    ['21.06516825', '52.13743803', 'Warszawa,02-793,Przy Bażantarni,3'],
    ['21.05841649', '52.1443915', 'Warszawa,02-792,Lanciego F.,10A'],
    ['21.05681474', '52.13673644', 'Warszawa,02-793,Żabińskiego J.,3'],
    ['21.03976908', '52.15089003', 'Warszawa,02-776,Hawajska,13'],
    ['21.04823088', '52.1469235', 'Warszawa,02-776,Wasilkowskiego J.,10'],
    ['21.00639684', '52.15179793', 'Warszawa,02-826,Poloneza,89'],
    ['21.02515948', '52.13675678', 'Warszawa,02-818,Kolibrów,11A'],
    ['21.01141136', '52.11058727', 'Warszawa,02-871,Karczunkowska,40'],
    ['21.01789513', '52.11492202', 'Warszawa,02-884,Puławska,502'],
    ['21.05325139', '52.13685539', 'Warszawa,02-793,Małej Łąki,15'],
    ['21.01495681', '52.15104172', 'Warszawa,02-829,Taneczna,71'],
    ['21.05911753', '52.14086547', 'Warszawa,02-793,Belgradzka,20'],
    ['21.04032839', '52.13802073', 'Warszawa,02-781,Pileckiego W.,114'],
    ['21.04878894', '52.14494139', 'Warszawa,02-776,Wasilkowskiego J.,3'],
    ['21.01988928', '52.15142684', 'Warszawa,02-804,Kraski,53C'],
    ['21.04182772', '52.16979493', 'Warszawa,02-768,Fosa,41'],
    ['21.02299335', '52.15859393', 'Warszawa,02-785,Puszczyka,10'],
    ['21.06927084', '52.13564626', 'Warszawa,02-796,Jeżewskiego K.,5'],
    ['21.04469117', '52.14333042', 'Warszawa,02-776,Dereniowa,2A'],
    ['21.02057122', '52.16272768', 'Warszawa,02-781,Zaolziańska,3'],
    ['21.05554263', '52.13659766', 'Warszawa,02-793,Małcużyńskiego W.,4'],
    ['21.05983448', '52.15082166', 'Warszawa,02-776,Nowoursynowska,139K'],
    ['21.07123713', '52.1345703', 'Warszawa,02-796,Dembego S.,23A'],
    ['21.07138532', '52.13447893', 'Warszawa,02-796,Dembego S.,23'],
    ['21.05279148', '52.14214926', 'Warszawa,02-791,Meander,22'],
    ['21.01549291', '52.15197388', 'Warszawa,02-829,Taneczna,82'],
    ['21.05370515', '52.15574432', 'Warszawa,02-776,Nugat,5'],
    ['21.05323291', '52.13924547', 'Warszawa,02-791,Braci Wagów,2'],
    ['20.98136456', '52.1306046', 'Warszawa,02-867,Baletowa,177B'],
    ['21.01841735', '52.14511667', 'Warszawa,02-801,Puławska,411'],
    ['21.06424627', '52.14023348', 'Warszawa,02-793,Lokajskiego E.,20'],
    ['21.02971424', '52.15213691', 'Warszawa,02-784,Dembowskiego E.,14'],
    ['21.07115491', '52.13578687', 'Warszawa,02-796,Dembego S.,30'],
    ['20.99554137', '52.15728366', 'Warszawa,02-820,Wyczółki,66'],
    ['21.04940301', '52.13439649', 'Warszawa,02-798,Jaworowa,44'],
    ['21.02106289', '52.14915391', 'Warszawa,02-807,Bociania,28'],
    ['21.03143757', '52.16261855', 'Warszawa,02-787,Symfonii,3'],
    ['21.07314664', '52.13459652', 'Warszawa,02-796,Rosoła J.,9'],
    ['21.03650609', '52.11180672', 'Warszawa,02-886,Jagielska,12'],
    ['21.05636013', '52.15722007', 'Warszawa,02-776,Nowoursynowska,162'],
    ['21.04329405', '52.14077711', 'Warszawa,02-795,Kazury S.,14'],
    ['21.02954691', '52.15990245', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,96'],
    ['21.07377752', '52.13659098', 'Warszawa,02-786,Rosoła J.,22A'],
    ['21.03268074', '52.10881665', 'Warszawa,02-892,Bażancia,18'],
    ['21.03688256', '52.15657181', 'Warszawa,02-786,Bacewiczówny G.,4'],
    ['20.99357429', '52.16814021', 'Warszawa,02-699,Kłobucka,23B'],
    ['21.02788887', '52.16043407', 'Warszawa,02-722,Al. Komisji Edukacji Narodowej,105'],
    ['21.01636091', '52.14496006', 'Warszawa,02-828,Olczyska,22'],
    ['21.03367115', '52.15612797', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,93'],
    ['21.06446125', '52.13880188', 'Warszawa,02-793,Przy Bażantarni,8D'],
    ['21.02000801', '52.13169303', 'Warszawa,02-838,Raniuszka,41'],
    ['21.06103218', '52.14514685', 'Warszawa,02-796,Mandarynki,1'],
    ['21.06157612', '52.14861816', 'Warszawa,02-790,Kpt. Pawlaczyka,1'],
    ['21.03684965', '52.15329196', 'Warszawa,02-784,Pasaż Ursynowski,1'],
    ['21.03299503', '52.15651463', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,95'],
    ['21.03746599', '52.16143989', 'Warszawa,02-786,Jastrzębowskiego W. B.,24'],
    ['21.02213187', '52.16216864', 'Warszawa,02-785,Nutki,1'],
    ['21.04446794', '52.14378914', 'Warszawa,02-776,Dereniowa,2B'],
    ['21.01164835', '52.11453292', 'Warszawa,02-868,Sarabandy,36'],
    ['21.05865776', '52.13752114', 'Warszawa,02-793,Żabińskiego J.,10'],
    ['21.03042139', '52.16142344', 'Warszawa,02-787,Wokalna,8'],
    ['21.03901974', '52.15161547', 'Warszawa,02-776,Hawajska,15'],
    ['21.05470845', '52.14995885', 'Warszawa,02-777,Cynamonowa,2'],
    ['21.03849595', '52.146995', 'Warszawa,02-776,Marco Polo,4'],
    ['21.01924642', '52.15617329', 'Warszawa,02-785,Puławska,303'],
    ['21.04043462', '52.14741732', 'Warszawa,02-776,Miklaszewskiego,1'],
    ['21.05256848', '52.140393', 'Warszawa,02-791,Meander,7'],
    ['21.04730249', '52.14213072', 'Warszawa,02-791,Na Uboczu,28B'],
    ['21.00196008', '52.15767503', 'Warszawa,02-820,Łączyny,5'],
    ['21.05275493', '52.13578821', 'Warszawa,02-793,Małej Łąki,3'],
    ['21.03471314', '52.16106678', 'Warszawa,02-786,Zamiany,15'],
    ['21.01867234', '52.13786934', 'Warszawa,02-844,Puławska,457'],
    ['21.02234336', '52.15895912', 'Warszawa,02-785,Puszczyka,11'],
    ['21.01235019', '52.14554461', 'Warszawa,02-849,Walczyka,31'],
    ['21.03953902', '52.13908465', 'Warszawa,02-781,Pileckiego W.,106'],
    ['21.06285286', '52.1430434', 'Warszawa,02-792,Lasek Brzozowy,6'],
    ['21.05970731', '52.150758', 'Warszawa,02-776,Nowoursynowska,139L'],
    ['21.05289143', '52.13344084', 'Warszawa,02-791,Stryjeńskich,15a'],
    ['21.064338', '52.131588', 'Warszawa,02-796,Wąwozowa,16'],
    ['21.0560513', '52.12838314', 'Warszawa,02-886,Rybałtów,12'],
    ['21.06518724', '52.13019259', 'Warszawa,02-798,Kabacki Dukt,16'],
    ['21.03570251', '52.14814791', 'Warszawa,02-781,Pileckiego W.,132'],
    ['21.036507', '52.16139998', 'Warszawa,02-786,Jastrzębowskiego W. B.,22'],
    ['21.0192274', '52.12431397', 'Warszawa,02-843,Marabuta,25'],
    ['20.9935716', '52.16645536', 'Warszawa,02-699,Kłobucka,16B'],
    ['21.0535311', '52.14182229', 'Warszawa,02-791,Meander,18'],
    ['21.05817881', '52.13810499', 'Warszawa,02-793,Żabińskiego J.,12'],
    ['20.99663316', '52.14373343', 'Warszawa,02-821,Hołubcowa,72'],
    ['21.01963929', '52.14665595', 'Warszawa,02-807,Bociania,13'],
    ['21.01939434', '52.16194233', 'Warszawa,02-781,Zaorskiego J.,2'],
    ['21.05243336', '52.15558705', 'Warszawa,02-776,Nugat,7'],
    ['21.06342663', '52.14103553', 'Warszawa,02-793,Lokajskiego E.,30'],
    ['21.04041644', '52.1510665', 'Warszawa,02-776,Hawajska,16'],
    ['21.07169799', '52.13466833', 'Warszawa,02-796,Dembego S.,24'],
    ['21.03374485', '52.15522697', 'Warszawa,02-784,Herbsta S.,1'],
    ['21.02123369', '52.15501825', 'Warszawa,02-784,Janowskiego A.,15'],
    ['21.00909655', '52.14339919', 'Warszawa,02-849,Krasnowolska,29A'],
    ['21.05545529', '52.1397776', 'Warszawa,02-793,Belgradzka,46'],
    ['21.06494645', '52.12989641', 'Warszawa,02-798,Za Łąkami,1'],
    ['21.03950739', '52.16430144', 'Warszawa,02-787,Chłapowskiego D.,5'],
    ['21.00637606', '52.15249925', 'Warszawa,02-826,Poloneza,93'],
    ['21.04784177', '52.15334568', 'Warszawa,02-777,Teligi L.,1'],
    ['21.025796', '52.16447948', 'Warszawa,02-785,Koński Jar,8'],
    ['21.05696734', '52.13852265', 'Warszawa,02-793,Żabińskiego J.,9'],
    ['21.05209673', '52.15113256', 'Warszawa,02-777,Szolc-Rogozińskiego,7'],
    ['21.06026731', '52.14078992', 'Warszawa,02-793,Belgradzka,14'],
    ['21.022804', '52.109191', 'Warszawa,02-892,Bażancia,52'],
    ['21.02338114', '52.10652188', 'Warszawa,02-892,Kuropatwy,34D'],
    ['21.05619327', '52.13418455', 'Warszawa,02-793,Telekiego Pala,12'],
    ['21.02639984', '52.15710105', 'Warszawa,02-784,Pięciolinii,4'],
    ['21.05135448', '52.14210538', 'Warszawa,02-791,Meander,19'],
    ['21.06589764', '52.12897115', 'Warszawa,02-798,Kabacki Dukt,7'],
    ['21.06886494', '52.14026351', 'Warszawa,02-786,Rosoła J.,32'],
    ['21.01223336', '52.1512442', 'Warszawa,02-824,Wodzirejów,9A'],
    ['21.07795387', '52.13127025', 'Warszawa,02-796,Relaksowa,14'],
    ['21.06495434', '52.13093209', 'Warszawa,02-796,Wąwozowa,17'],
    ['21.06118373', '52.13764902', 'Warszawa,02-793,Raabego H.,3'],
    ['21.06571019', '52.12973111', 'Warszawa,02-798,Kabacki Dukt,14'],
    ['20.99265596', '52.16222593', 'Warszawa,02-699,Kłobucka,8'],
    ['21.04187313', '52.14377403', 'Warszawa,02-776,Hirszfelda L.,7'],
    ['21.03162756', '52.16523474', 'Warszawa,02-787,Koncertowa,8A'],
    ['21.01445616', '52.12160367', 'Warszawa,02-878,Gajdy,48'],
    ['21.01521021', '52.13493603', 'Warszawa,02-857,Dźwiękowa,10'],
    ['21.06469226', '52.13314284', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,22'],
    ['21.0340911', '52.15145356', 'Warszawa,02-784,Służby Polsce,1'],
    ['21.03302393', '52.15828993', 'Warszawa,02-787,Pasaż Stokłosy,1'],
    ['21.02001228', '52.13812632', 'Warszawa,02-816,Rajskich Ptaków,64'],
    ['21.04940764', '52.15123161', 'Warszawa,02-777,Szolc-Rogozińskiego,15'],
    ['21.05459209', '52.15486369', 'Warszawa,02-776,Nowoursynowska,153B'],
    ['21.02123355', '52.15442255', 'Warszawa,02-784,Janowskiego A.,54'],
    ['21.05326832', '52.15811778', 'Warszawa,02-777,Przybylskiego Cz.,9'],
    ['21.01892226', '52.1575741', 'Warszawa,02-785,Surowieckiego W.,12B'],
    ['21.06661047', '52.14309342', 'Warszawa,02-790,Sengera Cichego M.,3'],
    ['21.02894938', '52.15850039', 'Warszawa,02-785,Wiolinowa,2'],
    ['21.03475578', '52.16354974', 'Warszawa,02-787,Okaryny,1B'],
    ['21.02413318', '52.11570254', 'Warszawa,02-886,Jagielska,42'],
    ['21.04898779', '52.14593223', 'Warszawa,02-776,Wasilkowskiego J.,6'],
    ['21.0599445', '52.14265693', 'Warszawa,02-792,Lanciego F.,6'],
    ['21.06682071', '52.13862332', 'Warszawa,02-793,Przy Bażantarni,8'],
    ['21.0113108', '52.10919561', 'Warszawa,02-881,Pozytywki,27'],
    ['21.0411393', '52.150162', 'Warszawa,02-776,Hawajska,12'],
    ['21.03741535', '52.15694177', 'Warszawa,02-786,Bacewiczówny G.,2'],
    ['21.03563903', '52.16063115', 'Warszawa,02-786,Zamiany,14'],
    ['21.01564604', '52.11292419', 'Warszawa,02-879,Chóralna,14'],
    ['21.05229472', '52.15094001', 'Warszawa,02-777,Szolc-Rogozińskiego,5'],
    ['21.03170879', '52.1475483', 'Warszawa,02-781,Roentgena W.,5'],
    ['21.06313672', '52.14910839', 'Warszawa,02-797,Nowoursynowska,92/100'],
    ['21.04614895', '52.15247318', 'Warszawa,02-777,Teligi L.,5'],
    ['21.04194466', '52.14648774', 'Warszawa,02-776,Hirszfelda L.,18'],
    ['21.02016469', '52.12668518', 'Warszawa,02-842,Maryli,19'],
    ['21.05222562', '52.14098655', 'Warszawa,02-791,Meander,9'],
    ['20.99424889', '52.1143361', 'Warszawa,02-869,Dawidowska,58E'],
    ['21.00253583', '52.12271475', 'Warszawa,02-867,Baletowa,49'],
    ['21.03717071', '52.14109425', 'Warszawa,02-778,Płaskowickiej F.,40'],
    ['21.06830091', '52.13376299', 'Warszawa,02-796,Bronikowskiego K.,55'],
    ['21.01208653', '52.13802445', 'Warszawa,02-830,Gawota,6C'],
    ['21.06980011', '52.13345796', 'Warszawa,02-796,Bronikowskiego K.,3'],
    ['21.01117564', '52.14269317', 'Warszawa,02-830,Samby,24A'],
    ['21.04697108', '52.1477649', 'Warszawa,02-776,Wasilkowskiego J.,14'],
    ['20.99204123', '52.16021595', 'Warszawa,02-699,Kłobucka,5'],
    ['21.03731541', '52.14951287', 'Warszawa,02-776,Amundsena R.,1'],
    ['21.0548166', '52.1433178', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,50'],
    ['21.01249752', '52.15421886', 'Warszawa,02-822,Poleczki,12C'],
    ['21.05134391', '52.1553853', 'Warszawa,02-776,Nugat,9'],
    ['21.02658086', '52.15882195', 'Warszawa,02-785,Wiolinowa,5'],
    ['21.0535453', '52.13283203', 'Warszawa,02-791,Stryjeńskich,13C'],
    ['21.02482093', '52.15055723', 'Warszawa,02-804,Kraski,3'],
    ['21.06084603', '52.13316287', 'Warszawa,02-793,Płk. Baló Z.,1'],
    ['21.02890433', '52.16522147', 'Warszawa,02-787,Koncertowa,11'],
    ['21.05519875', '52.1602614', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,56A'],
    ['21.03075801', '52.14299301', 'Warszawa,02-811,Pustułeczki,9A'],
    ['21.05472703', '52.1475059', 'Warszawa,02-777,Kulczyńskiego S.,2'],
    ['21.0185658', '52.13722072', 'Warszawa,02-844,Puławska,465'],
    ['21.02334081', '52.15117521', 'Warszawa,02-781,Pileckiego W.,63'],
    ['21.0417843', '52.14417642', 'Warszawa,02-776,Hirszfelda L.,9'],
    ['21.06223394', '52.13781969', 'Warszawa,02-793,Raabego H.,6'],
    ['21.05262446', '52.15834111', 'Warszawa,02-777,Przybylskiego Cz.,8'],
    ['21.03936083', '52.14837416', 'Warszawa,02-776,Miklaszewskiego,6'],
    ['21.04608977', '52.15550643', 'Warszawa,02-776,Strzeleckiego,10F'],
    ['21.00266108', '52.11324968', 'Warszawa,02-873,Nawłocka,30'],
    ['21.02904413', '52.16404054', 'Warszawa,02-787,Koncertowa,7'],
    ['21.02657524', '52.14504282', 'Warszawa,02-781,Roentgena W.,23'],
    ['21.03227931', '52.15372608', 'Warszawa,02-784,Dembowskiego E.,4'],
    ['21.00906541', '52.14371476', 'Warszawa,02-849,Krasnowolska,32'],
    ['21.07302057', '52.13347605', 'Warszawa,02-796,Dembego S.,20A'],
    ['21.05396109', '52.14529745', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,56'],
    ['21.04895474', '52.14185009', 'Warszawa,02-791,Na Uboczu,18'],
    ['21.04470658', '52.15450119', 'Warszawa,02-777,Magellana F.,8'],
    ['20.99312294', '52.14148661', 'Warszawa,02-852,Gromowładna,7'],
    ['21.02327378', '52.11376304', 'Warszawa,02-886,Jagielska,55H'],
    ['21.05421709', '52.13501086', 'Warszawa,02-793,Małej Łąki,4'],
    ['21.04688436', '52.15356798', 'Warszawa,02-777,Magellana F.,2'],
    ['21.01949092', '52.15798456', 'Warszawa,02-785,Surowieckiego W.,12A'],
    ['21.05384451', '52.12920562', 'Warszawa,02-835,Elegancka,4'],
    ['21.01723008', '52.13583408', 'Warszawa,02-819,Puławska,368'],
    ['21.01925143', '52.14884031', 'Warszawa,02-806,Albatrosów,10'],
    ['21.06570969', '52.13210232', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,18'],
    ['21.02286942', '52.15520535', 'Warszawa,02-784,Cybisa J.,6'],
    ['21.02874596', '52.13756323', 'Warszawa,02-815,Żołny,24A'],
    ['21.01975949', '52.12194955', 'Warszawa,02-844,Puławska,557'],
    ['21.01954307', '52.14552187', 'Warszawa,02-801,Puławska,405A'],
    ['21.06102057', '52.13912125', 'Warszawa,02-793,Raabego H.,10'],
    ['21.03635675', '52.14895751', 'Warszawa,02-776,Miklaszewskiego,11'],
    ['21.06226507', '52.14306234', 'Warszawa,02-792,Lasek Brzozowy,8'],
    ['21.04642377', '52.1387637', 'Warszawa,02-795,Kazury S.,2D'],
    ['21.02468286', '52.15272192', 'Warszawa,02-784,Dunikowskiego X.,24'],
    ['21.05821389', '52.13096386', 'Warszawa,02-796,Wąwozowa,31'],
    ['21.02336685', '52.15243382', 'Warszawa,02-784,Arctowskiego H.,3'],
    ['21.06517824', '52.13404522', 'Warszawa,02-796,Wańkowicza M.,5B'],
    ['21.04797619', '52.14162229', 'Warszawa,02-791,Na Uboczu,20'],
    ['21.00253278', '52.15541946', 'Warszawa,02-822,Poleczki,29'],
    ['21.06303505', '52.1315612', 'Warszawa,02-796,Wąwozowa,20'],
    ['21.06312884', '52.13475931', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,26'],
    ['21.05265576', '52.1575895', 'Warszawa,02-777,Przybylskiego Cz.,11'],
    ['21.06507593', '52.13953648', 'Warszawa,02-793,Lokajskiego E.,10'],
    ['21.02687487', '52.1436805', 'Warszawa,02-811,Pustułeczki,23'],
    ['21.070161', '52.13293698', 'Warszawa,02-796,Bronikowskiego K.,1'],
    ['21.04283599', '52.16865107', 'Warszawa,02-776,Arkadowa,29'],
    ['21.03175381', '52.15606571', 'Warszawa,02-784,Pasaż Ursynowski,7'],
    ['21.02711964', '52.15496165', 'Warszawa,02-784,Sosnowskiego,6'],
    ['21.05301148', '52.14119545', 'Warszawa,02-791,Meander,11'],
    ['21.02277214', '52.13254478', 'Warszawa,02-837,Bogatki,8'],
    ['21.02252002', '52.16209284', 'Warszawa,02-785,Nutki,2'],
    ['21.01361722', '52.12969159', 'Warszawa,02-860,Czterech Wiatrów,65'],
    ['20.99453113', '52.1606199', 'Warszawa,02-699,Złoty Potok,4'],
    ['21.00192996', '52.12968364', 'Warszawa,02-846,Sporna,48'],
    ['21.05376431', '52.13683337', 'Warszawa,02-793,Małej Łąki,72'],
    ['21.02083295', '52.1472331', 'Warszawa,02-809,Mysikrólika,2A'],
    ['21.06695462', '52.14223674', 'Warszawa,02-786,Rosoła J.,44A'],
    ['21.0158585', '52.13406007', 'Warszawa,02-858,Transportowców,11'],
    ['21.02304475', '52.15913466', 'Warszawa,02-785,Puszczyka,12'],
    ['21.06401465', '52.13785709', 'Warszawa,02-793,Raabego H.,2A'],
    ['21.07216767', '52.13580469', 'Warszawa,02-796,Rosoła J.,15'],
    ['21.05582173', '52.13499033', 'Warszawa,02-793,Małej Łąki,12'],
    ['21.06098678', '52.13852365', 'Warszawa,02-793,Raabego H.,8'],
    ['21.05855742', '52.15202735', 'Warszawa,02-776,Nowoursynowska,145E'],
    ['21.01088924', '52.13849731', 'Warszawa,02-830,Gawota,8B'],
    ['21.01645192', '52.14867345', 'Warszawa,02-829,Giewont,35'],
    ['21.01417078', '52.14756398', 'Warszawa,02-829,Taneczna,59K'],
    ['21.02702345', '52.15929102', 'Warszawa,02-785,Wiolinowa,7'],
    ['21.02940313', '52.14874774', 'Warszawa,02-781,Łukaszczyka P.,34'],
    ['21.02229237', '52.1598725', 'Warszawa,02-785,Puszczyka,15'],
    ['21.05803096', '52.15593425', 'Warszawa,02-776,Bajaderki,1'],
    ['21.00203359', '52.1565886', 'Warszawa,02-822,Galopu,11'],
    ['21.07314752', '52.13654196', 'Warszawa,02-786,Rosoła J.,22H'],
    ['21.05810558', '52.15668477', 'Warszawa,02-776,Urwisko,4'],
    ['21.01991841', '52.11283623', 'Warszawa,02-887,Karmazynowa,36'],
    ['21.0278273', '52.15876807', 'Warszawa,02-785,Wiolinowa,1'],
    ['21.0417526', '52.14193562', 'Warszawa,02-776,Hirszfelda L.,2'],
    ['21.03862026', '52.16825189', 'Warszawa,02-745,Fort Służew,3'],
    ['21.04629395', '52.14406836', 'Warszawa,02-776,Warchałowskiego E.,2'],
    ['21.06353972', '52.13838772', 'Warszawa,02-793,Raabego H.,2C'],
    ['21.04743099', '52.13834365', 'Warszawa,02-795,Kazury S.,2C'],
    ['21.00094126', '52.11576121', 'Warszawa,02-850,Trombity,38'],
    ['21.06668054', '52.1281106', 'Warszawa,02-798,Kabacki Dukt,3'],
    ['21.01920087', '52.14169803', 'Warszawa,02-812,Ibisa,18'],
    ['21.0406827', '52.15053428', 'Warszawa,02-776,Hawajska,14'],
    ['21.01578826', '52.11810098', 'Warszawa,02-875,Kobzy,6'],
    ['21.02206835', '52.16118076', 'Warszawa,02-785,Puszczyka,20'],
    ['21.0316183', '52.1599246', 'Warszawa,02-787,Stokłosy,02-Apr'],
    ['21.04743811', '52.14726072', 'Warszawa,02-776,Wasilkowskiego J.,12'],
    ['21.05426446', '52.13826348', 'Warszawa,02-793,Małcużyńskiego W.,11'],
    ['21.0227139', '52.12326328', 'Warszawa,02-843,Pelikanów,10B'],
    ['21.0582973', '52.15675601', 'Warszawa,02-776,Urwisko,2'],
    ['21.05474665', '52.13268967', 'Warszawa,02-791,Stryjeńskich,13'],
    ['21.04418539', '52.1447622', 'Warszawa,02-776,Dereniowa,4'],
    ['21.00641995', '52.14161', 'Warszawa,02-826,Poloneza,50'],
    ['21.02382362', '52.14966753', 'Warszawa,02-803,Bekasów,60'],
    ['21.01473909', '52.13687834', 'Warszawa,02-830,Wędrowców,14'],
    ['20.99321538', '52.16756984', 'Warszawa,02-699,Kłobucka,23'],
    ['21.05760864', '52.13604153', 'Warszawa,02-793,Żabińskiego J.,6'],
    ['21.05834319', '52.14048081', 'Warszawa,02-793,Belgradzka,22'],
    ['21.05890703', '52.13160153', 'Warszawa,02-796,Wąwozowa,30'],
    ['21.04196783', '52.14727191', 'Warszawa,02-776,Gandhi I.,19'],
    ['21.02621793', '52.15528783', 'Warszawa,02-784,Cybisa J.,2'],
    ['21.0647361', '52.14654443', 'Warszawa,02-790,Pachnąca,29'],
    ['21.02885568', '52.16226213', 'Warszawa,02-787,Bartóka B.,8'],
    ['21.02858821', '52.16320905', 'Warszawa,02-787,Koncertowa,03-May'],
    ['21.01963158', '52.15038216', 'Warszawa,02-806,Rybitwy,6'],
    ['21.02443152', '52.14317386', 'Warszawa,02-781,Roentgena W.,46'],
    ['21.01289699', '52.12163357', 'Warszawa,02-862,Farbiarska,19'],
    ['21.03494469', '52.16174158', 'Warszawa,02-786,Zamiany,18'],
    ['21.01273918', '52.12262487', 'Warszawa,02-862,Farbiarska,25A'],
    ['21.02583784', '52.1519844', 'Warszawa,02-784,Dunikowskiego X.,17'],
    ['21.04632', '52.162014', 'Warszawa,02-787,Nowoursynowska,159'],
    ['21.02573429', '52.15017025', 'Warszawa,02-808,Zięby,40'],
    ['21.02066354', '52.12323416', 'Warszawa,02-843,Pelikanów,18'],
    ['21.06009113', '52.12902338', 'Warszawa,02-798,Mielczarskiego R.,3'],
    ['21.03114812', '52.14400945', 'Warszawa,02-811,Makolągwy,3'],
    ['21.06331392', '52.13093298', 'Warszawa,02-796,Wąwozowa,21'],
    ['21.05458607', '52.14105702', 'Warszawa,02-791,Meander,8'],
    ['21.05951439', '52.13462316', 'Warszawa,02-793,Villardczyków,4'],
    ['21.04165601', '52.17009319', 'Warszawa,02-768,Fosa,39'],
    ['21.00932715', '52.15472048', 'Warszawa,02-822,Poleczki,30'],
    ['21.06300298', '52.12964045', 'Warszawa,02-798,Ekologiczna,1'],
    ['20.99787831', '52.10949132', 'Warszawa,02-871,Karczunkowska,89'],
    ['21.00393804', '52.12319697', 'Warszawa,02-867,Baletowa,47'],
    ['21.01684768', '52.12624137', 'Warszawa,02-884,Puławska,430'],
    ['21.04984942', '52.15191013', 'Warszawa,02-777,Szolc-Rogozińskiego,19'],
    ['21.07137836', '52.13103365', 'Warszawa,02-796,Zaruby J.,15'],
    ['21.01652936', '52.14704126', 'Warszawa,02-829,Giewont,5'],
    ['21.02121654', '52.14694664', 'Warszawa,02-809,Mysikrólika,9'],
    ['21.08347613', '52.10530263', 'Warszawa,02-973,Prawdziwka,14X'],
    ['21.02863011', '52.13607423', 'Warszawa,02-818,Kanarkowa,6'],
    ['21.06206137', '52.12936621', 'Warszawa,02-798,Pod Lipą,8'],
    ['20.994349', '52.1668717', 'Warszawa,02-699,Kłobucka,18B'],
    ['21.01062122', '52.12151298', 'Warszawa,02-878,Głęboka,18'],
    ['21.01990317', '52.148316', 'Warszawa,02-806,Albatrosów,8H'],
    ['21.06969322', '52.13556776', 'Warszawa,02-796,Jeżewskiego K.,5A'],
    ['21.0445325', '52.15121789', 'Warszawa,02-777,Polinezyjska,1'],
    ['21.05146804', '52.12901302', 'Warszawa,02-835,Batystowa,10'],
    ['21.03991763', '52.15377654', 'Warszawa,02-777,Ciszewskiego J.,15'],
    ['21.02391989', '52.1282301', 'Warszawa,02-841,Kobuza,2A'],
    ['21.03578047', '52.15886779', 'Warszawa,02-786,Zamiany,10'],
    ['20.99307935', '52.16551472', 'Warszawa,02-699,Kłobucka,10A'],
    ['21.0229966', '52.14467803', 'Warszawa,02-811,Pustułeczki,28A'],
    ['21.01804153', '52.10706622', 'Warszawa,02-884,Puławska,558/560'],
    ['21.04399889', '52.17127846', 'Warszawa,02-768,Fosa,19A'],
    ['21.02462746', '52.10800143', 'Warszawa,02-892,Bażancia,43'],
    ['21.0552707', '52.15609526', 'Warszawa,02-776,Nugat,3'],
    ['21.05584328', '52.13296813', 'Warszawa,02-791,Stryjeńskich,8'],
    ['21.05631421', '52.14325262', 'Warszawa,02-792,Lanciego F.,9K'],
    ['21.05204777', '52.12915841', 'Warszawa,02-835,Batystowa,8A'],
    ['21.05524789', '52.15931663', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,40'],
    ['21.01472905', '52.11058683', 'Warszawa,02-871,Karczunkowska,19'],
    ['21.0627851', '52.13750342', 'Warszawa,02-793,Raabego H.,1'],
    ['21.05781463', '52.1304534', 'Warszawa,02-798,Ekologiczna,20'],
    ['21.03056416', '52.15676732', 'Warszawa,02-784,Pasaż Ursynowski,9'],
    ['21.05915799', '52.15426242', 'Warszawa,02-797,Modelowa,10'],
    ['21.04591169', '52.15270217', 'Warszawa,02-777,Teligi L.,8'],
    ['21.01255629', '52.1109498', 'Warszawa,02-871,Karczunkowska,30'],
    ['21.0157734', '52.12922839', 'Warszawa,02-860,Kotylion,1'],
    ['20.99829756', '52.15401947', 'Warszawa,02-854,Hołubcowa,123'],
    ['21.06013378', '52.12829686', 'Warszawa,02-798,Mielczarskiego R.,1'],
    ['21.04583026', '52.13976479', 'Warszawa,02-795,Kazury S.,4'],
    ['21.01593745', '52.12963755', 'Warszawa,02-860,Kotylion,4'],
    ['21.01129197', '52.14373693', 'Warszawa,02-849,Krasnowolska,17'],
    ['21.01551639', '52.14044127', 'Warszawa,02-829,Taneczna,18B'],
    ['21.04177919', '52.14491927', 'Warszawa,02-776,Hirszfelda L.,12'],
    ['21.01768113', '52.1229242', 'Warszawa,02-846,Sporna,1'],
    ['21.01832722', '52.15167769', 'Warszawa,02-801,Puławska,359'],
    ['21.02991373', '52.14368637', 'Warszawa,02-811,Pustułeczki,10'],
    ['21.06439439', '52.13971663', 'Warszawa,02-793,Lokajskiego E.,14'],
    ['21.039522', '52.162575', 'Warszawa,02-787,Nowoursynowska,161L'],
    ['21.03983223', '52.13966158', 'Warszawa,02-781,Pileckiego W.,104'],
    ['21.0626418', '52.14217177', 'Warszawa,02-792,Lasek Brzozowy,1'],
    ['21.03183406', '52.16109643', 'Warszawa,02-787,Wokalna,3'],
    ['21.0269842', '52.16055912', 'Warszawa,02-785,Wiolinowa,10'],
    ['20.99684849', '52.11749378', 'Warszawa,02-850,Trombity,56'],
    ['21.06853777', '52.14060463', 'Warszawa,02-786,Rosoła J.,34'],
    ['20.996128', '52.1637677', 'Warszawa,02-699,Pieskowa Skała,13'],
    ['21.00469124', '52.14336156', 'Warszawa,02-849,Krasnowolska,50'],
    ['20.9921046', '52.16315628', 'Warszawa,02-699,Kłobucka,13'],
    ['21.02584287', '52.137353', 'Warszawa,02-818,Kolibrów,17'],
    ['21.07739837', '52.13218677', 'Warszawa,02-796,Relaksowa,28A'],
    ['21.03041978', '52.1496806', 'Warszawa,02-781,Czapli,35'],
    ['21.0475087', '52.15813084', 'Warszawa,02-786,Ciszewskiego J.,8'],
    ['21.03155122', '52.11172792', 'Warszawa,02-889,Indycza,18'],
    ['21.02365896', '52.13169868', 'Warszawa,02-838,Raniuszka,7'],
    ['21.01880956', '52.12503063', 'Warszawa,02-844,Puławska,533'],
    ['21.03734924', '52.16228683', 'Warszawa,02-786,Al. Rodowicza Anody J.,6'],
    ['21.02725934', '52.14421891', 'Warszawa,02-811,Pustułeczki,18'],
    ['21.05532751', '52.15255361', 'Warszawa,02-796,Rosoła J.,61'],
    ['21.06652123', '52.13435202', 'Warszawa,02-796,Wańkowicza M.,4A'],
    ['21.06488965', '52.13433728', 'Warszawa,02-796,Wańkowicza M.,7'],
    ['21.01573412', '52.13674601', 'Warszawa,02-830,Wędrowców,6'],
    ['21.03440116', '52.15219008', 'Warszawa,02-784,Służby Polsce,3'],
    ['21.05164363', '52.14900405', 'Warszawa,02-777,Kulczyńskiego S.,18'],
    ['21.03124232', '52.16063755', 'Warszawa,02-787,Stokłosy,6'],
    ['21.05798614', '52.14416184', 'Warszawa,02-792,Lanciego F.,10F'],
    ['21.04666517', '52.15286695', 'Warszawa,02-777,Teligi L.,3'],
    ['21.01939966', '52.12943884', 'Warszawa,02-838,Kajakowa,25'],
    ['21.02647371', '52.1600792', 'Warszawa,02-785,Wiolinowa,11'],
    ['21.05778656', '52.1358399', 'Warszawa,02-793,Żabińskiego J.,2'],
    ['21.0241443', '52.10817667', 'Warszawa,02-892,Bażancia,45A'],
    ['21.06906925', '52.14157217', 'Warszawa,02-797,Nowoursynowska,101A'],
    ['21.04043805', '52.15020872', 'Warszawa,02-776,Hawajska,11'],
    ['21.01990015', '52.11896324', 'Warszawa,02-843,Tukana,3'],
    ['21.02808467', '52.15470738', 'Warszawa,02-784,Sosnowskiego,4'],
    ['21.0114742', '52.13493712', 'Warszawa,02-856,Ludwinowska,22-Jan'],
    ['20.99685596', '52.14009193', 'Warszawa,02-851,Puchalska,7'],
    ['21.00769181', '52.12131389', 'Warszawa,02-878,Głęboka,59'],
    ['21.01719709', '52.13607566', 'Warszawa,02-819,Puławska,366A'],
    ['21.05573668', '52.15879854', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,28B'],
    ['20.98946264', '52.16177016', 'Warszawa,02-699,Nowy Służewiec,16'],
    ['21.04553843', '52.1507345', 'Warszawa,02-777,Polinezyjska,2'],
    ['21.01982607', '52.15083032', 'Warszawa,02-806,Rybitwy,6A'],
    ['21.02844643', '52.15886477', 'Warszawa,02-785,Wiolinowa,4'],
    ['21.0159984', '52.12464601', 'Warszawa,02-847,Klubowa,3'],
    ['21.01457421', '52.11651221', 'Warszawa,02-876,Puzonistów,8'],
    ['21.05782423', '52.1299472', 'Warszawa,02-798,Ekologiczna,18'],
    ['21.02150678', '52.11596199', 'Warszawa,02-886,Jagielska,50'],
    ['21.0169237', '52.15127365', 'Warszawa,02-819,Puławska,276'],
    ['21.01864026', '52.13569697', 'Warszawa,02-844,Puławska,469'],
    ['21.06179296', '52.1349307', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,21'],
    ['20.99354917', '52.16703987', 'Warszawa,02-699,Kłobucka,16'],
    ['21.06185937', '52.14223409', 'Warszawa,02-792,Lasek Brzozowy,3'],
    ['21.04029724', '52.14801965', 'Warszawa,02-776,Miklaszewskiego,4'],
    ['21.01094759', '52.13022618', 'Warszawa,02-862,Farbiarska,71A'],
    ['21.04426381', '52.14073623', 'Warszawa,02-795,Kazury S.,12'],
    ['21.02153168', '52.15030819', 'Warszawa,02-807,Bociania,36'],
    ['21.01392861', '52.12250064', 'Warszawa,02-867,Baletowa,12'],
    ['21.01834048', '52.15107784', 'Warszawa,02-801,Puławska,361'],
    ['21.0365476', '52.15589979', 'Warszawa,02-786,Bacewiczówny G.,7'],
    ['21.01129806', '52.14414508', 'Warszawa,02-849,Krasnowolska,17A'],
    ['20.99395142', '52.15312941', 'Warszawa,02-823,Osmańska,5'],
    ['21.03581813', '52.16318217', 'Warszawa,02-787,Okaryny,3A'],
    ['20.99609871', '52.15696119', 'Warszawa,02-820,Wyczółki,97'],
    ['21.0612151', '52.14859755', 'Warszawa,02-790,Kpt. Pawlaczyka,6'],
    ['21.05463178', '52.13914945', 'Warszawa,02-793,Belgradzka,48'],
    ['20.99414848', '52.16712109', 'Warszawa,02-699,Kłobucka,18A'],
    ['21.02839769', '52.13730028', 'Warszawa,02-815,Żołny,19'],
    ['21.07457523', '52.13249231', 'Warszawa,02-796,Wąwozowa,1'],
    ['21.01709134', '52.14324538', 'Warszawa,02-845,Puławska,324'],
    ['21.044498', '52.157332', 'Warszawa,02-787,Nowoursynowska,161/28'],
    ['21.02223032', '52.15947034', 'Warszawa,02-785,Puszczyka,13'],
    ['21.02425733', '52.15665267', 'Warszawa,02-784,Pięciolinii,5'],
    ['20.99480917', '52.16259319', 'Warszawa,02-699,Jurajska,3'],
    ['21.05461741', '52.14147546', 'Warszawa,02-791,Meander,16'],
    ['21.050511', '52.16147', 'Warszawa,02-787,Nowoursynowska,164/17'],
    ['21.05430246', '52.15142954', 'Warszawa,02-778,Grzegorzewskiej M.,13'],
    ['21.02059942', '52.10794376', 'Warszawa,02-892,Bażancia,53'],
    ['21.02794432', '52.14577719', 'Warszawa,02-781,Roentgena W.,7'],
    ['21.01520356', '52.14205008', 'Warszawa,02-829,Taneczna,27'],
    ['21.00122353', '52.13577554', 'Warszawa,02-855,Sztajerka,9'],
    ['21.06689031', '52.14419656', 'Warszawa,02-790,Sotta K. Sokoła,7'],
    ['21.014207', '52.14330237', 'Warszawa,02-830,Lambady,10'],
    ['21.03052622', '52.14890513', 'Warszawa,02-804,Jastrzębia,1'],
    ['21.02583166', '52.11505276', 'Warszawa,02-886,Jagielska,49A/2'],
    ['21.04771593', '52.15372589', 'Warszawa,02-777,Teligi L.,4'],
    ['21.0550778', '52.133548', 'Warszawa,02-791,Stryjeńskich,10'],
    ['21.0344235', '52.14951314', 'Warszawa,02-776,Dybowskiego B.,7'],
    ['21.02073912', '52.1146028', 'Warszawa,02-886,Jagielska,63A/10'],
    ['21.05703941', '52.13355998', 'Warszawa,02-793,Telekiego Pala,10'],
    ['21.02443781', '52.15524033', 'Warszawa,02-784,Cybisa J.,4'],
    ['21.0433289', '52.15227414', 'Warszawa,02-777,Polinezyjska,7'],
    ['21.05876698', '52.15436148', 'Warszawa,02-797,Modelowa,15'],
    ['21.03965805', '52.15134006', 'Warszawa,02-776,Hawajska,18'],
    ['21.02891995', '52.15184708', 'Warszawa,02-784,Dembowskiego E.,16'],
    ['21.02250333', '52.1501662', 'Warszawa,02-803,Bekasów,74'],
    ['21.03598988', '52.15090774', 'Warszawa,02-776,Dereniowa,60'],
    ['21.04240373', '52.15700761', 'Warszawa,02-786,Lachmana W.,1'],
    ['21.07021962', '52.13086686', 'Warszawa,02-796,Zaruby J.,17'],
    ['21.06359696', '52.14608901', 'Warszawa,02-790,Pachnąca,83'],
    ['21.04384641', '52.15208185', 'Warszawa,02-777,Polinezyjska,5'],
    ['21.06055005', '52.14720856', 'Warszawa,02-796,Migdałowa,13'],
    ['21.01117769', '52.13183429', 'Warszawa,02-862,Farbiarska,73'],
    ['21.04029806', '52.15188258', 'Warszawa,02-776,Hawajska,18A'],
    ['21.06248501', '52.12984092', 'Warszawa,02-798,Ekologiczna,4'],
    ['21.0129414', '52.15504767', 'Warszawa,02-820,Wyczółki,13'],
    ['21.04600202', '52.15349398', 'Warszawa,02-777,Magellana F.,1'],
    ['21.045773', '52.155704', 'Warszawa,02-776,Strzeleckiego,10'],
    ['21.05450983', '52.13088929', 'Warszawa,02-833,Wełniana,5'],
    ['21.01381688', '52.14735162', 'Warszawa,02-829,Taneczna,59D'],
    ['21.01373894', '52.15342723', 'Warszawa,02-822,Poleczki,7'],
    ['21.04148099', '52.11113859', 'Warszawa,02-886,Jagielska,1B'],
    ['21.06491965', '52.1448577', 'Warszawa,02-790,Sengera Cichego M.,11'],
    ['21.06205637', '52.12858545', 'Warszawa,02-798,Pod Lipą,6'],
    ['21.04351911', '52.14921602', 'Warszawa,02-776,Hawajska,2'],
    ['21.05580053', '52.15132258', 'Warszawa,02-777,Cynamonowa,10'],
    ['21.02831004', '52.14944284', 'Warszawa,02-804,Jastrzębia,37'],
    ['21.02189133', '52.14683932', 'Warszawa,02-809,Mysikrólika,1A'],
    ['21.02023649', '52.14406177', 'Warszawa,02-812,Lelka,20'],
    ['21.05573', '52.136636', 'Warszawa,02-793,Małcużyńskiego W.,8'],
    ['21.02263352', '52.12983084', 'Warszawa,02-838,Kajakowa,8'],
    ['21.07400573', '52.13111594', 'Warszawa,02-796,Dembego S.,7'],
    ['20.99806912', '52.14357758', 'Warszawa,02-849,Krasnowolska,82J'],
    ['21.0451186', '52.15415333', 'Warszawa,02-777,Magellana F.,6'],
    ['21.05419174', '52.13308222', 'Warszawa,02-791,Stryjeńskich,13D'],
    ['21.050598', '52.12943384', 'Warszawa,02-798,Ustronie,5'],
    ['21.05644764', '52.15533334', 'Warszawa,02-776,Bajaderki,2'],
    ['21.05815259', '52.15481835', 'Warszawa,02-797,Letniskowa,24'],
    ['21.03968208', '52.11042247', 'Warszawa,02-886,Jagielska,3E'],
    ['21.02045021', '52.16482716', 'Warszawa,02-781,Zaolziańska,9'],
    ['21.01244282', '52.1185838', 'Warszawa,02-878,Czysta,3'],
    ['21.006302', '52.155517', 'Warszawa,02-820,Wyczółki,33A'],
    ['21.06230551', '52.14248051', 'Warszawa,02-792,Lasek Brzozowy,5'],
    ['21.04243359', '52.14998292', 'Warszawa,02-776,Hawajska,5'],
    ['21.02279798', '52.15406533', 'Warszawa,02-784,Cybisa J.,7'],
    ['21.02032483', '52.15074448', 'Warszawa,02-806,Rybitwy,4A'],
    ['21.0464935', '52.15417915', 'Warszawa,02-776,Strzeleckiego,2'],
    ['21.0132524', '52.13793277', 'Warszawa,02-830,Gawota,6'],
    ['21.04146279', '52.1399315', 'Warszawa,02-795,Kazury S.,18'],
    ['21.03876416', '52.1483968', 'Warszawa,02-776,Miklaszewskiego,5'],
    ['21.06037722', '52.14237239', 'Warszawa,02-792,Lanciego F.,4'],
    ['21.07639721', '52.1333629', 'Warszawa,02-786,Rosoła J.,8C'],
    ['21.01380006', '52.15068634', 'Warszawa,02-827,Menueta,10'],
    ['21.01744905', '52.1299269', 'Warszawa,02-845,Puławska,408'],
    ['20.99019435', '52.12527931', 'Warszawa,02-867,Baletowa,113'],
    ['21.01406312', '52.12173624', 'Warszawa,02-878,Gajdy,53'],
    ['20.99839687', '52.1568526', 'Warszawa,02-820,Wyczółki,89'],
    ['21.04383498', '52.16371346', 'Warszawa,02-787,Nowoursynowska,161D'],
    ['21.04848545', '52.13171505', 'Warszawa,02-833,Wełniana,47P'],
    ['21.03862102', '52.15571996', 'Warszawa,02-786,Związku Walki Młodych,3'],
    ['21.06376803', '52.13410312', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,24'],
    ['21.052523', '52.16019', 'Warszawa,02-776,Nowoursynowska,162/19'],
    ['21.02577353', '52.13797333', 'Warszawa,02-815,Żołny,34'],
    ['21.038428', '52.15648059', 'Warszawa,02-786,Związku Walki Młodych,5'],
    ['21.05866835', '52.13012641', 'Warszawa,02-798,Pod Strzechą,5'],
    ['21.05362202', '52.14076915', 'Warszawa,02-791,Meander,10'],
    ['21.06120389', '52.13156052', 'Warszawa,02-796,Wąwozowa,22'],
    ['21.02035486', '52.13312015', 'Warszawa,02-837,Puchaczy,33'],
    ['20.99364072', '52.15750174', 'Warszawa,02-820,Wyczółki,78A'],
    ['21.02984238', '52.15163866', 'Warszawa,02-784,Dembowskiego E.,13'],
    ['21.07147481', '52.13436467', 'Warszawa,02-796,Dembego S.,23C'],
    ['21.04065744', '52.15613598', 'Warszawa,02-786,Lachmana W.,7'],
    ['21.06184401', '52.14298862', 'Warszawa,02-792,Lasek Brzozowy,7'],
    ['21.05806229', '52.13291532', 'Warszawa,02-793,Płk. Baló Z.,8'],
    ['20.99616517', '52.16304095', 'Warszawa,02-699,Pieskowa Skała,11'],
    ['21.05026072', '52.14782418', 'Warszawa,02-777,Benedykta Polaka,7'],
    ['21.06479369', '52.14003006', 'Warszawa,02-793,Lokajskiego E.,16'],
    ['21.02930172', '52.14380728', 'Warszawa,02-811,Pustułeczki,12'],
    ['21.02049137', '52.14844649', 'Warszawa,02-807,Bociania,27'],
    ['21.05928399', '52.14759375', 'Warszawa,02-796,Migdałowa,2C'],
    ['21.04503974', '52.15223428', 'Warszawa,02-777,Polinezyjska,8'],
    ['21.02340797', '52.12696162', 'Warszawa,02-842,Maryli,4'],
    ['21.06604471', '52.14357344', 'Warszawa,02-790,Sengera Cichego M.,5'],
    ['21.02487776', '52.15725518', 'Warszawa,02-784,Pięciolinii,8'],
    ['21.0213483', '52.12327965', 'Warszawa,02-843,Pelikanów,16'],
    ['21.02257586', '52.13170488', 'Warszawa,02-838,Raniuszka,17'],
    ['21.03281928', '52.10692139', 'Warszawa,02-892,Kuropatwy,8E'],
    ['21.02037595', '52.11838624', 'Warszawa,02-843,Tukana,5B'],
    ['21.05862248', '52.14393552', 'Warszawa,02-792,Lanciego F.,10B'],
    ['21.05633761', '52.15914569', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,32C'],
    ['21.01705351', '52.11885747', 'Warszawa,02-878,Gajdy,24'],
    ['21.07892653', '52.13110563', 'Warszawa,02-796,Relaksowa,12B'],
    ['21.02373809', '52.1449104', 'Warszawa,02-811,Makolągwy,23H'],
    ['21.054907', '52.1453598', 'Warszawa,02-792,Lanciego F.,17'],
    ['21.05054163', '52.12993486', 'Warszawa,02-798,Ustronie,11'],
    ['21.06124537', '52.14360726', 'Warszawa,02-792,Lasek Brzozowy,9'],
    ['21.01885527', '52.1245356', 'Warszawa,02-844,Puławska,537'],
    ['21.05861367', '52.13659611', 'Warszawa,02-793,Żabińskiego J.,4'],
    ['21.0171204', '52.11415402', 'Warszawa,02-884,Puławska,506/508'],
    ['21.03760553', '52.16464598', 'Warszawa,02-787,Chłapowskiego D.,28'],
    ['21.0212707', '52.11819445', 'Warszawa,02-843,Tukana,7D'],
    ['21.06609092', '52.14388015', 'Warszawa,02-790,Sengera Cichego M.,10'],
    ['21.05820775', '52.12985134', 'Warszawa,02-798,Ekologiczna,16'],
    ['21.02283774', '52.12097376', 'Warszawa,02-814,Słonki,5'],
    ['21.03146683', '52.15290362', 'Warszawa,02-784,Dembowskiego E.,5'],
    ['21.08285295', '52.12562533', 'Warszawa,02-797,Nowoursynowska,26'],
    ['21.06565283', '52.13342053', 'Warszawa,02-796,Wańkowicza M.,5'],
    ['21.06308755', '52.14081571', 'Warszawa,02-793,Lokajskiego E.,28'],
    ['21.02878421', '52.15427665', 'Warszawa,02-784,Sosnowskiego,2'],
    ['21.0110357', '52.12507157', 'Warszawa,02-862,Farbiarska,33'],
    ['21.03546791', '52.15273581', 'Warszawa,02-784,Służby Polsce,7'],
    ['20.99844924', '52.14382553', 'Warszawa,02-849,Krasnowolska,82H/2'],
    ['21.01413098', '52.13808969', 'Warszawa,02-830,Gawota,4B'],
    ['20.99535062', '52.16434425', 'Warszawa,02-699,Pieskowa Skała,15'],
    ['21.0038798', '52.15164225', 'Warszawa,02-823,Polki,3'],
    ['21.02211005', '52.15021395', 'Warszawa,02-803,Bekasów,57'],
    ['21.01676158', '52.11598583', 'Warszawa,02-878,Gajdy,3'],
    ['21.03789277', '52.14230801', 'Warszawa,02-781,Pileckiego W.,107'],
    ['21.06054465', '52.13836706', 'Warszawa,02-793,Raabego H.,5'],
    ['21.05692819', '52.13141158', 'Warszawa,02-796,Wąwozowa,36'],
    ['20.99635477', '52.14035778', 'Warszawa,02-851,Kadryla,21'],
    ['20.99601767', '52.14105865', 'Warszawa,02-821,Hołubcowa,56'],
    ['21.06826488', '52.14199448', 'Warszawa,02-797,Nowoursynowska,105E'],
    ['21.05118483', '52.15709641', 'Warszawa,02-777,Kopcińskiego S.,14'],
    ['21.04846587', '52.13301419', 'Warszawa,02-798,Jaworowa,14'],
    ['21.00842161', '52.1241443', 'Warszawa,02-867,Baletowa,26'],
    ['21.0170914', '52.14547589', 'Warszawa,02-828,Magury,5'],
    ['21.00941706', '52.13186741', 'Warszawa,02-863,Jeziorki,86'],
    ['21.03726001', '52.14888623', 'Warszawa,02-776,Miklaszewskiego,14'],
    ['21.01070553', '52.15463379', 'Warszawa,02-822,Poleczki,22'],
    ['21.06480598', '52.13847254', 'Warszawa,02-793,Przy Bażantarni,8C'],
    ['21.01697077', '52.14123879', 'Warszawa,02-845,Puławska,338'],
    ['21.0189624', '52.12358733', 'Warszawa,02-843,Puławska,543'],
    ['21.04530379', '52.13939599', 'Warszawa,02-795,Kazury S.,5'],
    ['21.019512', '52.142999', 'Warszawa,02-801,Puławska,423'],
    ['21.06291439', '52.13787306', 'Warszawa,02-793,Raabego H.,4'],
    ['21.05484142', '52.1291909', 'Warszawa,02-835,Batystowa,2'],
    ['21.02803053', '52.13615802', 'Warszawa,02-818,Kanarkowa,8'],
    ['21.02052193', '52.15529883', 'Warszawa,02-784,Janowskiego A.,11'],
    ['21.03565414', '52.16361113', 'Warszawa,02-787,Okaryny,3B'],
    ['21.04226254', '52.14558918', 'Warszawa,02-776,Dereniowa,13'],
    ['21.0318563', '52.15896926', 'Warszawa,02-787,Stokłosy,7'],
    ['21.05539458', '52.15207077', 'Warszawa,02-796,Rosoła J.,61A'],
    ['21.04560437', '52.17405856', 'Warszawa,02-768,Fosa,3'],
    ['21.01893679', '52.12860668', 'Warszawa,02-840,Leśna,2'],
    ['21.05484541', '52.13873125', 'Warszawa,02-793,Belgradzka,21A'],
    ['21.01421257', '52.13040334', 'Warszawa,02-862,Muzyczna,36'],
    ['21.06684325', '52.13193888', 'Warszawa,02-796,Wańkowicza M.,1'],
    ['21.03085573', '52.15156929', 'Warszawa,02-784,Dembowskiego E.,11'],
    ['21.05038989', '52.15597447', 'Warszawa,02-776,Nugat,8'],
    ['21.06668408', '52.13523026', 'Warszawa,02-796,Jeżewskiego K.,11A'],
    ['21.03899103', '52.14311559', 'Warszawa,02-781,Pileckiego W.,122'],
    ['21.04928345', '52.10951121', 'Józefosław,05-500,Działkowa,25'],
    ['21.05680126', '52.15013886', 'Warszawa,02-778,Grzegorzewskiej M.,4'],
    ['21.05348173', '52.13490032', 'Warszawa,02-793,Małej Łąki,2'],
    ['21.05535231', '52.13166609', 'Warszawa,02-791,Stryjeńskich,1'],
    ['21.03880627', '52.16453594', 'Warszawa,02-787,Chłapowskiego D.,10'],
    ['21.03944009', '52.15209071', 'Warszawa,02-776,Hawajska,20'],
    ['20.99204892', '52.15988746', 'Warszawa,02-699,Kłobucka,3'],
    ['21.0226804', '52.12395806', 'Warszawa,02-843,Rejewskiego,17'],
    ['21.04624826', '52.15372268', 'Warszawa,02-777,Magellana F.,4'],
    ['21.02739624', '52.15338275', 'Warszawa,02-784,Dunikowskiego X.,6'],
    ['21.07179499', '52.13390104', 'Warszawa,02-796,Dembego S.,21'],
    ['21.01961056', '52.15013942', 'Warszawa,02-806,Rybitwy,11'],
    ['20.99204508', '52.16100445', 'Warszawa,02-699,Kłobucka,9'],
    ['21.01706775', '52.11828077', 'Warszawa,02-878,Gajdy,20'],
    ['21.05255283', '52.13745226', 'Warszawa,02-793,Małej Łąki,17'],
    ['21.0552619', '52.13562024', 'Warszawa,02-793,Małej Łąki,34'],
    ['21.01384243', '52.14568151', 'Warszawa,02-849,Walczyka,8'],
    ['21.07735744', '52.13408437', 'Warszawa,02-786,Rosoła J.,10A'],
    ['21.04838992', '52.15383569', 'Warszawa,02-777,Teligi L.,2'],
    ['21.01946083', '52.16298961', 'Warszawa,02-781,Paszkiewicza L.,1'],
    ['21.04501215', '52.1387365', 'Warszawa,02-795,Kazury S.,28'],
    ['21.05604043', '52.1291529', 'Warszawa,02-886,Rybałtów,14'],
    ['21.0322424', '52.10744257', 'Warszawa,02-892,Kuropatwy,8T'],
    ['21.04535724', '52.14445022', 'Warszawa,02-776,Warchałowskiego E.,7'],
    ['21.04406904', '52.15555822', 'Warszawa,02-777,Magellana F.,14'],
    ['21.0124894', '52.12479877', 'Warszawa,02-862,Farbiarska,39'],
    ['21.04369612', '52.14047458', 'Warszawa,02-795,Kazury S.,11'],
    ['21.03852285', '52.15207554', 'Warszawa,02-776,Hawajska,17'],
    ['21.01474854', '52.14735414', 'Warszawa,02-829,Taneczna,59B'],
    ['21.05853355', '52.15220952', 'Warszawa,02-776,Nowoursynowska,145'],
    ['21.01944893', '52.13837354', 'Warszawa,02-815,Żołny,63A'],
    ['21.0384611', '52.16455761', 'Warszawa,02-787,Chłapowskiego D.,14'],
    ['21.07553325', '52.12998344', 'Warszawa,02-796,Dembego S.,8B'],
    ['21.01447194', '52.11513924', 'Warszawa,02-875,Klarnecistów,2'],
    ['21.03455325', '52.16495575', 'Warszawa,02-787,Okaryny,15'],
    ['21.05201351', '52.1571813', 'Warszawa,02-777,Kopcińskiego S.,12'],
    ['21.05486223', '52.12837498', 'Warszawa,02-886,Rybałtów,9'],
    ['21.05142336', '52.13949747', 'Warszawa,02-791,Na Uboczu,4'],
    ['21.02215813', '52.15080082', 'Warszawa,02-803,Bekasów,63/65'],
    ['21.01392602', '52.14603172', 'Warszawa,02-828,Pląsy,17'],
    ['21.05123442', '52.12960152', 'Warszawa,02-798,Ustronie,15'],
    ['21.02020095', '52.15431556', 'Warszawa,02-784,Janowskiego A.,52'],
    ['21.05710323', '52.1309448', 'Warszawa,02-796,Wąwozowa,33'],
    ['21.02490768', '52.11530121', 'Warszawa,02-886,Jagielska,49'],
    ['21.03577866', '52.14260915', 'Warszawa,02-775,Alternatywy,3'],
    ['21.01739062', '52.13279621', 'Warszawa,02-845,Puławska,392'],
    ['21.06468706', '52.13463549', 'Warszawa,02-796,Wańkowicza M.,7A'],
    ['21.06461652', '52.14720938', 'Warszawa,02-790,Pachnąca,45'],
    ['21.0674782', '52.14168685', 'Warszawa,02-786,Rosoła J.,42'],
    ['21.01836369', '52.14905741', 'Warszawa,02-801,Puławska,381'],
    ['21.03300049', '52.15748357', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,88'],
    ['21.04329817', '52.14281635', 'Warszawa,02-776,Dereniowa,3'],
    ['21.00938033', '52.1463094', 'Warszawa,02-828,Pląsy,22A'],
    ['21.07312846', '52.13283389', 'Warszawa,02-796,Dembego S.,18'],
    ['21.05234601', '52.12877517', 'Warszawa,02-835,Batystowa,6'],
    ['21.03924431', '52.15877212', 'Warszawa,02-786,Związku Walki Młodych,12'],
    ['21.07033657', '52.13579072', 'Warszawa,02-796,Jeżewskiego K.,3'],
    ['21.03592728', '52.1449424', 'Warszawa,02-775,Polskie Drogi,6'],
    ['20.98513568', '52.14167876', 'Warszawa,02-852,Czempińska,7'],
    ['21.03303299', '52.14567172', 'Warszawa,02-776,Gandhi I.,14'],
    ['21.01155923', '52.12978872', 'Warszawa,02-862,Farbiarska,42H'],
    ['21.02565463', '52.15249379', 'Warszawa,02-784,Dunikowskiego X.,12'],
    ['21.0439915', '52.15270847', 'Warszawa,02-777,Polinezyjska,12'],
    ['21.01074614', '52.15472034', 'Warszawa,02-822,Poleczki,22A'],
    ['21.06650728', '52.13534921', 'Warszawa,02-796,Jeżewskiego K.,11'],
    ['21.03874929', '52.15238664', 'Warszawa,02-776,Hawajska,19'],
    ['21.02091416', '52.15327905', 'Warszawa,02-784,Janowskiego A.,22'],
    ['21.00461598', '52.15075485', 'Warszawa,02-823,Polki,1C'],
    ['21.05154076', '52.13380758', 'Warszawa,02-791,Stryjeńskich,15J'],
    ['21.0399194', '52.15545309', 'Warszawa,02-786,Związku Walki Młodych,4'],
    ['21.04930516', '52.14906142', 'Warszawa,02-777,Kulczyńskiego S.,9'],
    ['21.01510283', '52.12669745', 'Warszawa,02-847,Kostki Napierskiego A.,10B'],
    ['21.05756934', '52.13777217', 'Warszawa,02-793,Żabińskiego J.,7'],
    ['21.06283942', '52.14471375', 'Warszawa,02-796,Mandarynki,6'],
    ['21.01290977', '52.13611382', 'Warszawa,02-857,Organistów,19'],
    ['21.04339376', '52.17163061', 'Warszawa,02-768,Fosa,25'],
    ['21.02515188', '52.16467384', 'Warszawa,02-785,Koński Jar,7'],
    ['21.05336198', '52.15102905', 'Warszawa,02-777,Szolc-Rogozińskiego,8'],
    ['21.01408039', '52.10545045', 'Warszawa,02-882,Katarynki,19'],
    ['21.02970299', '52.1632643', 'Warszawa,02-787,Koncertowa,4'],
    ['21.02053862', '52.1486651', 'Warszawa,02-807,Bociania,29'],
    ['21.03653723', '52.14955527', 'Warszawa,02-776,Amundsena R.,4'],
    ['21.01140499', '52.14692803', 'Warszawa,02-828,Pląsy,14H'],
    ['21.02252089', '52.15679466', 'Warszawa,02-785,Puszczyka,1'],
    ['21.01695808', '52.14161836', 'Warszawa,02-845,Puławska,336'],
    ['21.05625316', '52.1454929', 'Warszawa,02-792,Lanciego F.,13'],
    ['21.03706989', '52.16067832', 'Warszawa,02-786,Związku Walki Młodych,17'],
    ['21.01994101', '52.15624826', 'Warszawa,02-784,Romera E.,14'],
    ['21.01086191', '52.12377715', 'Warszawa,02-867,Baletowa,16'],
    ['21.02211118', '52.15373665', 'Warszawa,02-784,Cybisa J.,9'],
    ['21.01305071', '52.1473858', 'Warszawa,02-829,Taneczna,59F'],
    ['21.01205265', '52.14626559', 'Warszawa,02-828,Pląsy,12'],
    ['20.99457332', '52.1128344', 'Warszawa,02-869,Dawidowska,49'],
    ['21.02374965', '52.11318053', 'Warszawa,02-886,Jagielska,53C'],
    ['21.01395353', '52.14465844', 'Warszawa,02-849,Krasnowolska,3L'],
    ['21.01250428', '52.12320034', 'Warszawa,02-867,Baletowa,14'],
    ['21.00824856', '52.11649303', 'Warszawa,02-868,Sarabandy,61'],
    ['21.05608783', '52.13007344', 'Warszawa,02-886,Rybałtów,18'],
    ['21.01706904', '52.14742453', 'Warszawa,02-819,Puławska,300A'],
    ['21.04609866', '52.16361565', 'Warszawa,02-787,Nowoursynowska,161'],
    ['21.07194268', '52.13604093', 'Warszawa,02-796,Rosoła J.,17'],
    ['21.00814133', '52.14175114', 'Warszawa,02-830,Mazura,18A'],
    ['21.02142577', '52.1142586', 'Warszawa,02-886,Jagielska,57L'],
    ['21.04319339', '52.15011106', 'Warszawa,02-776,Hawajska,6'],
    ['21.07952169', '52.13353774', 'Warszawa,02-797,Nowoursynowska,70D'],
    ['21.02255191', '52.11866444', 'Warszawa,02-843,Tukana,9'],
    ['21.04126512', '52.1636667', 'Warszawa,02-787,Nowoursynowska,161G'],
    ['21.06023546', '52.14668648', 'Warszawa,02-796,Migdałowa,71'],
    ['21.00616587', '52.11340882', 'Warszawa,02-850,Trombity,22'],
    ['20.9971783', '52.15747969', 'Warszawa,02-820,Wyczółki,60A'],
    ['21.06445065', '52.13055224', 'Warszawa,02-798,Kabacki Dukt,11'],
    ['21.04873931', '52.15643549', 'Warszawa,02-777,Gutta R.,1'],
    ['21.03256582', '52.10635128', 'Warszawa,02-892,Kuropatwy,8'],
    ['21.05333532', '52.15698467', 'Warszawa,02-777,Kopcińskiego S.,7'],
    ['21.00774669', '52.14177195', 'Warszawa,02-830,Mazura,18'],
    ['21.0604759', '52.1514803', 'Warszawa,02-797,Imbirowa,6J'],
    ['21.01568193', '52.14409168', 'Warszawa,02-828,Pyszniańska,4'],
    ['21.01586579', '52.14381126', 'Warszawa,02-828,Roztoki,6'],
    ['21.04569757', '52.1451019', 'Warszawa,02-776,Warchałowskiego E.,4'],
    ['21.05021712', '52.13969058', 'Warszawa,02-791,Na Uboczu,2'],
    ['21.01400114', '52.11597539', 'Warszawa,02-875,Klarnecistów,7A'],
    ['21.01969339', '52.12894556', 'Warszawa,02-840,Leśna,3D'],
    ['21.07408193', '52.12977247', 'Warszawa,02-796,Zaruby J.,6A'],
    ['20.99372726', '52.12617571', 'Warszawa,02-867,Baletowa,97'],
    ['21.05469811', '52.15071914', 'Warszawa,02-778,Grzegorzewskiej M.,11'],
    ['21.01614697', '52.15276491', 'Warszawa,02-822,Poleczki,3'],
    ['21.07448891', '52.13520376', 'Warszawa,02-786,Rosoła J.,20'],
    ['21.01421351', '52.13520228', 'Warszawa,02-857,Dźwiękowa,16A'],
    ['20.99241975', '52.16767542', 'Warszawa,02-699,Kłobucka,23A'],
    ['21.07538542', '52.13230814', 'Warszawa,02-796,Relaksowa,45'],
    ['21.02118296', '52.10637867', 'Warszawa,02-892,Kuropatwy,40'],
    ['21.0189826', '52.1417065', 'Warszawa,02-812,Ibisa,20'],
    ['20.98889852', '52.12873292', 'Warszawa,02-867,Baletowa,128'],
    ['21.04203257', '52.1572508', 'Warszawa,02-786,Lachmana W.,2'],
    ['21.01383977', '52.14755261', 'Warszawa,02-829,Taneczna,59J'],
    ['21.01507705', '52.14458286', 'Warszawa,02-829,Taneczna,41'],
    ['20.99477812', '52.15035619', 'Warszawa,02-823,Osmańska,2'],
    ['21.0146625', '52.12037635', 'Warszawa,02-878,Gajdy,38D'],
    ['21.00259295', '52.11963207', 'Warszawa,02-868,Sarabandy,103H'],
    ['21.01437761', '52.11926344', 'Warszawa,02-878,Gajdy,34'],
    ['21.00514115', '52.15153029', 'Warszawa,02-823,Polki,1'],
    ['21.04350932', '52.14250228', 'Warszawa,02-776,Dereniowa,1'],
    ['21.01632081', '52.13632133', 'Warszawa,02-858,Transportowców,42'],
    ['21.00691087', '52.13548303', 'Warszawa,02-856,Ludwinowska,52C'],
    ['21.04893862', '52.15672047', 'Warszawa,02-777,Gutta R.,2'],
    ['21.05484367', '52.15659005', 'Warszawa,02-776,Nugat,2'],
    ['21.05747513', '52.15758291', 'Warszawa,02-776,Nowoursynowska,162J'],
    ['20.99571684', '52.16519527', 'Warszawa,02-699,Taborowa,33'],
    ['21.06614376', '52.1441691', 'Warszawa,02-790,Sengera Cichego M.,14'],
    ['21.05282609', '52.14791721', 'Warszawa,02-777,Kulczyńskiego S.,10'],
    ['21.01716758', '52.15024558', 'Warszawa,02-819,Puławska,284'],
    ['21.0234348', '52.15268289', 'Warszawa,02-784,Arctowskiego H.,11'],
    ['21.07235925', '52.1332178', 'Warszawa,02-796,Dembego S.,17A'],
    ['21.02466908', '52.10882022', 'Warszawa,02-892,Bażancia,46A'],
    ['21.01889906', '52.14584096', 'Warszawa,02-807,Bociania,9E'],
    ['21.00930828', '52.14596191', 'Warszawa,02-828,Pląsy,39'],
    ['21.01733012', '52.10773398', 'Warszawa,02-884,Puławska,554'],
    ['21.01644092', '52.14925162', 'Warszawa,02-829,Giewont,53'],
    ['21.02354934', '52.13696293', 'Warszawa,02-817,Białozora,18'],
    ['21.01497534', '52.14189659', 'Warszawa,02-829,Taneczna,23/27'],
    ['21.028578', '52.162784', 'Warszawa,02-787,Koncertowa,3'],
    ['21.06908499', '52.13351377', 'Warszawa,02-796,Bronikowskiego K.,21'],
    ['21.03753752', '52.11154131', 'Warszawa,02-886,Jagielska,6'],
    ['21.04948306', '52.15606741', 'Warszawa,02-777,Kopcińskiego S.,18'],
    ['21.01922159', '52.11736322', 'Warszawa,02-885,Puławska,585'],
    ['21.02782702', '52.14475249', 'Warszawa,02-781,Roentgena W.,9'],
    ['21.04266896', '52.16968296', 'Warszawa,02-768,Fosa,37B'],
    ['21.02115005', '52.15281464', 'Warszawa,02-784,Janowskiego A.,4'],
    ['21.06055782', '52.15025123', 'Warszawa,02-776,Nowoursynowska,139A'],
    ['21.01658155', '52.14737792', 'Warszawa,02-829,Giewont,15'],
    ['21.07026326', '52.13522264', 'Warszawa,02-796,Jeżewskiego K.,3B'],
    ['21.0604222', '52.14370704', 'Warszawa,02-792,Lasek Brzozowy,11'],
    ['21.01603698', '52.12618968', 'Warszawa,02-847,Szumiąca,2'],
    ['21.0137178', '52.10530395', 'Warszawa,02-882,Katarynki,21'],
    ['21.03048676', '52.1374371', 'Warszawa,02-815,Żołny,18'],
    ['21.0203555', '52.13503484', 'Warszawa,02-836,Modraszki,7'],
    ['21.004534', '52.135998', 'Warszawa,02-855,Sztajerka,2'],
    ['21.02177516', '52.15331367', 'Warszawa,02-784,Cybisa J.,18'],
    ['21.01732217', '52.1234788', 'Warszawa,02-884,Puławska,444'],
    ['21.05656373', '52.13094448', 'Warszawa,02-796,Wąwozowa,35'],
    ['21.06511578', '52.13816078', 'Warszawa,02-793,Przy Bażantarni,8B'],
    ['21.05954998', '52.12961612', 'Warszawa,02-798,Ekologiczna,11'],
    ['21.00977489', '52.13775668', 'Warszawa,02-830,Gawota,17'],
    ['21.05360219', '52.13712965', 'Warszawa,02-793,Małej Łąki,74'],
    ['21.04377136', '52.14639124', 'Warszawa,02-776,Dereniowa,10'],
    ['21.01239246', '52.13336265', 'Warszawa,02-862,Farbiarska,86'],
    ['21.02742023', '52.15931227', 'Warszawa,02-785,Wiolinowa,6'],
    ['21.06444909', '52.14583929', 'Warszawa,02-790,Pachnąca,95'],
    ['21.03098777', '52.10638593', 'Warszawa,02-893,Perlicza,1B'],
    ['21.02259418', '52.13497705', 'Warszawa,02-836,Kormoranów,1E'],
    ['21.07812635', '52.13346072', 'Warszawa,02-786,Rosoła J.,8L'],
    ['21.05226554', '52.136797', 'Warszawa,02-793,Małej Łąki,13'],
    ['21.00230672', '52.1229135', 'Warszawa,02-867,Baletowa,49A'],
    ['21.03126633', '52.16141755', 'Warszawa,02-787,Wokalna,6'],
    ['20.99517292', '52.15669665', 'Warszawa,02-820,Wyczółki,101A'],
    ['21.00103778', '52.14164814', 'Warszawa,02-850,Kądziołeczki,34'],
    ['21.025459', '52.15492179', 'Warszawa,02-784,Cybisa J.,1'],
    ['21.04053927', '52.13918499', 'Warszawa,02-781,Pileckiego W.,108'],
    ['21.03306364', '52.11029249', 'Warszawa,02-888,Kogucia,5A'],
    ['21.03134617', '52.15749807', 'Warszawa,02-784,Al. Komisji Edukacji Narodowej,99'],
    ['21.05353251', '52.14032945', 'Warszawa,02-791,Meander,1'],
    ['21.04671557', '52.14727399', 'Warszawa,02-776,Wasilkowskiego J.,11'],
    ['21.06002206', '52.15399113', 'Warszawa,02-797,Bulwarowa,6'],
    ['21.04183275', '52.14716939', 'Warszawa,02-776,Gandhi I.,23'],
    ['21.03492439', '52.16457181', 'Warszawa,02-787,Okaryny,7'],
    ['21.02411625', '52.11365381', 'Warszawa,02-886,Jagielska,51H'],
    ['21.02255893', '52.15760736', 'Warszawa,02-785,Puszczyka,3'],
    ['21.03106386', '52.15551677', 'Warszawa,02-784,Herbsta S.,2'],
    ['21.05680466', '52.13982367', 'Warszawa,02-793,Belgradzka,44'],
    ['21.05013493', '52.14146518', 'Warszawa,02-791,Na Uboczu,14'],
    ['21.0297345', '52.14820874', 'Warszawa,02-781,Św. M. Kolbego,12'],
    ['21.07568785', '52.12978148', 'Warszawa,02-796,Dembego S.,8A'],
    ['21.07293246', '52.13334485', 'Warszawa,02-796,Dembego S.,20'],
    ['21.01909913', '52.14839186', 'Warszawa,02-806,Albatrosów,8C'],
    ['21.01409223', '52.13694074', 'Warszawa,02-830,Wędrowców,16'],
    ['21.04505545', '52.13474562', 'Warszawa,02-834,Perkalowa,6C'],
    ['21.03543172', '52.16276682', 'Warszawa,02-787,Okaryny,3'],
    ['21.02699995', '52.14607917', 'Warszawa,02-781,Roentgena W.,20'],
    ['21.04278015', '52.1683662', 'Warszawa,02-787,Nowoursynowska,178'],
    ['21.026569', '52.15316798', 'Warszawa,02-784,Dunikowskiego X.,8'],
    ['21.058348', '52.149244', 'Warszawa,02-796,Rosoła J.,57'],
    ['21.04399662', '52.13885099', 'Warszawa,02-795,Kazury S.,21'],
    ['21.04362368', '52.17174819', 'Warszawa,02-768,Fosa,23'],
    ['21.01412058', '52.10609375', 'Warszawa,02-881,Pozytywki,1A'],
    ['21.03044308', '52.15259975', 'Warszawa,02-784,Dembowskiego E.,12'],
    ['21.0389075', '52.15077419', 'Warszawa,02-776,Malinowskiego E.,4'],
    ['21.0167094', '52.11653557', 'Warszawa,02-876,Puzonistów,2'],
    ['21.02783006', '52.1439157', 'Warszawa,02-811,Pustułeczki,16'],
    ['20.99087077', '52.12608842', 'Warszawa,02-867,Baletowa,115B'],
    ['21.02918712', '52.14796205', 'Warszawa,02-781,Św. M. Kolbego,5'],
    ['21.01936692', '52.13445749', 'Warszawa,02-836,Dzierzby,28'],
    ['21.02537902', '52.13752066', 'Warszawa,02-815,Żołny,33'],
    ['21.01772479', '52.11676109', 'Warszawa,02-884,Puławska,488'],
    ['21.06229978', '52.14735661', 'Warszawa,02-790,Pachnąca,54'],
    ['21.01396786', '52.1409641', 'Warszawa,02-830,Korowodu,8'],
    ['21.01497768', '52.1154094', 'Warszawa,02-877,Drumli,11B'],
    ['21.01308064', '52.11484537', 'Warszawa,02-868,Sarabandy,28B'],
    ['21.07085483', '52.13345017', 'Warszawa,02-796,Bronikowskiego K.,2A'],
    ['21.00115235', '52.14268734', 'Warszawa,02-849,Krasnowolska,68'],
    ['21.081587', '52.12736526', 'Warszawa,02-797,Nowoursynowska,36'],
    ['21.00133858', '52.15658706', 'Warszawa,02-820,Wyczółki,71'],
    ['21.0558459', '52.15063259', 'Warszawa,02-778,Grzegorzewskiej M.,8'],
    ['21.00833884', '52.13117606', 'Warszawa,02-863,Jeziorki,84'],
    ['21.05211275', '52.15785609', 'Warszawa,02-777,Przybylskiego Cz.,12'],
    ['21.02147291', '52.13699225', 'Warszawa,02-817,Białozora,37A'],
    ['21.05700369', '52.14942056', 'Warszawa,02-778,Grzegorzewskiej M.,1'],
    ['21.05865178', '52.14327094', 'Warszawa,02-792,Lanciego F.,10D'],
    ['21.00657691', '52.13368468', 'Warszawa,02-856,Ludwinowska,51B'],
    ['20.99420745', '52.15667075', 'Warszawa,02-822,Poleczki,82'],
    ['21.02279889', '52.14543765', 'Warszawa,02-811,Makolągwy,20A'],
    ['21.0129287', '52.13025425', 'Warszawa,02-860,Czterech Wiatrów,45'],
    ['21.0580703', '52.12877206', 'Warszawa,02-798,Wilczy Dół,4'],
    ['21.0183812', '52.14566963', 'Warszawa,02-801,Puławska,405'],
    ['21.04909231', '52.14660658', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,55A'],
    ['21.0072643', '52.12362649', 'Warszawa,02-867,Baletowa,29'],
    ['21.04627656', '52.13930608', 'Warszawa,02-795,Kazury S.,2F'],
    ['21.08444866', '52.1264576', 'Warszawa,02-797,Nowoursynowska,30D'],
    ['21.04225356', '52.14037797', 'Warszawa,02-795,Kazury S.,16'],
    ['20.99596786', '52.1617688', 'Warszawa,02-699,Pieskowa Skała,7'],
    ['21.06077712', '52.1401916', 'Warszawa,02-793,Raabego H.,13'],
    ['21.0115851', '52.13288749', 'Warszawa,02-863,Farbiarska,86/90'],
    ['21.03456027', '52.16047258', 'Warszawa,02-786,Zamiany,13'],
    ['21.05247206', '52.14952695', 'Warszawa,02-777,Szolc-Rogozińskiego,1'],
    ['21.01881448', '52.16308347', 'Warszawa,02-781,Paszkiewicza L.,3'],
    ['21.04519422', '52.15171704', 'Warszawa,02-777,Polinezyjska,6'],
    ['21.01477166', '52.15252015', 'Warszawa,02-829,Taneczna,73'],
    ['21.02220708', '52.15599225', 'Warszawa,02-784,Romera E.,8'],
    ['21.05893856', '52.15294363', 'Warszawa,02-797,Arachidowa,3'],
    ['21.03900578', '52.15968556', 'Warszawa,02-786,Związku Walki Młodych,16'],
    ['21.02095405', '52.12986372', 'Warszawa,02-838,Kajakowa,10'],
    ['21.03285445', '52.16238098', 'Warszawa,02-787,Bartóka B.,6'],
    ['21.01644152', '52.14919458', 'Warszawa,02-829,Giewont,51'],
    ['21.02818791', '52.14750607', 'Warszawa,02-781,Łukaszczyka P.,11'],
    ['21.01930736', '52.12810879', 'Warszawa,02-842,Trele,6'],
    ['21.06041922', '52.15071306', 'Warszawa,02-776,Nowoursynowska,139F'],
    ['21.03771263', '52.16462717', 'Warszawa,02-787,Chłapowskiego D.,26'],
    ['21.03253446', '52.10743402', 'Warszawa,02-892,Kuropatwy,8S'],
    ['21.06864879', '52.14177944', 'Warszawa,02-797,Nowoursynowska,103C'],
    ['21.02923457', '52.15333268', 'Warszawa,02-784,Dunikowskiego X.,3'],
    ['21.02788356', '52.14779097', 'Warszawa,02-781,Roentgena W.,8'],
    ['21.02296352', '52.15634517', 'Warszawa,02-784,Pięciolinii,7'],
    ['21.03774373', '52.15997918', 'Warszawa,02-786,Związku Walki Młodych,15'],
    ['21.0257056', '52.16323242', 'Warszawa,02-785,Koński Jar,4'],
    ['21.06753562', '52.14303198', 'Warszawa,02-790,Sotta K. Sokoła,1A'],
    ['21.00694633', '52.13576012', 'Warszawa,02-856,Ludwinowska,52D'],
    ['21.05902476', '52.12932498', 'Warszawa,02-798,Pod Strzechą,4'],
    ['21.06798328', '52.14113505', 'Warszawa,02-786,Rosoła J.,38'],
    ['21.06307872', '52.14409571', 'Warszawa,02-796,Mandarynki,2'],
    ['21.01515743', '52.14778228', 'Warszawa,02-829,Taneczna,61'],
    ['21.07254284', '52.13100659', 'Warszawa,02-796,Zaruby J.,10'],
    ['21.06107916', '52.13376413', 'Warszawa,02-793,Borsuka K.,5'],
    ['21.02232816', '52.13744214', 'Warszawa,02-817,Białozora,32D'],
    ['21.06918139', '52.14161705', 'Warszawa,02-797,Nowoursynowska,101'],
    ['21.01621444', '52.15076655', 'Warszawa,02-829,Mączeńskiego Z.,29'],
    ['21.06686591', '52.1396105', 'Warszawa,02-793,Lokajskiego E.,6'],
    ['21.01934428', '52.15091236', 'Warszawa,02-806,Rybitwy,8A'],
    ['21.06086054', '52.12909558', 'Warszawa,02-798,Mielczarskiego R.,8'],
    ['21.01849458', '52.13306892', 'Warszawa,02-844,Puławska,483'],
    ['21.01262629', '52.12573981', 'Warszawa,02-862,Farbiarska,47'],
    ['21.0141277', '52.13284961', 'Warszawa,02-862,Muzyczna,49'],
    ['21.01543975', '52.11066856', 'Warszawa,02-871,Karczunkowska,15'],
    ['21.0171573', '52.15061819', 'Warszawa,02-819,Puławska,280'],
    ['21.05665574', '52.15640757', 'Warszawa,02-776,Nowoursynowska,160G'],
    ['21.02335203', '52.13223967', 'Warszawa,02-837,Bogatki,5'],
    ['21.02224304', '52.13851287', 'Warszawa,02-815,Żołny,44'],
    ['21.01268429', '52.12691285', 'Warszawa,02-862,Farbiarska,42'],
    ['21.02160813', '52.12296084', 'Warszawa,02-843,Pelikanów,23'],
    ['20.99176295', '52.11007116', 'Warszawa,02-873,Kurantów,34'],
    ['21.05993129', '52.15013481', 'Warszawa,02-776,Nowoursynowska,139T'],
    ['21.0516998', '52.14146088', 'Warszawa,02-791,Meander,15'],
    ['21.02658146', '52.16055018', 'Warszawa,02-785,Wiolinowa,13'],
    ['20.99205759', '52.1595139', 'Warszawa,02-699,Kłobucka,1'],
    ['21.02486207', '52.10921426', 'Warszawa,02-892,Bażancia,46B'],
    ['21.02248477', '52.12245153', 'Warszawa,02-814,Słonki,11'],
    ['20.99649216', '52.11097254', 'Warszawa,02-874,Orfeusza,8'],
    ['21.01582275', '52.14565095', 'Warszawa,02-828,Litworowa,10'],
    ['21.05790958', '52.15243498', 'Warszawa,02-776,Nowoursynowska,145C'],
    ['20.99409933', '52.15611864', 'Warszawa,02-823,Osmańska,11'],
    ['21.04780754', '52.14079812', 'Warszawa,02-791,Na Uboczu,24'],
    ['21.07446676', '52.13126156', 'Warszawa,02-796,Dembego S.,12'],
    ['21.0251322', '52.15022041', 'Warszawa,02-808,Zięby,41'],
    ['21.04741553', '52.13265988', 'Warszawa,02-798,Jaworowa,13'],
    ['20.9923623', '52.11071787', 'Warszawa,02-871,Karczunkowska,146'],
    ['21.0293144', '52.10968325', 'Warszawa,02-892,Bażancia,32G'],
    ['21.04521259', '52.15205163', 'Warszawa,02-777,Polinezyjska,10A'],
    ['21.06308967', '52.14268884', 'Warszawa,02-792,Lasek Brzozowy,2'],
    ['21.03992693', '52.14766712', 'Warszawa,02-776,Miklaszewskiego,3'],
    ['20.99100957', '52.11006879', 'Warszawa,02-873,Kurantów,31'],
    ['21.05866399', '52.13069869', 'Warszawa,02-798,Pod Strzechą,7'],
    ['21.0410326', '52.14625044', 'Warszawa,02-776,Gandhi I.,27A'],
    ['21.03801404', '52.16460659', 'Warszawa,02-787,Chłapowskiego D.,20'],
    ['20.99313546', '52.10782199', 'Warszawa,02-872,Gogolińska,29'],
    ['21.0455568', '52.15361455', 'Warszawa,02-777,Magellana F.,3'],
    ['21.02340225', '52.1261476', 'Warszawa,02-842,Rataja M.,2'],
    ['21.01963038', '52.14057873', 'Warszawa,02-814,Gruchacza,39'],
    ['21.04717324', '52.15466327', 'Warszawa,02-776,Strzeleckiego,6'],
    ['21.02011606', '52.15583362', 'Warszawa,02-784,Romera E.,12'],
    ['21.06175434', '52.14792992', 'Warszawa,02-790,Kpt. Pawlaczyka,30'],
    ['21.03406482', '52.10637197', 'Warszawa,02-892,Kuropatwy,2'],
    ['21.043108', '52.143883', 'Warszawa,02-776,Dereniowa,5C'],
    ['21.05620414', '52.14203294', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,46'],
    ['21.05754354', '52.15798228', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,8'],
    ['21.02773077', '52.14444596', 'Warszawa,02-811,Makolągwy,17'],
    ['21.01809053', '52.10962184', 'Warszawa,02-884,Puławska,538'],
    ['21.0637213', '52.14030128', 'Warszawa,02-793,Lokajskiego E.,24'],
    ['21.01413969', '52.13292878', 'Warszawa,02-862,Muzyczna,53'],
    ['21.05727385', '52.15716796', 'Warszawa,02-776,Wrzosowisko,14'],
    ['21.05551603', '52.13533159', 'Warszawa,02-793,Małej Łąki,24'],
    ['21.04573006', '52.13846281', 'Warszawa,02-795,Kazury S.,2'],
    ['21.03700027', '52.15623899', 'Warszawa,02-786,Bacewiczówny G.,5'],
    ['21.05489024', '52.15245182', 'Warszawa,02-796,Rosoła J.,61B'],
    ['21.03684334', '52.1487507', 'Warszawa,02-776,Miklaszewskiego,9'],
    ['21.0002593', '52.14317249', 'Warszawa,02-850,Kądziołeczki,55'],
    ['21.04313749', '52.14506311', 'Warszawa,02-776,Dereniowa,9'],
    ['21.02384329', '52.15690894', 'Warszawa,02-784,Pięciolinii,10'],
    ['21.05384348', '52.13095067', 'Warszawa,02-833,Wełniana,17'],
    ['21.02702725', '52.15458291', 'Warszawa,02-784,Sosnowskiego,3'],
    ['20.98707447', '52.11168666', 'Warszawa,02-871,Karczunkowska,170'],
    ['21.050356', '52.161651', 'Warszawa,02-787,Nowoursynowska,164/16'],
    ['21.03140822', '52.15322974', 'Warszawa,02-784,Dembowskiego E.,6'],
    ['21.05316048', '52.14830665', 'Warszawa,02-777,Kulczyńskiego S.,12'],
    ['21.023961', '52.160081', 'Warszawa,02-785,Puszczyka,19'],
    ['21.02918821', '52.15532877', 'Warszawa,02-784,Romera E.,4B'],
    ['21.01310299', '52.14069954', 'Warszawa,02-830,Korowodu,15'],
    ['21.05889113', '52.14478659', 'Warszawa,02-792,Lasek Brzozowy,17'],
    ['21.02250265', '52.11002115', 'Warszawa,02-891,Jaskółcza,10A'],
    ['21.05963003', '52.15110925', 'Warszawa,02-776,Nowoursynowska,143B'],
    ['21.019424', '52.15474581', 'Warszawa,02-784,Janowskiego A.,9'],
    ['21.00334871', '52.15614153', 'Warszawa,02-822,Poleczki,40a'],
    ['21.03001954', '52.15380105', 'Warszawa,02-784,Dunikowskiego X.,1'],
    ['21.06005931', '52.14725007', 'Warszawa,02-796,Migdałowa,91'],
    ['21.01338036', '52.14756408', 'Warszawa,02-829,Taneczna,59H'],
    ['21.0678328', '52.1430989', 'Warszawa,02-790,Sotta K. Sokoła,1'],
    ['21.05251479', '52.13150438', 'Warszawa,02-833,Wełniana,27A'],
    ['21.02502689', '52.1071449', 'Warszawa,02-892,Kuropatwy,28S'],
    ['21.0651511', '52.14607682', 'Warszawa,02-790,Pachnąca,3'],
    ['21.03601906', '52.1561088', 'Warszawa,02-786,Bacewiczówny G.,8'],
    ['21.00191989', '52.15882851', 'Warszawa,02-820,Łączyny,Feb-52'],
    ['21.0243826', '52.14982733', 'Warszawa,02-803,Bekasów,64'],
    ['21.00938073', '52.14625493', 'Warszawa,02-828,Pląsy,22'],
    ['20.99958914', '52.14321429', 'Warszawa,02-850,Tramblanki,32B'],
    ['20.99044894', '52.12711467', 'Warszawa,02-867,Baletowa,117'],
    ['21.02419294', '52.15491382', 'Warszawa,02-784,Cybisa J.,3'],
    ['20.99927407', '52.15696327', 'Warszawa,02-820,Wyczółki,50'],
    ['21.01195489', '52.11966026', 'Warszawa,02-862,Farbiarska,7H'],
    ['21.01671729', '52.14382553', 'Warszawa,02-828,Rysy,5'],
    ['21.052613', '52.161743', 'Warszawa,02-787,Nowoursynowska,164/30'],
    ['21.01158908', '52.12500914', 'Warszawa,02-862,Farbiarska,35'],
    ['21.0584247', '52.14534646', 'Warszawa,02-792,Lasek Brzozowy,19'],
    ['21.04999975', '52.1393933', 'Warszawa,02-791,Na Uboczu,7'],
    ['21.04117095', '52.14526056', 'Warszawa,02-776,Hirszfelda L.,14'],
    ['21.05902492', '52.15112603', 'Warszawa,02-776,Nowoursynowska,143'],
    ['21.07370861', '52.12951037', 'Warszawa,02-796,Zaruby J.,6'],
    ['21.0763349', '52.1289242', 'Warszawa,02-796,Dembego S.,6'],
    ['21.00081576', '52.15567991', 'Warszawa,02-822,Poleczki,31'],
    ['21.0174158', '52.12700018', 'Warszawa,02-884,Puławska,426'],
    ['21.01979613', '52.13718399', 'Warszawa,02-816,Rajskich Ptaków,58'],
    ['21.01577497', '52.1507631', 'Warszawa,02-829,Mączeńskiego Z.,39'],
    ['21.02377993', '52.1503764', 'Warszawa,02-803,Bekasów,118'],
    ['21.04126491', '52.14210513', 'Warszawa,02-776,Hirszfelda L.,1'],
    ['21.01635826', '52.13536183', 'Warszawa,02-858,Transportowców,28A'],
    ['21.06015998', '52.14678482', 'Warszawa,02-796,Migdałowa,75'],
    ['21.00304506', '52.14232234', 'Warszawa,02-849,Krasnowolska,58'],
    ['21.00760186', '52.14267049', 'Warszawa,02-849,Krasnowolska,31C'],
    ['21.02199414', '52.14997553', 'Warszawa,02-803,Bekasów,55'],
    ['21.07902352', '52.13216944', 'Warszawa,02-796,Relaksowa,26C'],
    ['21.06451184', '52.14683992', 'Warszawa,02-790,Pachnąca,31'],
    ['21.0223725', '52.15467012', 'Warszawa,02-784,Cybisa J.,8'],
    ['21.03254517', '52.16280389', 'Warszawa,02-787,Symfonii,1'],
    ['21.01435987', '52.13791494', 'Warszawa,02-830,Gawota,2E'],
    ['21.01762361', '52.11734574', 'Warszawa,02-884,Puławska,482'],
    ['21.0551536', '52.1350846', 'Warszawa,02-793,Małej Łąki,6'],
    ['21.02164006', '52.15383372', 'Warszawa,02-784,Cybisa J.,10'],
    ['21.04657692', '52.15645401', 'Warszawa,02-786,Rosoła J.,68'],
    ['20.9890462', '52.12526728', 'Warszawa,02-867,Baletowa,113B'],
    ['21.0604239', '52.14636116', 'Warszawa,02-796,Migdałowa,59'],
    ['21.02410436', '52.15835528', 'Warszawa,02-785,Puszczyka,6'],
    ['21.01922159', '52.10720359', 'Warszawa,02-885,Puławska,621B'],
    ['21.02841672', '52.15350192', 'Warszawa,02-784,Dunikowskiego X.,4'],
    ['21.05417317', '52.15738095', 'Warszawa,02-777,Kopcińskiego S.,6'],
    ['21.04792047', '52.14561789', 'Warszawa,02-778,Szczuki,1'],
    ['21.05821734', '52.15455019', 'Warszawa,02-797,Letniskowa,18'],
    ['21.00817336', '52.1543837', 'Warszawa,02-822,Poleczki,19'],
    ['21.04226966', '52.14411663', 'Warszawa,02-776,Hirszfelda L.,10'],
    ['21.03963631', '52.11019358', 'Warszawa,02-886,Jagielska,3F'],
    ['21.02550907', '52.10630318', 'Warszawa,02-892,Kuropatwy,28'],
    ['21.05362493', '52.12948245', 'Warszawa,02-835,Elegancka,6'],
    ['21.00098563', '52.12706352', 'Warszawa,02-863,Jeziorki,27A'],
    ['21.05214159', '52.13416976', 'Warszawa,02-791,Stryjeńskich,15'],
    ['21.02185923', '52.15306965', 'Warszawa,02-784,Cybisa J.,24'],
    ['21.06358942', '52.12679905', 'Warszawa,02-798,Wilczy Dół,5'],
    ['21.01666653', '52.1520871', 'Warszawa,02-819,Puławska,274'],
    ['21.01133562', '52.14099812', 'Warszawa,02-830,Korowodu,20'],
    ['21.01734229', '52.13403035', 'Warszawa,02-845,Puławska,382'],
    ['21.08074584', '52.12836046', 'Warszawa,02-797,Nowoursynowska,44C'],
    ['21.04294513', '52.14985625', 'Warszawa,02-776,Hawajska,3'],
    ['21.0286838', '52.15954937', 'Warszawa,02-722,Al. Komisji Edukacji Narodowej,103'],
    ['21.0450449', '52.15721566', 'Warszawa,02-786,Ciszewskiego J.,10'],
    ['21.06002947', '52.13064414', 'Warszawa,02-798,Mielczarskiego R.,9'],
    ['21.06406867', '52.14748646', 'Warszawa,02-790,Pachnąca,18'],
    ['21.05387852', '52.14134967', 'Warszawa,02-791,Meander,14'],
    ['21.01541851', '52.15112516', 'Warszawa,02-829,Taneczna,78'],
    ['21.0399689', '52.15928405', 'Warszawa,02-786,Związku Walki Młodych,12B'],
    ['21.013586', '52.113644', 'Warszawa,02-868,Sarabandy,24'],
    ['21.02650805', '52.10956819', 'Warszawa,02-892,Bażancia,32A'],
    ['21.03508175', '52.15975185', 'Warszawa,02-786,Zamiany,9'],
    ['21.01283501', '52.12227545', 'Warszawa,02-878,Farbiarska,23'],
    ['21.01486117', '52.14415119', 'Warszawa,02-829,Taneczna,37'],
    ['21.01631154', '52.13560928', 'Warszawa,02-858,Transportowców,30'],
    ['21.00791757', '52.11989188', 'Warszawa,02-868,Sarabandy,92P'],
    ['21.04319056', '52.15353001', 'Warszawa,02-777,Cynamonowa,45'],
    ['21.05907208', '52.13010667', 'Warszawa,02-798,Pod Strzechą,6'],
    ['21.01741575', '52.12670072', 'Warszawa,02-884,Puławska,428'],
    ['21.01352169', '52.13700225', 'Warszawa,02-830,Wędrowców,18'],
    ['21.0322718', '52.1631518', 'Warszawa,02-787,Symfonii,4'],
    ['21.01868774', '52.13184925', 'Warszawa,02-844,Puławska,487'],
    ['21.07161823', '52.13854476', 'Warszawa,02-797,Nowoursynowska,85'],
    ['21.02607005', '52.14428952', 'Warszawa,02-811,Pustułeczki,18C'],
    ['21.00774491', '52.14076455', 'Warszawa,02-830,Mazura,10A'],
    ['20.99447756', '52.1462087', 'Warszawa,02-828,Pląsy,131'],
    ['21.0448235', '52.15551215', 'Warszawa,02-777,Magellana F.,12'],
    ['21.01503735', '52.15015843', 'Warszawa,02-829,Taneczna,67'],
    ['21.0136671', '52.12846925', 'Warszawa,02-860,Akustyczna,19'],
    ['21.05354909', '52.14742143', 'Warszawa,02-777,Kulczyńskiego S.,4'],
    ['21.06873607', '52.12728061', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,1'],
    ['21.00776304', '52.13282133', 'Warszawa,02-856,Ludwinowska,47H'],
    ['21.0152819', '52.13802506', 'Warszawa,02-830,Gawota,2D'],
    ['21.05963754', '52.15430305', 'Warszawa,02-797,Bulwarowa,11'],
    ['21.01166198', '52.13630063', 'Warszawa,02-830,Gawota,9J'],
    ['21.02593252', '52.1433425', 'Warszawa,02-781,Roentgena W.,43A'],
    ['21.05910639', '52.15178142', 'Warszawa,02-776,Nowoursynowska,143J'],
    ['21.02255459', '52.15598929', 'Warszawa,02-784,Romera E.,8A'],
    ['21.00999434', '52.12318721', 'Warszawa,02-867,Baletowa,11A'],
    ['21.03996337', '52.11211336', 'Warszawa,02-886,Jagielska,2'],
    ['21.02868175', '52.14431967', 'Warszawa,02-811,Makolągwy,13'],
    ['21.05620298', '52.14979332', 'Warszawa,02-778,Grzegorzewskiej M.,3'],
    ['20.99858134', '52.1101331', 'Warszawa,02-874,Achillesa,3'],
    ['20.9940698', '52.11412514', 'Warszawa,02-869,Dawidowska,58C'],
    ['21.02974081', '52.16506028', 'Warszawa,02-787,Koncertowa,8'],
    ['21.021717', '52.160806', 'Warszawa,02-785,Puszczyka,17'],
    ['21.04231819', '52.1702812', 'Warszawa,02-768,Fosa,35A'],
    ['21.01116826', '52.15116498', 'Warszawa,02-824,Wodzirejów,13A'],
    ['21.05023191', '52.14865754', 'Warszawa,02-777,Kulczyńskiego S.,7'],
    ['21.05434646', '52.15281754', 'Warszawa,02-796,Rosoła J.,61C'],
    ['21.07466357', '52.12951131', 'Warszawa,02-796,Dembego S.,3A'],
    ['21.01274812', '52.13371655', 'Warszawa,02-856,Ludwinowska,15A'],
    ['21.04735755', '52.15431223', 'Warszawa,02-776,Strzeleckiego,4'],
    ['21.05668203', '52.13908187', 'Warszawa,02-793,Żabińskiego J.,18'],
    ['21.01928903', '52.12996666', 'Warszawa,02-838,Kajakowa,12'],
    ['21.04590166', '52.15428042', 'Warszawa,02-776,Strzeleckiego,1'],
    ['21.02303567', '52.12332361', 'Warszawa,02-843,Pelikanów,10'],
    ['21.03135548', '52.14933148', 'Warszawa,02-781,Czapli,17'],
    ['21.05391048', '52.1324526', 'Warszawa,02-791,Stryjeńskich,13B'],
    ['21.04963318', '52.15154593', 'Warszawa,02-777,Szolc-Rogozińskiego,17'],
    ['21.01342578', '52.14489584', 'Warszawa,02-849,Krasnowolska,5'],
    ['21.01423985', '52.14438353', 'Warszawa,02-829,Taneczna,39'],
    ['21.04569164', '52.14389025', 'Warszawa,02-776,Warchałowskiego E.,3'],
    ['21.06200925', '52.12827215', 'Warszawa,02-798,Pod Lipą,4'],
    ['21.02261452', '52.12607454', 'Warszawa,02-842,Rataja M.,6A'],
    ['21.02586373', '52.10822769', 'Warszawa,02-892,Bażancia,39C'],
    ['21.0547889', '52.15919822', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,38'],
    ['20.99310519', '52.15068835', 'Warszawa,02-823,Mortkowicza,5'],
    ['21.0348312', '52.16466718', 'Warszawa,02-787,Okaryny,9'],
    ['21.01464561', '52.12602741', 'Warszawa,02-847,Kostki Napierskiego A.,7'],
    ['21.0297116', '52.14306047', 'Warszawa,02-811,Pustułeczki,9E'],
    ['21.02269385', '52.10654301', 'Warszawa,02-892,Kuropatwy,36'],
    ['21.01393548', '52.13616099', 'Warszawa,02-857,Organistów,12'],
    ['21.06149414', '52.13990955', 'Warszawa,02-793,Raabego H.,11'],
    ['21.00470625', '52.11104378', 'Warszawa,02-871,Karczunkowska,78A'],
    ['21.0529425', '52.15857647', 'Warszawa,02-777,Przybylskiego Cz.,4'],
    ['21.05743774', '52.15570589', 'Warszawa,02-776,Bajaderki,4'],
    ['21.02879088', '52.14849862', 'Warszawa,02-781,Łukaszczyka P.,32'],
    ['21.04260145', '52.16893983', 'Warszawa,02-787,Nowoursynowska,180A'],
    ['21.01279113', '52.12022999', 'Warszawa,02-862,Farbiarska,9BG'],
    ['21.07515781', '52.13474516', 'Warszawa,02-786,Rosoła J.,18'],
    ['21.02573293', '52.135911', 'Warszawa,02-818,Kolibrów,4'],
    ['21.02375042', '52.13746096', 'Warszawa,02-815,Żołny,43A'],
    ['21.02917887', '52.16469019', 'Warszawa,02-787,Koncertowa,9'],
    ['21.05786726', '52.15476272', 'Warszawa,02-797,Letniskowa,23'],
    ['21.01651456', '52.14798986', 'Warszawa,02-829,Giewont,21'],
    ['21.0523061', '52.14790957', 'Warszawa,02-777,Kulczyńskiego S.,1'],
    ['21.03964943', '52.14972428', 'Warszawa,02-776,Dereniowa,52/54'],
    ['21.071496', '52.13918878', 'Warszawa,02-797,Nowoursynowska,87'],
    ['21.04450523', '52.15496519', 'Warszawa,02-777,Magellana F.,10'],
    ['21.02775127', '52.14311887', 'Warszawa,02-781,Roentgena W.,43J'],
    ['21.05424198', '52.14776044', 'Warszawa,02-777,Kulczyńskiego S.,6'],
    ['20.999348', '52.14294651', 'Warszawa,02-850,Tramblanki,30'],
    ['21.01855548', '52.14154868', 'Warszawa,02-801,Puławska,433'],
    ['21.02065311', '52.14601653', 'Warszawa,02-803,Bekasów,12'],
    ['21.01465371', '52.12669049', 'Warszawa,02-847,Kostki Napierskiego A.,10A'],
    ['21.07042569', '52.13885526', 'Warszawa,02-786,Rosoła J.,26'],
    ['21.01222428', '52.11018555', 'Warszawa,02-871,Karczunkowska,31'],
    ['21.03069654', '52.15727852', 'Warszawa,02-784,Dzwonnicza,3'],
    ['21.0275541', '52.13591861', 'Warszawa,02-818,Kanarkowa,13A'],
    ['21.01066878', '52.15064763', 'Warszawa,02-827,Menueta,44'],
    ['21.05962076', '52.1504916', 'Warszawa,02-776,Nowoursynowska,139M'],
    ['21.06549009', '52.13915138', 'Warszawa,02-793,Lokajskiego E.,4'],
    ['21.03519694', '52.10765923', 'Warszawa,02-892,Kuropatwy,2W'],
    ['21.05230847', '52.15859886', 'Warszawa,02-777,Przybylskiego Cz.,6'],
    ['21.0772281', '52.13012288', 'Warszawa,02-796,Relaksowa,37'],
    ['21.07264896', '52.13529883', 'Warszawa,02-796,Rosoła J.,13'],
    ['21.05037773', '52.12973457', 'Warszawa,02-798,Ustronie,9'],
    ['21.02876658', '52.14842577', 'Warszawa,02-781,Łukaszczyka P.,30'],
    ['21.02095923', '52.11119875', 'Warszawa,02-887,Karmazynowa,7'],
    ['21.04590626', '52.15563266', 'Warszawa,02-776,Strzeleckiego,10C'],
    ['21.08021536', '52.1283104', 'Warszawa,02-797,Nowoursynowska,44'],
    ['21.01527042', '52.14324011', 'Warszawa,02-829,Taneczna,31'],
    ['21.00786176', '52.12497007', 'Warszawa,02-867,Baletowa,30C'],
    ['21.0162738', '52.14495979', 'Warszawa,02-828,Olczyska,20'],
    ['21.04256704', '52.14012053', 'Warszawa,02-795,Kazury S.,15'],
    ['21.03784169', '52.16408508', 'Warszawa,02-787,Chłapowskiego D.,35'],
    ['21.01524239', '52.14958085', 'Warszawa,02-829,Taneczna,67B'],
    ['20.98753552', '52.12959937', 'Warszawa,02-867,Baletowa,138'],
    ['21.01462876', '52.14755076', 'Warszawa,02-829,Taneczna,59L'],
    ['21.01966958', '52.11486626', 'Warszawa,02-886,Jagielska,63A/24'],
    ['21.01314248', '52.11092765', 'Warszawa,02-871,Karczunkowska,24A'],
    ['21.007191', '52.15541', 'Warszawa,02-820,Wyczółki,28'],
    ['21.03305563', '52.1500668', 'Warszawa,02-786,Ciszewskiego J.,24'],
    ['21.04065969', '52.11129415', 'Warszawa,02-886,Jagielska,1C'],
    ['21.06228343', '52.14525157', 'Warszawa,02-796,Mandarynki,10'],
    ['21.02270933', '52.11302623', 'Warszawa,02-886,Jagielska,53K'],
    ['21.04144673', '52.14326424', 'Warszawa,02-776,Hirszfelda L.,5'],
    ['21.01293489', '52.13461977', 'Warszawa,02-858,Fanfarowa,14'],
    ['21.02165322', '52.13170263', 'Warszawa,02-838,Raniuszka,27'],
    ['21.05144794', '52.15119356', 'Warszawa,02-777,Szolc-Rogozińskiego,9'],
    ['21.02332691', '52.1299186', 'Warszawa,02-838,Kajakowa,11A'],
    ['21.05891519', '52.14586031', 'Warszawa,02-792,Lasek Brzozowy,18'],
    ['21.06780735', '52.13534865', 'Warszawa,02-796,Jeżewskiego K.,7B'],
    ['21.03791906', '52.15777184', 'Warszawa,02-786,Związku Walki Młodych,7'],
    ['20.99334605', '52.137775', 'Warszawa,02-821,Hołubcowa,49'],
    ['21.01059352', '52.13451658', 'Warszawa,02-856,Ludwinowska,22B'],
    ['20.988643', '52.161688', 'Warszawa,02-699,Nowy Służewiec,3'],
    ['21.02079692', '52.10631061', 'Warszawa,02-892,Kuropatwy,42'],
    ['21.04756305', '52.16336403', 'Warszawa,02-787,Nowoursynowska,166'],
    ['21.0189356', '52.13309914', 'Warszawa,02-837,Puchaczy,49'],
    ['21.02015741', '52.12770201', 'Warszawa,02-841,Kobuza,17'],
    ['21.04208686', '52.13901644', 'Warszawa,02-795,Kazury S.,22'],
    ['21.00053528', '52.15635755', 'Warszawa,02-822,Poleczki,52'],
    ['21.05923577', '52.13244905', 'Warszawa,02-793,Telekiego Pala,8'],
    ['21.05305159', '52.15124229', 'Warszawa,02-777,Szolc-Rogozińskiego,10'],
    ['21.01466706', '52.12027174', 'Warszawa,02-878,Gajdy,38B'],
    ['21.05589781', '52.1543819', 'Warszawa,02-776,Nowoursynowska,151'],
    ['21.00206726', '52.11279616', 'Warszawa,02-873,Nawłocka,26'],
    ['21.01979842', '52.13272167', 'Warszawa,02-816,Rajskich Ptaków,10'],
    ['21.05569422', '52.15400626', 'Warszawa,02-776,Nowoursynowska,147B'],
    ['21.01566284', '52.14466552', 'Warszawa,02-828,Ornak,6'],
    ['21.06064629', '52.14196007', 'Warszawa,02-792,Lanciego F.,2'],
    ['21.03137171', '52.15067302', 'Warszawa,02-784,Dembowskiego E.,17A'],
    ['21.03129786', '52.14945296', 'Warszawa,02-781,Czapli,19A'],
    ['21.035127', '52.149858', 'Warszawa,02-776,Dybowskiego B.,5'],
    ['21.04604751', '52.15300448', 'Warszawa,02-777,Teligi L.,6'],
    ['21.0546006', '52.12884354', 'Warszawa,02-835,Batystowa,1B'],
    ['21.06586211', '52.14447098', 'Warszawa,02-790,Sengera Cichego M.,16'],
    ['20.99612929', '52.1364949', 'Warszawa,02-855,Sztajerka,28'],
    ['21.01395368', '52.11274822', 'Warszawa,02-868,Sarabandy,19A'],
    ['21.01411358', '52.13274059', 'Warszawa,02-862,Muzyczna,45'],
    ['20.99371884', '52.16674265', 'Warszawa,02-699,Kłobucka,16A'],
    ['21.00647697', '52.14133351', 'Warszawa,02-826,Poloneza,48'],
    ['21.05730887', '52.15608723', 'Warszawa,02-776,Urwisko,21'],
    ['21.03088462', '52.14930439', 'Warszawa,02-781,Czapli,23'],
    ['21.02503893', '52.1389868', 'Warszawa,02-814,Jerzyka,3'],
    ['20.99393353', '52.11404432', 'Warszawa,02-869,Dawidowska,58B'],
    ['21.0231009', '52.16137782', 'Warszawa,02-785,Puszczyka,18A'],
    ['20.99588757', '52.16551919', 'Warszawa,02-699,Taborowa,14'],
    ['21.019898', '52.125532', 'Warszawa,02-844,Puławska,531/533'],
    ['21.03486822', '52.16429391', 'Warszawa,02-787,Elegijna,5'],
    ['21.03843893', '52.15081419', 'Warszawa,02-776,Malinowskiego E.,3'],
    ['21.03300095', '52.15090288', 'Warszawa,02-784,Dembowskiego E.,23'],
    ['20.9942113', '52.16599817', 'Warszawa,02-699,Taborowa,20'],
    ['21.03867547', '52.15926771', 'Warszawa,02-786,Związku Walki Młodych,14'],
    ['21.08026262', '52.12805272', 'Warszawa,02-797,Nowoursynowska,42'],
    ['21.01547567', '52.1383764', 'Warszawa,02-830,Gawota,2B'],
    ['21.06024463', '52.13454889', 'Warszawa,02-793,Villardczyków,1'],
    ['21.01375802', '52.11470232', 'Warszawa,02-868,Sarabandy,24C'],
    ['21.0594185', '52.14296111', 'Warszawa,02-792,Lanciego F.,8'],
    ['21.03858887', '52.15722308', 'Warszawa,02-786,Związku Walki Młodych,8'],
    ['21.0173338', '52.12483923', 'Warszawa,02-884,Puławska,436'],
    ['21.05101637', '52.12982624', 'Warszawa,02-798,Ustronie,13'],
    ['21.04848717', '52.13448954', 'Warszawa,02-798,Jaworowa,53'],
    ['21.04329087', '52.16411495', 'Warszawa,02-787,Nowoursynowska,161C'],
    ['21.01591209', '52.13562546', 'Warszawa,02-858,Transportowców,29'],
    ['21.00007159', '52.13980361', 'Warszawa,02-850,Kądziołeczki,20A'],
    ['21.02022067', '52.11490683', 'Warszawa,02-886,Jagielska,63A/5'],
    ['21.01664512', '52.15013717', 'Warszawa,02-829,Mączeńskiego Z.,3'],
    ['21.07430631', '52.13600331', 'Warszawa,02-786,Rosoła J.,22C'],
    ['21.04795896', '52.13325929', 'Warszawa,02-798,Jaworowa,31'],
    ['21.04384788', '52.14967161', 'Warszawa,02-776,Hawajska,4'],
    ['21.0241581', '52.12961537', 'Warszawa,02-838,Kajakowa,1'],
    ['21.01727376', '52.13483072', 'Warszawa,02-845,Puławska,378'],
    ['21.02124735', '52.11428498', 'Warszawa,02-886,Jagielska,57M'],
    ['21.02083218', '52.15146482', 'Warszawa,02-804,Kraski,22'],
    ['21.02270185', '52.14545014', 'Warszawa,02-811,Makolągwy,20'],
    ['21.01264263', '52.12896331', 'Warszawa,02-860,Akustyczna,24'],
    ['21.01636968', '52.13336256', 'Warszawa,02-858,Transportowców,20'],
    ['21.01915719', '52.14594104', 'Warszawa,02-807,Bociania,9M'],
    ['21.01289073', '52.15364989', 'Warszawa,02-822,Poleczki,11'],
    ['21.07148374', '52.13536976', 'Warszawa,02-796,Dembego S.,28'],
    ['21.03106748', '52.11368376', 'Warszawa,02-886,Jagielska,34'],
    ['21.02916733', '52.13825491', 'Warszawa,02-815,Żołny,24C'],
    ['21.00215984', '52.11493031', 'Warszawa,02-850,Trombity,28'],
    ['21.00745923', '52.13764906', 'Warszawa,02-830,Gawota,27H'],
    ['21.01555114', '52.13032877', 'Warszawa,02-860,Kotylion,9'],
    ['21.01920787', '52.1283705', 'Warszawa,02-842,Trele,10'],
    ['21.00263129', '52.15175375', 'Warszawa,02-826,Polki,5'],
    ['21.05536487', '52.1456213', 'Warszawa,02-792,Lanciego F.,15'],
    ['21.0408476', '52.14469888', 'Warszawa,02-776,Hirszfelda L.,11'],
    ['21.01265883', '52.1427177', 'Warszawa,02-830,Rumby,25'],
    ['20.99151347', '52.15738713', 'Warszawa,02-820,Wyczółki,123'],
    ['21.01075175', '52.13595836', 'Warszawa,02-830,Gawota,13A'],
    ['21.0421573', '52.14736846', 'Warszawa,02-776,Gandhi I.,15'],
    ['21.01201738', '52.13767717', 'Warszawa,02-830,Gawota,9'],
    ['21.02344199', '52.1532141', 'Warszawa,02-784,Arctowskiego H.,27'],
    ['21.04562073', '52.13768778', 'Warszawa,02-795,Kazury S.,2A'],
    ['21.01834258', '52.14763295', 'Warszawa,02-801,Puławska,393'],
    ['21.01353841', '52.14064372', 'Warszawa,02-830,Korowodu,11'],
    ['21.07923624', '52.12932457', 'Warszawa,02-797,Nowoursynowska,50B'],
    ['21.04267186', '52.13852755', 'Warszawa,02-795,Kazury S.,24'],
    ['21.05709118', '52.12983664', 'Warszawa,02-798,Ekologiczna,26'],
    ['21.08070163', '52.13139287', 'Warszawa,02-796,Relaksowa,12G'],
    ['21.06544681', '52.14618463', 'Warszawa,02-790,Pachnąca,9'],
    ['20.99589145', '52.15704645', 'Warszawa,02-820,Wyczółki,99'],
    ['21.05759137', '52.13681708', 'Warszawa,02-793,Żabińskiego J.,5'],
    ['21.02760358', '52.14732467', 'Warszawa,02-781,Roentgena W.,12A'],
    ['21.0525174', '52.14828603', 'Warszawa,02-777,Kulczyńskiego S.,3'],
    ['21.05766702', '52.14459059', 'Warszawa,02-792,Lanciego F.,10G'],
    ['21.05540192', '52.1555458', 'Warszawa,02-776,Nowoursynowska,160'],
    ['20.99648467', '52.16538539', 'Warszawa,02-699,Taborowa,10'],
    ['21.01757672', '52.12181577', 'Warszawa,02-884,Puławska,456'],
    ['21.01947265', '52.1307794', 'Warszawa,02-838,Kajakowa,12B'],
    ['21.02379789', '52.15225369', 'Warszawa,02-784,Arctowskiego H.,6'],
    ['21.01056088', '52.15517592', 'Warszawa,02-820,Wyczółki,21'],
    ['21.01594227', '52.13501999', 'Warszawa,02-858,Transportowców,23'],
    ['21.03910831', '52.1549829', 'Warszawa,02-786,Związku Walki Młodych,1'],
    ['21.00506396', '52.15030983', 'Warszawa,02-826,Polki,1D'],
    ['20.99813469', '52.14388895', 'Warszawa,02-849,Krasnowolska,82J/3'],
    ['21.00692782', '52.13539011', 'Warszawa,02-856,Ludwinowska,52B'],
    ['21.01563279', '52.13278924', 'Warszawa,02-856,Ludwinowska,1'],
    ['21.01042544', '52.12871096', 'Warszawa,02-862,Farbiarska,63B'],
    ['21.00779038', '52.13304837', 'Warszawa,02-856,Ludwinowska,47F'],
    ['21.04307808', '52.13896017', 'Warszawa,02-795,Kazury S.,19'],
    ['20.99866302', '52.14153791', 'Warszawa,02-850,Tramblanki,33'],
    ['21.02091457', '52.14969092', 'Warszawa,02-807,Bociania,39'],
    ['21.00851456', '52.13253698', 'Warszawa,02-863,Jeziorki,95D'],
    ['21.0230428', '52.10623695', 'Warszawa,02-892,Kuropatwy,34'],
    ['21.06892054', '52.14188849', 'Warszawa,02-797,Nowoursynowska,103'],
    ['21.04215001', '52.16996286', 'Warszawa,02-768,Fosa,37A'],
    ['21.05591824', '52.14805001', 'Warszawa,02-777,Cynamonowa,3'],
    ['21.02406264', '52.10712407', 'Warszawa,02-892,Kuropatwy,34M'],
    ['21.06168753', '52.1449278', 'Warszawa,02-796,Mandarynki,8'],
    ['21.05178195', '52.14868742', 'Warszawa,02-777,Kulczyńskiego S.,5'],
    ['21.02202111', '52.12328318', 'Warszawa,02-843,Pelikanów,14'],
    ['21.02088824', '52.13508373', 'Warszawa,02-836,Modraszki,5'],
    ['21.00802076', '52.1238969', 'Warszawa,02-867,Baletowa,28'],
    ['21.000877', '52.14475419', 'Warszawa,02-850,Kądziołeczki,52C'],
    ['21.0196749', '52.11494256', 'Warszawa,02-886,Jagielska,63A/25'],
    ['21.04903533', '52.13435001', 'Warszawa,02-798,Jaworowa,40'],
    ['21.06578055', '52.14385943', 'Warszawa,02-790,Sengera Cichego M.,7'],
    ['21.06299712', '52.14735079', 'Warszawa,02-790,Pachnąca,44'],
    ['21.01004571', '52.11030795', 'Warszawa,02-871,Karczunkowska,43'],
    ['21.04803769', '52.13348192', 'Warszawa,02-798,Jaworowa,35'],
    ['21.06497263', '52.14566839', 'Warszawa,02-790,Pietraszewicza B.,7'],
    ['21.00537656', '52.15574323', 'Warszawa,02-822,Galopu,8'],
    ['21.02113295', '52.10668122', 'Warszawa,02-892,Kuropatwy,40B'],
    ['21.05994823', '52.13651702', 'Warszawa,02-793,Przy Bażantarni,10'],
    ['21.02437356', '52.1448029', 'Warszawa,02-811,Makolągwy,23D'],
    ['21.06718224', '52.1419753', 'Warszawa,02-786,Rosoła J.,44'],
    ['21.01652746', '52.14709455', 'Warszawa,02-829,Giewont,7'],
    ['21.06549177', '52.14487318', 'Warszawa,02-790,Sengera Cichego M.,24'],
    ['21.06604044', '52.1403313', 'Warszawa,02-793,Lokajskiego E.,18'],
    ['21.03257546', '52.10714344', 'Warszawa,02-892,Kuropatwy,8P'],
    ['21.01293076', '52.11404182', 'Warszawa,02-868,Sarabandy,28'],
    ['21.02217653', '52.1207777', 'Warszawa,02-814,Słonki,3B'],
    ['21.05020156', '52.13205223', 'Warszawa,02-833,Wełniana,22'],
    ['21.01118041', '52.15124573', 'Warszawa,02-824,Wodzirejów,13'],
    ['21.02399613', '52.13030327', 'Warszawa,02-838,Kajakowa,11C'],
    ['21.01012134', '52.13567131', 'Warszawa,02-856,Ludwinowska,24H'],
    ['21.03536571', '52.107384', 'Warszawa,02-892,Kuropatwy,2U'],
    ['21.07292778', '52.13309645', 'Warszawa,02-796,Dembego S.,18A'],
    ['21.02913828', '52.11391236', 'Warszawa,02-886,Jagielska,41B'],
    ['21.009739', '52.13813276', 'Warszawa,02-830,Gawota,12A'],
    ['21.01780152', '52.11316322', 'Warszawa,02-884,Puławska,512'],
    ['21.04867329', '52.13695395', 'Warszawa,02-834,Belgradzka,33'],
    ['21.07226664', '52.13140621', 'Warszawa,02-796,Zaruby J.,12'],
    ['21.05671259', '52.15586799', 'Warszawa,02-776,Urwisko,21A'],
    ['21.05743519', '52.13854937', 'Warszawa,02-793,Żabińskiego J.,14'],
    ['21.03098329', '52.14354058', 'Warszawa,02-811,Pustułeczki,4'],
    ['21.02245554', '52.15269492', 'Warszawa,02-784,Makowskiego T.,1'],
    ['21.01187324', '52.14554219', 'Warszawa,02-849,Walczyka,39'],
    ['21.0488319', '52.13305878', 'Warszawa,02-798,Jaworowa,16'],
    ['21.049791', '52.162298', 'Warszawa,02-787,Nowoursynowska,164/13'],
    ['21.013384', '52.108173', 'Warszawa,02-881,Cymbalistów,1'],
    ['21.02801602', '52.14366831', 'Warszawa,02-811,Pustułeczki,19'],
    ['21.04818191', '52.13273628', 'Warszawa,02-798,Jaworowa,6'],
    ['21.00133138', '52.11546252', 'Warszawa,02-850,Trombity,36'],
    ['21.02894485', '52.14925398', 'Warszawa,02-804,Jastrzębia,23'],
    ['21.01664232', '52.11187782', 'Warszawa,02-879,Kolbaczewska,8'],
    ['21.0063845', '52.13471713', 'Warszawa,02-856,Ludwinowska,54'],
    ['21.04508697', '52.17190201', 'Warszawa,02-768,Fosa,17'],
    ['21.05416868', '52.13976105', 'Warszawa,02-791,Meander,1A'],
    ['21.04776406', '52.13437403', 'Warszawa,02-834,Moczydłowska,63B'],
    ['21.05373296', '52.13069402', 'Warszawa,02-833,Wełniana,13'],
    ['21.04061217', '52.16945179', 'Warszawa,02-787,Nowoursynowska,183'],
    ['21.05418743', '52.14099661', 'Warszawa,02-791,Meander,12'],
    ['21.01959689', '52.15148502', 'Warszawa,02-804,Kraski,55'],
    ['21.06890952', '52.1354825', 'Warszawa,02-796,Jeżewskiego K.,5B'],
    ['21.03176336', '52.15182289', 'Warszawa,02-784,Dembowskiego E.,9'],
    ['21.01880436', '52.12624626', 'Warszawa,02-844,Puławska,525'],
    ['21.02379789', '52.16137058', 'Warszawa,02-785,Puszczyka,18'],
    ['21.03153936', '52.14282482', 'Warszawa,02-729,Rolna,25'],
    ['21.00769414', '52.14301209', 'Warszawa,02-849,Krasnowolska,31'],
    ['21.04923948', '52.13216032', 'Warszawa,02-833,Wełniana,26'],
    ['21.02323701', '52.15807391', 'Warszawa,02-785,Puszczyka,4'],
    ['21.01711262', '52.13815868', 'Warszawa,02-845,Puławska,354/356'],
    ['21.02620084', '52.16256434', 'Warszawa,02-785,Koński Jar,2'],
    ['21.049167', '52.159065', 'Warszawa,02-786,Ciszewskiego J.,2'],
    ['21.01413319', '52.13289047', 'Warszawa,02-862,Muzyczna,51'],
    ['21.03427226', '52.16452062', 'Warszawa,02-787,Elegijna,6'],
    ['21.04921273', '52.13442611', 'Warszawa,02-798,Jaworowa,42'],
    ['21.0508069', '52.13871377', 'Warszawa,02-791,Na Uboczu,3'],
    ['21.05548573', '52.13013988', 'Warszawa,02-886,Rybałtów,25'],
    ['21.01517587', '52.14740763', 'Warszawa,02-829,Taneczna,59A'],
    ['21.05823577', '52.15244566', 'Warszawa,02-776,Nowoursynowska,145A'],
    ['21.02293657', '52.13275644', 'Warszawa,02-837,Bogatki,8A'],
    ['21.0155724', '52.14653912', 'Warszawa,02-829,Taneczna,54/58'],
    ['21.00802144', '52.11511088', 'Warszawa,02-868,Dumki,3C'],
    ['21.07064924', '52.13377054', 'Warszawa,02-796,Bronikowskiego K.,4A'],
    ['21.03035897', '52.14949675', 'Warszawa,02-807,Kukułki,6'],
    ['21.01442522', '52.12361218', 'Warszawa,02-846,Sporna,19A'],
    ['21.00275454', '52.11235667', 'Warszawa,02-850,Trombity,13LA'],
    ['21.0193479', '52.14907424', 'Warszawa,02-806,Albatrosów,12'],
    ['21.02261411', '52.11918191', 'Warszawa,02-843,Tukana,8'],
    ['21.0116096', '52.13014543', 'Warszawa,02-862,Farbiarska,42K'],
    ['21.02063892', '52.1188438', 'Warszawa,02-843,Tukana,5A'],
    ['21.02207337', '52.11946642', 'Warszawa,02-843,Tukana,8B'],
    ['21.02634309', '52.10630704', 'Warszawa,02-892,Kuropatwy,26'],
    ['20.99473682', '52.11201542', 'Warszawa,02-869,Buszycka,21'],
    ['21.03286209', '52.11293996', 'Warszawa,02-886,Jagielska,32A'],
    ['21.05882145', '52.13310187', 'Warszawa,02-793,Płk. Baló Z.,6'],
    ['21.00896504', '52.14389959', 'Warszawa,02-849,Krasnowolska,32B'],
    ['21.01244017', '52.13289186', 'Warszawa,02-862,Farbiarska,58'],
    ['21.01919249', '52.11353124', 'Warszawa,02-885,Puławska,597'],
    ['21.06014468', '52.13897865', 'Warszawa,02-793,Raabego H.,7'],
    ['20.9806458', '52.12916236', 'Warszawa,02-867,Podolszyńska,3'],
    ['21.05628621', '52.14028021', 'Warszawa,02-793,Belgradzka,42'],
    ['21.02930145', '52.15830217', 'Warszawa,02-785,Wiolinowa,2A'],
    ['21.0231643', '52.12962047', 'Warszawa,02-838,Kajakowa,7'],
    ['21.08119035', '52.12769573', 'Warszawa,02-798,Rzekotki,4'],
    ['21.0532769', '52.14695965', 'Warszawa,02-777,Kulczyńskiego S.,8A'],
    ['21.0235822', '52.15040778', 'Warszawa,02-803,Bekasów,120'],
    ['21.06353283', '52.14813168', 'Warszawa,02-797,Nowoursynowska,129'],
    ['21.05536032', '52.13550872', 'Warszawa,02-793,Małej Łąki,30'],
    ['21.02215733', '52.12127367', 'Warszawa,02-814,Słonki,5K'],
    ['21.02365801', '52.11366347', 'Warszawa,02-886,Jagielska,51R'],
    ['21.01485004', '52.13806992', 'Warszawa,02-830,Gawota,4'],
    ['21.03698088', '52.14435685', 'Warszawa,02-781,Pileckiego W.,103'],
    ['21.00772074', '52.11840526', 'Warszawa,02-868,Sarabandy,80'],
    ['21.03008178', '52.1441407', 'Warszawa,02-811,Makolągwy,7'],
    ['21.02859136', '52.14885151', 'Warszawa,02-781,Łukaszczyka P.,35'],
    ['21.01409995', '52.13612899', 'Warszawa,02-857,Organistów,10'],
    ['21.00109785', '52.12569188', 'Warszawa,02-863,Jeziorki,10B'],
    ['21.03627386', '52.15461098', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,81'],
    ['21.07088972', '52.13901049', 'Warszawa,02-786,Rosoła J.,26A'],
    ['21.01436611', '52.14507303', 'Warszawa,02-849,Krasnowolska,3F'],
    ['21.02843796', '52.15029768', 'Warszawa,02-781,Czapli,57'],
    ['21.01521209', '52.13755019', 'Warszawa,02-830,Gawota,3A'],
    ['21.02941727', '52.15389861', 'Warszawa,02-784,Dunikowskiego X.,2'],
    ['21.01896264', '52.12911667', 'Warszawa,02-840,Leśna,1'],
    ['21.01925187', '52.11215982', 'Warszawa,02-885,Puławska,603/607'],
    ['21.04604052', '52.16876345', 'Warszawa,02-768,Renety,7'],
    ['21.01209372', '52.14299159', 'Warszawa,02-830,Rumby,8'],
    ['21.02975968', '52.14418224', 'Warszawa,02-811,Makolągwy,9'],
    ['21.01262477', '52.120493', 'Warszawa,02-862,Farbiarska,11'],
    ['21.05254584', '52.13158992', 'Warszawa,02-833,Wełniana,27'],
    ['21.0637216', '52.142824', 'Warszawa,02-792,Lasek Brzozowy,4'],
    ['21.01518069', '52.14168386', 'Warszawa,02-829,Taneczna,23'],
    ['21.01260779', '52.15066075', 'Warszawa,02-827,Menueta,26'],
    ['21.02650702', '52.13824983', 'Warszawa,02-818,Kolibrów,21'],
    ['21.02491072', '52.15309408', 'Warszawa,02-784,Dunikowskiego X.,34'],
    ['21.07774996', '52.13470607', 'Warszawa,02-797,Rosoła J.,16E'],
    ['21.03087681', '52.14936057', 'Warszawa,02-781,Czapli,25'],
    ['21.07990992', '52.13221832', 'Warszawa,02-796,Kulisiewicza,6'],
    ['21.02319948', '52.14420367', 'Warszawa,02-811,Pustułeczki,37A'],
    ['21.04309793', '52.16658429', 'Warszawa,02-787,Nowoursynowska,171A'],
    ['21.08375324', '52.12558131', 'Warszawa,02-797,Nowoursynowska,26A'],
    ['21.019186', '52.13751079', 'Warszawa,02-816,Rajskich Ptaków,53'],
    ['21.00216571', '52.13572393', 'Warszawa,02-855,Sztajerka,5'],
    ['21.02082876', '52.13170449', 'Warszawa,02-838,Raniuszka,33'],
    ['21.01198758', '52.13742037', 'Warszawa,02-830,Gawota,9B'],
    ['21.04159875', '52.16405498', 'Warszawa,02-787,Nowoursynowska,161F'],
    ['21.05026327', '52.15785915', 'Warszawa,02-777,Ciszewskiego J.,5'],
    ['20.9897122', '52.1588046', 'Warszawa,02-699,Nowy Służewiec,4'],
    ['21.02883724', '52.15151568', 'Warszawa,02-784,Dembowskiego E.,15'],
    ['21.07226317', '52.13400355', 'Warszawa,02-796,Dembego S.,22'],
    ['21.019537', '52.108609', 'Warszawa,02-885,Puławska,617/621'],
    ['21.00214103', '52.14505347', 'Warszawa,02-850,Kądziołeczki,54T'],
    ['21.03411911', '52.10841837', 'Warszawa,02-892,Bażancia,11A'],
    ['21.06570828', '52.14464135', 'Warszawa,02-790,Sengera Cichego M.,18'],
    ['21.01417774', '52.13838672', 'Warszawa,02-830,Gawota,4A'],
    ['21.02430173', '52.11400546', 'Warszawa,02-886,Jagielska,51D'],
    ['21.02269687', '52.1489042', 'Warszawa,02-803,Bekasów,46B'],
    ['21.02818826', '52.1443758', 'Warszawa,02-811,Makolągwy,15'],
    ['21.0603016', '52.13378543', 'Warszawa,02-793,Boglarczyków,5'],
    ['21.02505207', '52.14309959', 'Warszawa,02-781,Roentgena W.,45'],
    ['21.03156355', '52.10659765', 'Warszawa,02-893,Perlicza,6'],
    ['21.0267806', '52.13908119', 'Warszawa,02-818,Kolibrów,29'],
    ['21.02345271', '52.1151212', 'Warszawa,02-886,Jagielska,55'],
    ['21.0555882', '52.12903532', 'Warszawa,02-886,Rybałtów,21'],
    ['21.01190838', '52.13705105', 'Warszawa,02-830,Gawota,9E'],
    ['21.04822372', '52.14250458', 'Warszawa,02-791,Na Uboczu,28C'],
    ['21.04758615', '52.13281778', 'Warszawa,02-798,Jaworowa,17'],
    ['21.02888706', '52.14758139', 'Warszawa,02-781,Św. M. Kolbego,2B'],
    ['21.07975957', '52.13113011', 'Warszawa,02-796,Relaksowa,12D'],
    ['21.01558483', '52.11252049', 'Warszawa,02-879,Chóralna,9C'],
    ['21.01474696', '52.14900618', 'Warszawa,02-829,Taneczna,65'],
    ['21.05541081', '52.15693777', 'Warszawa,02-777,Kopcińskiego S.,1'],
    ['21.01712792', '52.11631581', 'Warszawa,02-878,Gajdy,6'],
    ['21.0227696', '52.15985565', 'Warszawa,02-785,Puszczyka,14'],
    ['21.05980226', '52.15504964', 'Warszawa,02-797,Kokosowa,53D'],
    ['21.0588348', '52.13423469', 'Warszawa,02-793,Plakatowa,19'],
    ['21.04115524', '52.14975001', 'Warszawa,02-776,Hawajska,7'],
    ['20.99801402', '52.11025437', 'Warszawa,02-874,Achillesa,7'],
    ['21.01627706', '52.12669387', 'Warszawa,02-847,Kostki Napierskiego A.,6'],
    ['21.02550619', '52.10650581', 'Warszawa,02-892,Kuropatwy,28D'],
    ['21.01161548', '52.13609237', 'Warszawa,02-830,Gawota,9K'],
    ['21.0227229', '52.15091503', 'Warszawa,02-804,Kraski,45'],
    ['21.04206999', '52.17045041', 'Warszawa,02-768,Fosa,35'],
    ['21.01338889', '52.13273951', 'Warszawa,02-856,Ludwinowska,19G'],
    ['21.0588808', '52.15385704', 'Warszawa,02-797,Modelowa,3'],
    ['21.03873292', '52.16426955', 'Warszawa,02-787,Chłapowskiego D.,13'],
    ['21.03706793', '52.10830403', 'Warszawa,02-892,Bażancia,4'],
    ['21.00670044', '52.11953656', 'Warszawa,02-868,Sarabandy,88'],
    ['21.05943951', '52.15250761', 'Warszawa,02-797,Pistacjowa,7'],
    ['21.02540328', '52.108187', 'Warszawa,02-892,Bażancia,41'],
    ['21.05585533', '52.15547882', 'Warszawa,02-776,Urwisko,21C'],
    ['21.04545026', '52.16874172', 'Warszawa,02-768,Anyżkowa,10'],
    ['20.99651575', '52.1398839', 'Warszawa,02-851,Puchalska,5'],
    ['21.0050094', '52.12330648', 'Warszawa,02-867,Baletowa,41'],
    ['21.05328648', '52.12898454', 'Warszawa,02-835,Elegancka,7'],
    ['21.06814655', '52.14340667', 'Warszawa,02-797,Nowoursynowska,111B'],
    ['21.02232669', '52.14896741', 'Warszawa,02-803,Bekasów,46A'],
    ['21.06098304', '52.1465321', 'Warszawa,02-796,Migdałowa,45'],
    ['21.04897914', '52.13422416', 'Warszawa,02-798,Jaworowa,38'],
    ['21.05446895', '52.12879728', 'Warszawa,02-835,Batystowa,1C'],
    ['21.075355', '52.132266', 'Warszawa,02-797,Nowoursynowska,77'],
    ['21.05611185', '52.15800959', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,12'],
    ['21.02699173', '52.10629451', 'Warszawa,02-892,Kuropatwy,24'],
    ['21.01660833', '52.11575309', 'Warszawa,02-878,Gajdy,1'],
    ['21.01752826', '52.12924356', 'Warszawa,02-845,Puławska,412'],
    ['21.01769769', '52.11828613', 'Warszawa,02-884,Puławska,476'],
    ['21.01856715', '52.14044635', 'Warszawa,02-801,Puławska,437'],
    ['21.00994241', '52.14138554', 'Warszawa,02-849,Krasnowolska,21/27B'],
    ['21.02658928', '52.15210817', 'Warszawa,02-784,Dunikowskiego X.,9'],
    ['21.06533356', '52.14635523', 'Warszawa,02-790,Pachnąca,13'],
    ['21.05770057', '52.13331036', 'Warszawa,02-793,Boglarczyków,35'],
    ['21.02296517', '52.13497103', 'Warszawa,02-836,Kormoranów,1D'],
    ['21.07089212', '52.13324846', 'Warszawa,02-796,Bronikowskiego K.,2'],
    ['21.00815168', '52.13286171', 'Warszawa,02-856,Ludwinowska,47T'],
    ['21.0171132', '52.11657551', 'Warszawa,02-878,Gajdy,6A'],
    ['21.02139302', '52.14024962', 'Warszawa,02-814,Gruchacza,33A'],
    ['21.04772332', '52.16312124', 'Warszawa,02-787,Nowoursynowska,164'],
    ['21.02185795', '52.16077066', 'Warszawa,02-785,Puszczyka,17/19'],
    ['21.01201871', '52.12617993', 'Warszawa,02-862,Farbiarska,49'],
    ['21.04243721', '52.13938079', 'Warszawa,02-795,Kazury S.,17'],
    ['21.0586897', '52.15486264', 'Warszawa,02-797,Kokosowa,55'],
    ['21.0212894', '52.13859342', 'Warszawa,02-815,Żołny,48'],
    ['21.03150297', '52.1439638', 'Warszawa,02-811,Makolągwy,1'],
    ['21.01093005', '52.15064388', 'Warszawa,02-827,Menueta,40'],
    ['21.01946394', '52.13210066', 'Warszawa,02-816,Rajskich Ptaków,13'],
    ['21.04291183', '52.17114903', 'Warszawa,02-768,Fosa,29'],
    ['21.05622845', '52.13882223', 'Warszawa,02-793,Żabińskiego J.,20'],
    ['21.01701805', '52.13845305', 'Warszawa,02-845,Puławska,352'],
    ['21.01605892', '52.1459482', 'Warszawa,02-828,Liptowska,8'],
    ['21.08157456', '52.1288206', 'Warszawa,02-798,Kuny,5'],
    ['21.04495035', '52.16846292', 'Warszawa,02-787,Nowoursynowska,172L'],
    ['21.05114922', '52.15175069', 'Warszawa,02-777,Szolc-Rogozińskiego,14'],
    ['21.05308874', '52.15042451', 'Warszawa,02-777,Szolc-Rogozińskiego,3'],
    ['21.01760788', '52.11905525', 'Warszawa,02-884,Puławska,470'],
    ['21.06965173', '52.14105015', 'Warszawa,02-797,Nowoursynowska,97'],
    ['21.05400577', '52.1552015', 'Warszawa,02-776,Nugat,5A'],
    ['20.99269533', '52.11063075', 'Warszawa,02-871,Karczunkowska,144'],
    ['21.00729729', '52.11425783', 'Warszawa,02-850,Trombity,22P'],
    ['21.06431483', '52.145411', 'Warszawa,02-790,Pietraszewicza B.,11'],
    ['21.01289559', '52.1191579', 'Warszawa,02-862,Farbiarska,5'],
    ['21.01110611', '52.11964925', 'Warszawa,02-862,Farbiarska,7B'],
    ['21.02333238', '52.10642458', 'Warszawa,02-892,Kuropatwy,34C'],
    ['21.01154994', '52.13577814', 'Warszawa,02-830,Gawota,9M'],
    ['21.06884597', '52.13396832', 'Warszawa,02-796,Bronikowskiego K.,53'],
    ['21.05477115', '52.13822935', 'Warszawa,02-793,Małcużyńskiego W.,9'],
    ['21.02283774', '52.12082117', 'Warszawa,02-814,Słonki,3A'],
    ['21.01138247', '52.14238823', 'Warszawa,02-830,Samby,23'],
    ['21.01653475', '52.14693', 'Warszawa,02-829,Giewont,1'],
    ['21.03084175', '52.13761678', 'Warszawa,02-815,Żołny,16'],
    ['21.04601464', '52.15183088', 'Warszawa,02-777,Cynamonowa,33'],
    ['20.99038598', '52.15815681', 'Warszawa,02-820,Wyczółki,96/98'],
    ['21.05936406', '52.13093783', 'Warszawa,02-796,Wąwozowa,29'],
    ['21.01563558', '52.11860915', 'Warszawa,02-878,Gajdy,25'],
    ['21.03015076', '52.14980691', 'Warszawa,02-781,Czapli,37'],
    ['21.01832821', '52.1458777', 'Warszawa,02-801,Puławska,403A'],
    ['21.02469362', '52.1447921', 'Warszawa,02-811,Makolągwy,23B'],
    ['21.01663846', '52.1178444', 'Warszawa,02-878,Gajdy,17'],
    ['21.03811794', '52.14937354', 'Warszawa,02-776,Miklaszewskiego,14A'],
    ['21.05977401', '52.15031588', 'Warszawa,02-776,Nowoursynowska,139P'],
    ['20.99603642', '52.16112983', 'Warszawa,02-699,Pieskowa Skała,5'],
    ['21.03443736', '52.16421441', 'Warszawa,02-787,Elegijna,17'],
    ['21.02187532', '52.12298274', 'Warszawa,02-843,Pelikanów,21'],
    ['21.01953221', '52.15729664', 'Warszawa,02-785,Surowieckiego W.,12C'],
    ['21.0183507', '52.14478817', 'Warszawa,02-801,Puławska,413A'],
    ['21.03276657', '52.1611848', 'Warszawa,02-787,Wokalna,1'],
    ['21.06020828', '52.15488067', 'Warszawa,02-797,Kokosowa,53'],
    ['21.06323318', '52.14581167', 'Warszawa,02-786,Rosoła J.,48'],
    ['21.01912698', '52.12582034', 'Warszawa,02-842,Rataja M.,21'],
    ['21.05019257', '52.12954103', 'Warszawa,02-798,Ustronie,7'],
    ['21.0225098', '52.13256568', 'Warszawa,02-837,Bogatki,10'],
    ['21.05312715', '52.14177163', 'Warszawa,02-791,Meander,20'],
    ['21.00938612', '52.14226634', 'Warszawa,02-830,Flamenco,13'],
    ['21.01849821', '52.14398182', 'Warszawa,02-801,Puławska,413'],
    ['21.04926203', '52.14035445', 'Warszawa,02-791,Na Uboczu,9'],
    ['21.06078795', '52.15042168', 'Warszawa,02-776,Nowoursynowska,139C'],
    ['21.00856555', '52.14366547', 'Warszawa,02-849,Krasnowolska,32U'],
    ['21.02235275', '52.12477881', 'Warszawa,02-843,6 Sierpnia,9'],
    ['21.05085827', '52.13157158', 'Warszawa,02-833,Wełniana,35a'],
    ['21.01632655', '52.12261802', 'Warszawa,02-867,Baletowa,4B'],
    ['21.02004625', '52.1286127', 'Warszawa,02-840,Leśna,8C'],
    ['21.02229263', '52.11773922', 'Warszawa,02-886,Jagielska,46K'],
    ['21.02284094', '52.13339022', 'Warszawa,02-837,Puchaczy,10'],
    ['21.04237958', '52.14361257', 'Warszawa,02-776,Hirszfelda L.,8'],
    ['21.05729006', '52.12994645', 'Warszawa,02-798,Ekologiczna,24'],
    ['21.05385831', '52.15778498', 'Warszawa,02-777,Kopcińskiego S.,8'],
    ['21.04381169', '52.15472876', 'Warszawa,02-777,Magellana F.,11'],
    ['21.00383659', '52.11071379', 'Warszawa,02-871,Karczunkowska,82B'],
    ['21.06077738', '52.13946853', 'Warszawa,02-793,Raabego H.,9'],
    ['21.00100312', '52.12629774', 'Warszawa,02-863,Jeziorki,14'],
    ['21.08234802', '52.12717141', 'Warszawa,02-797,Nowoursynowska,36A'],
    ['21.01522744', '52.14524783', 'Warszawa,02-829,Taneczna,47'],
    ['21.0142934', '52.11749121', 'Warszawa,02-875,Klarnecistów,18'],
    ['21.01439423', '52.1283227', 'Warszawa,02-860,Akustyczna,17A'],
    ['21.06608271', '52.14506383', 'Warszawa,02-790,Sotta K. Sokoła,15'],
    ['21.01219657', '52.10540635', 'Warszawa,02-882,Katarynki,32'],
    ['21.01515238', '52.12540545', 'Warszawa,02-847,Klubowa,8A'],
    ['21.043299', '52.14044911', 'Warszawa,02-795,Kazury S.,13'],
    ['21.02102611', '52.11894404', 'Warszawa,02-843,Tukana,7'],
    ['21.02071814', '52.14142191', 'Warszawa,02-812,Ibisa,6'],
    ['21.02138132', '52.11776871', 'Warszawa,02-886,Jagielska,50E'],
    ['20.99868646', '52.14180446', 'Warszawa,02-850,Tramblanki,35'],
    ['21.01250687', '52.13668799', 'Warszawa,02-830,Gawota,11U'],
    ['21.02408898', '52.12906704', 'Warszawa,02-840,Leśna,21'],
    ['21.03867417', '52.15019418', 'Warszawa,02-776,Malinowskiego E.,1'],
    ['21.0575575', '52.15618848', 'Warszawa,02-776,Urwisko,19'],
    ['20.99475339', '52.16588623', 'Warszawa,02-699,Taborowa,16'],
    ['21.02076429', '52.12847142', 'Warszawa,02-840,Leśna,12'],
    ['21.05626728', '52.15756247', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,2'],
    ['21.04499387', '52.16797904', 'Warszawa,02-787,Nowoursynowska,172F'],
    ['21.01462075', '52.11299994', 'Warszawa,02-868,Sarabandy,16/22'],
    ['21.020599', '52.15154', 'Warszawa,02-804,Kraski,20/22'],
    ['21.06547158', '52.14561654', 'Warszawa,02-790,Pietraszewicza B.,5A'],
    ['21.02188242', '52.15532009', 'Warszawa,02-780,Barwna,8'],
    ['21.02502665', '52.10670955', 'Warszawa,02-892,Kuropatwy,28H'],
    ['21.0356309', '52.14960102', 'Warszawa,02-776,Miklaszewskiego,16'],
    ['21.00305593', '52.1424056', 'Warszawa,02-849,Krasnowolska,58K'],
    ['21.05445616', '52.14045617', 'Warszawa,02-791,Meander,4'],
    ['21.06807676', '52.13911939', 'Warszawa,02-793,Przy Bażantarni,2'],
    ['20.99637228', '52.11148551', 'Warszawa,02-869,Buszycka,18C'],
    ['21.04299361', '52.14323362', 'Warszawa,02-776,Dereniowa,5'],
    ['21.01173417', '52.13006454', 'Warszawa,02-860,Czterech Wiatrów,25'],
    ['21.01401396', '52.11856342', 'Warszawa,02-878,Gajdy,33'],
    ['21.02404647', '52.11815142', 'Warszawa,02-843,Tukana,13A'],
    ['21.00425638', '52.13592023', 'Warszawa,02-855,Sztajerka,02-Apr'],
    ['21.05305564', '52.15508966', 'Warszawa,02-776,Nugat,7A'],
    ['21.02521336', '52.14480865', 'Warszawa,02-811,Makolągwy,23'],
    ['21.08210203', '52.12960606', 'Warszawa,02-798,Kuny,8'],
    ['21.06032144', '52.15079119', 'Warszawa,02-776,Nowoursynowska,139G'],
    ['21.00833413', '52.13445106', 'Warszawa,02-856,Ludwinowska,45'],
    ['20.99659226', '52.13648485', 'Warszawa,02-855,Sztajerka,26'],
    ['21.01175755', '52.14553975', 'Warszawa,02-849,Walczyka,41'],
    ['21.01393968', '52.14365722', 'Warszawa,02-829,Taneczna,33E'],
    ['21.05572337', '52.13948412', 'Warszawa,02-793,Belgradzka,46A'],
    ['21.00339681', '52.15633688', 'Warszawa,02-822,Galopu,7'],
    ['21.01108334', '52.13747227', 'Warszawa,02-830,Gawota,13E'],
    ['21.02538945', '52.16497758', 'Warszawa,02-785,Koński Jar,9'],
    ['21.02797021', '52.14802608', 'Warszawa,02-781,Roentgena W.,6'],
    ['21.00225351', '52.11440482', 'Warszawa,02-850,Trombity,21'],
    ['21.03812952', '52.16459801', 'Warszawa,02-787,Chłapowskiego D.,18'],
    ['20.99429954', '52.112016', 'Warszawa,02-869,Buszycka,21B'],
    ['21.02836444', '52.10644554', 'Warszawa,02-892,Kuropatwy,20'],
    ['21.05630629', '52.15917871', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,32D'],
    ['21.01328468', '52.15094177', 'Warszawa,02-824,Wodzirejów,5E'],
    ['21.06180804', '52.15188227', 'Warszawa,02-797,Kokosowa,11'],
    ['21.02550573', '52.10694116', 'Warszawa,02-892,Kuropatwy,28M'],
    ['21.00066157', '52.14504647', 'Warszawa,02-850,Kądziołeczki,54A'],
    ['21.01209188', '52.12134609', 'Warszawa,02-878,Głęboka,5'],
    ['20.9977006', '52.16449328', 'Warszawa,02-699,Taborowa,33B'],
    ['21.00806152', '52.13791294', 'Warszawa,02-830,Gawota,27'],
    ['21.04709568', '52.13464874', 'Warszawa,02-834,Moczydłowska,67B'],
    ['21.05212685', '52.13163603', 'Warszawa,02-833,Wełniana,31'],
    ['21.04460531', '52.15399976', 'Warszawa,02-777,Magellana F.,7'],
    ['21.00136874', '52.113715', 'Warszawa,02-850,Trombity,21B'],
    ['21.04706576', '52.13458777', 'Warszawa,02-834,Moczydłowska,67C'],
    ['21.03813874', '52.15678198', 'Warszawa,02-786,Bacewiczówny G.,1'],
    ['21.02049528', '52.14160263', 'Warszawa,02-812,Ibisa,8'],
    ['21.0332042', '52.10713523', 'Warszawa,02-892,Kuropatwy,8H'],
    ['21.0486173', '52.1601638', 'Warszawa,02-787,Nowoursynowska,159C'],
    ['21.00896297', '52.14433088', 'Warszawa,02-849,Krasnowolska,32G'],
    ['21.01593706', '52.14934407', 'Warszawa,02-829,Białej Wody,10'],
    ['21.05101127', '52.14234895', 'Warszawa,02-791,Meander,23'],
    ['21.01744623', '52.12534187', 'Warszawa,02-884,Puławska,434'],
    ['21.03609895', '52.1418073', 'Warszawa,02-781,Pileckiego W.,109'],
    ['21.01953436', '52.13786748', 'Warszawa,02-816,Rajskich Ptaków,55A'],
    ['21.04295036', '52.16372405', 'Warszawa,02-787,Nowoursynowska,161A'],
    ['21.04732854', '52.13246454', 'Warszawa,02-798,Jaworowa,5'],
    ['21.01306788', '52.11277167', 'Warszawa,02-868,Sarabandy,27C'],
    ['21.06928313', '52.14128704', 'Warszawa,02-797,Nowoursynowska,99A'],
    ['20.99363102', '52.12007207', 'Warszawa,02-850,Trombity,85/87'],
    ['21.01333408', '52.13022952', 'Warszawa,02-860,Czterech Wiatrów,49'],
    ['21.00717643', '52.13777577', 'Warszawa,02-830,Gawota,29'],
    ['21.06915794', '52.14085207', 'Warszawa,02-797,Nowoursynowska,97F'],
    ['21.02944429', '52.14841419', 'Warszawa,02-781,Św. M. Kolbego,15'],
    ['21.03464862', '52.16485542', 'Warszawa,02-787,Okaryny,13'],
    ['21.08066723', '52.13027153', 'Warszawa,02-797,Jeża,7'],
    ['21.02328387', '52.11318829', 'Warszawa,02-886,Jagielska,53F'],
    ['21.02013619', '52.13744958', 'Warszawa,02-817,Białozora,46'],
    ['21.05562551', '52.15891037', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,28D'],
    ['21.03318355', '52.16314194', 'Warszawa,02-787,Symfonii,2'],
    ['20.99867628', '52.1166727', 'Warszawa,02-850,Trombity,49'],
    ['21.03446387', '52.1081453', 'Warszawa,02-892,Bażancia,9C'],
    ['21.03411896', '52.10822966', 'Warszawa,02-892,Bażancia,11B'],
    ['21.07082153', '52.13859743', 'Warszawa,02-786,Rosoła J.,24'],
    ['21.0239859', '52.12868248', 'Warszawa,02-840,Leśna,26'],
    ['21.00607496', '52.15531653', 'Warszawa,02-820,Wyczółki,31'],
    ['21.01928073', '52.1416903', 'Warszawa,02-812,Ibisa,16'],
    ['21.00031029', '52.1128664', 'Warszawa,02-850,Trombity,21E'],
    ['21.00689694', '52.13478186', 'Warszawa,02-856,Ludwinowska,52'],
    ['21.00749234', '52.14082981', 'Warszawa,02-830,Mazura,10'],
    ['21.01210179', '52.15457227', 'Warszawa,02-822,Poleczki,16'],
    ['21.05219111', '52.15813506', 'Warszawa,02-777,Przybylskiego Cz.,10'],
    ['21.0758903', '52.13403671', 'Warszawa,02-786,Rosoła J.,10'],
    ['21.02229601', '52.11760281', 'Warszawa,02-886,Jagielska,46H'],
    ['21.03069918', '52.14413511', 'Warszawa,02-811,Makolągwy,5'],
    ['21.03112195', '52.14310005', 'Warszawa,02-811,Pustułeczki,3A'],
    ['21.02080687', '52.13651746', 'Warszawa,02-836,Kormoranów,18'],
    ['21.02331725', '52.14504412', 'Warszawa,02-811,Makolągwy,23L'],
    ['20.99387099', '52.15733539', 'Warszawa,02-820,Wyczółki,74'],
    ['21.00252052', '52.14505434', 'Warszawa,02-850,Kądziołeczki,54X'],
    ['21.01856753', '52.14136523', 'Warszawa,02-801,Puławska,435'],
    ['21.06969045', '52.13353634', 'Warszawa,02-796,Bronikowskiego K.,11'],
    ['20.99901518', '52.15478023', 'Warszawa,02-854,Hołubcowa,124'],
    ['21.02179337', '52.12606891', 'Warszawa,02-842,Rataja M.,10A'],
    ['21.05638553', '52.15691986', 'Warszawa,02-776,Nowoursynowska,160H'],
    ['21.02083812', '52.1494515', 'Warszawa,02-807,Bociania,37'],
    ['21.05938651', '52.15500154', 'Warszawa,02-797,Kokosowa,53B'],
    ['21.07779669', '52.13255856', 'Warszawa,02-797,Nowoursynowska,68'],
    ['21.05611035', '52.14345221', 'Warszawa,02-792,Lanciego F.,9P'],
    ['21.00029064', '52.14337361', 'Warszawa,02-850,Kądziołeczki,59'],
    ['21.02389619', '52.12849401', 'Warszawa,02-840,Leśna,26A'],
    ['21.02315105', '52.15734446', 'Warszawa,02-785,Puszczyka,2'],
    ['21.03422319', '52.16412144', 'Warszawa,02-787,Elegijna,23'],
    ['21.02371677', '52.13514523', 'Warszawa,02-836,Kormoranów,1B'],
    ['21.02212643', '52.13273289', 'Warszawa,02-837,Bogatki,12A'],
    ['21.0532781', '52.129396', 'Warszawa,02-835,Elegancka,19'],
    ['21.00828617', '52.13783042', 'Warszawa,02-830,Gawota,25B'],
    ['21.01441611', '52.14733926', 'Warszawa,02-829,Taneczna,59C'],
    ['21.05991187', '52.15298209', 'Warszawa,02-797,Pistacjowa,14'],
    ['21.05353961', '52.13644564', 'Warszawa,02-793,Małej Łąki,7'],
    ['21.01470116', '52.14268916', 'Warszawa,02-830,Samby,6'],
    ['21.06913227', '52.13345192', 'Warszawa,02-796,Bronikowskiego K.,17'],
    ['21.00034059', '52.14406358', 'Warszawa,02-850,Kądziołeczki,71'],
    ['21.02814074', '52.14961377', 'Warszawa,02-804,Gżegżółki,16'],
    ['21.02978369', '52.10628243', 'Warszawa,02-893,Synogarlicy,1'],
    ['21.01646704', '52.12222503', 'Warszawa,02-867,Baletowa,4'],
    ['21.02344731', '52.12864184', 'Warszawa,02-840,Leśna,24'],
    ['21.06060787', '52.15418611', 'Warszawa,02-797,Kokosowa,47'],
    ['21.06153558', '52.13946696', 'Warszawa,02-793,Raabego H.,12'],
    ['21.02801913', '52.15037099', 'Warszawa,02-781,Czapli,63'],
    ['21.05411999', '52.13578134', 'Warszawa,02-793,Małej Łąki,10'],
    ['21.01650547', '52.14887895', 'Warszawa,02-829,Giewont,41'],
    ['21.02210396', '52.15845272', 'Warszawa,02-785,Puszczyka,7'],
    ['21.02945584', '52.1143622', 'Warszawa,02-886,Jagielska,36C'],
    ['21.0441895', '52.15427185', 'Warszawa,02-777,Magellana F.,9'],
    ['21.02078846', '52.1530606', 'Warszawa,02-784,Janowskiego A.,28'],
    ['21.0093838', '52.14210387', 'Warszawa,02-830,Flamenco,11'],
    ['21.00574785', '52.15613127', 'Warszawa,02-820,Wyczółki,41'],
    ['21.06502356', '52.14602775', 'Warszawa,02-790,Pachnąca,1'],
    ['21.05184637', '52.12989542', 'Warszawa,02-833,Wełniana,29F'],
    ['21.01568651', '52.15076224', 'Warszawa,02-829,Mączeńskiego Z.,41'],
    ['21.06897845', '52.14116124', 'Warszawa,02-797,Nowoursynowska,99E'],
    ['21.0446346', '52.14965174', 'Warszawa,02-777,Al. Komisji Edukacji Narodowej,63'],
    ['21.02394186', '52.11872418', 'Warszawa,02-843,Tukana,13'],
    ['21.02145017', '52.12295993', 'Warszawa,02-843,Pelikanów,25'],
    ['20.99439317', '52.11112797', 'Warszawa,02-869,Buszycka,9'],
    ['21.01708603', '52.11543839', 'Warszawa,02-877,Drumli,3'],
    ['21.01960857', '52.13426714', 'Warszawa,02-836,Dzierzby,26'],
    ['21.07008087', '52.13424074', 'Warszawa,02-796,Dembego S.,23B'],
    ['21.01095091', '52.14532527', 'Warszawa,02-849,Krasnowolska,20H'],
    ['21.01714795', '52.14505314', 'Warszawa,02-845,Puławska,314'],
    ['21.01900006', '52.15179574', 'Warszawa,02-804,Kraski,30'],
    ['21.02060946', '52.13385324', 'Warszawa,02-836,Dzierzby,23A'],
    ['21.02856656', '52.14984098', 'Warszawa,02-804,Gżegżółki,7'],
    ['21.05549666', '52.15852829', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,26'],
    ['21.01505029', '52.13168941', 'Warszawa,02-859,Głuszca,18A'],
    ['21.01392173', '52.11807914', 'Warszawa,02-875,Kobzy,14'],
    ['21.03235945', '52.15345862', 'Warszawa,02-784,Dembowskiego E.,3'],
    ['21.00736742', '52.12448706', 'Warszawa,02-867,Baletowa,30B'],
    ['21.05713396', '52.13928373', 'Warszawa,02-793,Żabińskiego J.,16A'],
    ['20.99636894', '52.15701267', 'Warszawa,02-820,Wyczółki,95A'],
    ['21.06003414', '52.15224149', 'Warszawa,02-797,Imbirowa,13'],
    ['21.0206571', '52.13251619', 'Warszawa,02-837,Bogatki,18'],
    ['20.9999179', '52.12668884', 'Warszawa,02-863,Jeziorki,21B'],
    ['21.03411276', '52.10850526', 'Warszawa,02-892,Bażancia,11'],
    ['21.022108', '52.14899952', 'Warszawa,02-803,Bekasów,46'],
    ['21.02725297', '52.14318102', 'Warszawa,02-781,Roentgena W.,43G'],
    ['21.0566731', '52.15861657', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,22'],
    ['21.01932315', '52.16418492', 'Warszawa,02-781,Zawadowskiego W.,5'],
    ['21.01587542', '52.14169084', 'Warszawa,02-829,Taneczna,22'],
    ['21.01423069', '52.12551217', 'Warszawa,02-847,Klubowa,16'],
    ['21.00804024', '52.11111046', 'Warszawa,02-850,Trombity,8'],
    ['21.00026527', '52.14322488', 'Warszawa,02-850,Kądziołeczki,57'],
    ['21.01332424', '52.13548211', 'Warszawa,02-857,Dźwiękowa,20'],
    ['21.03726339', '52.16444879', 'Warszawa,02-787,Chłapowskiego D.,61'],
    ['21.01440084', '52.1254155', 'Warszawa,02-847,Klubowa,14'],
    ['21.05867275', '52.15162854', 'Warszawa,02-776,Nowoursynowska,143K'],
    ['21.02269709', '52.1503966', 'Warszawa,02-803,Bekasów,80'],
    ['20.99020722', '52.16535368', 'Warszawa,02-699,Kłobucka,19A'],
    ['21.01904674', '52.11781647', 'Warszawa,02-885,Puławska,579'],
    ['21.04801671', '52.13252658', 'Warszawa,02-798,Jaworowa,2'],
    ['21.08507148', '52.1018925', 'Warszawa,02-973,Prawdziwka,26'],
    ['21.06076109', '52.15034188', 'Warszawa,02-776,Nowoursynowska,139B'],
    ['21.02250461', '52.13571624', 'Warszawa,02-836,Kormoranów,7A'],
    ['21.05749584', '52.14322363', 'Warszawa,02-792,Lanciego F.,7A'],
    ['21.05066681', '52.13714311', 'Warszawa,02-793,Małej Łąki,21'],
    ['21.06768858', '52.13900288', 'Warszawa,02-793,Przy Bażantarni,4'],
    ['21.062136', '52.129935', 'Warszawa,02-798,Ekologiczna,6'],
    ['21.02700577', '52.11175885', 'Warszawa,02-887,Karmazynowa,1A'],
    ['21.01493285', '52.12760988', 'Warszawa,02-860,Łagiewnicka,15'],
    ['21.01785204', '52.11237942', 'Warszawa,02-884,Puławska,516'],
    ['21.07905267', '52.13142358', 'Warszawa,02-796,Relaksowa,14D'],
    ['20.99071533', '52.12648342', 'Warszawa,02-867,Baletowa,115A'],
    ['21.03987516', '52.15885001', 'Warszawa,02-786,Związku Walki Młodych,12A'],
    ['21.0015089', '52.15684357', 'Warszawa,02-820,Wyczółki,40'],
    ['21.06031638', '52.14744654', 'Warszawa,02-796,Migdałowa,3'],
    ['21.01202505', '52.13622803', 'Warszawa,02-830,Gawota,11B'],
    ['20.98969378', '52.11313009', 'Warszawa,02-871,Karczunkowska,164'],
    ['21.05497025', '52.15705242', 'Warszawa,02-777,Kopcińskiego S.,3'],
    ['21.06271106', '52.14053885', 'Warszawa,02-793,Lokajskiego E.,3'],
    ['21.00689593', '52.11463995', 'Warszawa,02-850,Trombity,24'],
    ['21.05527973', '52.15875214', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,26D'],
    ['21.01933002', '52.10656388', 'Warszawa,02-885,Puławska,623'],
    ['21.03481299', '52.11139816', 'Warszawa,02-886,Jagielska,23B'],
    ['21.06708545', '52.14398755', 'Warszawa,02-790,Sotta K. Sokoła,5'],
    ['21.0247001', '52.15216404', 'Warszawa,02-784,Dunikowskiego X.,37'],
    ['21.02234698', '52.15149445', 'Warszawa,02-803,Bekasów,71'],
    ['21.02752255', '52.14659203', 'Warszawa,02-781,Łukaszczyka P.,2'],
    ['21.03364584', '52.16562032', 'Warszawa,02-787,Elegijna,26'],
    ['21.01362865', '52.1297726', 'Warszawa,02-860,Czterech Wiatrów,63'],
    ['20.99495064', '52.16125363', 'Warszawa,02-699,Złoty Potok,5'],
    ['21.00806196', '52.11712491', 'Warszawa,02-868,Sarabandy,63'],
    ['21.07337913', '52.13718745', 'Warszawa,02-786,Rosoła J.,22K'],
    ['21.00901365', '52.14226986', 'Warszawa,02-830,Flamenco,13A'],
    ['21.03916244', '52.15606385', 'Warszawa,02-786,Związku Walki Młodych,6'],
    ['21.01430334', '52.12111985', 'Warszawa,02-878,Gajdy,44'],
    ['21.01542731', '52.13857454', 'Warszawa,02-830,Gawota,2A'],
    ['21.03433611', '52.11215426', 'Warszawa,02-886,Jagielska,25/27'],
    ['21.05381029', '52.12885835', 'Warszawa,02-835,Elegancka,1'],
    ['21.04606283', '52.15557081', 'Warszawa,02-776,Strzeleckiego,10E'],
    ['21.01347806', '52.1243806', 'Warszawa,02-846,Sporna,28A'],
    ['21.06879556', '52.13387949', 'Warszawa,02-796,Bronikowskiego K.,49'],
    ['21.01583506', '52.15105782', 'Warszawa,02-829,Mączeńskiego Z.,6'],
    ['21.02416534', '52.11372521', 'Warszawa,02-886,Jagielska,51G'],
    ['21.01742086', '52.11260955', 'Warszawa,02-879,Chóralna,3'],
    ['21.00989083', '52.15482138', 'Warszawa,02-822,Poleczki,28B'],
    ['21.05905984', '52.14348166', 'Warszawa,02-792,Lanciego F.,10C'],
    ['21.06270982', '52.1463746', 'Warszawa,02-786,Rosoła J.,50'],
    ['21.08264591', '52.12533379', 'Warszawa,02-797,Nowoursynowska,31'],
    ['21.03890116', '52.15022545', 'Warszawa,02-776,Malinowskiego E.,2'],
    ['21.05726679', '52.15686405', 'Warszawa,02-776,Wrzosowisko,19'],
    ['21.00993227', '52.12188245', 'Warszawa,02-878,Ługi,15'],
    ['21.04521979', '52.14629685', 'Warszawa,02-776,Warchałowskiego E.,11'],
    ['20.9941977', '52.13575109', 'Warszawa,02-821,Hołubcowa,45A'],
    ['21.02534606', '52.10742816', 'Warszawa,02-892,Bażancia,41G'],
    ['20.99680846', '52.16531139', 'Warszawa,02-699,Taborowa,8'],
    ['21.02319322', '52.13824798', 'Warszawa,02-815,Żołny,42'],
    ['21.02858345', '52.15022361', 'Warszawa,02-781,Czapli,55'],
    ['20.99542581', '52.15899169', 'Warszawa,02-820,Pl. Farmacji,1'],
    ['21.01417228', '52.12514469', 'Warszawa,02-847,Klubowa,19'],
    ['21.00851649', '52.12020957', 'Warszawa,02-862,Sarabandy,92E'],
    ['20.9861438', '52.11131794', 'Warszawa,02-871,Karczunkowska,172'],
    ['21.06883505', '52.14147584', 'Warszawa,02-797,Nowoursynowska,101D'],
    ['21.02664033', '52.10735376', 'Warszawa,02-892,Kuropatwy,24K'],
    ['21.01248258', '52.12135596', 'Warszawa,02-878,Głęboka,1'],
    ['21.01178439', '52.12877305', 'Warszawa,02-860,Czterech Wiatrów,1'],
    ['21.01584396', '52.14963069', 'Warszawa,02-829,Samsonowska,6'],
    ['21.02204977', '52.14721086', 'Warszawa,02-809,Perkoza,12'],
    ['21.03373208', '52.10878358', 'Warszawa,02-892,Bażancia,16'],
    ['21.02211187', '52.12046942', 'Warszawa,02-814,Słonki,1A'],
    ['20.99097682', '52.15789384', 'Warszawa,02-820,Wyczółki,92'],
    ['21.02276229', '52.15284482', 'Warszawa,02-784,Makowskiego T.,16'],
    ['21.01021831', '52.12132122', 'Warszawa,02-878,Głęboka,23'],
    ['21.01140098', '52.14600477', 'Warszawa,02-828,Pląsy,27'],
    ['21.04902233', '52.13164904', 'Warszawa,02-833,Wełniana,47A'],
    ['21.01476631', '52.13723733', 'Warszawa,02-830,Wędrowców,11'],
    ['21.02026424', '52.13533026', 'Warszawa,02-836,Modraszki,10'],
    ['21.03451873', '52.16423721', 'Warszawa,02-787,Elegijna,15'],
    ['21.01855412', '52.12670808', 'Warszawa,02-844,Puławska,523'],
    ['21.04735029', '52.14143725', 'Warszawa,02-791,Na Uboczu,22'],
    ['21.05991935', '52.13370861', 'Warszawa,02-793,Boglarczyków,13'],
    ['21.04167753', '52.14248513', 'Warszawa,02-776,Hirszfelda L.,4'],
    ['21.01694297', '52.14927264', 'Warszawa,02-819,Puławska,294'],
    ['21.04756234', '52.13473768', 'Warszawa,02-834,Moczydłowska,65A'],
    ['21.05007703', '52.13858048', 'Warszawa,02-791,Na Uboczu,5'],
    ['21.00768365', '52.1412115', 'Warszawa,02-830,Mazura,14'],
    ['20.99779373', '52.15690798', 'Warszawa,02-820,Wyczółki,91A'],
    ['21.04662295', '52.13369909', 'Warszawa,02-834,Moczydłowska,67M'],
    ['21.03029168', '52.13703946', 'Warszawa,02-815,Żołny,11'],
    ['21.02171672', '52.13785059', 'Warszawa,02-815,Żołny,53A'],
    ['21.02724741', '52.14451029', 'Warszawa,02-811,Makolągwy,19'],
    ['21.01942716', '52.16026653', 'Warszawa,02-785,Surowieckiego W.,6'],
    ['21.00830306', '52.12321882', 'Warszawa,02-867,Baletowa,23A'],
    ['21.05551872', '52.15902244', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,28F'],
    ['21.01543075', '52.11890515', 'Warszawa,02-878,Gajdy,30D'],
    ['21.05603501', '52.1435275', 'Warszawa,02-792,Lanciego F.,9R'],
    ['21.01465382', '52.11961034', 'Warszawa,02-878,Gajdy,34C'],
    ['21.01945222', '52.13868289', 'Warszawa,02-815,Żołny,56A'],
    ['21.00573597', '52.12379886', 'Warszawa,02-867,Baletowa,32'],
    ['21.01522702', '52.14397936', 'Warszawa,02-829,Taneczna,37A'],
    ['21.01237018', '52.13605835', 'Warszawa,02-830,Gawota,11N'],
    ['21.03537494', '52.15004331', 'Warszawa,02-776,Dybowskiego B.,3'],
    ['21.07006931', '52.13911274', 'Warszawa,02-786,Rosoła J.,28'],
    ['21.0233772', '52.10685973', 'Warszawa,02-892,Kuropatwy,34G'],
    ['21.03927329', '52.16426554', 'Warszawa,02-787,Chłapowskiego D.,7'],
    ['21.00810698', '52.13243908', 'Warszawa,02-856,Ludwinowska,47Z'],
    ['21.01045333', '52.11863861', 'Warszawa,02-878,Czysta,10'],
    ['21.00714831', '52.13735355', 'Warszawa,02-830,Gawota,29C'],
    ['21.00350902', '52.11301405', 'Warszawa,02-850,Trombity,13R'],
    ['21.0048406', '52.12370382', 'Warszawa,02-867,Baletowa,36'],
    ['21.01362972', '52.12349735', 'Warszawa,02-862,Farbiarska,26'],
    ['21.02027339', '52.14793265', 'Warszawa,02-807,Bociania,21'],
    ['21.05445952', '52.15708225', 'Warszawa,02-777,Kopcińskiego S.,5'],
    ['21.02401528', '52.14811544', 'Warszawa,02-808,Zięby,10'],
    ['21.03384124', '52.10961229', 'Warszawa,02-888,Kogucia,6A'],
    ['21.06351397', '52.14577721', 'Warszawa,02-790,Pachnąca,85'],
    ['21.07395448', '52.13382432', 'Warszawa,02-796,Rosoła J.,7'],
    ['21.05251773', '52.129282', 'Warszawa,02-835,Nowoczesna,9'],
    ['21.05436151', '52.13055525', 'Warszawa,02-833,Wełniana,5E'],
    ['21.02393646', '52.11419598', 'Warszawa,02-886,Jagielska,55B'],
    ['21.02020385', '52.12830349', 'Warszawa,02-840,Leśna,8A'],
    ['21.01945884', '52.10817741', 'Warszawa,02-892,Bażancia,55'],
    ['21.01586707', '52.13530148', 'Warszawa,02-858,Transportowców,25'],
    ['21.01197146', '52.12134179', 'Warszawa,02-878,Głęboka,7'],
    ['21.04764474', '52.1300468', 'Warszawa,02-834,Kabacki Zakątek,17'],
    ['21.00453298', '52.11329433', 'Warszawa,02-850,Trombity,24G'],
    ['21.0146417', '52.14503769', 'Warszawa,02-849,Krasnowolska,3D'],
    ['21.01979597', '52.13292015', 'Warszawa,02-816,Rajskich Ptaków,12'],
    ['21.00379335', '52.11029026', 'Warszawa,02-871,Karczunkowska,82'],
    ['21.07038722', '52.13390277', 'Warszawa,02-796,Bronikowskiego K.,4'],
    ['21.06974459', '52.13961111', 'Warszawa,02-797,Nowoursynowska,89J'],
    ['21.01360611', '52.12322438', 'Warszawa,02-862,Farbiarska,24'],
    ['21.05819321', '52.15464636', 'Warszawa,02-797,Letniskowa,20'],
    ['21.02092063', '52.15335499', 'Warszawa,02-784,Janowskiego A.,20'],
    ['21.0548627', '52.12846271', 'Warszawa,02-886,Rybałtów,11'],
    ['21.00993568', '52.12214193', 'Warszawa,02-878,Ługi,16'],
    ['21.05533703', '52.15869404', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,26C'],
    ['21.06315823', '52.14717277', 'Warszawa,02-790,Pachnąca,48'],
    ['21.05919583', '52.15409222', 'Warszawa,02-797,Modelowa,8'],
    ['21.01230911', '52.13574326', 'Warszawa,02-830,Gawota,11L'],
    ['21.08316381', '52.12467545', 'Warszawa,02-797,Nowoursynowska,29'],
    ['20.99051311', '52.1246961', 'Warszawa,02-867,Baletowa,113A'],
    ['21.01637629', '52.12453513', 'Warszawa,02-847,Klubowa,1K'],
    ['21.05286508', '52.14680136', 'Warszawa,02-777,Benedykta Polaka,2'],
    ['21.003736', '52.15803', 'Warszawa,02-820,Łączyny,1'],
    ['21.04245741', '52.16406621', 'Warszawa,02-787,Nowoursynowska,161B'],
    ['21.01939321', '52.12871195', 'Warszawa,02-840,Leśna,4'],
    ['21.01989379', '52.13165946', 'Warszawa,02-838,Raniuszka,43'],
    ['21.01991188', '52.11842968', 'Warszawa,02-843,Tukana,3B'],
    ['21.04728099', '52.14695169', 'Warszawa,02-776,Wasilkowskiego J.,9'],
    ['21.03257958', '52.10663829', 'Warszawa,02-892,Kuropatwy,8B'],
    ['21.01744695', '52.13001028', 'Warszawa,02-845,Puławska,408A'],
    ['21.01180893', '52.12940217', 'Warszawa,02-860,Czterech Wiatrów,17'],
    ['21.02424437', '52.1153165', 'Warszawa,02-886,Jagielska,53'],
    ['21.06321186', '52.13815141', 'Warszawa,02-793,Raabego H.,2'],
    ['21.06710707', '52.14458223', 'Warszawa,02-797,Nowoursynowska,115G'],
    ['21.01703314', '52.15098667', 'Warszawa,02-819,Puławska,278'],
    ['21.01647906', '52.1306202', 'Warszawa,02-859,Głuszca,6'],
    ['21.01883186', '52.12832212', 'Warszawa,02-844,Puławska,509'],
    ['20.99437816', '52.16596021', 'Warszawa,02-699,Taborowa,18'],
    ['21.05868619', '52.15471513', 'Warszawa,02-797,Modelowa,21'],
    ['21.04791101', '52.13832717', 'Warszawa,02-795,Kazury S.,1'],
    ['21.01104457', '52.15231905', 'Warszawa,02-825,Tango,3'],
    ['21.01868111', '52.13351626', 'Warszawa,02-844,Puławska,479A'],
    ['21.00342473', '52.14189039', 'Warszawa,02-849,Krasnowolska,51'],
    ['21.01759417', '52.12223176', 'Warszawa,02-884,Puławska,452'],
    ['21.05806737', '52.15374042', 'Warszawa,02-797,Letniskowa,1'],
    ['21.05285307', '52.13062963', 'Warszawa,02-798,Ustronie,18'],
    ['20.98805966', '52.11395743', 'Warszawa,02-871,Karczunkowska,170J'],
    ['21.05651891', '52.15818746', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,14'],
    ['21.05446969', '52.15957953', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,54'],
    ['21.05760904', '52.15650101', 'Warszawa,02-776,Urwisko,10'],
    ['21.0002975', '52.14342637', 'Warszawa,02-850,Kądziołeczki,61'],
    ['21.05698104', '52.14469531', 'Warszawa,02-792,Lanciego F.,11A'],
    ['21.05395114', '52.1304138', 'Warszawa,02-833,Wełniana,7'],
    ['21.00542952', '52.15569865', 'Warszawa,02-822,Galopu,6'],
    ['21.0516626', '52.13043093', 'Warszawa,02-833,Wełniana,29A'],
    ['21.01165289', '52.14294161', 'Warszawa,02-830,Rumby,10'],
    ['21.01924545', '52.11333106', 'Warszawa,02-885,Puławska,599B'],
    ['21.01014925', '52.13778893', 'Warszawa,02-830,Gawota,15A'],
    ['21.01761587', '52.11880765', 'Warszawa,02-884,Puławska,472'],
    ['21.01541586', '52.13725896', 'Warszawa,02-830,Wędrowców,10'],
    ['21.05728947', '52.13043251', 'Warszawa,02-798,Ekologiczna,22'],
    ['21.043112', '52.143971', 'Warszawa,02-776,Dereniowa,5D'],
    ['21.04565604', '52.15535012', 'Warszawa,02-776,Strzeleckiego,5'],
    ['21.0552111', '52.13569252', 'Warszawa,02-793,Małej Łąki,36'],
    ['21.02325821', '52.14488838', 'Warszawa,02-811,Makolągwy,23M'],
    ['21.02076998', '52.15290961', 'Warszawa,02-784,Janowskiego A.,32'],
    ['21.01591077', '52.13567535', 'Warszawa,02-858,Transportowców,29A'],
    ['21.02030228', '52.15135566', 'Warszawa,02-804,Kraski,53A'],
    ['21.02651445', '52.13632053', 'Warszawa,02-818,Kanarkowa,20'],
    ['21.01294751', '52.10930127', 'Warszawa,02-881,Pozytywki,18'],
    ['21.09819407', '52.10521926', 'Konstancin-Jeziorna,02-971,Prawdziwka,2'],
    ['21.02948165', '52.14798889', 'Warszawa,02-781,Św. M. Kolbego,10'],
    ['21.00125363', '52.14475506', 'Warszawa,02-850,Kądziołeczki,52F'],
    ['20.9905855', '52.16499837', 'Warszawa,02-699,Kłobucka,19'],
    ['21.01765347', '52.11715176', 'Warszawa,02-884,Puławska,484'],
    ['21.0131991', '52.14069792', 'Warszawa,02-830,Korowodu,13'],
    ['21.00601737', '52.14390795', 'Warszawa,02-826,Poloneza,49'],
    ['21.05945063', '52.15256276', 'Warszawa,02-797,Pistacjowa,9'],
    ['21.06416649', '52.1472018', 'Warszawa,02-790,Pachnąca,53'],
    ['21.06165073', '52.14073548', 'Warszawa,02-793,Belgradzka,5'],
    ['21.01567107', '52.1085607', 'Warszawa,02-881,Cymbalistów,6'],
    ['21.02079506', '52.14682281', 'Warszawa,02-803,Bekasów,21'],
    ['21.02154701', '52.13487955', 'Warszawa,02-836,Modraszki,3B'],
    ['21.02094119', '52.14063963', 'Warszawa,02-814,Gruchacza,34'],
    ['21.05158352', '52.15148683', 'Warszawa,02-777,Szolc-Rogozińskiego,12'],
    ['21.04114339', '52.14287074', 'Warszawa,02-776,Hirszfelda L.,3'],
    ['21.02831285', '52.14840131', 'Warszawa,02-781,Łukaszczyka P.,27'],
    ['21.06876592', '52.14144267', 'Warszawa,02-797,Nowoursynowska,101E'],
    ['21.0519193', '52.14226022', 'Warszawa,02-791,Meander,21'],
    ['21.08182599', '52.12682804', 'Warszawa,02-797,Nowoursynowska,34'],
    ['21.01198516', '52.14600892', 'Warszawa,02-828,Pląsy,25'],
    ['21.01199733', '52.13611958', 'Warszawa,02-830,Gawota,11C'],
    ['21.01706756', '52.14702137', 'Warszawa,02-819,Puławska,300'],
    ['21.04871786', '52.13362206', 'Warszawa,02-798,Jaworowa,26'],
    ['21.06340427', '52.13446859', 'Warszawa,02-797,Al. Komisji Edukacji Narodowej,24A'],
    ['21.02365331', '52.12466129', 'Warszawa,02-843,6 Sierpnia,01-May'],
    ['21.05527816', '52.15451471', 'Warszawa,02-776,Nowoursynowska,153A'],
    ['21.0407242', '52.16405563', 'Warszawa,02-787,Nowoursynowska,161H'],
    ['21.05672901', '52.14340392', 'Warszawa,02-792,Lanciego F.,7B'],
    ['21.04838568', '52.13291545', 'Warszawa,02-798,Jaworowa,12'],
    ['21.0163415', '52.11541822', 'Warszawa,02-877,Drumli,7B'],
    ['21.03062236', '52.15493537', 'Warszawa,02-784,Romera E.,4'],
    ['21.060639', '52.144667', 'Warszawa,02-792,Lasek Brzozowy,14'],
    ['20.99637895', '52.15388286', 'Warszawa,02-823,Osmańska,12A'],
    ['21.00989131', '52.14646407', 'Warszawa,02-828,Pląsy,20C'],
    ['21.07285946', '52.13493093', 'Warszawa,02-796,Rosoła J.,11'],
    ['21.05330292', '52.12969456', 'Warszawa,02-835,Elegancka,27'],
    ['21.05148065', '52.13169015', 'Warszawa,02-833,Wełniana,33'],
    ['21.01728762', '52.14366281', 'Warszawa,02-845,Puławska,322'],
    ['21.00685819', '52.13139428', 'Warszawa,02-863,Jeziorki,65B'],
    ['21.046811', '52.172764', 'Warszawa,02-768,Fosa,3/3A'],
    ['21.02368584', '52.15251338', 'Warszawa,02-784,Arctowskiego H.,14'],
    ['21.02884984', '52.14737689', 'Warszawa,02-781,Św. M. Kolbego,2'],
    ['21.01695045', '52.14870259', 'Warszawa,02-819,Puławska,296'],
    ['21.0337722', '52.15383046', 'Warszawa,02-784,Dembowskiego E.,1'],
    ['20.99917075', '52.12523606', 'Warszawa,02-863,Jeziorki,7'],
    ['21.01192627', '52.13577822', 'Warszawa,02-830,Gawota,11E'],
    ['21.05188131', '52.1289215', 'Warszawa,02-835,Batystowa,8'],
    ['21.02390648', '52.14457702', 'Warszawa,02-811,Pustułeczki,24'],
    ['21.05291491', '52.13764226', 'Warszawa,02-793,Małej Łąki,78'],
    ['21.00320816', '52.13421808', 'Warszawa,02-856,Krzesanego,20'],
    ['21.01496802', '52.11927136', 'Warszawa,02-878,Gajdy,34B'],
    ['21.05623206', '52.15401134', 'Warszawa,02-776,Nowoursynowska,149D'],
    ['21.06478607', '52.14704779', 'Warszawa,02-790,Pachnąca,39'],
    ['21.02098847', '52.13222388', 'Warszawa,02-837,Bogatki,23'],
    ['21.081026', '52.129219', 'Warszawa,02-797,Nowoursynowska,48'],
    ['21.01498816', '52.14583952', 'Warszawa,02-829,Taneczna,51'],
    ['21.01940251', '52.12329631', 'Warszawa,02-843,Pelikanów,24'],
    ['21.01197242', '52.14554308', 'Warszawa,02-849,Walczyka,37'],
    ['21.01228866', '52.15433527', 'Warszawa,02-822,Poleczki,14'],
    ['21.01669069', '52.1449628', 'Warszawa,02-828,Rysy,41'],
    ['21.00110345', '52.1234688', 'Warszawa,02-867,Baletowa,46'],
    ['21.01962862', '52.1313823', 'Warszawa,02-816,Rajskich Ptaków,9'],
    ['21.04245559', '52.17009414', 'Warszawa,02-768,Fosa,35B'],
    ['21.01427237', '52.11995204', 'Warszawa,02-878,Gajdy,38'],
    ['21.06623906', '52.1437212', 'Warszawa,02-790,Sengera Cichego M.,8'],
    ['21.046984', '52.143414', 'Warszawa,02-778,Płaskowickiej F.,30'],
    ['21.00246772', '52.14476206', 'Warszawa,02-850,Kądziołeczki,52U'],
    ['21.00023963', '52.1430319', 'Warszawa,02-850,Kądziołeczki,53'],
    ['21.06016514', '52.15006572', 'Warszawa,02-776,Nowoursynowska,139U'],
    ['21.05866274', '52.14062148', 'Warszawa,02-793,Belgradzka,20A'],
    ['21.02847741', '52.14730939', 'Warszawa,02-781,Łukaszczyka P.,10'],
    ['21.06088201', '52.15359647', 'Warszawa,02-797,Kokosowa,39'],
    ['20.99414283', '52.11901898', 'Warszawa,02-850,Trombity,77'],
    ['21.05243023', '52.13134159', 'Warszawa,02-833,Wełniana,27B'],
    ['21.01638895', '52.13424672', 'Warszawa,02-857,Dźwiękowa,7'],
    ['21.0706367', '52.13928021', 'Warszawa,02-786,Rosoła J.,28A'],
    ['21.02369367', '52.10804253', 'Warszawa,02-892,Bażancia,45'],
    ['21.00231962', '52.1213664', 'Warszawa,02-868,Sarabandy,115B'],
    ['21.06523918', '52.14443963', 'Warszawa,02-790,Sengera Cichego M.,9'],
    ['21.00208201', '52.13533972', 'Warszawa,02-855,Sztajerka,5A'],
    ['21.04922689', '52.13395734', 'Warszawa,02-798,Jaworowa,34'],
    ['21.06332158', '52.14189041', 'Warszawa,02-793,Belgradzka,6'],
    ['20.99281993', '52.15744993', 'Warszawa,02-820,Wyczółki,82'],
    ['21.03830923', '52.1476383', 'Warszawa,02-776,Marco Polo,2'],
    ['21.05507657', '52.13881351', 'Warszawa,02-793,Belgradzka,21'],
    ['21.00893069', '52.12134911', 'Warszawa,02-878,Głęboka,39'],
    ['21.06001354', '52.13477505', 'Warszawa,02-793,Villardczyków,2'],
    ['21.00891996', '52.11994096', 'Warszawa,02-862,Sarabandy,92K'],
    ['21.01141223', '52.14568806', 'Warszawa,02-828,Pląsy,27A'],
    ['21.03072751', '52.15328297', 'Warszawa,02-784,Dembowskiego E.,8'],
    ['21.01552844', '52.14722059', 'Warszawa,02-829,Kondracka,2'],
    ['21.00751262', '52.11988137', 'Warszawa,02-868,Sarabandy,92R'],
    ['20.99315226', '52.15719471', 'Warszawa,02-820,Wyczółki,113'],
    ['21.05494653', '52.15961731', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,50'],
    ['21.01894746', '52.14978066', 'Warszawa,02-806,Albatrosów,15A'],
    ['21.05958213', '52.154544', 'Warszawa,02-797,Bulwarowa,19'],
    ['21.05791913', '52.13408678', 'Warszawa,02-793,Villardczyków,15'],
    ['21.04582397', '52.15479717', 'Warszawa,02-776,Strzeleckiego,3'],
    ['21.02110762', '52.11533261', 'Warszawa,02-886,Jagielska,61B'],
    ['21.02196527', '52.13310043', 'Warszawa,02-837,Puchaczy,21'],
    ['21.06615161', '52.14264529', 'Warszawa,02-786,Rosoła J.,46'],
    ['21.00662655', '52.12952583', 'Warszawa,02-863,Jeziorki,74A'],
    ['21.01576614', '52.14934274', 'Warszawa,02-829,Białej Wody,6'],
    ['20.99084242', '52.11009922', 'Warszawa,02-873,Kurantów,31A'],
    ['21.02100631', '52.14664686', 'Warszawa,02-803,Bekasów,18/20'],
    ['21.01495977', '52.14756213', 'Warszawa,02-829,Taneczna,59M'],
    ['21.04624044', '52.13191364', 'Warszawa,02-834,Moczydłowska,37'],
    ['21.0208666', '52.11263345', 'Warszawa,02-887,Karmazynowa,32A'],
    ['21.05813335', '52.15254953', 'Warszawa,02-776,Nowoursynowska,145B'],
    ['21.04337644', '52.1534235', 'Warszawa,02-777,Cynamonowa,41'],
    ['21.02281077', '52.12607672', 'Warszawa,02-842,Rataja M.,6'],
    ['21.01030489', '52.12513619', 'Warszawa,02-862,Farbiarska,31'],
    ['21.00981993', '52.1213126', 'Warszawa,02-878,Głęboka,27'],
    ['21.02064658', '52.14808223', 'Warszawa,02-807,Bociania,22F'],
    ['21.00403759', '52.11637336', 'Warszawa,02-868,Dumki,20'],
    ['21.00910398', '52.1242864', 'Warszawa,02-867,Baletowa,24B'],
    ['20.99932112', '52.14325485', 'Warszawa,02-850,Tramblanki,32'],
    ['21.04872919', '52.13250792', 'Warszawa,02-798,Jaworowa,10'],
    ['21.02041339', '52.1317093', 'Warszawa,02-838,Raniuszka,37'],
    ['21.01076316', '52.13122393', 'Warszawa,02-862,Farbiarska,71'],
    ['20.99295304', '52.12696148', 'Warszawa,02-867,Baletowa,104'],
    ['20.99365402', '52.15715691', 'Warszawa,02-820,Wyczółki,109'],
    ['21.01120146', '52.15494803', 'Warszawa,02-822,Poleczki,20D'],
    ['20.99968948', '52.15677594', 'Warszawa,02-820,Wyczółki,81'],
    ['21.06338198', '52.14692128', 'Warszawa,02-790,Pachnąca,67'],
    ['21.01865654', '52.13826496', 'Warszawa,02-801,Puławska,455'],
    ['21.0217545', '52.15867645', 'Warszawa,02-785,Puszczyka,9'],
    ['21.01095443', '52.14598266', 'Warszawa,02-828,Pląsy,29'],
    ['21.04807143', '52.13110242', 'Warszawa,02-833,Wełniana,47K'],
    ['21.03365913', '52.16442609', 'Warszawa,02-787,Elegijna,41'],
    ['21.02272955', '52.11533178', 'Warszawa,02-886,Jagielska,57'],
    ['21.0590488', '52.15223674', 'Warszawa,02-797,Nowoursynowska,130'],
    ['21.04893345', '52.13412076', 'Warszawa,02-798,Jaworowa,36'],
    ['21.02449562', '52.15234745', 'Warszawa,02-784,Dunikowskiego X.,14'],
    ['21.01406058', '52.12957968', 'Warszawa,02-862,Muzyczna,24'],
    ['21.08402989', '52.12544423', 'Warszawa,02-797,Nowoursynowska,26C'],
    ['21.05636525', '52.12983523', 'Warszawa,02-798,Ekologiczna,28'],
    ['21.041393', '52.153529', 'Warszawa,02-777,Polinezyjska,15'],
    ['21.05515364', '52.14737404', 'Warszawa,02-777,Kulczyńskiego S.,2A'],
    ['21.047349', '52.163952', 'Warszawa,02-787,Nowoursynowska,166/11'],
    ['21.01933137', '52.14419915', 'Warszawa,02-812,Lelka,28'],
    ['21.06714962', '52.14453598', 'Warszawa,02-797,Nowoursynowska,115F'],
    ['21.05325511', '52.1493777', 'Warszawa,02-777,Szolc-Rogozińskiego,2'],
    ['21.02373196', '52.13628704', 'Warszawa,02-817,Białozora,1B'],
    ['21.00171823', '52.15992002', 'Warszawa,02-820,Łączyny,8'],
    ['21.02160895', '52.14728994', 'Warszawa,02-803,Perkoza,6'],
    ['21.02270377', '52.11360962', 'Warszawa,02-886,Jagielska,55N'],
    ['21.06041986', '52.15153054', 'Warszawa,02-797,Imbirowa,6H'],
    ['21.01006743', '52.13442389', 'Warszawa,02-856,Ludwinowska,24'],
    ['21.00154466', '52.14447243', 'Warszawa,02-850,Kądziołeczki,50J'],
    ['21.00128929', '52.14448472', 'Warszawa,02-850,Kądziołeczki,50G'],
    ['21.05607823', '52.15790649', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,10'],
    ['21.01949734', '52.13775053', 'Warszawa,02-816,Rajskich Ptaków,55'],
    ['21.02108439', '52.1343825', 'Warszawa,02-836,Dzierzby,18'],
    ['21.02148091', '52.14073828', 'Warszawa,02-814,Gruchacza,34A'],
    ['21.01116393', '52.15093239', 'Warszawa,02-824,Wodzirejów,13C'],
    ['21.05713821', '52.15747687', 'Warszawa,02-776,Nowoursynowska,162G'],
    ['21.02219293', '52.11439986', 'Warszawa,02-886,Jagielska,59F'],
    ['21.03771991', '52.16379826', 'Warszawa,02-787,Chłapowskiego D.,49'],
    ['21.02052478', '52.13274129', 'Warszawa,02-837,Bogatki,20B'],
    ['21.02292951', '52.13309048', 'Warszawa,02-837,Puchaczy,11'],
    ['21.01877328', '52.1458016', 'Warszawa,02-807,Bociania,9F'],
    ['21.00812494', '52.13261621', 'Warszawa,02-856,Ludwinowska,47X'],
    ['21.05555961', '52.15984832', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,46'],
    ['21.07416138', '52.13159175', 'Warszawa,02-796,Dembego S.,14'],
    ['21.00751839', '52.13448457', 'Warszawa,02-856,Ludwinowska,49'],
    ['21.01882547', '52.14522212', 'Warszawa,02-801,Puławska,409C'],
    ['21.00988802', '52.14651771', 'Warszawa,02-828,Pląsy,20D'],
    ['21.05433442', '52.15763807', 'Warszawa,02-777,Kopcińskiego S.,4'],
    ['21.01298548', '52.12975761', 'Warszawa,02-860,Czterech Wiatrów,40'],
    ['21.0423878', '52.13524237', 'Warszawa,02-834,Ziemska,21C'],
    ['21.07814369', '52.13095341', 'Warszawa,02-796,Relaksowa,12'],
    ['21.03768524', '52.1638319', 'Warszawa,02-787,Chłapowskiego D.,51'],
    ['21.04310247', '52.17144898', 'Warszawa,02-768,Fosa,27'],
    ['21.05994982', '52.14794635', 'Warszawa,02-796,Migdałowa,2'],
    ['21.0693597', '52.13331802', 'Warszawa,02-796,Bronikowskiego K.,5'],
    ['21.02230476', '52.11753434', 'Warszawa,02-886,Jagielska,46G'],
    ['21.00901726', '52.14242115', 'Warszawa,02-830,Flamenco,15A'],
    ['21.02398261', '52.12992617', 'Warszawa,02-838,Kajakowa,11D'],
    ['21.01906359', '52.14824601', 'Warszawa,02-806,Albatrosów,8A'],
    ['21.02362576', '52.11893535', 'Warszawa,02-843,Tukana,11A'],
    ['21.00465735', '52.15711132', 'Warszawa,02-820,Wyczółki,53A'],
    ['20.9959904', '52.1106117', 'Warszawa,02-869,Buszycka,6C'],
    ['21.0276582', '52.14780775', 'Warszawa,02-781,Roentgena W.,8B'],
    ['21.02534917', '52.10774873', 'Warszawa,02-892,Bażancia,41D'],
    ['21.06594744', '52.14525386', 'Warszawa,02-790,Sotta K. Sokoła,17'],
    ['21.07272123', '52.13705143', 'Warszawa,02-786,Rosoła J.,22J'],
    ['21.00658296', '52.15120897', 'Warszawa,02-826,Poloneza,87'],
    ['21.02055314', '52.11490728', 'Warszawa,02-886,Jagielska,63A/3'],
    ['21.01865521', '52.15026247', 'Warszawa,02-806,Rybitwy,13'],
    ['21.02336366', '52.11319362', 'Warszawa,02-886,Jagielska,53E'],
    ['21.05750326', '52.15693539', 'Warszawa,02-776,Wrzosowisko,15'],
    ['21.0529824', '52.15885093', 'Warszawa,02-777,Przybylskiego Cz.,2'],
    ['21.02356927', '52.11352003', 'Warszawa,02-886,Jagielska,51P'],
    ['21.02540132', '52.10630196', 'Warszawa,02-892,Kuropatwy,28A'],
    ['21.00851118', '52.14412201', 'Warszawa,02-849,Krasnowolska,32M'],
    ['20.98884656', '52.15979422', 'Warszawa,02-699,Nowy Służewiec,1'],
    ['21.01157381', '52.15456706', 'Warszawa,02-822,Poleczki,18'],
    ['21.01630428', '52.14381518', 'Warszawa,02-828,Roztoki,16'],
    ['21.02068093', '52.13730048', 'Warszawa,02-817,Białozora,40'],
    ['21.02336831', '52.10780704', 'Warszawa,02-892,Bażancia,47D'],
    ['21.03580183', '52.15253079', 'Warszawa,02-784,Służby Polsce,4'],
    ['21.06543001', '52.14458972', 'Warszawa,02-790,Sengera Cichego M.,20'],
    ['20.99725954', '52.14159645', 'Warszawa,02-851,Puchalska,15'],
    ['21.00313935', '52.14302345', 'Warszawa,02-849,Krasnowolska,58B'],
    ['21.01400406', '52.11517786', 'Warszawa,02-875,Klarnecistów,3'],
    ['21.03931732', '52.16451483', 'Warszawa,02-787,Chłapowskiego D.,4'],
    ['21.02403606', '52.12239466', 'Warszawa,02-843,Pelikanów,1B'],
    ['21.02154291', '52.11564787', 'Warszawa,02-886,Jagielska,50A'],
    ['21.01484875', '52.14832287', 'Warszawa,02-829,Taneczna,65A'],
    ['21.0480391', '52.14627274', 'Warszawa,02-776,Wasilkowskiego J.,7'],
    ['21.06651732', '52.14377107', 'Warszawa,02-790,Sengera Cichego M.,6'],
    ['21.06556501', '52.14587696', 'Warszawa,02-790,Pietraszewicza B.,3'],
    ['21.02895833', '52.13753704', 'Warszawa,02-815,Żołny,24'],
    ['21.03316069', '52.16175056', 'Warszawa,02-787,Wokalna,2'],
    ['20.99149065', '52.10847519', 'Warszawa,02-873,Kurantów,24'],
    ['21.01358741', '52.13736474', 'Warszawa,02-830,Wędrowców,18A'],
    ['21.02060022', '52.14122562', 'Warszawa,02-812,Ibisa,5'],
    ['21.01130597', '52.14269332', 'Warszawa,02-830,Samby,24'],
    ['21.01327944', '52.15124077', 'Warszawa,02-824,Wodzirejów,5A'],
    ['21.00673938', '52.1373295', 'Warszawa,02-830,Gawota,31C'],
    ['21.0045427', '52.13691333', 'Warszawa,02-826,Kujawiaka,35'],
    ['21.0250078', '52.14443425', 'Warszawa,02-781,Roentgena W.,34'],
    ['21.01390504', '52.11713021', 'Warszawa,02-876,Kapeli,15'],
    ['21.03017622', '52.10739851', 'Warszawa,02-893,Synogarlicy,12'],
    ['21.048289', '52.13399714', 'Warszawa,02-798,Jaworowa,43'],
    ['21.06733899', '52.14428836', 'Warszawa,02-797,Nowoursynowska,115A'],
    ['21.02593854', '52.10861922', 'Warszawa,02-892,Bażancia,42'],
    ['21.08016233', '52.13133041', 'Warszawa,02-796,Relaksowa,12E'],
    ['21.04884883', '52.13392547', 'Warszawa,02-798,Jaworowa,32'],
    ['21.05302585', '52.13995185', 'Warszawa,02-791,Meander,3'],
    ['21.04266972', '52.14200564', 'Warszawa,02-776,Hirszfelda L.,2A'],
    ['21.05608298', '52.15833431', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,24A'],
    ['21.02317158', '52.13334781', 'Warszawa,02-837,Puchaczy,8'],
    ['21.07868771', '52.13452279', 'Warszawa,02-796,Gąsek,91E'],
    ['21.06015607', '52.15132715', 'Warszawa,02-797,Nowoursynowska,112'],
    ['21.01448863', '52.10947406', 'Warszawa,02-881,Pozytywki,18B'],
    ['21.01034969', '52.15519788', 'Warszawa,02-820,Wyczółki,21A'],
    ['20.99260536', '52.15946033', 'Warszawa,02-699,Kłobucka,6'],
    ['21.01464', '52.14509482', 'Warszawa,02-849,Krasnowolska,3C'],
    ['21.05154084', '52.15838813', 'Warszawa,02-777,Ciszewskiego J.,3'],
    ['21.01149908', '52.14624341', 'Warszawa,02-828,Pląsy,14'],
    ['21.00477683', '52.1124649', 'Warszawa,02-850,Trombity,13'],
    ['21.00530874', '52.15096503', 'Warszawa,02-823,Polki,1A'],
    ['21.08741705', '52.1040862', 'Warszawa,02-973,Prawdziwka,16C'],
    ['21.01643436', '52.14572074', 'Warszawa,02-828,Rysy,63'],
    ['21.029726', '52.106648', 'Warszawa,02-892,Kuropatwy,16B'],
    ['21.00026759', '52.15695867', 'Warszawa,02-820,Wyczółki,46'],
    ['21.00995857', '52.13465706', 'Warszawa,02-856,Ludwinowska,24B'],
    ['21.05874543', '52.1529296', 'Warszawa,02-797,Arachidowa,5'],
    ['21.07864984', '52.13137174', 'Warszawa,02-796,Relaksowa,14C'],
    ['20.99384351', '52.11396931', 'Warszawa,02-869,Dawidowska,58'],
    ['21.06889915', '52.12942444', 'Warszawa,02-796,Al. Komisji Edukacji Narodowej,14'],
    ['21.00345184', '52.12789267', 'Warszawa,02-863,Jeziorki,34A'],
    ['21.05236567', '52.15618549', 'Warszawa,02-776,Nugat,4'],
    ['21.0223729', '52.11662308', 'Warszawa,02-886,Jagielska,46'],
    ['21.01559462', '52.14876456', 'Warszawa,02-829,Czerwonych Wierchów,2'],
    ['21.01382348', '52.13532495', 'Warszawa,02-857,Dźwiękowa,16C'],
    ['21.01022733', '52.13826887', 'Warszawa,02-830,Gawota,10A'],
    ['21.00953522', '52.12905042', 'Warszawa,02-862,Farbiarska,65E/2'],
    ['21.07619035', '52.13317909', 'Warszawa,02-786,Rosoła J.,8'],
    ['21.052487', '52.160575', 'Warszawa,02-776,Nowoursynowska,162/18'],
    ['21.05183832', '52.13110347', 'Warszawa,02-833,Wełniana,31E'],
    ['21.01404426', '52.12950266', 'Warszawa,02-862,Muzyczna,22'],
    ['21.04853927', '52.13321407', 'Warszawa,02-798,Jaworowa,18'],
    ['21.04523439', '52.16935192', 'Warszawa,02-768,Anyżkowa,14'],
    ['21.026987', '52.13811', 'Warszawa,02-815,Żołny,32 B'],
    ['21.02188247', '52.14853831', 'Warszawa,02-803,Bekasów,36'],
    ['21.01138661', '52.12859592', 'Warszawa,02-862,Farbiarska,65'],
    ['21.02026443', '52.15317883', 'Warszawa,02-784,Janowskiego A.,42'],
    ['21.05131442', '52.13024638', 'Warszawa,02-833,Wełniana,29K'],
    ['21.01410336', '52.12980989', 'Warszawa,02-862,Muzyczna,28'],
    ['21.00180253', '52.11265846', 'Warszawa,02-873,Nawłocka,24'],
    ['21.05333048', '52.13091818', 'Warszawa,02-798,Ustronie,20A'],
    ['21.01305078', '52.14755358', 'Warszawa,02-829,Taneczna,59G'],
    ['21.02352088', '52.13788378', 'Warszawa,02-815,Żołny,45'],
    ['21.05580816', '52.1591467', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,30F'],
    ['21.03225067', '52.10643226', 'Warszawa,02-892,Kuropatwy,8ZB'],
    ['21.00475727', '52.15101465', 'Warszawa,02-823,Polki,1B'],
    ['21.02210756', '52.11925304', 'Warszawa,02-843,Tukana,8A'],
    ['21.02206642', '52.13253175', 'Warszawa,02-837,Bogatki,14'],
    ['21.02283482', '52.14514199', 'Warszawa,02-811,Makolągwy,27'],
    ['21.01928959', '52.12066713', 'Warszawa,02-844,Puławska,561'],
    ['21.02369645', '52.13410334', 'Warszawa,02-836,Dzierzby,3A'],
    ['21.03791934', '52.16461299', 'Warszawa,02-787,Chłapowskiego D.,22'],
    ['21.02483446', '52.11018516', 'Warszawa,02-891,Jaskółcza,2'],
    ['21.0344151', '52.10649176', 'Warszawa,02-892,Kuropatwy,2D'],
    ['21.01931133', '52.14933649', 'Warszawa,02-806,Albatrosów,12E'],
    ['21.07955933', '52.13197769', 'Warszawa,02-796,Kulisiewicza,8'],
    ['21.00938156', '52.14301526', 'Warszawa,02-830,Flamenco,19'],
    ['21.04244675', '52.14720882', 'Warszawa,02-776,Dereniowa,11'],
    ['21.05580475', '52.15363812', 'Warszawa,02-776,Nowoursynowska,147A'],
    ['21.03176148', '52.10962782', 'Warszawa,02-890,Papuzia,6'],
    ['21.03908626', '52.11050374', 'Warszawa,02-886,Jagielska,5D'],
    ['21.01435462', '52.13436272', 'Warszawa,02-858,Fanfarowa,10'],
    ['21.04291293', '52.16896017', 'Warszawa,02-787,Nowoursynowska,180B'],
    ['21.02918629', '52.14973842', 'Warszawa,02-807,Zimorodka,7'],
    ['21.02180488', '52.12670644', 'Warszawa,02-842,Maryli,13'],
    ['21.0258891', '52.11528104', 'Warszawa,02-886,Jagielska,49A/1'],
    ['21.02240381', '52.12299108', 'Warszawa,02-843,Pelikanów,15'],
    ['20.9945284', '52.12585625', 'Warszawa,02-867,Baletowa,91'],
    ['21.00991368', '52.15491002', 'Warszawa,02-822,Poleczki,28C'],
    ['21.00918147', '52.13659334', 'Warszawa,02-830,Gawota,19E'],
    ['21.02148303', '52.15111488', 'Warszawa,02-807,Bociania,51'],
    ['21.03070057', '52.15287163', 'Warszawa,02-784,Dembowskiego E.,10'],
    ['21.0191129', '52.14581216', 'Warszawa,02-807,Bociania,9C'],
    ['21.00375884', '52.11134924', 'Warszawa,02-871,Karczunkowska,82E'],
    ['20.99840029', '52.14145715', 'Warszawa,02-850,Tramblanki,33A'],
    ['20.99968714', '52.14216589', 'Warszawa,02-849,Krasnowolska,73'],
    ['21.05825585', '52.13414724', 'Warszawa,02-793,Villardczyków,9'],
    ['21.03408686', '52.16522549', 'Warszawa,02-787,Okaryny,19'],
    ['21.04936371', '52.14524724', 'Warszawa,02-776,Wasilkowskiego J.,4'],
    ['21.02384016', '52.13674145', 'Warszawa,02-817,Białozora,1'],
    ['21.01538104', '52.11210931', 'Warszawa,02-868,Sarabandy,10'],
    ['21.07945084', '52.13218933', 'Warszawa,02-796,Relaksowa,24F'],
    ['21.052851', '52.12865689', 'Warszawa,02-835,Batystowa,4'],
    ['21.00817965', '52.13823316', 'Warszawa,02-830,Gawota,20'],
    ['21.04400428', '52.17183377', 'Warszawa,02-768,Fosa,19'],
    ['21.023687', '52.15072', 'Warszawa,02-804,Kraski,23'],
    ['21.01735927', '52.11689132', 'Warszawa,02-878,Gajdy,10'],
    ['21.0605308', '52.15143173', 'Warszawa,02-797,Imbirowa,6K'],
    ['21.05376', '52.160671', 'Warszawa,02-776,Nowoursynowska,162/20'],
    ['21.01663226', '52.14991023', 'Warszawa,02-829,Samsonowska,1'],
    ['21.01374679', '52.14279128', 'Warszawa,02-830,Rumby,9'],
    ['21.02151157', '52.14859669', 'Warszawa,02-803,Bekasów,43E'],
    ['21.02596461', '52.10821776', 'Warszawa,02-892,Bażancia,39B'],
    ['21.01604562', '52.14207602', 'Warszawa,02-845,Puławska,334'],
    ['21.05375821', '52.12891364', 'Warszawa,02-835,Elegancka,3'],
    ['21.01445791', '52.14095348', 'Warszawa,02-830,Korowodu,4'],
    ['21.02074617', '52.15127617', 'Warszawa,02-804,Kraski,51'],
    ['21.02320593', '52.1230205', 'Warszawa,02-843,Pelikanów,9'],
    ['21.00186426', '52.14505348', 'Warszawa,02-850,Kądziołeczki,54P'],
    ['20.99255934', '52.12668111', 'Warszawa,02-867,Baletowa,105'],
    ['21.01941717', '52.15401901', 'Warszawa,02-784,Janowskiego A.,7'],
    ['21.06976954', '52.1402277', 'Warszawa,02-797,Nowoursynowska,91'],
    ['21.01171668', '52.14276209', 'Warszawa,02-830,Rumby,31'],
    ['21.0027338', '52.12767583', 'Warszawa,02-863,Jeziorki,30'],
    ['21.02079449', '52.11344237', 'Warszawa,02-887,Karmazynowa,34B'],
    ['21.02062294', '52.14948809', 'Warszawa,02-807,Bociania,37B'],
    ['21.00115654', '52.11561942', 'Warszawa,02-850,Trombity,36A'],
    ['21.05741048', '52.1572179', 'Warszawa,02-776,Wrzosowisko,12'],
    ['21.01263212', '52.14497329', 'Warszawa,02-849,Krasnowolska,18'],
    ['21.06922188', '52.13334178', 'Warszawa,02-796,Bronikowskiego K.,9'],
    ['21.05170538', '52.13767949', 'Warszawa,02-793,Małej Łąki,23'],
    ['21.05159828', '52.13046016', 'Warszawa,02-833,Wełniana,29'],
    ['21.06796576', '52.14228229', 'Warszawa,02-797,Nowoursynowska,107E'],
    ['21.05802976', '52.15712912', 'Warszawa,02-776,Wrzosowisko,3'],
    ['21.07136018', '52.13940372', 'Warszawa,02-797,Nowoursynowska,87D'],
    ['21.04844741', '52.13440064', 'Warszawa,02-798,Jaworowa,51'],
    ['21.0089682', '52.13815148', 'Warszawa,02-830,Gawota,16'],
    ['20.99370301', '52.16611919', 'Warszawa,02-699,Taborowa,24'],
    ['21.02748863', '52.14732841', 'Warszawa,02-781,Roentgena W.,12B'],
    ['21.00027252', '52.14097977', 'Warszawa,02-850,Kądziołeczki,29B'],
    ['21.00055193', '52.11564169', 'Warszawa,02-850,Trombity,31'],
    ['21.02849181', '52.10912739', 'Warszawa,02-892,Bażancia,32'],
    ['21.02359167', '52.14969264', 'Warszawa,02-803,Bekasów,58'],
    ['21.02113984', '52.15297017', 'Warszawa,02-784,Janowskiego A.,8'],
    ['21.01077619', '52.15480851', 'Warszawa,02-822,Poleczki,22B'],
    ['21.08010858', '52.1324464', 'Warszawa,02-796,Relaksowa,28G'],
    ['21.01630716', '52.13173911', 'Warszawa,02-858,Transportowców,10A'],
    ['21.02946029', '52.14788743', 'Warszawa,02-781,Św. M. Kolbego,8'],
    ['21.02022125', '52.13192105', 'Warszawa,02-838,Raniuszka,20'],
    ['21.04595826', '52.13161346', 'Warszawa,02-834,Moczydłowska,8'],
    ['21.01660153', '52.13639474', 'Warszawa,02-830,Wędrowców,3'],
    ['21.01635963', '52.13530758', 'Warszawa,02-858,Transportowców,28'],
    ['21.02415475', '52.13340477', 'Warszawa,02-837,Puchaczy,2'],
    ['20.99428291', '52.15979659', 'Warszawa,02-699,Złoty Potok,8'],
    ['21.01235072', '52.13028678', 'Warszawa,02-860,Czterech Wiatrów,39'],
    ['21.03491258', '52.16694292', 'Warszawa,02-786,Al. Rodowicza Anody J.,1'],
    ['21.04917471', '52.15886803', 'Warszawa,02-786,Ciszewskiego J.,6'],
    ['21.08423256', '52.10213985', 'Warszawa,02-973,Prawdziwka,26B'],
    ['21.02028647', '52.11896683', 'Warszawa,02-843,Tukana,5'],
    ['21.02198955', '52.13415215', 'Warszawa,02-836,Dzierzby,15'],
    ['21.01094875', '52.14525593', 'Warszawa,02-849,Krasnowolska,20G'],
    ['21.04485886', '52.15362674', 'Warszawa,02-777,Magellana F.,5'],
    ['21.05834463', '52.15399721', 'Warszawa,02-797,Letniskowa,8'],
    ['21.02588565', '52.1065064', 'Warszawa,02-892,Kuropatwy,26D'],
    ['21.02833921', '52.15035234', 'Warszawa,02-781,Czapli,59'],
    ['21.02365622', '52.10688546', 'Warszawa,02-892,Kuropatwy,34H'],
    ['21.029135', '52.145711', 'Warszawa,02-781,Roentgena W.,15'],
    ['21.0003138', '52.14357174', 'Warszawa,02-850,Kądziołeczki,63'],
    ['21.05505056', '52.12906697', 'Warszawa,02-835,Batystowa,1'],
    ['21.05718129', '52.15713325', 'Warszawa,02-776,Wrzosowisko,16'],
    ['21.00269438', '52.11746726', 'Warszawa,02-868,Dumki,30'],
    ['21.00320669', '52.1277325', 'Warszawa,02-863,Jeziorki,32A'],
    ['21.03133252', '52.14351261', 'Warszawa,02-811,Pustułeczki,2'],
    ['21.06805334', '52.14229556', 'Warszawa,02-797,Nowoursynowska,107D'],
    ['21.07976056', '52.13345459', 'Warszawa,02-797,Nowoursynowska,68D'],
    ['21.005078', '52.159019', 'Warszawa,02-822,Galopu,17'],
    ['21.05641013', '52.15568187', 'Warszawa,02-776,Urwisko,21B'],
    ['21.02341986', '52.14495018', 'Warszawa,02-811,Makolągwy,23K'],
    ['21.01414833', '52.145518', 'Warszawa,02-849,Walczyka,7'],
    ['21.07394705', '52.13565665', 'Warszawa,02-786,Rosoła J.,22E'],
    ['21.02948751', '52.13683102', 'Warszawa,02-815,Żołny,15C'],
    ['21.04657946', '52.16858632', 'Warszawa,02-768,Renety,1'],
    ['21.0333978', '52.16496636', 'Warszawa,02-787,Elegijna,51'],
    ['21.02932051', '52.13894457', 'Warszawa,02-815,Żołny,24F'],
    ['21.00197765', '52.12638514', 'Warszawa,02-863,Jeziorki,18B'],
    ['21.01214596', '52.15434757', 'Warszawa,02-822,Poleczki,14A'],
    ['21.00079513', '52.13524', 'Warszawa,02-855,Sztajerka,9C'],
    ['21.02682022', '52.13565291', 'Warszawa,02-818,Kanarkowa,17'],
    ['21.00779928', '52.133151', 'Warszawa,02-856,Ludwinowska,47E'],
    ['21.0686865', '52.14216118', 'Warszawa,02-797,Nowoursynowska,105'],
    ['21.01844637', '52.14823886', 'Warszawa,02-801,Puławska,387'],
    ['21.01366867', '52.13002702', 'Warszawa,02-860,Czterech Wiatrów,55'],
    ['21.01605128', '52.12586271', 'Warszawa,02-847,Szumiąca,6'],
    ['21.06002174', '52.13372573', 'Warszawa,02-793,Boglarczyków,11'],
    ['21.01084199', '52.15498222', 'Warszawa,02-822,Poleczki,22D'],
    ['21.02327104', '52.1502986', 'Warszawa,02-803,Bekasów,88'],
    ['21.02147964', '52.14851455', 'Warszawa,02-803,Bekasów,43C'],
    ['21.05608444', '52.15887007', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,30A'],
    ['21.00544541', '52.12049931', 'Warszawa,02-868,Sarabandy,91'],
    ['21.0241983', '52.14482608', 'Warszawa,02-811,Makolągwy,23E'],
    ['21.02067461', '52.13310806', 'Warszawa,02-837,Puchaczy,29'],
    ['21.02319037', '52.11657795', 'Warszawa,02-886,Jagielska,44'],
    ['21.00768067', '52.14101902', 'Warszawa,02-830,Mazura,12'],
    ['21.011942', '52.118523', 'Warszawa,02-878,Czysta,51B'],
    ['21.025588', '52.114773', 'Warszawa,02-886,Jagielska,49A'],
    ['21.01008331', '52.13529024', 'Warszawa,02-856,Ludwinowska,24G'],
    ['21.005082', '52.15922', 'Warszawa,02-822,Galopu,15'],
    ['21.01925689', '52.11409824', 'Warszawa,02-886,Puławska,595'],
    ['21.00206683', '52.14476032', 'Warszawa,02-850,Kądziołeczki,52P'],
    ['20.99877651', '52.15682912', 'Warszawa,02-820,Wyczółki,87'],
    ['20.9974189', '52.14277472', 'Warszawa,02-849,Krasnowolska,84'],
    ['21.02145685', '52.14032205', 'Warszawa,02-814,Gruchacza,33A/1'],
    ['21.07884901', '52.13165509', 'Warszawa,02-796,Kulisiewicza,7'],
    ['21.06302844', '52.14711983', 'Warszawa,02-790,Pachnąca,50'],
    ['21.01155231', '52.146759', 'Warszawa,02-828,Pląsy,14F'],
    ['21.00789401', '52.11468919', 'Warszawa,02-850,Trombity,22M'],
    ['21.06230013', '52.14823349', 'Warszawa,02-797,Nowoursynowska,135E'],
    ['20.99918084', '52.12606517', 'Warszawa,02-863,Jeziorki,13B'],
    ['21.02162473', '52.10844891', 'Warszawa,02-892,Bażancia,52D'],
    ['21.00642649', '52.11052067', 'Warszawa,02-871,Karczunkowska,62B'],
    ['21.03270028', '52.15047783', 'Warszawa,02-784,Dembowskiego E.,21'],
    ['21.05209357', '52.13153295', 'Warszawa,02-833,Wełniana,31A'],
    ['21.03793037', '52.16399287', 'Warszawa,02-787,Chłapowskiego D.,39'],
    ['21.00192272', '52.14447594', 'Warszawa,02-850,Kądziołeczki,50N'],
    ['21.03745806', '52.16093076', 'Warszawa,02-786,Związku Walki Młodych,22'],
    ['21.03569081', '52.1620791', 'Warszawa,02-787,Bartóka B.,1'],
    ['21.01968872', '52.1338198', 'Warszawa,02-816,Rajskich Ptaków,18'],
    ['21.06078665', '52.14703553', 'Warszawa,02-796,Migdałowa,21'],
    ['21.0117444', '52.13013751', 'Warszawa,02-860,Czterech Wiatrów,27'],
    ['20.99429401', '52.16649454', 'Warszawa,02-699,Kłobucka,18C'],
    ['21.01106439', '52.15460222', 'Warszawa,02-822,Poleczki,20'],
    ['21.03224973', '52.10700763', 'Warszawa,02-892,Kuropatwy,8X'],
    ['21.08026153', '52.12857473', 'Warszawa,02-797,Nowoursynowska,46'],
    ['21.02198289', '52.13171188', 'Warszawa,02-838,Raniuszka,23'],
    ['21.01330264', '52.10818505', 'Warszawa,02-881,Cymbalistów,11'],
    ['21.05125153', '52.13027558', 'Warszawa,02-833,Wełniana,29L'],
    ['21.0114298', '52.13479165', 'Warszawa,02-856,Ludwinowska,22-Feb'],
    ['21.00533184', '52.11033959', 'Warszawa,02-871,Karczunkowska,74'],
    ['21.02301916', '52.1145293', 'Warszawa,02-886,Jagielska,59C'],
    ['21.01204373', '52.14693909', 'Warszawa,02-828,Pląsy,12F'],
    ['21.02168928', '52.12365175', 'Warszawa,02-843,Rejewskiego,16'],
    ['21.02353899', '52.15024989', 'Warszawa,02-803,Bekasów,92'],
    ['21.01246216', '52.14554577', 'Warszawa,02-849,Walczyka,29'],
    ['21.05728538', '52.15448665', 'Warszawa,02-797,Nowoursynowska,156'],
    ['21.0128363', '52.15094294', 'Warszawa,02-824,Wodzirejów,7E'],
    ['21.01270021', '52.13391744', 'Warszawa,02-856,Ludwinowska,16A'],
    ['21.01303291', '52.14502896', 'Warszawa,02-849,Krasnowolska,16'],
    ['21.06939642', '52.14132801', 'Warszawa,02-797,Nowoursynowska,99'],
    ['21.05755535', '52.13375276', 'Warszawa,02-793,Boglarczyków,24C'],
    ['21.01550558', '52.1309937', 'Warszawa,02-859,Głuszca,8'],
    ['21.04815702', '52.13374951', 'Warszawa,02-798,Jaworowa,41'],
    ['21.03224024', '52.10726956', 'Warszawa,02-892,Kuropatwy,8U'],
    ['21.05406317', '52.14039952', 'Warszawa,02-791,Meander,2'],
    ['20.99743299', '52.11213803', 'Warszawa,02-869,Dawidowska,36'],
    ['21.05176754', '52.12995037', 'Warszawa,02-833,Wełniana,29G'],
    ['20.98970935', '52.14895619', 'Warszawa,02-852,Zatorze,1'],
    ['21.02223886', '52.1232616', 'Warszawa,02-843,Pelikanów,12'],
    ['21.06367118', '52.14735111', 'Warszawa,02-790,Pachnąca,32'],
    ['21.00162451', '52.14105454', 'Warszawa,02-849,Oberka,35A'],
    ['21.02202646', '52.11104254', 'Warszawa,02-887,Karmazynowa,7P'],
    ['21.01432337', '52.15175833', 'Warszawa,02-829,Taneczna,71A'],
    ['21.0837778', '52.12458689', 'Warszawa,02-797,Nowoursynowska,20'],
    ['21.03852937', '52.1579916', 'Warszawa,02-786,Związku Walki Młodych,10'],
    ['21.001616', '52.14475681', 'Warszawa,02-850,Kądziołeczki,52J'],
    ['20.99405704', '52.12636026', 'Warszawa,02-867,Baletowa,92'],
    ['21.00237627', '52.11024175', 'Warszawa,02-871,Karczunkowska,96'],
    ['21.04335022', '52.17198126', 'Warszawa,02-768,Fosa,30'],
    ['21.01968964', '52.15330153', 'Warszawa,02-784,Janowskiego A.,5'],
    ['21.07087538', '52.13759841', 'Warszawa,02-796,Rosoła J.,19'],
    ['21.01713581', '52.13553264', 'Warszawa,02-819,Puławska,372'],
    ['21.06465575', '52.14555721', 'Warszawa,02-790,Pietraszewicza B.,9'],
    ['21.00725569', '52.13129879', 'Warszawa,02-863,Jeziorki,67'],
    ['21.02157997', '52.14875781', 'Warszawa,02-803,Bekasów,45'],
    ['21.06094549', '52.14684183', 'Warszawa,02-796,Migdałowa,29'],
    ['21.02544428', '52.11528868', 'Warszawa,02-886,Jagielska,49A/4'],
    ['21.01873023', '52.14298438', 'Warszawa,02-801,Puławska,425'],
    ['21.00645427', '52.16733452', 'Warszawa,02-682,Bokserska,3'],
    ['21.02845345', '52.13802503', 'Warszawa,02-815,Żołny,26A'],
    ['21.00685797', '52.11966447', 'Warszawa,02-868,Sarabandy,90'],
    ['21.01908702', '52.11320089', 'Warszawa,02-885,Puławska,601'],
    ['21.00062591', '52.14505964', 'Warszawa,02-850,Kądziołeczki,54'],
    ['21.02395491', '52.11336598', 'Warszawa,02-886,Jagielska,51M'],
    ['21.01353381', '52.13373723', 'Warszawa,02-856,Ludwinowska,14'],
    ['21.06717331', '52.13879428', 'Warszawa,02-793,Przy Bażantarni,6'],
    ['21.02051687', '52.14950617', 'Warszawa,02-807,Bociania,37C'],
    ['21.02220117', '52.11494233', 'Warszawa,02-886,Jagielska,59N'],
    ['21.01594031', '52.14040767', 'Warszawa,02-829,Taneczna,18A'],
    ['21.06028351', '52.15177261', 'Warszawa,02-797,Imbirowa,6F'],
    ['21.01621428', '52.14381409', 'Warszawa,02-828,Roztoki,14'],
    ['21.03123875', '52.10907569', 'Warszawa,02-889,Indycza,4'],
    ['21.04452435', '52.15208605', 'Warszawa,02-777,Polinezyjska,10'],
    ['21.05961501', '52.1544027', 'Warszawa,02-797,Bulwarowa,15'],
    ['20.99451892', '52.15670855', 'Warszawa,02-823,Półksiężyca,24A'],
    ['21.01102585', '52.14692685', 'Warszawa,02-828,Pląsy,16H'],
    ['21.03144456', '52.14283749', 'Warszawa,02-729,Rolna,25A'],
    ['21.02296232', '52.14429546', 'Warszawa,02-811,Pustułeczki,37'],
    ['21.01643684', '52.14544804', 'Warszawa,02-828,Rysy,53'],
    ['21.02360529', '52.13811014', 'Warszawa,02-815,Żołny,40'],
    ['21.05050176', '52.13323136', 'Warszawa,02-833,Welurowa,7'],
    ['21.05137312', '52.13138933', 'Warszawa,02-833,Wełniana,33C'],
    ['21.04746742', '52.13475338', 'Warszawa,02-834,Moczydłowska,65'],
    ['21.01635464', '52.15102374', 'Warszawa,02-829,Mączeńskiego Z.,4A'],
    ['21.01590801', '52.1293965', 'Warszawa,02-860,Kotylion,2'],
    ['21.06881169', '52.14184422', 'Warszawa,02-797,Nowoursynowska,103A'],
    ['21.0199121', '52.14645712', 'Warszawa,02-807,Bociania,22'],
    ['21.01300199', '52.11453428', 'Warszawa,02-868,Sarabandy,28D'],
    ['21.0592108', '52.15296347', 'Warszawa,02-797,Arachidowa,1'],
    ['20.99801032', '52.14332755', 'Warszawa,02-849,Krasnowolska,82L'],
    ['21.06404228', '52.14771965', 'Warszawa,02-790,Pachnąca,12'],
    ['21.01832387', '52.14607526', 'Warszawa,02-801,Puławska,403'],
    ['21.01494501', '52.13654866', 'Warszawa,02-830,Wędrowców,09-Nov'],
    ['21.02166653', '52.10630371', 'Warszawa,02-892,Kuropatwy,38'],
    ['21.02677661', '52.1374354', 'Warszawa,02-815,Żołny,27A'],
    ['21.05531356', '52.135564', 'Warszawa,02-793,Małej Łąki,32'],
    ['21.01611735', '52.14905164', 'Warszawa,02-829,Cyrhli,14'],
    ['21.00984881', '52.15516018', 'Warszawa,02-820,Wyczółki,23A'],
    ['21.0031802', '52.14201851', 'Warszawa,02-849,Krasnowolska,53'],
    ['21.05350319', '52.15849201', 'Warszawa,02-777,Przybylskiego Cz.,7'],
    ['21.0135729', '52.12287064', 'Warszawa,02-862,Farbiarska,22'],
    ['21.03479988', '52.10646438', 'Warszawa,02-892,Kuropatwy,2A'],
    ['21.02423394', '52.14442193', 'Warszawa,02-811,Pustułeczki,22'],
    ['21.06576842', '52.14594795', 'Warszawa,02-790,Pietraszewicza B.,1'],
    ['21.0234292', '52.13768772', 'Warszawa,02-815,Żołny,45A'],
    ['21.01180557', '52.12936137', 'Warszawa,02-860,Czterech Wiatrów,15'],
    ['21.0147711', '52.12911083', 'Warszawa,02-860,Akustyczna,16'],
    ['21.02854633', '52.14973697', 'Warszawa,02-804,Gżegżółki,9'],
    ['21.0235791', '52.1449307', 'Warszawa,02-811,Makolągwy,23J'],
    ['21.00103108', '52.14504822', 'Warszawa,02-850,Kądziołeczki,54E'],
    ['21.05623298', '52.15763979', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,2A'],
    ['21.07367955', '52.13595368', 'Warszawa,02-786,Rosoła J.,22F'],
    ['21.0332606', '52.16533343', 'Warszawa,02-787,Elegijna,57'],
    ['20.98452677', '52.13052149', 'Warszawa,02-867,Baletowa,153'],
    ['21.02444903', '52.15061418', 'Warszawa,02-804,Kraski,11'],
    ['20.99687279', '52.15714515', 'Warszawa,02-820,Wyczółki,60'],
    ['20.99972008', '52.14320504', 'Warszawa,02-850,Tramblanki,32C'],
    ['21.05920006', '52.15316274', 'Warszawa,02-797,Arachidowa,2'],
    ['21.02401315', '52.11343444', 'Warszawa,02-886,Jagielska,51L'],
    ['21.00148432', '52.14099419', 'Warszawa,02-850,Kądziołeczki,28'],
    ['21.00994523', '52.121316', 'Warszawa,02-878,Głęboka,25'],
    ['21.00174156', '52.14506314', 'Warszawa,02-850,Kądziołeczki,54M'],
    ['21.02992225', '52.11351981', 'Warszawa,02-886,Jagielska,39'],
    ['21.02144075', '52.13614969', 'Warszawa,02-836,Kormoranów,14A'],
    ['21.0379539', '52.16028027', 'Warszawa,02-786,Związku Walki Młodych,20'],
    ['21.01908013', '52.13306488', 'Warszawa,02-837,Puchaczy,47'],
    ['21.00814199', '52.13277316', 'Warszawa,02-856,Ludwinowska,47U'],
    ['20.99889739', '52.14301481', 'Warszawa,02-850,Tramblanki,43'],
    ['21.04631404', '52.13207136', 'Warszawa,02-834,Moczydłowska,37B'],
    ['21.01193067', '52.14581973', 'Warszawa,02-828,Pląsy,25B'],
    ['20.99824942', '52.14292396', 'Warszawa,02-849,Krasnowolska,82B'],
    ['21.02128952', '52.11493475', 'Warszawa,02-886,Jagielska,61R'],
    ['21.05764601', '52.15622091', 'Warszawa,02-776,Urwisko,17'],
    ['21.05318879', '52.13792825', 'Warszawa,02-793,Małej Łąki,80'],
    ['21.01033139', '52.12327558', 'Warszawa,02-867,Baletowa,11'],
    ['21.02006099', '52.13533381', 'Warszawa,02-836,Modraszki,12'],
    ['21.0147029', '52.13475204', 'Warszawa,02-857,Dźwiękowa,15'],
    ['21.01478146', '52.1436091', 'Warszawa,02-829,Taneczna,33B'],
    ['21.02113833', '52.1530473', 'Warszawa,02-784,Janowskiego A.,10'],
    ['21.02212298', '52.11013566', 'Warszawa,02-891,Jaskółcza,10'],
    ['21.0450645', '52.16886694', 'Warszawa,02-776,Arkadowa,1'],
    ['21.01503146', '52.14053051', 'Warszawa,02-829,Taneczna,19'],
    ['21.02370032', '52.10712055', 'Warszawa,02-892,Kuropatwy,34P'],
    ['21.08065739', '52.13035053', 'Warszawa,02-797,Nowoursynowska,56B'],
    ['21.02520455', '52.15212674', 'Warszawa,02-784,Dunikowskiego X.,27'],
    ['21.04395137', '52.13402614', 'Warszawa,02-834,Perkalowa,8'],
    ['21.027853', '52.14803244', 'Warszawa,02-781,Roentgena W.,6A'],
    ['21.02457579', '52.11532887', 'Warszawa,02-886,Jagielska,51'],
    ['21.00138455', '52.12686632', 'Warszawa,02-863,Jeziorki,27'],
    ['21.00280931', '52.14378129', 'Warszawa,02-849,Krasnowolska,60B'],
    ['21.05418775', '52.15853225', 'Warszawa,02-777,Przybylskiego Cz.,3'],
    ['20.98750209', '52.11125551', 'Warszawa,02-871,Karczunkowska,163'],
    ['20.99732854', '52.11033262', 'Warszawa,02-873,Nawłocka,7'],
    ['21.0224264', '52.15291084', 'Warszawa,02-784,Makowskiego T.,9'],
    ['21.02977787', '52.14990634', 'Warszawa,02-781,Czapli,41'],
    ['21.04799801', '52.13339477', 'Warszawa,02-798,Jaworowa,33'],
    ['21.01198782', '52.14495037', 'Warszawa,02-849,Krasnowolska,18D'],
    ['21.01234653', '52.13594936', 'Warszawa,02-830,Gawota,11M'],
    ['21.03026964', '52.10828975', 'Warszawa,02-892,Bażancia,27'],
    ['21.00749357', '52.14150116', 'Warszawa,02-830,Mazura,16'],
    ['21.00747078', '52.16715463', 'Warszawa,02-682,Bokserska,1'],
    ['21.02760645', '52.14370418', 'Warszawa,02-811,Pustułeczki,21'],
    ['21.02763237', '52.14806471', 'Warszawa,02-781,Roentgena W.,6C'],
    ['21.02825143', '52.1492717', 'Warszawa,02-804,Jastrzębia,33'],
    ['21.01431898', '52.13110783', 'Warszawa,02-862,Muzyczna,46'],
    ['21.01654329', '52.11285118', 'Warszawa,02-879,Chóralna,8'],
    ['21.04922925', '52.14285415', 'Warszawa,02-791,Braci Wagów,13'],
    ['21.013741', '52.153286', 'Warszawa,02-822,Poleczki,9'],
    ['21.05906371', '52.15466266', 'Warszawa,02-797,Modelowa,18'],
    ['21.02071359', '52.14287614', 'Warszawa,02-812,Sójki,35'],
    ['21.02134524', '52.14820733', 'Warszawa,02-803,Bekasów,37'],
    ['21.02394424', '52.13446354', 'Warszawa,02-836,Dzierzby,2'],
    ['21.02311075', '52.11320612', 'Warszawa,02-886,Jagielska,53G'],
    ['21.0016294', '52.14087495', 'Warszawa,02-849,Oberka,35'],
    ['21.05757958', '52.13368997', 'Warszawa,02-793,Boglarczyków,24B'],
    ['21.01448062', '52.1432746', 'Warszawa,02-830,Lambady,8'],
    ['21.06009959', '52.15210991', 'Warszawa,02-797,Imbirowa,6'],
    ['21.02073537', '52.11467753', 'Warszawa,02-886,Jagielska,63A/9'],
    ['21.02092972', '52.12474849', 'Warszawa,02-843,6 Sierpnia,15'],
    ['21.05152364', '52.13624582', 'Warszawa,02-793,Małej Łąki,11'],
    ['21.03890886', '52.11186895', 'Warszawa,02-886,Jagielska,4'],
    ['21.01148057', '52.11967433', 'Warszawa,02-862,Farbiarska,7A'],
    ['21.05224785', '52.13088272', 'Warszawa,02-833,Wełniana,27F'],
    ['21.01599936', '52.14547913', 'Warszawa,02-828,Magury,1'],
    ['21.02385829', '52.11572923', 'Warszawa,02-886,Jagielska,42A'],
    ['21.01397748', '52.11771355', 'Warszawa,02-875,Klarnecistów,25'],
    ['21.01429805', '52.11956803', 'Warszawa,02-878,Gajdy,36'],
    ['21.04719178', '52.13484559', 'Warszawa,02-834,Moczydłowska,67'],
    ['21.05293533', '52.12912801', 'Warszawa,02-835,Elegancka,11'],
    ['20.99505867', '52.13674869', 'Warszawa,02-821,Hołubcowa,30'],
    ['21.04058487', '52.11097067', 'Warszawa,02-886,Jagielska,1D'],
    ['21.05824616', '52.15571471', 'Warszawa,02-776,Bajaderki,7'],
    ['21.01472114', '52.12896233', 'Warszawa,02-860,Akustyczna,16B'],
    ['21.06653117', '52.14515714', 'Warszawa,02-797,Nowoursynowska,119A'],
    ['21.01621766', '52.15102288', 'Warszawa,02-829,Mączeńskiego Z.,4'],
    ['21.01752434', '52.12948838', 'Warszawa,02-845,Puławska,410'],
    ['21.0652812', '52.14474835', 'Warszawa,02-790,Sengera Cichego M.,22'],
    ['21.080579', '52.12973799', 'Warszawa,02-797,Nowoursynowska,52'],
    ['21.02999513', '52.1428378', 'Warszawa,02-781,Roentgena W.,43W'],
    ['21.01595785', '52.15105748', 'Warszawa,02-829,Mączeńskiego Z.,6A'],
    ['21.0590601', '52.15525652', 'Warszawa,02-797,Kokosowa,44'],
    ['21.04838718', '52.13136188', 'Warszawa,02-833,Wełniana,47L'],
    ['21.01732856', '52.14458002', 'Warszawa,02-845,Puławska,318'],
    ['21.060873', '52.152675', 'Warszawa,02-797,Kokosowa,23'],
    ['21.04174101', '52.14712888', 'Warszawa,02-776,Gandhi I.,25'],
    ['21.00898703', '52.12022007', 'Warszawa,02-862,Sarabandy,92G'],
    ['21.03056958', '52.1496595', 'Warszawa,02-781,Czapli,33'],
    ['21.02119536', '52.13274835', 'Warszawa,02-814,Słonki,63'],
    ['21.02298929', '52.11413037', 'Warszawa,02-886,Jagielska,57B'],
    ['21.05703987', '52.15862596', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,18'],
    ['21.02035957', '52.13629915', 'Warszawa,02-836,Kormoranów,20'],
    ['21.03492907', '52.10791173', 'Warszawa,02-892,Kuropatwy,2Z'],
    ['20.99153806', '52.13691337', 'Warszawa,02-821,Hołubcowa,51C'],
    ['21.07834862', '52.13182906', 'Warszawa,02-796,Kulisiewicza,14'],
    ['21.01561971', '52.13367801', 'Warszawa,02-858,Fanfarowa,3'],
    ['21.05588', '52.15824705', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,24C'],
    ['21.01665244', '52.15063747', 'Warszawa,02-829,Mączeńskiego Z.,21'],
    ['21.02163387', '52.13511013', 'Warszawa,02-836,Modraszki,3'],
    ['21.06103585', '52.14608918', 'Warszawa,02-796,Mandarynki,14'],
    ['20.99555377', '52.11068453', 'Warszawa,02-869,Buszycka,6A'],
    ['21.02434895', '52.11407552', 'Warszawa,02-886,Jagielska,51C'],
    ['20.99438667', '52.15728706', 'Warszawa,02-820,Wyczółki,70'],
    ['21.03308553', '52.1556564', 'Warszawa,02-784,Pasaż Ursynowski,5'],
    ['21.02271885', '52.12071179', 'Warszawa,02-814,Słonki,3'],
    ['21.02341809', '52.13465508', 'Warszawa,02-836,Dzierzby,4B'],
    ['21.02622591', '52.10674299', 'Warszawa,02-892,Kuropatwy,26J'],
    ['21.01069449', '52.15093331', 'Warszawa,02-824,Wodzirejów,15C'],
    ['21.02741821', '52.16326122', 'Warszawa,02-722,Al. Komisji Edukacji Narodowej,100'],
    ['21.02366724', '52.13443319', 'Warszawa,02-836,Dzierzby,4'],
    ['21.01438409', '52.10591091', 'Warszawa,02-881,Pozytywki,1'],
    ['21.0322454', '52.10840463', 'Warszawa,02-892,Bażancia,21'],
    ['21.02222242', '52.10735837', 'Warszawa,02-892,Bażancia,49G'],
    ['21.02406039', '52.14487061', 'Warszawa,02-811,Makolągwy,23F'],
    ['21.06002795', '52.1509093', 'Warszawa,02-776,Nowoursynowska,139J'],
    ['21.01227808', '52.13563431', 'Warszawa,02-830,Gawota,11K'],
    ['21.01663846', '52.12169154', 'Warszawa,02-867,Baletowa,4A'],
    ['21.02175786', '52.12438128', 'Warszawa,02-843,Marabuta,8'],
    ['21.04657864', '52.1311194', 'Warszawa,02-834,Jedwabna,8'],
    ['21.04909804', '52.13366377', 'Warszawa,02-798,Jaworowa,28'],
    ['21.05765811', '52.1335665', 'Warszawa,02-793,Boglarczyków,24'],
    ['20.9952042', '52.11047783', 'Warszawa,02-869,Buszycka,4A'],
    ['21.02320775', '52.10623866', 'Warszawa,02-892,Kuropatwy,34A'],
    ['21.00056567', '52.14089551', 'Warszawa,02-850,Kądziołeczki,29'],
    ['21.01557562', '52.10613147', 'Warszawa,02-882,Katarynki,15A'],
    ['20.99718724', '52.14221645', 'Warszawa,02-851,Puchalska,19'],
    ['21.03097557', '52.10696501', 'Warszawa,02-893,Perlicza,5B'],
    ['21.00851464', '52.13242762', 'Warszawa,02-863,Jeziorki,95C'],
    ['21.03179334', '52.10823268', 'Warszawa,02-892,Bażancia,23'],
    ['21.005132', '52.157906', 'Warszawa,02-822,Galopu,21'],
    ['21.0235391', '52.15001174', 'Warszawa,02-803,Bekasów,68B'],
    ['21.00623648', '52.155474', 'Warszawa,02-820,Wyczółki,33B'],
    ['21.01648027', '52.13320121', 'Warszawa,02-858,Transportowców,18'],
    ['21.05171127', '52.13091682', 'Warszawa,02-833,Wełniana,31G'],
    ['21.02631564', '52.1614649', 'Warszawa,02-785,Wiolinowa,17'],
    ['21.05885918', '52.15580788', 'Warszawa,02-797,Bajaderki,13'],
    ['21.01978034', '52.15010867', 'Warszawa,02-806,Rybitwy,9'],
    ['21.06085604', '52.15081603', 'Warszawa,02-797,Nowoursynowska,104'],
    ['21.05843486', '52.13378836', 'Warszawa,02-793,Boglarczyków,20A'],
    ['21.00598138', '52.11907088', 'Warszawa,02-868,Sarabandy,73B'],
    ['21.0225801', '52.12913201', 'Warszawa,02-840,Leśna,15'],
    ['21.06120927', '52.15154237', 'Warszawa,02-797,Imbirowa,8J'],
    ['21.01222999', '52.151088', 'Warszawa,02-824,Wodzirejów,9C'],
    ['20.99064691', '52.11291698', 'Warszawa,02-871,Karczunkowska,164B'],
    ['21.00806543', '52.14303997', 'Warszawa,02-849,Krasnowolska,29D'],
    ['21.06485018', '52.14698192', 'Warszawa,02-790,Pachnąca,37'],
    ['21.0538222', '52.15825614', 'Warszawa,02-777,Przybylskiego Cz.,5'],
    ['21.05738478', '52.14997896', 'Warszawa,02-778,Grzegorzewskiej M.,2A'],
    ['21.01034269', '52.14316345', 'Warszawa,02-849,Flamenco,18'],
    ['21.05581806', '52.1557013', 'Warszawa,02-776,Nowoursynowska,160A'],
    ['21.02148117', '52.13583755', 'Warszawa,02-836,Kormoranów,9'],
    ['21.03448796', '52.10796184', 'Warszawa,02-892,Bażancia,9D'],
    ['20.99452136', '52.11197432', 'Warszawa,02-869,Buszycka,21A'],
    ['21.02006088', '52.11490693', 'Warszawa,02-886,Jagielska,63A/6'],
    ['21.02778713', '52.13765934', 'Warszawa,02-815,Żołny,28'],
    ['21.05216991', '52.13067765', 'Warszawa,02-833,Wełniana,27H'],
    ['21.01562304', '52.14791409', 'Warszawa,02-829,Harendy,8'],
    ['21.02040372', '52.14403431', 'Warszawa,02-812,Lelka,18'],
    ['21.03514257', '52.16432968', 'Warszawa,02-787,Elegijna,1'],
    ['21.0070908', '52.13698497', 'Warszawa,02-830,Gawota,29E'],
    ['21.02076283', '52.14835221', 'Warszawa,02-807,Bociania,24'],
    ['21.05097943', '52.12934815', 'Warszawa,02-798,Ustronie,3'],
    ['21.01202697', '52.13466338', 'Warszawa,02-856,Ludwinowska,22-Nov'],
    ['21.05406968', '52.1306629', 'Warszawa,02-833,Wełniana,11'],
    ['21.04547069', '52.14715437', 'Warszawa,02-776,Warchałowskiego E.,8'],
    ['21.06085663', '52.15180821', 'Warszawa,02-797,Imbirowa,8E'],
    ['21.02943294', '52.1494452', 'Warszawa,02-807,Zimorodka,2'],
    ['21.0266294', '52.10638811', 'Warszawa,02-892,Kuropatwy,24S'],
    ['21.0662764', '52.14485344', 'Warszawa,02-790,Sotta K. Sokoła,13'],
    ['21.02171656', '52.13193506', 'Warszawa,02-838,Raniuszka,14'],
    ['21.03097759', '52.10895234', 'Warszawa,02-889,Indycza,2'],
    ['21.02054107', '52.14929928', 'Warszawa,02-807,Bociania,35A'],
    ['21.05672422', '52.15736735', 'Warszawa,02-776,Nowoursynowska,162C'],
    ['21.01734123', '52.11469037', 'Warszawa,02-884,Puławska,504'],
    ['21.02392173', '52.13792443', 'Warszawa,02-815,Żołny,43'],
    ['21.0164072', '52.11541875', 'Warszawa,02-877,Drumli,7A'],
    ['21.00366312', '52.12352316', 'Warszawa,02-867,Baletowa,40'],
    ['21.00969697', '52.13711625', 'Warszawa,02-830,Gawota,17C'],
    ['20.9804096', '52.13257601', 'Warszawa,02-867,Baletowa,177'],
    ['21.03850411', '52.16408035', 'Warszawa,02-787,Chłapowskiego D.,19'],
    ['21.01765557', '52.1178771', 'Warszawa,02-884,Puławska,480'],
    ['20.99866732', '52.16452627', 'Warszawa,02-699,Taborowa,33C'],
    ['21.03313783', '52.11196726', 'Warszawa,02-889,Turkawki,1A'],
    ['21.06145765', '52.14830755', 'Warszawa,02-790,Kpt. Pawlaczyka,18'],
    ['21.0653365', '52.14503565', 'Warszawa,02-790,Sengera Cichego M.,26'],
    ['21.02059717', '52.11235307', 'Warszawa,02-887,Karmazynowa,33'],
    ['21.01196762', '52.13311162', 'Warszawa,02-862,Farbiarska,70'],
    ['21.04228933', '52.16891986', 'Warszawa,02-787,Nowoursynowska,180'],
    ['21.05239494', '52.131257', 'Warszawa,02-833,Wełniana,27C'],
    ['21.01259525', '52.14301072', 'Warszawa,02-830,Lambady,16'],
    ['21.01569836', '52.11572849', 'Warszawa,02-877,Drumli,10'],
    ['21.01113195', '52.13790849', 'Warszawa,02-830,Gawota,13'],
    ['20.99555179', '52.10860402', 'Warszawa,02-871,Karczunkowska,139A'],
    ['21.01313041', '52.11996752', 'Warszawa,02-862,Farbiarska,9'],
    ['21.06989357', '52.14077554', 'Warszawa,02-797,Nowoursynowska,95'],
    ['20.99501856', '52.11168438', 'Warszawa,02-869,Buszycka,17'],
    ['21.07695599', '52.13212242', 'Warszawa,02-796,Relaksowa,28'],
    ['21.02076298', '52.1412041', 'Warszawa,02-812,Ibisa,3'],
    ['20.99199688', '52.16118841', 'Warszawa,02-699,Kłobucka,9A'],
    ['21.0068203', '52.13506161', 'Warszawa,02-856,Ludwinowska,52A'],
    ['21.00229793', '52.14447769', 'Warszawa,02-850,Kądziołeczki,50T'],
    ['21.05815247', '52.13341222', 'Warszawa,02-793,Boglarczyków,33'],
    ['21.02696857', '52.10722523', 'Warszawa,02-892,Kuropatwy,24F'],
    ['21.06667258', '52.14360334', 'Warszawa,02-790,Sengera Cichego M.,4'],
    ['21.02334739', '52.13496359', 'Warszawa,02-836,Kormoranów,1C'],
    ['21.02276039', '52.14467887', 'Warszawa,02-811,Pustułeczki,28B'],
    ['21.01667523', '52.1243439', 'Warszawa,02-847,Klubowa,1C'],
    ['21.01080139', '52.14269578', 'Warszawa,02-830,Samby,26A'],
    ['21.02402963', '52.12148728', 'Warszawa,02-814,Słonki,2C'],
    ['21.01659191', '52.11923515', 'Warszawa,02-878,Gajdy,30'],
    ['21.01356007', '52.13289557', 'Warszawa,02-856,Ludwinowska,19F'],
    ['21.00897059', '52.14407816', 'Warszawa,02-849,Krasnowolska,32D'],
    ['21.01664509', '52.15008187', 'Warszawa,02-829,Mączeńskiego Z.,1'],
    ['21.00541549', '52.11392277', 'Warszawa,02-850,Trombity,24K'],
    ['21.01101469', '52.12194612', 'Warszawa,02-878,Ługi,9'],
    ['21.02032304', '52.13588771', 'Warszawa,02-836,Kormoranów,15'],
    ['21.00801382', '52.13436352', 'Warszawa,02-856,Ludwinowska,47'],
    ['21.02216408', '52.12154152', 'Warszawa,02-843,Drzemlika,1'],
    ['21.02087125', '52.13592048', 'Warszawa,02-836,Kormoranów,13'],
    ['21.04504653', '52.16803698', 'Warszawa,02-787,Nowoursynowska,172G'],
    ['21.01493486', '52.10856164', 'Warszawa,02-881,Cymbalistów,8'],
    ['21.05849139', '52.15609684', 'Warszawa,02-776,Bajaderki,21'],
    ['20.99973267', '52.15702265', 'Warszawa,02-820,Wyczółki,48'],
    ['21.02389444', '52.1071223', 'Warszawa,02-892,Kuropatwy,34N'],
    ['21.02912625', '52.14956026', 'Warszawa,02-807,Zimorodka,3'],
    ['21.05333592', '52.15038135', 'Warszawa,02-770,Szolc-Rogozińskiego,4'],
    ['21.08896208', '52.10404053', 'Warszawa,02-973,Prawdziwka,16'],
    ['20.9995233', '52.14404228', 'Warszawa,02-850,Tramblanki,36'],
    ['21.01867831', '52.13177395', 'Warszawa,02-844,Puławska,489'],
    ['21.05653072', '52.15890425', 'Warszawa,02-776,Ppłk. Kiedacza Z.S.,32'],
    ['21.055526', '52.12947493', 'Warszawa,02-886,Rybałtów,23'],
    ['20.99767268', '52.14039821', 'Warszawa,02-851,Kadryla,17A'],
    ['21.03579217', '52.10747343', 'Warszawa,02-892,Kuropatwy,2R'],
    ['21.01522248', '52.14377525', 'Warszawa,02-829,Taneczna,35'],
    ['21.02135344', '52.14033435', 'Warszawa,02-814,Gruchacza,33A/2'],
    ['21.00459373', '52.15671384', 'Warszawa,02-820,Wyczółki,49'],
    ['21.04926069', '52.13221597', 'Warszawa,02-833,Wełniana,26A'],
    ['21.01426002', '52.15065735', 'Warszawa,02-827,Menueta,4'],
    ['21.05620028', '52.1558419', 'Warszawa,02-776,Nowoursynowska,160B'],
    ['21.01112948', '52.13538089', 'Warszawa,02-856,Ludwinowska,22J'],
    ['20.99640695', '52.11078954', 'Warszawa,02-874,Orfeusza,5'],
    ['21.05774952', '52.15656314', 'Warszawa,02-776,Urwisko,8'],
    ['21.03226517', '52.10634329', 'Warszawa,02-892,Kuropatwy,8ZC'],
    ['21.01050745', '52.13871445', 'Warszawa,02-830,Gawota,10'],
    ['21.00941976', '52.13791508', 'Warszawa,02-830,Gawota,19'],
    ['21.05624074', '52.14332688', 'Warszawa,02-792,Lanciego F.,9L'],
    ['21.06094036', '52.1517491', 'Warszawa,02-797,Imbirowa,8F'],
    ['21.00430243', '52.15654284', 'Warszawa,02-822,Galopu,18'],
    ['21.06019665', '52.1508891', 'Warszawa,02-776,Nowoursynowska,139H'],
    ['21.01735114', '52.12301154', 'Warszawa,02-846,Sporna,3'],
    ['21.0265761', '52.13845068', 'Warszawa,02-818,Kolibrów,23'],
    ['21.01217954', '52.1292696', 'Warszawa,02-860,Czterech Wiatrów,12'],
    ['21.02149339', '52.14730751', 'Warszawa,02-803,Perkoza,4'],
    ['20.99073922', '52.1269058', 'Warszawa,02-867,Baletowa,115'],
    ['21.02140705', '52.15063887', 'Warszawa,02-807,Bociania,47'],
    ['21.01573616', '52.12456827', 'Warszawa,02-847,Klubowa,5'],
    ['21.03088684', '52.14941499', 'Warszawa,02-781,Czapli,27'],
    ['21.05037074', '52.13340345', 'Warszawa,02-798,Welurowa,8'],
    ['20.99507614', '52.11181702', 'Warszawa,02-869,Buszycka,19'],
    ['21.00140446', '52.12584238', 'Warszawa,02-863,Jeziorki,12B'],
    ['20.98687304', '52.1297922', 'Warszawa,02-867,Baletowa,142'],
    ['21.01148673', '52.13500938', 'Warszawa,02-856,Ludwinowska,22'],
    ['21.05546634', '52.13539027', 'Warszawa,02-793,Małej Łąki,26'],
    ['21.01712604', '52.12759442', 'Warszawa,02-860,Łagiewnicka,1'],
    ['21.0255866', '52.15202611', 'Warszawa,02-784,Dunikowskiego X.,21'],
    ['21.01078645', '52.12133317', 'Warszawa,02-878,Głęboka,17'],
    ['21.04807174', '52.12991058', 'Warszawa,02-834,Kabacki Zakątek,15'],
    ['21.02253469', '52.14455559', 'Warszawa,02-811,Pustułeczki,30'],
    ['21.00344056', '52.14230263', 'Warszawa,02-849,Krasnowolska,56'],
    ['21.04836792', '52.1342237', 'Warszawa,02-798,Jaworowa,47'],
    ['21.05960068', '52.15446356', 'Warszawa,02-797,Bulwarowa,17'],
    ['21.04663302', '52.13915059', 'Warszawa,02-795,Kazury S.,2E'],
    ['21.01437001', '52.12639683', 'Warszawa,02-847,Kostki Napierskiego A.,15'],
    ['21.02379157', '52.14532754', 'Warszawa,02-811,Makolągwy,16'],
    ['21.02921774', '52.14983145', 'Warszawa,02-807,Zimorodka,9'],
    ['21.06891047', '52.14112462', 'Warszawa,02-797,Nowoursynowska,99F'],
    ['21.05846102', '52.1334645', 'Warszawa,02-793,Boglarczyków,27'],
    ['21.01443176', '52.12397202', 'Warszawa,02-846,Sporna,21'],
    ['21.06019415', '52.14673565', 'Warszawa,02-796,Migdałowa,73'],
    ['21.0462648', '52.13196241', 'Warszawa,02-834,Moczydłowska,37A'],
    ['21.02092768', '52.12695015', 'Warszawa,02-842,Maryli,18'],
    ['21.02019219', '52.12884626', 'Warszawa,02-840,Leśna,5'],
    ['21.01979442', '52.11326495', 'Warszawa,02-887,Karmazynowa,32K'],
    ['21.04466146', '52.16880138', 'Warszawa,02-776,Arkadowa,7'],
    ['21.0202265', '52.15307486', 'Warszawa,02-784,Janowskiego A.,40'],
    ['21.0239032', '52.15572818', 'Warszawa,02-784,Romera E.,15'],
    ['21.08357183', '52.12471124', 'Warszawa,02-797,Nowoursynowska,22'],
    ['21.01261325', '52.12291815', 'Warszawa,02-867,Baletowa,5'],
    ['21.061053', '52.14865', 'Warszawa,02-790,Kpt. Pawlaczyka,2'],
    ['21.02866846', '52.14300674', 'Warszawa,02-781,Roentgena W.,43N'],
    ['21.0132732', '52.12418508', 'Warszawa,02-846,Sporna,29'],
    ['21.01497821', '52.11713609', 'Warszawa,02-876,Kapeli,9'],
    ['21.03097046', '52.14957074', 'Warszawa,02-781,Czapli,31A'],
    ['21.01305121', '52.13282845', 'Warszawa,02-856,Ludwinowska,21'],
    ['21.02054259', '52.13896309', 'Warszawa,02-815,Żołny,52B'],
    ['21.01365473', '52.12441161', 'Warszawa,02-846,Sporna,28'],
    ['21.00239211', '52.14476119', 'Warszawa,02-850,Kądziołeczki,52S'],
    ['21.00585053', '52.16747193', 'Warszawa,02-690,Bokserska,17'],
    ['21.01172826', '52.11012991', 'Warszawa,02-871,Karczunkowska,33'],
    ['21.05845995', '52.13369966', 'Warszawa,02-793,Boglarczyków,20'],
    ['21.02295837', '52.15451907', 'Warszawa,02-784,Cybisa J.,5'],
    ['21.02453316', '52.14481155', 'Warszawa,02-811,Makolągwy,23C'],
    ['21.009055', '52.142708', 'Warszawa,02-849,Krasnowolska,29'],
    ['21.01004065', '52.14277809', 'Warszawa,02-830,Flamenco,16'],
    ['20.9955112', '52.14511583', 'Warszawa,02-821,Hołubcowa,75'],
    ['21.01461055', '52.13658444', 'Warszawa,02-830,Wędrowców,15'],
    ['21.00854946', '52.14386795', 'Warszawa,02-849,Krasnowolska,32R'],
    ['21.0466566', '52.16922682', 'Warszawa,02-768,Renety,10'],
    ['21.03521654', '52.11047687', 'Warszawa,02-886,Jagielska,23C'],
    ['21.0586338', '52.12890607', 'Warszawa,02-798,Pod Strzechą,3'],
    ['21.01610531', '52.1446709', 'Warszawa,02-828,Ornak,16'],
    ['21.02331179', '52.15045445', 'Warszawa,02-803,Bekasów,124'],
    ['20.99335785', '52.16621015', 'Warszawa,02-699,Taborowa,26'],
    ['21.00766557', '52.13207771', 'Warszawa,02-863,Jeziorki,77A'],
    ['21.01307282', '52.1185243', 'Warszawa,02-862,Farbiarska,1'],
    ['21.01675588', '52.12442252', 'Warszawa,02-847,Klubowa,1B'],
    ['21.01863792', '52.13249075', 'Warszawa,02-844,Puławska,485'],
    ['21.02829139', '52.13610711', 'Warszawa,02-818,Kanarkowa,6A'],
    ['21.0121308', '52.13029901', 'Warszawa,02-860,Czterech Wiatrów,37'],
    ['20.99597998', '52.11124298', 'Warszawa,02-869,Buszycka,16B'],
    ['21.02171328', '52.14910326', 'Warszawa,02-803,Bekasów,47'],
    ['21.02334573', '52.13309178', 'Warszawa,02-837,Puchaczy,7'],
    ['21.057504', '52.146119', 'Warszawa,02-792,Lanciego F.,12B'],
    ['21.02132315', '52.1469044', 'Warszawa,02-809,Mysikrólika,7'],
    ['21.0218291', '52.1089334', 'Warszawa,02-892,Bażancia,52B'],
    ['21.01502413', '52.1459585', 'Warszawa,02-829,Taneczna,53'],
    ['21.01412083', '52.13280635', 'Warszawa,02-862,Muzyczna,47'],
    ['21.12371513', '52.16423417', 'Warszawa,02-992,Sążnista,8F'],
    ['21.11667474', '52.17080377', 'Warszawa,02-991,Bruzdowa,96D'],
    ['21.09896186', '52.18096431', 'Warszawa,02-987,Syta,181'],
    ['21.11300757', '52.1725397', 'Warszawa,02-991,Bruzdowa,106'],
    ['21.11537835', '52.17436111', 'Warszawa,02-991,Bruzdowa,112U'],
    ['21.09750618', '52.18023849', 'Warszawa,02-987,Syta,179H'],
    ['21.09793742', '52.18016272', 'Warszawa,02-987,Syta,179'],
    ['21.10892514', '52.16671448', 'Warszawa,02-989,Zaściankowa,96'],
    ['21.11399206', '52.1714103', 'Warszawa,02-991,Bruzdowa,102'],
    ['21.10795977', '52.16810612', 'Warszawa,02-987,Syta,108'],
    ['21.11585344', '52.17155289', 'Warszawa,02-991,Bruzdowa,100C'],
    ['21.1073206', '52.1680313', 'Warszawa,02-987,Syta,93'],
    ['21.12572197', '52.16610623', 'Warszawa,02-986,Wał Zawadowski,103A'],
    ['21.12796614', '52.1568047', 'Warszawa,02-992,Metryczna,2A'],
    ['21.10970132', '52.17701031', 'Warszawa,02-991,Bruzdowa,142A'],
    ['21.11533131', '52.17279124', 'Warszawa,02-991,Cicha Dolina,48'],
    ['21.10993616', '52.16856687', 'Warszawa,02-987,Syta,110E'],
    ['21.10193573', '52.17975474', 'Warszawa,02-987,Syta,170'],
    ['21.10997091', '52.16980778', 'Warszawa,02-991,Dostatnia,23'],
    ['21.12224654', '52.1630089', 'Warszawa,02-991,Bruzdowa,81'],
    ['21.10810305', '52.17024439', 'Warszawa,02-989,Hektarowa,2A'],
    ['21.11348732', '52.17272963', 'Warszawa,02-991,Bruzdowa,106A'],
    ['21.11542492', '52.17141611', 'Warszawa,02-991,Bruzdowa,100B'],
    ['21.13000403', '52.15455024', 'Warszawa,02-991,Bruzdowa,37'],
    ['21.12666567', '52.15968063', 'Warszawa,02-991,Bruzdowa,44'],
    ['21.11471851', '52.17532427', 'Warszawa,02-988,Jara,5C'],
    ['21.09951421', '52.18065453', 'Warszawa,02-987,Syta,177'],
    ['21.11276055', '52.17490537', 'Warszawa,02-994,Jara,7'],
    ['21.11031795', '52.1661988', 'Warszawa,02-989,Vogla Z.,62'],
    ['21.11234305', '52.17423782', 'Warszawa,02-991,Bruzdowa,118B'],
    ['21.11174221', '52.17578785', 'Warszawa,02-991,Bruzdowa,128B'],
    ['21.11489713', '52.17510658', 'Warszawa,02-988,Jara,5E'],
    ['21.11647925', '52.16695925', 'Warszawa,02-991,Bruzdowa,95G'],
    ['21.10691883', '52.17931696', 'Warszawa,02-991,Bruzdowa,177'],
    ['21.09729884', '52.1805851', 'Warszawa,02-987,Syta,181C'],
    ['21.1078808', '52.17388423', 'Warszawa,02-987,Syta,125'],
    ['21.10397636', '52.17990593', 'Warszawa,02-987,Syta,170F'],
    ['21.11517022', '52.1754698', 'Warszawa,02-988,Jara,5B'],
    ['21.11237847', '52.1732985', 'Warszawa,02-991,Bruzdowa,112'],
    ['21.11700284', '52.17026836', 'Warszawa,02-991,Bruzdowa,92F'],
    ['21.10624582', '52.16475409', 'Warszawa,02-993,Nad Wilanówką,13'],
    ['21.10680237', '52.17927669', 'Warszawa,02-991,Bruzdowa,177A'],
    ['21.11613205', '52.17208636', 'Warszawa,02-991,Bruzdowa,102E'],
    ['21.11460029', '52.17354328', 'Warszawa,02-991,Bruzdowa,108D'],
    ['21.11636409', '52.17116339', 'Warszawa,02-991,Bruzdowa,98F'],
    ['21.11453897', '52.17554065', 'Warszawa,02-988,Jara,5A'],
    ['21.12182038', '52.1580027', 'Warszawa,02-993,Syta,44'],
    ['21.132612', '52.155185', 'Warszawa,02-991,Bruzdowa,30M'],
    ['21.11440875', '52.17152424', 'Warszawa,02-991,Bruzdowa,102A'],
    ['21.11644448', '52.16773232', 'Warszawa,02-991,Bruzdowa,97'],
    ['21.11547805', '52.1709303', 'Warszawa,02-991,Bruzdowa,98D'],
    ['21.11403804', '52.16351194', 'Warszawa,02-992,Satynowa,33'],
    ['21.12275666', '52.16322818', 'Warszawa,02-991,Bruzdowa,56'],
    ['21.12009114', '52.15886757', 'Warszawa,02-993,Syta,50A'],
    ['21.10949912', '52.17698956', 'Warszawa,02-991,Bruzdowa,142'],
    ['21.11317233', '52.17307697', 'Warszawa,02-991,Bruzdowa,108A'],
    ['21.10661722', '52.16935781', 'Warszawa,02-987,Syta,99'],
    ['21.11165732', '52.17442023', 'Warszawa,02-988,Jara,7D'],
    ['21.09857962', '52.18003482', 'Warszawa,02-987,Syta,175H'],
    ['21.11199684', '52.17226063', 'Warszawa,02-991,Bruzdowa,125A'],
    ['21.10771525', '52.17503184', 'Warszawa,02-987,Syta,144A'],
    ['21.10436694', '52.17789316', 'Warszawa,02-987,Syta,158'],
    ['21.11112278', '52.17405207', 'Warszawa,02-991,Bruzdowa,143'],
    ['21.10730562', '52.17044457', 'Warszawa,02-987,Syta,118D'],
    ['21.1086883', '52.16722735', 'Warszawa,02-987,Syta,102A'],
    ['21.1166653', '52.16146683', 'Warszawa,02-993,Syta,68A'],
    ['21.11375235', '52.1757263', 'Warszawa,02-988,Jara,4F'],
    ['21.11513238', '52.17370679', 'Warszawa,02-991,Bruzdowa,108E'],
    ['21.11406064', '52.17597132', 'Warszawa,02-988,Jara,4B'],
    ['21.11296813', '52.1710595', 'Warszawa,02-991,Bruzdowa,117B'],
    ['21.1143828', '52.17115066', 'Warszawa,02-991,Bruzdowa,100'],
    ['21.11370384', '52.17246757', 'Warszawa,02-991,Cicha Dolina,10'],
    ['21.10732741', '52.17161222', 'Warszawa,02-987,Syta,122'],
    ['21.10869067', '52.1678575', 'Warszawa,02-987,Syta,106N'],
    ['21.11909963', '52.16617279', 'Warszawa,02-991,Bruzdowa,76'],
    ['21.1154537', '52.17264454', 'Warszawa,02-991,Cicha Dolina,52'],
    ['21.10828353', '52.16579989', 'Warszawa,02-993,Nad Wilanówką,2'],
    ['21.11103258', '52.16760822', 'Warszawa,02-991,Dostatnia,1F'],
    ['21.10979182', '52.16953848', 'Warszawa,02-991,Dostatnia,21A'],
    ['21.11497727', '52.17568206', 'Warszawa,02-988,Jara,5'],
    ['21.10773363', '52.16980962', 'Warszawa,02-987,Syta,116E'],
    ['21.11675913', '52.16937006', 'Warszawa,02-989,Zaściankowa,8A'],
    ['21.11931132', '52.1704944', 'Warszawa,02-989,Zaściankowa,4'],
    ['21.09833622', '52.18082368', 'Warszawa,02-987,Syta,181A'],
    ['21.1252941', '52.16065095', 'Warszawa,02-991,Bruzdowa,73'],
    ['21.10657934', '52.17040402', 'Warszawa,02-987,Syta,118A'],
    ['21.11448549', '52.17303587', 'Warszawa,02-991,Bruzdowa,106C'],
    ['21.10863272', '52.17050537', 'Warszawa,02-989,Hektarowa,2C'],
    ['21.10270028', '52.17874882', 'Warszawa,02-987,Syta,151'],
    ['21.117406', '52.169043', 'Warszawa,02-989,Zaściankowa,7A'],
    ['21.10017758', '52.18036621', 'Warszawa,02-987,Syta,175'],
    ['21.11520668', '52.17475515', 'Warszawa,02-991,Bruzdowa,116S'],
    ['21.11650331', '52.16963292', 'Warszawa,02-989,Zaściankowa,8B'],
    ['21.11267606', '52.17291545', 'Warszawa,02-991,Bruzdowa,108'],
    ['21.11622415', '52.16655501', 'Warszawa,02-991,Bruzdowa,95C'],
    ['21.11511378', '52.17031588', 'Warszawa,02-991,Bruzdowa,96'],
    ['21.11175274', '52.17104343', 'Warszawa,02-991,Bruzdowa,119'],
    ['21.11323879', '52.17231793', 'Warszawa,02-991,Cicha Dolina,2'],
    ['21.10797571', '52.16544594', 'Warszawa,02-993,Nad Wilanówką,5'],
    ['21.111983', '52.167194', 'Warszawa,02-989,Zaściankowa,17A'],
    ['21.110132', '52.165125', 'Warszawa,02-993,Syta,98'],
    ['21.12685438', '52.16013165', 'Warszawa,02-991,Bruzdowa,46'],
    ['21.11630686', '52.17068502', 'Warszawa,02-991,Bruzdowa,96C'],
    ['21.1137919', '52.17220073', 'Warszawa,02-991,Bruzdowa,104'],
    ['21.10160702', '52.18012043', 'Warszawa,02-987,Syta,174'],
    ['21.11299276', '52.16816679', 'Warszawa,02-989,Zaściankowa,52'],
    ['21.1023446', '52.17849792', 'Warszawa,02-986,Trójpolowa,4'],
    ['21.11599082', '52.17429116', 'Warszawa,02-991,Bruzdowa,112Z'],
    ['21.09860966', '52.18054642', 'Warszawa,02-987,Syta,177H'],
    ['21.10740832', '52.17180656', 'Warszawa,02-987,Syta,124'],
    ['21.11011899', '52.16965316', 'Warszawa,02-991,Dostatnia,21'],
    ['21.11713014', '52.17151336', 'Warszawa,02-991,Bruzdowa,98H'],
    ['21.11395016', '52.17533945', 'Warszawa,02-988,Jara,5F'],
    ['21.09747608', '52.18028914', 'Warszawa,02-987,Syta,179G'],
    ['21.11284443', '52.17151389', 'Warszawa,02-991,Bruzdowa,121F'],
    ['21.10691696', '52.1710859', 'Warszawa,02-987,Syta,120'],
    ['21.12924402', '52.16389876', 'Warszawa,02-986,Wał Zawadowski,99'],
    ['21.12256546', '52.16485899', 'Warszawa,02-992,Bruzdowa,60J'],
    ['21.10817453', '52.17143658', 'Warszawa,02-991,Dostatnia,31B'],
    ['21.11131235', '52.17156846', 'Warszawa,02-991,Dostatnia,54A'],
    ['21.11206474', '52.16407241', 'Warszawa,02-993,Syta,92'],
    ['21.11286404', '52.16943074', 'Warszawa,02-991,Bruzdowa,109C'],
    ['21.11697002', '52.16978135', 'Warszawa,02-989,Zaściankowa,8C'],
    ['21.10820352', '52.16734778', 'Warszawa,02-987,Syta,104'],
    ['21.11678642', '52.17187702', 'Warszawa,02-991,Bruzdowa,100E'],
    ['21.09792149', '52.17987983', 'Warszawa,02-987,Syta,175M'],
    ['21.12982466', '52.15701657', 'Warszawa,02-991,Bruzdowa,36A'],
    ['21.1173621', '52.1720545', 'Warszawa,02-991,Bruzdowa,100F'],
    ['21.11613327', '52.17033586', 'Warszawa,02-991,Bruzdowa,96G'],
    ['21.10792876', '52.16885032', 'Warszawa,02-987,Syta,112F'],
    ['21.10706281', '52.16668249', 'Warszawa,02-987,Syta,87G'],
    ['21.10821971', '52.16771393', 'Warszawa,02-987,Syta,106M'],
    ['21.11663637', '52.17130784', 'Warszawa,02-991,Bruzdowa,98G'],
    ['21.09725964', '52.17974058', 'Warszawa,02-987,Syta,175S'],
    ['21.12408353', '52.15663153', 'Warszawa,02-993,Syta,38'],
    ['21.11503482', '52.17397001', 'Warszawa,02-991,Bruzdowa,112S'],
    ['21.11030794', '52.16774634', 'Warszawa,02-987,Syta,102D'],
    ['21.10651917', '52.1706216', 'Warszawa,02-987,Syta,118B'],
    ['21.11335746', '52.16987374', 'Warszawa,02-991,Bruzdowa,107M'],
    ['21.11776857', '52.17218312', 'Warszawa,02-991,Bruzdowa,100G'],
    ['21.11715552', '52.17391907', 'Warszawa,02-991,Bruzdowa,106H'],
    ['21.1067816', '52.17095863', 'Warszawa,02-987,Syta,118'],
    ['21.10857508', '52.17132978', 'Warszawa,02-991,Dostatnia,31A'],
    ['21.11859855', '52.17078849', 'Warszawa,02-991,Bruzdowa,92N'],
    ['21.13074326', '52.15970621', 'Warszawa,02-991,Bruzdowa,42'],
    ['21.107876', '52.169079', 'Warszawa,02-987,Syta,114'],
    ['21.12041133', '52.17054288', 'Warszawa,02-989,Zaściankowa,2'],
    ['21.10812635', '52.17066936', 'Warszawa,02-989,Hektarowa,2D'],
    ['21.1324426', '52.15628362', 'Warszawa,02-991,Bruzdowa,32W/1'],
    ['21.10723164', '52.17070752', 'Warszawa,02-987,Syta,118C'],
    ['21.11664501', '52.17372884', 'Warszawa,02-991,Bruzdowa,106G'],
    ['21.09829755', '52.18041801', 'Warszawa,02-987,Syta,177S'],
    ['21.11306563', '52.16893296', 'Warszawa,02-991,Bruzdowa,105Ł'],
    ['21.10829052', '52.16704129', 'Warszawa,02-987,Syta,102'],
    ['21.11401439', '52.17602974', 'Warszawa,02-988,Jara,4C'],
    ['21.10632073', '52.17145779', 'Warszawa,02-987,Syta,109A'],
    ['21.11419471', '52.16900556', 'Warszawa,02-991,Dostatnia,4L'],
    ['21.10799715', '52.17130306', 'Warszawa,02-991,Dostatnia,31C'],
    ['21.12845759', '52.16099071', 'Warszawa,02-991,Bruzdowa,46U'],
    ['21.10736839', '52.17137077', 'Warszawa,02-989,Hektarowa,10'],
    ['21.10956891', '52.16751211', 'Warszawa,02-987,Syta,102B'],
    ['21.09832817', '52.18036651', 'Warszawa,02-987,Syta,177R'],
    ['21.11627272', '52.17170402', 'Warszawa,02-991,Bruzdowa,100D'],
    ['21.11783575', '52.17012885', 'Warszawa,02-989,Zaściankowa,6B'],
    ['21.10620843', '52.16992047', 'Warszawa,02-987,Syta,103'],
    ['21.09823841', '52.18051926', 'Warszawa,02-987,Syta,177U'],
    ['21.11869022', '52.16232076', 'Warszawa,02-991,Sady Zawadowskie,15'],
    ['21.10758235', '52.16754708', 'Warszawa,02-987,Syta,91'],
    ['21.10766424', '52.16582419', 'Warszawa,02-993,Nad Wilanówką,6'],
    ['21.13068061', '52.15511733', 'Warszawa,02-991,Bruzdowa,32'],
    ['21.10854985', '52.1697703', 'Warszawa,02-987,Syta,114W'],
    ['21.10603283', '52.18134155', 'Warszawa,02-988,Glebowa,10'],
    ['21.13063895', '52.16107721', 'Warszawa,02-986,Wał Zawadowski,85A'],
    ['21.11186229', '52.18046842', 'Warszawa,02-986,Wał Zawadowski,135'],
    ['21.10984587', '52.16716119', 'Warszawa,02-989,Zaściankowa,92'],
    ['21.11539393', '52.17271657', 'Warszawa,02-991,Cicha Dolina,50'],
    ['21.10860751', '52.17112378', 'Warszawa,02-991,Dostatnia,31'],
    ['21.11855893', '52.16904798', 'Warszawa,02-989,Zaściankowa,5'],
    ['21.1097824', '52.17667886', 'Warszawa,02-991,Bruzdowa,140'],
    ['21.1191605', '52.17008503', 'Warszawa,02-989,Zaściankowa,4C'],
    ['21.09771168', '52.18068341', 'Warszawa,02-987,Syta,181B'],
    ['21.11438233', '52.16861437', 'Warszawa,02-989,Zaściankowa,28'],
    ['21.11680894', '52.1742626', 'Warszawa,02-991,Bruzdowa,108H'],
    ['21.10512612', '52.18004224', 'Warszawa,02-987,Syta,170K'],
    ['21.1083106', '52.16611201', 'Warszawa,02-987,Syta,85'],
    ['21.1061764', '52.17017876', 'Warszawa,02-987,Syta,103A'],
    ['21.1140878', '52.16852463', 'Warszawa,02-989,Zaściankowa,34'],
    ['21.10942992', '52.17031751', 'Warszawa,02-987,Syta,114Y'],
    ['21.1147117', '52.17249342', 'Warszawa,02-991,Cicha Dolina,30'],
    ['21.10929482', '52.17416088', 'Warszawa,02-988,Jara,12A'],
    ['21.11455218', '52.17584862', 'Warszawa,02-988,Jara,4'],
    ['21.11663567', '52.16437186', 'Warszawa,02-992,Satynowa,15'],
    ['21.10600993', '52.17836719', 'Warszawa,02-988,Siedliskowa,5'],
    ['21.11553621', '52.16972827', 'Warszawa,02-991,Bruzdowa,92'],
    ['21.11347382', '52.17239319', 'Warszawa,02-991,Cicha Dolina,6'],
    ['21.10808487', '52.17166928', 'Warszawa,02-987,Syta,124A'],
    ['21.1316005', '52.16133741', 'Warszawa,02-986,Wał Zawadowski,85B'],
    ['21.10768339', '52.1692462', 'Warszawa,02-987,Syta,114H'],
    ['21.12080848', '52.16253495', 'Warszawa,02-991,Bruzdowa,81C/2'],
    ['21.10178446', '52.17985444', 'Warszawa,02-987,Syta,172'],
    ['21.10787359', '52.16829098', 'Warszawa,02-987,Syta,110'],
    ['21.13126464', '52.15493145', 'Warszawa,02-991,Bruzdowa,32H'],
    ['21.11175116', '52.16778992', 'Warszawa,02-991,Dostatnia,1'],
    ['21.10642944', '52.17915678', 'Warszawa,02-991,Bruzdowa,177D'],
    ['21.11770469', '52.16975737', 'Warszawa,02-989,Zaściankowa,6A'],
    ['21.12086668', '52.16246715', 'Warszawa,02-991,Bruzdowa,81C/1'],
    ['21.11193648', '52.17388937', 'Warszawa,02-991,Bruzdowa,116'],
    ['21.12707761', '52.15677451', 'Warszawa,02-992,Metryczna,2'],
    ['21.1154593', '52.16399417', 'Warszawa,02-992,Satynowa,23'],
    ['21.1112644', '52.17101241', 'Warszawa,02-991,Bruzdowa,119D'],
    ['21.10853984', '52.17020277', 'Warszawa,02-989,Hektarowa,2B'],
    ['21.10823838', '52.17468865', 'Warszawa,02-987,Syta,142'],
    ['21.12036047', '52.16287225', 'Warszawa,02-991,Sady Zawadowskie,7'],
    ['21.11579549', '52.17452312', 'Warszawa,02-991,Bruzdowa,112W'],
    ['21.11472358', '52.17172207', 'Warszawa,02-991,Bruzdowa,102B'],
    ['21.10767274', '52.17030191', 'Warszawa,02-989,Hektarowa,2'],
    ['21.10691051', '52.16338417', 'Warszawa,02-990,Vogla Z.,33'],
    ['21.10832869', '52.16800223', 'Warszawa,02-987,Syta,108B'],
    ['21.09863938', '52.18049562', 'Warszawa,02-987,Syta,177J'],
    ['21.10427927', '52.17631857', 'Warszawa,02-987,Syta,137A'],
    ['21.11265154', '52.16962142', 'Warszawa,02-991,Bruzdowa,109D'],
    ['21.11401781', '52.168466', 'Warszawa,02-989,Zaściankowa,36'],
    ['21.11047468', '52.1680761', 'Warszawa,02-987,Syta,104H'],
    ['21.11444858', '52.17231813', 'Warszawa,02-991,Cicha Dolina,14'],
    ['21.09915367', '52.18049505', 'Warszawa,02-987,Syta,177B'],
    ['21.11738275', '52.16915386', 'Warszawa,02-989,Zaściankowa,7'],
    ['21.11717105', '52.16950389', 'Warszawa,02-989,Zaściankowa,8'],
    ['21.10866345', '52.16925866', 'Warszawa,02-987,Syta,112K'],
    ['21.10612148', '52.16948377', 'Warszawa,02-987,Syta,99C'],
    ['21.10583986', '52.16570789', 'Warszawa,02-993,Nad Wilanówką,16'],
    ['21.11443653', '52.17608938', 'Warszawa,02-988,Jara,4A'],
    ['21.11499758', '52.17127297', 'Warszawa,02-991,Bruzdowa,100A'],
    ['21.11629884', '52.17408115', 'Warszawa,02-991,Bruzdowa,108G'],
    ['21.10779821', '52.16858274', 'Warszawa,02-987,Syta,112'],
    ['21.11572948', '52.17195759', 'Warszawa,02-991,Bruzdowa,102D'],
    ['21.10702035', '52.16864334', 'Warszawa,02-987,Syta,95'],
    ['21.11269644', '52.16807759', 'Warszawa,02-989,Zaściankowa,58'],
    ['21.10972649', '52.16697933', 'Warszawa,02-989,Zaściankowa,94'],
    ['21.10671426', '52.17148492', 'Warszawa,02-987,Syta,109'],
    ['21.11175544', '52.1724845', 'Warszawa,02-991,Bruzdowa,127B'],
    ['21.11559453', '52.17300426', 'Warszawa,02-991,Cicha Dolina,60'],
    ['21.1118384', '52.16896294', 'Warszawa,02-991,Bruzdowa,109P'],
    ['21.12022843', '52.15870988', 'Warszawa,02-993,Syta,50'],
    ['21.11394924', '52.16805597', 'Warszawa,02-989,Zaściankowa,13'],
    ['21.11499273', '52.17083811', 'Warszawa,02-991,Bruzdowa,98B'],
    ['21.11984876', '52.16217713', 'Warszawa,02-991,Bruzdowa,81E'],
    ['21.11064581', '52.17571152', 'Warszawa,02-991,Bruzdowa,130'],
    ['21.13333337', '52.15567995', 'Warszawa,02-991,Olęderska,14A'],
    ['21.12475758', '52.16017404', 'Warszawa,02-993,Bruzdowa,71B'],
    ['21.11839029', '52.17136257', 'Warszawa,02-991,Bruzdowa,96K'],
    ['21.10709619', '52.17975966', 'Warszawa,02-991,Bruzdowa,162'],
    ['21.10731884', '52.16337376', 'Warszawa,02-990,Vogla Z.,31'],
    ['21.0987003', '52.18039448', 'Warszawa,02-987,Syta,177L'],
    ['21.11717097', '52.16744513', 'Warszawa,02-991,Bruzdowa,84'],
    ['21.11136275', '52.17759216', 'Warszawa,02-991,Bruzdowa,142F'],
    ['21.10612726', '52.16745045', 'Warszawa,02-987,Syta,91H'],
    ['21.10347374', '52.17939412', 'Warszawa,02-987,Syta,166'],
    ['21.12046147', '52.16896188', 'Warszawa,02-991,Bruzdowa,84K'],
    ['21.11226124', '52.16792971', 'Warszawa,02-989,Zaściankowa,64'],
    ['21.10993893', '52.1676289', 'Warszawa,02-987,Syta,102C'],
    ['21.10119812', '52.180515', 'Warszawa,02-987,Syta,174B'],
    ['21.1075683', '52.17447797', 'Warszawa,02-987,Syta,127'],
    ['21.11022688', '52.16714057', 'Warszawa,02-989,Zaściankowa,90'],
    ['21.10861044', '52.16661432', 'Warszawa,02-989,Zaściankowa,98'],
    ['21.10619955', '52.16578495', 'Warszawa,02-993,Nad Wilanówką,14'],
    ['21.10734857', '52.16968737', 'Warszawa,02-987,Syta,116D'],
    ['21.11717925', '52.16326945', 'Warszawa,02-992,Lercha W.,14H'],
    ['21.11396936', '52.17287734', 'Warszawa,02-991,Bruzdowa,106B'],
    ['21.11529467', '52.16394134', 'Warszawa,02-992,Satynowa,25'],
    ['21.1165379', '52.17221674', 'Warszawa,02-991,Bruzdowa,102F'],
    ['21.11275294', '52.17440425', 'Warszawa,02-991,Bruzdowa,118E'],
    ['21.11205625', '52.17078929', 'Warszawa,02-991,Bruzdowa,117E'],
    ['21.10811316', '52.16993547', 'Warszawa,02-987,Syta,116F'],
    ['21.12876155', '52.15611501', 'Warszawa,02-991,Bruzdowa,53'],
    ['21.13307015', '52.15473178', 'Warszawa,02-991,Olęderska,10'],
    ['21.10685064', '52.17169754', 'Warszawa,02-987,Syta,111'],
    ['21.10685568', '52.16969705', 'Warszawa,02-987,Syta,116B'],
    ['21.11266491', '52.17608316', 'Warszawa,02-991,Bruzdowa,128D'],
    ['21.10683128', '52.16995662', 'Warszawa,02-987,Syta,116'],
    ['21.11076069', '52.16852475', 'Warszawa,02-987,Syta,106J'],
    ['21.11524624', '52.16827962', 'Warszawa,02-991,Bruzdowa,99A'],
    ['21.12108548', '52.15980584', 'Warszawa,02-993,Syta,52B'],
    ['21.11664326', '52.17015728', 'Warszawa,02-991,Bruzdowa,92D'],
    ['21.10693797', '52.17900502', 'Warszawa,02-991,Bruzdowa,177K'],
    ['21.10975274', '52.1743144', 'Warszawa,02-988,Jara,12'],
    ['21.1100055', '52.16848494', 'Warszawa,02-987,Syta,110F'],
    ['21.11049283', '52.17486452', 'Warszawa,02-991,Bruzdowa,149'],
    ['21.11155125', '52.16772882', 'Warszawa,02-991,Dostatnia,1B'],
    ['21.11371041', '52.16954236', 'Warszawa,02-991,Bruzdowa,107K'],
    ['21.11817434', '52.17231261', 'Warszawa,02-991,Bruzdowa,100H'],
    ['21.12960667', '52.16286767', 'Warszawa,02-986,Wał Zawadowski,93A'],
    ['21.1069842', '52.16764376', 'Warszawa,02-987,Syta,91L'],
    ['21.11348661', '52.17519052', 'Warszawa,02-988,Jara,5G'],
    ['21.11159592', '52.18056531', 'Warszawa,02-986,Wał Zawadowski,137'],
    ['21.11583467', '52.16283949', 'Warszawa,02-992,Lercha W.,14C'],
    ['21.11263305', '52.16630501', 'Warszawa,02-989,Łokciowa,12'],
    ['21.11716796', '52.16684723', 'Warszawa,02-991,Bruzdowa,95'],
    ['21.10828235', '52.16413329', 'Warszawa,02-990,Vogla Z.,45'],
    ['21.11502411', '52.16961285', 'Warszawa,02-991,Bruzdowa,105'],
    ['21.11484428', '52.17419089', 'Warszawa,02-991,Bruzdowa,112R'],
    ['21.10800084', '52.16914692', 'Warszawa,02-987,Syta,112H'],
    ['21.09790507', '52.1802155', 'Warszawa,02-987,Syta,179A'],
    ['21.11474043', '52.1705184', 'Warszawa,02-991,Bruzdowa,98'],
    ['21.1122767', '52.16829197', 'Warszawa,02-991,Dostatnia,4A'],
    ['21.11901058', '52.17039989', 'Warszawa,02-989,Zaściankowa,4D'],
    ['21.11535696', '52.17525439', 'Warszawa,02-988,Jara,5D'],
    ['21.10655375', '52.17919704', 'Warszawa,02-991,Bruzdowa,177C'],
    ['21.10624696', '52.16927627', 'Warszawa,02-987,Syta,99B'],
    ['21.11059643', '52.18146939', 'Warszawa,02-988,Glebowa,135B'],
    ['21.12610256', '52.16385548', 'Warszawa,02-992,Sążnista,7'],
    ['21.10704701', '52.17728', 'Warszawa,02-987,Syta,154'],
    ['21.09643086', '52.18039565', 'Warszawa,02-987,Syta,181D'],
    ['21.11609025', '52.1641963', 'Warszawa,02-992,Satynowa,19'],
    ['21.11122382', '52.17393372', 'Warszawa,02-991,Bruzdowa,141'],
    ['21.11580525', '52.16575111', 'Warszawa,02-991,Bruzdowa,93E'],
    ['21.11611279', '52.16605511', 'Warszawa,02-991,Bruzdowa,93D'],
    ['21.11859536', '52.17112099', 'Warszawa,02-991,Bruzdowa,96L'],
    ['21.13273757', '52.15644947', 'Warszawa,02-991,Bruzdowa,32Z'],
    ['21.1162211', '52.16148701', 'Warszawa,02-993,Syta,68'],
    ['21.11896362', '52.16346466', 'Warszawa,02-992,Lercha W.,2'],
    ['21.11246408', '52.17431127', 'Warszawa,02-991,Bruzdowa,118C'],
    ['21.129907', '52.156278', 'Warszawa,02-991,Bruzdowa,30'],
    ['21.11951917', '52.16260189', 'Warszawa,02-991,Sady Zawadowskie,11'],
    ['21.13258388', '52.15516288', 'Warszawa,02-991,Bruzdowa,30M/1'],
    ['21.11555163', '52.16238038', 'Warszawa,02-992,Lercha W.,12'],
    ['21.0995904', '52.17947077', 'Warszawa,02-988,Glebowa,26'],
    ['21.11273527', '52.16656253', 'Warszawa,02-989,Łokciowa,16'],
    ['21.11107912', '52.16896492', 'Warszawa,02-991,Dostatnia,14'],
    ['21.121389', '52.163828', 'Warszawa,02-991,Bruzdowa,58'],
    ['21.10596175', '52.18052493', 'Warszawa,02-991,Bruzdowa,191'],
    ['21.11507264', '52.16259441', 'Warszawa,02-992,Lercha W.,14'],
    ['21.11007489', '52.17102488', 'Warszawa,02-991,Dostatnia,38'],
    ['21.11351461', '52.1759916', 'Warszawa,02-988,Jara,4E'],
    ['21.12899937', '52.15680675', 'Warszawa,02-991,Bruzdowa,38'],
    ['21.09912249', '52.18054718', 'Warszawa,02-987,Syta,177C'],
    ['21.10742952', '52.1660212', 'Warszawa,02-987,Syta,85C'],
    ['21.12428697', '52.16354543', 'Warszawa,02-992,Sążnista,8A'],
    ['21.13318132', '52.1556991', 'Warszawa,02-991,Bruzdowa,30U'],
    ['21.09903262', '52.18069906', 'Warszawa,02-987,Syta,177F'],
    ['21.11545996', '52.17335394', 'Warszawa,02-991,Bruzdowa,106E'],
    ['21.11330734', '52.16325155', 'Warszawa,02-993,Syta,80'],
    ['21.11265659', '52.17234477', 'Warszawa,02-991,Bruzdowa,125'],
    ['21.10727542', '52.17402041', 'Warszawa,02-987,Syta,125A'],
    ['21.11094234', '52.1774144', 'Warszawa,02-991,Bruzdowa,142E'],
    ['21.11560321', '52.17495102', 'Warszawa,02-991,Bruzdowa,116T'],
    ['21.11881066', '52.15972183', 'Warszawa,02-993,Syta,56A'],
    ['21.13255926', '52.15520369', 'Warszawa,02-991,Olęderska,10C'],
    ['21.13273173', '52.15513591', 'Warszawa,02-991,Olęderska,10B'],
    ['21.12404146', '52.16383179', 'Warszawa,02-992,Sążnista,8C'],
    ['21.11476524', '52.16870855', 'Warszawa,02-989,Zaściankowa,22'],
    ['21.10674263', '52.17252943', 'Warszawa,02-987,Syta,117C'],
    ['21.11160534', '52.16843476', 'Warszawa,02-991,Dostatnia,8'],
    ['21.11268342', '52.17488044', 'Warszawa,02-994,Bruzdowa,7A'],
    ['21.11605636', '52.17354791', 'Warszawa,02-991,Bruzdowa,106F'],
    ['21.11912975', '52.16247756', 'Warszawa,02-991,Sady Zawadowskie,13'],
    ['21.11948553', '52.15929155', 'Warszawa,02-993,Syta,52'],
    ['21.10931894', '52.17140227', 'Warszawa,02-987,Syta,124C'],
    ['21.10662507', '52.17486377', 'Warszawa,02-987,Syta,127C'],
    ['21.09873162', '52.18034251', 'Warszawa,02-987,Syta,177M'],
    ['21.10734941', '52.16892809', 'Warszawa,02-987,Syta,112G'],
    ['21.11782391', '52.16206489', 'Warszawa,02-991,Sady Zawadowskie,19'],
    ['21.12962121', '52.15432394', 'Warszawa,02-991,Bruzdowa,37A'],
    ['21.11465193', '52.17256808', 'Warszawa,02-991,Cicha Dolina,28'],
    ['21.114869', '52.175002', 'Warszawa,02-988,Jara,5R'],
    ['21.10871806', '52.17295867', 'Warszawa,02-987,Syta,132B'],
    ['21.11634878', '52.16300315', 'Warszawa,02-992,Lercha W.,14E'],
    ['21.11867219', '52.16505401', 'Warszawa,02-991,Bruzdowa,89A'],
    ['21.10694096', '52.16954425', 'Warszawa,02-987,Syta,116C'],
    ['21.10771574', '52.16732368', 'Warszawa,02-987,Syta,89'],
    ['21.12420593', '52.16365699', 'Warszawa,02-992,Sążnista,8B'],
    ['21.11921649', '52.16464338', 'Warszawa,02-991,Bruzdowa,89'],
    ['21.11278246', '52.1677357', 'Warszawa,02-989,Zaściankowa,17'],
    ['21.11760446', '52.16651449', 'Warszawa,02-991,Bruzdowa,93'],
    ['21.1149795', '52.17319478', 'Warszawa,02-991,Bruzdowa,106D'],
    ['21.11341403', '52.16936457', 'Warszawa,02-991,Bruzdowa,107S'],
    ['21.1111749', '52.16885242', 'Warszawa,02-991,Dostatnia,12'],
    ['21.10861173', '52.16969683', 'Warszawa,02-987,Syta,114U'],
    ['21.11556', '52.168984', 'Warszawa,02-991,Bruzdowa,101'],
    ['21.13020952', '52.16343302', 'Warszawa,02-986,Wał Zawadowski,95A'],
    ['21.10829505', '52.16959334', 'Warszawa,02-987,Syta,114O'],
    ['21.09838874', '52.18026471', 'Warszawa,02-987,Syta,177N'],
    ['21.1084131', '52.16903507', 'Warszawa,02-987,Syta,112J'],
    ['21.11147753', '52.1713742', 'Warszawa,02-991,Dostatnia,54'],
    ['21.10949062', '52.17000543', 'Warszawa,02-987,Syta,114Z'],
    ['21.11531968', '52.17183186', 'Warszawa,02-991,Bruzdowa,102C'],
    ['21.11159355', '52.17122986', 'Warszawa,02-991,Bruzdowa,119B'],
    ['21.12532289', '52.16392258', 'Warszawa,02-992,Sążnista,12'],
    ['21.11574553', '52.17392242', 'Warszawa,02-991,Bruzdowa,108F'],
    ['21.09703385', '52.17968847', 'Warszawa,02-987,Syta,175T'],
    ['21.10602825', '52.16482575', 'Warszawa,02-993,Nad Wilanówką,32'],
    ['21.11054416', '52.17080314', 'Warszawa,02-991,Bruzdowa,119M'],
    ['21.1299071', '52.16363458', 'Warszawa,02-986,Wał Zawadowski,95B'],
    ['21.11902229', '52.1638221', 'Warszawa,02-992,Lercha W.,14R'],
    ['21.10847813', '52.16830584', 'Warszawa,02-987,Syta,108D'],
    ['21.12005686', '52.17004599', 'Warszawa,02-989,Zaściankowa,3'],
    ['21.11492724', '52.16212815', 'Warszawa,02-993,Syta,72'],
    ['21.12466281', '52.16470269', 'Warszawa,02-992,Sążnista,12F'],
    ['21.10638194', '52.16517112', 'Warszawa,02-993,Nad Wilanówką,26'],
    ['21.11276711', '52.16809838', 'Warszawa,02-989,Zaściankowa,56'],
    ['21.12762932', '52.16513412', 'Warszawa,02-986,Wał Zawadowski,101'],
    ['21.09909239', '52.18059762', 'Warszawa,02-987,Syta,177D'],
    ['21.10703612', '52.16601204', 'Warszawa,02-987,Syta,85D'],
    ['21.11442958', '52.17270083', 'Warszawa,02-991,Cicha Dolina,24'],
    ['21.11135761', '52.1687113', 'Warszawa,02-991,Dostatnia,10'],
    ['21.11518443', '52.16879881', 'Warszawa,02-989,Zaściankowa,14'],
    ['21.10352472', '52.17888643', 'Warszawa,02-987,Syta,164'],
    ['21.10872334', '52.16956768', 'Warszawa,02-987,Syta,114S'],
    ['21.1155071', '52.1674319', 'Warszawa,02-991,Bruzdowa,97B'],
    ['21.11796698', '52.16815324', 'Warszawa,02-991,Bruzdowa,84E'],
    ['21.1133334', '52.17342944', 'Warszawa,02-991,Bruzdowa,112C'],
    ['21.1103934', '52.16927087', 'Warszawa,02-991,Dostatnia,17'],
    ['21.11120256', '52.17110158', 'Warszawa,02-991,Bruzdowa,119E'],
    ['21.12379475', '52.16412524', 'Warszawa,02-992,Sążnista,8E'],
    ['21.10762191', '52.16552621', 'Warszawa,02-993,Nad Wilanówką,7'],
    ['21.10795089', '52.16786076', 'Warszawa,02-987,Syta,108A'],
    ['21.11258431', '52.17211186', 'Warszawa,02-991,Bruzdowa,123'],
    ['21.11414162', '52.17339164', 'Warszawa,02-991,Bruzdowa,108C'],
    ['21.131417', '52.15504', 'Warszawa,02-991,Bruzdowa,32H/2'],
    ['21.07973821', '52.16555217', 'Warszawa,02-962,Kieślowskiego,3A'],
    ['21.07562869', '52.17641402', 'Warszawa,02-952,Wiertnicza,133'],
    ['21.07270025', '52.15608929', 'Warszawa,02-972,Branickiego A.,18'],
    ['21.06783056', '52.17238335', 'Warszawa,02-956,Rutkiewicz,9'],
    ['21.09058177', '52.15722299', 'Warszawa,02-967,Młocarni,37'],
    ['21.07390689', '52.16322779', 'Warszawa,02-972,Sarmacka,12G'],
    ['21.06979871', '52.1663013', 'Warszawa,02-972,Al. Rzeczypospolitej,20'],
    ['21.07946557', '52.15798151', 'Warszawa,02-972,Branickiego A.,10'],
    ['21.07752199', '52.15745123', 'Warszawa,02-972,Branickiego A.,12'],
    ['21.07622944', '52.15916686', 'Warszawa,02-972,Herbu Szreniawa,11'],
    ['21.07330134', '52.16420357', 'Warszawa,02-972,Sarmacka,10C'],
    ['21.06493327', '52.16110172', 'Warszawa,02-972,Hlonda,10F'],
    ['21.08280906', '52.16029711', 'Warszawa,02-999,Gieysztora A.,4'],
    ['21.07479837', '52.15550185', 'Warszawa,02-972,Branickiego A.,15'],
    ['21.07290132', '52.1745411', 'Warszawa,02-955,Łowcza,24'],
    ['21.06805127', '52.1665998', 'Warszawa,02-972,Al. Rzeczypospolitej,29A'],
    ['21.07658912', '52.1655959', 'Warszawa,02-972,Sarmacka,5'],
    ['21.08767259', '52.17141016', 'Warszawa,02-951,Rotmistrzowska,1'],
    ['21.08255967', '52.16957787', 'Warszawa,02-952,Wiertnicza,45A'],
    ['21.07291217', '52.17899637', 'Warszawa,02-952,Wiertnicza,166'],
    ['21.07561105', '52.17599274', 'Warszawa,02-952,Wiertnicza,129A'],
    ['21.07245844', '52.16415055', 'Warszawa,02-972,Sarmacka,10F'],
    ['21.07242181', '52.15485446', 'Warszawa,02-972,Branickiego A.,21'],
    ['21.08278082', '52.17493708', 'Warszawa,02-951,Piechoty Łanowej,51'],
    ['21.0753168', '52.16378689', 'Warszawa,02-972,Sarmacka,7A'],
    ['21.07134416', '52.15518517', 'Warszawa,02-972,Branickiego A.,20'],
    ['21.0770516', '52.16146842', 'Warszawa,02-972,Sarmacka,16'],
    ['21.07786034', '52.16066904', 'Warszawa,02-972,Sarmacka,18'],
    ['21.0754952', '52.16557187', 'Warszawa,02-972,Sarmacka,5G'],
    ['21.08040274', '52.15573723', 'Warszawa,02-972,Sarmacka,28B'],
    ['21.0774241', '52.16171808', 'Warszawa,02-972,Sarmacka,9'],
    ['21.07999125', '52.15917126', 'Warszawa,02-972,Sarmacka,15'],
    ['21.08139772', '52.1747199', 'Warszawa,02-951,Husarii,59'],
    ['21.08142776', '52.16880624', 'Warszawa,02-956,Rumiana,1E'],
    ['21.08039815', '52.16062133', 'Warszawa,02-999,Kazachska,3'],
    ['21.07011533', '52.160022', 'Warszawa,02-972,Hlonda,2'],
    ['21.06506458', '52.17564054', 'Warszawa,02-921,Niemirowska,1'],
    ['21.07092206', '52.16804465', 'Warszawa,02-972,Al. Rzeczypospolitej,22B'],
    ['21.07323221', '52.16037706', 'Warszawa,02-797,Klimczaka F.,17'],
    ['21.08246102', '52.16388319', 'Warszawa,02-797,Klimczaka F.,1'],
    ['21.0686787', '52.16025815', 'Warszawa,02-972,Hlonda,4A'],
    ['21.0728391', '52.16108079', 'Warszawa,02-797,Klimczaka F.,22'],
    ['21.0857458', '52.17296727', 'Warszawa,02-948,Kosiarzy,1B'],
    ['21.07244914', '52.1609491', 'Warszawa,02-797,Klimczaka F.,22A'],
    ['21.07345604', '52.15437088', 'Warszawa,02-972,Branickiego A.,21A'],
    ['21.07323217', '52.17805644', 'Warszawa,02-952,Wiertnicza,159'],
    ['21.07591017', '52.15972479', 'Warszawa,02-972,Abp. Teodorowicza J.,4'],
    ['21.07411144', '52.15469539', 'Warszawa,02-972,Branickiego A.,19A'],
    ['21.08042094', '52.16275758', 'Warszawa,02-789,Klimczaka F.,5'],
    ['21.07972726', '52.17284069', 'Warszawa,02-952,Wiertnicza,83'],
    ['21.08200984', '52.16126604', 'Warszawa,02-999,Gieysztora A.,2'],
    ['21.07894564', '52.15704525', 'Warszawa,02-972,Branickiego A.,11'],
    ['21.07177243', '52.17335937', 'Warszawa,02-954,Królowej Marysieńki,21'],
    ['21.0694992', '52.16734653', 'Warszawa,02-972,Al. Rzeczypospolitej,22'],
    ['21.08235872', '52.15646554', 'Warszawa,02-972,Sarmacka,21'],
    ['21.07870687', '52.15982844', 'Warszawa,02-972,Sarmacka,20'],
    ['21.07514574', '52.16695166', 'Warszawa,02-972,Sarmacka,1A'],
    ['21.06808784', '52.16560317', 'Warszawa,02-972,Al. Rzeczypospolitej,27A'],
    ['21.08930409', '52.16073362', 'Warszawa,02-958,Kostki-Potockiego S.,2B'],
    ['21.07102146', '52.16313508', 'Warszawa,02-972,Al. Rzeczypospolitej,12'],
    ['21.07957835', '52.15896197', 'Warszawa,02-972,Sarmacka,22'],
    ['21.07184379', '52.15728328', 'Warszawa,02-972,Sejmu Czteroletniego,2'],
    ['21.06785412', '52.15915526', 'Warszawa,02-972,Hlonda,8'],
    ['21.06435461', '52.17452495', 'Warszawa,02-957,Jana III Sobieskiego,16'],
    ['21.0897358', '52.1599409', 'Warszawa,02-963,Vogla Z.,8'],
    ['21.07654036', '52.1765076', 'Warszawa,02-952,Wiertnicza,128'],
    ['21.07562233', '52.15591386', 'Warszawa,02-972,Branickiego A.,13'],
    ['21.06581068', '52.16051045', 'Warszawa,02-972,Hlonda,10C'],
    ['21.07284233', '52.17344627', 'Warszawa,02-954,Królowej Marysieńki,48'],
    ['21.08664849', '52.17306178', 'Warszawa,02-951,Rotmistrzowska,20B'],
    ['21.07617976', '52.17162179', 'Warszawa,02-956,Królewicza Jakuba,21'],
    ['21.06882177', '52.15955311', 'Warszawa,02-972,Hlonda,6'],
    ['21.08071742', '52.15845262', 'Warszawa,02-972,Sarmacka,17'],
    ['21.08037951', '52.15746696', 'Warszawa,02-972,Branickiego A.,9'],
    ['21.07349077', '52.17532582', 'Warszawa,02-956,Królewicza Jakuba,64A'],
    ['21.08325297', '52.15438282', 'Warszawa,02-972,Al. Rzeczypospolitej,2'],
    ['21.07012936', '52.16773676', 'Warszawa,02-972,Al. Rzeczypospolitej,24A'],
    ['21.07366538', '52.15603589', 'Warszawa,02-972,Branickiego A.,16'],
    ['21.080067', '52.163025', 'Warszawa,02-797,Klimczaka F.,5B'],
    ['21.08599692', '52.17013668', 'Warszawa,02-948,Obornicka,13'],
    ['21.08760478', '52.16942073', 'Warszawa,02-960,Janczarów,17'],
    ['21.07014398', '52.16543154', 'Warszawa,02-972,Al. Rzeczypospolitej,18'],
    ['21.07490666', '52.16422254', 'Warszawa,02-972,Sarmacka,5K'],
    ['21.07980256', '52.16937769', 'Warszawa,02-954,Urodzajna,11'],
    ['21.07139917', '52.16207078', 'Warszawa,02-972,Al. Rzeczypospolitej,10'],
    ['21.06535483', '52.16033497', 'Warszawa,02-972,Hlonda,10D'],
    ['21.076164', '52.16665705', 'Warszawa,02-972,Sarmacka,1'],
    ['21.08172825', '52.15728387', 'Warszawa,02-972,Sarmacka,19'],
    ['21.08199835', '52.15886386', 'Warszawa,02-999,Kazachska,7'],
    ['21.07829352', '52.1608549', 'Warszawa,02-972,Sarmacka,11'],
    ['21.07430028', '52.16587435', 'Warszawa,02-972,Sarmacka,6'],
    ['21.09237743', '52.15639724', 'Warszawa,02-967,Uprawna,10'],
    ['21.07191569', '52.17210519', 'Warszawa,02-956,Jabłonowskiego J. S.,3'],
    ['21.07280178', '52.16588426', 'Warszawa,02-972,Oś Królewska,18C'],
    ['21.06713614', '52.16164735', 'Warszawa,02-972,Hlonda,4E'],
    ['21.08892365', '52.17424542', 'Warszawa,02-947,Łucznicza,12'],
    ['21.07627859', '52.15995681', 'Warszawa,02-972,Abp. Teodorowicza J.,3'],
    ['21.07616', '52.16314094', 'Warszawa,02-797,Klimczaka F.,10D'],
    ['21.06831531', '52.16061518', 'Warszawa,02-972,Hlonda,4B'],
    ['21.07301404', '52.17121875', 'Warszawa,02-954,Kubickiego J.,3'],
    ['21.06714889', '52.15888195', 'Warszawa,02-972,Hlonda,10'],
    ['21.07138572', '52.17035818', 'Warszawa,02-954,Wiktorii Wiedeńskiej,17'],
    ['21.08913496', '52.17036951', 'Warszawa,02-946,Biedronki,40'],
    ['21.07350911', '52.16377292', 'Warszawa,02-972,Sarmacka,10B'],
    ['21.08228752', '52.17121184', 'Warszawa,02-952,Wiertnicza,54'],
    ['21.07358146', '52.16723738', 'Warszawa,02-972,Sarmacka,4A'],
    ['21.08052386', '52.16843169', 'Warszawa,02-953,Obornicka,36A'],
    ['21.07482106', '52.16369014', 'Warszawa,02-972,Sarmacka,12'],
    ['21.06553526', '52.17511706', 'Warszawa,02-954,Goplańska,35'],
    ['21.06926665', '52.16410018', 'Warszawa,02-972,Al. Rzeczypospolitej,23'],
    ['21.07296041', '52.16998035', 'Warszawa,02-954,Marconich,5'],
    ['21.079903', '52.162887', 'Warszawa,02-797,Klimczaka F.,5A'],
    ['21.07003996', '52.17502511', 'Warszawa,02-954,Królowej Marysieńki,31'],
    ['21.07539263', '52.17343522', 'Warszawa,02-956,Królewicza Jakuba,40A'],
    ['21.08126603', '52.15971725', 'Warszawa,02-999,Kazachska,5'],
    ['21.07914102', '52.17101274', 'Warszawa,02-956,Rumiana,15'],
    ['21.07352209', '52.16137272', 'Warszawa,02-797,Klimczaka F.,20'],
    ['21.09250582', '52.15676501', 'Warszawa,02-967,Uprawna,Sep-17'],
    ['21.06705925', '52.15927239', 'Warszawa,02-972,Hlonda,10A'],
    ['21.0715252', '52.16964665', 'Warszawa,02-954,Wiktorii Wiedeńskiej,13'],
    ['21.08117973', '52.16904222', 'Warszawa,02-956,Rumiana,1C'],
    ['21.07476779', '52.16595501', 'Warszawa,02-972,Sarmacka,1B'],
    ['21.07546834', '52.17455272', 'Warszawa,02-956,Rumiana,61'],
    ['21.07760964', '52.15946541', 'Warszawa,02-972,Sarmacka,20A'],
    ['21.07230383', '52.16681415', 'Warszawa,02-972,Sarmacka,4B'],
    ['21.0709958', '52.15663713', 'Warszawa,02-972,Św. Ledóchowskiej U.,5F'],
    ['21.06889862', '52.16132472', 'Warszawa,02-972,Hlonda,2B'],
    ['21.08230948', '52.15710417', 'Warszawa,02-972,Sarmacka,19F'],
    ['21.08159772', '52.16421865', 'Warszawa,02-797,Klimczaka F.,4'],
    ['21.09224692', '52.15944337', 'Warszawa,02-965,Biwakowa,9A'],
    ['21.07986281', '52.17356041', 'Warszawa,02-952,Wiertnicza,84'],
    ['21.07113186', '52.16838035', 'Warszawa,02-972,Al. Rzeczypospolitej,22C'],
    ['21.09362278', '52.15825537', 'Warszawa,02-967,Uprawna,37A'],
    ['21.07781501', '52.16391559', 'Warszawa,02-797,Klimczaka F.,8A'],
    ['21.081334', '52.158439', 'Warszawa,02-972,Sarmacka,17D'],
    ['21.07188711', '52.17769616', 'Warszawa,02-956,Rumiana,109'],
    ['21.0808583', '52.16936022', 'Warszawa,02-956,Rumiana,1'],
    ['21.07434418', '52.16080052', 'Warszawa,02-797,Klimczaka F.,15'],
    ['21.0871648', '52.16791836', 'Warszawa,02-960,Kolegiacka,8'],
    ['21.07287769', '52.16342573', 'Warszawa,02-972,Sarmacka,10D'],
    ['21.06925855', '52.17696899', 'Warszawa,02-954,Goplańska,16A'],
    ['21.06595942', '52.1622614', 'Warszawa,02-972,Hlonda,4G'],
    ['21.06991025', '52.17621415', 'Warszawa,02-954,Królowej Marysieńki,96B'],
    ['21.0649771', '52.16196004', 'Warszawa,02-972,Hlonda,10K'],
    ['21.0726601', '52.17246642', 'Warszawa,02-954,Królowej Marysieńki,19'],
    ['21.07883429', '52.17251664', 'Warszawa,02-956,Rumiana,44A'],
    ['21.08084794', '52.16515293', 'Warszawa,02-962,Kieślowskiego,3D'],
    ['21.07465504', '52.16181143', 'Warszawa,02-797,Klimczaka F.,16'],
    ['21.0816325', '52.15399896', 'Warszawa,02-972,Al. Rzeczypospolitej,1'],
    ['21.08094397', '52.15350684', 'Warszawa,02-972,Herbu Janina,3A'],
    ['21.06671073', '52.16538189', 'Warszawa,02-972,Al. Rzeczypospolitej,27C'],
    ['21.07141598', '52.17096195', 'Warszawa,02-956,Lentza S.,35'],
    ['21.0778578', '52.17052867', 'Warszawa,02-956,Królewicza Jakuba,4'],
    ['21.07427966', '52.16409719', 'Warszawa,02-972,Sarmacka,10'],
    ['21.08374953', '52.15949505', 'Warszawa,02-999,Gieysztora A.,6'],
    ['21.08301976', '52.16965139', 'Warszawa,02-952,Wiertnicza,43'],
    ['21.0791869', '52.16230361', 'Warszawa,02-797,Klimczaka F.,7'],
    ['21.07834091', '52.16813179', 'Warszawa,02-954,Królowej Marysieńki,3D'],
    ['21.07295986', '52.17824427', 'Warszawa,02-952,Wiertnicza,163'],
    ['21.07540745', '52.17746412', 'Warszawa,02-952,Wiertnicza,142'],
    ['21.08616813', '52.1671434', 'Warszawa,02-958,Kostki-Potockiego S.,24B'],
    ['21.08007548', '52.16787696', 'Warszawa,02-953,Obornicka,29'],
    ['21.08083135', '52.15615227', 'Warszawa,02-972,Sarmacka,28A'],
    ['21.07265235', '52.17837533', 'Warszawa,02-952,Wiertnicza,165/167'],
    ['21.06652051', '52.17361904', 'Warszawa,02-956,Radosna,10A'],
    ['21.09556425', '52.15697678', 'Warszawa,02-964,Europejska,18D'],
    ['21.06825342', '52.16198142', 'Warszawa,02-972,Hlonda,2C'],
    ['21.07157652', '52.15453198', 'Warszawa,02-972,Branickiego A.,23'],
    ['21.06710833', '52.17562069', 'Warszawa,02-954,Goplańska,29'],
    ['21.08670347', '52.17455897', 'Warszawa,02-959,Biedronki,55'],
    ['21.07273049', '52.17447568', 'Warszawa,02-955,Łowcza,26'],
    ['21.0735637', '52.15525922', 'Warszawa,02-972,Branickiego A.,19'],
    ['21.07847752', '52.15867959', 'Warszawa,02-972,Sarmacka,22A'],
    ['21.07506401', '52.1695981', 'Warszawa,02-954,Wiktorii Wiedeńskiej,6'],
    ['21.06786645', '52.16865496', 'Warszawa,02-972,Al. Rzeczypospolitej,33'],
    ['21.06482262', '52.16157857', 'Warszawa,02-972,Hlonda,10H'],
    ['21.07799625', '52.1551772', 'Warszawa,02-972,Al. Rzeczypospolitej,5'],
    ['21.08043527', '52.17215579', 'Warszawa,02-952,Wiertnicza,73'],
    ['21.07650764', '52.16284799', 'Warszawa,02-797,Klimczaka F.,10C'],
    ['21.06749063', '52.16633637', 'Warszawa,02-972,Al. Rzeczypospolitej,29B'],
    ['21.07733651', '52.17515308', 'Warszawa,02-952,Wiertnicza,115'],
    ['21.06631422', '52.16104805', 'Warszawa,02-972,Hlonda,8C'],
    ['21.07538749', '52.16119345', 'Warszawa,02-797,Klimczaka F.,13'],
    ['21.06825376', '52.16787413', 'Warszawa,02-972,Al. Rzeczypospolitej,31'],
    ['21.0830376', '52.17311482', 'Warszawa,02-951,Husarii,35'],
    ['21.07079525', '52.17410921', 'Warszawa,02-954,Królowej Marysieńki,27C'],
    ['21.06741073', '52.16547727', 'Warszawa,02-972,Al. Rzeczypospolitej,27B'],
    ['21.07364921', '52.17774344', 'Warszawa,02-952,Wiertnicza,155'],
    ['21.07347903', '52.16285978', 'Warszawa,02-972,Sarmacka,12H'],
    ['21.07470047', '52.16701612', 'Warszawa,02-972,Sarmacka,4'],
    ['21.07275469', '52.16969067', 'Warszawa,02-954,Wiktorii Wiedeńskiej,9A'],
    ['21.06418935', '52.16205158', 'Warszawa,02-972,Hlonda,10G'],
    ['21.07470249', '52.15927755', 'Warszawa,02-972,Al. Rzeczypospolitej,8'],
    ['21.06891207', '52.17237088', 'Warszawa,02-956,Gubinowska,31'],
    ['21.06707346', '52.16738678', 'Warszawa,02-972,Al. Rzeczypospolitej,31B'],
    ['21.07248201', '52.16156368', 'Warszawa,02-797,Klimczaka F.,22F'],
    ['21.06878783', '52.16569474', 'Warszawa,02-972,Al. Rzeczypospolitej,27'],
    ['21.06631884', '52.16589335', 'Warszawa,02-972,Al. Rzeczypospolitej,29D'],
    ['21.08841059', '52.16110957', 'Warszawa,02-958,Kostki-Potockiego S.,3'],
    ['21.09221344', '52.15558374', 'Warszawa,02-967,Brodzik,6'],
    ['21.06820997', '52.16411161', 'Warszawa,02-972,Al. Rzeczypospolitej,23A'],
    ['21.0807069', '52.17189322', 'Warszawa,02-952,Wiertnicza,69A'],
    ['21.07004999', '52.15647748', 'Warszawa,02-972,Św. Ledóchowskiej U.,5B'],
    ['21.06864397', '52.16682328', 'Warszawa,02-972,Al. Rzeczypospolitej,29'],
    ['21.06608883', '52.16768217', 'Warszawa,02-972,Al. Rzeczypospolitej,33A'],
    ['21.08483318', '52.16892325', 'Warszawa,02-952,Wiertnicza,26'],
    ['21.07673311', '52.17637476', 'Warszawa,02-952,Wiertnicza,126'],
    ['21.07937479', '52.16151593', 'Warszawa,02-999,Kazachska,1'],
    ['21.07584146', '52.17243075', 'Warszawa,02-956,Królewicza Jakuba,28'],
    ['21.08169623', '52.17082822', 'Warszawa,02-952,Wiertnicza,59'],
    ['21.08878576', '52.1740264', 'Warszawa,02-947,Łucznicza,12A'],
    ['21.09222131', '52.15498079', 'Warszawa,02-967,Młocarni,2'],
    ['21.08028624', '52.17432248', 'Warszawa,02-949,Ostra,15'],
    ['21.06947745', '52.17169172', 'Warszawa,02-956,Gubinowska,7'],
    ['21.07546299', '52.17726171', 'Warszawa,02-952,Wiertnicza,140'],
    ['21.06858607', '52.17154718', 'Warszawa,02-956,Resorowa,16'],
    ['21.07920676', '52.17201825', 'Warszawa,02-956,Rumiana,38C'],
    ['21.07612567', '52.16857051', 'Warszawa,02-953,Kosiarzy,37'],
    ['21.07507665', '52.17441711', 'Warszawa,02-956,Rumiana,61A'],
    ['21.06618189', '52.17256137', 'Warszawa,02-957,Jana III Sobieskiego,10'],
    ['21.07554003', '52.16898151', 'Warszawa,02-954,Wiktorii Wiedeńskiej,1'],
    ['21.06490841', '52.17376322', 'Warszawa,02-957,Jana III Sobieskiego,14'],
    ['21.07504973', '52.16242694', 'Warszawa,02-797,Klimczaka F.,12B'],
    ['21.06985361', '52.16848298', 'Warszawa,02-972,Al. Rzeczypospolitej,24C'],
    ['21.07512067', '52.15655854', 'Warszawa,02-972,Branickiego A.,14'],
    ['21.06994152', '52.16810639', 'Warszawa,02-972,Al. Rzeczypospolitej,24B'],
    ['21.07920554', '52.16539542', 'Warszawa,02-962,Kieślowskiego,3'],
    ['21.08858757', '52.17027989', 'Warszawa,02-946,Biedronki,21'],
    ['21.07694572', '52.17623462', 'Warszawa,02-952,Wiertnicza,124'],
    ['21.07453486', '52.1632573', 'Warszawa,02-972,Sarmacka,12D'],
    ['21.07871797', '52.16823895', 'Warszawa,02-954,Królowej Marysieńki,3'],
    ['21.08982661', '52.16043581', 'Warszawa,02-958,Kostki-Potockiego S.,2'],
    ['21.07559508', '52.16348391', 'Warszawa,02-972,Sarmacka,7D'],
    ['21.06689067', '52.16048423', 'Warszawa,02-972,Hlonda,8B'],
    ['21.0799846', '52.17347595', 'Warszawa,02-952,Wiertnicza,82'],
    ['21.08316016', '52.17605118', 'Warszawa,02-946,Sielanki,27B'],
    ['21.06605643', '52.16688969', 'Warszawa,02-972,Al. Rzeczypospolitej,31D'],
    ['21.07485564', '52.15435743', 'Warszawa,02-972,Branickiego A.,17'],
    ['21.07519828', '52.16331825', 'Warszawa,02-972,Sarmacka,12B'],
    ['21.07255943', '52.16519189', 'Warszawa,02-972,Oś Królewska,18'],
    ['21.09118396', '52.15889384', 'Warszawa,02-966,Wyprawa,14'],
    ['21.08067171', '52.17005101', 'Warszawa,02-956,Rumiana,16'],
    ['21.08722274', '52.17247893', 'Warszawa,02-951,Rotmistrzowska,14'],
    ['21.087942', '52.163381', 'Warszawa,02-958,Kostki-Potockiego S.,1F'],
    ['21.07076583', '52.16408876', 'Warszawa,02-972,Al. Rzeczypospolitej,14'],
    ['21.08670546', '52.17143413', 'Warszawa,02-951,Piechoty Łanowej,6'],
    ['21.07469858', '52.1770514', 'Warszawa,02-952,Wiertnicza,143'],
    ['21.07844144', '52.1632175', 'Warszawa,02-797,Klimczaka F.,8'],
    ['21.07155021', '52.17669424', 'Warszawa,02-956,Królewicza Jakuba,82'],
    ['21.07047325', '52.15600239', 'Warszawa,02-972,Św. Ledóchowskiej U.,5'],
    ['21.06931837', '52.16062294', 'Warszawa,02-972,Hlonda,2A'],
    ['21.07550583', '52.16298331', 'Warszawa,02-972,Sarmacka,14C'],
    ['21.0881008', '52.15964019', 'Warszawa,02-963,Vogla Z.,2A'],
    ['21.08154435', '52.17353191', 'Warszawa,02-951,Chorągwi Pancernej,48'],
    ['21.07524103', '52.17477643', 'Warszawa,02-956,Rumiana,65'],
    ['21.07548645', '52.1765127', 'Warszawa,02-952,Wiertnicza,135'],
    ['21.06766676', '52.1676445', 'Warszawa,02-972,Al. Rzeczypospolitej,31A'],
    ['21.07023162', '52.16748388', 'Warszawa,02-972,Al. Rzeczypospolitej,22A'],
    ['21.06869495', '52.15992463', 'Warszawa,02-972,Hlonda,4'],
    ['21.07024732', '52.17585262', 'Warszawa,02-954,Królowej Marysieńki,90'],
    ['21.07198963', '52.17622462', 'Warszawa,02-956,Królewicza Jakuba,76'],
    ['21.08727762', '52.16319673', 'Warszawa,02-958,Kostki-Potockiego S.,19'],
    ['21.06883993', '52.17276365', 'Warszawa,02-956,Rutkiewicz,3'],
    ['21.07259465', '52.17792067', 'Warszawa,02-956,Rumiana,120B'],
    ['21.080163', '52.160102', 'Warszawa,02-972,Sarmacka,13J'],
    ['21.08338901', '52.17437869', 'Warszawa,02-951,Piechoty Łanowej,43'],
    ['21.08386788', '52.17225738', 'Warszawa,02-951,Husarii,23'],
    ['21.06919425', '52.16768381', 'Warszawa,02-972,Al. Rzeczypospolitej,24'],
    ['21.07745456', '52.17264534', 'Warszawa,02-956,Rumiana,35A'],
    ['21.0803317', '52.15364035', 'Warszawa,02-972,Herbu Janina,3'],
    ['21.09233738', '52.15317743', 'Warszawa,02-964,Europejska,125'],
    ['21.08389473', '52.16975426', 'Warszawa,02-952,Wiertnicza,32'],
    ['21.07363257', '52.17108398', 'Warszawa,02-954,Kubickiego J.,9'],
    ['21.07821995', '52.17352241', 'Warszawa,02-956,Lentza S.,3'],
    ['21.07507059', '52.17032855', 'Warszawa,02-954,Marconich,1'],
    ['21.06893341', '52.16839052', 'Warszawa,02-972,Al. Rzeczypospolitej,24E'],
    ['21.08081421', '52.17450812', 'Warszawa,02-949,Ostra,13'],
    ['21.06716758', '52.1720649', 'Warszawa,02-957,Jana III Sobieskiego,8'],
    ['21.08538824', '52.17555256', 'Warszawa,02-959,Biedronki,92A'],
    ['21.07921785', '52.17333509', 'Warszawa,02-952,Wiertnicza,89'],
    ['21.07341118', '52.17698911', 'Warszawa,02-956,Rumiana,108A'],
    ['21.0818926', '52.15635152', 'Warszawa,02-972,Sarmacka,28'],
    ['21.08447191', '52.17213212', 'Warszawa,02-951,Husarii,18'],
    ['21.07444151', '52.17016219', 'Warszawa,02-954,Marconich,3'],
    ['21.08858471', '52.1706585', 'Warszawa,02-946,Biedronki,23B'],
    ['21.07799101', '52.15378722', 'Warszawa,02-972,Herbu Janina,5'],
    ['21.082265', '52.17161392', 'Warszawa,02-953,Kosiarzy,9A'],
    ['21.07475545', '52.16309541', 'Warszawa,02-972,Sarmacka,12C'],
    ['21.06749204', '52.15989801', 'Warszawa,02-972,Hlonda,8A'],
    ['21.07664354', '52.16372619', 'Warszawa,02-972,Sarmacka,7F'],
    ['21.07906492', '52.16022457', 'Warszawa,02-972,Sarmacka,13'],
    ['21.08846045', '52.17445677', 'Warszawa,02-947,Łucznicza,3'],
    ['21.08060968', '52.16599394', 'Warszawa,02-962,Kieślowskiego,3E'],
    ['21.070288', '52.161447', 'Warszawa,02-972,Hlonda,2N'],
    ['21.07374663', '52.16413351', 'Warszawa,02-972,Sarmacka,10A'],
    ['21.06650817', '52.17417825', 'Warszawa,02-954,Pastewna,29'],
    ['21.06902543', '52.17596354', 'Warszawa,02-954,Królowej Marysieńki,33'],
    ['21.07947942', '52.17308064', 'Warszawa,02-952,Wiertnicza,85'],
    ['21.08380129', '52.17548113', 'Warszawa,02-959,Biedronki,73'],
    ['21.09215252', '52.15628872', 'Warszawa,02-967,Uprawna,8'],
    ['21.07232653', '52.17781759', 'Warszawa,02-956,Rumiana,120A'],
    ['21.07517365', '52.16392933', 'Warszawa,02-972,Sarmacka,7'],
    ['21.09268714', '52.15907355', 'Warszawa,02-967,Uprawna,41F'],
    ['21.07504796', '52.16539771', 'Warszawa,02-972,Sarmacka,5J'],
    ['21.08294951', '52.16971975', 'Warszawa,02-952,Wiertnicza,45'],
    ['21.07479092', '52.16914451', 'Warszawa,02-954,Wiktorii Wiedeńskiej,3'],
    ['21.07005446', '52.16255141', 'Warszawa,02-972,Al. Rzeczypospolitej,17'],
    ['21.09444665', '52.15834108', 'Warszawa,02-967,Uprawna,45'],
    ['21.07375648', '52.17081085', 'Warszawa,02-954,Kubickiego J.,11'],
    ['21.07093612', '52.17397262', 'Warszawa,02-954,Królowej Marysieńki,29B'],
    ['21.092814', '52.1600996', 'Warszawa,02-964,Europejska,4'],
    ['21.08238962', '52.16918853', 'Warszawa,02-952,Wiertnicza,39A'],
    ['21.07762514', '52.17022868', 'Warszawa,02-956,Królewicza Jakuba,1A'],
    ['21.07241724', '52.16382494', 'Warszawa,02-972,Sarmacka,10E'],
    ['21.08261823', '52.17200439', 'Warszawa,02-953,Kosiarzy,14'],
    ['21.08527673', '52.16993106', 'Warszawa,02-948,Obornicka,15'],
    ['21.07027635', '52.17771587', 'Warszawa,02-954,Goplańska,8B'],
    ['21.08405481', '52.176096', 'Warszawa,02-946,Sielanki,23'],
    ['21.0755972', '52.17496793', 'Warszawa,02-956,Rumiana,84'],
    ['21.06690438', '52.16611684', 'Warszawa,02-972,Al. Rzeczypospolitej,29C'],
    ['21.06806719', '52.17422582', 'Warszawa,02-956,Radosna,7'],
    ['21.07993231', '52.16514258', 'Warszawa,02-962,Kieślowskiego,3B'],
    ['21.08194307', '52.17599437', 'Warszawa,02-959,Biedronki,87'],
    ['21.0660303', '52.17392899', 'Warszawa,02-956,Resorowa,40'],
    ['21.07907142', '52.17546585', 'Warszawa,02-951,Chorągwi Pancernej,75'],
    ['21.08277063', '52.17382255', 'Warszawa,02-949,Petyhorska,19'],
    ['21.07049489', '52.17777806', 'Warszawa,02-954,Goplańska,8'],
    ['21.06908982', '52.1680016', 'Warszawa,02-972,Al. Rzeczypospolitej,24D'],
    ['21.07292903', '52.17375236', 'Warszawa,02-954,Królowej Marysieńki,50A'],
    ['21.07925793', '52.16957212', 'Warszawa,02-954,Urodzajna,12'],
    ['21.06788367', '52.17594958', 'Warszawa,02-954,Goplańska,25'],
    ['21.0790392', '52.17068261', 'Warszawa,02-953,Kosiarzy,24'],
    ['21.08593041', '52.17305721', 'Warszawa,02-948,Kosiarzy,1A'],
    ['21.07955537', '52.16824206', 'Warszawa,02-954,Królowej Marysieńki,2'],
    ['21.07162419', '52.17604802', 'Warszawa,02-956,Królewicza Jakuba,81'],
    ['21.08026034', '52.16460827', 'Warszawa,02-962,Kieślowskiego,5A'],
    ['21.0668772', '52.17430438', 'Warszawa,02-954,Pastewna,27B'],
    ['21.06749634', '52.16129401', 'Warszawa,02-972,Hlonda,4D'],
    ['21.08110286', '52.17236194', 'Warszawa,02-952,Wiertnicza,66'],
    ['21.07495041', '52.17502442', 'Warszawa,02-956,Rumiana,69'],
    ['21.08309433', '52.17407093', 'Warszawa,02-949,Petyhorska,12'],
    ['21.08052891', '52.17125356', 'Warszawa,02-953,Kosiarzy,20A'],
    ['21.08756896', '52.17037844', 'Warszawa,02-960,Janczarów,27'],
    ['21.07236487', '52.17507731', 'Warszawa,02-956,Królewicza Jakuba,69'],
    ['21.07226935', '52.16586528', 'Warszawa,02-972,Oś Królewska,18B'],
    ['21.07461262', '52.16364581', 'Warszawa,02-972,Sarmacka,12F'],
    ['21.0760133', '52.17685514', 'Warszawa,02-952,Wiertnicza,134A'],
    ['21.0791805', '52.16915297', 'Warszawa,02-954,Urodzajna,17'],
    ['21.06478835', '52.16019763', 'Warszawa,02-972,Hlonda,10E'],
    ['21.08151033', '52.1760087', 'Warszawa,02-959,Biedronki,89'],
    ['21.07245849', '52.17772993', 'Warszawa,02-956,Rumiana,120'],
    ['21.07028765', '52.15624209', 'Warszawa,02-972,Św. Ledóchowskiej U.,5A'],
    ['21.06616665', '52.16295201', 'Warszawa,02-972,Hlonda,4K'],
    ['21.07757683', '52.17305557', 'Warszawa,02-956,Rumiana,56'],
    ['21.08797368', '52.16224374', 'Warszawa,02-958,Kostki-Potockiego S.,11'],
    ['21.07786571', '52.17549256', 'Warszawa,02-952,Wiertnicza,112'],
    ['21.07709967', '52.17536813', 'Warszawa,02-952,Wiertnicza,117'],
    ['21.08283262', '52.17243905', 'Warszawa,02-951,Chorągwi Pancernej,32'],
    ['21.07079846', '52.15945385', 'Warszawa,02-972,Hlonda,1'],
    ['21.0778254', '52.17644613', 'Warszawa,02-950,Łowcza,2A'],
    ['21.08078867', '52.17266866', 'Warszawa,02-952,Wiertnicza,70'],
    ['21.08797976', '52.17280458', 'Warszawa,02-959,Biedronki,41'],
    ['21.08083131', '52.16855395', 'Warszawa,02-953,Obornicka,34A'],
    ['21.08977992', '52.15701558', 'Warszawa,02-962,Przyczółkowa,320'],
    ['21.07536799', '52.15837074', 'Warszawa,02-972,Al. Rzeczypospolitej,6'],
    ['21.07208088', '52.1712117', 'Warszawa,02-956,Lentza S.,35A'],
    ['21.07427908', '52.16945433', 'Warszawa,02-954,Wiktorii Wiedeńskiej,7'],
    ['21.07746456', '52.16285144', 'Warszawa,02-797,Klimczaka F.,10'],
    ['21.08789754', '52.15960898', 'Warszawa,02-963,Vogla Z.,2'],
    ['21.07616715', '52.1644004', 'Warszawa,02-972,Sarmacka,7J'],
    ['21.0665132', '52.15952305', 'Warszawa,02-972,Hlonda,10B'],
    ['21.08574487', '52.17043743', 'Warszawa,02-948,Obornicka,20'],
    ['21.08768783', '52.1711925', 'Warszawa,02-948,Obornicka,8'],
    ['21.0888997', '52.16740134', 'Warszawa,02-946,Biedronki,6'],
    ['21.08270969', '52.16455818', 'Warszawa,02-797,Klimczaka F.,2'],
    ['21.08268466', '52.17504988', 'Warszawa,02-951,Piechoty Łanowej,53'],
    ['21.09188586', '52.16066763', 'Warszawa,02-990,Vogla Z.,17'],
    ['21.07088587', '52.15618737', 'Warszawa,02-972,Św. Ledóchowskiej U.,5H'],
    ['21.0657183', '52.16263877', 'Warszawa,02-972,Hlonda,4J'],
    ['21.08735012', '52.17079616', 'Warszawa,02-948,Obornicka,5'],
    ['21.07751372', '52.17373164', 'Warszawa,02-956,Rumiana,62A'],
    ['21.07908694', '52.17346292', 'Warszawa,02-952,Wiertnicza,91'],
    ['21.08948708', '52.15750755', 'Warszawa,02-962,Przyczółkowa,324'],
    ['21.08675899', '52.16505285', 'Warszawa,02-958,Kostki-Potockiego S.,Oct-16'],
    ['21.0760757', '52.16242557', 'Warszawa,02-972,Sarmacka,14'],
    ['21.08103077', '52.17454514', 'Warszawa,02-949,Ostra,13C'],
    ['21.07593737', '52.17035378', 'Warszawa,02-954,Królowej Marysieńki,24B'],
    ['21.09131054', '52.16019095', 'Warszawa,02-963,Vogla Z.,28'],
    ['21.07780842', '52.17110592', 'Warszawa,02-956,Królewicza Jakuba,10A'],
    ['21.07393112', '52.16912484', 'Warszawa,02-954,Wiktorii Wiedeńskiej,5'],
    ['21.07581928', '52.16414509', 'Warszawa,02-972,Sarmacka,7C'],
    ['21.07316936', '52.17042898', 'Warszawa,02-954,Marconich,8'],
    ['21.08643234', '52.17130947', 'Warszawa,02-951,Piechoty Łanowej,7'],
    ['21.08032805', '52.16574662', 'Warszawa,02-962,Kieślowskiego,3C'],
    ['21.07667817', '52.1733999', 'Warszawa,02-956,Rumiana,43'],
    ['21.06868595', '52.17135517', 'Warszawa,02-956,Resorowa,12'],
    ['21.07815698', '52.1752298', 'Warszawa,02-952,Wiertnicza,108'],
    ['21.08002507', '52.17016487', 'Warszawa,02-956,Rumiana,5A'],
    ['21.07382452', '52.17248225', 'Warszawa,02-954,Królowej Marysieńki,34D'],
    ['21.07468892', '52.17159369', 'Warszawa,02-954,Królowej Marysieńki,28'],
    ['21.0703976', '52.16995339', 'Warszawa,02-954,Marconich,11'],
    ['21.0761116', '52.16542455', 'Warszawa,02-972,Sarmacka,5B'],
    ['21.06750761', '52.17130461', 'Warszawa,02-957,Jana III Sobieskiego,6'],
    ['21.08187555', '52.17348623', 'Warszawa,02-949,Petyhorska,25'],
    ['21.06659923', '52.17563544', 'Warszawa,02-954,Goplańska,31'],
    ['21.07492651', '52.17558662', 'Warszawa,02-956,Rumiana,92'],
    ['21.06516591', '52.16235296', 'Warszawa,02-972,Hlonda,10L'],
    ['21.06755901', '52.17426699', 'Warszawa,02-956,Radosna,4'],
    ['21.08138192', '52.17328027', 'Warszawa,02-951,Chorągwi Pancernej,43'],
    ['21.07306325', '52.17551439', 'Warszawa,02-956,Królewicza Jakuba,68/2'],
    ['21.07672696', '52.17111244', 'Warszawa,02-956,Królewicza Jakuba,13'],
    ['21.06896055', '52.17232503', 'Warszawa,02-956,Gubinowska,33'],
    ['21.06654216', '52.16717643', 'Warszawa,02-972,Al. Rzeczypospolitej,31C'],
    ['21.08638768', '52.16678733', 'Warszawa,02-958,Kostki-Potockiego S.,24'],
    ['21.07622973', '52.17733764', 'Warszawa,02-959,Biedronki,127'],
    ['21.07333205', '52.17706369', 'Warszawa,02-956,Rumiana,108'],
    ['21.0674533', '52.1759308', 'Warszawa,02-954,Goplańska,27'],
    ['21.07570336', '52.16385197', 'Warszawa,02-972,Sarmacka,7B'],
    ['21.07593806', '52.17186852', 'Warszawa,02-956,Królewicza Jakuba,25'],
    ['21.07767777', '52.17017708', 'Warszawa,02-956,Królewicza Jakuba,1'],
    ['21.06564468', '52.1617655', 'Warszawa,02-972,Hlonda,10J'],
    ['21.08863368', '52.15874768', 'Warszawa,02-962,Przyczółkowa,342'],
    ['21.07649938', '52.16919434', 'Warszawa,02-953,Kosiarzy,35'],
    ['21.09459532', '52.15812375', 'Warszawa,02-967,Uprawna,44'],
    ['21.09085766', '52.15873716', 'Warszawa,02-966,Wycieczka,26'],
    ['21.08170445', '52.16897591', 'Warszawa,02-956,Rumiana,4'],
    ['21.0870727', '52.16821907', 'Warszawa,02-960,Janczarów,5A'],
    ['21.07197955', '52.17423498', 'Warszawa,02-954,Królowej Marysieńki,58'],
    ['21.07107583', '52.15642125', 'Warszawa,02-972,Św. Ledóchowskiej U.,5G'],
    ['21.08053043', '52.16968005', 'Warszawa,02-956,Rumiana,3C'],
    ['21.08886502', '52.15823725', 'Warszawa,02-962,Przyczółkowa,334'],
    ['21.09508278', '52.15908846', 'Warszawa,02-964,Europejska,21'],
    ['21.07305016', '52.175696', 'Warszawa,02-956,Królewicza Jakuba,68D'],
    ['21.07737084', '52.16306277', 'Warszawa,02-797,Klimczaka F.,10G'],
    ['21.07267462', '52.1613423', 'Warszawa,02-797,Klimczaka F.,22G'],
    ['21.07779539', '52.17698541', 'Warszawa,02-959,Biedronki,115'],
    ['21.06679079', '52.1742709', 'Warszawa,02-954,Pastewna,27C'],
    ['21.07259778', '52.17363107', 'Warszawa,02-954,Królowej Marysieńki,50'],
    ['21.09021306', '52.15729219', 'Warszawa,02-967,Koralowa,36A'],
    ['21.07875', '52.162177', 'Warszawa,02-797,Klimczaka F.,7F'],
    ['21.07072699', '52.17213696', 'Warszawa,02-956,Gubinowska,4'],
    ['21.08372264', '52.16992116', 'Warszawa,02-952,Wiertnicza,36'],
    ['21.07229073', '52.17739367', 'Warszawa,02-956,Rumiana,105'],
    ['21.08326602', '52.1702666', 'Warszawa,02-952,Wiertnicza,40'],
    ['21.08538493', '52.17589818', 'Warszawa,02-946,Sielanki,15'],
    ['21.07931839', '52.16437305', 'Warszawa,02-962,Kieślowskiego,5'],
    ['21.077556', '52.172036', 'Warszawa,02-956,Rumiana,27A'],
    ['21.08480712', '52.1696955', 'Warszawa,02-948,Obornicka,19'],
    ['21.09512085', '52.15783731', 'Warszawa,02-967,Uprawna,44B'],
    ['21.09022598', '52.1564155', 'Warszawa,02-962,Przyczółkowa,310'],
    ['21.07106095', '52.17440454', 'Warszawa,02-954,Królowej Marysieńki,25C'],
    ['21.07910052', '52.16814776', 'Warszawa,02-954,Królowej Marysieńki,1'],
    ['21.07302594', '52.16549178', 'Warszawa,02-972,Oś Królewska,18A'],
    ['21.07569373', '52.1627998', 'Warszawa,02-972,Sarmacka,14B'],
    ['21.08179846', '52.15350686', 'Warszawa,02-972,Herbu Janina,3B'],
    ['21.07222395', '52.16105233', 'Warszawa,02-797,Klimczaka F.,22B'],
    ['21.06734451', '52.1758932', 'Warszawa,02-954,Goplańska,27A'],
    ['21.07934671', '52.17320968', 'Warszawa,02-952,Wiertnicza,87'],
    ['21.09151069', '52.15605982', 'Warszawa,02-967,Uprawna,3'],
    ['21.08290419', '52.17218795', 'Warszawa,02-951,Chorągwi Pancernej,30'],
    ['21.07584555', '52.17487225', 'Warszawa,02-956,Rumiana,82'],
    ['21.09539116', '52.15887434', 'Warszawa,02-964,Europejska,25'],
    ['21.06668543', '52.16219505', 'Warszawa,02-972,Hlonda,4F'],
    ['21.07444239', '52.17331104', 'Warszawa,02-956,Królewicza Jakuba,43'],
    ['21.07807699', '52.15838966', 'Warszawa,02-972,Abp. Teodorowicza J.,7'],
    ['21.08374903', '52.17161513', 'Warszawa,02-951,Chorągwi Pancernej,20'],
    ['21.09192604', '52.15402026', 'Warszawa,02-964,Europejska,127'],
    ['21.08332219', '52.17325819', 'Warszawa,02-951,Husarii,36'],
    ['21.06773165', '52.17369924', 'Warszawa,02-956,Radosna,11'],
    ['21.08833685', '52.17461125', 'Warszawa,02-947,Łucznicza,3A'],
    ['21.08514735', '52.17470182', 'Warszawa,02-951,Rotmistrzowska,42'],
    ['21.07476696', '52.17527219', 'Warszawa,02-956,Rumiana,71'],
    ['21.07515412', '52.17636242', 'Warszawa,02-952,Wiertnicza,135A'],
    ['21.07597918', '52.17294556', 'Warszawa,02-956,Lentza S.,6'],
    ['21.07828866', '52.16919187', 'Warszawa,02-954,Urodzajna,22'],
    ['21.08481448', '52.17551088', 'Warszawa,02-959,Biedronki,94'],
    ['21.08093159', '52.17451844', 'Warszawa,02-949,Ostra,13B'],
    ['21.07555163', '52.16934376', 'Warszawa,02-954,Wiktorii Wiedeńskiej,2'],
    ['21.09069858', '52.15617345', 'Warszawa,02-797,Koralowa,1'],
    ['21.08362339', '52.17254452', 'Warszawa,02-951,Husarii,27'],
    ['21.07969785', '52.1697552', 'Warszawa,02-954,Urodzajna,6A'],
    ['21.08823636', '52.17303824', 'Warszawa,02-959,Biedronki,68'],
    ['21.07262199', '52.17764661', 'Warszawa,02-956,Rumiana,118'],
    ['21.07674818', '52.1625763', 'Warszawa,02-797,Klimczaka F.,10B'],
    ['21.07502644', '52.16348741', 'Warszawa,02-972,Sarmacka,12A'],
    ['21.07517478', '52.17672873', 'Warszawa,02-952,Wiertnicza,139'],
    ['21.07792788', '52.17648558', 'Warszawa,02-950,Łowcza,2'],
    ['21.07778966', '52.16612956', 'Warszawa,02-954,Al. Wilanowska,7A'],
    ['21.09059873', '52.15892384', 'Warszawa,02-967,Młocarni,30'],
    ['21.09705464', '52.15768425', 'Warszawa,02-964,Europejska,49'],
    ['21.07537998', '52.17294755', 'Warszawa,02-956,Królewicza Jakuba,34'],
    ['21.08028932', '52.17229083', 'Warszawa,02-952,Wiertnicza,75'],
    ['21.08655289', '52.16750322', 'Warszawa,02-960,Kolegiacka,3'],
    ['21.0771385', '52.16329788', 'Warszawa,02-797,Klimczaka F.,10F'],
    ['21.08598605', '52.17553706', 'Warszawa,02-946,Sielanki,9A'],
    ['21.08458616', '52.17505169', 'Warszawa,02-959,Biedronki,63'],
    ['21.07428523', '52.17509198', 'Warszawa,02-955,Łowcza,14'],
    ['21.09603621', '52.15795943', 'Warszawa,02-967,Uprawna,48C'],
    ['21.08176857', '52.16891092', 'Warszawa,02-956,Rumiana,2'],
    ['21.07965947', '52.17057036', 'Warszawa,02-956,Rumiana,7A'],
    ['21.07530528', '52.17472898', 'Warszawa,02-956,Rumiana,63A'],
    ['21.0781893', '52.16807435', 'Warszawa,02-954,Królowej Marysieńki,3F'],
    ['21.08305305', '52.17045588', 'Warszawa,02-952,Wiertnicza,44'],
    ['21.08606736', '52.16911768', 'Warszawa,02-952,Wiertnicza,26A'],
    ['21.09214768', '52.15534859', 'Warszawa,02-967,Brodzik,3'],
    ['21.07907896', '52.17431453', 'Warszawa,02-952,Wiertnicza,94'],
    ['21.08422992', '52.16929689', 'Warszawa,02-952,Wiertnicza,30'],
    ['21.07774847', '52.16985326', 'Warszawa,02-953,Kosiarzy,29B'],
    ['21.07844784', '52.17408713', 'Warszawa,02-952,Wiertnicza,99'],
    ['21.07520868', '52.17570109', 'Warszawa,02-956,Rumiana,92A'],
    ['21.08871785', '52.17039378', 'Warszawa,02-946,Biedronki,23'],
    ['21.08833813', '52.1728649', 'Warszawa,02-959,Biedronki,66'],
    ['21.07797012', '52.16798057', 'Warszawa,02-954,Królowej Marysieńki,3I'],
    ['21.07580261', '52.17567165', 'Warszawa,02-950,Łowcza,10'],
    ['21.06801666', '52.1726984', 'Warszawa,02-956,Rutkiewicz,2'],
    ['21.070436', '52.161265', 'Warszawa,02-972,Hlonda,2M'],
    ['21.07334742', '52.16952676', 'Warszawa,02-954,Wiktorii Wiedeńskiej,9'],
    ['21.07111102', '52.17654958', 'Warszawa,02-956,Królewicza Jakuba,89'],
    ['21.08507717', '52.17430958', 'Warszawa,02-951,Rotmistrzowska,39'],
    ['21.06705107', '52.17438876', 'Warszawa,02-954,Pastewna,25'],
    ['21.07391686', '52.17236866', 'Warszawa,02-954,Królowej Marysieńki,34B'],
    ['21.07682199', '52.17150055', 'Warszawa,02-956,Królewicza Jakuba,14A'],
    ['21.08488316', '52.17325844', 'Warszawa,02-951,Piechoty Łanowej,30'],
    ['21.09339194', '52.15869275', 'Warszawa,02-967,Uprawna,41B'],
    ['21.07283617', '52.17742087', 'Warszawa,02-956,Rumiana,114'],
    ['21.06886818', '52.17241256', 'Warszawa,02-956,Gubinowska,29'],
    ['21.09314868', '52.15956182', 'Warszawa,02-965,Biwakowa,4'],
    ['21.09400521', '52.15802377', 'Warszawa,02-967,Uprawna,37'],
    ['21.08083914', '52.17109258', 'Warszawa,02-953,Kosiarzy,11'],
    ['21.07918962', '52.17422702', 'Warszawa,02-952,Wiertnicza,92'],
    ['21.06610584', '52.17548346', 'Warszawa,02-954,Goplańska,31A'],
    ['21.06585323', '52.17330761', 'Warszawa,02-957,Jana III Sobieskiego,12'],
    ['21.08764773', '52.16983488', 'Warszawa,02-960,Janczarów,21'],
    ['21.07186759', '52.16141101', 'Warszawa,02-797,Klimczaka F.,22D'],
    ['21.08912099', '52.1600914', 'Warszawa,02-990,Vogla Z.,11'],
    ['21.07456316', '52.17082669', 'Warszawa,02-954,Królowej Marysieńki,9'],
    ['21.07806669', '52.169965', 'Warszawa,02-953,Kosiarzy,27'],
    ['21.07094497', '52.17799546', 'Warszawa,02-954,Goplańska,6A'],
    ['21.07703471', '52.16860906', 'Warszawa,02-954,Królowej Marysieńki,7E'],
    ['21.09405371', '52.15588042', 'Warszawa,02-964,Europejska,30D'],
    ['21.07669337', '52.169761', 'Warszawa,02-953,Kosiarzy,32'],
    ['21.08960283', '52.16118131', 'Warszawa,02-958,Kostki-Potockiego S.,2D'],
    ['21.09065065', '52.15669318', 'Warszawa,02-967,Koralowa,7'],
    ['21.07362199', '52.1784879', 'Warszawa,02-952,Wiertnicza,160'],
    ['21.07448249', '52.17210454', 'Warszawa,02-956,Lentza S.,21'],
    ['21.08760473', '52.16928563', 'Warszawa,02-960,Janczarów,15'],
    ['21.08795788', '52.16940461', 'Warszawa,02-960,Janczarów,16'],
    ['21.09691208', '52.15754431', 'Warszawa,02-964,Europejska,51'],
    ['21.07468921', '52.17262221', 'Warszawa,02-956,Lentza S.,10'],
    ['21.07134442', '52.17413025', 'Warszawa,02-954,Królowej Marysieńki,23A'],
    ['21.09626143', '52.15783724', 'Warszawa,02-967,Uprawna,48D'],
    ['21.08218943', '52.17507463', 'Warszawa,02-949,Ostra,7'],
    ['21.08245654', '52.17368598', 'Warszawa,02-949,Petyhorska,21'],
    ['21.09355481', '52.1591899', 'Warszawa,02-965,Biwakowa,7'],
    ['21.07551672', '52.1621185', 'Warszawa,02-797,Klimczaka F.,12'],
    ['21.08823308', '52.16140164', 'Warszawa,02-958,Kostki-Potockiego S.,5'],
    ['21.06801938', '52.17427176', 'Warszawa,02-954,Radosna,03-May'],
    ['21.08216399', '52.1730206', 'Warszawa,02-951,Chorągwi Pancernej,40'],
    ['21.077062', '52.17355221', 'Warszawa,02-956,Rumiana,62'],
    ['21.093069', '52.158229', 'Warszawa,02-967,Uprawna,33'],
    ['21.09076667', '52.15637244', 'Warszawa,02-967,Koralowa,3'],
    ['21.09327023', '52.15540451', 'Warszawa,02-964,Europejska,36'],
    ['21.08170732', '52.17174957', 'Warszawa,02-952,Wiertnicza,58'],
    ['21.08471983', '52.1734229', 'Warszawa,02-951,Piechoty Łanowej,32'],
    ['21.08900145', '52.16006892', 'Warszawa,02-963,Vogla Z.,9'],
    ['21.08236873', '52.17544948', 'Warszawa,02-949,Ostra,8'],
    ['21.08595953', '52.1642171', 'Warszawa,02-958,Kostki-Potockiego S.,21'],
    ['21.07847152', '52.17054696', 'Warszawa,02-953,Kosiarzy,26A'],
    ['21.0827809', '52.17073799', 'Warszawa,02-952,Wiertnicza,46'],
    ['21.06772649', '52.1723408', 'Warszawa,02-956,Rutkiewicz,11'],
    ['21.08487622', '52.17176601', 'Warszawa,02-951,Husarii,16'],
    ['21.08488526', '52.17444462', 'Warszawa,02-951,Rotmistrzowska,41'],
    ['21.07307416', '52.17460741', 'Warszawa,02-955,Łowcza,22'],
    ['21.07022951', '52.15662522', 'Warszawa,02-972,Św. Ledóchowskiej U.,5C'],
    ['21.07798416', '52.17212977', 'Warszawa,02-956,Rumiana,27'],
    ['21.09067064', '52.16010907', 'Warszawa,02-963,Vogla Z.,20'],
    ['21.08172657', '52.16945348', 'Warszawa,02-956,Rumiana,10A'],
    ['21.0910708', '52.15823628', 'Warszawa,02-966,Niedzielna,34'],
    ['21.0713461', '52.17486774', 'Warszawa,02-954,Królowej Marysieńki,70'],
    ['21.07088891', '52.17527337', 'Warszawa,02-954,Królowej Marysieńki,80'],
    ['21.08123119', '52.1711958', 'Warszawa,02-952,Wiertnicza,63B'],
    ['21.07156404', '52.17610926', 'Warszawa,02-956,Królewicza Jakuba,81A'],
    ['21.09376192', '52.15709405', 'Warszawa,02-967,Uprawna,26'],
    ['21.06643538', '52.16257501', 'Warszawa,02-972,Hlonda,4H'],
    ['21.07859013', '52.17018556', 'Warszawa,02-953,Kosiarzy,21C'],
    ['21.07503275', '52.16053731', 'Warszawa,02-972,Abp. Teodorowicza J.,2'],
    ['21.08912769', '52.15912255', 'Warszawa,02-967,Koralowa,70'],
    ['21.07941582', '52.16858527', 'Warszawa,02-954,Królowej Marysieńki,4A'],
    ['21.06920207', '52.15634723', 'Warszawa,02-972,Św. Ledóchowskiej U.,10'],
    ['21.07629913', '52.17590673', 'Warszawa,02-952,Wiertnicza,125'],
    ['21.08498581', '52.16644993', 'Warszawa,02-962,Przyczółkowa,400'],
    ['21.07588164', '52.16261325', 'Warszawa,02-972,Sarmacka,14A'],
    ['21.07205117', '52.16123395', 'Warszawa,02-797,Klimczaka F.,22C'],
    ['21.0752365', '52.16225382', 'Warszawa,02-797,Klimczaka F.,12A'],
    ['21.08641635', '52.17040686', 'Warszawa,02-948,Obornicka,11'],
    ['21.07668092', '52.16426961', 'Warszawa,02-972,Sarmacka,7H'],
    ['21.09249112', '52.15630914', 'Warszawa,02-967,Uprawna,10A'],
    ['21.07921135', '52.17669993', 'Warszawa,02-959,Biedronki,105'],
    ['21.08636244', '52.17336599', 'Warszawa,02-951,Rotmistrzowska,24A'],
    ['21.08480777', '52.1745852', 'Warszawa,02-951,Rotmistrzowska,43'],
    ['21.08834799', '52.15998923', 'Warszawa,02-963,Vogla Z.,5'],
    ['21.08104255', '52.16463582', 'Warszawa,02-962,Kieślowskiego,5B'],
    ['21.08867056', '52.17290934', 'Warszawa,02-959,Biedronki,66A'],
    ['21.07739996', '52.1709549', 'Warszawa,02-956,Królewicza Jakuba,10'],
    ['21.08381831', '52.17530665', 'Warszawa,02-959,Biedronki,71A'],
    ['21.08242222', '52.17170673', 'Warszawa,02-953,Kosiarzy,9'],
    ['21.07175105', '52.17593041', 'Warszawa,02-956,Królewicza Jakuba,79'],
    ['21.07973926', '52.17533024', 'Warszawa,02-951,Chorągwi Pancernej,72'],
    ['21.06831977', '52.17238979', 'Warszawa,02-956,Gubinowska,41'],
    ['21.09247016', '52.15578732', 'Warszawa,02-964,Europejska,36D'],
    ['21.08503798', '52.16720518', 'Warszawa,02-958,Kostki-Potockiego S.,31'],
    ['21.09385846', '52.15714458', 'Warszawa,02-967,Uprawna,28'],
    ['21.08556431', '52.17058117', 'Warszawa,02-951,Husarii,5'],
    ['21.07012382', '52.17228554', 'Warszawa,02-956,Gubinowska,6'],
    ['21.06881616', '52.1724617', 'Warszawa,02-956,Gubinowska,27'],
    ['21.06897388', '52.15751497', 'Warszawa,02-972,Św. Ledóchowskiej U.,3'],
    ['21.08193874', '52.17493045', 'Warszawa,02-951,Husarii,60A'],
    ['21.08912914', '52.16856768', 'Warszawa,02-946,Biedronki,20'],
    ['21.06968485', '52.17649357', 'Warszawa,02-954,Królowej Marysieńki,104'],
    ['21.079163', '52.160156', 'Warszawa,02-972,Sarmacka,13E'],
    ['21.08796158', '52.16802561', 'Warszawa,02-960,Janczarów,2B'],
    ['21.07052644', '52.15674212', 'Warszawa,02-972,Św. Ledóchowskiej U.,5D'],
    ['21.09536457', '52.15678492', 'Warszawa,02-964,Europejska,18A'],
    ['21.0678297', '52.16093229', 'Warszawa,02-972,Hlonda,4C'],
    ['21.08459482', '52.17355196', 'Warszawa,02-951,Piechoty Łanowej,34'],
    ['21.080042', '52.162755', 'Warszawa,02-797,Klimczaka F.,5I'],
    ['21.08417393', '52.17355406', 'Warszawa,02-951,Piechoty Łanowej,35'],
    ['21.07311606', '52.17324166', 'Warszawa,02-954,Królowej Marysieńki,46'],
    ['21.09134672', '52.15508134', 'Warszawa,02-962,Przyczółkowa,298'],
    ['21.0757597', '52.17421227', 'Warszawa,02-956,Rumiana,55/57'],
    ['21.08372603', '52.16884082', 'Warszawa,02-952,Wiertnicza,35'],
    ['21.07208801', '52.1775928', 'Warszawa,02-956,Rumiana,107'],
    ['21.08076002', '52.17393051', 'Warszawa,02-951,Chorągwi Pancernej,53'],
    ['21.08047158', '52.17466262', 'Warszawa,02-949,Ostra,20'],
    ['21.0800424', '52.17077769', 'Warszawa,02-953,Kosiarzy,15'],
    ['21.06997388', '52.17031223', 'Warszawa,02-954,Kubickiego J.,21'],
    ['21.07848474', '52.17614922', 'Warszawa,02-951,Chorągwi Pancernej,85'],
    ['21.07783138', '52.17279862', 'Warszawa,02-956,Rumiana,52'],
    ['21.087261', '52.166637', 'Warszawa,02-946,Biedronki,1'],
    ['21.08043248', '52.16839448', 'Warszawa,02-953,Obornicka,36'],
    ['21.07420401', '52.17534273', 'Warszawa,02-956,Rumiana,75A'],
    ['21.06369197', '52.17488894', 'Warszawa,02-957,Jana III Sobieskiego,18'],
    ['21.07854729', '52.1704715', 'Warszawa,02-953,Kosiarzy,26'],
    ['21.07663759', '52.17741797', 'Warszawa,02-959,Biedronki,158'],
    ['21.08336991', '52.17614393', 'Warszawa,02-946,Sielanki,27'],
    ['21.07407063', '52.17367817', 'Warszawa,02-956,Królewicza Jakuba,49'],
    ['21.06950148', '52.1728176', 'Warszawa,02-956,Gubinowska,22'],
    ['21.07105133', '52.17618336', 'Warszawa,02-956,Królewicza Jakuba,83A'],
    ['21.07739181', '52.16892315', 'Warszawa,02-954,Królowej Marysieńki,14'],
    ['21.0939064', '52.15582064', 'Warszawa,02-964,Europejska,32'],
    ['21.09303969', '52.15750273', 'Warszawa,02-967,Uprawna,25'],
    ['21.07842566', '52.17500475', 'Warszawa,02-952,Wiertnicza,104'],
    ['21.09285836', '52.15919173', 'Warszawa,02-967,Uprawna,41E'],
    ['21.07082333', '52.15685448', 'Warszawa,02-972,Św. Ledóchowskiej U.,5E'],
    ['21.079707', '52.160632', 'Warszawa,02-972,Sarmacka,13H'],
    ['21.09014045', '52.1611318', 'Warszawa,02-958,Kostki-Potockiego S.,2F'],
    ['21.07693515', '52.16952902', 'Warszawa,02-953,Kosiarzy,33'],
    ['21.07771417', '52.17474768', 'Warszawa,02-952,Wiertnicza,109'],
    ['21.07222886', '52.17405151', 'Warszawa,02-955,Łowcza,17'],
    ['21.081061', '52.159239', 'Warszawa,02-972,Sarmacka,15J'],
    ['21.07536043', '52.17095204', 'Warszawa,02-954,Królowej Marysieńki,24H'],
    ['21.0759234', '52.17621279', 'Warszawa,02-952,Wiertnicza,129'],
    ['21.09418845', '52.15818083', 'Warszawa,02-967,Uprawna,41'],
    ['21.07213658', '52.16154133', 'Warszawa,02-797,Klimczaka F.,22E'],
    ['21.07605978', '52.17680848', 'Warszawa,02-952,Wiertnicza,134'],
    ['21.07388459', '52.17601481', 'Warszawa,02-956,Rumiana,85'],
    ['21.08610691', '52.17514308', 'Warszawa,02-959,Biedronki,84A'],
    ['21.08083763', '52.17572626', 'Warszawa,02-951,Husarii,74'],
    ['21.07810875', '52.17315548', 'Warszawa,02-956,Rumiana,54A'],
    ['21.07416253', '52.17034335', 'Warszawa,02-954,Marconich,2'],
    ['21.07422438', '52.17736573', 'Warszawa,02-952,Wiertnicza,147'],
    ['21.07243732', '52.17025596', 'Warszawa,02-954,Marconich,7'],
    ['21.07881152', '52.17186539', 'Warszawa,02-956,Rumiana,38'],
    ['21.06358472', '52.17516022', 'Warszawa,02-957,Jana III Sobieskiego,18A'],
    ['21.07340102', '52.17479487', 'Warszawa,02-956,Królewicza Jakuba,58'],
    ['21.07905877', '52.17448966', 'Warszawa,02-952,Wiertnicza,96'],
    ['21.06772261', '52.17464977', 'Warszawa,02-954,Pastewna,21'],
    ['21.07799597', '52.17392797', 'Warszawa,02-952,Wiertnicza,99A'],
    ['21.0871907', '52.16047338', 'Warszawa,02-962,Przyczółkowa,360'],
    ['21.07420304', '52.17119117', 'Warszawa,02-954,Królowej Marysieńki,13'],
    ['21.06932849', '52.17258161', 'Warszawa,02-956,Gubinowska,15'],
    ['21.08083239', '52.1699062', 'Warszawa,02-956,Rumiana,14A'],
    ['21.08709986', '52.17063862', 'Warszawa,02-948,Obornicka,7'],
    ['21.07435118', '52.1768938', 'Warszawa,02-952,Wiertnicza,143A'],
    ['21.07603427', '52.17026453', 'Warszawa,02-954,Królowej Marysieńki,24'],
    ['21.09354651', '52.15572715', 'Warszawa,02-964,Europejska,32B'],
    ['21.0840358', '52.17258641', 'Warszawa,02-951,Husarii,22'],
    ['21.09203597', '52.1582116', 'Warszawa,02-966,Wyprawa,7'],
    ['21.08948336', '52.16043479', 'Warszawa,02-958,Kostki-Potockiego S.,2A'],
    ['21.0791129', '52.1699634', 'Warszawa,02-953,Kosiarzy,21D'],
    ['21.08255152', '52.17255955', 'Warszawa,02-951,Chorągwi Pancernej,34'],
    ['21.07498567', '52.17130701', 'Warszawa,02-954,Królowej Marysieńki,26C'],
    ['21.09302496', '52.16001304', 'Warszawa,02-964,Europejska,6'],
    ['21.08255839', '52.17215031', 'Warszawa,02-951,Chorągwi Pancernej,27'],
    ['21.08434688', '52.17224885', 'Warszawa,02-951,Husarii,20'],
    ['21.0824359', '52.17111883', 'Warszawa,02-952,Wiertnicza,52A'],
    ['21.08383755', '52.16980738', 'Warszawa,02-952,Wiertnicza,34'],
    ['21.09216426', '52.15561164', 'Warszawa,02-967,Brodzik,8'],
    ['21.0803427', '52.16368838', 'Warszawa,02-797,Klimczaka F.,6'],
    ['21.08896532', '52.17196915', 'Warszawa,02-959,Biedronki,56B'],
    ['21.08555403', '52.17209196', 'Warszawa,02-951,Piechoty Łanowej,15B'],
    ['21.06892771', '52.17112571', 'Warszawa,02-956,Resorowa,4'],
    ['21.07087065', '52.17313874', 'Warszawa,02-956,Jabłonowskiego J. S.,19'],
    ['21.07255555', '52.17415911', 'Warszawa,02-954,Łowcza,17A'],
    ['21.08272047', '52.17079408', 'Warszawa,02-952,Wiertnicza,48'],
    ['21.0769172', '52.17369087', 'Warszawa,02-956,Rumiana,64'],
    ['21.0766298', '52.17397948', 'Warszawa,02-956,Rumiana,68'],
    ['21.08330459', '52.169141', 'Warszawa,02-953,Obornicka,21'],
    ['21.0799', '52.16423636', 'Warszawa,02-962,Kieślowskiego,5E'],
    ['21.0793359', '52.17605179', 'Warszawa,02-951,Chorągwi Pancernej,82A'],
    ['21.08200131', '52.17310247', 'Warszawa,02-951,Chorągwi Pancernej,42'],
    ['21.09200421', '52.15436688', 'Warszawa,02-964,Europejska,50'],
    ['21.07455764', '52.17495627', 'Warszawa,02-955,Łowcza,11B'],
    ['21.08511197', '52.17309028', 'Warszawa,02-951,Piechoty Łanowej,28'],
    ['21.09450637', '52.1584008', 'Warszawa,02-967,Uprawna,47'],
    ['21.09663512', '52.15733847', 'Warszawa,02-964,Europejska,53'],
    ['21.08102155', '52.17422054', 'Warszawa,02-951,Chorągwi Pancernej,56'],
    ['21.09333413', '52.15504895', 'Warszawa,02-964,Europejska,109'],
    ['21.08734181', '52.17445313', 'Warszawa,02-959,Biedronki,76A'],
    ['21.06828592', '52.17177074', 'Warszawa,02-956,Resorowa,20'],
    ['21.08209407', '52.17405717', 'Warszawa,02-951,Husarii,49'],
    ['21.0775574', '52.16567018', 'Warszawa,02-797,Al. Wilanowska,9A'],
    ['21.07628662', '52.1731979', 'Warszawa,02-956,Lentza S.,4A'],
    ['21.0829887', '52.17316163', 'Warszawa,02-951,Husarii,35A'],
    ['21.07097811', '52.17563547', 'Warszawa,02-954,Królowej Marysieńki,82A'],
    ['21.07841001', '52.17343989', 'Warszawa,02-956,Lentza S.,3A'],
    ['21.06971647', '52.17689322', 'Warszawa,02-954,Goplańska,17A'],
    ['21.06870715', '52.17378751', 'Warszawa,02-956,Gubinowska,28/30'],
    ['21.07717355', '52.17344731', 'Warszawa,02-956,Rumiana,60'],
    ['21.08781557', '52.16770278', 'Warszawa,02-960,Kolegiacka,1'],
    ['21.07546426', '52.17613137', 'Warszawa,02-952,Wiertnicza,131'],
    ['21.09283115', '52.15471443', 'Warszawa,02-964,Europejska,115'],
    ['21.07854168', '52.17606921', 'Warszawa,02-951,Chorągwi Pancernej,85A'],
    ['21.07208883', '52.17068315', 'Warszawa,02-954,Kubickiego J.,17'],
    ['21.07483515', '52.1746396', 'Warszawa,02-956,Rumiana,65A'],
    ['21.08170053', '52.17456016', 'Warszawa,02-951,Husarii,55/57'],
    ['21.08381422', '52.17139623', 'Warszawa,02-951,Chorągwi Pancernej,18'],
    ['21.07118634', '52.16994876', 'Warszawa,02-954,Marconich,9'],
    ['21.07331534', '52.17874398', 'Warszawa,02-952,Wiertnicza,164'],
    ['21.09635916', '52.15836825', 'Warszawa,02-964,Europejska,35'],
    ['21.07496319', '52.17687456', 'Warszawa,02-952,Wiertnicza,141'],
    ['21.07515299', '52.17271733', 'Warszawa,02-956,Królewicza Jakuba,37'],
    ['21.07699168', '52.16940766', 'Warszawa,02-954,Królowej Marysieńki,20'],
    ['21.07559602', '52.16972907', 'Warszawa,02-954,Wiktorii Wiedeńskiej,4'],
    ['21.07282691', '52.17542263', 'Warszawa,02-956,Królewicza Jakuba,68/1'],
    ['21.07521792', '52.17109701', 'Warszawa,02-954,Królowej Marysieńki,24L'],
    ['21.06839695', '52.15716187', 'Warszawa,02-972,Św. Ledóchowskiej U.,8'],
    ['21.0780884', '52.17256358', 'Warszawa,02-956,Rumiana,48'],
    ['21.07375671', '52.17398006', 'Warszawa,02-956,Królewicza Jakuba,55'],
    ['21.09346105', '52.15766414', 'Warszawa,02-967,Uprawna,29'],
    ['21.07952089', '52.17391321', 'Warszawa,02-952,Wiertnicza,88'],
    ['21.0797155', '52.1749712', 'Warszawa,02-951,Chorągwi Pancernej,67'],
    ['21.09538366', '52.15649219', 'Warszawa,02-964,Europejska,77A'],
    ['21.07008252', '52.15530907', 'Warszawa,02-972,Św. Ledóchowskiej U.,12'],
    ['21.06876885', '52.17250648', 'Warszawa,02-956,Gubinowska,25'],
    ['21.06872039', '52.17255242', 'Warszawa,02-956,Gubinowska,23'],
    ['21.07453474', '52.17240572', 'Warszawa,02-956,Lentza S.,12'],
    ['21.069771', '52.159981', 'Warszawa,02-972,Hlonda,2F'],
    ['21.07882248', '52.1702515', 'Warszawa,02-953,Kosiarzy,21A'],
    ['21.07923512', '52.17581481', 'Warszawa,02-951,Chorągwi Pancernej,80'],
    ['21.08224055', '52.1703894', 'Warszawa,02-952,Wiertnicza,53'],
    ['21.07691373', '52.17735021', 'Warszawa,02-959,Biedronki,156'],
    ['21.08781154', '52.1717361', 'Warszawa,02-951,Rotmistrzowska,6'],
    ['21.07637161', '52.17312886', 'Warszawa,02-956,Lentza S.,4'],
    ['21.07712972', '52.16272324', 'Warszawa,02-797,Klimczaka F.,10A'],
    ['21.07806028', '52.17630471', 'Warszawa,02-950,Łowcza,1'],
    ['21.09455923', '52.15751805', 'Warszawa,02-967,Uprawna,40A'],
    ['21.09428389', '52.15466257', 'Warszawa,02-964,Europejska,107A'],
    ['21.08067534', '52.16895426', 'Warszawa,02-953,Obornicka,32A'],
    ['21.08081885', '52.17432592', 'Warszawa,02-951,Chorągwi Pancernej,58'],
    ['21.07714046', '52.17414854', 'Warszawa,02-956,Rumiana,68A'],
    ['21.07668541', '52.16409317', 'Warszawa,02-972,Sarmacka,7G'],
    ['21.08793485', '52.16851227', 'Warszawa,02-960,Janczarów,4'],
    ['21.07601216', '52.17456868', 'Warszawa,02-956,Rumiana,78'],
    ['21.06771423', '52.17413167', 'Warszawa,02-954,Radosna,6'],
    ['21.06739615', '52.17262944', 'Warszawa,02-956,Resorowa,30'],
    ['21.08621083', '52.17149099', 'Warszawa,02-951,Piechoty Łanowej,9A'],
    ['21.07620107', '52.16363932', 'Warszawa,02-972,Sarmacka,7E'],
    ['21.09426315', '52.15969805', 'Warszawa,02-964,Europejska,15A'],
    ['21.07232793', '52.17590307', 'Warszawa,02-956,Królewicza Jakuba,72'],
    ['21.07934432', '52.17134235', 'Warszawa,02-956,Rumiana,30'],
    ['21.07974404', '52.17047354', 'Warszawa,02-956,Rumiana,7'],
    ['21.08756919', '52.16820352', 'Warszawa,02-960,Janczarów,3'],
    ['21.09078505', '52.16015799', 'Warszawa,02-963,Vogla Z.,24'],
    ['21.08480075', '52.17595317', 'Warszawa,02-946,Sielanki,19'],
    ['21.08134458', '52.17381317', 'Warszawa,02-951,Chorągwi Pancernej,52'],
    ['21.07109031', '52.17508834', 'Warszawa,02-954,Królowej Marysieńki,74'],
    ['21.08205387', '52.15743223', 'Warszawa,02-972,Sarmacka,19C'],
    ['21.0765685', '52.16324843', 'Warszawa,02-797,Klimczaka F.,10E'],
    ['21.07350438', '52.17129129', 'Warszawa,02-954,Kubickiego J.,7'],
    ['21.09101403', '52.16012342', 'Warszawa,02-963,Vogla Z.,26'],
    ['21.07396906', '52.17494844', 'Warszawa,02-955,Łowcza,16'],
    ['21.07448139', '52.17381796', 'Warszawa,02-956,Królewicza Jakuba,46'],
    ['21.09323973', '52.15703239', 'Warszawa,02-967,Uprawna,22'],
    ['21.09103233', '52.15520729', 'Warszawa,02-962,Przyczółkowa,296A'],
    ['21.0932293', '52.15497356', 'Warszawa,02-964,Europejska,111'],
    ['21.09651811', '52.15827341', 'Warszawa,02-964,Europejska,35A'],
    ['21.08158193', '52.17097484', 'Warszawa,02-952,Wiertnicza,61'],
    ['21.07729944', '52.17597387', 'Warszawa,02-952,Wiertnicza,122'],
    ['21.09386107', '52.15443468', 'Warszawa,02-964,Europejska,113A'],
    ['21.06999452', '52.17618491', 'Warszawa,02-954,Królowej Marysieńki,96'],
    ['21.08643582', '52.17329133', 'Warszawa,02-951,Rotmistrzowska,24'],
    ['21.08191095', '52.15346244', 'Warszawa,02-972,Al. Rzeczypospolitej,1A'],
    ['21.08001847', '52.16630978', 'Warszawa,02-954,Al. Wilanowska,5'],
    ['21.08584007', '52.17497918', 'Warszawa,02-959,Biedronki,59A'],
    ['21.07258693', '52.17467159', 'Warszawa,02-956,Królewicza Jakuba,63A'],
    ['21.07310624', '52.17671822', 'Warszawa,02-956,Rumiana,95'],
    ['21.09077543', '52.15799057', 'Warszawa,02-967,Młocarni,18'],
    ['21.07896407', '52.17117951', 'Warszawa,02-956,Rumiana,17'],
    ['21.07643942', '52.17095116', 'Warszawa,02-956,Królewicza Jakuba,13A'],
    ['21.07200762', '52.17833869', 'Warszawa,02-954,Goplańska,1'],
    ['21.07071606', '52.17041965', 'Warszawa,02-954,Kubickiego J.,19'],
    ['21.0694348', '52.17295237', 'Warszawa,02-956,Gubinowska,24'],
    ['21.0778138', '52.16821626', 'Warszawa,02-954,Królowej Marysieńki,5E'],
    ['21.07379257', '52.17467202', 'Warszawa,02-955,Łowcza,13A'],
    ['21.0832045', '52.17337997', 'Warszawa,02-951,Husarii,38'],
    ['21.08911764', '52.16891063', 'Warszawa,02-946,Biedronki,24A'],
    ['21.08514671', '52.1714711', 'Warszawa,02-951,Husarii,12'],
    ['21.07432639', '52.16354077', 'Warszawa,02-972,Sarmacka,12E'],
    ['21.07809221', '52.16862745', 'Warszawa,02-954,Królowej Marysieńki,10'],
    ['21.0846015', '52.1727747', 'Warszawa,02-948,Kosiarzy,6'],
    ['21.07249316', '52.17854087', 'Warszawa,02-952,Wiertnicza,169'],
    ['21.08205237', '52.16929441', 'Warszawa,02-956,Rumiana,6A'],
    ['21.074104', '52.166043', 'Warszawa,02-972,Sarmacka,8'],
    ['21.07867575', '52.1759966', 'Warszawa,02-951,Chorągwi Pancernej,83'],
    ['21.07853769', '52.17399351', 'Warszawa,02-952,Wiertnicza,97'],
    ['21.07175283', '52.177773', 'Warszawa,02-956,Rumiana,111'],
    ['21.08894471', '52.16787269', 'Warszawa,02-946,Biedronki,12'],
    ['21.079302', '52.162108', 'Warszawa,02-797,Klimczaka F.,7D'],
    ['21.06928638', '52.17262341', 'Warszawa,02-956,Gubinowska,15A'],
    ['21.08968583', '52.15862367', 'Warszawa,02-967,Koralowa,60'],
    ['21.08636293', '52.17464082', 'Warszawa,02-959,Biedronki,57'],
    ['21.07078696', '52.1758093', 'Warszawa,02-954,Królowej Marysieńki,88'],
    ['21.08892454', '52.17432695', 'Warszawa,02-947,Łucznicza,14'],
    ['21.07296698', '52.17528969', 'Warszawa,02-956,Królewicza Jakuba,66'],
    ['21.08509465', '52.17543911', 'Warszawa,02-959,Biedronki,92'],
    ['21.08195469', '52.15755049', 'Warszawa,02-972,Sarmacka,19B'],
    ['21.08760424', '52.16962289', 'Warszawa,02-960,Janczarów,19'],
    ['21.08107002', '52.17490792', 'Warszawa,02-949,Ostra,14'],
    ['21.09409626', '52.15457032', 'Warszawa,02-964,Europejska,109A'],
    ['21.08167302', '52.17577937', 'Warszawa,02-959,Biedronki,85A'],
    ['21.0700404', '52.17613132', 'Warszawa,02-954,Królowej Marysieńki,94A'],
    ['21.07575502', '52.17071286', 'Warszawa,02-954,Królowej Marysieńki,24E'],
    ['21.08252716', '52.1751792', 'Warszawa,02-951,Piechoty Łanowej,55'],
    ['21.08262836', '52.17357298', 'Warszawa,02-951,Husarii,41'],
    ['21.090158', '52.157261', 'Warszawa,02-967,Koralowa,36'],
    ['21.06822358', '52.17247796', 'Warszawa,02-956,Gubinowska,37'],
    ['21.08605768', '52.17374665', 'Warszawa,02-951,Rotmistrzowska,28'],
    ['21.07735746', '52.16804807', 'Warszawa,02-954,Królowej Marysieńki,5K'],
    ['21.09144053', '52.15492257', 'Warszawa,02-962,Przyczółkowa,296'],
    ['21.08857236', '52.1705256', 'Warszawa,02-946,Biedronki,23A'],
    ['21.07487009', '52.17343913', 'Warszawa,02-956,Królewicza Jakuba,42'],
    ['21.0874894', '52.1708496', 'Warszawa,02-948,Obornicka,3'],
    ['21.09019167', '52.160009', 'Warszawa,02-963,Vogla Z.,10'],
    ['21.07141679', '52.17625922', 'Warszawa,02-956,Królewicza Jakuba,83'],
    ['21.09332347', '52.15423267', 'Warszawa,02-964,Europejska,117A'],
    ['21.07980446', '52.16969403', 'Warszawa,02-954,Urodzajna,6'],
    ['21.08349662', '52.17013944', 'Warszawa,02-952,Wiertnicza,38'],
    ['21.08861134', '52.16040493', 'Warszawa,02-964,Przedatki,1'],
    ['21.06891009', '52.17504912', 'Warszawa,02-956,Jabłonowskiego J. S.,51'],
    ['21.08466084', '52.17477792', 'Warszawa,02-949,Petyhorska,6'],
    ['21.0901511', '52.15828242', 'Warszawa,02-967,Koralowa,29'],
    ['21.08137154', '52.16886107', 'Warszawa,02-956,Rumiana,1D'],
    ['21.079979', '52.159479', 'Warszawa,02-972,Sarmacka,15F'],
    ['21.08347615', '52.17312595', 'Warszawa,02-951,Husarii,34'],
    ['21.0780467', '52.16829576', 'Warszawa,02-954,Królowej Marysieńki,5B'],
    ['21.0713649', '52.1768401', 'Warszawa,02-956,Królewicza Jakuba,84A'],
    ['21.08207333', '52.17255122', 'Warszawa,02-951,Chorągwi Pancernej,33'],
    ['21.07562346', '52.17711629', 'Warszawa,02-952,Wiertnicza,138'],
    ['21.08758682', '52.17151348', 'Warszawa,02-951,Rotmistrzowska,3'],
    ['21.08770116', '52.17404989', 'Warszawa,02-959,Biedronki,74A'],
    ['21.06900813', '52.17227989', 'Warszawa,02-956,Gubinowska,35'],
    ['21.0917195', '52.15590798', 'Warszawa,02-967,Brodzik,14'],
    ['21.08782831', '52.17303765', 'Warszawa,02-959,Biedronki,43'],
    ['21.08222173', '52.15721098', 'Warszawa,02-972,Sarmacka,19E']
];