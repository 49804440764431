import { Pipe, PipeTransform } from '@angular/core';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { RouteExt } from '@entities/route-ext';

@Pipe({
    name: 'deliveriesPerVansPipe',
    pure: true
})
export class DeliveriesPerVansPipe implements PipeTransform {

    public transform(optimizeRoute: OptimizeRoutes): string {
        const routes: number = optimizeRoute.routes.filter((r: RouteExt) => r.deliveriesCount).length;
        return optimizeRoute.totalDeliveries ? (optimizeRoute.totalDeliveries / routes).toFixed(2) : "0";
    }
}
