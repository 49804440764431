import { Injectable } from '@angular/core';
import { GuidedTourService, GuidedTour, Orientation, TourStep } from 'ngx-guided-tour';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environment';
import { AppLang } from '@enums/enum';
import { AppService } from '@services/app.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { clone } from 'lodash';
const data: any = require('./generator/sheet.json')

@Injectable()
export class ProxyGuideTourService {

  private selector: string;
  private guidedTourCurrentStepStream$: Subscription;

  constructor(
    private readonly guidedTourService: GuidedTourService,
    private readonly translateService: TranslateService,
    // private readonly appService: AppService
  ) { }

  public getOffsetTop = element => {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  }

  public startTour(name: string) {
    
    if (this.guidedTourCurrentStepStream$) {
      this.guidedTourCurrentStepStream$.unsubscribe();
    }

    this.guidedTourService.startTour(this.generateInstructions(name));

    this.guidedTourCurrentStepStream$ = this.guidedTourService.guidedTourCurrentStepStream
      .subscribe((step: TourStep) => {

        const element = document.querySelector(step.selector) as HTMLElement;
        const stepOvarlay = document.querySelector('.guided-tour-spotlight-overlay') as HTMLElement;
        const parent = document.getElementById('content');
        const tourStep = document.querySelector('.tour-step') as HTMLElement;

        if (!name.includes('Create') && !name.includes('Update') && !name.includes('Edit')) {
          return;
        }

        if (element) {

          if (parent.scrollHeight - parent.scrollTop < parent.clientHeight + 300) {

            parent.scrollTo({
              top: element.offsetTop - parent.offsetTop,
              left: 0,
              behavior: 'smooth'
            });

          } else {
            parent.scrollTo({
              top: element.offsetTop - parent.offsetTop,
              left: 0,
              behavior: 'smooth'
            });
  
            setTimeout(() => {
              stepOvarlay.style.top = element.scrollTop + 294 + 'px';
              tourStep.style.top = element.scrollTop + 294 + stepOvarlay.clientHeight + 'px';
            }, 20);
          }
        }
      });
  }

  private generateInstructions(componentName: string) {
    const component = data.filter(e => (e['A'] === componentName) && ['ALL', environment.client].includes(e['I']) && e['B'] !== 'HELP');
    
    const response: GuidedTour = {
      tourId: componentName,
      useOrb: true,
      steps: []
    };
    
    component.forEach(item => {

      const key = (item['I'] === 'ALL') 
        ? `GUIDETOUR.${item['A']}.${item['B']}` 
        : `GUIDETOUR.${item['A']}.${item['B']}.${environment.client}`;
      
      const currentLang = this.translateService.currentLang;

      const title = ((currentLang === AppLang.PL && item['D']) || currentLang === AppLang.EN && item['E'] ) ? this.translateService.instant(key + '.title') : '';
      let description = ((currentLang === AppLang.PL && item['F']) || currentLang === AppLang.EN && item['G'] ) ? this.translateService.instant(key + '.description') : '';

      if (!environment.production) {
        description += `<br /><br /> [INFO: line: ${item['J']}, id: ${item['C']}]`
      }

      this.selector = item['C'];
      response.steps.push({
        title: title,
        action: this.test,
        selector: this.selector,
        content: description,
        orientation: item['H'] ? item['H'].toLowerCase() : Orientation.Bottom
      });
    });

    return response;
  }

  public test = (): void => {
  }

  public findHelpInstruction(componentName: string) {
    let text = data.filter(e => (e['A'] === componentName && ['ALL', environment.client].includes(e['I']) && e['B'] === 'HELP'));
    let response;
    const lang = this.translateService.currentLang;
    
    if (text.length === 2) {
      if (lang === AppLang.PL) {
        response = text.find(e => [environment.client].includes(e['I']))['F'];
      } else {
        response = text.find(e => [environment.client].includes(e['I']))['G'];
      }
    } else {
      if (lang === AppLang.PL) {
        response = text.find(e => ['ALL'].includes(e['I']))['F'];
      } else {
        response = text.find(e => ['ALL'].includes(e['I']))['G'];
      }
    }

    if (!environment.production) {
      response += `<br /><br /> [INFO: line: ${text[0]['J']}, id: ${text[0]['A']}]`
    }

    return response;
  }

}
