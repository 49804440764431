import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Finalize } from '../entities/finalize';
import { SimulatorService } from '../services/simulator.service';

@Component({
    selector: 'app-simulation-finalize',
    template: `


        <ul *ngIf="!loading">
            <li>{{'All' | translate}} - <strong>{{results.length}}</strong><span class="comma">,</span></li>
            <li *ngFor="let key of countResultsKeys()">{{key | translate}} - <strong>{{value(key)}}</strong><span class="comma">,</span></li>
        </ul>

        <ngx-datatable
                *ngIf="!loading"
                id="simulation-results"
                class="bootstrap"
                [rows]="results"
                [columns]="columns"
                [columnMode]="'flex'"
                [headerHeight]="50"
                [footerHeight]="50"
                [messages]="tableMessages"
                [limit]="30"
                [sortType]="'multi'"
                [rowHeight]="'auto'">
        </ngx-datatable>

        <div *ngIf="loading" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
        </div>

        <!-- {{'INITIAL_ERROR' | translate}} -->
        <!-- {{'MAPPING_ERROR' | translate}} -->
        <!-- {{'ERROR' | translate}} -->
        <!-- {{'NO_SLOTS_AVAILABLE' | translate}} -->
        <!-- {{'RESERVED' | translate}} -->
        <!-- {{'CONFIRMED' | translate}} -->

    `,
    styleUrls: ['./create-simulation.component.scss']
})
export class FinalizeSimulationComponent implements OnInit {

    public results: Finalize[];
    public countResults: Object;

    public loading: boolean = true;

    public tableMessages = {
        'emptyMessage': this.translateService.instant('No data to display')
    };

    public columns = [
        {prop: 'queueElementId', name: this.translateService.instant('queueElementId'), flexGrow: 1},
        {prop: 'result', name: this.translateService.instant('Result'), flexGrow: 2},
        {prop: 'slotPicked', name: this.translateService.instant('slotPicked'), flexGrow: 1},
        {prop: 'slotPickingStrategy', name: this.translateService.instant('slotPickingStrategy'), flexGrow: 2},
        {prop: 'slotsAvailable', name: this.translateService.instant('slotsAvailable'), flexGrow: 3},
        {prop: 'type', name: this.translateService.instant('type'), flexGrow: 1},
        {prop: 'fixedSlot', name: this.translateService.instant('fixedSlot'), flexGrow: 1},
    ];

    constructor(private simulatorService: SimulatorService, private translateService: TranslateService) {}

    public ngOnInit() {
        this.simulatorService.simulationResults
            .subscribe(
                (finalize: Finalize[]) => {
                    this.loading = true;
                    this.results = finalize;
                    this.countResults = _.countBy(this.results, (r: Finalize) => r.result);
                    setTimeout(() => {
                        this.loading = false;
                    }, 100);
                });
    }

    public countResultsKeys() {
        return _.keys(this.countResults);
    }

    public value(key): string {
        const p = (this.countResults[key] * 100 / this.results.length).toFixed(0);
        return `${this.countResults[key]} (${p}%)`;
    }
}
