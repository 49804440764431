import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteId } from '@entities/routeId';
import { AuditLogType } from '@enums/enum';
import { AuditLog } from '@interfaces/audit-log';
import { TranslateService } from '@ngx-translate/core';
import { AuditLogService } from '@services/audit-log.service';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RoutesPlanningParams } from 'src/app/visualiser/interfaces/routes-planning-params.interface';

@Component({
  selector: 'app-audit-log-content',
  templateUrl: './audit-log-content.component.html',
})
export class AuditLogContentComponent implements OnInit {

  public logs$: Observable<AuditLog[]>;

  public columns = [
    { prop: 'occurredAt', name: this.translate.instant('Date'), flexGrow: 2, sortable: true },
    { prop: 'type', name: this.translate.instant('Type'), flexGrow: 3, sortable: true },
    { prop: 'data', name: this.translate.instant('Log'), flexGrow: 5, sortable: true },
    { prop: 'username', name: this.translate.instant('User'), flexGrow: 2, sortable: true },
  ];

  public tableMessages = {
    'emptyMessage': this.translate.instant('No data to display')
  };

  public auditLogType = AuditLogType;
  public params: RoutesPlanningParams;

  constructor(
    private readonly audotLogService: AuditLogService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilsService: UtilsService,

  ) { }

  ngOnInit() {   

    this.logs$ = this.route.params.pipe(
      switchMap((params: {y: string, m: string, d: string, shiftId: string, id: string, type: string}) => {

        this.params = { 
          y: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'y'),
          m: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'm'),
          d: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'd'),
          shiftId: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'shiftId'),
          depot: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'depot'),
        } as RoutesPlanningParams;
        
        const type = (!params.hasOwnProperty('type')) 
          ? this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'type') : params.type;
         
        const id = (!params.hasOwnProperty('type')) 
          ? RouteId.getShiftId3(this.params.y, this.params.m, this.params.d, this.params.shiftId) : params.id;

        switch (type) {
          case 'DELIVERY':
            return this.audotLogService.getDeliveryLogs(params.id);
          case 'ROUTE':
            return this.audotLogService.getRouteLogs(params.id);
          case 'SHIFT':
            return this.audotLogService.getShiftLogs(id);
          case 'SHIFTPLANNING':
            return this.audotLogService.getShiftPlanningLogs(params.id);
          case 'BOXBAG':
            return this.audotLogService.getBoxBaggLogs(params.id);
          default:
            break;
        }
      })
    )
  }
}
