<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="sliders"></i-feather> {{'Create vehicle type' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="sliders"></i-feather> {{'Edit vehicle type' | translate}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form">


                    <div *ngFor="let item of regularFormItems()" class="form-group row">
                        <label class="col-2" for="code">{{item.toUpperCase() | translate}}:</label>
                        <div class="col-10">
                            <input [formControlName]="item" class="form-control" />
                            <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-2" for="active">{{'Active' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="active">
                                <option [value]="true">{{'ACTIVE' | translate}}</option>
                                <option [value]="false">{{'UNACTIVE' | translate}}</option>
                            </select>

                            <div *ngIf="form.get('active').hasError('required') && form.get('active').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-2" for="allowMergeToShared">{{'Allow merge to shared' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="allowMergeToShared">
                                <option [value]="true">{{'Yes' | translate}}</option>
                                <option [value]="false">{{'No' | translate}}</option>
                            </select>

                            <div *ngIf="form.get('active').hasError('required') && form.get('active').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let type of ['capacity', 'softCapacity']" [formGroupName]="type">

                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'VehicleType.' + type | translate}}</label>

                        <div *ngFor="let item of capacityNumberControl" class="form-group row">
                            <label class="col-2" for="code">{{'VehicleType.' + item | translate}}:</label>
                            <div class="col-10">
                                <input type="number" [formControlName]="item" class="form-control" />
                                <div *ngIf="form.get(type).get(item).hasError('required') && form.get(type).get(item).touched" class="error-color">
                                    {{ "The field is required" | translate }}!
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!-- {{ 'VehicleType.chilledBoxes' | translate }} -->
<!-- {{ 'VehicleType.combinedBoxes' | translate }} -->
<!-- {{ 'VehicleType.dryBoxes' | translate }} -->
<!-- {{ 'VehicleType.frozenBoxes' | translate }} -->
<!-- {{ 'VehicleType.weight' | translate }} -->
<!-- {{ 'VehicleType.capacity' | translate }} -->
<!-- {{ 'VehicleType.softCapacity' | translate }} -->