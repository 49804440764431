import { Pipe, PipeTransform } from '@angular/core';
import { PlannerSupported, PlanningSequenceClazz } from '../entities/planning-sequence';

@Pipe({
    name: 'findValidatorPipe',
    pure: true
})
export class FindValidatorPipe implements PipeTransform {
    public transform(planner: PlannerSupported[], type: PlanningSequenceClazz): PlannerSupported | undefined {

        return (planner) ? planner.find( (p: PlannerSupported) => p.clazz === type) : undefined;
    }
}
