
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Shift edges' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]" class="btn btn-xs btn-white" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">

                <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <label>{{ 'Start Time' | translate }}:</label>
                    <span class="modify-value">{{ shiftConfig.shiftId.date + ' ' + shiftConfig.startTime | timeFormatterPipe:'HH:mm' }}</span>
                </div>

                <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <label>{{ 'End Time' | translate }}:</label>
                    <span class="modify-value">{{ shiftConfig.shiftId.date + ' ' + shiftConfig.endTime | timeFormatterPipe:'HH:mm' }}</span>
                </div>
            </div>
        </div>

        <div [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'" *ngIf="mode == 'edit' || mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()" [valid]="validShiftStartAndFinishTime">
                <h3 class="title">{{ 'Shift edges' | translate }}</h3>
                <div>{{ timeToClose }}</div>

                <div class="form">
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>
                    <div class="row">
                        <div class="col-4">
                            <label>{{ 'Start Time' | translate }}:</label>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="startTime.hour">
                                <option *ngFor="let option of cutoffHoursOptionsForShift" value="{{ option.value }}">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="startTime.minutes">
                                <option value="00">00</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <label>{{ 'End Time' | translate }}:</label>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="endTime.hour">
                                <option *ngFor="let option of cutoffHoursOptionsForShift" value="{{ option.value }}">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="endTime.minutes">
                                <option value="00">00</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                    </div>

                    <p *ngIf="!validShiftStartAndFinishTime" class="alert alert-error">{{ 'Invalid start and finish hours' | translate }}!</p>
                </div>
            </editor-window>
        </div>
    