<ng-container *ngIf="(routing$ | async) as routing">
    <form [formGroup]="form">
        <div class="shift-settings">
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of regularControlsNumber" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                name="info"></i-feather>
                        </ng-container>
                    </label>

                    <input type="number" [formControlName]="item" class="form-control w-100" />

                    <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                        {{ "The field value is too long" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('min') && form.get(item).touched" class="error-color">
                        {{ "The entered value exceeds the defined range" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('max') && form.get(item).touched" class="error-color">
                        {{ "The entered value exceeds the defined range" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>

                </div>

                <div *ngFor="let item of regularControlsBoolean" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                name="info"></i-feather>
                        </ng-container>
                    </label>

                    <select [id]="item" class="custom-select" [formControlName]="item">
                        <option value="true">{{ 'True' | translate }}</option>
                        <option value="false">{{ 'False' | translate }}</option>
                    </select>

                    <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                        {{ "The field value is too long" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>

                </div>

                <div class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.planningSequence' | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                name="info"></i-feather>
                        </ng-container>
                    </label>
                    <select id="planningSequence" class="custom-select" formControlName="planningSequence">
                        <option *ngFor="let planning of (planningSequence$ | async); let i = index"
                            [value]="planning.id">{{ planning.name | translate }}</option>
                    </select>
                </div>

                <div class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.shiftplanType' | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                name="info"></i-feather>
                        </ng-container>
                    </label>
                    <select id="shiftplanType" class="custom-select" formControlName="shiftplanType">
                        <option *ngFor="let option of shiftPlanTypeValues" [value]="option">{{ option | translate }}
                        </option>
                    </select>
                </div>

                <div class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'routeStrategy' | translate}}:&nbsp;</span>
                        <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                            [style.width.px]="13" name="info"></i-feather>
                    </label>

                    <select id="routeStrategy" class="custom-select" formControlName="routeStrategy">
                        <option *ngFor="let strategy of (routeStrategies$ | async)" [value]="strategy">{{ strategy }}
                        </option>
                    </select>
                </div>

            </div>


            <div class="d-flex justify-content-start flex-fill">
                <div class="w-50">
                    <!-- VAN LIMITS -->
                    <ng-container formArrayName="vanLimits">
                        <label
                            class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'VAN
                            LIMITS' | translate}}:</label>
                        <button (click)="addVanLimit()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0"
                            [style.height.px]="20" [style.width.px]="20">
                            <i-feather [style.width.px]="18" [style.height.px]="18" name="plus"></i-feather>
                        </button>
                        <div class="d-flex justify-content-start align-items-center flex-wrap">
                            <ng-container
                                *ngFor="let step of form.controls.vanLimits.controls | sortBy:'desc':'usedPercentage'; let i=index">
                                <ng-container formGroupName="{{i}}">
                                    <div class="item-sm mg-r-10">
                                        <label
                                            class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.RoutesUsed'
                                            | translate}}:</label>
                                        <input type="number" formControlName="usedPercentage"
                                            class="form-control w-100" />
                                    </div>
                                    <div class="item-sm mg-r-10">
                                        <label
                                            class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.MinimalUsage'
                                            | translate}}:</label>
                                        <input type="number" formControlName="blockedSlots"
                                            class="form-control w-100" />
                                    </div>
                                    <button (click)="removeVanLimit(i)"
                                        class="btn btn-xs btn-outline-warning remove-van-limit align-self-end"><i-feather
                                            [style.height.px]="16" name="trash-2"></i-feather></button>
                                </ng-container>

                            </ng-container>

                        </div>
                        <div class="error"
                            *ngIf="form.get('vanLimits').errors && form.get('vanLimits').dirty && form.get('vanLimits').errors.validVanLimitMin">
                            {{'Not found van limit used percentage equal 0' | translate}}
                        </div>
                        <div class="error"
                            *ngIf="form.get('vanLimits').errors && form.get('vanLimits').dirty && form.get('vanLimits').errors.validVanLimitMax">
                            {{'Not found van limit used percentage equal 100' | translate}}
                        </div>
                    </ng-container>

                    <ng-container>
                        <label
                            class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'VIRTUAL
                            DEPOTS' | translate}}:</label>
                        <div id="virtual-depot-ids-control" class="col-10 d-flex flex-wrap">
                            <ng-container formArrayName="virtualDepotConfig">
                                <ng-container *ngFor="let p of virtualDepotConfigCtrl.controls; let i=index">

                                    <ng-container formGroupName="{{i}}">

                                        <div id="role-from-virtualDepotIds-{{i}}"
                                            class="custom-control custom-checkbox pd-b-10 w-100">
                                            <input formControlName="enabled" [checked]="p.value.enabled" type="checkbox"
                                                class="custom-control-input" id="customCheck{{i}}">
                                            <label class="custom-control-label justify-content-start"
                                                for="customCheck{{i}}">{{p.value.name}}</label>

                                            <ng-container
                                                *ngIf="virtualDepotConfigCtrl.controls[i].controls.enabled.value">
                                                <div class="item-md mg-r-10">
                                                    <label
                                                        class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.routeStrategy'
                                                        | translate}}:</label>

                                                    <select id="shiftplanType" class="custom-select"
                                                        formControlName="routeStrategy">
                                                        <option *ngFor="let option of routeStrategyType"
                                                            [value]="option">{{ option | translate }}</option>
                                                    </select>

                                                </div>

                                                <div class="d-flex justify-content-start flex-fill">

                                                    <div class="item-md mg-r-10 flex-grow-1">
                                                        <label
                                                            class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.routes'
                                                            | translate}}:</label>
                                                        <input type="number" formControlName="routes"
                                                            class="form-control w-100" />
                                                    </div>

                                                    <div class="item-md mg-r-10 flex-grow-1">
                                                        <label
                                                            class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.isExclusive'
                                                            | translate}}:</label>
                                                        <select id="isExclusive" class="custom-select"
                                                            formControlName="isExclusive">
                                                            <option [ngValue]="true">{{ 'True' | translate }}</option>
                                                            <option [ngValue]="false">{{ 'False' | translate }}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </ng-container>

                                        </div>



                                    </ng-container>

                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>
                <div class="w-50">
                    <!-- SLOTS LIMITS  -->
                    <ng-container formArrayName="slotLimits">

                        <label
                            class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">
                            <span>{{'shiftProperty.slotLimitsPerVan' | translate}}:&nbsp;</span>
                            <ng-container *ngIf="'slotLimitsPerVan' | tooltipLabelPipe:'shift' as tooltip">
                                <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                    name="info"></i-feather>
                            </ng-container>
                        </label>

                        <button (click)="addSlotLimit()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0"
                            [style.height.px]="20" [style.width.px]="20">
                            <i-feather [style.width.px]="18" [style.height.px]="18" name="plus"></i-feather>
                        </button>
                        <div class="d-flex justify-content-start align-items-start flex-wrap">
                            <ng-container *ngFor="let step of form.controls.slotLimits.controls; let i=index">
                                <ng-container formGroupName="{{i}}">
                                    <div class="item-sm mg-r-10">
                                        <label
                                            class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.slot'
                                            | translate}}:</label>
                                        <input type="text" formControlName="key" class="form-control w-100" />

                                        <div *ngIf="slotLimits.controls[i].get('key').hasError('pattern')"
                                            class="error-color">
                                            {{ "Please fill time in format" | translate }}!
                                        </div>
                                    </div>

                                    <div class="item-sm mg-r-10">
                                        <label
                                            class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'shiftProperty.limit'
                                            | translate}}:</label>
                                        <input type="number" formControlName="value" class="form-control w-100" />
                                    </div>

                                    <button (click)="removeSlotLimit(i)"
                                        class="btn btn-xs btn-outline-warning remove-van-limit remove-slot-limit align-self-start"><i-feather
                                            [style.height.px]="16" name="trash-2"></i-feather></button>



                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>


            <div *ngIf="formErrorsMessages" class="form-group mg-t-40">
                <div class="">
                    <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                    <p class="error-color">{{formErrorsMessages.message | translate}}</p>
                    <ul>
                        <li *ngFor="let error of formErrorsMessages" class="error-color">

                            <span [ngSwitch]="error.field.split('.')[1]">
                                <span *ngSwitchCase="'shiftFulfillmentData'">{{'Fulfilment' | translate}}</span>
                                <span *ngSwitchCase="'shiftIntegrationData'">{{'Integration' | translate}}</span>
                                <span *ngSwitchCase="'shiftRoutingData'">{{'Routing' | translate}}</span>
                                &nbsp;-&nbsp;
                            </span>

                            <strong>{{ 'shiftProperty.'+error.field.split('.')[2] | translate }}&nbsp;</strong>

                            <span>{{ error.value | translate }}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>



        <div class="actions d-flex justify-content-between">
            <button [routerLink]="['/calendar/shift-planning']" class="btn btn-white tx-11">{{'Back to shifts list' |
                translate}}</button>
            <div class="d-flex justify-content-between">
                <button [routerLink]="['../step-4']"
                    class="btn btn-sm btn-warning pd-x-15 btn-uppercase mg-l-5">{{'Previous step' | translate}}</button>
                <button *ngIf="mode !== 'create'" type="submit" (click)="update()"
                    class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Save shift' | translate}}</button>
                <button *ngIf="mode === 'create'" type="submit" (click)="create()"
                    class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Create shift' | translate}}</button>

            </div>
        </div>
    </form>
</ng-container>

<!-- {{'must not be null' | translate}} -->
<!-- {{'COUNT' | translate}} -->
<!-- {{'PERCENTAGE' | translate}} -->