import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '@services/app.service';
import { filter, map } from 'rxjs/operators';
import { Boo } from '@entities/boo';

@Pipe({
  name: 'findWarehousePipe',
  pure: true
})
export class FindWarehousePipe implements PipeTransform {
  
  constructor(private appService: AppService){}

  transform(warehouseId: number): any {
    return this.appService.findWarehouseRef(warehouseId) || '';
  }

}
