export class Hardware {
    public id: string;
    public brand: string;
    public createdAt: string;
    public deviceNo: string;
    public hardwareType: string;
    public inventoryNo: string;
    public model: string;
    public modifiedAt: string;
    public owner: string;
    public status: string;
    public version: string;
    public phoneNumber: string;
    public properties: {
        pin: string;
        puk: string;
    };

    get label(): string {
        return `${this.inventoryNo} ${this.deviceNo}`;
    }
}
