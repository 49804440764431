import { Pipe, PipeTransform } from '@angular/core';
import { sumBy } from 'lodash';

@Pipe({
    name: 'sumByPipe',
    pure: true
})
export class SumByPipe implements PipeTransform {
    public transform(results: any[], key: string): number {
        return sumBy(results, (s: any) => s[key]);
    }
}
