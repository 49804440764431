import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class EntityValidationError {
    public errors: string[] = [];

    constructor(private translateService: TranslateService) {}

    public parse(err: HttpErrorResponse) {
        const errors = [];

        if (!err.hasOwnProperty('error') || !err.error.hasOwnProperty('errors')) {
            return err;
        }
        
        (err.error.errors).map((e: string) => {
            const splittedError = e.split(': ');
            const key = splittedError[0].split(' ');
            errors.push({field: key[key.length - 1], value: splittedError[1]});
        });
        return errors;
    }

    private __translate() {
        this.translateService.instant('must not be blank');
        this.translateService.instant('A driver with this employeeId already exists');
        this.translateService.instant('Deserialization failed');
        this.translateService.instant('A van with this registration already exists');
    }
}
