<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="activity"></i-feather> {{'Analytics' | translate}}</h4>
        </div>
        <div toolbar>
            <date-range-picker class="flex-grow-1 pic-apm-maint-slot" [extendedOption]="false"
            (changedDateRange)="changedDateRange($event)" [defaultDateRange]="dateRange"></date-range-picker>
            
            <button (click)="print()" class="btn btn-sm btn-uppercase mg-l-5 btn-white"><i class="fas fa-print"></i> {{'Download' | translate}}</button>

        </div>
        <hr />
    </div>

    <div content>

        <div *ngIf="(deliveriesPunctualityStats$ | async)"></div>
        <div class="chart-container">
            <div class="card bg-white">
                <div class="chart">
                    <div class="inside small">
                        <h3 class="text-center">{{'Summary punctuality deliveries' | translate}} - ({{dateRange | translate}})</h3>

                        <ngx-charts-bar-horizontal-normalized 
                            *ngIf="!loaderDeliveries && chartDataDeliveries.length"
                            [scheme]="colorScheme" 
                            [results]="chartDataDeliveries" 
                            [gradient]="gradient" 
                            [xAxis]="showXAxis"
                            [yAxis]="false" 
                            [legend]="showLegend" 
                            [showXAxisLabel]="showXAxisLabel" 
                            [showYAxisLabel]="false">

                            <ng-template #tooltipTemplate let-model="model">
                                <app-chart-tooltip [model]="model"></app-chart-tooltip>
                            </ng-template>

                        </ngx-charts-bar-horizontal-normalized>

                        <div *ngIf="loaderDeliveries" class="load-spinner text-center">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
                        </div>
                        <div *ngIf="!loaderDeliveries && !chartDataDeliveries.length" class="load-spinner text-center">
                            {{'No results' | translate}}!
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(driversPunctualityStats$ | async) as driversPunctualityStats"></div>
        <div class="chart-container">
            <div class="card bg-white">
                <div class="chart">
                    <div class="inside scrollable large">
                        <h3 class="text-center form-inline">
                            <span>{{'Search by name' | translate}}:</span> <input type="text" class="form-control form-control-sm" [(ngModel)]="driverName"
                                (input)="filterByName()" />
                            <span>{{'Select by country' | translate}}:</span>

                            <select [(ngModel)]="nationality" class="form-control form-control-sm" (change)="filterByNationality()">
                                <option *ngFor="let nationality of nationalityOptions" [value]="nationality">{{nationality | translate}}</option>
                            </select>
                        </h3>

                        <ngx-charts-bar-horizontal-normalized 
                            *ngIf="!loader && filteredChartData.length" 
                            [scheme]="colorScheme"
                            [results]="filteredChartData" 
                            [gradient]="gradient" 
                            [xAxis]="showXAxis" 
                            [yAxis]="showYAxis"
                            [legend]="showLegend" 
                            [showXAxisLabel]="showXAxisLabel" 
                            [showYAxisLabel]="showYAxisLabel">

                            <ng-template #tooltipTemplate let-model="model">
                                <app-chart-tooltip [model]="model"></app-chart-tooltip>
                            </ng-template>
                        </ngx-charts-bar-horizontal-normalized>

                        <div *ngIf="loader" class="load-spinner text-center">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
                        </div>
                        <div *ngIf="!loader && !filteredChartData.length" class="load-spinner text-center">
                            {{'No results' | translate}}!
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="chart-container">
            <div class="card bg-white">
                <div class="chart">
                    <div class="inside scrollable large">
                        <h3 class="text-center form-inline">
                            <span>{{'Search by name' | translate}}:</span> <input type="text" class="form-control form-control-sm" [(ngModel)]="driverName"
                                (input)="filterByName()" />
                            <span>{{'Select by country' | translate}}:</span>

                            <select [(ngModel)]="nationality" class="form-control form-control-sm" (change)="filterByNationality()">
                                <option *ngFor="let nationality of nationalityOptions" [value]="nationality">{{nationality
                                    | translate}}</option>
                            </select>
                        </h3>
                        <driver-printable-report *ngIf="!loader && filteredDriverReportData.length" [data]="filteredDriverReportData"></driver-printable-report>
                        <div *ngIf="loader" class="load-spinner text-center">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
                        </div>
                        <div *ngIf="!loader && !filteredChartData.length" class="load-spinner text-center">
                            {{'No results' | translate}}!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</layout-default>

<!-- 
    {{'EARLY' | translate }}
    {{'LATE' | translate }}
    {{'ON_TIME' | translate }}
    {{'YESTERDAY' | translate }}
    {{'THIS_WEEK' | translate }}
    {{'THIS_MONTH' | translate }}
    {{'TWO_WEEKS' | translate }}
    {{'THIS_QUARTER' | translate }}
    {{'LAST_7_DAYS' | translate }}
    {{'LAST_30_DAYS' | translate }}
    {{'LAST_90_DAYS' | translate }}
    {{'USER_VALUE' | translate }}
    {{'Access is denied' | translate}}
    {{'INCOMPLETE_AFTER' | translate}}
    {{'INCOMPLETE_BEFORE' | translate}}
   -->
