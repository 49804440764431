<layout-default>
  <div header>
      <div toolbar>
          <h4>
              <i-feather name="sliders"></i-feather> {{'Cron job scheduler' | translate}}
          </h4>
      </div>

      <div toolbar>
          <button class="btn btn-sm btn-uppercase btn-white"
              [routerLink]="['../create']">
              <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add new cron job' | translate }}
          </button>
          <div id="vehicle-types-btn-search" class="filter-container">
              <div class="phrase-filter">
                  <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
              </div>
          </div>
      </div>
      <hr />
  </div>
  
  <div content>
      <div *ngIf="(data$ | async) as data">

          <ngx-datatable 
              id="cron-job-scheduler-table" 
              class="bootstrap" 
              [rows]="rows" 
              [columns]="columns" 
              [columnMode]="'flex'"
              [headerHeight]="50" 
              [footerHeight]="50" 
              [messages]="tableMessages" 
              [rowHeight]="'auto'" 
              [externalPaging]="false"
              [rowClass]="rowClass"
              [scrollbarH]="scrollBarHorizontal">
              <ng-container *ngFor="let col of columns">
                  <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                      <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                          <b (click)="sort()">{{ col.name | translate }}</b>
                      </ng-template>
                      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                          <div [ngSwitch]="col.prop">
                              
                              <div *ngSwitchCase="'options'">
                                  <div class="dropdown" dropdown>
                                      <button
                                          class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton">
                                          {{ 'Select' | translate }}
                                      </button>
                                      <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                          
                                          <a 
                                              id="cron-job-scheduler-btn-show-details"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              [routerLink]="['../', row.jobId]">
                                                  {{'Edit' | translate }}
                                          </a>

                                          <a 
                                              id="cron-job-scheduler-btn-show-details"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              (click)="runCronJob(row.jobId)">
                                                  {{'Run' | translate }}
                                          </a>

                                          <a 
                                              id="cron-job-scheduler-btn-show-details"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              (click)="resumeCronJob(row.jobId)">
                                                  {{'Resume' | translate }}
                                          </a>

                                          <a 
                                              id="cron-job-scheduler-btn-show-details"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              (click)="pauseCronJob(row.jobId)">
                                                  {{'Pause' | translate }}
                                          </a>

                                          <a 
                                              id="cron-job-scheduler-btn-show-details"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              (click)="removeCronJob(row.jobId)">
                                                  {{'Remove' | translate }}
                                          </a>
  
                                      </div>
                                  </div>
                              </div>

                              <div id="cron-job-scheduler-table-col-{{col.id}}" *ngSwitchCase="'modifiedAt'">
                                {{value | date: 'd-MM-yyyy HH:mm'}}
                              </div>

                              <div id="cron-job-scheduler-table-col-{{col.id}}" *ngSwitchCase="'cronJob'">
                                {{value.toString() | translate}}
                              </div>

                              <div id="cron-job-scheduler-table-col-{{col.id}}" *ngSwitchCase="'jobStatus'">
                                  <ng-container [ngSwitch]="value">
                                      <span *ngSwitchCase="jobStatusType.Paused" class="badge badge-warning"><i-feather name="pause"></i-feather>{{ 'CronJobStatus.'+value | translate }}</span>
                                      <span *ngSwitchCase="jobStatusType.ScheduledStarted" class="badge badge-success"><i-feather name="play"></i-feather>{{ 'CronJobStatus.'+value | translate }}</span>
                                      <span *ngSwitchCase="jobStatusType.Resumed" class="badge badge-info"><i-feather name="refresh-cw"></i-feather>{{ 'CronJobStatus.'+value | translate }}</span>
                                      <span *ngSwitchDefault class="badge badge-light">{{ value }}</span>
                                  </ng-container>
                              </div>
                              <div id="cron-job-scheduler-table-col-{{col.id}}" *ngSwitchDefault>{{ value }}</div>
                          </div>

                      </ng-template>
                  </ngx-datatable-column>
              </ng-container>
          </ngx-datatable>
      </div>

      <div *ngIf="loader" class="load-spinner text-center">
          <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
      </div>
  </div>

</layout-default>

<ng-template #confirmation>
  <div class="modal-header">
      <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body text-center">
      <p>{{'Do you confirm the action' | translate }}?</p>
      <button type="button" class="btn btn-brand-01" (click)="confirm()">
          {{'Yes' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
  </div>
</ng-template>


<!-- {{ 'CronJobStatus.PAUSED' | translate }} -->
<!-- {{ 'CronJobStatus.RESUMED' | translate }} -->
<!-- {{ 'CronJobStatus.SCHEDULED & STARTED' | translate }} -->
