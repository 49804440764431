import { Component, Input, OnInit } from '@angular/core';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { DeliveryStatus, ShiftType } from '@enums/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar-overview-bar',
  templateUrl: './calendar-overview-bar.component.html',
  styleUrls: ['./calendar-overview-bar.component.scss']
})
export class CalendarOverviewBarComponent implements OnInit {
  @Input() shift: ShiftExt;

  private readonly numberDeliveriesPerVanMorning: number = 19.3;
  private readonly numberDeliveriesPerVanAfternoon: number = 20;
  private readonly numberDeliveriesPerVanEvening: number = 14;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  public flexGrowEstimatedMaxNoDeliveries(shift: ShiftExt): number {
    return (
        this.estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.shiftId.type) -
        shift.statuses[DeliveryStatus.ORDERED] -
        shift.statuses[DeliveryStatus.RESERVED] -
        shift.statuses[DeliveryStatus.ASSIGNED] -
        shift.statuses[DeliveryStatus.DONE] -
        shift.statuses[DeliveryStatus.FAILURE] -
        shift.statuses[DeliveryStatus.RETRY] -
        shift.statuses[DeliveryStatus.PENDING] -
        shift.statuses[DeliveryStatus.CUTOFF] -
        shift.statuses[DeliveryStatus.FINALIZING] -
        shift.statuses[DeliveryStatus.ARCHIVED]
    );
}

  public isLimitExceeded(shift: ShiftExt): boolean {
    return (shift.fulfilment.maximumDeliveries !== -1) ? shift.statuses['RESERVED'] + shift.statuses['ORDERED'] >= shift.fulfilment.maximumDeliveries : false;
  }

  public isEstimatedMaxNoDeliveriesExceeded(shift: ShiftExt): boolean {
    return shift.statuses['RESERVED'] + shift.statuses['ORDERED'] > this.estimatedMaxNoDeliveries(shift.fulfilment.fleetMaximum, shift.fulfilment.label as ShiftType);
  }

  public estimatedMaxNoDeliveries(maxVanNumber: number, shiftType: ShiftType): number {
    if (shiftType === ShiftType.MORNING_1) {
      return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanMorning);
    } else if (shiftType === ShiftType.AFTERNOON) {
      return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanAfternoon);
    } else if (shiftType === ShiftType.EVENING) {
      return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanEvening);
    } else {
      return Math.ceil(maxVanNumber * this.numberDeliveriesPerVanMorning);
    }
  }

  public tooltipLabel(shift: ShiftExt): string {
    const N = '\n';

    const RESERVED: string = `${shift.statuses['RESERVED'] ? this.translate.instant('Reserved deliveries') + ': ' + shift.statuses['RESERVED'] + N : ''}`;
    const ORDERED: string = `${shift.statuses['ORDERED'] ? this.translate.instant('Ordered deliveries') + ': ' + shift.statuses['ORDERED'] + N : ''}`;
    const ASSIGNED: string = `${shift.statuses['ASSIGNED'] ? this.translate.instant('Assigned deliveries') + ': ' + shift.statuses['ASSIGNED'] + N : ''}`;
    const DONE: string = `${shift.statuses['DONE'] ? this.translate.instant('Done deliveries') + ': ' + shift.statuses['DONE'] + N : ''}`;
    const FAILURE: string = `${shift.statuses['FAILURE'] ? this.translate.instant('Failure deliveries') + ': ' + shift.statuses['FAILURE'] + N : ''}`;
    const RETRY: string = `${shift.statuses['RETRY'] ? this.translate.instant('Retry deliveries') + ': ' + shift.statuses['RETRY'] + N : ''}`;
    const PENDING: string = `${shift.statuses['PENDING'] ? this.translate.instant('Pending deliveries') + ': ' + shift.statuses['PENDING'] + N : ''}`;
    const ARCHIVED: string = `${shift.statuses['ARCHIVED'] ? this.translate.instant('Archived deliveries') + ': ' + shift.statuses['ARCHIVED'] + N : ''}`;
    const CUTOFF: string = `${shift.statuses['CUTOFF'] ? this.translate.instant('Cutoff deliveries') + ': ' + shift.statuses['CUTOFF'] + N : ''}`;
    const NEW: string = `${shift.statuses['NEW'] ? this.translate.instant('New deliveries') + ': ' + shift.statuses['NEW'] + N : ''}`;
    const FINALIZING: string = `${shift.statuses['FINALIZING'] ? this.translate.instant('Finalizing deliveries') + ': ' + shift.statuses['FINALIZING'] + N : ''}`;


    return `${this.translate.instant(shift.fulfilment.shiftId.type)}${N}${RESERVED}${ORDERED}${ASSIGNED}${DONE}${FAILURE}${RETRY}${PENDING}${ARCHIVED}${NEW}${CUTOFF}${FINALIZING}${this.translate.instant(
        'Maximum Deliveries'
    )}: ${(shift.fulfilment.maximumDeliveries !== -1) ? shift.fulfilment.maximumDeliveries : this.translate.instant('Disabled')}
${this.translate.instant('Fleet size')}: ${shift.fulfilment.fleetMaximum}`;
return '';
}
}
