import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UsersRoles } from '@enums/roles';
import { RoleGuardService } from '@guards/role.guard';
import { MainComponent } from '@shared/components/main.component';

import { SimulatorComponent } from './components/simulator.component';
import { SolutionComponent } from './components/solution.component';
import { CanActivateSimulator } from './services/can-activate-simulator.service';
import { CanDeactivateSimulator } from './services/can-deactivate-simulator.service';
import { SimulationResolve } from './services/simulation.resolve';
import { ProxyComponent } from '@shared/components/proxy.component';


const simulatorRoutes: Routes = [
    {
        path: '',
        canActivate: [RoleGuardService, CanActivateSimulator],
        component: ProxyComponent,
        canDeactivate: [CanDeactivateSimulator],
        data: {
            expectedRole: [UsersRoles.ROLE_SYSADMIN]
        },
        children: [
            { path: '', component: SimulatorComponent },
            {
                path: 'solution/:simulationId',
                component: SolutionComponent,
                resolve: { simulation: SimulationResolve },
                data: {
                    breadcrumb: 'Breadcrumbs.Solution'
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(simulatorRoutes)],
    exports: [RouterModule],
    providers: []
})

export class SimulatorRouting { }
