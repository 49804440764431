import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Warehouse } from '@enums/enum';
import { NavService } from '@services/nav.sevice';
import { slideInOut } from '@shared/animations/animations';

import { Dataset } from '../entities/dataset';
import { Simulation } from '../entities/simulation';
import { SimulatorService } from '../services/simulator.service';

@Component({
    selector: 'app-simulator',
    templateUrl: './simulator.component.html',
    styleUrls: ['./simulator.component.scss'],
    animations: [slideInOut]
})
export class SimulatorComponent implements OnInit, OnDestroy {
    @ViewChild('removeDatasetConfirmation', { static: true }) public removeDatasetConfirmation: TemplateRef<any>;
    @ViewChild('renameDatasetRef', { static: true }) public renameDatasetRef: TemplateRef<any>;
    @ViewChild('deliveriesTable', { static: false }) public table: any;

    public dataset: Dataset[] = [];
    public columns: Object;
    public loader: boolean;
    public createDatasetMode = false;
    public createSimulationMode = false;
    public modifiedDatasetId = null;
    public selectedDatasetId = null;
    public selectedDatasetLabel = null;
    public datasetName: string;
    public modalRef: BsModalRef;
    public message: string;
    public modalArgs = { values: {} };
    public confirmObservable: Observable<any>;
    public tableMessages = {
        emptyMessage: this.translate.instant('No data to display')
    };
    public simulations: Simulation[] = [];
    public locale = '';
    private expandedRow: number = -1;

    constructor(
        private simulatorService: SimulatorService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private navService: NavService,
        private notifierService: NotifierService
    ) {}

    public ngOnInit() {
        this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.columns = [
            { prop: 'name', name: this.translate.instant('Label'), flexGrow: 2 },
            { prop: 'config.originStartDate', name: this.translate.instant('Origin Start Date'), flexGrow: 2 },
            { prop: 'config.originEndDate', name: this.translate.instant('Origin End Date'), flexGrow: 2 },
            { prop: 'config.originShiftType', name: this.translate.instant('Shift'), flexGrow: 2 },
            { prop: 'config.originWarehouse', name: this.translate.instant('Warehouse'), flexGrow: 2 },
            { prop: 'config.poolSize', name: this.translate.instant('Pool size'), flexGrow: 2 },
            { prop: 'options', name: this.translate.instant('Options'), flexGrow: 4 }
        ];
        this.getDatasets();
    }

    public getDatasets() {
        this.loader = true;
        this.simulatorService.getDatasets().subscribe(
            (dataset: Dataset[]) => {
                this.loader = false;
                this.dataset = dataset.reverse();
            },
            () => {
                this.loader = false;
            }
        );
    }

    public addedDataset(dataset: Dataset): void {
        this.createDatasetMode = false;
        this.modifiedDatasetId = dataset.id;
        this.getDatasets();
        setTimeout(() => (this.modifiedDatasetId = null), 4000);
    }

    public addedSimulation(simulation: Simulation): void {
        this.createSimulationMode = false;
        this.modifiedDatasetId = simulation.datasetId;
        this.getDatasets();
        setTimeout(() => (this.modifiedDatasetId = null), 4000);
    }

    public removeDataset(datasetId: string) {
        this.modalRef = this.modalService.show(this.removeDatasetConfirmation, { class: 'modal-md' });

        this.confirmObservable = this.simulatorService.removeDataset(datasetId).pipe(map(() => this.getDatasets()));
    }

    public renameDataset(datasetId: string, name: string) {
        this.modalArgs.values = { id: datasetId };
        this.datasetName = name;
        this.modalRef = this.modalService.show(this.renameDatasetRef, { class: 'modal-md' });
    }

    public renameDatasetExecute(deliveryId: string) {
        this.simulatorService.renameDataset(deliveryId, this.datasetName).subscribe(() => {
            this.getDatasets();
            this.modalRef.hide();
            this.notifierService.notify('success', this.translate.instant('New dataset name has been set successfully!'));
        });
    }

    public createSimulation(dataseId: string, label: string) {
        this.selectedDatasetId = dataseId;
        this.selectedDatasetLabel = label;
        this.createSimulationMode = true;
    }

    public columnToTranslate(name: string): boolean {
        return name === 'config.originShiftType' || name === 'config.originWarehouse';
    }

    public isOptionsColumn(name: string): boolean {
        return name === 'options';
    }

    public confirm(): void {
        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.confirmObservable = null;
        });
    }

    public toggleExpandRow(row): void {
        this.table.rowDetail.collapseAllRows();
        this.simulations = [];
        if (this.expandedRow !== row.id) {
            this.table.rowDetail.toggleExpandRow(row);
            this.expandedRow = row.id;
        } else {
            this.expandedRow = -1;
        }
    }

    public activateRow(event): void {
        const path = event.event.path ? event.event.path : event.event.composedPath();
        const isInside = path.filter(item => !_.isUndefined(item.classList) && item.classList.value.includes('btn'));

        if (event.type === 'click' && !isInside.length) {
            this.toggleExpandRow(event.row);
            this.getSimulation(event.row);
        }
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }

    public getSimulation(row: Dataset) {
        this.simulatorService.getSimulationForDataset(row.id).subscribe((simulations: Simulation[]) => {
            this.simulations = _.orderBy(simulations, (s: Simulation) => s.simulationShiftConfigDto.date);
        });
    }

    public goToSimulation(id: string) {
        this.navService.goToPage(`/simulator/solution/${id}`);
    }

    public rowClass = row => {
        return {
            'row-modified': row.id === this.modifiedDatasetId
        };
    };

    public ngOnDestroy() {
        localStorage.setItem('depot', "1");
    }
}
