<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode === 'CREATE'">
                <i-feather name="mail"></i-feather> {{'Create sms message template' | translate}}
            </h4>
            <h4 *ngIf="mode === 'UPDATE'">
                <i-feather name="mail"></i-feather> {{'Edit sms message template' | translate}}: {{message?.title}}
            </h4>
        </div>
        <hr />
    </div>
    
    <div content>
        <div *ngIf="!loader" class="content bg-white">

            <div class="card-content col-8 offset-2">

                <form [formGroup]="form" autocomplete="off">

                    <div id="sms-template-edit-title" class="form-group row">

                        <label for="depot" class="col-2">
                            <span>{{'Title' | translate}}:&nbsp;</span>
                            <span *ngIf="form.get('title') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('title')" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('title')"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-10">
                            <input id="title" formControlName="title" class="form-control" />
                            <div *ngIf="form.get('title').hasError('maxlength') && form.get('title').touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                            <div *ngIf="form.get('title').hasError('required') && form.get('title').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="sms-template-edit-type" class="form-group row">
                        <label class="col-2" for="code">{{'Type' | translate}}:&nbsp;<span *ngIf="form.get('type') | isRequiredControlPipe">*</span></label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="type">
                                <option *ngFor="let type of smsTemplatesType" [value]="type">{{'MessageTemplateType.' + type | translate}}</option>
                            </select>
                            <div *ngIf="form.get('type').hasError('required') && form.get('type').touched" class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="form.get('type').value === smsTemplatesTypeOptions.SMS" formArrayName="parameters">
                        <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Parameters' | translate}}</label>

                        <div *ngFor="let param of parameters.controls; let index=index" class="form-horizontal">
                            
                            <ng-container formGroupName="{{index}}">

                                <div id="sms-template-edit-name-{{index}}" class="form-group row">
                                    <label class="col-2" for="code">{{'Name' | translate}}:</label>
                                    <div class="col-10">
                                        <input id="name" formControlName="name" class="form-control" />
                                        
                                    </div>
                                </div>

                                <div id="sms-template-edit-code-{{index}}" class="form-group row">
                                    <label class="col-2" for="code">{{'Description' | translate}}:</label>
                                    <div class="col-10">
                                        <input id="description" formControlName="description" class="form-control" />
                                    </div>
                                </div>

                                <div class="d-flex justify-content-end mg-t-20 mg-b-20">
                                    <button id="sms-template-edit-param-add-{{index}}" *ngIf="index + 1 === parameters.controls.length" (click)="addNewParam()" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-secondary">{{'Add new parametr' | translate}}</button>
                                    <button id="sms-template-edit-param-remove-{{index}}" (click)="removeParam()" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-warning">{{'Remove parametr' | translate}}</button>
                                </div>
                            </ng-container>

                        </div>

                        <div class="d-flex justify-content-end mg-t-20 mg-b-20">
                            <button id="sms-template-edit-param-add-new" *ngIf="!parameters.controls.length" (click)="addNewParam()" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-secondary">{{'Add new parametr' | translate}}</button>
                        </div>

                    </ng-container>


                    <label class="d-block col-10 offset-2 tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-b-20">{{'Messeage body' | translate}}</label>


                    <div id="sms-template-edit-parameters" class="form-group row">
                        <label class="col-2" for="code">{{'Parameters' | translate}}:</label>
                        <div class="col-10">
                            <ul class="params-list">
                                <ng-container *ngIf="form.get('type').value === smsTemplatesTypeOptions.SMS">
                                    <li class="mg-r-10 mg-b-5" *ngFor="let param of staticParametersSms">
                                        <span (click)="pasteParam(param)">
                                                {{ 'smsTemplate.params.' + param | translate}}
                                        </span>
                                    </li>
                                </ng-container>
                                
                                <ng-container *ngIf="form.get('type').value === smsTemplatesTypeOptions.INTERNAL_NOTIFICATION">
                                    <li  class="mg-r-10 mg-b-5" *ngFor="let param of staticParametersInternal">
                                        <span (click)="pasteParam(param)">
                                                {{ 'smsTemplate.params.' + param | translate}}
                                        </span>
                                    </li>
                                </ng-container>

                                <ng-container *ngIf="form.get('type').value === smsTemplatesTypeOptions.EMAIL_NOTIFICATION">
                                    <li  class="mg-r-10 mg-b-5" *ngFor="let param of staticParametersMail">
                                        <span (click)="pasteParam(param)">
                                                {{ 'smsTemplate.params.' + param | translate}}
                                        </span>
                                    </li>
                                </ng-container>
                                
                                <ng-container *ngFor="let param of parameters.controls">
                                    <li 
                                        (click)="pasteParam(param.value.name)"
                                        *ngIf="param.value.name && param.value.description"
                                        class="mg-r-10 mg-b-5">
                                            {{param.value.name}}
                                    </li>
                                </ng-container>
                            </ul>
                            <!-- <p class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1" *ngIf="!parameters.controls.length">{{'Not defined' | translate}}</p> -->
                        </div>
                    </div>

                    <div id="sms-template-edit-message" class="form-group row">
                        <label class="col-2" for="code">{{'Message' | translate}}:</label>
                        <div class="col-10">
                            <textarea formControlName="body" rows="5" class="form-control pd-5"></textarea>
                        </div>
                    </div>

                    <div *ngIf="form.get('type').value === smsTemplatesTypeOptions.EMAIL_NOTIFICATION" class="form-group row">
                        <label class="col-2" for="code">{{'Message' | translate}} HTML:</label>
                        <div class="col-10">
                            <textarea formControlName="htmlBody" rows="5" class="form-control pd-5"></textarea>
                        </div>
                    </div>

                    <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                        <div class="col-10 offset-2">
                            <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                            <ul>
                                <li *ngFor="let error of formErrorsMessages" class="error-color">
                                    <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                    <strong>{{ error.field | translate }}</strong>
                                    <span>{{ error.value | translate }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>

    </div>
</layout-default>

<!-- {{'smsTemplate.params.curierFirstName' | translate}} -->
<!-- {{'smsTemplate.params.customerFirstName' | translate}} -->
<!-- {{'smsTemplate.params.customerFullName' | translate}} -->
<!-- {{'smsTemplate.params.customerId' | translate}} -->
<!-- {{'smsTemplate.params.customerList' | translate}} -->
<!-- {{'smsTemplate.params.customerListHtml' | translate}} -->
<!-- {{'smsTemplate.params.pickupCode' | translate}} -->


<!-- {{'MessageTemplateType.SMS' | translate }} -->
<!-- {{'MessageTemplateType.EMAIL_NOTIFICATION' | translate }} -->
<!-- {{'MessageTemplateType.INTERNAL_NOTIFICATION' | translate }} -->
