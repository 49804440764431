
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AgencyService } from '../services/agency.service';

@Injectable()
export class AgencyResolver implements Resolve<any> {
    constructor(private agencyService: AgencyService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.agencyService.findOne(params['agencyId']);
    }
}
