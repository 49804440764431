<ng-container>

    <form *ngIf="!loader" [formGroup]="form">

        <div class="form-group row">
            <label class="col-3" for="date">{{'Date' | translate}}:</label>
            <div class="col-7">
                <input type="date" formControlName="date" class="form-control" />
                <div *ngIf="form.get('date').hasError('required') && form.get('date').touched" class="error-color">
                    {{ "The field is required" | translate }}!
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-3" for="shiftPlanning">{{'Shift planning' | translate}}:</label>
            <div class="col-7">
                    <select id="shiftPlanning" class="custom-select" formControlName="shiftPlanning">
                        <option 
                        *ngFor="let shift of shiftPlanning$ | async"
                        [ngValue]="shift.id">{{shift.id}} - {{ shift.shiftName }}</option>
                    </select>
                <div *ngIf="form.get('date').hasError('required') && form.get('date').touched" class="error-color">
                    {{ "The field is required" | translate }}!
                </div>
            </div>
        </div>

    </form>
</ng-container>
