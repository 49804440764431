
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CronJobSchedulerService } from './cronjob-scheduler.service';

@Injectable()
export class CronJobSchedulerResolver implements Resolve<any> {
    constructor(private cronJobSchedulerService: CronJobSchedulerService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.cronJobSchedulerService.findOne(params['jobId']);
    }
}
