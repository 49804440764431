import { Type } from 'class-transformer';

import { serializeType } from '@shared/functions/serializeType.function';

export class Step {
    public version: number;
    public createdAt: Date;
    public modifiedAt: Date;
    public id: string;
    public order: number;
    public clazz: Clazz;
    public config: string;
}

export class Planning {
    @Type(serializeType(Step)) public steps: Step[];

    public version: number;
    public createdAt: Date;
    public modifiedAt: Date;
    public id: string;
    public name: string;

}

export enum Clazz {
    DeliveryAdjustFilter = 'DeliveryAdjustFilter',
    MinimalCostProcessor = 'MinimalCostProcessor',
    ShiftAdjustFilter = 'ShiftAdjustFilter',
}
