<ng-container *ngIf="(shift$ | async) as shift">

    <div class="simulator pd-20">
        
        <div class="d-flex justify-content-start align-items-center mg-b-30">
            
            <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]" (click)="recalculateSimulator(false)" [disabled]="recalculateLoader" class="menu__button btn btn-sm btn-uppercase btn-success mg-r-10">
                <span *ngIf="!recalculateLoader">{{ "Recalculate" | translate }}</span>
                <ng-container *ngIf="recalculateLoader">
                    <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i></div>
                </ng-container>
            </button>

            <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]" (click)="loadRoutesPlanning()" class="menu__button btn btn-sm btn-uppercase btn-primary mg-r-10">
                <span>{{ "Refresh" | translate }}</span>
            </button>

            <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]" (click)="shiftSettingsSwitcher = !shiftSettingsSwitcher" [disabled]="changeShiftSettingsModeLoader" class="menu__button btn btn-sm btn-uppercase btn-outline-dark mg-r-10">
                <span *ngIf="!changeShiftSettingsModeLoader">{{'Change shift settings' | translate}} <i-feather name="settings" [style.height.px]="10"></i-feather></span>
                <ng-container *ngIf="changeShiftSettingsModeLoader">
                    <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i></div>
                </ng-container>
            </button>

        </div>

        <ng-container *ngIf="(routesPlanningResults$ | async) as routesPlanningResults; else loader">

            <div *ngIf="shiftSettingsSwitcher" class="mg-t-10">
                <app-visualiser-simulator-shift-management
                    [shift]="shiftExt"
                    (updatedData)="updatedData($event)"></app-visualiser-simulator-shift-management>
            </div>

            <ngx-datatable 
                #table
                id="solutions" 
                class="bootstrap" 
                [rows]="routesPlanningResults" 
                [columns]="columns" 
                [groupRowsBy]="'simulationKey'"
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="false"
                [limit]="queryParams.size"
                [scrollbarH]="scrollBarHorizontal"
                [sortType]="SortType.multi"
                (page)="setPage($event)">
                    <!-- Group Header Template -->
                    <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader (toggle)="onDetailToggle($event)">
                        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                        <div class="d-flex group-header text-center">
                            <ng-container *ngIf="group.value | findBestSolutionPipe as row">
                               
                           
                            <ng-container *ngFor="let col of columns">

                                <div [style.width.%]="4.16 * col.flexGrow" class="text-center" [ngSwitch]="col.prop">
        
                                    <div *ngSwitchCase="'options'">
                                       
                            
                                        <a 
                                            class="btn btn-xs btn-uppercase btn-primary" 
                                            [routerLink]="['./' + row.simulationKey + '/solution/' + row.id + '/view']">
                                                {{'See routes' | translate }}
                                        </a>
                            
                                          
                                    </div>
                            
                                    <div *ngSwitchCase="'distance'">
                                        <strong>{{((row.distance / row.deliveryCount) / 1000).toFixed(2)}} km</strong>
                                    </div>
                            
                                    <div *ngSwitchCase="'completion'">
                                        {{row.completion | timeFormatterPipe: 'D.MM.YYYY, HH:mm'}}
                                    </div>
                            
                                    <div *ngSwitchCase="'routeCount'">
                                        {{row.routeCount}} <br /> {{row.deliveryCount}}
                                    </div>
                            
                                    <div *ngSwitchCase="'deliveryEarly'">
                                        {{row.deliveryEarly}} / {{row.deliveryLate}}
                                    </div>
                            
                                    <div *ngSwitchCase="'simulationKey'">
                                        <a
                                            [class.datatable-icon-right]="!expanded"
                                            [class.datatable-icon-down]="expanded"
                                            title="Expand/Collapse Group"
                                            (click)="toggleExpandGroup(group)">
                                            <strong>{{'Key' | translate}}: {{group.value[0].simulationKey}} </strong>
                                        </a>
                                    </div>
                            
                                    <div *ngSwitchCase="'errors'">
                                        <strong class="no-errors" [ngClass]="{'red': value}">{{row.errors}}</strong>
                                    </div>
                            
                                    <div *ngSwitchCase="'deliveryTime'">
                                        {{row.deliveryTime}} min
                                    </div>
                            
                                    <div *ngSwitchCase="'shiftStart'">
                                        {{'Start' | translate}}: {{row.shiftStart}} min <br />
                                        {{'End' | translate}}: {{row.shiftEnd}} min
                                    </div>
                            
                                    <div *ngSwitchCase="'slotStart'">
                                        {{'Start' | translate}}: {{row.slotStart}} min <br />
                                        {{'End' | translate}}: {{row.slotEnd}} min
                                    </div>
                            
                                    <div *ngSwitchCase="'description'">
                                        <ul class="text-left">
                                            <li *ngFor="let item of row.description">{{item}}</li>
                                        </ul>
                                    </div>
                            
                                    <div *ngSwitchCase="'status'">
                                        <ng-container *ngIf="(routesPlanningResults | countBySolutionStatusPipe:row.simulationKey) as result">
                                            <span [style.min-width.px]="100" *ngIf="result['COMPLETE']" class="badge badge-success justify-content-center mg-b-6">{{ 'SolutionStatus.COMPLETE' | translate }} - {{result['COMPLETE']}}</span>
                                            <span [style.min-width.px]="100" *ngIf="result['INITIALIZING']" class="badge badge-info justify-content-center mg-b-6">{{ 'SolutionStatus.INITIALIZING' | translate }} - {{result['INITIALIZING']}}</span>
                                            <span [style.min-width.px]="100" *ngIf="result['FAILED']" class="badge badge-danger justify-content-center">{{ 'SolutionStatus.FAILED' | translate }} - {{result['FAILED']}}</span>
                                        </ng-container>
                                        <!-- <ng-container [ngSwitch]="row.status">
                                            <span *ngSwitchCase="'COMPLETE'" class="badge badge-success">{{ 'SolutionStatus.'+row.status | translate }}</span>
                                            <span *ngSwitchCase="'INITIALIZING'" class="badge badge-info">{{ 'SolutionStatus.'+row.status | translate }}</span>
                                            <span *ngSwitchCase="'FAILED'" class="badge badge-danger">{{ 'SolutionStatus.'+row.status | translate }}</span>
                                            <span *ngSwitchDefault class="badge badge-info">{{ 'SolutionStatus.'+row.status | translate }}</span>
                                        </ng-container> -->
                                        <br /> 
                                        <span class="tx-11 pd-t-10">{{row.completion | timeFormatterPipe: 'D.MM.YYYY, HH:mm'}}</span>
                                    </div>
                            
                                    <div *ngSwitchCase="'address'">{{row.address | addressLabelPipe}}</div>
                                    <div *ngSwitchDefault>brak</div>
                                </div>

                             
                                
                            </ng-container>

                        </ng-container>
                        </div>
                        </ng-template>
                    </ngx-datatable-group-header>

                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="'text-center'">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <strong (click)="sort()">{{ col.name | translate }}</strong>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <ng-container *ngTemplateOutlet="tableRow; context: { col: col, row: row, value: value }"></ng-container>
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
        </ng-container>

    </div>

</ng-container>



<ng-template #loader>
    <div *ngIf="loader" class="load-spinner spinner">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        <p class="spinner__info">{{ 'Loading, routes' | translate }}: <strong>{{params?.y}}-{{params?.m}}-{{params?.d}} {{params?.shiftId | translate}}</strong></p>
    </div>
</ng-template>


<ng-template let-col="col" let-row="row" let-value="value" #tableRow>
    <div class="text-center" [ngSwitch]="col.prop">
        
        <div *ngSwitchCase="'options'">
            <div class="dropdown" dropdown>
                <button
                    class="btn btn-outline-secondary btn-xs dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton">
                    {{ 'Select' | translate }}
                </button>

                <div class="dropdown-menu dropdown-menu-right">

                    <a 
                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-remove" 
                        [routerLink]="['./' + row.simulationKey + '/solution/' + row.id + '/view']">
                            {{'See routes' | translate }}
                    </a>

                </div>
            </div>
        </div>

        <div *ngSwitchCase="'distance'">
            <strong>{{((value / row.deliveryCount) / 1000).toFixed(2)}} km</strong>
        </div>

        <div *ngSwitchCase="'completion'">
            {{value | timeFormatterPipe: 'D.MM.YYYY, HH:mm'}}
        </div>

        <div *ngSwitchCase="'routeCount'">
            {{value}} <br /> {{row.deliveryCount}}
        </div>

        <div *ngSwitchCase="'deliveryEarly'">
            {{row.deliveryEarly}} / {{row.deliveryLate}}
        </div>

        <div *ngSwitchCase="'simulationKey'">

        </div>

        <div *ngSwitchCase="'errors'">
            <strong class="no-errors" [ngClass]="{'red': value}">{{value}}</strong>
        </div>

        <div *ngSwitchCase="'deliveryTime'">
            {{value}} min
        </div>

        <div *ngSwitchCase="'shiftStart'">
            {{'Start' | translate}}: {{row.shiftStart}} min <br />
            {{'End' | translate}}: {{row.shiftEnd}} min
        </div>

        <div *ngSwitchCase="'slotStart'">
            {{'Start' | translate}}: {{row.slotStart}} min <br />
            {{'End' | translate}}: {{row.slotEnd}} min
        </div>

        <div *ngSwitchCase="'description'">
            <ul class="text-left">
                <li *ngFor="let item of value">{{item}}</li>
            </ul>
        </div>

        <div *ngSwitchCase="'status'">
            <ng-container [ngSwitch]="value">
                <span *ngSwitchCase="'COMPLETE'" class="badge badge-success">{{ 'SolutionStatus.'+value | translate }}</span>
                <span *ngSwitchCase="'INITIALIZING'" class="badge badge-info">{{ 'SolutionStatus.'+value | translate }}</span>
                <span *ngSwitchCase="'FAILED'" class="badge badge-danger">{{ 'SolutionStatus.'+value | translate }}</span>
                <span *ngSwitchDefault class="badge badge-info">{{ 'SolutionStatus.'+value | translate }}</span>
            </ng-container> <br />
            <span class="tx-11 pd-t-10">{{row.completion | timeFormatterPipe: 'D.MM.YYYY, HH:mm'}}</span>
        </div>

        <div *ngSwitchCase="'address'">{{value | addressLabelPipe}}</div>
        <div *ngSwitchDefault>{{value}}</div>
    </div>
</ng-template>


<!-- {{'SolutionStatus.INITIALIZING' | translate }} -->
<!-- {{'SolutionStatus.COMPLETE' | translate }} -->
<!-- {{'SolutionStatus.FAILED' | translate }} -->