import { Type } from 'class-transformer';

import { serializeType } from '../functions/serializeType.function';
import { Box } from './box';
import { Delivery } from './delivery';
import { RouteId } from './routeId';

export class BoxBagSearchResults {
    @Type(serializeType(Delivery)) 
    public delivery: Delivery;

    @Type(serializeType(Box)) 
    public scannedBoxes: Box[];
    
    @Type(serializeType(Box))

    public routeId: RouteId;
    public deliveryId: string;
    public box: Box;
}
