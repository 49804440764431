import { AfterContentInit, ContentChild, Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

import { DropdownMenuDirective } from './dropdown-menu.directive';
import { DropdownToggleDirective } from './dropdown-toggle.directive';

@Directive({
    selector: '[dropdown]'
})
export class DropdownDirective implements AfterContentInit {

    constructor(private elem: ElementRef) {}

    @ContentChild(DropdownToggleDirective, /* TODO: add static flag */ {read: DropdownToggleDirective, static: false})
    public toggleElem: DropdownToggleDirective;

    @ContentChild(DropdownMenuDirective, /* TODO: add static flag */ {read: DropdownMenuDirective, static: false})
    public menu: DropdownMenuDirective;

    @HostBinding('class.show')
    public opened = false;

    @HostListener('keyup.escape')
    @HostListener('document:click', ['$event'])
    public exitDropdown(event) {
        const path = (event.path) ? event.path : event.composedPath();
        const isOutside = (path.indexOf(this.elem.nativeElement) === -1);
        if (isOutside) {
            this.close();
        }
    }

    public open(): void {
        this.opened = true;
        this.menu.opened = this.opened;
    }

    public close(): void {
        this.opened = false;
        this.menu.opened = this.opened;
    }

    public toggle(): void {
        this.opened ? this.close() : this.open();
    }

    public ngAfterContentInit() {
        this.close();

        this.toggleElem.onToggle.subscribe(() => {
            this.toggle();
        });
    }
}
