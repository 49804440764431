<layout-default>
    <div header>
        <div toolbar>
            <h4> <i-feather name="activity"></i-feather> {{'Notification' | translate}}</h4>
        </div>

        <hr>
    </div>

    <div content>

        <div *ngIf="(notifications$ | async) as notifications">

            <ngx-datatable 
                id="notifications" 
                class="bootstrap" 
                [rows]="notifications" 
                [columns]="columns" 
                [columnMode]="'flex'"
                [headerHeight]="50" 
                [footerHeight]="50" 
                [messages]="tableMessages" 
                [rowHeight]="'auto'" 
                [externalPaging]="false"
                [count]="notifications.length"
                [offset]="queryParams.page"
                [rowClass]="rowClass"
                [limit]="queryParams.size"
                [scrollbarH]="scrollBarHorizontal">
                <ng-container *ngFor="let col of columns; let index = index;">
                    <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
  
                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'deepLinkType'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="'CUSTOMER'" class="badge badge-secondary">{{ 'DeepLinkType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'ROUTE'" class="badge badge-success">{{ 'DeepLinkType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'DELIVERY'" class="badge badge-info">{{ 'DeepLinkType.'+value | translate }}</span>
                                        <span *ngSwitchCase="'APM'" class="badge badge-info">{{ 'DeepLinkType.'+value | translate }}</span>

                                        <span *ngSwitchDefault class="badge badge-info">{{ 'DeepLinkType.'+value | translate }}</span>
                                    </ng-container>
                                </div>
                                <div *ngSwitchCase="'date'">
                                   {{value | date:'short'}}
                                 </div>
                                <div *ngSwitchCase="'options'">
                                   <button (click)="markAsRead(row)" class="btn btn-link btn-xs" [style.font-size.px]="11">{{'Mark as read' | translate}}</button>
                                </div>
                                <div *ngSwitchDefault>{{ value | translate }}</div>
                            </div>
  
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>
  
        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>
        

    </div>
</layout-default>

<!-- {{'DeepLinkType.CUSTOMER' | translate}} -->
<!-- {{'DeepLinkType.DELIVERY' | translate}} -->
<!-- {{'DeepLinkType.ROUTE' | translate}} -->
<!-- {{'DeepLinkType.APM' | translate}} -->

<!-- {{'NotificationEnums.APM temperature problem' | translate}} -->