import { serializeType } from '@shared/functions/serializeType.function';
import { Type } from 'class-transformer';
import { Permissions } from '@enums/permissions';


export class PlanningStep {
    public createdAt:   string;
    public modifiedAt:  string;
    public id:          string;
    public order:       number;
    public clazz:       PlanningSequenceClazz;
    public config:      string;
    public isSolver:    boolean;
    public forward:     boolean;
    public backward:    boolean;
    public isFinaliser: boolean;
}

export class PlanningSequence {
    public id:         string;
    public name:       string;
    
    @Type(serializeType(PlanningStep))
    public steps:      PlanningStep[];
    
    public version:    number;
    public createdAt:  string;
    public modifiedAt: string;
}


export interface PlannerSupported {
    clazz:       PlanningSequenceClazz;
    description: string;
    permissions: Permissions[];
    parameters:  PlannerSupportedParameter[];
}

export interface PlannerSupportedParameter {
    name:        string;
    type:        string;
    required:    boolean;
    description: string;
}

export enum PlanningSequenceClazz {
    DeliveryAdjustFilter = "DeliveryAdjustFilter",
    DeliveryClusterFilter = "DeliveryClusterFilter",
    DrivingTimeFilter = "DrivingTimeFilter",
    ShiftAdjustFilter = "ShiftAdjustFilter",
    MinimalCostProcessor = "MinimalCostProcessor",
    OrToolsProcessor = "OrToolsProcessor",
    OrToolsRemoteProcessor = "OrToolsRemoteProcessor",
    RuinAndRebuildProcessor = "RuinAndRebuildProcessor",
}

