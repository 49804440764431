import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { MomentModule } from 'ngx-moment';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { AnalyticsComponent } from './analytics.components';
import { AnalyticsRoutingModule } from './analytics.routing';
import { ChartTooltipComponent } from './components/chart-tooltip.component';
import { DriverPrintableReportComponent } from './components/punctuality/driver-printable-report.component';
import { DriverPunctualityComponent } from './components/punctuality/driver-punctuality.component';
import { RoutingEfficiencyComponent } from './components/routing-efficiency/routing-efficiency.component';
import { AnalyticsService } from './services/analytics.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgmCoreModule } from '@agm/core';
import { TooltipModule } from 'ngx-bootstrap';
import { FindByTimePeriodPipe } from './pipes/find-by-time-period.pipe';
import { RemoveTotalSeriePipe } from './pipes/remove-total-serie.pipe';
import { ChartTooltipPipe } from './pipes/chart-tooltip.pipe';
import { SortSeriesPipe } from './pipes/sort-series.pipe';
import { StatsRoutingDistancesComponent } from './components/stats-routing-distances/stats-routing-distances.component';
import { ComboChartComponent } from '@shared/components/combo-chart/combo-chart.component';
import { ComboSeriesVerticalComponent } from '@shared/components/combo-chart/combo-series-vertical.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OrderReportComponent } from './components/order-report/order-report.component';
import { environment } from '@environment';

@NgModule({
    imports: [CommonModule, SharedModule, AnalyticsRoutingModule, NgxChartsModule, AuthenticationModule, 
        MomentModule, NgxDatatableModule, 
        TooltipModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapApiKey,
            libraries: ['visualization', 'places']
        })
    ],
    declarations: [
        DriverPunctualityComponent, 
        AnalyticsComponent, 
        ChartTooltipComponent, 
        DriverPrintableReportComponent, 
        RoutingEfficiencyComponent, 
        FindByTimePeriodPipe,
        RemoveTotalSeriePipe,
        ChartTooltipPipe,
        SortSeriesPipe,
        StatsRoutingDistancesComponent,
        ComboSeriesVerticalComponent,
        ComboChartComponent,
        OrderReportComponent
    ],
    providers: [AnalyticsService, DecimalPipe]
})
export class AnalyticsModule {}
