
        <ng-container *ngIf="dayComposite">
            <div class="shifts-container" *ngIf="dayComposite.dailyShiftConfig">
                <div class="shift" *ngFor="let shiftConfig of dayComposite.dailyShiftConfig.shifts; let shiftIndex = index">
                    <div *ngIf="selectedShift == shiftConfig.fulfilment.shiftId.type">
                        <h2>{{dayComposite.date | amLocale: locale | amDateFormat:'dddd, MMMM DD'}} - {{selectedShift | translate}}</h2>

                        <div class="shift-details time-to-close-shift" *ngIf="!isConfiguring(shiftConfig.status) && timeToClose[shiftIndex]"
                             title="{{timeToClose[shiftIndex].title}}">{{timeToClose[shiftIndex].label}}</div>
                        <div class="shift-details time-to-close-shift" *ngIf="isConfiguring(shiftConfig.status) && timeToClose[shiftIndex]" title="{{timeToClose[shiftIndex].title}}">
                            {{'Shift was closed manually' | translate}}
                        </div>

                        <status-switcher 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></status-switcher>

                        <cutoff-details 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.shiftCutoffData"
                            [shiftId]="shiftConfig.integration.shiftId"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></cutoff-details>

                        <!--<deliveries-details 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment" 
                            [productLines]="shiftConfig.productLines"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            [skuCount]="shiftConfig.skuCount"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></deliveries-details>-->

                        <vans-details 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></vans-details>

                        <shift-edges 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-edges>

                        <shift-fulfilment
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-fulfilment>

                        <shift-integration
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_integration_view]"
                            [shiftConfig]="shiftConfig.integration"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-integration>

                        <shift-routing
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_routing_view]"
                            [shiftConfig]="shiftConfig.routing"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-routing>
                        
                    </div>
                </div>
            </div>
        </ng-container>      