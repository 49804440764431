<layout-default>
  <div header>
      <div toolbar>
          <h4 *ngIf="mode === 'CREATE' else update">
              <i-feather name="package"></i-feather> {{'Create virtual depot' | translate}}
          </h4>
          <ng-template #update>
              <h4 *ngIf="mode === 'UPDATE'">
                  <i-feather name="package"></i-feather> {{'Edit virtual depot' | translate}}
              </h4>
          </ng-template>
      </div>

      <hr />
  </div>
  
  <div content>

          <div *ngIf="!loader" class="content bg-white">

              <div class="card-content col-8 offset-2">

                  <form [formGroup]="form">

                      <div *ngFor="let input of regularControls" [id]="'input-' + input" class="form-group row">

                          <label [for]="input" class="col-3">
                              <span>{{'VIRTUAL_DEPOT.Inputs.' + input | translate}}:&nbsp;</span>
                              <span *ngIf="form.get(input) | isRequiredControlPipe">*</span>&nbsp;
                              <i-feather *ngIf="tooltipLabel(input)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('code')"
                                  [style.width.px]="13" name="info"></i-feather>
                          </label>

                          <div class="col-9">
                              <input type="text" [id]="input" [formControlName]="input" class="form-control" />
                              <div *ngIf="form.get(input).hasError('maxlength') && form.get(input).touched" class="error-color">
                                  {{ "The field value is too long" | translate }}!
                              </div>
                          </div>
                      </div>


                      <div *ngFor="let input of numberControls" [id]="'input-' + input" class="form-group row">

                        <label [for]="input" class="col-3">
                            <span>{{'VIRTUAL_DEPOT.Inputs.' + input | translate}}:&nbsp;</span>
                            <span *ngIf="form.get(input) | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel(input)" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('code')"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <div class="col-9">
                            <input type="number" [id]="input" [formControlName]="input" class="form-control" />
                            <div *ngIf="form.get(input).hasError('maxlength') && form.get(input).touched" class="error-color">
                                {{ "The field value is too long" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="input-active" class="form-group row">
                        <label class="col-3" for="active">{{'Active' | translate}}:</label>
                        <div class="col-9">
                            <select id="active" class="custom-select" formControlName="active">
                                <option [value]="true">{{'Yes' | translate}}</option>
                                <option [value]="false">{{'No' | translate}}</option>
                            </select>

                            <div *ngIf="form.get('active')?.hasError('required') && form.get('active')?.touched" class="error-color">
                                    {{ "Lift field is required" | translate }}!
                            </div>
                        </div>
                    </div>   

                    <div id="input-filterStrategy" class="form-group row">
                        <label class="col-3" for="filterStrategy">{{'Filter strategy' | translate}}:</label>
                        <div class="col-9">
                            <select id="filterStrategy" class="custom-select" formControlName="filterStrategy">
                                <option *ngFor="let option of filterStrategyType" [value]="option">{{'FilterStrategy.' + option | translate}}</option>
                            </select>

                            <div *ngIf="form.get('filterStrategy')?.hasError('required') && form.get('filterStrategy')?.touched" class="error-color">
                                    {{ "Lift field is required" | translate }}!
                            </div>
                        </div>
                    </div>   
                    

                      <div id="input-allowed-zip" class="form-group row zip-codes">

                          <label class="col-3" for="code">{{'allowedZipCodes' | translate}}:</label>
                        

                          <div class="col-9">
                              <ng-container formArrayName="allowedZipCodes">
                                  <div *ngFor="let control of allowedZipCodesControl.controls; let index=index" class="form-horizontal">
                                      <input [formControlName]="index" [dropSpecialCharacters]="false" mask="99-999" class="form-control" />
                                      <button (click)="removeAllowedZipCodes(index)" class="btn btn-xs d-inline btn-white remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                                      <button *ngIf="index === allowedZipCodesControl.controls.length - 1" (click)="addAllowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>
                                  </div>
                                  <button *ngIf="!allowedZipCodesControl.controls.length" (click)="addAllowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>

                              </ng-container>
                          </div>
                      </div>

                      <div id="input-disallowed-zip" class="form-group row zip-codes">
                          <label class="col-3" for="code">{{'disallowedZipCodes' | translate}}:</label>
                          <div class="col-9">
                              <ng-container formArrayName="disallowedZipCodes">
                                  <div *ngFor="let control of disallowedZipCodesControl.controls; let index=index" class="form-horizontal">
                                      <input [formControlName]="index" [dropSpecialCharacters]="false" mask="99-999" class="form-control" />
                                      <button (click)="removeDisallowedZipCodes(index)" class="btn btn-xs d-inline btn-white remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                                      <button *ngIf="index === disallowedZipCodesControl.controls.length - 1" (click)="addDisallowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>
                                  </div>
                                  <button *ngIf="!disallowedZipCodesControl.controls.length" (click)="addDisallowedZipCodes()" class="btn btn-xs d-inline btn-warning remove pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="plus"></i-feather></button>

                              </ng-container>
                          </div>
                      </div>

                      <ng-container formGroupName="deliveryTimeAdjustment">
                            <div class="row">
                                <div class="d-block col-9 offset-2">
                                    <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20 pd-b-20">{{'Delivery time informations' | translate}}</label>
                                </div>
                            </div>
                            
                            <div *ngFor="let input of deliveryTimeAdjustmentControls" [id]="'input-' + input" class="form-group row">

                                <label [for]="input" class="col-3">
                                    <span>{{'VIRTUAL_DEPOT.Inputs.' + input | translate}}:&nbsp;</span>
                                </label>
        
                                <div class="col-9">
                                    <input type="number" [id]="input" [formControlName]="input" class="form-control" />
                                </div>
                            </div>
                        </ng-container>

                      <ng-container formGroupName="coordinates">
                          <div id="btn-geocode" class="row">
                              <div class="d-block col-9 offset-2">
                                  <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Depot coordinates' | translate}}</label>
                                  <button class="btn btn-sm btn-white btn-geocode" (click)="getCoordinates()">{{'Geocode address' | translate}}</button>
                              </div>
                          </div>
                          
                          <div id="map-geocoded" class="row">
                              <div class="col-10 offset-2 mg-t-20 mg-b-20">
                                  <gmap *ngIf="coordinatesRaw" (coords)="changeCoordinates($event)" [locations]="coordinatesRaw" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
                              </div>
                          </div>
                          <div id="input-{{item}}" *ngFor="let item of coordinatesKeys" class="form-group row">
                              <label class="col-3" for="code">{{item.toUpperCase() | translate}}:</label>
                              <div class="col-9">
                                  <input [id]="item" [formControlName]="item" class="form-control" />
                                  <div *ngIf="form.get('coordinates').get(item)?.hasError('required') && form.get('coordinates').get(item)?.touched" class="error-color">
                                      {{ "The field is required" | translate }}!
                                  </div>
                              </div>
                          </div>
                      </ng-container>

                      <ng-container formGroupName="testLocations">
                          <div class="row">
                              <div class="d-block col-9 offset-2">
                                  <label class="d-block tx-sans tx-10 tx-semibold tx-uppercase tx-color-03 tx-spacing-1 text-center mg-r-20">{{'Location to test booking availability (click on the map)' | translate}}</label>
                              </div>
                          </div>
                          
                          <div id="map-markers" class="row">
                              <div class="col-10 offset-2 mg-t-20 mg-b-20">

                                  <agm-map #map
                                      [latitude]="coordinatesRaw.lat"
                                      [longitude]="coordinatesRaw.lng"
                                      [zoomControl]="true"
                                      [zoom]="10"
                                      [mapTypeControl]="true"
                                      [usePanning]="true"
                                      (mapClick)="placeMarker($event)"
                                      [fitBounds]="bounds">

                                      <ng-container *ngFor="let marker of testLocationsControl.value; let index = index;">
                                          <agm-marker [latitude]="marker.lat" [longitude]="marker.lng">
                                              <agm-info-window>
                                                  <button (click)="removeMarker(marker, index)" class="btn btn-xs btn-white">Usuń marker</button>
                                              </agm-info-window>
                                          </agm-marker>
                                      </ng-container>
                                  </agm-map>
                              </div>
                          </div>
                      

                      </ng-container>

                      <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                          <div class="col-9 offset-2">
                              <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                              <ul>
                                  <li *ngFor="let error of formErrorsMessages" class="error-color">
                                      <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                      <strong>{{ error.field | translate }}</strong>
                                      <span>{{ error.value | translate }}</span>
                                  </li>
                              </ul>
                          </div>
                      </div>

                      <div class="form-group row justify-content-end">
                          <div class="col-3">
                              <button id="btn-submit" (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                  class="btn btn-sm pd-x-15 btn-submit btn-uppercase mg-l-5 btn-brand-01 w-100">

                                  <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                  <ng-container *ngIf="submitted">
                                      <div class="load-spinner text-center">
                                          <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                      </div>
                                  </ng-container>

                              </button>
                          </div>
                      </div>

                  </form>
              </div>
          </div>
      
  </div>
</layout-default>

<!-- {{'VIRTUAL_DEPOT.Inputs.code' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.name' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.drivingTime' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.latFrom' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.latTo' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.lngFrom' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.lngTo' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.parkingTime' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.parkingTimeThreshold' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.initialTime' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.boxGroupTime' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.boxGroupSize' | translate}} -->
<!-- {{'VIRTUAL_DEPOT.Inputs.paymentTime' | translate}} -->


<!-- {{'FilterStrategy.AREA' | translate}} -->
<!-- {{'FilterStrategy.ZIP' | translate}} -->
<!-- {{'FilterStrategy.BOTH' | translate}} -->
<!-- {{'FilterStrategy.EITHER' | translate}} -->
