import * as _ from 'lodash';

import { ComplaintType } from '@enums/enum';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class Complaint extends Entity implements Serializable<Complaint> {
    public id: string;
    public createdAt: string;
    public modifiedAt: string;
    public quantity: number;
    public type: string;
    public complaint: ComplaintType;
    public complaintAmount: number;
    public filename: string[];
    public version: number;

    public deserialize(data: any, price?: number) {
        this.id = _.get(data, 'id');
        this.createdAt = _.get(data, 'createdAt');
        this.modifiedAt = _.get(data, 'modifiedAt');
        this.quantity = _.get(data, 'quantity');
        this.filename = _.get(data, 'filename');
        this.type = _.get(data, 'type');
        this.complaint = _.get(data, 'complaint', ComplaintType.OTHER);
        this.version = _.get(data, 'version');

        this.complaintAmount = this.quantity * price;

        return this;
    }

    public serialize() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            modifiedAt: this.modifiedAt,
            quantity: this.quantity,
            type: this.type,
            filename: this.filename,
            complaint: this.complaint,
            version: this.version
        };
    }
}
