export class Licensing {

    public deliveriesCount: number = 0;
    public deliveriesFee: number = 0;
    public usersCount: number = 0;
    public usersFee: number = 0;
    public mobileAppCount: number = 0;
    public mobileAppFee: number = 0;
    public total: number = 0;
}

