
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <ng-container *ngIf="!submitted">
                <div class="form-group">
                    <select class="form-control form-control-xs text-center custom-select" formControlName="status" [ngClass]="form.value.status">
                        <option *ngFor="let status of reviewStatusOptions" [value]="status" class="{{ status }}">{{ status | translate }}</option>
                    </select>
                </div>

                <div *ngIf="hasDiscrepancies()" class="form-group">
                    <input currencyMask formControlName="collectedCashByDriver" placeholder="{{ 'Sum of cash collected by driver' | translate }}" class="form-control" />
                </div>

                <div *ngIf="hasDiscrepancies()" class="form-group">
                    <input currencyMask formControlName="collectedCouponsByDriver" placeholder="{{ 'Sum of coupons value collected by driver' | translate }}" class="form-control" />
                </div>

                <div *ngIf="isApprovedPayment()" class="form-group">
                    <textarea formControlName="comment" class="form-control" placeholder="{{ 'Comment' | translate }}"></textarea>
                </div>

                <div *ngIf="isApprovedPayment()" class="form-group">
                    <button [disabled]="loading" class="w-100 btn btn btn-secondary btn-sm">{{ 'Save' | translate }}</button>
                </div>
            </ng-container>

            <ng-container *ngIf="submitted">
                <div class="load-spinner text-center">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                </div>
            </ng-container>
        </form>
    