
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ProfanityFilterService } from '../services/profanity-filter.service';

@Injectable()
export class ProfanityFilterResolver implements Resolve<any> {
    constructor(private service: ProfanityFilterService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.service.findOne(params['id']);
    }
}
