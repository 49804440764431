import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { PermissionGuardService } from '@guards/permission.guard';
import { ShiftPlanningComponent } from './components/shift-planning/shift-planning.component';
import { ShiftPlanningEditComponent } from './components/shift-planning-edit/shift-planning-edit.component';
import { ProxyComponent } from '@shared/components/proxy.component';
import * as P from '@enums/permissions';
import { ShiftPlanningEditNewComponent } from './components/shift-planning-edit-new/shift-planning-edit-new.component';
import { ShiftPlanningEditFulfilmentComponent } from './components/shift-planning-edit-fulfilment/shift-planning-edit-fulfilment.component';
import { ShiftPlanningEditBasicComponent } from './components/shift-planning-edit-basic/shift-planning-edit-basic.component';
import { ShiftPlanningEditIntegrationComponent } from './components/shift-planning-edit-integration/shift-planning-edit-integration.component';
import { ShiftPlanningEditRoutingComponent } from './components/shift-planning-edit-routing/shift-planning-edit-routing.component';
import { AuditLogComponent } from '@shared/components/audit-log/audit-log.component';
import { ShitPlanningEditCutoffComponent } from './components/shit-planning-edit-cutoff/shit-planning-edit-cutoff.component';
import { CalendarOverviewComponent } from './components/overview/calendar-overview/calendar-overview.component';

const calendarRoutes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        canActivate: [PermissionGuardService],
        data: {
            expectedPermission: [P.Permissions.Calendar_view]
        },
        children: [
            {
                path: 'month', 
                component: CalendarComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Calendar'
                }
            },
            {
                path: 'shift-planning', 
                component: ShiftPlanningComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.ShiftPlanning'
                }
            },
            {
                path: 'overview', 
                component: CalendarOverviewComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Calendar'
                }
            },
            // {
            //     path: 'shift-planning/create/:id', 
            //     component: ShiftPlanningEditComponent,
            //     data: {
            //         breadcrumb: 'Breadcrumbs.ShiftPlanningEdit'
            //     },
            // },
            {
                path: 'shift-planning/auditlog/:id/:type', 
                component: AuditLogComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.AuditLog'
                },
            },
            {
                path: 'shift-planning/:action/:id', 
                component: ShiftPlanningEditNewComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.ShiftPlanningEdit'
                },
                children: [
                    
                    {
                        path: 'step-1', 
                        component: ShiftPlanningEditBasicComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ShiftPlanningEditBasic'
                        }
                    },
                    {
                        path: 'step-2', 
                        component: ShiftPlanningEditFulfilmentComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ShiftPlanningEditFulfilment'
                        }
                    },
                    {
                        path: 'step-3', 
                        component: ShiftPlanningEditIntegrationComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ShiftPlanningEditIntegration'
                        }
                    },
                    {
                        path: 'step-4', 
                        component: ShitPlanningEditCutoffComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ShiftPlanningEditCutoffs'
                        }
                    },
                    {
                        path: 'step-5', 
                        component: ShiftPlanningEditRoutingComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ShiftPlanningEditRouting'
                        }
                    },
                    {
                        path: '**', redirectTo: 'step-1'
                    },
                ]
            },
            {path: '**', redirectTo: 'overview'}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(calendarRoutes)],
    exports: [RouterModule],
    providers: []
})

export class CalendarRouting { }
