import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Location } from '@entities/location';
import { PageableResponse } from '@entities/pagable-response';
import { environment } from '@environment';

@Injectable()
export class LocationsService {
    public static LOCATIONS: string = 'location/v1/status/${status}?size=${size}&sort=${sort}&order=${order}&page=${page}';
    public static LOCATION: string = 'location/v1/${id}';
    public static UPDATE_DELIVERY_LOCATION: string = 'delivery/v1/${id}/location';
    public static UPDATE_LOCATION_GLOBALLY: string = 'location/v1/${id}';

    private TAG = '[LocationsService]';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(private http: HttpClient) {}

    /**
     * Get locations from backend
     * @param status
     * @param page
     * @param size
     * @param sort
     * @param order
     *
     * @return Observable<PageableResponse<Location>> The Paged Result of locations
     */
    public getLocations(status: string, page: number, size: number, sort: string, order: string): Observable<PageableResponse<Location>> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${LocationsService.LOCATIONS}`, { status, page, size, sort, order });

        return this.http.get(endpoint).pipe(
            map((response: any) => {
                const pageableResponse = new PageableResponse<Location>(Location);

                pageableResponse.contentItem = new Location();
                pageableResponse.deserialize(response);

                console.log(`${this.TAG} getLocations`, pageableResponse);

                return pageableResponse;
            })
        );
    }

    /**
     * Get location by id
     *
     * @param id The location Id
     * @return Observable<Location> The result
     */
    public getLocation(id: string): Observable<Location> {
        if (!id) {
            throw new Error('Empty LocationId');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${LocationsService.LOCATION}`, { id: id });

        return this.http.get(endpoint).pipe(
            map(response => {
                return new Location().deserialize(response);
            })
        );
    }

    /*public createLocation(location: Location): Observable<Location> {
        let endpoint = `${this.host}${this.prefix}/${LocationsService.LOCATIONS}`;

        let locationData = location.serialize();
        // delete locationData.id;

        return this.http.post(endpoint, locationData)
            .map((response) => {
                let location = new Location().deserialize(response);
                return location;
            });
    }*/

    /**
     * Update specific location
     *
     * @param location
     * @return Observable<Location> Observable of Updated Location
     */
    public updateDeliveryLocation(deliveryId: string, location: Location): Observable<Location> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${LocationsService.UPDATE_DELIVERY_LOCATION}`, { id: deliveryId });

        // const locationData = location.serialize();
        // locationData.additional['accesskey'] = location.additional['location'];

        return this.http.put(endpoint, location).pipe(
            map(response => {
                location = new Location().deserialize(response);
                return location;
            })
        );
    }

    /**
     * Update location address globally
     *
     * @param location
     * @return Observable<Location> Observable of Updated Location
     */
    public updateLocationGlobally(deliveryId: string, location: Location): Observable<Location> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${LocationsService.UPDATE_LOCATION_GLOBALLY}`, { id: deliveryId });

        // const locationData = location.serialize();
        // locationData.additional['accesskey'] = location.additional['location'];

        return this.http.put(endpoint, location).pipe(
            map(response => {
                location = new Location().deserialize(response);
                return location;
            })
        );
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
