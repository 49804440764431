import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormArray, AbstractControl, FormBuilder } from '@angular/forms';
import { Agency } from '../../interfaces/agency';
import { Multiplier } from '../../interfaces/multiplier';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { MultiplierType } from '../../enums/multiplier-type.enum';
import * as _ from 'lodash';
import { AgencyService } from '../../services/agency.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CRUD_MODE } from '@enums/enum';
import { SalaryReport } from '../../interfaces/salary-report';
import { SalaryReportService } from '../../services/salary-report.service';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.scss']
})
export class ReportEditComponent implements OnInit {

  public report: SalaryReport;
  public multiplierForm: FormArray;
  public rawApms: any[] = [];
  public loader: boolean = true;
  public submitted: boolean = false;
  public form: FormGroup;
  public mode: CRUD_MODE;
  public multiplierTypes = _.values(MultiplierType);

  constructor(
    private readonly reportService: SalaryReportService,
    private readonly formBuilder: FormBuilder,
    private readonly notifierService: NotifierService,
    private readonly translateService: TranslateService,
    private readonly navService: NavService,
    private readonly navigationRoute: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.report = this.navigationRoute.snapshot.data.report as SalaryReport;
    this.mode = CRUD_MODE.UPDATE;
    this.initForm();
  }  

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: [this.report ? this.report.id : null],
      name: [this.report ? this.report.name : null, Validators.required],
      baseDeliveryFeeGross: [this.report ? this.report.baseDeliveryFeeGross : null, Validators.required],
      baseDeliveryFeeNet: [this.report ? this.report.baseDeliveryFeeNet : null, Validators.required],
      baseMonthlySalaryGross: [this.report ? this.report.baseMonthlySalaryGross : null, Validators.required],
      baseMonthlySalaryNet: [this.report ? this.report.baseMonthlySalaryNet : null, Validators.required],
      multiplierList: this.formBuilder.array([]),
      version: [this.report ? this.report.version : null]
    })

    this.multiplierForm = <FormArray>this.form.controls['multiplierList'];
    if (this.report && this.report.multiplierList.length) {
      this.report.multiplierList.forEach((item) => this.multiplierForm.push(this.patchValues(item)));
    } else {
      this.multiplierTypes.forEach(type => this.multiplierForm.push(this.patchValues({factor: null, rangeFrom: null, rangeTo: null, type: type} as Multiplier)));
    }

    console.log(this.form);
    this.loader = false;
  }

  private patchValues(item?: Multiplier): AbstractControl {
    return this.formBuilder.group({
      factor: [{value: item ? item.factor : null, disabled: item.factor !== null ? true : false}],
      rangeFrom: [{value: item ? item.rangeFrom : null, disabled: item.rangeFrom !== null ? true : false}],
      rangeTo: [{value: item ? item.rangeTo : null, disabled: item.rangeTo !== null ? true : false}],
      type: [{value: item ? item.type : null, disabled: false}],
    })
  }

  public addMultiplier(type: MultiplierType): void {
    this.multiplierForm.push(this.patchValues({factor: null, rangeFrom: null, rangeTo: null, type: type} as Multiplier));
  }

  public removeMultiplier(i: number): void {
    this.multiplierForm.removeAt(i);
  }
 
  public onSubmit() {
    if(this.form.invalid) {
      this.notifierService.notify('warning', this.translateService.instant('The form is invalid'));
      console.log(this.form);
      return;
    }

    this.update();
  }

  private update() {
    const form = this.form.getRawValue() as SalaryReport;
    console.log(this.report);
    form['parentId'] = this.report.parent.id;
    delete form['parent'];

    form['multiplierList'] = form.multiplierList.filter((multiplier: Multiplier) => multiplier.factor && multiplier.rangeFrom && multiplier.rangeTo && multiplier.type);
    const body: string[] = [];

    this.reportService.update(form['id'], form, {})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (salaryReport: SalaryReport) => {
          this.notifierService.notify('success', this.translateService.instant('Salary report has been updated!'));
          this.navService.goToPage(`/salary/report/list`, {modifiedRow: salaryReport.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }
}
