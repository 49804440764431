import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';

import { map } from 'rxjs/operators';

import { User } from '@entities/user';
import { NavService } from '@shared/services/nav.sevice';

import { AuthenticationService } from '../../authentication.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuardService implements CanActivate, CanActivateChild {
    private currentUser: User;

    constructor(private navService: NavService, private auth: AuthenticationService) {
        this.auth.currentUser
            .pipe(
                map((user: User) => {
                    this.currentUser = user;
                })
            )
            .subscribe();
    }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole = route.data.expectedRole;

        if (this.auth.checkPrivileges(expectedRole)) {
            return true;
        }
        this.navService.goToPage('/login');
        return false;
    }

    public canActivateChild(): boolean {
        return !!this.currentUser;
    }
}
