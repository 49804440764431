import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppLang } from '@enums/enum';

@Component({
    selector: 'print-l',
    encapsulation: ViewEncapsulation.None,
    template: `
        <router-outlet></router-outlet>
    `,
    styleUrls: ['printable-landscape.component.scss']
})
export class PrintableLandscapeComponent {
    private readonly tag = '[PrintableLandscapeComponent]';

    constructor(private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => moment.locale(event.lang));
        const language = localStorage.getItem('lang') || AppLang.PL;
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
    }
}
