

    <layout-default>
        <div header>
            <div toolbar>
                <h4>
                    <i-feather name="dollar-sign"></i-feather> {{'Salary reports' | translate}}
                </h4>
            </div>
    
            <div toolbar>
                <button class="btn btn-sm btn-uppercase btn-white"
                    [routerLink]="['/salary/report/create/0']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add report' | translate }}
                </button>
                <button class="btn btn-sm btn-uppercase btn-white"
                    [routerLink]="['/salary/agency/list']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="user-plus"></i-feather> {{ 'Agencies' | translate }}
                </button>
                <div class="filter-container">
                    <div class="phrase-filter">
                        <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        
        <div content>
            <div *ngIf="(reportsSubscriptions$ | async) as collectionReports">
                <div *ngIf="collectionReports.totalElements ; else notFound">
                    <ngx-datatable 
                        id="agencies" 
                        class="bootstrap" 
                        [rows]="reports" 
                        [columns]="columns" 
                        [columnMode]="'flex'"
                        [headerHeight]="50" 
                        [footerHeight]="50" 
                        [rowHeight]="'auto'" 
                        [externalPaging]="true"
                        [count]="collectionReports.totalElements" 
                        [offset]="queryParams.page" 
                        [rowClass]="rowClass"
                        [limit]="pagination.limit" 
                        [scrollbarH]="scrollBarHorizontal"
                        [externalSorting]="true"
                        (sort)="onSort($event)"
                        (page)="setPage($event)">

                        <ng-container *ngFor="let col of columns">
                            <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                                <ng-template let-column="column" class="header" let-sort="sortFn" ngx-datatable-header-template>
                                    <span class="tx-bold " (click)="sort($event)">{{col.name | translate}}</span>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                

                                    <ng-container [ngSwitch]="col.prop">
                                        <div *ngSwitchCase="'name'">
                                            {{ value }}
                                        </div>
                                        <div *ngSwitchCase="'driverIdSet.length'">
                                            {{ value }}
                                        </div>
                                        <div *ngSwitchCase="'status'">
                                            <ng-container [ngSwitch]="value">
                                                <span *ngSwitchCase="'NOT_GENERATED'" class="badge badge-warning">{{ 'SalaryReportStatus.'+value | translate }}</span>
                                                <span *ngSwitchCase="'IN_PROGRESS'" class="badge badge-info">{{ 'SalaryReportStatus.'+value | translate }}</span>
                                                <span *ngSwitchCase="'GENERATED'" class="badge badge-success">{{ 'SalaryReportStatus.'+value | translate }}</span>
                                                <span *ngSwitchDefault class="badge badge-info">{{ 'SalaryReportStatus.'+value | translate }}</span>
                                            </ng-container>
                                        </div>
                                        <div *ngSwitchCase="'options'">
                                            <div class="dropdown" dropdown>
                                                <button
                                                    class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton">
                                                    {{ 'Select' | translate }}
                                                </button>
            
                                                <div class="dropdown-menu dropdown-menu-right">
        
                                                    <a 
                                                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                        [routerLink]="['../edit', row.id]">
                                                            {{'Edit' | translate }}
                                                    </a>

                                                    <a 
                                                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                        [routerLink]="['../', row.id, 'assigning-drivers']">
                                                            {{'Assign drivers' | translate }}
                                                    </a>

                                                    <a 
                                                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                        *ngIf="row.status !== 'GENERATED'"
                                                        (click)="generateRaport(row.id)">
                                                            {{'Generate' | translate }}
                                                    </a>

                                                    <a 
                                                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                        *ngIf="row.status === 'GENERATED'"
                                                        (click)="createNewRevision(row.id)">
                                                            {{'Generate next revision' | translate }}
                                                    </a>

                                                    <a 
                                                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                        *ngIf="row.status === 'GENERATED'"
                                                        [routerLink]="['../', row.id, 'files']">
                                                            {{'Show generated files' | translate }}
                                                    </a>
            
                                                    <a 
                                                        class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                        (click)="removeSalaryreport(row.id)">
                                                            {{'Remove' | translate }}
                                                    </a> 
            
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngSwitchDefault>
                                            {{ value | number:'.2' }} {{'PLN' | translate}}
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                    </ngx-datatable>
                </div>

                <ng-template #notFound>
                    <section class="empty-list">
                    <figure>
                        
                        <figcaption>
                            <p class="text-center tx-12 pd-30"><i-feather name="alert-circle"></i-feather> {{'No report reports found' | translate}}!</p>
                        </figcaption>
                    </figure>
                    </section>
                </ng-template>
        
                <div *ngIf="loader" class="load-spinner text-center">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
                </div>
        
            </div>
        </div>
</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the salary report' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<!-- {{'SalaryReportStatus.NOT_GENERATED' | translate}} -->
<!-- {{'SalaryReportStatus.IN_PROGRESS' | translate}} -->
<!-- {{'SalaryReportStatus.GENERATED' | translate}} -->