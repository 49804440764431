import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { plainToClass } from 'class-transformer';
import { PlanningSequence } from '../entities/planning-sequence';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanningSequenceService {

  private static PLANNING: string = 'planner/v2/';
  private static PLANNING_SINGLE: string = 'planner/v2/${id}';
  private static PLANNER_SUPPORTED: string = 'planner/v2/supported';

  private readonly host = environment.api.url;
  private readonly prefix = environment.api.prefix;
  
  constructor(private http: HttpClient, private utilsService: UtilsService) { }

  public getPlanningSequences() {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PlanningSequenceService.PLANNING}`, {});
    
    return this.http.get(endpoint).pipe(
      map((response) => plainToClass(PlanningSequence, response as PlanningSequence[]))
    );
  }

  public getPlannerSuported() {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PlanningSequenceService.PLANNER_SUPPORTED}`, {});
    return this.http.get(endpoint);
  }

  public getPlanningSequence(id: string) {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PlanningSequenceService.PLANNING_SINGLE}`, {id: id});
  
    return this.http.get(endpoint).pipe(
      map((response) => plainToClass(PlanningSequence, response as PlanningSequence))
    );
  }


  public updatePlanning(form: Object): Observable<PlanningSequence> {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PlanningSequenceService.PLANNING_SINGLE}`, {id: form['id']});
  
    return this.http.put(endpoint, form).pipe(
      map((response) => plainToClass(PlanningSequence, response as PlanningSequence))
    );
  }

  public createPlanning(form: Object): Observable<PlanningSequence> {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PlanningSequenceService.PLANNING}`, {});
    return this.http.post(endpoint, form).pipe(
      map((response) => plainToClass(PlanningSequence, response as PlanningSequence))
    );
  }

  public removePlanning(id: string) {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${PlanningSequenceService.PLANNING_SINGLE}`, {id});
    return this.http.delete(endpoint).pipe(
      map((response) => response)
    );
  }

}
