<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="map"></i-feather> {{'Route details' | translate}}
                <span>{{route?.routeLabel}} </span>
            </h4>
        </div>
        <hr>
    </div>

    <div content>
        <ng-container *ngIf="(warehouseDictionary$ | async) as warehouse">
            <agm-map 
                *ngIf="path && minimumClusterSize | async" 
                [zoomControl]="true" 
                [zoom]="zoom" 
                [latitude]="warehouse.locationCoordinates.lat" 
                [longitude]="warehouse.locationCoordinates.lng" 
                [usePanning]="true">

                <agm-polyline [strokeColor]="000000" strokeWeight="1" [strokeOpacity]="0.5">
                    <!-- Warehouse Marker -->
                    <!-- <agm-polyline-point [latitude]="warehouse.location.coordinates.lat" [longitude]="warehouse.location.coordinates.lng"></agm-polyline-point> -->

                    <ng-container *ngIf="path">
                        <agm-polyline-point *ngFor="let point of path.points; let hi = index;"
                                            [latitude]="point.lat" [longitude]="point.lng">
                        </agm-polyline-point>
                    </ng-container>

                    <!-- Warehouse Marker -->
                    <!-- <agm-polyline-point [latitude]="warehouse.location.coordinates.lat" [longitude]="warehouse.location.coordinates.lng"></agm-polyline-point> -->

                </agm-polyline>

                <ng-container *ngIf="client === inpostClient">
                    <ng-container *ngIf="path">
                        <agm-marker-cluster [zoomOnClick]="false" [minimumClusterSize]="minimumClusterSize | async" imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                            <agm-marker *ngFor="let point of path.points; let hi = index;"
                                [latitude]="point.lat" [longitude]="point.lng"
                                iconUrl="{{markerPath}}/{{point.icon}}"
                                (clusterClick)="test()"
                                [label]="point.label"
                                [title]="point.slot">
                                <agm-info-window [isOpen]="point.isOpen">
                                    {{point.description}}
                                </agm-info-window>
                            </agm-marker>
                        </agm-marker-cluster>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="client === friscoClient">
                    <ng-container *ngIf="path">
                        <agm-marker *ngFor="let point of path.points; let hi = index;"
                            [latitude]="point.lat" [longitude]="point.lng"
                            iconUrl="{{markerPath}}/{{point.icon}}"
                            (clusterClick)="test()"
                            [label]="point.label"
                            [title]="point.slot">
                            <agm-info-window [isOpen]="point.isOpen">
                                {{point.description}}
                            </agm-info-window>
                        </agm-marker>
                    </ng-container>
                </ng-container>

                <!-- Warehouse Frisco Marker -->
                <!-- <agm-marker [latitude]="warehouse.location.coordinates.lat" [longitude]="warehouse.location.coordinates.lng"
                            iconUrl="{{markerPath}}/{{client}}Marker.png"></agm-marker> -->
            </agm-map>
        </ng-container>
    </div>
</layout-default>
