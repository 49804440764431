import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { ShiftConfig } from './entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { ShiftSettingsService } from './services/shift-settings.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Fulfilment } from './interafces/fulfilment.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IShiftStatus } from './interafces/shift-status.interface';
import { ShiftStatus } from '@enums/enum';

@Component({
    selector: 'shift-fulfilment',
    template: `
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Fulfilment' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_integration_edit]" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">
                <div *ngFor="let control of numberControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control] }}</div>
                </div>

                <h4 class="mg-t-10">{{'Departure wave informations' | translate}}: 
                    <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('departureWaveInformations')">
                        &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                    </sup>
                </h4>
                <div *ngFor="let control of departureControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control] }}</div>
                </div>

                <h4 class="mg-t-10">{{'Delivery time informations' | translate}}: 
                    <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('deliveryTime')">
                        &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                    </sup>
                </h4>
                <div *ngFor="let control of deliveryTimeControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig.deliveryTime[control] }}</div>
                </div>
                <div *ngFor="let control of booleanControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div [style.height.px]="24">
                        <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                        <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control].toString() | translate }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="mode == 'edit' || mode == 'saving'" [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <h3 class="title">{{ 'Fulfilment' | translate }}</h3>

                <div class="form">
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <div *ngFor="let control of numberControls; let index = index">
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                            </sup>
                        </div> 
                        <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedShiftConfig[control]" />
                    </div>

                    <h4 class="tx-11 tx-bold mg-t-10">{{'Departure wave informations' | translate}}: 
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('departureWaveInformations')">
                            &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                        </sup>
                    </h4>
                    <div *ngFor="let control of departureControls; let index = index">
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                            </sup>
                        </div> 
                        <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedShiftConfig[control]" />
                    </div>

                    <h4 class="tx-11 tx-bold mg-t-10">{{'Delivery time informations' | translate}}: 
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('deliveryTime')">
                            &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                        </sup>
                    </h4>
                    <div *ngFor="let control of deliveryTimeControls; let index = index">
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                            </sup>
                        </div> 
                        <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedShiftConfig.deliveryTime[control]" />
                    </div>
                    <div *ngFor="let control of booleanControls; let index = index"> 
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                            <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig[control]">
                            <option [ngValue]="true">{{ 'True' | translate }}</option>
                            <option [ngValue]="false">{{ 'False' | translate }}</option>
                        </select>
                    </div>
                </div>
            </editor-window>
        </div>
    `
})
export class ShiftFulfilmentComponent implements OnDestroy {
    @Input() 
    public shiftConfig: Fulfilment = null;

    @Input()
    public shiftStatusData: IShiftStatus = null;
    
    @Output() public shiftConfigChanged: EventEmitter<ShiftConfig> = new EventEmitter();

    public mode = 'view';
    public editedShiftConfig: Fulfilment = null;
    public Permissions = Permissions;
    private updateFulfilmentSub$: Subscription;
    public shiftStatus = ShiftStatus;


    public numberControls = ['reloadTimeMinutes', 'shiftEndMarginMinutes', 'shiftStartMarginMinutes', 'shiftDateOffset'];
    public deliveryTimeControls = ['boxGroupSize', 'boxGroupTime', 'initialTime', 'parkingTime', 'parkingTimeThreshold', 'paymentTime']
    public departureControls = ['departureWaveInitialSize', 'departureWaveInterval', 'departureWaveSize'];
    public booleanControls = ['lockDeliveryOrder'];

    constructor(
        private readonly shiftSettingsService: ShiftSettingsService,
        private readonly translateService: TranslateService
    ) {}

    public onModify() {
        console.log(this.shiftConfig);
        this.editedShiftConfig = _.cloneDeep(this.shiftConfig);
        console.log(this.editedShiftConfig);

        this.mode = 'edit';
    }

    public onCancel() {
        this.editedShiftConfig = null;
        this.mode = 'view';
    }

    public onSave() {
        this.mode = 'saving';

        this.updateFulfilmentSub$ = this.shiftSettingsService.updateFulfilment(this.editedShiftConfig)
            .subscribe((updatedShiftConfig: ShiftConfig) => {
                this.editedShiftConfig = null;
                this.mode = 'view';
                this.shiftConfigChanged.next(updatedShiftConfig);
            },
            (error: HttpErrorResponse) => {
                this.mode = 'view';
            });
    }

    public tooltipLabel(property: string): string {
        return tooltipLabel(this.translateService, `tooltips.shift.labels.${property}`);
    }

    public ngOnDestroy() {
        if (this.updateFulfilmentSub$) {
            this.updateFulfilmentSub$.unsubscribe()
        }
    }
}
