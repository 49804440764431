import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ShiftStatus } from '@enums/enum';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';

@Pipe({
    name: 'checkShiftLimitsPipe',
    pure: true
})
export class CheckShiftLimitsPipe implements PipeTransform {
    public transform(shift: ShiftExt): boolean {
        const sumOfdeliveries = _.chain(shift.statuses).toArray().sum().value();
        const isPastDate = moment(shift.fulfilment.shiftId.date).isBefore(moment());     
        return !isPastDate && shift.shiftStatusData.status === ShiftStatus.BOOKING && ((shift.fulfilment.maximumProductLines < shift.productLines && shift.fulfilment.maximumProductLines !== -1) || (sumOfdeliveries >= shift.fulfilment.maximumDeliveries && shift.fulfilment.maximumDeliveries !== -1));
    }
}
