import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { NUMERIC_PATTREN } from '@shared/constants/utils.constants';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ShiftsService } from '@services/shifts.service';
import { Planning } from '@entities/planning';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { map } from 'rxjs/operators';
import { PageableResponse } from '@entities/pagable-response';

@Component({
  selector: 'app-visualiser-simulator-shift-management',
  templateUrl: './visualiser-simulator-shift-management.component.html',
  styleUrls: ['./visualiser-simulator-shift-management.component.scss']
})
export class VisualiserSimulatorShiftManagementComponent implements OnInit {

  @Input() public shift: ShiftExt;

  @Output() public updatedData: EventEmitter<ShiftExt> = new EventEmitter();
  @Output() public closeShiftManagement: EventEmitter<boolean> = new EventEmitter();


  public form: FormGroup;
  public submitted = false;
  public planningSequence$: Observable<Planning[]>;
  public vehicleTypes$: Observable<VehicleTypes[]>;
  public vehicleTypes: VehicleTypes[];

  public fulfilmentTimesControl = ['startTime', 'endTime'];
  public fulfilmentControl = [ 'departureWaveInitialSize', 'departureWaveInterval', 'departureWaveSize', 'shiftStartMarginMinutes', 'shiftEndMarginMinutes'];
  public deliveryTimeControls: string[] = [ 'boxGroupSize', 'boxGroupTime', 'initialTime', 'parkingTime', 'parkingTimeThreshold', 'paymentTime']
  public routingControls = [ 'slotEndMarginMinutes', 'slotStartMarginMinutes', 'maxCostFactor', 'routeMargin', 'planningSequence' ];
  public fleet: FormArray;
  
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translateService: TranslateService,
    private readonly shiftService: ShiftsService,
    private readonly vehicleTypesService: VehicleTypesService
  ) { }

  public ngOnInit() {
    this.planningSequence$ = this.shiftService.loadPlanningSequence();
    this.vehicleTypesService.fetchAll();
    this.vehicleTypes$ = this.vehicleTypesService.list$.pipe(map((pageable: PageableResponse<VehicleTypes>) => {
      this.vehicleTypes = pageable.content;
      return pageable.content;
    }));

    this.initForm();
  }


  public initForm() {
    this.form = this.formBuilder.group({
      key: [Math.random().toString(36).substring(9).toUpperCase(), [Validators.required]], 

      fulfilment: this.formBuilder.group({
        endTime: [null, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
        startTime: [ null, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]],
        departureWaveInitialSize: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
        departureWaveInterval: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
        departureWaveSize: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
        shiftStartMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
        shiftEndMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
        fleet: this.formBuilder.array([]),
        deliveryTime: this.formBuilder.group({
          boxGroupSize: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
          boxGroupTime: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
          initialTime: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
          parkingTime: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
          paymentTime: [0, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
          parkingTimeThreshold: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
        })
      }),
      routing: this.formBuilder.group({
        slotEndMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
        slotStartMarginMinutes: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
        maxCostFactor: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
        routeMargin: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]], 
        planningSequence: [null, [Validators.required]]
      })
    })

    this.fleet = <FormArray>(this.form.controls['fulfilment'] as FormGroup).controls['fleet'];
    this.shift.fulfilment.fleet.forEach((key: ShiftFleet) => this.fleet.push(this.patchFleet(key)));
    this.form.patchValue(this.shift);
  }

  private patchFleet(fleet?: ShiftFleet): AbstractControl {
    return this.formBuilder.group({
      vehicleTypeId: [(fleet) ? fleet.vehicleTypeId : null, Validators.required],
      max: [(fleet) ? fleet.max : null, Validators.required],
    });
  }

  public addFleet(): void {
    this.fleet.push(this.patchFleet());
  }

  public removeFleet(i): void {
    (<FormArray>(this.form.controls['fulfilment'] as FormGroup).controls['fleet']).removeAt(i);
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.shift.labels.${property}`);
  }

  public onSubmit() {
    this.submitted = true;
    const shift = _.cloneDeep(this.shift);
    const formRawValue = this.form.getRawValue();
    shift.fulfilment = Object.assign(shift.fulfilment, formRawValue.fulfilment);
    shift.routing = Object.assign(shift.routing, formRawValue.routing);
    shift['key'] = formRawValue['key'];
  
    this.updatedData.emit(shift);
  }

  public resetForm(): void {
    this.initForm();
  }
}
