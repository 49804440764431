import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'monthOffsetPipe',
    pure: true
})
export class MonthOffsetPipe implements PipeTransform {

    public transform(offset: number): number {
        return moment().diff(moment().add(-offset, 'month'), 'days');
    }
}
