import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PunctualityEventType } from '@enums/enum';
import { UtilsService } from '@services/utils.service';
import { ReportSettings } from '../entities/report-settings';
import { ChartModel } from '../interfaces/chart.interface';
import { IRoutingEfficiency } from '../interfaces/routing-efficiency.interface';
import { RoutePunctuality, PunctualityEvent, IGroupDataByDelivery } from '../entities/route-punctuality';
import { environment } from '@environment';
import { LocalStorageService } from '@services/local-storage.service';
import { StatsRoutingDistances } from '@analytics/interfaces/stats-routing-distances.interface';
import { OrdersReport } from '@analytics/interfaces/orders-report.interface';
import { DriverPunctuality } from '@analytics/interfaces/driver-punctuality.interface';
import { PunctualityEnum } from '@analytics/enums/punctuality.enum';
import { DeliveryReportInterface } from '@analytics/interfaces/delivery-report.interface';

import * as _ from 'lodash';

@Injectable()
export class AnalyticsService {

    public static DRIVERS_PUNCTUALITY: string = 'delivery/v2/stats/driver?startDate=${startDate}&endDate=${endDate}&aggregation=${aggregation}&page=${page}&size=${size}';
    public static DELIVERIES_PUNCTUALITY: string = 'delivery/v2/stats/punctuality?startDate=${startDate}&endDate=${endDate}&aggregation=${aggregation}';
    public static PUNCTUALITY_SETTINGS_V2: string = 'settings/v1/punctuality';
    public static ROUTING_EFFICIENCY: string = 'route/v1/stats/deliveries_per_route';
    public static ROUTING_PUNCTUALITY_V2: string = 'events/route-stat/v1/${routeId}';
    public static STATS_ROUTING_DISTANCES: string = 'stats/v1/distances?startDate=${startDate}&endDate=${endDate}&warehouse=${warehouse}&aggregation=${aggregation}';
    public static ORDERS_REPORT: string = 'stats/v1/values?startDate=${startDate}&endDate=${endDate}&warehouse=${warehouse}&aggregation=${aggregation}';

    public reportSettings: ReportSettings;

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(
        private readonly http: HttpClient, 
        private readonly utilsService: UtilsService, 
        private readonly localStorageService: LocalStorageService
    ) {}

    public getDriversPunctualityStats(startDate: string, endDate: string, aggregation = 'FOREVER', page = 0, size = 1000): Observable<ChartModel[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${AnalyticsService.DRIVERS_PUNCTUALITY}`, {
            startDate: startDate,
            endDate: endDate,
            aggregation: aggregation,
            page: page,
            size: size
        });

        return this.http.get<DriverPunctuality[]>(endpoint).pipe(
            map((response: DriverPunctuality[]) => {
                const chart: ChartModel[] = [];

                response.forEach((data: DriverPunctuality) => {
                    const c = {
                        name: `[${data.driver.employeeId}] ${data.driver.firstName} ${data.driver.lastname}`,
                        series: this.generateChartDataForDrivers(data),
                        punctuality: data.punctuality,
                        driver: data.driver,
                        nationality: data.driver.nationality
                    }
                    chart.push(c);
                });

                return chart as ChartModel[];
            })
        );
    }

    public generateChartDataForDrivers(data: DriverPunctuality | DeliveryReportInterface) {
        let series;

        series = [
            {
                name: PunctualityEnum.ON_TIME,
                value: data.punctuality.completeOnSchedule,
                percent: (((data.punctuality.completeOnSchedule * 100) / data.punctuality.total) || 0).toFixed(2),
                totalDeliveries: data.punctuality.total
            },
            {
                name: PunctualityEnum.EARLY,
                value: data.punctuality.completeBeforeSchedule,
                percent: (((data.punctuality.completeBeforeSchedule * 100) / data.punctuality.total) || 0).toFixed(2),
                totalDeliveries: data.punctuality.total
            },
            {
                name: PunctualityEnum.LATE,
                value: data.punctuality.completeAfterSchedule,
                percent: (((data.punctuality.completeAfterSchedule * 100) / data.punctuality.total) || 0).toFixed(2),
                totalDeliveries: data.punctuality.total
            },
            {
                name: PunctualityEnum.INCOMPLETE_BEFORE,
                value: data.punctuality.incompleteBeforeSchedule,
                percent: (((data.punctuality.incompleteBeforeSchedule * 100) / data.punctuality.total) || 0).toFixed(2),
                totalDeliveries: data.punctuality.total
            },
            {
                name: PunctualityEnum.INCOMPLETE_AFTER,
                value: data.punctuality.incompleteAfterSchedule,
                percent: (((data.punctuality.incompleteAfterSchedule * 100) / data.punctuality.total) || 0).toFixed(2),
                totalDeliveries: data.punctuality.total
            }
        ];

        return series;
    }

    public getDeliveriesPunctualityStats(startDate: string, endDate: string, aggregation = 'DAYS', page = 0, size = 10): Observable<ChartModel[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${AnalyticsService.DELIVERIES_PUNCTUALITY}`, {
            startDate: startDate,
            endDate: endDate,
            aggregation: aggregation
        });

        return this.http.get<DeliveryReportInterface[]>(endpoint).pipe(
            map((response: DeliveryReportInterface[]) => {
                const chart = [];
                response.forEach((data: DeliveryReportInterface) => {
                    const c = {
                        name: `${data.date} ${data.shift}`,
                        series: this.generateChartDataForDrivers(data),
                        punctuality: data.punctuality

                    }
                    chart.push(c);
                });

                return chart;
            })
        );
    }

    public getRoutingDistancesStats(startDate: string, endDate: string, aggregation: string = 'WEEKS'): Observable<StatsRoutingDistances[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${AnalyticsService.STATS_ROUTING_DISTANCES}`, {
            startDate, endDate, 
            warehouse: this.localStorageService.getDepot(),
            aggregation
        });

        return this.http.post<StatsRoutingDistances[]>(endpoint, this.utilsService.httpHeaders()).pipe(
            map((results: StatsRoutingDistances[]) => {
                results.map(s => {
                    s.averageDistance = s.distance / s.deliveries;
                    s.averageDryBoxCount = s.totalDryBoxCount / s.deliveries;
                    s.averageChilledBoxCount = s.totalChilledBoxCount / s.deliveries;
                    s.averageFrozenBoxCount = s.totalFrozenBoxCount / s.deliveries;
                });
                return results;
            })
        );
    }

    public getOrdersReport(startDate: string, endDate: string, aggregation: string = 'WEEKS'): Observable<OrdersReport[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${AnalyticsService.ORDERS_REPORT}`, {
            startDate, endDate, 
            warehouse: this.localStorageService.getDepot(),
            aggregation
        });

        return this.http.post<OrdersReport[]>(endpoint, this.utilsService.httpHeaders()).pipe(
            map((results: OrdersReport[]) => {
                results.map(s => {
                    s.averageValue = s.value / s.deliveries;
                    s.averageSkus = s.skus / s.deliveries;
                });
                console.log('re', results);
                
                return results;
            })
        );
    }

    public getRoutingEfficiency(periods: Array<IRoutingEfficiency>) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${AnalyticsService.ROUTING_EFFICIENCY}`, {});
        return this.http.post(endpoint, periods).pipe(map((responses: any) => responses));
    }

    public groupByDelivery(routeData: RoutePunctuality) {

        routeData.segmentPunctualityAggList.forEach(segment => {

            segment.punctualityEventList.forEach((event: PunctualityEvent, index: number) => {

                if (event.relatedDeliveryId === null) {
                    if (index === 0) {
                        segment.punctualityEventList[index].relatedDeliveryId = segment.punctualityEventList.find((e: PunctualityEvent) => !!e.relatedDeliveryId).relatedDeliveryId;
                    } else {
                        for (let i = index; i > 0; i--) {       
                            const id = segment.punctualityEventList[i].relatedDeliveryId;
                            if (id) {
                                segment.punctualityEventList[index].relatedDeliveryId = id;
                                break;
                            }
                        }
                    }
                }
            });

            segment.punctualityEventList
                .filter((e: PunctualityEvent) => !e.relatedDeliveryId)
                .map((e: PunctualityEvent) => e.relatedDeliveryId = segment.punctualityEventList[0].relatedDeliveryId);
        });


        routeData.segmentPunctualityAggList.forEach((segment) => {

            segment.punctualityEventList.forEach((event: PunctualityEvent, index: number) => {
                if (event.relatedDelivery === null && event.type !== PunctualityEventType.WAREHOUSE_DEPARTURE) {
                    event.relatedDelivery = segment.punctualityEventList[index - 1].relatedDelivery;
                }
            });

            const eventsList = _.chain(segment.punctualityEventList)
                .groupBy((del: PunctualityEvent) => del.relatedDeliveryId)
                .map((value, key) => {
                    const delivery: PunctualityEvent = value.find((punctualityEvent: PunctualityEvent) => punctualityEvent.type === PunctualityEventType.DELIVERY_STOP);
                    return { 
                        delivery: key,
                        relatedDelivery: (delivery) ? delivery.relatedDelivery : null,
                        events: value
                    } as IGroupDataByDelivery
                }).value();
        
            routeData.groupDataByDelivery.push(...eventsList);

        });

        return routeData;
    }
}
