import { Component, OnInit } from '@angular/core';
import { locationsData } from './db';
import { mapTypes, mapOptions } from '@enums/enum';

@Component({
  selector: 'app-find-location',
  templateUrl: './find-location.component.html',
  styleUrls: ['./find-location.component.scss']
})
export class FindLocationComponent implements OnInit {

  public coordinates = locationsData;

  mode = 'REGULAR';

  private mapProperties = {
    zoom: 11,
    center: {
        lat: 52.229676,
        lng: 21.012229
    },
    mapTypeId: mapTypes.ROADMAP,
    scaleControl: true
  };
  
  private mapOption = mapOptions.HEATMAP;

  constructor() { }

  ngOnInit() {
  }

}
