import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CrudEditComponent } from '@shared/components/cruds/crud-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DepotVirtual } from '@interfaces/depot-virtual.interface';
import { DepotVirtualService } from '../../services/depot-virtual.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { DepotService } from '../../services/depot.service';
import { Observable } from 'rxjs';
import { Depot } from '@interfaces/depot.interface';
import { switchMap } from 'rxjs/operators';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-depot-virtual-list',
  templateUrl: './depot-virtual-list.component.html',
  styleUrls: ['./depot-virtual-list.component.scss']
})
export class DepotVirtualListComponent extends CrudEditComponent<DepotVirtual, string> implements OnInit {

  public depot$: Observable<Depot>;
  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public columns = [
    { prop: 'name', headerClass: 'name', name: this.translateService.instant('Name'), flexGrow: 2, align: 'left' },
    { prop: 'code', headerClass: 'code', name: this.translateService.instant('Code'), flexGrow: 2, align: 'left' },
    { prop: 'drivingTime', headerClass: 'drivingTime', name: this.translateService.instant('Driving time'), flexGrow: 2, align: 'center' },
    { prop: 'testLocations', headerClass: 'drivingTime', name: this.translateService.instant('Test locations number'), flexGrow: 2, align: 'center' },
    { prop: 'options', headerClass: 'options', name: this.translateService.instant('Options'), flexGrow: 1, align: 'center' },
  ];

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;
  
  constructor(
    private readonly navigationRoute: ActivatedRoute,
    protected readonly depotVirtualService: DepotVirtualService,
    protected readonly depotService: DepotService,
    protected readonly notifierService: NotifierService,
    protected readonly translateService: TranslateService,
    protected readonly router: Router,
    private readonly modalService: BsModalService,

  ) {
    super(depotVirtualService, translateService, notifierService, router, navigationRoute)
   }

  public ngOnInit() {
    this.depot$ = this.navigationRoute.params.pipe(
      switchMap((params: {id: string}) => this.depotService.findOne(params.id))
    )
  }

  public removeVirtualDepot(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.depotVirtualService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams = environment.pagination;
      this.depotService.fetchAll(this.queryParams);
      this.notifierService.notify('success', this.translateService.instant('The depot has been removed!'));
      this.confirmObservable = null;

      this.depot$ = this.navigationRoute.params.pipe(
        switchMap((params: {id: string}) => this.depotService.findOne(params.id))
      )
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }
}
