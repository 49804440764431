import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DayComposite } from '@calendar/entities/dayComposite';
import { MonthlyShiftConfig } from '@calendar/entities/monthlyShiftConfig';
import { Depot } from '@interfaces/depot.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { ShiftsService } from '@services/shifts.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap, take } from 'rxjs/operators';
import { DepotService } from 'src/app/locations/services/depot.service';
import { CalendarOverviewShiftUpdateDialogComponent } from '../calendar-overview-shift-update-dialog/calendar-overview-shift-update-dialog.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';

@Component({
  selector: 'app-calendar-overview',
  templateUrl: './calendar-overview.component.html',
  styleUrls: ['./calendar-overview.component.scss']
})
export class CalendarOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('changeShiftSettingsTmpl', { static: true }) public changeShiftSettingsTmpl: TemplateRef<any>;

  public depots$: Observable<Depot[]>;
  public currentMonth$: Observable<MonthlyShiftConfig>;
  public loader = true;
  public today = moment();
  public selectedDepot;

  list: DayComposite[];
  locale = 'pl';

  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  private readonly numberDeliveriesPerVanMorning: number = 19.3;
  private readonly numberDeliveriesPerVanAfternoon: number = 20;
  private readonly numberDeliveriesPerVanEvening: number = 14;

  public destroy$ = new Subject<boolean>();

  constructor(
    private readonly depotService: DepotService,
    private readonly shiftsService: ShiftsService,
    private readonly translate: TranslateService,
    private readonly localStorageService: LocalStorageService,
    private readonly modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.locale = this.translate.currentLang;
    this.depots$ = this.depotService.getDepotsForUser();
    this.selectedDepot = this.localStorageService.getDepot();
    const startdate = moment().format('YYYY-MM-DD');
    const enddate = moment().add(2, 'days').format('YYYY-MM-DD');
    this.shiftsService.loadMonthConfiguration(startdate, enddate, this.selectedDepot);

    this.translate.onLangChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe((lang: LangChangeEvent) => {
      this.locale = lang.lang;
    });

    this.currentMonth$ = this.shiftsService.selectedMonthShiftConfig.pipe(
      tap(() => this.loader = true),
      map((monthlyShiftConfig: MonthlyShiftConfig) => {
        this.list = [];
        for (let index = 0; index < 3; index++) {
          let daycomposite: DayComposite = monthlyShiftConfig.days.find((day: DayComposite) => day.dateRaw === this.today.clone().add(index, 'day').format('YYYY-MM-DD'));
          daycomposite.dailyShiftConfig.shifts = sortBy(daycomposite.dailyShiftConfig.shifts, (shift: ShiftExt) => shift.fulfilment.startTime);
          daycomposite.dailyShiftConfig.shifts = daycomposite.dailyShiftConfig.shifts.map((shift: ShiftExt) => {
            return {
              ...shift,
              shiftCutoffData: sortBy(shift.shiftCutoffData, cutoff => cutoff.status === 'COMPLETED')
            }
          })
          this.list.push(daycomposite);
        }
        return monthlyShiftConfig;
      }),
      tap(() => this.loader = false)
    );
  }

  changeDepot(depotId: string) {
    this.loader = true;
    this.selectedDepot = depotId;
    const startdate = moment().format('YYYY-MM-DD');
    const enddate = moment().add(2, 'days').format('YYYY-MM-DD');
    this.shiftsService.loadMonthConfiguration(startdate, enddate, this.selectedDepot);
  }


  changeShiftSettings(shift, cutoffid, event) {
    event.preventDefault();
    this.modalRef = this.modalService.show(CalendarOverviewShiftUpdateDialogComponent, { initialState: { shift, cutoffid } });

    this.modalService.onHide.pipe(
      take(1),
      takeUntil(this.destroy$),
    ).subscribe((event) => {
      if (this.modalRef.content.cancelled || event == 'backdrop-click') {
        this.loader = false
        return
      }
      this.changeDepot(this.selectedDepot);
    });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
