export const ChartSettings = {
    multi: [],
    view: [1000, 400],
    legend: true,
    showLabels: true,
    animations: true,
    xAxis: true,
    yAxis: true,
    showYAxisLabel: true,
    showXAxisLabel: true,
    xAxisLabel: 'Date',
    yAxisLabel: '$$',
    timeline: true,

    colorScheme: {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
    },
};
