import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumValuesPipe',
  pure: true,
})
export class SumValuesPipe implements PipeTransform {

  transform(value: object): number {
    // Sprawdzamy czy wartość jest obiektem
    if (!value || typeof value !== 'object') {
      return 0;
    }

    // Sumujemy wszystkie wartości liczbowe w obiekcie
    return Object.values(value).reduce((acc, val) => acc + (typeof val === 'number' ? val : 0), 0);
  }
}
