import * as _ from 'lodash';

import { Driver } from '@drivers/entities/driver';
import { CustomerType, ActivityType, DeliveryStatus, DeliveryType, RouteStatus } from '@enums/enum';
import { Van } from '@hardware/entities/van';
import { Entity } from './Entity';
import { Serializable } from './Serializable';
import { RouteExt } from './route-ext';
import { Activity } from './activity';
import { secToHours } from '@shared/functions/secToHours.function';
import * as moment from 'moment';
import { Delivery } from './delivery';
import { environment } from '@environment';

export class RoutePoint {
    public label: string;
    public lat: number;
    public lng: number;
    public group: string;
    public slot: string;
    public description: string;
    public icon: string;
    public stopNo: number;

    public fill(label: string, stopNo: number, lat: number, lng: number, slot: string, group: string, description: string) {
        this.label = label;
        this.stopNo = stopNo;
        this.lat = lat;
        this.lng = lng;
        this.slot = slot;
        this.group = group;
        this.description = description;
    }
}

export class RoutePath extends Entity implements Serializable<RoutePath> {
    public id: string;
    public year: string;
    public month: string;
    public day: string;
    public date: string;
    public shift: string;
    public number: string;
    public routeNumber: number;
    public vanLabel: string;
    public driverLabel: string;
    public warehouseId: string;

    public points: RoutePoint[] = [];

    protected latRange = {
        min: 0,
        max: 0
    };

    protected lngRange = {
        min: 0,
        max: 0
    };

    get routeLabel() {
        return String.fromCharCode(((Number(this.routeNumber) % 26)) + 65);
    }

    get latCenter() {
        return ((this.latRange.min + this.latRange.max) / 2) || 52.237049;
    }

    get lngCenter() {
        return ((this.lngRange.min + this.lngRange.max) / 2) || 21.017532;
    }

    public static fromActivity(activities: Activity[]): RoutePath{
        const path = new RoutePath();
        const latValues = [];
        const lngValues = [];
        const rawPoints = [];

        const act: Activity[] = activities.filter((a: Activity) => a.type === ActivityType.DELIVERY);
        let index = 1;

        act.map((a: Activity) => {
            a.idList.forEach(dIndex => {
            const point = new RoutePoint();

            const pl = (a.planning) ? `${moment(a.planning.from).format('HH:mm')}-${moment(a.planning.to).format('HH:mm')}` : null;

            point.fill(
                `${index}`, // lw: don't remove backticks
                a.stepNo,
                a.location.lat,
                a.location.lng,
                `[${index + 1}] ${a.slot.slot}`,
                `${a.slot.slot}`,
                `Id: ${a.id},
                NO: ${a.stepNo},
                RouteNo: ${path.routeNumber},
                Slot: ${a.slot.slot},
                Duration: ${secToHours(a.durationSeconds)}
                Pl: ${pl}`,
            );

            latValues.push(a.location.lat);
            lngValues.push(a.location.lng);

            point.icon = `z${(index % 9) + 1}.png`;

            rawPoints.push(point);

            index = index + 1;
        });
    });

    path.latRange = {
        min: _.min(latValues),
        max: _.max(latValues)
    };

    path.lngRange = {
        min: _.min(lngValues),
        max: _.max(lngValues)
    };

    path.points.push(...rawPoints);

    return path;
    }

    public static fromRouteSummary(route: RouteExt) {
        const path = new RoutePath();
        path.routeNumber = route.routeNumber;
        
        const deliveries = route.activities.filter((a: Activity) => a.type === ActivityType.DELIVERY);
        const deliveriesBySlot = _.groupBy(deliveries, (a: Activity) => a.slot.slot);

        const latValues = [];
        const lngValues = [];
        const rawPoints = [];

        let counter = 1;
        let index = 1;

        deliveries.map((a: Activity) => {
            a.idList.forEach(dIndex => {
                const point = new RoutePoint();

                const pl = (a.planning) ? `${moment(a.planning.from).format('HH:mm')}-${moment(a.planning.to).format('HH:mm')}` : null;


                point.fill(
                    `${index}`, // lw: don't remove backticks
                    a.stepNo,
                    a.location.lat,
                    a.location.lng,
                    `[${counter++}] ${a.slot.slot}`,
                    `${a.slot.slot}`,
                    `[${a.id}] ${(route.deliveryDict[dIndex]) ? route.deliveryDict[dIndex].customer.label : ''},
                    NO: ${a.stepNo},
                    RouteNo: ${path.routeNumber},
                    Slot: ${a.slot.slot},
                    Duration: ${secToHours(a.durationSeconds)},
                    Pl: ${pl}`,
                );

                latValues.push(a.location.lat);
                lngValues.push(a.location.lng);
                    console.log(route.status);
                
                if (route.status === RouteStatus.COMPLETED) {
                    if (moment(a.planning.from).isBefore(a.slot.from)) {
                        point.icon = 'z1.png';
                    } else if (moment(a.planning.from).isAfter(a.slot.to)) {
                        point.icon = 'z4.png';
                    } else {
                        point.icon = 'z8.png';
                    }
                } else {
                    point.icon = (route.deliveryDict[dIndex] && route.deliveryDict[dIndex].status === DeliveryStatus.DONE) 
                        ? `z10-delivered.png`
                        : `z${(Object.keys(deliveriesBySlot).indexOf(a.slot.slot) % 9) + 1}.png`;
                }

                

                index = index + 1;
                rawPoints.push(point);
            });


        });

        path.latRange = {
            min: _.min(latValues),
            max: _.max(latValues)
        };

        path.lngRange = {
            min: _.min(lngValues),
            max: _.max(lngValues)
        };


        path.points.push(...rawPoints);

        _.chain(path.points).groupBy('lat').map((points) => {
            points.forEach((element) => {
                element.lat = element.lat + _.random(0.000015, 0.00010);
                element.lng = element.lng + _.random(0.000015, 0.00010);
            });
        }).value();

        return path;
    }

    public deserialize(data: any) {


        this.id = _.get(data, 'id');

        this.date = _.get(data, 'date');
        [this.year, this.month, this.day] = this.date.split('-');

        this.warehouseId = _.get(data, 'warehouse');
        const status = _.get(data, 'status');

        const driverLabel = (new Driver()).deserialize(data.driver).label;
        this.driverLabel = driverLabel.length > 2 ? driverLabel : `Driver ${this.number}`;
        const vanLabel = (new Van()).deserialize(data.van).label;
        this.vanLabel = vanLabel.length > 2 ? vanLabel : `Van ${this.number}`;

        let counter = 1;

        const deliveryDict: Delivery = (_.get(data, 'deliveryDict', []));
        const activities = (_.get(data, 'activities', []));
        const deliveries = activities.filter((a: Activity) => [ActivityType.RELOAD, ActivityType.DELIVERY, ActivityType.LOAD, ActivityType.DEPARTURE].includes(a.type));
        const deliveriesBySlot = _.groupBy(deliveries, (d: Activity) => d.slot.slot);
        let index = 1;
        const latValues = [];
        const lngValues = [];

        deliveries.map((delivery: Activity) => {

            if ([ActivityType.RELOAD, ActivityType.LOAD, ActivityType.UNLOAD, ActivityType.DEPARTURE].includes(delivery.type)) {
                const point = new RoutePoint();
                const pl = (delivery.planning) ? `${moment(delivery.planning.from).format('HH:mm')}-${moment(delivery.planning.to).format('HH:mm')}` : null;

                point.fill(
                    ``,
                    delivery.stepNo,
                    delivery.location.lat,
                    delivery.location.lng,
                    `[${counter++}] ${delivery.slot.slot}`,
                    `${delivery.slot.slot}`,
                    `[${delivery.type}],
                    NO: ${delivery.stepNo},
                    Slot: ${delivery.slot.slot},
                    Duration: ${secToHours(delivery.durationSeconds)},
                    Planning: ${pl}`
                );

                point.icon = `${environment.client.toLowerCase()}Marker.png`
                
                latValues.push(delivery.location.lat);
                lngValues.push(delivery.location.lng);

                switch (delivery.type) {
                    case ActivityType.RELOAD :
                        point.icon = `${environment.client.toLowerCase()}Marker.png`
                        break;
                    case ActivityType.DEPARTURE:
                        point.icon = `${environment.client.toLowerCase()}Marker.png`
                        break;
                    case ActivityType.LOAD:
                        point.icon = `marker-crazy-delivery.png`
                        break;
                    case ActivityType.UNLOAD:
                        point.icon = `marker-crazy-delivery2.png`
                        break;
                }
                this.points.push(point);

            }else {

                delivery.idList.forEach(dIndex => {
                   

                    const deliveryTime = (status === RouteStatus.COMPLETED && delivery.deliveries.length && delivery.deliveries[0].deliveryTime) ? moment(delivery.deliveries[0].deliveryTime).format('HH:mm') : '';

                    const point = new RoutePoint();
                    const pl = (delivery.planning) ? `${moment(delivery.planning.from).format('HH:mm')}-${moment(delivery.planning.to).format('HH:mm')}` : null;

                    point.fill(
                        `${index}`, // lw: don't remove backticks
                        delivery.stepNo,
                        delivery.location.lat,
                        delivery.location.lng,
                        `[${counter++}] ${delivery.slot.slot}${deliveryTime}`,
                        `${delivery.slot.slot}`,
                        `[${delivery.id}] ${(deliveryDict[dIndex]) ? deliveryDict[dIndex].customer.label : ''},
                        NO: ${delivery.stepNo},
                        Slot: ${delivery.slot.slot},
                        Duration: ${secToHours(delivery.durationSeconds)},
                        Planning: ${pl}
                        ${(status === RouteStatus.COMPLETED) ? `, Delivery time: ${deliveryTime}` : ``}
                        `,
                    );

                    latValues.push(delivery.location.lat);
                    lngValues.push(delivery.location.lng);

                    if (status === RouteStatus.COMPLETED && delivery.deliveries.length) {
                        if (moment(delivery.deliveries[0].deliveryTime).isBefore(delivery.slot.from)) {
                            point.icon = 'z4.png';
                        } else if (moment(delivery.deliveries[0].deliveryTime).isAfter(delivery.slot.to)) {
                            point.icon = 'z1.png';
                        } else {
                            point.icon = 'z8.png';
                        }
                    } else {
                        point.icon = (deliveryDict[dIndex] && deliveryDict[dIndex].status === DeliveryStatus.DONE) 
                            ? `z10-delivered.png`
                            : `z${(Object.keys(deliveriesBySlot).indexOf(delivery.slot.slot) % 9) + 1}.png`;

                    }


                    
                    this.points.push(point);
                    index = index + 1;
                    return point;
                });

            }
            
        });

        this.latRange = {
            min: _.min(latValues),
            max: _.max(latValues)
        };

        this.lngRange = {
            min: _.min(lngValues),
            max: _.max(lngValues)
        };

        _.chain(this.points).groupBy('lat').map(this.randomizePoints).value();

        console.log('PATH: ', this);

        return this;
    }

    private randomizePoints(points) {
        return points.forEach((element, index) => {
            element.lat = element.lat + _.random(0.000015, 0.00010);
            element.lng = element.lng + _.random(0.000015, 0.00010);
        });
    }

    private showClientStatus(data, id) {
        const status = this.getClientStatus(data, id);
        return status === CustomerType.NEW || status === CustomerType.VIP ? `Status: ${status},` : '';
    }

    private getClientStatus(data, id) {
        let status = '';

        data.find(delieryData => delieryData.deliveries.some(delivery => {
            if (delivery.id === id) {
                return status = (delivery.customer) ? delivery.customer.status : '';
            }
        }));
        return status;
    }

    public serialize() {
        return {};
    }
}
