import * as _ from 'lodash';
import * as moment from 'moment';

export class LicensingRequest {
    public dateFrom: string;
    public dateTo: string;
    public deliveriesFee: number;
    public usersFee: number;
    public mobileAppFee: number;

    constructor(dateFrom: string, dateTo: string, deliveriesFee: number, usersFee: number, mobileAppFee: number){
        
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.deliveriesFee = deliveriesFee;
        this.usersFee = usersFee;
        this.mobileAppFee = mobileAppFee;

    }

    public get isValidDate(): boolean {
        return moment(this.dateFrom).isBefore(moment(this.dateTo));
    }
}
