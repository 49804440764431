export enum Permissions {
    // Create_user = "CREATE_USER",
    // Modify_user = "MODIFY_USER",
    // Deactivate_user = "DEACTIVATE_USER",
    // Get_user = "GET_USER",
    Create_role = "CREATE_ROLE",
    Modify_role = "MODIFY_ROLE",
    Remove_role = "REMOVE_ROLE",
    Assign_role = "ASSIGN_ROLE",
    List_roles = "LIST_ROLES",
    // Modify_delivery = "MODIFY_DELIVERY",
    // Assign_delivery = "ASSIGN_DELIVERY",
    Analytics_driverpunctuality_view = "ANALYTICS_DRIVERPUNCTUALITY_VIEW",
    Analytics_ordershistory_view = "ANALYTICS_ORDERSHISTORY_VIEW",
    Analytics_punctuality_view = "ANALYTICS_PUNCTUALITY_VIEW",
    Analytics_routingefficiency_view = "ANALYTICS_ROUTINGEFFICIENCY_VIEW",
    Analytics_stats2_view = "ANALYTICS_STATS2_VIEW",
    Booking_availability = "BOOKING_AVAILABILITY",
    Calendar_advancedsettings = "CALENDAR_ADVANCEDSETTINGS",
    Calendar_basicsettings = "CALENDAR_BASICSETTINGS",
    Calendar_view = "CALENDAR_VIEW",
    Config_default_edit = "CONFIG_DEFAULT_EDIT",
    Config_default_view = "CONFIG_DEFAULT_VIEW",
    Config_planningsequence_edit = "CONFIG_PLANNINGSEQUENCE_EDIT",
    Config_planningsequence_view = "CONFIG_PLANNINGSEQUENCE_VIEW",
    Delivery_edit = "DELIVERY_EDIT",
    Delivery_view = "DELIVERY_VIEW",
    Driver_edit = "DRIVER_EDIT",
    Driver_login = "DRIVER_LOGIN",
    Driver_notify = "DRIVER_NOTIFY",
    Dashboard_view = "DASHBOARD_VIEW",
    Driver_planning_edit = "DRIVER_PLANNING_EDIT",
    Driver_planning_view = "DRIVER_PLANNING_VIEW",
    Driver_salaries_edit = "DRIVER_SALARIES_EDIT",
    Driver_salaries_view = "DRIVER_SALARIES_VIEW",
    Driver_view = "DRIVER_VIEW",
    Hardware_edit = "HARDWARE_EDIT",
    Hardware_view = "HARDWARE_VIEW",
    Location_edit = "LOCATION_EDIT",
    Location_view = "LOCATION_VIEW",
    Payments_view = "PAYMENTS_VIEW",
    Payments_accept = "PAYMENTS_ACCEPT",
    Roles_view = "ROLES_VIEW",
    Roles_edit = "ROLES_EDIT",
    Roles_assign = "ROLES_ASSIGN",
    Route_delivery_assign = "ROUTE_DELIVERY_ASSIGN",
    Route_delivery_unassign = "ROUTE_DELIVERY_UNASSIGN",
    Route_view = "ROUTE_VIEW",
    Route_driver_assign = "ROUTE_DRIVER_ASSIGN",
    Route_driver_unassign = "ROUTE_DRIVER_UNASSIGN",
    Route_edit = "ROUTE_EDIT",
    Route_van_assign = "ROUTE_VAN_ASSIGN",
    Route_van_unassign = "ROUTE_VAN_UNASSIGN",
    Simulator_view = "SIMULATOR_VIEW",
    Simulator_edit = "SIMULATOR_EDIT",
    User_edit = "USER_EDIT",
    User_view = "USER_VIEW",
    Visualiser_cutoff = "VISUALISER_CUTOFF",
    Visualiser_view = "VISUALISER_VIEW",
    Apm_view = "APM_VIEW",
    Apm_edit = "APM_EDIT",
    Partner_view = "PARTNER_VIEW",
    Partner_edit = "PARTNER_EDIT",
    Partneruser_view = "PARTNERUSER_VIEW",
    Partneruser_edit = "PARTNERUSER_EDIT",
    Depot_edit = "DEPOT_EDIT",
    Shift_edit = "SHIFT_EDIT",
    Shift_fulfillment_edit = "SHIFT_FULFILLMENT_EDIT",
    Shift_fulfillment_view = "SHIFT_FULFILLMENT_VIEW",
    Shift_integration_edit = "SHIFT_INTEGRATION_EDIT",
    Shift_integration_view = "SHIFT_INTEGRATION_VIEW",
    Shift_routing_edit = "SHIFT_ROUTING_EDIT",
    Shift_routing_view = "SHIFT_ROUTING_VIEW",
    Shift_planning_edit = "SHIFT_PLANNING_EDIT",
    Shift_planning_fulfillment_edit = "SHIFT_PLANNING_FULFILLMENT_EDIT",
    Shift_planning_integration_edit = "SHIFT_PLANNING_INTEGRATION_EDIT",
    Shift_planning_routing_edit = "SHIFT_PLANNING_ROUTING_EDIT",
    Apm_delivery_reschedule = "APMDELIVERY_RESCHEDULE",
    Apm_maintenance_edit = "MAINTENANCE_EDIT",
    Message_template_edit = "MESSAGE_TEMPLATE_EDIT",
    Message_template_view = "MESSAGE_TEMPLATE_VIEW",
    Billing_view = "BILLING_VIEW",
    Receive_important_customer_notification = "RECEIVE_IMPORTANT_CUSTOMER_NOTIFICATION",
    Apm_delivery_rebook = "APMDELIVERY_REBOOK",
    Importjob_view = 'IMPORTJOB_VIEW',
    Customer_delete = 'CUSTOMER_DELETE',
    Receive_delivery_failure_notification = 'RECEIVE_DELIVERY_FAILURE_NOTIFICATION',
    Shift_planning_view = 'SHIFT_PLANNING_VIEW',
    Apm_delivery_billing_edit = 'APMDELIVERY_BILLING_EDIT',
    Route_consolidate = 'ROUTE_CONSOLIDATE',
    Import_job_edit = 'IMPORTJOB_EDIT',
    Shift_cutoff_force = 'SHIFT_CUTOFF_FORCE',
    Schedule_edit = 'SCHEDULE_EDIT',
    FilterWordView = 'FILTER_WORD_VIEW',
    FilterWordEdit = 'FILTER_WORD_EDIT',
    DashboardSalesView = 'DASHBOARD_SALES_VIEW',

    //

    ROLE_DISPATCHER = 'ROLE_DISPATCHER',
    ROLE_SYSADMIN = 'ROLE_SYSADMIN',
    ROLE_CUSTOMER_CARE = 'ROLE_CUSTOMER_CARE'

}
