import { plainToClass, Type } from 'class-transformer';
import * as _ from 'lodash';

import { Driver } from '@drivers/entities/driver';
import { serializeType } from '@shared/functions/serializeType.function';

import { Hardware } from './hardware';

export class HardwareUsages {
    public createdAt: string;
    public date: string;
    public driverId: string;
    public hardwareId: string;
    public id: number;
    public modifiedAt: string;
    public receivedAt: string;
    public returnedAt: string;
    public routeNumber: number;
    public shift: string;
    public version: number;
    public warehouse: string;

    @Type(serializeType(Driver))
    public driver: Driver;

    @Type(serializeType(Hardware))
    public hardware: Hardware;

    public deserialize(data: any): HardwareUsages {
        this.createdAt = _.get(data, 'createdAt');
        this.date = _.get(data, 'date');
        this.driverId = _.get(data, 'driverId');
        this.hardwareId = _.get(data, 'hardwareId');
        this.id = _.get(data, 'id');
        this.modifiedAt = _.get(data, 'modifiedAt');
        this.receivedAt = _.get(data, 'receivedAt');
        this.returnedAt = _.get(data, 'returnedAt');
        this.routeNumber = _.get(data, 'routeNumber');
        this.shift = _.get(data, 'shift');
        this.version = _.get(data, 'version');
        this.warehouse = _.get(data, 'warehouse');

        const driverRaw = _.get(data, 'driver');
        this.driver = plainToClass(Driver, driverRaw as Driver);

        const hardwareRaw = _.get(data, 'hardware');
        this.hardware = plainToClass(Hardware, hardwareRaw as Hardware);

        return this;
    }
}
