import * as _ from 'lodash';
import * as moment from 'moment';

import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { DeliveryClean } from '@entities/delivery-clean';
import { RouteExt } from '@entities/route-ext';
import { ShiftConstants, ShiftHoursInterface } from '@shared/constants/shift.constants';

import { OptimizeRoutes } from './optimize-routes';
import { Activity } from '@entities/activity';
import { ActivityType } from '@enums/enum';

export class OptimizeResult {
    public log: Array<string>;
    public routes: OptimizeRoutes[];
    public countByslot = [];
    public shift: ShiftConfig;

    public static fromJson(data: any) {

        const result = new OptimizeResult();
        const routeDeliveryBySlot = [];
        result.shift = new ShiftConfig().deserialize(data.shift);

        result.routes = data.results.map((routeData: any) => {
            return new OptimizeRoutes().deserialize(routeData);
        });

        return result;
    }
    // public extractDeliveriesBySlot(slot) {
    //     const routes = _.cloneDeep(this.routes);
    //     const deliveries = [];

    //     _.forEach(routes, (optRoutes: OptimizeRoutes) => {
    //         _.forEach(optRoutes.routes, (route: RouteExt) => {
    //             const routeDeliveryBySlot = [];
    //             routeDeliveryBySlot.push(..._.filter(route.activities, (a: Activity) => {

    //                 if (a.type === ActivityType.DELIVERY) {
    //                     const slotEnd = a.slot.slot.split('-')[1];
    //                     const selectedSlot = slot.split('-')[1];
    //                     const selectedSlot2 = moment('2020-01-01 ' + selectedSlot).add(-30, 'minutes').format("HH:mm");

    //                     if (slotEnd === selectedSlot || slotEnd === selectedSlot2) {
    //                         return a;
    //                     }
    //                 }
    //             }));
    //             route.activities = routeDeliveryBySlot;
    //             if (routeDeliveryBySlot.length) {
    //                 deliveries.push(route);
    //             }

    //         });
    //     });

    //     return _.cloneDeep(deliveries);
    // }

    public getRoutes(): RouteExt[] {
        const routes = [];

        _.forEach(this.routes, optRoutes => {
            _.forEach(optRoutes.routes, (route: RouteExt) => {
                route['runtime'] = optRoutes.runtime;
                routes.push(route);
            });
        });

        return routes;
    }
}
