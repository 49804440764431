import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { Client, DriverStatus } from '@enums/enum';
import { NavService } from '@services/nav.sevice';

import { Driver } from '../entities/driver';
import { DriversService } from '../services/drivers.service';
import { AppService } from '@services/app.service';

@Component({
    selector: 'drivers',
    templateUrl: 'drivers.component.html',
    styleUrls: ['drivers.component.scss']
})
export class DriversComponent implements OnInit, OnDestroy {
    private readonly TAG: string = '[DriversComponent]';
    private drivers: Driver[] = [];
    private drivers$: Subscription;
    private modifiedId = '';
    private routeQueryParams$: Subscription;

    public columns;
    public filterPhrase: string = '';
    public loader: boolean = true;
    public rows: Driver[] = [];
    public rowClass;
    public selectedDriverStatus = DriverStatus.ACTIVE;
    public tableMessages = {
        'emptyMessage': this.translate.instant('No data to display')
    };
    public inpostClient: Client = Client.INPOST;
    public friscoClient: Client = Client.FRISCO;

    get driverStatus() {
        return _.keys(DriverStatus);
    }

    constructor(
        private driversService: DriversService,
        private translate: TranslateService,
        private appService: AppService,
        private navService: NavService,
        private navigationRoute: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.drivers$ = this.driversService.drivers.subscribe((drivers: Driver[]) => {
            this.drivers = drivers;
            this.applyFiltering();

            this.loader = false;
            
        });

        // mark recently modified record
        this.routeQueryParams$ = this.navigationRoute.params.subscribe((params) => {
            this.modifiedId = _.get(params, 'modifiedId', '');
        });

        this.columns = [
            {id: 'num', prop: 'num', name: '', flexGrow: 1, sortable: false},
            {id: 'lastname', prop: 'lastname', name: this.translate.instant('lastname'), flexGrow: 2, sortable: true},
            {id: 'firstName', prop: 'firstName', name: this.translate.instant('Firstname'), flexGrow: 2, sortable: true},
            {id: 'employeeId', prop: 'employeeId', name: this.translate.instant('Employee ID'), flexGrow: 3, sortable: true},
            {id: 'nationality', prop: 'nationality', name: this.translate.instant('Nationality'), flexGrow: 2, sortable: true},
            {id: 'label', prop: 'preferredVan.label', name: this.translate.instant('Preferred van'), flexGrow: 4, sortable: true},
            {id: 'status', prop: 'status', name: this.translate.instant('Status'), flexGrow: 2, sortable: true},
        ];

        this.appService.selectedWarehouse.subscribe(depot => {
            this.loader = true;
            this.driversService.loadDrivers(depot);
        })

        this.rowClass = van => {
            return { 'row-modified': van.id === this.modifiedId };
        };
    }

    public ngOnDestroy() {
        console.log(`${this.TAG}, ngOnDestroy`);
        if (this.drivers$) {
            this.drivers$.unsubscribe();
        }
        if (this.routeQueryParams$) {
            this.routeQueryParams$.unsubscribe();
        }
    }

    public goToDetails(event): void {
        if (event.type === 'click') {
            this.navService.goToPage(`/drivers/${event.row.id}/show`);
        }
    }

    public goToAddPage(): void {
        this.navService.goToPage(`/drivers/${this.drivers.length + 1}/create`);
    }

    public goToBarcodesPage(): void {
        this.navService.goToPage('/drivers/drivers-barcodes');
    }

    public isNum(status: string): boolean {
        return (status === 'num');
    }

    public isColumnToTranslate(status: string): boolean {
        return (status === 'nationality' || status === 'status');
    }

    public order(driver: Driver): number {
        return this.rows.indexOf(driver) + 1;
    }

    public applyFiltering(event?) {
        this.filterPhrase = event ? event.target.value.toLowerCase() : this.filterPhrase;
        this.rows = this.drivers.filter((driver: Driver) =>
            (driver.label.toLowerCase().indexOf(this.filterPhrase) !== -1 || !this.filterPhrase) &&
            driver.status === this.selectedDriverStatus
        );
    }
}
