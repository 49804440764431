import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError, distinctUntilChanged, filter, flatMap, map, mergeMap } from 'rxjs/operators';

import { PageableResponse } from '@shared/entities/pagable-response';
import { RouteId } from '@shared/entities/routeId';
import { ORDER, SearchHardwareBy } from '@shared/enums/enum';

import { HardwareUsages } from '../entities/hardware-usages';
import { HardwareService } from '../services/hardware.service';

@Component({
    selector: 'search-hardware',
    template: `
        <ng-template #customItemTemplate let-model="item" let-index="index"></ng-template>

        <div class="filter-container input-group delivery-typeahead search-by-hardware-id">
            <ng-container *ngIf="!isDateMode">
                <div class="input-group-prepend">
                    <span class="input-group-text typeahead-loader" id="basic-addon1">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true" [class.notvisible]="!typeaheadLoading"></i>
                        <i class="fa fa-search" aria-hidden="true" [class.notvisible]="typeaheadLoading"></i>
                    </span>
                </div>
                <input
                    id="search-hardware-searchbar"
                    [(ngModel)]="asyncSelected"
                    [typeahead]="dataSource"
                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                    [typeaheadOptionsLimit]="7"
                    [typeaheadItemTemplate]="customItemTemplate"
                    typeaheadOptionField="customerLabel"
                    typeaheadWaitMs="1000"
                    [typeaheadMinLength]="1"
                    [placeholder]="searchLabel"
                    class="form-control tx-uppercase"
                    aria-label="Wyszukaj dostawę"
                    aria-describedby="basic-addon1"
                />
            </ng-container>

            <ng-container *ngIf="isDateMode">
                <div  class="input-group-prepend">
                    <span class="input-group-text typeahead-loader" id="basic-addon1">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true" [class.notvisible]="!typeaheadLoading"></i>
                        <i class="fa fa-calendar" aria-hidden="true" [class.notvisible]="typeaheadLoading"></i>
                    </span>
                </div>
                <ng-datepicker id="search-hardware-searcby-date" class="datepicker-input" [(ngModel)]="asyncSelected" (ngModelChange)="onChangeDate($event)" [options]="datePickerOptions"></ng-datepicker>
            </ng-container>
        </div>
    `,
    styleUrls: ['./hardware-searchbar.component.scss']
})
export class HardwareSearchbarComponent implements OnInit, OnDestroy {
    @Input() public mode: SearchHardwareBy;

    @Input() set pageInfo(pageInfo) {
        const isSearchngBy = this.hardwareService.isSearchingBy;
        if (!_.isUndefined(pageInfo) && isSearchngBy === this.mode) {
            this.page = pageInfo.offset;
            this.generateCall().subscribe();
        }
    }

    public asyncSelected: string;
    public typeaheadLoading: boolean;
    public dataSource: Observable<any>;

    public locale;

    protected page: number = 0;
    protected size = 20;
    protected order: ORDER = ORDER.ASC;
    protected sortBy: string = 'driverId';
    protected pagable: PageableResponse<HardwareUsages> = new PageableResponse<HardwareUsages>(HardwareUsages);

    private searchedPhrase: string = '';

    public datePickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'D MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1,
        locale: this.locale
    };

    public routingSubscription: Subscription;

    get searchHardwareBy() {
        return SearchHardwareBy;
    }

    get isDateMode() {
        return this.mode === SearchHardwareBy.DATE || this.mode === SearchHardwareBy.SHIFT_ID;
    }

    get searchLabel(): string {
        switch (this.mode) {
            case SearchHardwareBy.ID:
                return this.translateService.instant('Search for the hardware by the ID number');
            case SearchHardwareBy.DRIVER_ID:
                return this.translateService.instant('Search for the hardware by the driver ID');
            case SearchHardwareBy.INVENTORY_NO:
                return this.translateService.instant('Search for the hardware by the inventory no');
            case SearchHardwareBy.DRIVER_NAME:
                return this.translateService.instant('Search for the hardware by the driver name');
        }
    }

    constructor(private hardwareService: HardwareService, private translateService: TranslateService, private notifierService: NotifierService, private navigationRoute: ActivatedRoute) {}

    public ngOnInit() {
        this.locale = this.translateService.currentLang === 'en' ? enLocale : plLocale;
        this.datePickerOptions.locale = this.locale;

        if (this.mode === SearchHardwareBy.DATE) {
            this.navigationRoute.params
                .pipe(
                    map((params: any) => params),
                    flatMap(params => {
                        if (!_.isUndefined(params.shiftId)) {
                            const shiftId = RouteId.getShiftId3(params.y, params.m, params.d, params.shiftId);
                            this.asyncSelected = `${params.y}-${params.m}-${params.d}`;
                            this.mode = SearchHardwareBy.SHIFT_ID;
                            this.searchedPhrase = shiftId;
                            return this.hardwareService.searchBy(SearchHardwareBy.SHIFT_ID, shiftId, this.page, this.size, this.sortBy, this.order);
                        } else {
                            this.asyncSelected = `${params.y}-${params.m}-${params.d}`;
                            this.searchedPhrase = this.asyncSelected;
                            return this.hardwareService.searchBy(this.mode, moment(this.asyncSelected).format('YYYY-MM-DD'), this.page, this.size, this.sortBy, this.order);
                        }
                    })
                ).subscribe();
        }

        this.dataSource = new Observable((observer: any) => observer.next(this.asyncSelected))
            .pipe(
                filter((a: Array<any>) => a.length >= 3),
                distinctUntilChanged(),
                mergeMap((token: any) => {
                    this.searchedPhrase = token;
                    return this.generateCall();
                }),
                catchError((error: HttpErrorResponse) => {
                    this.changeTypeaheadLoading(false);
                    return this.displayError(error);
                })
            );
    }

    public ngOnDestroy() {
        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    public onChangeDate(event) {
        this.mode = SearchHardwareBy.DATE;
        this.searchedPhrase = moment(event).format('YYYY-MM-DD');
        return this.hardwareService
            .searchBy(this.mode, moment(event).format('YYYY-MM-DD'), this.page, this.size, this.sortBy, this.order)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.changeTypeaheadLoading(false);
                    return this.displayError(error);
                })
            )
            .subscribe(() => this.changeTypeaheadLoading(false), () => this.changeTypeaheadLoading(false));
    }

    public generateCall() {
        return this.hardwareService.searchBy(this.mode, this.searchedPhrase, this.page, this.size, this.sortBy, this.order);
    }

    public displayError(error: HttpErrorResponse): ObservableInput<{}> {
        if (error.status === 404) {
            this.notifierService.notify('warning', this.translateService.instant('Box not found in this shift!'));
            return [];
        } else {
            this.notifierService.notify('error', this.translateService.instant('Error occurred, please try again!'));
            return throwError(error);
        }
    }
}
