import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { EditUserComponent } from './components/edit-user.component';
import { UsersComponent } from './components/users.component';
import { UsersService } from './services/users.service';
import { UsersRouting } from './users.routing';
import { RolesComponent } from './components/roles/roles.component';
import { RolesService } from './services/roles.service';
import { RolesResolver } from './services/roles.resolver';
import { EditRoleComponent } from './components/roles/edit-role.component';
import { FindRoleResolver } from './services/find-role.resolve';
import { RolesMainComponent } from './components/roles/role-main.component';
import { PermissionsResolver } from './services/permissions.resolver';
import { IsUserSysadminPipe } from './pipes/is-user-sysadmin.pipe';
import { UsersResolver } from './services/users.resolver';
import { NgxMaskModule } from 'ngx-mask';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UsersRouting,
        NgxDatatableModule,
        SharedModule,
        AuthenticationModule,
        MomentModule,
        ReactiveFormsModule,
        NgxMaskModule,
        TooltipModule
    ],
    declarations: [
        UsersComponent, 
        EditUserComponent, 
        RolesComponent, 
        EditRoleComponent, 
        RolesMainComponent, 
        IsUserSysadminPipe
    ],
    providers: [
        UsersService,
        RolesService,
        RolesResolver,
        FindRoleResolver,
        PermissionsResolver,
        UsersResolver
    ]
})
export class UsersModule {
}
