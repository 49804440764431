import { Pipe, PipeTransform } from '@angular/core';
import { ActivityType } from '@enums/enum';
import { RouteExt } from '@entities/route-ext';
import { Activity } from '@entities/activity';
import { DManagementSettingInterface } from '../interfaces/d-management-settings.interface';

@Pipe({
    name: 'isCrazyDeliveryPipe',
    pure: true
})
export class IsCrazyDeliveryPipe implements PipeTransform {

    public transform(route: RouteExt, activity: Activity, deliveryManagmentSettings: DManagementSettingInterface): boolean {

        if (!deliveryManagmentSettings) {
            return false;
        }

        const activityAfter = route.activities.find((a: Activity) => a.stepNo > activity.stepNo && a.type === ActivityType.DRIVING);
        const activityAfterDuration = (activityAfter) ? activityAfter.durationSeconds : 0;
        
        if (activity.durationSeconds + activityAfterDuration < deliveryManagmentSettings.drivingTime) {
            return false;
        }

        if (activity.type !== ActivityType.DRIVING || activity.stepNo < 3) {
            return false;
        }

        const p = (activity.durationSeconds * 100) / (activity.durationSeconds + activityAfterDuration);

        if (p < deliveryManagmentSettings.drivingToPercent && activity.durationSeconds < deliveryManagmentSettings.drivingTime && 
                activityAfterDuration < deliveryManagmentSettings.drivingTime && p !== 100) {
            return false
        }

        return true;
    }
}
