import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, Observable, of } from 'rxjs';
import { filter, take, map, tap } from 'rxjs/operators';
import { RoutePath } from '@entities/route-path';
import { Warehouses } from '@shared/constants/warehouses.constants';
import { RouteExt } from '@entities/route-ext';
import { AppService } from '@services/app.service';
import { Boo } from '@entities/boo';
import { environment } from '@environment';
import { Client } from '@enums/enum';

@Component({
    selector: 'route-map',
    templateUrl: 'route-map.component.html',
    styleUrls: ['route-map.component.scss']
})
export class RouteMapComponent implements OnInit, OnDestroy {
    public path: RoutePath;
    public route: RouteExt;
    private assetsPath = '/assets/img';
    private markerPath = `${this.assetsPath}/markers`;
    private zoom: number = 11;
    private routeId;
    private routingSubscription: Subscription;
    private routeParams: {
        y: string;
        m: string;
        d: string;
        shiftId: string;
        routeNumber: number;
    };
    public minimumClusterSize: Observable<number> = of(2);
    public loading = false;
    public warehouseDictionary$: Observable<any>;
    public client = environment.client;
    public inpostClient: string = Client.INPOST;
    public friscoClient: string = Client.FRISCO;


    constructor(
        private appService: AppService,
        private navigationRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        const warehouse = localStorage.getItem('depot');
        this.route = this.navigationRoute.snapshot.data.route;
        this.path = new RoutePath().deserialize(this.route);

        console.log(this.path);

        this.warehouseDictionary$ = this.appService.getWarehouses().pipe(
            map(boo => boo.find(b => b.id === parseInt(warehouse, 0))),
            take(1)
        );
    }

    public ngOnDestroy(): void {
        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }
    }
}
