import * as _ from 'lodash';

import { Complaint } from './complaint';
import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class Item extends Entity implements Serializable<Item> {
    public productId: string;
    public brand: string;
    public ean: string;
    public fulfilled: number;
    public num: number;
    public name: string;
    public ordered: number;
    public weight: string;
    public label: string;
    public price: number;
    public isActivated?: boolean = false;
    public version: string = '';
    public complaints: Complaint[] = [];
    public age: number;
    public merchant: string;

    public deserialize(data: any, index?: number) {
        if (!data) {
            return;
        }

        this.productId = _.get(data, 'productId');
        this.brand = _.get(data, 'brand');
        this.ean = _.get(data, 'ean');
        this.fulfilled = _.get(data, 'fulfilled');
        this.num = index;
        this.name = _.get(data, 'name');
        this.ordered = _.get(data, 'ordered');
        this.weight = _.get(data, 'weight');
        this.label = this.productNameLabel;
        this.price = _.get(data, 'price');
        this.version = _.get(data, 'version');
        this.age = _.get(data, 'age');
        this.merchant = _.get(data, 'merchant');

        let rawComplaints = _.get(data, 'complaints', []);

        if (!rawComplaints || !_.isArray(rawComplaints)) {
            rawComplaints = [];
        }

        rawComplaints.forEach(complaint => {
            this.complaints.push(new Complaint().deserialize(complaint, this.price));
        });

        return this;
    }

    public serialize() {
        return {
            productId: this.productId,
            brand: this.brand,
            ean: this.ean,
            fulfilled: this.fulfilled,
            name: this.name,
            ordered: this.ordered,
            weight: this.weight,
            price: this.price,
            merchant: this.merchant,
            version: this.version
        };
    }

    get productNameLabel() {
        return `${(this.brand) ? this.brand : '-'}, ${(this.name) ? this.name : '-'}, ${(this.weight) ? this.weight : '-'}`;
    }
}
