import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { RoutesService } from '@routes/services/routes.service';
import { NavService } from '@services/nav.sevice';

@Component({
    selector: 'payments',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h4><i-feather name="credit-card"></i-feather> {{'Payments' | translate}}</h4>
                </div>
                
                <div toolbar>
                    <div style="flex-grow: 2">
                        <shift-picker class="text-center" (changedShift)="changedDate($event)" [shift]="shiftId"></shift-picker>
                    </div>
                </div>
                <hr />
            </div>

            <div content>
                <payments-shift-list></payments-shift-list>
            </div>
        </layout-default>
    `,
    styleUrls: ['payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {
    public tag: string = '[PaymentsComponent]';

    private routingSubscription: Subscription;
    public routeListLoading: boolean = false;

    public shiftId: string;
    public year: string;
    public month: string;
    public day: string;
    public shift: string;

    constructor(
        private navigationRoute: ActivatedRoute,
        private routeService: RoutesService,
        private navService: NavService,
        private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.routingSubscription = this.navigationRoute.params
            .subscribe((params) => {
                if (_.keys(params).length > 0) {
                    this.cdr.detectChanges();
                    const shiftId = _.values(params).join('/');
                    this.routeService.setSelectedShiftId(shiftId);
                    this.shiftId = shiftId;
                }
            });
    }

    public changedDate(shiftId): void {
        this.routeListLoading = true;
        console.log(this.tag, '(changedDate)', shiftId);
        this.shiftId = shiftId;

        if (shiftId) {
            [this.year, this.month, this.day, this.shift] = shiftId.split('/');
            this.navService.goToPage(`/payments/${shiftId}`);
        }
    }

    public ngOnDestroy(): void {
        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }
    }
}
