<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="calendar"></i-feather> {{ 'Calendar overview' | translate }}</h4>
        </div>
    </div>

    <div content>
        <ng-container *ngIf="(depots$ | async) as depots">
            <div class="d-flex justify-content-between align-items-center">
                <ul class="depot-selector">
                    <li *ngFor="let depot of depots">
                        <button class="btn btn-default mg-r-10" [ngClass]="{'btn-brand-01': selectedDepot == depot.id}"
                            (click)="changeDepot(depot.id)" [disabled]="loader">{{ depot.name }}</button>
                    </li>
                </ul>

                <a [href]="['/calendar/month']" class="btn btn-default tx-10 tx-medium tx-color-03 tx-sans mg-r-10">
                    {{ 'Go to full calendar' | translate }}</a>
            </div>

        </ng-container>

        <ng-container *ngIf="(currentMonth$ | async) else loadingTmpl">
            <div *ngFor="let day of list; let i = index" class="day">

                <div class="day__title">
                    <div [ngSwitch]="i">
                        <ng-container *ngSwitchCase="0">
                            <strong>{{'Today' | translate}}</strong>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <strong>{{'Tomorrow' | translate}}</strong>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <strong>{{ day.date | amLocale: locale | amDateFormat: 'dddd, D MMM'}}</strong>
                        </ng-container>
                        <span>, {{ day.dailyShiftConfig | calendarTitlePipe }}</span>
                    </div>
                </div>
                <div class="day__shifts">
                    <div class="day__shifts__shift day__shifts__shift--description">
                        <div
                            class="day__shifts__shift__order_label text-right tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans">
                            {{'Orders' | translate}}
                        </div>
                        <div
                            class="day__shifts__shift__limits day__shifts__shift__limits--label tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans">
                            {{'Limits' | translate}}
                        </div>
                        <!-- <div
                            class="day__shifts__shift__product_lines day__shifts__shift__product_lines--label tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans">
                            {{'Product lines short' | translate}}
                        </div> -->
                        <div
                            class="day__shifts__shift__cutoff day__shifts__shift__cutoff--label tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans">
                            {{'Cutoff' | translate}}
                        </div>
                    </div>
                    <ng-container *ngIf="day.dailyShiftConfig">
                        <ng-container *ngFor="let shift of day.dailyShiftConfig.shifts">

                            <div *ngFor="let cutoff of shift.shiftCutoffData; let cutoffIndex = index;"
                                class="day__shifts__shift">
                                <ng-container *ngIf="!loader else loadingTmpl">

                                    <div class="day__shifts__shift__name">
                                        <p class="day__shifts__shift__name__label">
                                            {{ shift.fulfilment.label | translate }}
                                            {{ cutoff.time.slice(0,5) }}
                                        </p>
                                        <p class="day__shifts__shift__name__status tx-11"
                                            [ngClass]="{'day__shifts__shift__name__status--open': cutoff.status == 'OPEN'}">
                                            <i class="fas fa-unlock"></i>
                                            <i class="fas fa-lock"></i>
                                            <span>{{ cutoff.status | translate}}</span>
                                        </p>
                                    </div>
                                    <div class="day__shifts__shift__orders">
                                        <ng-container *ngIf="cutoffIndex === 0">
                                            <strong class="mg-r-10">{{ shift.statuses | sumValuesPipe | number: '1.0-0'
                                                }}</strong>
                                            <app-calendar-overview-bar [shift]="shift"></app-calendar-overview-bar>

                                        </ng-container>
                                        <hr />
                                    </div>
                                    <div class="day__shifts__shift__limits">
                                        <div class="day__shifts__shift__limits__item">
                                            <ng-container
                                                *ngTemplateOutlet="displayValueTmpl; context: { value: cutoff.maximumDeliveries }"></ng-container>

                                            <p class="tx-11 tx-medium tx-color-03 tx-sans">
                                                {{'Orders' | translate}}</p>
                                        </div>
                                        <div class="day__shifts__shift__limits__item">
                                            <ng-container
                                                *ngTemplateOutlet="displayValueTmpl; context: { value: cutoff.maximumRoutes }"></ng-container>


                                            <p class="tx-11 tx-medium tx-color-03 tx-sans">
                                                {{'Routes' | translate}}</p>
                                        </div>
                                        <div class="day__shifts__shift__limits__item">
                                            <p>{{ shift.fulfilment.fleetMaximum | number: '1.0-0' }}
                                            </p>
                                            <p class="tx-11 tx-medium tx-color-03 tx-sans">
                                                {{'Vans' | translate}}</p>
                                        </div>
                                        <!-- <div class="day__shifts__shift__limits__item">
                                            <ng-container
                                                *ngTemplateOutlet="displayValueTmpl; context: { value: cutoff.maximumProductLines }"></ng-container>

                                            <p class="tx-11 tx-medium tx-color-03 tx-sans">
                                                {{'Product lines short' | translate}}</p>
                                        </div> -->

                                        <button *ngIf="(shift.shiftStatusData.status | isShiftOpenPipe)"
                                            (click)="changeShiftSettings(shift, cutoff.id, $event)"
                                            class="btn btn-default tx-10 tx-medium tx-color-03 tx-sans w-100 mt-2">
                                            {{ 'Edit limits' | translate }}</button>
                                    </div>
                                    <div class="tx-12 tx-medium tx-color-03 tx-sans">
                                        <ng-container>
                                            {{cutoff.cutoff | amLocale: locale | amDateFormat: 'dddd, HH:mm'}}
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

</layout-default>

<ng-template #loadingTmpl>
    <div class="loading">
        <div class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        </div>
    </div>
</ng-template>

<ng-template #displayValueTmpl let-value="value">
    <p *ngIf="value === -1">
        <svg width="16px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <rect fill="none" height="256" width="256" />
            <path d="M106.6,152.1l-8.7,9.8a47.9,47.9,0,1,1,0-67.8l60.2,67.8a47.9,47.9,0,1,0,0-67.8l-8.7,9.8" fill="none"
                stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
        </svg>
    </p>
    <p *ngIf="value !== -1">{{ value | number: '1.0-0' }}</p>
</ng-template>

<!-- {{'OPEN' | translate}} -->