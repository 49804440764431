<div class="chartsWrapper" #chartsWrapper>

    <button (click)="toggleFullscreen()" class="btn btn-default fullscreen-button">
        <span class="material-icons">{{fullscreen ? 'fullscreen_exit' : 'fullscreen'}}</span>
    </button>

    <div *ngFor="let depot of punctuality" class="depot card box">

        <p class="depotName">
            <strong>{{depot.name}}</strong>
        </p>

        <div class="shift-charts headers">
            <span></span>
            <span>{{'Delivery' | translate}}</span>
            <span>{{'Fulfillment' | translate}}</span>
            <span>{{'Loading' | translate}}</span>
        </div>

        <div *ngFor="let shift of depot.shifts" class="shift">

            <div class="shift-charts">
                <span class="shiftName">{{shift.name | translate}}</span>

                <app-dashboard-chart [quantityName]="'deliveries'"
                                     [chart]="shift.deliveryPunctuality"
                                     [showTotals]="settings.showTotalsDelivery"
                                     [showPartials]="settings.showPartials"
                >
                </app-dashboard-chart>
                <app-dashboard-chart [chart]="shift.fulfillmentPunctuality"
                                     [quantityName]="'routes'"
                                     [showTotals]="settings.showTotalsFulfillment"
                                     [showPartials]="settings.showPartials"
                >
                </app-dashboard-chart>
                <app-dashboard-chart [chart]="shift.loadingPunctuality"
                                     [quantityName]="'routes'"
                                     [showTotals]="settings.showTotalsLoading"
                                     [showPartials]="settings.showPartials"
                >
                </app-dashboard-chart>
            </div>


        </div>

    </div>

    <div *ngIf="fullscreen" class="powered">
        <div>Proudly Powered by&nbsp;<a title="Open Routing" href="http://open-routing.com"> Open Routing</a></div>
    </div>

</div>

