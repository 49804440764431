import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';

@Component({
    selector: 'booking-availability',
    templateUrl: 'booking-availability.component.html',
    styleUrls: ['booking-availability.component.scss']
})
export class BookingAvailabilityComponent {
    public locale = null;
    public _date = moment().add(1, 'days');

    private datePickerOptions: DatepickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'D MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1,
        locale: this.locale
    };

    set date(date) {
        this._date = date;
    }

    get date() {
        return this._date;
    }

    constructor(private translate: TranslateService) {
        this.locale = translate.currentLang === 'en' ? enLocale : plLocale;
        this.datePickerOptions.locale = this.locale;
    }
}
