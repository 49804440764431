export const dashboardMapStyles = [
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

export const dashboardSettingsInitial = {
  mode: 'presentation',
  pollingTimeSeconds: 240,
  depotCyclingTimeSeconds: 10,
  animationTimeSeconds: 2,
  animationFps: 40,
  vehicleScale: 100,
  earlyVehicleColor: '#1976d2',
  okVehicleColor: '#32a63b',
  lateVehicleColor: '#ff0000',
  unknownVehicleColor: '#868686',
  showPartials: false,
  showTotalsDelivery: true,
  showTotalsFulfillment: false,
  showTotalsLoading: false,
  hideVehiclesWithoutLastStop: false,
  hideVehiclesWithoutNextStop: false,
  startsWithin: '',
  endsWithin: ''
}
