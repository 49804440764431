<ng-container *ngIf="(licensing$ | async) as licensing; else loaderTmpl">

    <div class="row">
        <!-- <div class="col-sm-6">
            <div class="mg-50">
                <h2 class="mg-b-20 tx-semibold tx-14">{{'Excludes' | translate}}:</h2>
                <div class="form-group d-flex justify-content-center align-items-center">
                    <input [(ngModel)]="excludes" class="form-control" />
                    <button (click)="changeFilters()" class="btn btn-white mg-l-10">{{'Update' | translate}}</button>
                </div>

                <h2 class="mg-b-20 tx-semibold tx-14">{{'Includes' | translate}}:</h2>
                <div class="form-group d-flex justify-content-center align-items-center">
                    <input [(ngModel)]="includes" class="form-control" />
                    <button (click)="changeFilters()" class="btn btn-white mg-l-10">{{'Update' | translate}}</button>

                </div>
            </div>
        </div> -->
        <div class="offset-sm-6 col-sm-6">
            <div class="d-flex justify-content-center flex-column mg-50">
                <h2 class="mg-b-20 tx-semibold tx-14">{{'Billing' | translate}}:</h2>
                <div class="d-flex justify-content-center mg-b-10">
                    <div class="flex-grow-1">{{'Number of unique mobile app devices used in OR' | translate}}</div>
                    <div>{{mobileAppCount | number:'1.0-0'}}</div>
                </div>
                <div class="d-flex justify-content-center mg-b-10">
                    <div class="flex-grow-1">{{'Mobile app fee' | translate}}</div>
                    <div>{{mobileAppFee | number: '1.2-2' }} {{ CurrencyType.PLN | translate }}</div>
                </div>
                <div class="d-flex justify-content-center mg-t-0">
                    <div class="flex-grow-1 tx-bold">{{'Total' | translate}}</div>
                    <div class="tx-bold">{{mobileAppCount * mobileAppFee | number: '1.2-2' }} {{ CurrencyType.PLN | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngFor="let depots of licensing | keyvalue">
        <h3>{{'Depot' | translate}}: <span>{{depots.key | findWarehousePipe}}</span>, {{'Sum' | translate}}: <span>{{depots.value.length}}</span></h3>
        
        <ng-container *ngIf="!depots.value.length">
            <ng-container *ngTemplateOutlet="tableTmpl; context: { data: []}"></ng-container>
        </ng-container>

        <ng-container *ngIf="depots.value.length">
            <ng-container *ngTemplateOutlet="tableTmpl; context: { data: depots.value}"></ng-container>
        </ng-container>
    </div>

     <!-- <ng-container *ngIf="licensing.length">
         <ng-container *ngTemplateOutlet="tableTmpl; context: { data: licensing}"></ng-container>
     </ng-container>

     <ng-container *ngIf="!licensing.length">
         <ng-container *ngTemplateOutlet="tableTmpl; context: { data: []}"></ng-container>
     </ng-container> -->


</ng-container>

<ng-template let-data="data" #tableTmpl>
    <ngx-datatable 
        id="distances" 
        class="bootstrap" 
        [rows]="data" 
        [columns]="columns" 
        [columnMode]="'flex'"
        [headerHeight]="50" 
        [footerHeight]="50" 
        [messages]="tableMessages"
        [sortType]="'single'"
        [rowHeight]="'auto'"
        [limit]="20"
        [externalPaging]="false"
        [rowClass]="rowClass"
        [scrollbarH]="scrollBarHorizontal">
    
    <ng-container *ngFor="let col of columns">
        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                <b (click)="sort()">{{ col.name | translate }}</b>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div [ngSwitch]="col.prop">
                    <div *ngSwitchCase="'hardware.depotId'">
                        {{findDepotRef(value)}}
                    </div>
                    <div *ngSwitchDefault>{{value}}</div>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
</ngx-datatable>
</ng-template>

<ng-template #loaderTmpl>
    <div class="load-spinner text-center">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>&nbsp;{{ 'Please, wait!' | translate }}
    </div>
</ng-template>
