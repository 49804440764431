import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { environment } from '@environment';
import { ProfanityDict } from '../interfaces/profanity-dict.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfanityFilterService extends CrudService<ProfanityDict, number>{

    protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/filterword/v1`;

    constructor(
        protected _http: HttpClient,
        protected utilsService: UtilsService) {
        super(_http, ProfanityFilterService.BASE_API, utilsService);
    }

}
