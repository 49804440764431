
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { SmsTemplatesService } from '../services/sms-templates.service';

@Injectable()
export class SmsTemplateResolver implements Resolve<any> {
    constructor(
        private readonly smsTemplatesService: SmsTemplatesService,
        private readonly utilsService: UtilsService
    ) { }

    public resolve(route: ActivatedRouteSnapshot) {

        const params = {
            id: this.utilsService.findUpRouteParams(route, 'id'),
        }

        return this.smsTemplatesService.findOne(params['id'], params);
    }
}
