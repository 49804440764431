
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Integration' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_integration_edit]" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">
                <div *ngFor="let control of regularControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div [style.height.px]="24">
                        <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                        <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control] }}</div>
                </div>
                <div *ngFor="let control of booleanControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div [style.height.px]="24">
                        <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                        <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control].toString() | translate }}</div>
                </div>
                <div *ngFor="let control of booleanControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div [style.height.px]="24">
                        <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                        <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control].toString() | translate }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="mode == 'edit' || mode == 'saving'" [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <h3 class="title">{{ 'Integration' | translate }}</h3>

                <div class="form">
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <div *ngFor="let control of regularControls; let index = index">
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                            <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ control | translate }}:</i-feather>
                            </sup>
                        </div> 
                        <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedShiftConfig[control]" />
                    </div>
                    <div>
                        <div for="slotOverLapping">{{ 'shiftProperty.slotOverLapping' | translate }}:</div>
                        <select id="slotOverLapping" [(ngModel)]="editedShiftConfig.slotOverLapping">
                            <option value="30">30</option>
                            <option value="60">60</option>
                        </select>
                    </div>
                    <div *ngFor="let control of booleanControls; let index = index"> 
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                            <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig[control]">
                            <option [ngValue]="true">{{ 'True' | translate }}</option>
                            <option [ngValue]="false">{{ 'False' | translate }}</option>
                        </select>
                    </div>
                    <div *ngFor="let control of booleanControls; let index = index"> 
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                            <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig[control]">
                            <option [ngValue]="true">{{ 'True' | translate }}</option>
                            <option [ngValue]="false">{{ 'False' | translate }}</option>
                        </select>
                    </div>
                </div>
            </editor-window>
        </div>
    