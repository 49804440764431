import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';

import { Warehouse } from '@enums/enum';

@Injectable()
export class CanActivateSimulator implements CanActivate, CanActivateChild {

    constructor() {}

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        localStorage.setItem('depot', Warehouse.SIM);
        return true;
    }

    public canActivateChild() {
        return true;
    }
}
