import * as _ from 'lodash';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class Bag extends Entity implements Serializable<Bag> {
    public id: string;
    public ref: string;
    public type: string;
    public deliveryScanAt: string;
    public loadedAt: string;
    public refNum: string;
    public refLead: string;

    public deserialize(data: any) {
        this.id = _.get(data, 'id', '');
        this.ref = _.get(data, 'ref', '');
        this.refNum = this.ref.substr(-2);
        this.refLead = this.ref.substr(0, -2);
        this.type = _.get(data, 'type', '');
        this.deliveryScanAt = _.get(data, 'deliveryScanAt');
        this.loadedAt = _.get(data, 'loadedAt');

        return this;
    }

    get isScanned(): boolean {
        return (!_.isEmpty(this.deliveryScanAt));
    }

    get isLoaded(): boolean {
        return (!_.isEmpty(this.loadedAt));
    }

    public serialize() {
        return {};
    }
}
