
        <div class="wrapper">
            <div class="header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div class="content">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
        <div class="sidebar">
            <ng-content select="[sidebar]"></ng-content>
        </div>
        <div *ngIf="help" class="sidebar sidebar-help" @animateSidebar>
            <sidebar-with-header>
                <div header class="vans-drivers-header pd-l-15 d-flex justify-content-between align-items-center">
                    <h6 class="tx-13 tx-spacng-1 tx-uppercase tx-semibold mg-b-0 d-inline btn-drivers active">{{'Help' | translate}}</h6>
                    <button (click)="close()" class="btn btn-link"><i-feather name="X"></i-feather></button>
                </div>
                <div content [innerHTML]="help"></div>
            </sidebar-with-header>
        </div>
    