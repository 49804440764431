
        <br />
        <h6 class="d-block white">
            <strong>{{ model.name | translate }}</strong>
        </h6>
        <p class="tooltip-card">
            <span *ngIf="model.series">{{ model.series }}<br /></span><br />
            <span class="d-block">{{ 'Total deliveries' | translate }}: {{ model.totalDeliveries }}</span>
            <span class="d-block">{{ model.name | translate }}: {{ model.value }}</span>
            <span class="d-block"
                ><strong>{{ model.percent }}%</strong></span
            ><br />
            <!-- <span *ngIf="model.averageEarly" class="d-block">{{ 'Avarage Early' | translate }}: {{ timeConverter(model.averageEarly) }}</span>
            <span *ngIf="model.averageLate" class="d-block">{{ 'Avarage Late' | translate }}: {{ timeConverter(model.averageLate) }}</span> -->
        </p>
        <!--<pre class="white">{{model|json}}</pre>-->
    