import {Component, Input, OnInit} from '@angular/core';

import {DashboardShiftChart} from '../../interfaces/DashboardShiftChart';
import {PunctualityChart} from '../../interfaces/DashboardPunctuality';

@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss']
})
export class DashboardChartComponent implements OnInit {

  @Input() public quantityName: string;
  @Input() public chart: PunctualityChart;
  @Input() public showTotals: boolean;
  @Input() public showPartials: boolean;

  constructor() { }

  public ngOnInit() {
  }

  public getWidth(num: number, report: PunctualityChart): string {
    return `${num * 100 / report.total}%`;
  }

  public getRemainder(report: PunctualityChart): number {
    return report.total
      - (report.completeBeforeSchedule || 0)
      - (report.completeOnSchedule || 0)
      - (report.completeAfterSchedule || 0)
      - (report.incompleteBeforeSchedule || 0)
      - (report.incompleteAfterSchedule || 0);
  }

}
