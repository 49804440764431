<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="dollar-sign"></i-feather> {{'Create agency' | translate}}
            </h4>
        </div>

        <hr />
    </div>

    <div content>

        <ng-container *ngIf="(agencySubscriptions$ | async) as agencySubscriptions">

            <div *ngIf="!loader" class="content bg-white col-8 offset-2 pd-40">

                <form [formGroup]="form">

                    <div class="form-group row">
                        <label class="col-2" for="code">{{'Agency' | translate}}:</label>
                        <div class="col-10">
                            <select class="custom-select" formControlName="salaryPlanId">
                                <option *ngFor="let a of agencySubscriptions" [value]="a.id">{{a.name}}</option>
                            </select>
                            <div *ngIf="form.get('salaryPlanId').hasError('required') && form.get('salaryPlanId').touched"
                                class="error-color">
                                {{ "The field is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <ng-container formGroupName="dateRange">
                        <div *ngFor="let i of ['from', 'to']" class="form-group row">
                            <label class="col-2" [for]="i">{{ i | translate }}:</label>
                            <div class="col-10">
                                <ng-datepicker class="datepicker-input form-group" [formControlName]="i" [id]="i" [headless]="disabled" [options]="options"> </ng-datepicker>
                                <input *ngIf="disabled" type="text" class="form-control" [disabled]="disabled" [value]="form.get(i).value | date: 'dd.MM.yyyy'" />
                                <div *ngIf="form.get('dateRange').get(i).hasError('required') && form.get('dateRange').get(i).touched" class="error-color">{{ 'The field is required' | translate }}!</div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="d-flex justify-content-end mg-t-20">
                        <div class="col-2">
                            <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                                class="btn btn-sm btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Add report" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>

        </ng-container>

    </div>
</layout-default>