


        <ul *ngIf="!loading">
            <li>{{'All' | translate}} - <strong>{{results.length}}</strong><span class="comma">,</span></li>
            <li *ngFor="let key of countResultsKeys()">{{key | translate}} - <strong>{{value(key)}}</strong><span class="comma">,</span></li>
        </ul>

        <ngx-datatable
                *ngIf="!loading"
                id="simulation-results"
                class="bootstrap"
                [rows]="results"
                [columns]="columns"
                [columnMode]="'flex'"
                [headerHeight]="50"
                [footerHeight]="50"
                [messages]="tableMessages"
                [limit]="30"
                [sortType]="'multi'"
                [rowHeight]="'auto'">
        </ngx-datatable>

        <div *ngIf="loading" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
        </div>

        <!-- {{'INITIAL_ERROR' | translate}} -->
        <!-- {{'MAPPING_ERROR' | translate}} -->
        <!-- {{'ERROR' | translate}} -->
        <!-- {{'NO_SLOTS_AVAILABLE' | translate}} -->
        <!-- {{'RESERVED' | translate}} -->
        <!-- {{'CONFIRMED' | translate}} -->

    