import * as moment from 'moment';
import * as _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { RouteExt } from '@entities/route-ext';
import { Activity } from '@entities/activity';
import { TranslateService } from '@ngx-translate/core';
import { secToHours } from '@shared/functions/secToHours.function';

@Pipe({
    name: 'endShiftPaddingPipe',
    pure: true
})
export class EndShiftPaddingPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    public transform(route: RouteExt, shift: ShiftConfig): string {
        const endShift = moment(shift.date + ' ' + shift.endTime);
        const lastActivity: Activity = route.activities[route.activities.length - 1];
        const diff = endShift.diff(lastActivity.planning.to, 'seconds');

        return (diff > 0 ) ? `${this.translate.instant('Time till end of shift')}: ${secToHours(diff)}` : '';
    }
}
