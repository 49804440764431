import * as _ from 'lodash';

import { Customer } from './customer';
import { Delivery } from './delivery';
import { Entity } from './Entity';
import { Location } from './location';
import { Serializable } from './Serializable';

/**
 * Deliveries search response
 */
export class DeliveriesSearchResponse extends Entity implements Serializable<DeliveriesSearchResponse> {

    public customer: Customer = new Customer();
    public delivery: Delivery = new Delivery();
    public locations: Array<Location> = [];

    public deserialize(data: any) {
        this.customer = (new Customer()).deserialize(_.get(data, 'customer'));
        this.delivery = (new Delivery()).deserialize(_.get(data, 'delivery'));
        const locationsRaw = _.get(data, 'locations');
        locationsRaw.map(element => this.locations.push(new Location().deserialize(element)));

        return this;
    }

    public serialize() {
        return {
            customer: this.customer.serialize(),
            delivery: this.delivery.serialize(),
            locations: this.locations
        };
    }
}
