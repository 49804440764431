import { SimulationStepResult, SimulationType, SlotChoiceStrategy } from '@shared/enums/enum';

export class Finalize {
    public fixedSlot: string;
    public id: string;
    public queueElementId: number;
    public result: SimulationStepResult;
    public slotPicked: string;
    public slotPickingStrategy: SlotChoiceStrategy;
    public slotsAvailable: Array<string>;
    public type: SimulationType;
}
