import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';

import { CalendarModule } from '@calendar/calendar.module';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { VisualiserModule } from '../visualiser/visualiser.module';
import { CreateDatasetComponent } from './components/create-dataset.component';
import { CreateSimulationComponent } from './components/create-simulation.component';
import { ExecuteSimulationComponent } from './components/execute.component';
import { FinalizeSimulationComponent } from './components/results.component';
import { SimulatorComponent } from './components/simulator.component';
import { SolutionComponent } from './components/solution.component';
import { CanActivateSimulator } from './services/can-activate-simulator.service';
import { CanDeactivateSimulator } from './services/can-deactivate-simulator.service';
import { SimulationResolve } from './services/simulation.resolve';
import { SimulatorService } from './services/simulator.service';
import { SimulatorRouting } from './simulator.routing';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        AuthenticationModule,
        SimulatorRouting,
        NgxDatatableModule,
        ReactiveFormsModule,
        VisualiserModule,
    CalendarModule,
        MomentModule
    ],
    declarations: [
        SimulatorComponent,
        CreateDatasetComponent,
        CreateSimulationComponent,
        SolutionComponent,
        ExecuteSimulationComponent,
        FinalizeSimulationComponent],
    providers: [SimulatorService, SimulationResolve, CanDeactivateSimulator, CanActivateSimulator]
})
export class SimulatorModule {
}
