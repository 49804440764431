
    <app-navbar class="d-print-none"></app-navbar>

    <div class="content content-fixed content-auth-alt" [style.height.vh]="100">
      <div class="container ht-100p tx-center">
          <div class="ht-100p d-flex flex-column align-items-center justify-content-center">
              <h1 class="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5"><i-feather name="activity"></i-feather> {{'404 Page Not Found' | translate}}</h1>
              <h5 class="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Oopps. {{'The page you were looking for doesn/t exist' | translate}}.</h5>
              <div class="mg-b-40"><button class="btn btn-white pd-x-30" (click)="back()">{{'Back to homepage' | translate}}</button></div>
          </div>
      </div>
    </div>

    <div class="col-12 footer outlet-container">
      Proudly Powered by&nbsp;<a title="Open Routing" href="http://open-routing.com"> Open Routing</a>&nbsp;|&nbsp;Copyright &copy; 2018-2019&nbsp;
    </div>
