import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShiftPlanningWizardService } from '@calendar/services/shift-planning-wizard.service';
import { Observable, Subject } from 'rxjs';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { switchMap, tap, takeUntil } from 'rxjs/operators';
import { ShiftsService } from '@services/shifts.service';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { AppService } from '@services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Depot } from '@interfaces/depot.interface';

@Component({
  selector: 'app-shift-planning-edit-new',
  templateUrl: './shift-planning-edit-new.component.html',
  styleUrls: ['./shift-planning-edit-new.component.scss'],
})
export class ShiftPlanningEditNewComponent implements OnInit, OnDestroy {

  public shiftPlanning: ShiftPlanning | null;
  public depot$: Observable<Depot[]>;
  public level: string;
  public loader = true;
  public destroy$ = new Subject<boolean>();

  constructor(
    private readonly wizardService: ShiftPlanningWizardService,
    private readonly shiftService: ShiftsService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly navService: NavService,
    private readonly router: Router,
    private readonly appServie: AppService) {
   
  }

  public ngOnInit() {

    const id = this.navigationRoute.snapshot.params.id;

    if (id !== '-1') {
     
      this.navigationRoute.params.pipe(
        switchMap(params => {
          return this.shiftService.getOneShiftPlanning(params['id']);
        }),
        tap(shift => this.wizardService.create(shift)),
        takeUntil(this.destroy$)
      ).subscribe(() => this.loader = false);

    } else {
     
      this.wizardService.create(null);

    }
   

    this.wizardService.getModel()
      .pipe(takeUntil(this.destroy$))
      .subscribe(a => this.loader = false);
  }

  public checkStep(step: number): boolean {
    return window.location.href.includes(`step-${step}`);
  }

  public goToStep(step: number) {
    this.router.navigate([`./step-${step}`], {relativeTo: this.navigationRoute})
  }

  public ngOnDestroy() {
    this.wizardService.destroy();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
