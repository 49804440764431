<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="users"></i-feather> {{'Users' | translate}}</h4>
        </div>

        <div toolbar>
            <a id="users-btn-create" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-default"
                routerLink="/users/new/create">{{'Add new user' | translate}}</a>
            <div id="users-btn-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}"
                        (keyup)='applyFiltering($event)' />
                </div>
            </div>
        </div>
        <hr>
    </div>

    <div content>
        <div *ngIf="!loader && users.length">
            <ngx-datatable class="bootstrap" id="users-table" [rows]="users" [columns]="columns" [columnMode]="'flex'"
                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="15">

                <ng-container *ngFor="let col of columns, let i = index;">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <b
                                [ngClass]="{'d-block text-center': col.prop === 'options' || col.prop === 'createdAt' || col.prop === 'sysadmin'}">
                                {{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"
                            ngx-datatable-cell-template>
                            <span id="users-table-col-{{col.prop}}" class="d-block text-center"
                                *ngIf="isColumnCreatedAt(col.prop)">
                                {{value | amLocale: locale | amDateFormat:'D MMMM YYYY' }}</span>
                            <span id="users-table-col-{{col.prop}}" *ngIf="isColumnId(col.prop)">
                                {{ rowIndex + 1 }}.</span>
                            <div *ngIf="isColumnOptions(col.prop)">
                                <div class="dropdown" dropdown>
                                    <button class="btn btn-outline-secondary btn-xs dropdown-toggle tx-uppercase"
                                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{'Select' | translate}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right div-main-actions"
                                        aria-labelledby="dropdownMenuButton">
                                        <a id="users-btn-edit"
                                            class="btn btn-outline-secondary btn-xs btn-uppercase dropdown-item"
                                            [routerLink]="'/users/' + row.id + '/edit'">
                                            {{'Edit' | translate}}
                                        </a>
                                        <a id="users-btn-edit"
                                            class="btn btn-outline-secondary btn-xs btn-uppercase dropdown-item"
                                            (click)="sendTestSmsNotification(row.id)">
                                            {{'Send SMS notification' | translate}}
                                        </a>
                                        <a id="users-btn-delete"
                                            class="btn btn-outline-secondary btn-xs btn-uppercase dropdown-item"
                                            (click)="delete(row)">
                                            {{'Delete' | translate}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <span id="users-table-col-{{col.prop}}" *ngIf="isColumnDynamicRoles(col.prop)">
                                <ul class="roles">
                                    <li *ngFor="let role of value">{{role.name | translate}}</li>
                                </ul>
                            </span>
                            <span id="users-table-col-{{col.prop}}" appCheckPrivileges
                                [permissions]="[Permissions.ROLE_SYSADMIN]" class="d-block text-center"
                                *ngIf="isColumnSysadmin(col.prop)">
                                <input [checked]="row | isUserSysadminPipe" type="checkbox"
                                    (click)="makeSysadmin($event, row)" class="custom-control-input"
                                    id="customCheck{{rowIndex}}">
                                <label class="custom-control-label text-center justify-content-start mg-l-25"
                                    for="customCheck{{rowIndex}}">&nbsp;</label>
                            </span>
                            <span id="users-table-col-{{col.prop}}"
                                *ngIf="!isColumnCreatedAt(col.prop) && !isColumnOptions(col.prop) && !isColumnId(col.prop) && !isColumnDynamicRoles(col.prop) && !isColumnSysadmin(col.prop)">
                                {{ value }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>
    </div>

    <div *ngIf="loader" class="load-spinner spinner">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        <p class="spinner__info">{{'Please, wait!' | translate}}</p>
    </div>
</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the user' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm('removeUser')">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #makeUserSysadmin>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm to make user with role sysadmin' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm('addSysadminRole')">
            {{'Yes' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #removeRoleSysadminForUser>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove role sysadmin for the user' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm('removeSysadminRole')">
            {{'Yes' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #confirmActionTmpl>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm the action' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>