<ul class="{{ className }}__menu">
    <li class="{{ className }}__item {{ className }}__item--with-sub-menu" routerLinkActive="{{ className }}__item--active" [routerLinkActiveOptions]="{exact: true}" appMenuItemHasChildren>
        
        <a class="{{ className }}__link {{ className }}__link" [routerLink]="['/calendar']"><i-feather name="calendar" class="{{ className }}__icon"></i-feather> {{'Calendar' | translate}}</a>
        
        <ul class="{{ className }}__sub-menu">
            <li appCheckFeaturesConfig [featureName]="[Permissions.Calendar_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/calendar']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="calendar" class="{{ className }}__icon"></i-feather> {{'Calendar' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/visualiser']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="align-left" class="{{ className }}__icon"></i-feather> {{'Visualiser' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Booking_availability]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/visualiser/booking-availability']" routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="layout" class="{{ className }}__icon"></i-feather> {{'Booking Availability' | translate}}</a></li>
        </ul>
    </li>

    <li class="{{ className }}__item {{ className }}__item--with-sub-menu"  routerLinkActive="{{ className }}__item--active" appMenuItemHasChildren>
        <a class="{{ className }}__link {{ className }}__link" [routerLink]="['/routes']"><i-feather name="navigation-2" class="{{ className }}__icon"></i-feather> {{'Routes' | translate}}</a>
        <ul class="{{ className }}__sub-menu">
            <li appCheckFeaturesConfig [featureName]="[Permissions.Route_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/routes']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="navigation-2" class="{{ className }}__icon"></i-feather> {{'Routes' | translate}}</a></li>   
            <li appCheckClient [client]="inpostClient" appCheckFeaturesConfig [featureName]="[Permissions.Route_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/routes/import']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="corner-right-up" class="{{ className }}__icon"></i-feather> {{'Import' | translate}}</a></li>
            <li appCheckClient [client]="inpostClient" appCheckFeaturesConfig [featureName]="[Permissions.Route_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/routes/import-catering']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="file-text" class="{{ className }}__icon"></i-feather> {{'Import catering' | translate}}</a></li>

            <li appCheckClient [client]="friscoClient" appCheckFeaturesConfig [featureName]="[Permissions.Driver_planning_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/drivers/availability']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="users" class="{{ className }}__icon"></i-feather> {{'Drivers and schedule' | translate}}</a></li>
            <li appCheckClient [client]="inpostClient" appCheckFeaturesConfig [featureName]="[Permissions.Driver_planning_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/drivers/availability']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="users" class="{{ className }}__icon"></i-feather> {{'Couriers and schedule' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Payments_view]" appCheckClient [client]="friscoClient" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/payments']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="credit-card" class="{{ className }}__icon"></i-feather> {{'Payments' | translate}}</a></li>
            
            <li appCheckFeaturesConfig [featureName]="[Permissions.Payments_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/payments/report']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="credit-card" class="{{ className }}__icon"></i-feather> {{'Payment report' | translate}}</a></li>

            <li appCheckFeaturesConfig [featureName]="[Permissions.Billing_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/payments/licensing']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="credit-card" class="{{ className }}__icon"></i-feather> {{'Licensing' | translate}}</a></li>
        </ul>
    </li>

    <li appCheckFeaturesConfig [featureName]="[Permissions.Delivery_view]" class="{{ className }}__item {{ className }}__item--with-sub-menu"  routerLinkActive="{{ className }}__item--active" [routerLinkActiveOptions]="{exact: true}">
        <a class="{{ className }}__link" [routerLink]="['/deliveries']"><i-feather name="box" class="{{ className }}__icon"></i-feather>  {{'Deliveries' | translate}}</a>
    
        <ul class="{{ className }}__sub-menu">
            <li appCheckClient [client]="friscoClient" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/deliveries']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="box" class="{{ className }}__icon"></i-feather> {{'Deliveries' | translate}}</a></li>
            <li class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/deliveries/report-by-driver']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="copy" class="{{ className }}__icon"></i-feather> {{'Driver report' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.FilterWordView]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/locations/comments/list']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="message-square" class="{{ className }}__icon"></i-feather> {{'Drivers comments' | translate}}</a></li>
        </ul>
    </li>

    <li class="{{ className }}__item {{ className }}__item--with-sub-menu" routerLinkActive="{{ className }}__item--active" [routerLinkActiveOptions]="{exact: true}" appMenuItemHasChildren>
        <a class="{{ className }}__link {{ className }}__link" [routerLink]="['/analytics/drivers']"><i-feather name="activity" class="{{ className }}__icon"></i-feather> {{'Analytics' | translate}}</a>
        <ul class="{{ className }}__sub-menu">
            <li appCheckFeaturesConfig [featureName]="[Permissions.Dashboard_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/dashboard']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="navigation" class="{{ className }}__icon"></i-feather> {{'Dashboard' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Billing_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/dashboard/delivery-sales']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="columns" class="{{ className }}__icon"></i-feather> {{'Sales dashboard' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Analytics_driverpunctuality_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/analytics/drivers']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="user" class="{{ className }}__icon"></i-feather> {{'Drivers statistic' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Analytics_ordershistory_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/visualiser/history']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="database" class="{{ className }}__icon"></i-feather> {{'Orders history' | translate}}</a></li>
            <!-- <li appCheckFeaturesConfig [featureName]="[Permissions.Analytics_routingefficiency_view]" appCheckClient [client]="friscoClient" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/analytics/routing-efficiency/boxes']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="bar-chart-2" class="{{ className }}__icon"></i-feather> {{'Routing efficiency by order' | translate}}</a></li> -->
            <li appCheckFeaturesConfig [featureName]="[Permissions.Analytics_routingefficiency_view]" appCheckClient [client]="friscoClient" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/analytics/routing-efficiency/distances']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="git-merge" class="{{ className }}__icon"></i-feather> {{'Routing efficiency by distance' | translate}}</a></li>
            <li appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/analytics/orders']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="pie-chart" class="{{ className }}__icon"></i-feather> {{'Orders report' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Dashboard_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/dashboard/vehicle-distance-history']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="map-pin" class="{{ className }}__icon"></i-feather> {{'Vehicle distance history' | translate}}</a></li>
        </ul>
    </li>

    <li class="{{ className }}__item {{ className }}__item--with-sub-menu" routerLinkActive="{{ className }}__item--active" [routerLinkActiveOptions]="{exact: true}" appMenuItemHasChildren>
        
        <a class="{{ className }}__link {{ className }}__link" [routerLink]="['/fleet']"><i-feather name="users" class="{{ className }}__icon"></i-feather> {{'Users & hardware' | translate}}</a>
        
        <ul class="{{ className }}__sub-menu">
            <li appCheckFeaturesConfig [featureName]="[Permissions.Hardware_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link"  [routerLink]="['/fleet']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="truck" class="{{ className }}__icon"></i-feather> {{'Fleet & hardware' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.User_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/users']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="users" class="{{ className }}__icon"></i-feather> {{'Users' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Location_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/locations']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="map" class="{{ className }}__icon"></i-feather> {{'Locations' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Driver_view]" class="{{ className }}__sub-item">
                <a appCheckClient [client]="friscoClient" class="{{ className }}__sub-link" [routerLink]="['/drivers']" routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="user" class="{{ className }}__icon"></i-feather> {{'Drivers' | translate}}</a>
                <a appCheckClient [client]="inpostClient" class="{{ className }}__sub-link" [routerLink]="['/drivers']" routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="user" class="{{ className }}__icon"></i-feather> {{'Couriers' | translate}}</a>
            </li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Roles_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/users/roles']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="award" class="{{ className }}__icon"></i-feather> {{'Roles' | translate}}</a></li>
            <li appCheckClient [client]="friscoClient" appCheckFeaturesConfig [featureName]="[Permissions.Roles_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/salary/agency/list']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="dollar-sign" class="{{ className }}__icon"></i-feather> {{'Salaries' | translate}}</a></li>
            <!-- <li appCheckFeaturesConfig [featureName]="'licensing'" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" appCheckPrivileges [roles]="[sysadminRole]" [routerLink]="['/payments/licensing']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="activity" class="{{ className }}__icon"></i-feather> {{'Licensing' | translate}}</a></li> -->
        </ul>
    </li>

    <li class="{{ className }}__item {{ className }}__item--with-sub-menu" routerLinkActive="{{ className }}__item--active" [routerLinkActiveOptions]="{exact: true}" appMenuItemHasChildren>
        <a class="{{ className }}__link {{ className }}__link"><i-feather name="cast" class="{{ className }}__icon"></i-feather> {{'RLM' | translate}}</a>
        <ul class="{{ className }}__sub-menu">
            <li appCheckClient [client]="inpostClient" appCheckFeaturesConfig [featureName]="[Permissions.Apm_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/apm']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="cast" class="{{ className }}__icon"></i-feather> {{'RLM' | translate}}</a></li>
            <li appCheckClient [client]="inpostClient" appCheckFeaturesConfig [featureName]="[Permissions.Partner_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/partner']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="users" class="{{ className }}__icon"></i-feather> {{'Partners' | translate}}</a></li>
            <li appCheckClient [client]="inpostClient" appCheckFeaturesConfig [featureName]="[Permissions.Partner_view, Permissions.Apm_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/subscription']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="inbox" class="{{ className }}__icon"></i-feather> {{'Subscriptions' | translate}}</a></li>
        </ul>
    </li>

    <li class="{{ className }}__item {{ className }}__item--with-sub-menu" routerLinkActive="{{ className }}__item--active" [routerLinkActiveOptions]="{exact: true}" appMenuItemHasChildren>
        <a class="{{ className }}__link {{ className }}__link"><i-feather name="sliders" class="{{ className }}__icon"></i-feather> {{'Configurator' | translate}}</a>
        <ul class="{{ className }}__sub-menu">
            <li appCheckFeaturesConfig [featureName]="[Permissions.Depot_edit]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/locations/depots']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="package" class="{{ className }}__icon"></i-feather> {{'Depots' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Config_default_view]" appCheckFeaturesConfig [featureName]="[Permissions.Partner_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/config-value']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="settings" class="{{ className }}__icon"></i-feather> {{'Config' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Config_planningsequence_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/visualiser/planning-sequence']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="layers" class="{{ className }}__icon"></i-feather> {{'Planning sequence' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Shift_planning_edit]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/calendar/shift-planning']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="grid" class="{{ className }}__icon"></i-feather> {{'Create shifts' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Message_template_view]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/settings/message-templates']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="mail" class="{{ className }}__icon"></i-feather> {{'Sms templates' | translate}}</a></li>
            <li appCheckFeaturesConfig [featureName]="[Permissions.Schedule_edit]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/system/cron-job-scheduler']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="rotate-cw" class="{{ className }}__icon"></i-feather> {{'Cron job scheduler' | translate}}</a></li>
            <li appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]" class="{{ className }}__sub-item"><a class="{{ className }}__sub-link" [routerLink]="['/simulator']"  routerLinkActive="{{ className }}__sub-link--active" [routerLinkActiveOptions]="{exact: true}"><i-feather name="map" class="{{ className }}__icon"></i-feather> {{'Simulator' | translate}}</a></li>
        </ul>
    </li>
</ul>
