import { Pipe, PipeTransform } from '@angular/core';
import { ShiftStatus } from '@enums/enum';

@Pipe({
    name: 'isShiftOpenPipe',
    pure: true
})
export class IsShiftOpenPipe implements PipeTransform {

    public transform(status: string): boolean {
        return status === ShiftStatus.BOOKING;
    }
}
