
        <form *ngIf="form" [formGroup]="form">
            <div class="row">
                <ng-container formGroupName="simulationShiftConfigDto">
                    <ng-container formGroupName="shiftId">
                        <div class="form-group col-4">
                            <label for="date">{{ 'Date' | translate }}:</label>
                            <ng-datepicker class="datepicker-input" formControlName="date" [options]="options"></ng-datepicker>
                        </div>

                        <div class="form-group col-2">
                            <label for="type">{{ 'Shift type' | translate }}:</label>
                            <select class="form-control" formControlName="type">
                                <option *ngFor="let option of shiftType" [value]="option">{{ option | translate }}</option>
                            </select>
                        </div>

                        <div class="form-group col-2">
                            <label for="warehouse">{{ 'Warehouse' | translate }}:</label>
                            <select class="form-control" formControlName="warehouse">
                                <option value="SIM">{{ 'SIM' | translate }}</option>
                            </select>
                        </div>
                    </ng-container>

                    <div class="form-group col-2">
                        <label for="fleetSize">{{ 'Fleet size' | translate }}:</label>
                        <input type="number" min="1" formControlName="fleetSize" class="form-control" />
                    </div>

                    <div class="form-group col-2">
                        <label for="ordersLimit">{{ 'Orders limit' | translate }}:</label>
                        <input type="number" min="1" formControlName="ordersLimit" class="form-control" />
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <ng-container formGroupName="simulationShiftConfigDto">
                    <div class="form-group col-4">
                        <label for="recalculateFrequency">{{ 'Recalculate Frequency' | translate }}:</label>
                        <input type="number" min="1" formControlName="recalculateFrequency" class="form-control" />
                    </div>

                    <div class="form-group col-4">
                        <label for="shiftStartMarginMinutes">{{ 'Shift start margin minutes' | translate }}:</label>
                        <input type="number" min="1" formControlName="shiftStartMarginMinutes" class="form-control" />
                    </div>

                    <div class="form-group col-2">
                        <label for="slotEndMarginMinutes">{{ 'Slot end margin minutes' | translate }}:</label>
                        <input type="number" min="1" formControlName="slotEndMarginMinutes" class="form-control" />
                    </div>

                    <div class="form-group col-2">
                        <label for="slotStartMarginMinutes">{{ 'Slot start margin minutes' | translate }}:</label>
                        <input type="number" min="1" formControlName="slotStartMarginMinutes" class="form-control" />
                    </div>
                </ng-container>
            </div>

            <div class="row justify-content-end">
                <ng-template [ngIf]="isSetSimulation" [ngIfElse]="create" type="submit">
                    <button (click)="loadMonthConfiguration()" class="col-3 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{ 'Advanced settings' | translate }}</button>

                    <button (click)="update()" [disabled]="!valid" class="col-3 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{ 'Update simulation' | translate }}</button>
                </ng-template>

                <ng-template #create>
                    <button (click)="onSubmit()" [disabled]="!valid" type="submit" class="col-3 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{ 'Create simulation' | translate }}</button>
                </ng-template>
            </div>
        </form>

       <!-- <ng-sidebar-container [style.z-index]="_sidebarZIndex" [style.display]="_opened ? 'block' : 'none'">
            <ng-sidebar
                ng-sidebar--right
                [(opened)]="_opened"
                [position]="'right'"
                [autoCollapseHeight]="true"
                [autoCollapseWidth]="true"
                [closeOnClickOutside]="false"
                [closeOnClickBackdrop]="true"
                [showBackdrop]="false"
            >
                <div class="sidebar-header">
                    <h6 class="tx-uppercase">{{ 'Shift configuration' | translate }}</h6>
                    <button class="btn btn-xs btn-uppercase btn-white" (click)="_toggleSidebar()">Close</button>
                </div>

                <day-details *ngIf="_opened" [dayComposite]="selectedDay" [selectedShift]="selectedShift" [calculateTempResult]="false" (dayCompositeChanged)="onDayCompositeChanged($event)">
                </day-details>
            </ng-sidebar>

            <div ng-sidebar-content></div>
        </ng-sidebar-container>-->
    