
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-5">
                    <label for="deliveryPoolSize">{{ 'Delivery pool size' | translate }}:</label>
                    <input type="number" id="deliveryPoolSize" formControlName="deliveryPoolSize" class="form-control" />
                </div>

                <div class="form-group col-3">
                    <ng-container formGroupName="slotChoiceStrategy">
                        <label for="choiceType">{{ 'Slot choice strategy' | translate }}:</label>
                        <select class="form-control" formControlName="@type">
                            <option *ngFor="let option of slotChoiceStrategyOptions" [value]="option">{{ option | translate }}</option>
                        </select>
                    </ng-container>
                </div>

                <div class="form-group col-2">
                    <ng-container formGroupName="slotChoiceStrategy">
                        <label for="slot">{{ 'Slot' | translate }}:</label>
                        <select class="form-control" [attr.disabled]="true" formControlName="slot">
                            <option *ngFor="let option of slots" [value]="option">{{ option }}</option>
                        </select>
                    </ng-container>
                </div>

                <div class="form-group col-2">
                    <label>&nbsp;</label>

                    <button [disabled]="submitted" type="submit" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-white submit">
                        <span *ngIf="!submitted">{{ 'Inject deliveries' | translate }} </span>
                        <div class="load-spinner">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true" *ngIf="submitted"></i>
                        </div>
                    </button>
                </div>
            </div>
        </form>

        <!--{{'FIRST_AVAILABLE_STRATEGY' | translate }} -->
        <!--{{'LAST_AVAILABLE_STRATEGY' | translate }} -->
        <!--{{'RANDOM_AVAILABLE_STRATEGY' | translate }} -->
        <!--{{'FIXED_SLOT_STRATEGY' | translate }} -->
        <!--{{'MANUAL_SLOT_STRATEGY' | translate }} -->
        <!--{{'SMALL_BIAS_STRATEGY' | translate}} -->
        <!--{{'LARGE_BIAS_STRATEGY' | translate}} -->
    