import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'utcToLocalTimePipe',
    pure: true
})
export class UtcToLocalTimePipe implements PipeTransform {

    public transform(time: string): string {
        const stillUtc = moment.utc(time).toDate();
        return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    }
}
