
        <layout-default>
            <div header>
                <div toolbar>
                    <h1 class="title">{{ 'Drivers' | translate }}</h1>
                    <button (click)="showDriverBarcode()" class="btn btn-default btn-white"><i class="fas fa-print" style="font-size:1rem"></i> {{ 'Print' | translate }}</button>
                </div>
            </div>
            
            <div content>
                <div class="card-body">
                    <div *ngFor="let driver of drivers; let index = index">
                        <div *ngIf="index % 6 == 5" style="display:block;page-break-before:always;"></div>
                        <div style="padding: 20px; display:flex;">
                            <div style="padding: 5px 10px;margin-top:10px;">
                                <ng-container>
                                    <ngx-barcode [bc-value]="driver.employeeId" [bc-display-value]="true"></ngx-barcode>
                                </ng-container>
                            </div>
                            <div style="margin: 10px;">
                                <p>
                                    <strong>{{ driver.lastname.toUpperCase() }} {{ driver.firstName }}</strong>
                                </p>
                                <p>{{ driver.status }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </layout-default>
    