<layout-default>
  <div header>
      <div toolbar>
          <h4>
              <i-feather name="package"></i-feather> {{'Assign vehicle to depot' | translate}}
          </h4>
      </div>

      <hr />
  </div>
  
  <div content>

      <div *ngIf="!loader" class="content bg-white pd-40">

          <div class="row">
              <div class="col">
                  <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1">{{'Depot' | translate}}</label>
                  <p>{{'Name' | translate}}: <strong>{{depot?.name}}</strong>, {{'Code' | translate}}: <strong>{{depot?.code}}</strong></p>
              </div>
              <div class="d-flex justify-content-end mg-t-20">
                  <div class="col">
                      <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                          class="btn btn-sm btn-uppercase mg-l-5 btn-brand-01 w-100">

                          <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                          <ng-container *ngIf="submitted">
                              <div class="load-spinner text-center">
                                  <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                              </div>
                          </ng-container>

                      </button>
                  </div>
              </div>
          </div>
          
          <form [formGroup]="form">

              <ng-container formArrayName="ids">
                  <div *ngFor="let apm of idsControl.controls; let index=index" class="form-horizontal">
                      <ng-container formGroupName="{{index}}">
                          
                          <div [style.flex-grow]="3" class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                              <label for="identifier" class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Name' | translate}}:</label>
                              

                              <ng-template [ngIf]="isSetValue(index)" [ngIfElse]="autocomplete" type="submit">
                                  <input formControlName="identifier" class="form-control" />
                              </ng-template>
              
                              <ng-template #autocomplete>
                                  <div class="ng-autocomplete">
                                      <ng-autocomplete 
                                          [data]="rawEntities"
                                          [searchKeyword]="'identifier'"
                                          (selected)='selectEvent($event)'
                                          (inputChanged)='onChangeSearch($event)'
                                          [itemTemplate]="itemTemplate"
                                          [minQueryLength]="3"
                                          [debounceTime]="600"
                                          [placeHolder]="'Registration' | translate"
                                          [notFoundTemplate]="notFoundTemplate">                                 
                                      </ng-autocomplete>
                                      
                                      <ng-template #itemTemplate let-item>
                                          <a [innerHTML]="item.identifier + ' ' + item.additionalInfo"></a>
                                      </ng-template>
                                      
                                      <ng-template #notFoundTemplate let-notFound>
                                          <div [innerHTML]="'not found!' | translate"></div>
                                      </ng-template>
                                  </div>
                              </ng-template>
                          </div>

                          <div [style.flex-grow]="1" class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                              <label for="additionalInfo" class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{ 'Additional information' | translate }}:</label>
                              <input formControlName="additionalInfo" class="form-control"  />
                          </div>

                          <div  class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                              <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-6 d-block">&nbsp;</label>
                              <button [ngClass]="{'invisible': !isSetValue(index)}" (click)="remove(index)" class="btn btn-xs d-inline btn-white remove-driver pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                          </div>


                      </ng-container>
                  </div>
              </ng-container>
              


              <div class="d-flex justify-content-end mg-t-20">
                  <div class="col-2">
                      <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                          class="btn btn-sm btn-uppercase mg-l-5 btn-brand-01 w-100">

                          <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                          <ng-container *ngIf="submitted">
                              <div class="load-spinner text-center">
                                  <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                              </div>
                          </ng-container>

                      </button>
                  </div>
              </div>

          </form>
      </div>
      
  </div>
</layout-default>


<ng-template #confirmation>
  <div class="modal-header">
      <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body text-center">
      <p>{{'Do you confirm removal of the vehicle assignment' | translate }}?</p>
      <button type="button" class="btn btn-brand-01" (click)="confirm()">
          {{'Yes, remove' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
  </div>
</ng-template>