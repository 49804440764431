<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="align-left"></i-feather> {{'Visualiser' | translate}}</h4>
        </div>
        <div toolbar>
            <aurels-shift-picker ></aurels-shift-picker>
            <!-- (changedDeliveryManagementSettings)="changedDeliveryManagementSettingsFun($event)" (changedDataset)="changedDataset($event)" -->
        </div>
        <hr />
    </div>
    <div content>
        <!-- <div class="card-body"> -->
            <router-outlet></router-outlet>
            <!-- <grid [deliveryManagementSettings]="deliveryManagementSettings" [dataset]="dataset"></grid> -->
        <!-- </div> -->
    </div>
</layout-default>
