import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Agency } from '../../interfaces/agency';
import { Driver } from '@drivers/entities/driver';
import { FormArray, FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AgencyService } from '../../services/agency.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { DriverStatus } from '@enums/enum';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SalaryReport } from '../../interfaces/salary-report';
import { SalaryReportService } from '../../services/salary-report.service';

@Component({
  selector: 'app-report-assign-driver',
  templateUrl: './report-assign-driver.component.html',
  styleUrls: ['./report-assign-driver.component.scss']
})
export class ReportAssignDriverComponent implements OnInit {

  public report: SalaryReport;
  public drivers: Driver[];
  public assignDrivers: string[] = [];

  public driversForm: FormArray;

  public rawDrivers: any[] = [];

  public loader: boolean = true;
  public submitted: boolean = false;

  public form: FormGroup;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  private removedDriverId: number;

  constructor(
    private readonly navigationRoute: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly salaryReportService: SalaryReportService,
    private readonly modalService: BsModalService,
    private readonly notifierService: NotifierService,
    private readonly translateService: TranslateService,
    private readonly navService: NavService
  ) { }

  public ngOnInit() {

    this.drivers = (this.navigationRoute.snapshot.data.drivers).filter((d: Driver) => d.status === DriverStatus.ACTIVE);
    this.report = this.navigationRoute.snapshot.data.report as SalaryReport;
    this.assignDrivers = this.report.driverIdSet;

    this.initForm();
    this.prepareDrivers();
  }

  public prepareDrivers() {
    this.drivers.map((d: Driver) => {
      const isExist = this.assignDrivers.find((driverId: string) => driverId === d.id);
      if (d.status === DriverStatus.ACTIVE && isExist === undefined){
        this.rawDrivers.push({
          id: d.id, 
          username: d.fullName
        });
      }
    });

    this.loader = false;
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      drivers: this.formBuilder.array([])
    })

    this.driversForm = <FormArray>this.form.controls['drivers'];
    if (this.assignDrivers.length) {
      this.assignDrivers.forEach((id) => this.driversForm.push(this.patchValues(id)));
    }

    this.driversForm.push(this.patchValues());
  }

  private patchValues(id?: string): AbstractControl {
    const driver = (id !== undefined) ? this.drivers.find((d) => d.id === id) : null;

    return this.formBuilder.group({
      id: [{value: driver ? driver.id : null, disabled: driver ? true : false}],
      fullName: [{value: driver ? driver.fullName : null, disabled: driver ? true : false}],
      nationality: [{value: driver ? this.translateService.instant(driver.nationality) : null, disabled: driver ? true : false}],
      experience: [{value: driver ? this.translateService.instant(driver.experience) : null, disabled: driver ? true : false}],
    })
  }

  public selectEvent(item) {
    const driver: Driver = this.drivers.find((d: Driver) => d.id === item['id']);
    const num: number = this.driversForm.controls.length;

    this.driversForm.controls[num - 1].setValue({
      id: driver.id,
      fullName: driver.fullName,
      nationality: this.translateService.instant(driver.nationality),
      experience: this.translateService.instant(driver.experience)
    }, {onlySelf: true});

    _.remove(this.rawDrivers, (d: Driver) => d.id === driver.id);

    this.addStep();
  }

  public removeStep(i): void {
    console.log((<FormArray>this.form.controls['drivers']).controls[i].value);
    this.removedDriverId = i;
    const assigningId: string = (<FormArray>this.form.controls['drivers']).controls[i].value['id'];
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.salaryReportService.unassignDriversToSalaryReport(this.report.id, [assigningId]);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      (<FormArray>this.form.controls['drivers']).removeAt(this.removedDriverId);
      this.notifierService.notify('success', this.translateService.instant('The drivers has been removed!'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public addStep(): void {
    this.driversForm.push(this.patchValues());
  }

  public isSetValue(index: number): boolean {
    return ((this.driversForm.controls[index] as FormControl).value['fullName']);
  }
 
  public onSubmit() {

    const form = this.form.getRawValue();
    const body: string[] = [];

    _.forEach(_.values(form['drivers']), (d: Driver) => d.id ? body.push(d.id) : null)

    this.salaryReportService.assignDriversToSalaryReport(this.report.id, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: any) => {
          console.log(region);
          this.notifierService.notify('success', this.translateService.instant('Drivers has been assign to salary report!'));
          this.navService.goToPage(`/salary/report/list`, {modifiedRow: this.report.id});
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );

    
  }

}
