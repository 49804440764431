
    <ng-container *ngIf="hasDeliveries()">

        <div class="payments-summary">
            <h6>{{'Payments summary' | translate}}:</h6>
            <div class="payments-list">
                <div *ngFor="let type of paymentTypesOptions">{{type | translate}} - <strong>{{summaryPaymentDetailsByType(type) | number:'1.2-2'}} {{'PLN' | translate}}</strong></div>
            </div>
        </div>
        <hr />
        <div class="row thead">
            <div class="col-2">{{'Customer' | translate}}</div>
            <div class="col-2">{{'Invoice' | translate}}</div>
            <div class="col-1">{{'Method' | translate}}</div>
            <div class="col-1">{{'Amount due' | translate}}</div>
            <div class="col-1">{{'Collected' | translate}}</div>
            <div class="col-1">{{'Complaints' | translate}}</div>
            <div class="col-2">{{'Discrepancy' | translate}}</div>
            <div class="col-2">{{'Payment details' | translate}}</div>
        </div>
        <ng-container *ngFor="let delivery of deliveries">
            <div class="row tbody" *ngFor="let payment of delivery.paymentInfos; let index = index">
                <div class="col-2">
                    <button (click)="goToDeliveryPage(delivery)" class="btn btn-xs d-inline">
                        <i-feather name="external-link" [style.width.px]="12" [style.height.px]="12"></i-feather>
                    </button>
                    {{delivery.customer.labelLong}}
                </div>
                <div class="col-2">
                    <strong>{{payment.merchant}}</strong>
                    <p class="pd-0 mg-0">{{payment.id}}</p>
                </div>
                <div class="col-1">{{payment.requestedType | translate}}</div>
                <div class="col-1">{{payment.amountDue | number:'1.2-2'}} {{payment.currency | translate}}</div>
                <div class="col-1">{{payment.amountPaid | number:'1.2-2'}} {{payment.currency | translate}}</div>
                <div class="col-1">{{delivery.complaintsAmount[payment.merchant] | number:'1.2-2'}} {{payment.currency | translate}}
                
                </div>
                <div class="col-2 discrepancy">
                    <ng-container *ngIf="index === 0">
                        <span class="green" *ngIf="hasDiscrepancy(delivery, payment) == 0">
                            <i *ngIf="!delivery.isStatusAssigned && !delivery.isStatusRetry && !delivery.isStatusFailure" class="fa fa-check-circle"></i>
                            <span *ngIf="delivery.isStatusAssigned || delivery.isStatusRetry" class="badge badge-warning text-center">{{'Not delivered' | translate}}</span>
                            <span *ngIf="delivery.isStatusFailure" class="badge badge-info text-center">{{'FAILURE' | translate}}</span>
                        </span>
                        <span class="red" *ngIf="hasDiscrepancy(delivery, payment) < 0">
                            <i class="fa fa-times-circle"></i> {{ hasDiscrepancy(delivery, payment) | number:'1.2-2' }}{{payment.currency | translate}}
                        </span>
                        <span class="orange" *ngIf="hasDiscrepancy(delivery, payment) > 0">
                            <i class="fa fa-times-circle"></i> +{{ hasDiscrepancy(delivery, payment) | number:'1.2-2' }}{{payment.currency | translate}}
                        </span>
                    </ng-container>
                </div>
                <div class="col-2">
                    <ul>
                        <li *ngFor="let method of payment.methods">{{method.type | translate}} - {{method.amount | number:'1.2-2' }} {{method.currency}}</li>
                    </ul>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!hasDeliveries()">
        <div class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        </div>
    </ng-container>
    