<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="navigation"></i-feather>
                {{'Dashboard' | translate}} <span
                    *ngIf="settings?.mode === 'presentation' && displayedDepotId">({{'Dashboard_page.Presentation Mode'
                    | translate}} - {{displayDepotName(displayedDepotId)}}
                    )</span>
            </h4>
        </div>
    </div>

    <div content>
        <div class="row">

            <div class="col-sm-12 col-lg-12 box">
                <div *ngIf="(userAppSettings$ | async)" class="">
                    <ng-container *ngIf="depot$ | async as depot">
                        <div class="resizableMapContainer">

                            <agm-map #map [latitude]="depot.locationCoordinates.lat"
                                [longitude]="depot.locationCoordinates.lng" [zoomControl]="true"
                                [zoom]="mapProperties.zoom" [mapTypeControl]="true" [streetViewControl]="false"
                                [styles]="mapStyles" [usePanning]="true" [scrollwheel]="null"
                                (mapClick)="unselectVehicles()" [gestureHandling]="'cooperative'"
                                [fullscreenControl]="true">

                                <ng-container *ngFor="let v of vehicles">
                                    <agm-marker *ngIf="v.position" [latitude]="v.position.lat"
                                        [longitude]="v.position.lng" (markerClick)="selectVehicle(v)"
                                        [zIndex]="selectedVehicle === v ? 100 : v.nextStop ? 10 : 1"
                                        [iconUrl]="v | dashboardMapVehicleMarker : v.position : selectedVehicle === v : v.nextStop : settings">
                                        <agm-info-window [isOpen]="selectedVehicle === v">
                                            <div *ngIf="selectedVehicle === v">

                                                <div>
                                                    <strong>{{selectedVehicle.driverName}}</strong><br>

                                                    <app-dashboard-chart [quantityName]="'deliveries'"
                                                        [chart]="selectedVehicle.deliveryPunctuality"
                                                        [showTotals]="false"
                                                        [showPartials]="settings.showPartials"></app-dashboard-chart>

                                                    <p style="margin: 0.5rem 0">
                                                        <a
                                                            [href]="'tel:' + selectedVehicle.driverPhone">{{selectedVehicle.driverPhone}}</a>
                                                    </p>
                                                    <div>
                                                        <strong><a
                                                                [routerLink]="driverUrlFromDriver(selectedVehicle, getCurrentlyDisplayedDepot().id + '')">{{'Dashboard_page.Driverpage'
                                                                | translate}}</a></strong>
                                                    </div>


                                                </div>

                                                <ng-template #loading>
                                                    <p>. . .</p>
                                                </ng-template>

                                            </div>

                                        </agm-info-window>
                                    </agm-marker>


                                </ng-container>

                                <agm-marker [latitude]="getCurrentlyDisplayedDepot().locationCoordinates.lat"
                                    [longitude]="getCurrentlyDisplayedDepot().locationCoordinates.lng" [zIndex]="200"
                                    [iconUrl]="client === friscoClient ? markerPath + '/friscoMarker.png' : markerPath + '/inpostMarker.png'">
                                </agm-marker>

                                <agm-marker *ngIf="selectedVehicle && selectedVehicle.nextStop"
                                    [latitude]="selectedVehicle.nextStop.lat" [longitude]="selectedVehicle.nextStop.lng"
                                    [zIndex]="100">
                                </agm-marker>

                                <agm-polyline
                                    *ngIf="selectedVehicle && selectedVehicle.nextStop && selectedVehicle.position"
                                    [zIndex]="0" [strokeColor]="'#ca95ff'" [strokeOpacity]="0.5">
                                    <agm-polyline-point [latitude]="selectedVehicle.position.lat"
                                        [longitude]="selectedVehicle.position.lng"></agm-polyline-point>
                                    <agm-polyline-point [latitude]="selectedVehicle.nextStop.lat"
                                        [longitude]="selectedVehicle.nextStop.lng"></agm-polyline-point>
                                </agm-polyline>
                            </agm-map>
                        </div>


                    </ng-container>
                </div>
            </div>

            <app-dashboard-charts *ngIf="(punctuality$ | async) as punctuality" [punctuality]="punctuality"
                [settings]="settings"></app-dashboard-charts>

        </div>

        <div class="card settings pd-10">
            <a [routerLink]="['../history']" class="settingsOpener">
                <span class="material-icons">dvr</span>
                {{'Dashboard_page.History' | translate}}
            </a>
        </div>

        <div class="card settings pd-10">
            <a href="javascript:void(0)" (click)="settingsOpen = !settingsOpen" class="settingsOpener">
                <span class="material-icons">settings</span>
                {{'Dashboard_page.Settings' | translate}}
            </a>
            <div class="card-body settingsDetails" *ngIf="settingsOpen">
                <form [formGroup]="settingsForm">
                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Presentation (multiple depots)' | translate}}
                            <input type="radio" value="presentation" formControlName="mode">
                        </label>
                        <label class="pd-10">
                            {{'Dashboard_page.Interactive (1 depot)' | translate}}
                            <input type="radio" value="interactive" formControlName="mode">
                        </label>
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Depot cycling every X seconds (only for presentation mode)' | translate}}
                        </label>
                        <input type="number" formControlName="depotCyclingTimeSeconds" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Data update every X seconds' | translate}}
                        </label>
                        <input type="number" min="240" formControlName="pollingTimeSeconds" />
                    </div>

                    <hr />

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Vehicle animation seconds' | translate}}
                        </label>
                        <input type="number" formControlName="animationTimeSeconds" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Animation frames per second' | translate}}
                        </label>
                        <input type="number" formControlName="animationFps" />
                    </div>

                    <hr />

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Hide vehicles with unknown punctuality' | translate}}
                        </label>
                        <input type="checkbox" formControlName="hideVehiclesWithoutLastStop" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Hide vehicles without a next target' | translate}}
                        </label>
                        <input type="checkbox" formControlName="hideVehiclesWithoutNextStop" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Vehicle scale %' | translate}}
                        </label>
                        <input type="number" formControlName="vehicleScale" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Early vehicle color' | translate}}
                        </label>
                        <input type="color" formControlName="earlyVehicleColor" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.On-time vehicle color' | translate}}
                        </label>
                        <input type="color" formControlName="okVehicleColor" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Late vehicle color' | translate}}
                        </label>
                        <input type="color" formControlName="lateVehicleColor" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Unknown punctuality vehicle color' | translate}}
                        </label>
                        <input type="color" formControlName="unknownVehicleColor" />
                    </div>

                    <hr />

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Show partial chart numbers' | translate}}
                        </label>
                        <input type="checkbox" formControlName="showPartials" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Show delivery totals' | translate}}
                        </label>
                        <input type="checkbox" formControlName="showTotalsDelivery" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Show fulfillment totals' | translate}}
                        </label>
                        <input type="checkbox" formControlName="showTotalsFulfillment" />
                    </div>

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Show loading totals' | translate}}
                        </label>
                        <input type="checkbox" formControlName="showTotalsLoading" />
                    </div>

                    <hr />

                    <div>
                        <label class="pd-10">
                            {{'Dashboard_page.Chart data bounds [seconds]' | translate}}
                        </label>
                        <input type="number" class="mg-r-10" formControlName="startsWithin"
                            [placeholder]="'Dashboard_page.startsWithin' | translate" />
                        <input type="number" formControlName="endsWithin"
                            [placeholder]="'Dashboard_page.endsWithin' | translate" />
                    </div>

                    <hr />

                    <div class="btn-toolbar">
                        <button class="btn btn-primary" (click)="submitSettingsForm()">{{'Dashboard_page.Save & reload'
                            | translate}}</button>
                        <button class="btn btn-link" (click)="resetSettings()">{{'Dashboard_page.Reset settings' |
                            translate}}</button>
                    </div>


                </form>
            </div>
        </div>
    </div>
</layout-default>