import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsService } from 'src/app/payments/services/payments.service';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { CurrencyType, Client } from '@enums/enum';
import { User } from '@entities/user';
import { environment } from '@environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-licensing-users',
  templateUrl: './licensing-users.component.html',
  styleUrls: ['./licensing-users.component.scss']
})
export class LicensingUsersComponent implements OnInit {

  public readonly usersFee: number = 199;
  private to: string;
  public licensing$!: Observable<any>;
  public usersCount: number = 0;
  public CurrencyType = CurrencyType;
  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public excludes: string;
  public includes: string;

  public columns = [
    { prop: 'username', name: this.translateService.instant('Username'), flexGrow: 4 },
    { prop: 'firstName', name: this.translateService.instant('Firstname'), flexGrow: 2 },
    { prop: 'lastName', name: this.translateService.instant('Lastname'), flexGrow: 2 },
    { prop: 'createdAt', name: this.translateService.instant('Created at'), flexGrow: 2 },
  ];

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private paymentService: PaymentsService
  ) { }

  public ngOnInit() {

    if (environment.client === Client.FRISCO) {
      this.includes = '%frisco.pl';
    }

    this.route.queryParams
      .subscribe(params => {
        if (params['from'] && params['to']) {
          this.to = moment(params['to']).format('YYYY-MM-DD');
          this.fetechLicensingUsers();
        }
      });
      this.fetechLicensingUsers();
  }

  private fetechLicensingUsers() {    
    this.licensing$ = this.paymentService.licensingUsers(this.to, this.excludes, this.includes).pipe(
      tap((u: User[]) => this.usersCount = u.length)
    );
  }

  public changeFilters(): void {
    this.fetechLicensingUsers();  
  }

  public exportToXls(licensing: User[]) {
    const data = this.getDataToExport(licensing);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    const workbook: XLSX.WorkBook = { Sheets: { 'Users': worksheet }, SheetNames: ['Users']};
    const date = moment().format('YYYY-MM-DD');
    XLSX.writeFile(workbook, `Users-${this.to}.xls`, { bookType: 'xls', type: 'buffer' });
  }

  private getDataToExport(users: User[]) {
    const routeData = [];

    for (const item of users) {
      routeData.push({
        'Imię': item.firstName,
        'Nazwisko': item.lastName,
        'Adres e-mail': item.username,
        'Data utworzenia': item['createdAt']
      })
    }

    return routeData;
  }
}
