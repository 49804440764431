import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Warehouse } from '@enums/enum';

import { Simulation } from '../entities/simulation';
import { SimulatorService } from '../services/simulator.service';

@Component({
    selector: 'app-solution',
    templateUrl: './solution.component.html',
    styleUrls: ['./solution.component.scss']
})
export class SolutionComponent implements OnInit, OnDestroy {
    public simulation: Simulation;
    public dataset;
    public visualiser: boolean = true;
    public results: boolean = true;
    public bookingAvailability: boolean = true;
    public loading: boolean = true;

    constructor(private simulationService: SimulatorService, private route: ActivatedRoute) {}

    public ngOnInit(): void {
        localStorage.setItem('depot', Warehouse.SIM);
        this.simulation = this.route.snapshot.data['simulation'];
        this.simulationService.getResults(this.simulation.simulationId).subscribe();

        this.dataset = {
            cacheOsrm: false,
            cacheResults: false,
            dataset: 'ORIGINAL',
            date: this.simulation.simulationShiftConfigDto.date,
            shiftDeliveries: false,
            shiftId: this.simulation.simulationShiftConfigDto.shift,
            warehouse: Warehouse.SIM,
            forceFit: false
        };

        this.simulationService.simulationResults
            .subscribe(
                () => {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                    }, 100);
                });
    }

    public ngOnDestroy() {
      localStorage.setItem('depot', Warehouse.WAW);
    }

    public addedSimulation(event) {}
}
