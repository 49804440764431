import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { CrudService } from '@services/crud.service';
import { CronJobScheduler } from '../interfaces/cron-job-scheduler.interface';
import { Observable } from 'rxjs';
import { CronJobAvailableParams } from '../interfaces/cron-job-available-params.interface';

@Injectable({
  providedIn: 'root'
})
export class CronJobSchedulerService extends CrudService<CronJobScheduler, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/scheduler/v1`;
  private static RUN_JOB: string = `${environment.api.url}${environment.api.prefix}` + '/scheduler/v1/${jobId}/runJob';
  private static RESUME_JOB: string = `${environment.api.url}${environment.api.prefix}` + '/scheduler/v1/${jobId}/resumeJob';
  private static PAUSE_JOB: string = `${environment.api.url}${environment.api.prefix}` + '/scheduler/v1/${jobId}/pauseJob';
  private static DELETE_JOB: string = `${environment.api.url}${environment.api.prefix}` + '/scheduler/v1/${jobId}/deleteJob';
  private static UPDATE_JOB: string = `${environment.api.url}${environment.api.prefix}` + '/scheduler/v1';
  private static AVAILABLE: string = `${environment.api.url}${environment.api.prefix}` + '/scheduler/v1/available';


  
  constructor(
    protected _http: HttpClient,
    protected utilsService: UtilsService) {
    super(_http, CronJobSchedulerService.BASE_API, utilsService);
  }

  public runCronJob(jobId: string): Observable<CronJobScheduler> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.RUN_JOB}`, {jobId});
    return this._http.post<any>(endpoint, null);
  }

  public resumeJob(jobId: string): Observable<CronJobScheduler> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.RESUME_JOB}`, {jobId});
    return this._http.post<any>(endpoint, null);
  }

  public pauseJob(jobId: string): Observable<CronJobScheduler> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.PAUSE_JOB}`, {jobId});
    return this._http.post<any>(endpoint, null);
  }

  public updateCronJob(cronJob: any): Observable<CronJobScheduler> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.UPDATE_JOB}`, {});
    return this._http.put<any>(endpoint, cronJob);
  }

  public deleteCronJob(jobId: string): Observable<CronJobScheduler> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.DELETE_JOB}`, {jobId});
    return this._http.delete<any>(endpoint);
  }

  public createCronJob(cronJob: any): Observable<CronJobScheduler> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.UPDATE_JOB}`, {});
    return this._http.post<any>(endpoint, cronJob);
  }

  public available(): Observable<CronJobAvailableParams[]> {
    const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.AVAILABLE}`, {});
    return this._http.get<CronJobAvailableParams[]>(endpoint);
  }

  // public runCronJob(jobId: string): Observable<CronJobScheduler> {
  //   const endpoint = this.utilsService.interpolate(`${CronJobSchedulerService.RUN_JOB}`, {jobId});
  //   return this._http.post<any>(endpoint, null);
  // }
}
