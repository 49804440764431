import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutePath, RoutePoint } from '@entities/route-path';
import { RouteId } from '@entities/routeId';
import { mapOptions, mapTypes, Roles, ActivityType } from '@enums/enum';
import { MapPropertiesInterface } from '@interfaces/map-properties.interface';
import { NavService } from '@services/nav.sevice';
import { ShiftConstants } from '@shared/constants/shift.constants';
import { Dataset } from "../../../simulator/entities/dataset";
import { OptimizeResult } from '../../entities/optimize-result';
import { VisualiserService } from '../../services/visualiser.service';
import { FinderEvent } from '@analytics/entities/finder-event';
import { DeliveryClean } from '@entities/delivery-clean';
import { OptimizeRoutes } from '../../entities/optimize-routes';
import { RouteExt } from '@entities/route-ext';
import { AppService } from '@services/app.service';
import { Activity } from '@entities/activity';
import { DeliveriesService } from '@deliveries/services/deliveries.services';
import { Delivery } from '@entities/delivery';
import { RoutesService } from '@routes/services/routes.service';
import { DeliveryExtInterface } from '../../interfaces/delivery-ext.interface';
import { DManagementSettingInterface } from '../../interfaces/d-management-settings.interface';
import { environment } from '@environment';
import { Permissions } from '@enums/permissions';

@Component({
    selector: 'grid',
    templateUrl: 'grid.component.html',
    styleUrls: ['grid.component.scss'],
    //encapsulation: ViewEncapsulation.Emulated
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('confirmation', { static: true }) public confirmation: TemplateRef<any>;
    @ViewChild('crazyDeliveryWindow', { static: true }) public crazyDeliveryWindow: TemplateRef<any>;

    @Input() public dataset: Dataset;
    @Input() public deliveryManagementSettings: Object;

    private readonly tag: string = '[GridComponent]';
    private readonly maxNoBoxes: number = 12;
    private assetsPath: string = '/assets/img';
    private confirmObservable: Observable<any>;
    private distances = undefined;
    private distancesSubscription: Subscription;
    private warehouseCoords;
    private getRoutePaths2Subscription: Subscription;
    private loadingActionButton: boolean = false;
    private mapOption: mapOptions;
    private mapProperties: MapPropertiesInterface;
    private mapTemplateRef: BsModalRef;
    private markerPath: string = `${this.assetsPath}/markers`;
    private modalArgs: object;
    private modalRef: BsModalRef;
    private path: RoutePath;
    private paths: Array<any> = [];
    private route: any;
    private selectedSlot: string;
    private slotCols = ShiftConstants.slotCols;
    private zoom: number = 11;
    public shiftStatusChanged: FinderEvent[] = [];

    private getDeliverySub: Subscription;
    private findClosestDeliveriesSub: Subscription;

    public loader: boolean = false;
    public optimizeResult: OptimizeResult = undefined;
    public locale = '';
    public mode: "REPLAN" | "DELIVERY_MANAGEMENT" | "REGULAR" = 'REGULAR';

    public Permissions = Permissions;

    private closestDeliveries: DeliveryExtInterface[] = [];
    public selectedCrazyDelivery: DeliveryExtInterface = {activity: null, delivery: null, route: null, path: null, routePath: null};
    public closeDeliveryIndex: number = 0;
    public closeDeliveryIndexLoader: boolean = false;
    private numberOfCrazyDeliveries: number = 10;
    private deliveryManagmentSettings: DManagementSettingInterface;
    public client = environment.client.toLowerCase();

    private get roles() {
        return Roles;
    }

    public get isDeliveryManagementMode(): boolean {
        return this.mode === 'DELIVERY_MANAGEMENT';
    }

    constructor(
        private visualiserService: VisualiserService,
        private modalService: BsModalService,
        private translate: TranslateService,
        private notifierService: NotifierService,
        private navService: NavService,
        private deliveriesService: DeliveriesService,
        private changeDetector: ChangeDetectorRef,
        private routeService: RoutesService,
        private appService: AppService
    ) {}

    public ngOnInit() {
        this.visualiserService.calculateShiftResult.subscribe(results => {
            this.loader = true;
            this.optimizeResult = results;
            setTimeout(() => {
                this.loader = false;
            }, 2000);
        });

        this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('dataset')) {
            this.changedDataset(changes.dataset.currentValue);
        } else if (changes.hasOwnProperty('deliveryManagementSettings')) {
            this.changedDeliveryManagementSettings(changes.deliveryManagementSettings.currentValue);
        }
        
    }

    private changedDataset(dataset) {
        console.log(this.tag, 'dataset', dataset);

        if (!dataset) {
            return;
        }

        this.loader = true;
        this.mode = 'REPLAN';

        const warehouse = localStorage.getItem('depot');
        this.warehouseCoords = this.appService.findWarehouseCoordinates(parseInt(warehouse, 0));

        if (this.getRoutePaths2Subscription) {
            this.getRoutePaths2Subscription.unsubscribe();
        }

        if (this.distancesSubscription) {
            this.distancesSubscription.unsubscribe();
        }

        const [y, m, d] = moment(dataset.date)
            .format('YYYY-MM-DD')
            .split('-');

        this.getRoutePaths2Subscription = this.visualiserService
            .getRoutePaths2(y, m, d, dataset.shiftId)
            .pipe(
                map((result: OptimizeResult) => {
                    this.optimizeResult = result;
                    this.loader = false;
                })
            )
            .subscribe(
                () => {},
                () => {
                    this.optimizeResult = null;
                    this.loader = false;
                }
            );

        this.distancesSubscription = this.visualiserService.distances.subscribe(distances => {
            this.distances = distances;
            this.changeDetector.markForCheck();
        });

        const shiftId = `${dataset.date}:${localStorage.getItem('depot')}:${dataset.shiftId}`;
        this.visualiserService.shiftStatusChange(shiftId).subscribe(data => {
            this.shiftStatusChanged = data;
        })
    }

    private changedDeliveryManagementSettings(settings) {
        if (settings === null) {
            this.mode = 'REPLAN';
        } else {
            this.deliveryManagmentSettings = settings;
            this.mode = 'DELIVERY_MANAGEMENT';
        }
    }

    private confirm(): void {
        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.confirmObservable = null;
            this.loadingActionButton = false;
            this.notifierService.notify('success', this.translate.instant('The action has executed successfully!'));
        });
    }

    private decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();

        if (this.getDeliverySub) {
            this.getDeliverySub.unsubscribe();
        }
        if (this.findClosestDeliveriesSub) {
            this.findClosestDeliveriesSub.unsubscribe();
        }
    }

    private goToGMaps(path: RoutePath): string {
        let url = `${this.warehouseCoords.lat}+${this.warehouseCoords.lng}/`;

        _.forEach(path.points, point => {
            url += `${point.lat}+${point.lng}/`;
        });

        url += `${this.warehouseCoords.lat}+${this.warehouseCoords.lng}`;

        return `https://www.google.pl/maps/dir/${url}`;
    }

    private goToRoute(route: RouteId): void {
        this.navService.goToPage(`/routes/${route.getRouteIdPath()}`);
    }


    private openMap(route: any, template: TemplateRef<any>) {
        //this.path = RoutePath.fromRouteSummary(route);
        this.path = new RoutePath().deserialize(route);

        this.route = route;
        this.mapTemplateRef = this.modalService.show(template, {
            class: 'modal-lg',
            animated: false
        });
    }

    private openMapGroupedBySlot(routeIndex: number, template: TemplateRef<any>) {
        this.mapOption = mapOptions.CLUSTER;
        this.mapProperties = {
            zoom: 11,
            center: {
                lat: 52.229676,
                lng: 21.012229
            },
            mapTypeId: mapTypes.ROADMAP
        };

        this.paths = [];
        this.selectedSlot = this.slotLabel(routeIndex + 1);
        const optimizeResult = _.cloneDeep(this.optimizeResult);
       // const routes = optimizeResult.extractDeliveriesBySlot(this.selectedSlot);
        // _.forEach(routes, route => this.paths.push(RoutePath.fromRouteSummary(route)));

        this.mapTemplateRef = this.modalService.show(template, {
            class: 'modal-lg',
            animated: false
        });
    }

    private openShiftMap(type: string, template: TemplateRef<any>) {
        this.mapOption = type === mapOptions.HEATMAP ? mapOptions.HEATMAP : mapOptions.CLUSTER;
        this.mapProperties = {
            zoom: 11,
            center: {
                lat: 52.229676,
                lng: 21.012229
            },
            mapTypeId: mapTypes.ROADMAP
        };

        this.paths = [];

        if (type === mapOptions.HEATMAP) {
            this.mapOption = mapOptions.HEATMAP;
            _.forEach(this.optimizeResult.routes[0].routes, route => this.paths.push(...this.visualiserService.getCoordinatesFromRoute(route)));
        } else {
            this.mapOption = mapOptions.CLUSTER;
            _.forEach(this.optimizeResult.routes[0].routes, route => this.paths.push(RoutePath.fromRouteSummary(route)));
        }

        this.mapTemplateRef = this.modalService.show(template, {
            class: 'modal-lg',
            animated: false
        });
    }

    private reflow(routeId: string): void {
        this.modalArgs = { action: this.translate.instant('reflow action'), route: routeId };
        this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-md' });
        this.confirmObservable = this.visualiserService.reflow(routeId);
        this.loadingActionButton = true;
    }

    private replan(routeId: string): void {
        this.modalArgs = { action: this.translate.instant('replan action'), route: routeId };
        this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-md' });
        this.confirmObservable = this.visualiserService.replan(routeId);
        this.loadingActionButton = true;
    }

    private shiftHours(shift) {
        const startTime = moment(shift.startTime, 'hours');
        const endTime = moment(shift.endTime, 'hours');
        const slotDuration = shift.slotDuration;
        const hours = [];

        while (startTime.hour() !== endTime.hour()) {
            hours.push(startTime.format("HH:mm") + '-' + startTime.add(slotDuration, "minutes").format("HH:mm"))
        }

        return hours
    }

    private slotLabel(routeIndex) {
        let test: string = '';

        _.filter(this.slotCols, item => {
            if (item.id === this.optimizeResult.shift.type) {
                test = item.hours[routeIndex - 1];
            }
        });

        return test;
    }


    private secToHours(seconds: number) {
        let minutes: number = Math.floor(seconds / 60);
        let hours: number = 0;

        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - hours * 60;
        }

        seconds = Math.floor(seconds % 60);

        if (hours) {
            return hours + 'h ' + minutes + 'm ' + seconds + 's';
        }
        return minutes + 'm ' + seconds + 's';
    }


    public deliveriesOutOfLimit(key: string, value: number): boolean{
        return (this.optimizeResult.shift.slotLimits[key.split("-")[0]] * this.optimizeResult.shift.fleetMaximum) < value
    }

    public modeReplanRoutes() {
        this.mode = 'REPLAN';
    }

    public findClosestDeliveriesFromModal(route: RouteExt, point: RoutePoint) {
        this.findClosestDeliveries(route, route.activities[point.stopNo]);
        this.mapTemplateRef.hide();
        this.confirmObservable = null;
    }

    public findClosestDeliveries(route: RouteExt, activity: Activity): void {

        const timePrecise = this.deliveryManagmentSettings.timePrecision;
        this.closeDeliveryIndex = 0;

        const deliveries = Object.values(route.deliveryDict);
        const startSlot = moment(activity.planning.from).add(-timePrecise, 'minutes');
        const endSlot = moment(activity.planning.to).add(timePrecise, 'minutes');

        try {
            this.selectedCrazyDelivery.activity = activity.type === ActivityType.DELIVERY ? activity : route.activities.find((a: Activity) => a.type === ActivityType.DELIVERY && a.stepNo > activity.stepNo);
            this.selectedCrazyDelivery.activity['actualDrivingTime'] = activity.durationSeconds;
            this.selectedCrazyDelivery.delivery = new Delivery();
            this.selectedCrazyDelivery.delivery.id = deliveries.find((d: Delivery) => d.id === this.selectedCrazyDelivery.activity.toString() ).id;

        } catch (err) {
            this.notifierService.notify('error', this.translate.instant('Cannot proceed, not found delivery for that driving activity item!'));
        }


        this.getDeliverySub = this.deliveriesService.getDelivery(this.selectedCrazyDelivery.delivery.id).subscribe(
            (delivery: Delivery) => {
                this.selectedCrazyDelivery.delivery = delivery;
                _.forEach(this.optimizeResult.routes, (optRoutes: OptimizeRoutes) => {
                    this.selectedCrazyDelivery.route = optRoutes.routes.find((r: RouteExt) => (r.activities.find((act: Activity) => act.id === this.selectedCrazyDelivery.activity.id)));
                });

                this.closestDeliveries = [];

                _.forEach(this.optimizeResult.routes, (optRoutes: OptimizeRoutes) => {
                    _.forEach(optRoutes.routes, (routeExt:  RouteExt) => {
                        _.forEach(routeExt.activities, (a:  Activity) => {
                            if (a.type === ActivityType.DELIVERY && routeExt.routeNumber !== this.selectedCrazyDelivery.route.routeNumber && routeExt.deliveriesCount < this.deliveryManagmentSettings.deliveriesCount) {
                                const departureTime = moment(a.slot.to);
                              
                                if (departureTime.isBetween(startSlot, endSlot) && a.id !== this.selectedCrazyDelivery.activity.id) {
                                    const routeRaw = new RouteExt();
                                    routeRaw.id = routeExt.id;
                                    routeRaw.routeNumber = routeExt.routeNumber;
                                    this.closestDeliveries.push({activity: a, delivery: null, route: routeRaw, path: null, routePath: null});
                                }
                            }
                        });
                    });
                });

                if (this.closestDeliveries.length > 0) {
                    this.findClosestDeliveriesPart2();
                } else {
                    this.notifierService.notify('warning', this.translate.instant('Cannot find any deliveries nearby!'));
                }

            }
        )
    }

    public findClosestDeliveriesPart2() {
        this.findClosestDeliveriesSub = this.visualiserService.findClosestDeliveries(this.selectedCrazyDelivery.activity, this.closestDeliveries).subscribe(
            (r: DeliveryExtInterface[]) => {
                r.filter((deliveryExt: DeliveryExtInterface) => deliveryExt.activity['waypoint']['distance'] <= this.deliveryManagmentSettings.drivingTime);
                r = _.uniqBy(r, (dExt: DeliveryExtInterface) => dExt.route.routeNumber);
                
                if (r.length > 1) {
                    this.numberOfCrazyDeliveries = (r.length > 9) ? 10 : r.length - 1;
                    this.closestDeliveries = r.slice(0, this.numberOfCrazyDeliveries);
                    this.addRoutePathToActivity(this.selectedCrazyDelivery.activity);
                    this.openCrazyDeliveryWindow();
                } else {
                    this.notifierService.notify('warning', this.translate.instant('Cannot find any deliveries nearby!'));
                }
            }
        );
    }

    public isCloseDelivery(activityId): boolean {
        return (this.closestDeliveries.length) 
            ? this.closestDeliveries.find((deliveryExt: DeliveryExtInterface) => deliveryExt.activity.id === activityId) !== undefined : false;
    }

    private openCrazyDeliveryWindow(): void {

        this.mapOption = mapOptions.CRAZY_DELIVERY_MAP;
        this.mapProperties = {
            zoom: 11,
            center: {
                lat: 52.229676,
                lng: 21.012229
            },
            mapTypeId: mapTypes.ROADMAP
        };

        this.mapTemplateRef = this.modalService.show(this.crazyDeliveryWindow, {
            class: 'modal-lg',
            animated: true
        });
    }

    private addRoutePathToActivity(crazyDelivery: Activity): void {

        this.closestDeliveries
            .map((deliveryExt: DeliveryExtInterface) => {

                _.forEach(this.optimizeResult.routes, (optRoutes: OptimizeRoutes) => {
                    deliveryExt.route = optRoutes.routes.find((r: RouteExt) => r.activities.find((act: Activity) => act.id === deliveryExt.activity.id));
                });

                deliveryExt.routePath = RoutePath.fromActivity(deliveryExt.route.activities);
                deliveryExt.path = RoutePath.fromActivity([deliveryExt.activity]);
                const deliveries = Object.values(deliveryExt.route.deliveryDict);
                const id = deliveries.find((d: Delivery) => d.id === deliveryExt.activity.id.toString() ).id;
                const sub: Subscription = this.deliveriesService.getDelivery(id)
                    .subscribe((delivery: Delivery) => {
                        deliveryExt.delivery = delivery;
                        sub.unsubscribe();
                    });
            })
                
            this.selectedCrazyDelivery.path = RoutePath.fromActivity([this.selectedCrazyDelivery.activity]);
    }

    public assignDelivery(deliveryExt: DeliveryExtInterface, movement: number): void {
        this.routeService.unassignDelivery(this.selectedCrazyDelivery.route.id, this.selectedCrazyDelivery.delivery).subscribe(() => {
            this.assignTest(deliveryExt.route, null, deliveryExt.activity.stepNo + movement, this.selectedCrazyDelivery.delivery);
        })
    }

    private assignTest(route, segment, pos, delivery) {
        this.routeService.assignDelivery(route, segment, pos, delivery).subscribe(
            (aaa) => {
                this.changedDataset(this.dataset);
                this.confirmObservable = null;
                this.mapTemplateRef.hide();
                this.mode = 'REGULAR';
        })
    }

    public closeDeliveryIndexFun(index: number): void {
        this.closeDeliveryIndexLoader = true;
        this.closeDeliveryIndex = (this.closeDeliveryIndex + index ) % this.numberOfCrazyDeliveries;

        setTimeout(() => {
            this.closeDeliveryIndexLoader = false;
        }, 500);
        
    }

    public ngOnDestroy() {
        if (this.getDeliverySub) {
            this.getDeliverySub.unsubscribe();
        }
        if (this.findClosestDeliveriesSub) {
            this.findClosestDeliveriesSub.unsubscribe();
        }
    }
}
