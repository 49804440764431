import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingAvailabilityComponent } from './components/booking-availability.compnent';
import { OrdersHistoryMapComponent } from './components/orders-history-map.component';
import { RouteMapComponent } from './components/route-map.component';
import { VisualiserComponent } from './visualiser.component';
import { PlanningSequenceComponent } from './components/planning-sequence/planning-sequence/planning-sequence.component';
import { EditPlanningSequenceComponent } from './components/planning-sequence/edit-planning-sequence/edit-planning-sequence.component';
import { RouteResolver } from '@routes/route.resolver';
import { ProxyComponent } from '@shared/components/proxy.component';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';
import { VisualiserRoutesPlanningComponent } from './components/visualiser-routes-planning/visualiser-routes-planning.component';
import { VisualiserCutoffComponent } from './components/visualiser-cutoff/visualiser-cutoff.component';
import { RouterOutletComponent } from '@shared/components/router-outlet.component';
import { VisualiserSolutionDetailsComponent } from './components/visualiser-solution-details/visualiser-solution-details.component';
import { VisualiserCrazyDeliveriesComponent } from './components/visualiser-crazy-deliveries/visualiser-crazy-deliveries.component';
import { VisualiserSimulatorComponent } from './components/visualiser-simulator/visualiser-simulator.component';
import { FindLocationComponent } from './components/find-location/find-location.component';
import { VisualiserSimulatorSolutionViewComponent } from './components/visualiser-simulator-solution-view/visualiser-simulator-solution-view.component';
import { AuditLogContentComponent } from '@shared/components/audit-log-content/audit-log-content.component';

const visualiserRoutes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        children: [
            { 
                path: '', 
                canActivate: [PermissionGuardService],
                component: VisualiserComponent,
                data: {
                    expectedPermission: [P.Permissions.Visualiser_view],
                    breadcrumb: 'Breadcrumbs.Visualiser',
                },
                children: [
                    {
                        path: ':y/:m/:d/:shiftId/:depot',
                        component: RouterOutletComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            breadcrumb: 'Breadcrumbs.Visualiser',
                            expectedPermission: [P.Permissions.Route_view]
                        },
                        children: [
                            {
                                path: '',
                                component: VisualiserRoutesPlanningComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    breadcrumb: 'Breadcrumbs.RouteMap',
                                    expectedPermission: [P.Permissions.Route_view]
                                },
                            },
                        ]
                    },
                    {
                        path: 'cutoff/:y/:m/:d/:shiftId/:depot/:cutoff',
                        component: RouterOutletComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            breadcrumb: 'Breadcrumbs.CutoffProccess',
                            expectedPermission: [P.Permissions.Route_view]
                        },
                        children: [
                            {
                                path: 'list',
                                component: VisualiserCutoffComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Route_view]
                                }
                            },
                            {
                                path: ':solutionId',
                                component: VisualiserSolutionDetailsComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    breadcrumb: 'Breadcrumbs.SolutionDetails',
                                    expectedPermission: [P.Permissions.Route_view]
                                },
                            },
                            { path: '**', redirectTo: 'list' },
                        ]
                    },
                    {
                        path: 'audit-log/:y/:m/:d/:shiftId/:depot/:type',
                        component: RouterOutletComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            breadcrumb: 'Breadcrumbs.AuditLog',
                            expectedPermission: [P.Permissions.Route_view]
                        },
                        children: [
                            {
                                path: 'list',
                                component: AuditLogContentComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Route_view]
                                }
                            },
                            { path: '**', redirectTo: 'list' },
                        ]
                    },
                    {
                        path: 'simulator/:y/:m/:d/:shiftId/:depot/:cutoff',
                        component: RouterOutletComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            breadcrumb: 'Breadcrumbs.Simulator',
                            expectedPermission: [P.Permissions.Route_view]
                        },
                        children: [
                            {
                                path: '',
                                component: VisualiserSimulatorComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Route_view]
                                }
                            },
                            {
                                path: ':simulationKey/solution/:solutionId/view',
                                component: VisualiserSimulatorSolutionViewComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Route_view],
                                }
                            },
                        ]
                    },
                    {
                        path: 'crazy-deliveries/:y/:m/:d/:shiftId/:depot',
                        component: RouterOutletComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            breadcrumb: 'Breadcrumbs.ManageCrazyDeliveries',
                            expectedPermission: [P.Permissions.Route_view]
                        },
                        children: [
                            {
                                path: 'management',
                                component: VisualiserCrazyDeliveriesComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Route_view]
                                }
                            },

                            { path: '**', redirectTo: 'list' },
                        ]
                    },
                    {
                        path: 'crazy-deliveries/:y/:m/:d/:shiftId/:depot',
                        component: RouterOutletComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            breadcrumb: 'Breadcrumbs.ManageCrazyDeliveries',
                            expectedPermission: [P.Permissions.Route_view]
                        },
                        children: [
                            {
                                path: 'management',
                                component: VisualiserCrazyDeliveriesComponent,
                                canActivate: [PermissionGuardService],
                                data: {
                                    expectedPermission: [P.Permissions.Route_view]
                                }
                            },

                            { path: '**', redirectTo: 'list' },
                        ]
                    }
                ]
            },
         ]
    },
    {
        path: 'map/:y/:m/:d/:shiftId/:warehouse/:routeNumber/:id',
        component: RouteMapComponent,
        canActivate: [PermissionGuardService],
        data: {
            breadcrumb: 'Breadcrumbs.RouteMap',
            expectedPermission: [P.Permissions.Route_view]
        },
        resolve: { route: RouteResolver }
    },
    {
        path: '',
        component: ProxyComponent,
        canActivate: [PermissionGuardService],
        data: {
            expectedPermission: [P.Permissions.Booking_availability]
        },
        children: [
            {
                path: 'booking-availability',
                component: BookingAvailabilityComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.BookingAvailability'
                }
            },
            {
                path: 'locations',
                component: FindLocationComponent,
                canActivate: [PermissionGuardService],
                data: {
                    breadcrumb: 'Breadcrumbs.FindLocations',
                    expectedPermission: [P.Permissions.Route_view]
                },
            },
        ]
    },
    {
        path: '',
        component: ProxyComponent,
        children: [
            {
                path: 'planning-sequence',
                component: RouterOutletComponent,
                canActivate: [PermissionGuardService],
                data: {
                    breadcrumb: 'Breadcrumbs.PlanningSequence',
                    expectedPermission: [P.Permissions.Config_planningsequence_view]
                },
                children: [
                    {
                        path: 'list', 
                        component: PlanningSequenceComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Config_planningsequence_view],
                            breadcrumb: 'Breadcrumbs.PlanningSequence'
                        }
                    },
                    {
                        path: 'edit/:id', 
                        component: EditPlanningSequenceComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Config_planningsequence_edit],
                            breadcrumb: 'Breadcrumbs.EditPlanningSequence'
                        }
                    },
                    {
                        path: 'create', component: EditPlanningSequenceComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Config_planningsequence_edit],
                            breadcrumb: 'Breadcrumbs.CreatePlanningSequence'
                        }
                    },

                    { path: '**', redirectTo: 'list' },
                ]
            },
        ]
    },
    {
        path: '',
        component: ProxyComponent,
        children: [
            {
                path: 'history', component: OrdersHistoryMapComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Analytics_ordershistory_view],
                    breadcrumb: 'Breadcrumbs.OrdersHistory'
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(visualiserRoutes)],
    exports: [RouterModule],
    providers: []
})

export class VisualiserRouting { }
