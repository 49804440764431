<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="mail"></i-feather> {{'Sms templates' | translate}}
            </h4>
        </div>
        <div toolbar>
          <button id="sms-templates-btn-create" class="btn btn-sm btn-uppercase btn-white"
              [routerLink]="['../create']">
              <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add message template' | translate }}
          </button>
          <div class="filter-container">
              <div id="sms-templates-search" class="phrase-filter">
                  <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
              </div>
          </div>
      </div>
        <hr />
    </div>

    <div content>
   
      <div *ngIf="smsTemplates">

          <ngx-datatable 
              id="apms" 
              class="bootstrap" 
              [rows]="smsTemplates" 
              [columns]="columns" 
              [columnMode]="'flex'"
              [headerHeight]="50" 
              [footerHeight]="50" 
              [messages]="tableMessages" 
              [rowHeight]="'auto'" 
              [externalPaging]="false"
              [count]="smsTemplates.length"
              [offset]="queryParams.page"
              [rowClass]="rowClass"
              [limit]="queryParams.size"
              [scrollbarH]="scrollBarHorizontal">
              <ng-container *ngFor="let col of columns; let index = index;">
                  <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                      <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                          <b (click)="sort()">{{ col.name | translate }}</b>
                      </ng-template>
                      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>

                          <div [ngSwitch]="col.prop">
                              <div *ngSwitchCase="'id'">
                                <span>{{rowIndex + 1}}</span>
                              </div>
                              <div id="sms-templates-table-parameters" *ngSwitchCase="'parameters'">
                                 {{value.length}}
                              </div>
                              <div id="sms-templates-table-status" *ngSwitchCase="'type'">
                                  <ng-container [ngSwitch]="value">
                                      <span *ngSwitchCase="'SMS'" class="badge badge-secondary">{{ 'SmsTemplateType.'+value | translate }}</span>
                                      <span *ngSwitchCase="'EMAIL_NOTIFICATION'" class="badge badge-success">{{ 'SmsTemplateType.'+value | translate }}</span>
                                      <span *ngSwitchCase="'INTERNAL_NOTIFICATION'" class="badge badge-warning">{{ 'SmsTemplateType.'+value | translate }}</span>
                                  </ng-container>
                              </div>
                              <div *ngSwitchCase="'options'">
                                  <div class="dropdown" dropdown>
                                      <button
                                          class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuButton">
                                          {{ 'Select' | translate }}
                                      </button>
                                      <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                          
                                          <a 
                                              id="sms-templates-btn-edit"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              [routerLink]="['../', 'edit', row.id]">
                                                  {{'Edit' | translate }}
                                          </a>
  
                                          <a 
                                              id="sms-templates-btn-remove"
                                              appCheckFeaturesConfig [featureName]="[Permissions.Message_template_edit]"
                                              class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                              (click)="remove(row.id)">
                                                  {{'Remove' | translate }}
                                          </a>
                                      </div>
                                  </div>
                              </div>
                              <div id="sms-templates-table-title" *ngSwitchCase="'title'">{{ value | translate }}</div>
                              <div *ngSwitchDefault>{{ value | translate }}</div>
                          </div>

                      </ng-template>
                  </ngx-datatable-column>
              </ng-container>
          </ngx-datatable>
      </div>

      <div *ngIf="loader" class="load-spinner text-center">
          <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
      </div>
  </div>

</layout-default>

<ng-template #confirmation>
  <div class="modal-header">
      <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body text-center">
      <p>{{'Do you confirm removal the messege template' | translate }}?</p>
      <button type="button" class="btn btn-brand-01" (click)="confirm()">
          {{'Yes, remove' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
  </div>
</ng-template>


<!-- {{'SmsTemplateType.SMS' | translate}} -->
<!-- {{'SmsTemplateType.EMAIL_NOTIFICATION' | translate}} -->
<!-- {{'SmsTemplateType.INTERNAL_NOTIFICATION' | translate}} -->