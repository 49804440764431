import * as moment from 'moment';
import { Moment } from 'moment';

import { DailyShiftConfig } from './dailyShiftConfig';

export class DayComposite {
    public dayOfMonth: number;
    public weekday: number;
    public dateRaw: string;
    public date: Moment;

    public dailyShiftConfig?: DailyShiftConfig;

    public isToday = false;
    public isFutureDay = false;
    public isPastDay = false;
    public isTomorrow = false;

    constructor(dateValue: string) {
        this.dateRaw = dateValue;
        this.date = moment(dateValue);

        const todayValue = moment().format('YYYY-MM-DD');
        const tomorrowValue = moment().add(1, 'day').format('YYYY-MM-DD');
        this.isToday = this.date.isSame(todayValue, 'day');
        this.isFutureDay = this.date.isAfter(todayValue, 'day');
        this.isPastDay = this.date.isBefore(todayValue, 'day');
        this.isTomorrow = this.date.isSame(tomorrowValue, 'day');

        // this.dailyShiftConfig = new DailyShiftConfig().deserialize()

        // console.log(dateValue);
    }
}
