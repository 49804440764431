import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { QueryParams } from '@interfaces/query-params.interface';
import { Collection } from '@interfaces/collection.interface';
import { Agency } from '../interfaces/agency';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { objectToString } from '@shared/functions/object-to-string.function';
import { environment } from '@environment';
import { UtilsService } from '@services/utils.service';
import { CrudService } from '@services/crud.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyService extends CrudService<Agency, number> {

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/salary/v1/plan`;  
  // private queryParamsSubject: Subject<QueryParams> = new ReplaySubject<QueryParams>(1);

  // public agencySubscriptions$: Observable<Collection<Agency>> = this.queryParamsSubject.asObservable()
  //   .pipe(
  //     switchMap(queryParams => this.fetchAllFromServer(queryParams))
  //   );

  constructor(
    protected readonly http: HttpClient,
    protected readonly utilsService: UtilsService
  ) {
    super(http, AgencyService.BASE_API, utilsService);
  }

  // public fetchAll(params: QueryParams) {
  //   this.queryParamsSubject.next((params));
  // }

  // private fetchAllFromServer(params: QueryParams): Observable<Collection<Agency>> {
  //   const paramsString = objectToString(params);
  //   let url = `${AgencyService.BASE_API}/list`;

  //   if (paramsString) {
  //     url += '?' + paramsString;
  //   }

  //   return this.http.get<Collection<Agency>>(url, { observe: 'response' })
  //     .pipe(
  //       map((response: HttpResponse<any>) => {
  //         return response.body as Collection<Agency>;
  //       })
  //     );
  // }

  public createAgency(rawData: Agency): Observable<Agency> {
    return this.http.post<Agency>(AgencyService.BASE_API, rawData, this.utilsService.httpHeaders());
  }

  public updateAgency(rawData: Agency): Observable<Agency> {
    return this.http.put<Agency>(AgencyService.BASE_API + `/${rawData.id}`, rawData, this.utilsService.httpHeaders());
  }

  public findOne(agencyId: string): Observable<Agency> {
    return this.http.get<Agency>(AgencyService.BASE_API + `/${agencyId}`);
  }

  public assignDriversToAgency(agencyId: string, driversIdSet: string[]): Observable<Agency> {
    return this.http.post<Agency>(AgencyService.BASE_API + `/${agencyId}/assign-driver`, driversIdSet);
  }

  public unassignDriversToAgency(agencyId: string, driversIdSet: string[]): Observable<Agency> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), 
      body: driversIdSet
   };
    return this.http.delete<Agency>(AgencyService.BASE_API + `/${agencyId}/unassign-driver`, httpOptions);
  }
}
