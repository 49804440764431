import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ORDER } from '@enums/enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import { Depot } from '@interfaces/depot.interface';
import { DepotService } from '../../services/depot.service';
import { PageableResponse } from '@entities/pagable-response';
import { tap } from 'rxjs/operators';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';

@Component({
  selector: 'app-depot',
  templateUrl: './depot.component.html',
  styleUrls: ['./depot.component.scss']
})
export class DepotComponent implements OnInit {

  public list$: Observable<PageableResponse<Depot>>;
  
  public depots: Depot[];
  private depotsCollection: Depot[];


  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  public modifiedRow: string;
  public rowClass;

  public partnerId: string;
  public regionId: string;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'id', headerClass: 'id', name: this.translateService.instant('Id'), flexGrow: 1, align: 'left' },
    { prop: 'name', headerClass: 'name', name: this.translateService.instant('Name'), flexGrow: 2, align: 'left' },
    { prop: 'code', headerClass: 'code', name: this.translateService.instant('Code'), flexGrow: 2, align: 'left' },
    { prop: 'address', headerClass: 'address', name: this.translateService.instant('Address'), flexGrow: 4, align: 'center' },
    { prop: 'options', headerClass: 'options', name: this.translateService.instant('Options'), flexGrow: 1, align: 'center' },
  ];

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  constructor(
    private readonly depotService: DepotService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly modalService: BsModalService,
    private readonly notifierService: NotifierService,
  ) { }

  public ngOnInit() {
    this.modifiedRow = this.navigationRoute.snapshot.queryParams.modifiedRow;

    this.list$ = this.depotService.list$.pipe(
      tap((list: PageableResponse<Depot>) => {
          this.depots = this.depotsCollection = list.content;
        })
      );

    this.depotService.fetchAll(this.queryParams);
 
    this.rowClass = row => {
      return { 'row-modified': row.id == this.modifiedRow };
    };
    
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.depotService.fetchAll(this.queryParams);
  }

  public onSort(sortInfo) {
    this.queryParams.sort = sortInfo.sorts[0].prop
    this.queryParams.order = (sortInfo.sorts[0].dir === 'asc') ? ORDER.ASC : ORDER.DESC;
    this.depotService.fetchAll(this.queryParams);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.depotsCollection, (d: Depot) => {
      const searchIn: string = `${d.code} ${d.name}`;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

   this.depots = temp;
  }

  public removeDepot(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.depotService.delete(id);
  }

  public sendTestSms(id: string) {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.depotService.sendTestSmsNotification(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.queryParams = environment.pagination;
      this.depotService.fetchAll(this.queryParams);      
      this.notifierService.notify('success', this.translateService.instant('The action has been executed.'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }
}
