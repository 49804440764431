
import { Directive, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { AppService } from '@services/app.service';
import { Subscription } from 'rxjs';
import { ConfigValue } from 'projects/inpost/src/app/operations/entities/config-value';
import { AuthenticationService } from 'src/app/authentication.service';
import { Permissions } from '@enums/permissions';

@Directive({
    selector: '[appCheckFeaturesConfig]'
})

export class CheckFeaturesConfigurationDirective implements OnInit {    
    @Input() public featureName: Permissions[];

    constructor(
        private readonly elementRef: ElementRef,
        private readonly authService: AuthenticationService
    ) {}

    public ngOnInit() {
        if (!this.authService.checkPrivileges(this.featureName)) {
            this.elementRef.nativeElement.remove();
        }
    }
}
