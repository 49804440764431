import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxBarcodeModule } from 'ngx-barcode';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { DriverEditComponent } from './components/driver-edit.component';
import { DriversAvailabilityComponent } from './components/drivers-availability.component';
import { DriversBarcodesComponent } from './components/drivers-barcodes.component';
import { DriversComponent } from './components/drivers.component';
import { PrintDriversScheduleComponent } from './components/print-drivers-schedule.component';
import { DriversRouting } from './drivers.routing';
import { DriversService } from './services/drivers.service';
import { DriversResolver } from './resolvers/drivers.resolvers';
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
    imports: [CommonModule, 
        SharedModule, 
        TooltipModule.forRoot(),
        RouterModule, DriversRouting, NgxBarcodeModule, NgDatepickerModule, NgxDatatableModule, ReactiveFormsModule, AuthenticationModule],
    declarations: [DriversComponent, DriverEditComponent, DriversBarcodesComponent, DriversAvailabilityComponent, PrintDriversScheduleComponent],
    providers: [DriversService, DriversResolver]
})
export class DriversModule { }
