import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';

import { ShiftType, SlotChoiceStrategy } from '@enums/enum';
import { ShiftConstants, ShiftHoursInterface } from '@shared/constants/shift.constants';

import { ExecuteSimulation } from '../entities/execute';
import { Simulation } from '../entities/simulation';
import { SimulatorService } from '../services/simulator.service';

@Component({
    selector: 'app-simulation-execute',
    template: `
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-5">
                    <label for="deliveryPoolSize">{{ 'Delivery pool size' | translate }}:</label>
                    <input type="number" id="deliveryPoolSize" formControlName="deliveryPoolSize" class="form-control" />
                </div>

                <div class="form-group col-3">
                    <ng-container formGroupName="slotChoiceStrategy">
                        <label for="choiceType">{{ 'Slot choice strategy' | translate }}:</label>
                        <select class="form-control" formControlName="@type">
                            <option *ngFor="let option of slotChoiceStrategyOptions" [value]="option">{{ option | translate }}</option>
                        </select>
                    </ng-container>
                </div>

                <div class="form-group col-2">
                    <ng-container formGroupName="slotChoiceStrategy">
                        <label for="slot">{{ 'Slot' | translate }}:</label>
                        <select class="form-control" [attr.disabled]="true" formControlName="slot">
                            <option *ngFor="let option of slots" [value]="option">{{ option }}</option>
                        </select>
                    </ng-container>
                </div>

                <div class="form-group col-2">
                    <label>&nbsp;</label>

                    <button [disabled]="submitted" type="submit" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-white submit">
                        <span *ngIf="!submitted">{{ 'Inject deliveries' | translate }} </span>
                        <div class="load-spinner">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true" *ngIf="submitted"></i>
                        </div>
                    </button>
                </div>
            </div>
        </form>

        <!--{{'FIRST_AVAILABLE_STRATEGY' | translate }} -->
        <!--{{'LAST_AVAILABLE_STRATEGY' | translate }} -->
        <!--{{'RANDOM_AVAILABLE_STRATEGY' | translate }} -->
        <!--{{'FIXED_SLOT_STRATEGY' | translate }} -->
        <!--{{'MANUAL_SLOT_STRATEGY' | translate }} -->
        <!--{{'SMALL_BIAS_STRATEGY' | translate}} -->
        <!--{{'LARGE_BIAS_STRATEGY' | translate}} -->
    `,
    styleUrls: ['./create-simulation.component.scss']
})
export class ExecuteSimulationComponent implements OnInit {
    @Input() public simulationId;
    @Input() public shift: ShiftType;
    @Input() public simulation: Simulation;

    public form: FormGroup;
    public submitted: boolean = false;

    get slotChoiceStrategyOptions() {
        return _.values(SlotChoiceStrategy);
    }

    get slots(): Array<string> {
        return _.find(ShiftConstants.slotCols, (s: ShiftHoursInterface) => s.id === this.shift).hours;
    }

    constructor(private formBuilder: FormBuilder, private simulatorService: SimulatorService, private notifierService: NotifierService, private translate: TranslateService) {}

    public ngOnInit() {
        this.form = this.formBuilder.group({
            deliveryPoolSize: [1, Validators.required],
            slotChoiceStrategy: this.formBuilder.group({
                '@type': [SlotChoiceStrategy.FIRST_AVAILABLE_STRATEGY, Validators.required],
                slot: [null, { disabled: true }]
            })
        });

        (this.form.controls.slotChoiceStrategy as FormGroup)
            .get('@type')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(type => {
                const field = (this.form.controls.slotChoiceStrategy as FormGroup).get('slot');
                if (type !== SlotChoiceStrategy.FIXED_SLOT_STRATEGY) {
                    field.setValue(null);
                    field.disable();
                } else {
                    field.setValue(this.slots[0]);
                    field.enable();
                }
            });
    }

    public buildShiftId(): string {
        return `${this.simulation.simulationShiftConfigDto.date}:${localStorage.getItem('depot')}:${this.shift}`;
    }

    public onSubmit() {
        if (!this.form.valid) {
            return;
        }

        this.submitted = true;
        const rawConfig = this.form.getRawValue();

        this.simulatorService.execute(this.simulationId, rawConfig, this.buildShiftId()).subscribe(
            (executeSimulation: ExecuteSimulation) => {
                this.notifierService.notify('success', this.translate.instant('Successfully executed attempt'));
            },
            error => {
                this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
                this.submitted = false;
            },
            () => {
                this.submitted = false;
            }
        );
    }
}
