import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'distancePerDeliveryPipe',
    pure: true
})
export class DistancePerDeliveryPipe implements PipeTransform {
    public transform(distances: Array<any>, totalDeliveries: number): string {
        if (distances.length) {
            return (_.sumBy(distances, r => r.totalDistance) / totalDeliveries / 1000).toFixed(2) + ' km';
        }
    }
}
