import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Dataset } from "../simulator/entities/dataset";

@Component({
    selector: 'visualiser',
    templateUrl: './visualiser.component.html',
    styleUrls: ['./visualiser.component.scss']
})
export class VisualiserComponent {
    private TAG: string = '[VisualiserComponent]';
    public dataset: Dataset;
    public deliveryManagementSettings: Object;

    constructor() {}

    // public changedDataset(dataset: Dataset): void {
    //     console.log(this.TAG, '(changedDataset)', dataset);
    //     this.dataset = dataset;
    // }

    // public changedDeliveryManagementSettingsFun(event): void {
    //     console.log(event);
    //     this.deliveryManagementSettings = event;
    // }
}
