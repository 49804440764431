import { Injectable } from '@angular/core';
import { Depot } from '@interfaces/depot.interface';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { Coordinates } from '@entities/coordinates';
import { map } from 'rxjs/internal/operators/map';
import { PartnerUser } from 'projects/inpost/src/app/operations/interfaces/partneruser.interface';
import { Driver } from '@drivers/entities/driver';
import { Van } from '@hardware/entities/van';
import { Hardware } from '@hardware/entities/hardware';

@Injectable({
  providedIn: 'root'
})
export class DepotService extends CrudService<Depot, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/depot/v1`;  
  protected static GOOGLE_API: string = 'https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}';

  protected static UNASSIGN_USER_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/user';
  protected static UNASSIGN_DRIVER_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/driver';
  protected static UNASSIGN_VANS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/vehicle';
  protected static UNASSIGN_HARDWARE_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/hardware';

  protected static ASSIGN_USERS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/user';
  protected static ASSIGN_DRIVERS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/driver';
  protected static ASSIGN_VANS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/vehicle';
  protected static ASSIGN_HARDWARE_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/hardware';

  protected static GET_ASSIGN_USERS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/user/list';
  protected static GET_ASSIGN_DRIVERS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/driver/list';
  protected static GET_ASSIGN_VANS_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/vehicle/list';
  protected static GET_ASSIGN_HARDWARE_TO_DEPOT = `${environment.api.url}${environment.api.prefix}/depot/v1/` + '${depotId}/hardware/list';

  protected static GET_DEPOTS_FOR_USER = `${environment.api.url}${environment.api.prefix}/depot/v1/user/depot/list`;

  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, DepotService.BASE_API, utilsService);
    }

    public getCoordinatesForLocation(address: string): Observable<any> {
      const endpoint = this.utilsService.interpolate(DepotService.GOOGLE_API, {address: address, apiKey: environment.googleMapApiKey});
      return this._http.get(endpoint).pipe(
        map(response => plainToClass(Coordinates, response['results'][0]['geometry']['location']))
      )
    }

    public unassignUserFromDepot(depotId: number, userId: string): Observable<PartnerUser[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.UNASSIGN_USER_TO_DEPOT}`, { depotId });

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: [userId],
      };

      return this._http.delete<PartnerUser[]>(endpoint, options);
    }

    public unassignDriverFromDepot(depotId: number, driverId: string): Observable<Driver[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.UNASSIGN_DRIVER_TO_DEPOT}`, { depotId });

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: [driverId],
      };

      return this._http.delete<Driver[]>(endpoint, options);
    }

    public unassignVansFromDepot(depotId: number, vanId: string): Observable<Driver[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.UNASSIGN_VANS_TO_DEPOT}`, { depotId });

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: [vanId],
      };

      return this._http.delete<Driver[]>(endpoint, options);
    }

    public unassignHardwareFromDepot(depotId: number, hardwareId: string): Observable<Hardware[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.UNASSIGN_HARDWARE_TO_DEPOT}`, { depotId });

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: [hardwareId],
      };

      return this._http.delete<Hardware[]>(endpoint, options);
    }

    public assignUsersToDepot(depotId: number, users: string[]): Observable<PartnerUser[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.ASSIGN_USERS_TO_DEPOT}`, { depotId });
      return this._http.post<PartnerUser[]>(endpoint, users);
    }

    public assignDriversToDepot(depotId: number, users: string[]): Observable<Driver[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.ASSIGN_DRIVERS_TO_DEPOT}`, { depotId });
      return this._http.post<Driver[]>(endpoint, users);
    }

    public assignVansToDepot(depotId: number, users: string[]): Observable<Van[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.ASSIGN_VANS_TO_DEPOT}`, { depotId });
      return this._http.post<Van[]>(endpoint, users);
    }

    public assignHardwareToDepot(depotId: number, users: string[]): Observable<Hardware[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.ASSIGN_HARDWARE_TO_DEPOT}`, { depotId });
      return this._http.post<Hardware[]>(endpoint, users);
    }

    public getAssignUsersToDepot(depotId: number): Observable<PartnerUser[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.GET_ASSIGN_USERS_TO_DEPOT}`, { depotId });
      return this._http.get<PartnerUser[]>(endpoint);
    }

    public getAssignDriversToDepot(depotId: number): Observable<Driver[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.GET_ASSIGN_DRIVERS_TO_DEPOT}`, { depotId });
      return this._http.get<Driver[]>(endpoint);
    }

    public getAssignVansToDepot(depotId: number): Observable<Van[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.GET_ASSIGN_VANS_TO_DEPOT}`, { depotId });
      return this._http.get<Van[]>(endpoint);
    }

    public getAssignHardwareToDepot(depotId: number): Observable<Hardware[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.GET_ASSIGN_HARDWARE_TO_DEPOT}`, { depotId });
      return this._http.get<Hardware[]>(endpoint);
    }
    
    public getDepotsForUser(): Observable<Depot[]> {
      const endpoint = this.utilsService.interpolate(`${DepotService.GET_DEPOTS_FOR_USER}`, { });
      return this._http.get<Depot[]>(endpoint);
    }
}
