import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as plLocale from 'date-fns/locale/pl';
import * as _ from 'lodash';
import { DatepickerOptions } from 'ng2-datepicker';
import { Subscription, throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DriverAppLang, DriverAppLangOptions, DriverExperience, DriverNationality, DriverNationalityOptions, DriverSocialNumberType, DriverSocialNumberTypeOptions, DriverStatus, DriverStatusOptions, Client } from '@enums/enum';
import { Van } from '@hardware/entities/van';
import { HardwareService } from '@hardware/services/hardware.service';
import { NavService } from '@services/nav.sevice';

import { Driver } from '../entities/driver';
import { DriversService } from '../services/drivers.service';
import { AppService } from '@services/app.service';
import { Depot } from '@interfaces/depot.interface';
import { environment } from '@environment';
import { LocalStorageService } from '@services/local-storage.service';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Component({
    selector: 'driver-edit',
    templateUrl: 'driver-edit.component.html',
    styleUrls: ['driver-edit.component.scss']
})
export class DriverEditComponent implements OnInit, OnDestroy {
    private readonly TAG: string = '[DriverEditComponent]';
    private vanSubscription: Subscription;
    private driversQueryParams: Subscription;

    public driver: Driver = new Driver();
    public vans: Van[] = [];
    public formErrorsMessages;
    public employeeId: number;
    public mode: string = 'list';
    public form: FormGroup;
    public submitted: boolean = false;
    public options: DatepickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'DD.MM.YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1,
        locale: plLocale
    };
    public depots$: Observable<Depot[]>;

    private readonly notifier: NotifierService;
    private depotPrefix: string;


    get disabled() {
        return this.mode !== 'create' && this.mode !== 'edit';
    }

    get statusOptions() {
        return DriverStatusOptions;
    }

    get socialNumberTypeOptions() {
        return DriverSocialNumberTypeOptions;
    }

    get appLangOptions() {
        return DriverAppLangOptions;
    }

    get nationalityOptions() {
        return DriverNationalityOptions;
    }

    get parsedEmployeeId() {
        return `D${('0' + this.employeeId).slice(-3)}_${this.depotPrefix}`;
    }

    constructor(
        private readonly driversService: DriversService,
        private readonly hardwareService: HardwareService,
        private readonly navService: NavService,
        private readonly notifierService: NotifierService,
        private readonly translateService: TranslateService,
        private readonly navigationRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly localStorageService: LocalStorageService,
        public readonly appService: AppService
    ) {
        this.notifier = notifierService;
    }

    public ngOnInit() {
        this.driversQueryParams = this.navigationRoute.params.subscribe(params => {
            this.employeeId = _.get(params, 'driverId', '');
        });

        this.depotPrefix = this.appService.findWarehouseRef(this.localStorageService.getDepot());

        this.depots$ = this.appService.getWarehouses();

        this.form = this.formBuilder.group({
            id: [null],
            employeeId: [{ value: null, disabled: false }],
            status: [DriverStatus.ACTIVE],
            firstName: [null, Validators.required],
            lastname: [null, Validators.required],
            pinCode: [null, Validators.required],
            socialNumberType: [DriverSocialNumberType.PESEL],
            socialNumber: [null],
            driversLicenseId: [null],
            depotId: [{value: this.localStorageService.getDepot(), disabled: false}, Validators.required],
            phoneNumber: [null],
            coolomatCode: [null],
            email: [null, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')],
            nationality: [DriverNationality.PL],
            appLang: [DriverAppLang.PL],
            joinedAt: [new Date()],
            experience: [DriverExperience.MEDIOR],
            preferredVan: [null],
            version: [null]
        });

        this.navigationRoute.params.subscribe(params => {
            if (params.action === 'create') {
                this.mode = 'create';
                this.selectedDriverId = '';
                if (environment.client === Client.FRISCO) {
                    this.form.controls.employeeId.setValue(this.parsedEmployeeId);
                }
                
            } else if (params.action === 'show') {
                this.mode = 'show';
                this.selectedDriverId = params.driverId;
            } else if (params.action === 'edit') {
                this.mode = params.action;
                this.selectedDriverId = params.driverId;
            }
            if (this.disabled) {
                this.form.disable();
            } else {
                this.form.enable();
                // if (environment.client === Client.FRISCO) {
                //     this.form.controls.employeeId.disable();
                // }
            }
        });

        this.vanSubscription = this.hardwareService.vans.subscribe((data: Van[]) => {
            this.vans = data;
            console.log(this.TAG, 'subscribe receive model: ', this.vans);
        });
    }

    set selectedDriverId(driverId) {
        if (driverId === undefined || driverId === '') {
            return;
        }

        this.driversService.getDriver(driverId).subscribe((driver: Driver) => {
            this.driver = driver;
            this.form.patchValue(driver);
            console.log(this.TAG, ' model: ', this.form);
        });
    }

    public onSubmit(): void {
        this.submitted = true;
        this.driver.patch(this.form.getRawValue());
        const rawDriver = _.cloneDeep(this.driver);

        if (this.form.controls.preferredVan.value === null) {
            rawDriver.preferredVan = null;
        }

        console.log(`${this.TAG} mode: ${this.mode}`, this.driver);

        if (this.mode === 'create') {
            delete rawDriver.version;
            this.driversService
                .createDriver(rawDriver)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    (driver: Driver) => {
                        console.log(this.TAG, 'Creating driver', driver);
                        this.notifierService.notify('success', this.translateService.instant('Data has been updated'));
                        this.navService.goToPage('/drivers', { modifiedId: driver.id });
                        this.submitted = false;
                    },
                    error => {
                        console.log(`${this.TAG} Creating driver - error`, error);
                        this.formErrorsMessages = error;
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        } else if (this.mode === 'edit') {
            this.driversService
                .updateDriver(rawDriver)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    (driver: Driver) => {
                        console.log(this.TAG, 'Updating driver', driver);

                        try {

                            this.notifier.notify('success', this.translateService.instant('Data has been updated'));
                        } catch (er) {
                            console.error(er)
                        }
                        this.navService.goToPage('/drivers', { modifiedId: driver.id });
                        this.submitted = false;
                    },
                    error => {
                        console.log(`${this.TAG} Updating driver - error`, error);
                        this.formErrorsMessages = error;
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        }
    }

    public showDriverBarcode() {
        const printContents = document.getElementById('driver-profile').innerHTML;
        const popupWin = window.open('', '_blank', 'width=700,height=700');
        popupWin.document.open();
        popupWin.document.write(`<html><body onload="window.print()">${printContents}</body></html>`);
        popupWin.document.close();
    }

    public compareFn(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    public ngOnDestroy(): void {
        if (this.vanSubscription) {
            this.vanSubscription.unsubscribe();
        }
    }

    public tooltipLabel(property: string): string {
        return tooltipLabel(this.translateService, `tooltips.driver.edit.labels.${property}`);
      }

    private __translate() {
        this.translateService.instant('id');
        this.translateService.instant('employeeId');
        this.translateService.instant('status');
        this.translateService.instant('firstName');
        this.translateService.instant('lastname');
        this.translateService.instant('pinCode');
        this.translateService.instant('socialNumberType');
        this.translateService.instant('socialNumber');
        this.translateService.instant('driversLicenseId');
        this.translateService.instant('phoneNumber');
        this.translateService.instant('email');
        this.translateService.instant('nationality');
        this.translateService.instant('appLang');
        this.translateService.instant('joinedAt');
        this.translateService.instant('experience');
        this.translateService.instant('preferredVan');
        this.translateService.instant('version');
    }
}
