<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="package"></i-feather> {{'Depots' | translate}}
            </h4>
        </div>

        <div toolbar>
            <button id="btn-create-depot" class="btn btn-sm btn-uppercase btn-white btn-depot-create"
                [routerLink]="['../create']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{
                'Create depot' | translate }}
            </button>
            <button id="btn-depot-map-view" class="btn btn-sm btn-uppercase btn-white btn-depots-map"
                [routerLink]="['../map']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="map"></i-feather> {{
                'Depots map' | translate }}
            </button>
            <div id="depot-searchbar" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}"
                        (keyup)='applyFiltering($event)' />
                </div>
            </div>
        </div>
        <hr />
    </div>

    <div content>

        <div *ngIf="(list$ | async) as list">
            <ngx-datatable id="hardware" class="bootstrap" [rows]="depots" [columns]="columns" [columnMode]="'flex'"
                [headerHeight]="50" [footerHeight]="50" [messages]="tableMessages" [rowHeight]="'auto'"
                [externalPaging]="true" [count]="list.totalElements" [offset]="queryParams.page" [rowClass]="rowClass"
                [limit]="queryParams.size" [scrollbarH]="scrollBarHorizontal" (page)="setPage($event)">

                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="col.headerClass">
                        <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                            <b (click)="sort()">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>


                            <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'address'">{{value | addressLabelPipe}}</div>
                                <div *ngSwitchCase="'options'">
                                    <div class="dropdown" dropdown>
                                        <button class="btn btn-outline-secondary btn-xs dropdown-toggle" type="button"
                                            id="dropdownMenuButton">
                                            {{ 'Select' | translate }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                            <a id="btn-depot-edit"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-edit"
                                                [routerLink]="['../edit', row.id]">
                                                {{'Edit' | translate }}
                                            </a>

                                            <a id="btn-depot-virtual-list"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-edit"
                                                [routerLink]="['../virtual', row.id, 'list']">
                                                {{'Virtual depots' | translate }}
                                            </a>

                                            <a id="btn-depot-assign-users"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-assign"
                                                [routerLink]="['../assigned-users', row.id]">
                                                {{'Assigned users' | translate }}
                                            </a>

                                            <a id="btn-depot-assign-users"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-assign"
                                                [routerLink]="['../assigned-drivers', row.id]">
                                                {{'Assigned drivers' | translate }}
                                            </a>

                                            <a id="btn-depot-assign-vans"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-assign"
                                                [routerLink]="['../assigned-vans', row.id]">
                                                {{'Assigned vans' | translate }}
                                            </a>

                                            <a id="btn-depot-hardware-vans"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-assign"
                                                [routerLink]="['../assigned-hardware', row.id]">
                                                {{'Assigned hardware' | translate }}
                                            </a>

                                            <a id="btn-depot-sms-test"
                                                class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-assign"
                                                (click)="sendTestSms(row.id)">
                                                {{'Send SMS notification' | translate }}
                                            </a>

                                            <!-- <a class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-remove"
                                                        (click)="removeDepot(row.id)">
                                                        {{'Remove' | translate }}
                                                    </a> -->

                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchDefault>{{value}}</div>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>

        </div>

        <ng-template #loaderRef>
            <div *ngIf="loader" class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
            </div>
        </ng-template>


    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm the action' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>