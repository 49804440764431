import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersRoles } from '@enums/roles';
import { RoleGuardService } from '@guards/role.guard';
import { ProxyComponent } from '@shared/components/proxy.component';
import { FriscoAppComponent } from '../frisco-app.component';
import { SalaryListComponent } from './components/salary-list/salary-list.component';
import { AgencyListComponent } from './components/agency-list/agency-list.component';
import { AgencyEditComponent } from './components/agency-edit/agency-edit.component';
import { AgencyResolver } from './resolvers/agency.resolver';
import { DriversResolver } from '@drivers/resolvers/drivers.resolvers';
import { AgencyAssignDriverComponent } from './components/agency-assign-driver/agency-assign-driver.component';
import { ReportListComponent } from './components/report-list/report-list.component';
import { ReportEditComponent } from './components/report-edit/report-edit.component';
import { ReportCreateComponent } from './components/report-create/report-create.component';
import { SalaryReportResolver } from './resolvers/salary-report.resolver';
import { ReportAssignDriverComponent } from './components/report-assign-driver/report-assign-driver.component';
import { RouterOutletComponent } from '@shared/components/router-outlet.component';
import { SalaryReportFilesResolver } from './resolvers/salary-report-files.respolver';
import { ReportFilesComponent } from './components/report-files/report-files.component';


const routes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        // canActivate: [RoleGuardService],
        // data: {
        //     expectedRole: [UsersRoles.ROLE_SYSADMIN, UsersRoles.ROLE_DISPATCHER]
        // },
        children: [
            {
                path: 'agency', component: FriscoAppComponent,
                children: [
                    {
                        path: 'list',
                        component: AgencyListComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.Agency'
                        }
                    },
                    {
                        path: 'create',
                        component: AgencyEditComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.AgencyCreate'
                        }
                    },
                    {
                        path: ':agencyId/edit',
                        component: AgencyEditComponent,
                        resolve: {
                            agency: AgencyResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.AgencyEdit'
                        }
                    },
                    {
                        path: ':agencyId/assigning-drivers',
                        component: AgencyAssignDriverComponent,
                        resolve: {
                            agency: AgencyResolver,
                            drivers: DriversResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.AgencyAssigningDrivers'
                        }
                    },
                    {
                        path: '**', redirectTo: 'list',
                    }
                ]
            },
            {
                path: 'report', 
                component: RouterOutletComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.SalarReport'
                },
                children: [
                    {
                        path: 'list',
                        component: ReportListComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.SalarReport'
                        }
                    },
                    {
                        path: 'create/:reportId',
                        component: ReportCreateComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.SalarReportCreate'
                        }
                    },
                    {
                        path: 'edit/:reportId',
                        component: ReportEditComponent,
                        resolve: {
                            report: SalaryReportResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.SalarReportEdit'
                        }
                    },
                    {
                        path: ':reportId/assigning-drivers',
                        component: ReportAssignDriverComponent,
                        resolve: {
                            report: SalaryReportResolver,
                            drivers: DriversResolver
                        },
                        data: {
                            breadcrumb: 'Breadcrumbs.SalaryReportAssigningDrivers'
                        }
                    },
                    {
                        path: ':reportId/files', 
                        component: RouterOutletComponent,
                        data: {
                            breadcrumb: 'Breadcrumbs.ReportFiles',
                        },
                        children: [
                            { path: '', pathMatch: 'full', redirectTo: 'list' },
                            { 
                                path: 'list', 
                                component: ReportFilesComponent,
                                resolve: {
                                    salaryReportFiles: SalaryReportFilesResolver
                                },
                                data: {
                                    breadcrumb: 'Breadcrumbs.ReportFiles'
                                }
                            },
                        ]
                    },
                    {
                        path: '**', redirectTo: 'list',
                    }
                ]
            },
            {
                path: '**', redirectTo: 'agency',
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SalaryRoutingModule { }