import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RouteExt } from '@entities/route-ext';
import { RoutePath } from '@entities/route-path';
import { RouteId } from '@entities/routeId';
import { Slot } from '@entities/slot';
import { environment } from '@environment';
import { AuthenticationService } from '../../authentication.service';
import { OptimizeResult } from '../entities/optimize-result';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { FinderEvent } from '@analytics/entities/finder-event';
import { UtilsService } from '@services/utils.service';
import { Activity } from '@entities/activity';
import { AppService } from '@services/app.service';
import { DeliveryExtInterface } from '../interfaces/delivery-ext.interface';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Depot } from '@interfaces/depot.interface';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { ShiftSummaryStats } from '../interfaces/shift-summary-stats.interface';
import { ActivityType } from '@enums/enum';

@Injectable({
    providedIn: 'root'
})
export class VisualiserService {
    private static ROUTE_STRATEGY_DICTIONARY: string = 'dictionary/v1/RouteStrategy';
    private static ROUTE_SEGMENTS: string = 'route/v3/${routeId}?segmented=true';
    private static COORDINATES_FOR_PAST_DELIVERIES: string = 'location/v1/deliveries?startdate=${startDate}&enddate=${endDate}';
    private static CALCULATE_DISTANCE: string = 'https://osrm.open-routing.com/route/v1/driving/${destinations}';
    private static CHECK_AVAILABLE_SLOTS: string = 'slot/v2/available/${date}/${warehouse}/${customerRef}';
    private static OPTIMIZE_ROUTES: string = 'shifts/v3/recalculate/${shiftId}/${mustFit}';
    private static GENERATE_SOLUTIONS: string = 'shifts/v4/recalculate/${shiftId}/${mustFit}';
    private static FINALIZE: string = 'shifts/v3/finalize/${shiftId}';
    private static CLOSE_SHIFT: string = 'shifts/v2/close/${shiftId}';
    private static REFLOW: string = 'route/v2/${routeId}/reflow';
    private static REPLAN: string = 'route/v2/${routeId}/replan';
    private static SHIFT_STATUS_CHANGE: string = 'eventlog/v1/shift/${shiftId}/SHIFT_STATUS_CHANGE';
    private static LOAD_ROUTES_2: string = 'shifts/v1/${shiftId}/planning';
    
    private static LOAD_ROUTES: string = 'shifts/v3/solution/${depot}/${cutoff}';

    private static GET_SOLUTION: string = 'shifts/v2/solution/${shiftId}/simulations/${simulationKey}/${solutionId}';
    private static MANUAL_KEEP_SOLUTION: string = 'shifts/v1/${shiftId}/manualCutoffAndKeepSolutions';
    private static SHIFT_STATS: string = 'shifts/v1/${date}/${warehouse}/stats';
    
    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    private readonly TAG = '[VisualiserService]';

    private routesSource: BehaviorSubject<OptimizeRoutes[]> = new BehaviorSubject<OptimizeRoutes[]>([]);
    public routes: Observable<OptimizeRoutes[]> = this.routesSource.asObservable();

    public calculateShiftResultSource: Subject<OptimizeResult> = new Subject<OptimizeResult>();
    public calculateShiftResult: Observable<OptimizeResult> = this.calculateShiftResultSource.asObservable();

    private routeStrategySource: BehaviorSubject<Array<string> | null> = new BehaviorSubject<Array<string> | null>(null);
    public routeStrategy: Observable<Array<string> | null> = this.routeStrategySource.asObservable();

    private distancesSource: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public distances: Observable<any> = this.distancesSource.asObservable();

    public sampleCustomers = [
        ['52.2580488', '20.8957648', '000602', 'NEW', 'ul. Wacława Gąsiorowskiego, Wars 26c, Warszawa-Bemowo 01-483, PL'],
        ['52.287612', '21.067708', '000163', 'NEW', 'Skrzypcowa 17, Warszawa-Targówek 03-622, PL'],
        ['52.2077619', '21.0258188', '001579', 'NEW', 'ul. Słoneczna 12, Warszawa-Mokotów 00-789, PL'],
        ['52.1444449', '21.0558201', '001263', 'NEW', 'Migdałowa 10, Warszawa-Ursynów 02-796, PL'],
        ['52.2379139', '21.0095469', '000227', 'NEW', 'ul. Kredytowa 8, Warszawa-Śródmieście 00-062, PL'],
        ['52.16004', '21.069985', '000693', 'NEW', 'ul. Ks. Prymasa Hlonda 2C, Warszawa-Wilanów 02-972, PL'],
        ['52.239550', '21.084694', '000680', 'NEW', 'Majdańska 1, Warszawa-Praga 04-088, PL'],
        ['52.23266', '20.986378', '000029', 'NEW', 'Łucka 20, Warszawa-Wola 00-845, PL']
    ];

    constructor(
        private http: HttpClient, 
        private authService: AuthenticationService, 
        private utilsService: UtilsService, 
        private appService: AppService,
        private notifierService: NotifierService,
        private translate: TranslateService
        ) {
        this.loadRouteStrategyDictionary().subscribe();
    }

    private loadRouteStrategyDictionary() {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.ROUTE_STRATEGY_DICTIONARY}`, {});
        return this.http.get(endpoint).pipe(
            map((response: Array<string> | null) => {
                this.routeStrategySource.next(response);
                return response;
            })
        );
    }

    public getRoutePath(routeId: string) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.ROUTE_SEGMENTS}`, { routeId });

        console.log('to remove: ' + endpoint);

        return this.http
            .get(endpoint)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return observableThrowError(err);
                })
            )
            .pipe(
                map(response => {
                    const routePath = new RoutePath().deserialize(response);
                    console.log(this.TAG, routePath);
                    return routePath;
                })
            );
    }

    public getRoutePaths2(year: string, month: string, day: string, shift: string ): Observable<OptimizeResult> {
        const shiftId = RouteId.getShiftId3(year, month, day, shift);
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.LOAD_ROUTES_2}`, { shiftId: shiftId });

        return this.http.get(endpoint)
            .pipe(
                catchError((ex) => {
                    throw ex;
                }),
                map((res) => {
                    return this.generateOptimizeResult(res)
                })
            );
    }

    public getRoutePaths3(depot: string, cutoff: string): Observable<OptimizeResult> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.LOAD_ROUTES}`, { depot, cutoff });

        return this.http.get(endpoint)
            .pipe(
                catchError((ex) => {
                    throw ex;
                }),
                map((res) => {
                    return this.generateOptimizeResult(res)
                })
            );
    }

    public generateOptimizeResult(response: any): OptimizeResult {
        
        let result;

        try {
            result = OptimizeResult.fromJson(response);
        } catch (err) {
            console.error(err);
        }
        console.log(`${this.TAG} OptimizeResult`, result);
        this.distancesSource.next(undefined);
        this.calculateDistances(result);
        return result;
    }

    public getCoordinatesForPastDeliveries(startDate: string, endDate: string, shift?: string, dow?: string): Observable<Array<Array<number>>> {
        let endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.COORDINATES_FOR_PAST_DELIVERIES}`, { startDate, endDate });

        if (dow !== null) {
            endpoint += `&dow=${dow}`;
        }

        if (shift !== null) {
            // endpoint += `&shift=${shift}`;
        } else {
            // endpoint += `&shift=MORNING,AFTERNOON`;
        }

        return this.http.get(endpoint).pipe(
            map(
                (response: any): Array<Array<number>> => {
                    return response;
                }
            )
        );
    }

    public checkAvailableSlots(date: string, c, sampleCustomers) {
        const warehouse: string = localStorage.getItem('depot');
        const endpoints = [];

        _.forEach(sampleCustomers, cust => {
            const customer = _.cloneDeep(c);
            customer['coordinates']['lat'] = cust[0];
            customer['coordinates']['lng'] = cust[1];
            customer['customer']['ref'] = cust[2];
            customer['customer']['status'] = cust[3];
            customer['rawAddress'] = cust[4];
            const customerRef = customer['customer']['ref'];
            const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.CHECK_AVAILABLE_SLOTS}`, { date, warehouse, customerRef });
            endpoints.push(
                this.http
                    .post(endpoint, customer)
                    .pipe(map(res => plainToClass(Slot, res as Slot)))
                    .pipe(map(response => response))
            );
        });

        return forkJoin(...endpoints).pipe(map(responses => responses));
    }

    public getSolution(shiftId: string, simulationKey: string, solutionId: string): Observable<OptimizeResult> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.GET_SOLUTION}`, { shiftId, simulationKey, solutionId });

        return this.http.get<OptimizeRoutes>(endpoint).pipe(
            catchError((error) => {
                throw error;
            }),
            map((a) => this.generateOptimizeResult(a))
        );
    }

    public addLoation(location) {
        if (this.sampleCustomers.length === 10) {
            this.sampleCustomers.shift();
        }
        this.sampleCustomers.push(location);
    }

    public getCoordinatesFromRoute(routeSummary: any) {
        const result: Array<Array<number>> = [];
        _.get(routeSummary, 'activities', []).map((a: Activity) => {
            if (a.type === ActivityType.DELIVERY) {
                result.push([a.location.lat, a.location.lng]);
            }
        });
        return result;
    }

    public calculateTemporaryResults(response: any) {
        const result: OptimizeResult = OptimizeResult.fromJson(response);
        console.log(`${this.TAG} OptimizeResult`, result);
        this.distancesSource.next([]);
        this.calculateShiftResultSource.next(result);
        this.calculateDistances(result);
        return result;
    }

    private async findDepotCoords() {
        const depotCode = localStorage.getItem('depot');
        return this.appService.warehouseDictionary.subscribe((depots: Depot[]) => depots.find(d => d.id.toString() === depotCode));
    }

    public calculateDistances(optimizeResult: OptimizeResult) {
        const routes: any = optimizeResult.getRoutes();
        const depotCode = localStorage.getItem('depot');   
        const depotLocation = this.appService.findWarehouseCoordinates(depotCode);
        const depotCoords = `${depotLocation.lng},${depotLocation.lat}`

        const routesWithDeliveries = _.filter(routes, (r: RouteExt) => r.activities.length).length;

        const distances: {
            routeNumber: number;
            partialDistances: Object;
            totalDistance: number;
            totalDuration: number;
            runtime: number;
            deliveriesCount: number;
        }[] = [];

        console.log(routes);

        // if (!routes.length) {
        //     this.distancesSource.next(_.groupBy(distances, 'runtime'));
        // }

        _.forEach(routes, (route: RouteExt) => {
            let destinations = `${depotCoords};`;

            if (route.travelDistance) {
                distances.push({
                    runtime: route.runtime,
                    routeNumber: route.routeNumber,
                    partialDistances: null,
                    totalDistance: route.travelDistance,
                    totalDuration: route.travelTime,
                    deliveriesCount: route.deliveriesCount
                });

                if (distances.length === routesWithDeliveries) {
                    this.distancesSource.next(_.groupBy(distances, 'runtime'));
                }
                 return;
            }

            _.forEach(route.activities, (activity: Activity, index: number) => {
                if (index === 0 && index !== route.activities.length - 1) {
                    destinations += activity.location.parseCoordinates + ';';
                } else if (index === route.activities.length - 1) {
                    destinations += activity.location.parseCoordinates + ';';
                    destinations += depotCoords;
                } else {
                    if (activity.location !== null) {
                        destinations += activity.location.parseCoordinates + ';';
                    }
                }
            });


            if (route.activities.length) {
                const end2 = this.interpolate(`${VisualiserService.CALCULATE_DISTANCE}`, { destinations });
                this.http.get(end2).subscribe(
                    result => {
                        distances.push({
                            runtime: route['runtime'],
                            routeNumber: route.routeNumber,
                            partialDistances: result,
                            totalDistance: result['routes'][0]['distance'],
                            totalDuration: result['routes'][0]['duration'],
                            deliveriesCount: route.deliveriesCount
                        });
                        route['distance'] = 'dsaas';
                        if (distances.length === routesWithDeliveries) {
                            console.log(`${this.TAG} Calculated distances`, _.groupBy(distances, 'runtime'));
                            this.distancesSource.next(_.groupBy(distances, 'runtime'));
                        }
                    },
                    err => {
                        console.log(console.log(`${this.TAG} Error during calculate distances`, err));
                        distances.push({
                            runtime: route['runtime'],
                            routeNumber: route.routeNumber,
                            partialDistances: null,
                            totalDistance: 0,
                            totalDuration: 0,
                            deliveriesCount: route.deliveriesCount
                        });

                        if (distances.length === routesWithDeliveries) {
                            console.log(`${this.TAG} Calculated distances`, _.groupBy(distances, 'runtime'));
                            this.distancesSource.next(_.groupBy(distances, 'runtime'));
                        }
                    }
                );
            }
        });



        return;
    }

    public findClosestDeliveries(deliveryTarget: Activity, deliveryExt: DeliveryExtInterface[]): Observable<any> {


        const deliveryTargetCoords = `${deliveryTarget.location.parseCoordinates}`
        const tmp = 3;
        const splitCounter: number = Math.floor(deliveryExt.length / tmp);
        const endpoints: any[] = [];
        
        let waypoints = deliveryTargetCoords;

        for (let i = 0; i <= splitCounter; i++) {

            const deliveries = deliveryExt.slice(i * tmp, (i + 1) * tmp);
            waypoints = deliveryTargetCoords;

            _.forEach(deliveries, (dExt: DeliveryExtInterface) => {
                if (deliveryTarget.id !== dExt.activity.id) {
                    waypoints += `;${dExt.activity.location.parseCoordinates};${deliveryTargetCoords}`;
                }
            });

            if (deliveries.length > 1) {
                endpoints.push(this.http.get(this.interpolate(`${VisualiserService.CALCULATE_DISTANCE}?overview=full`, { destinations: waypoints })).pipe(map(res => res)));
            } else {
                endpoints.push(this.http.get(this.interpolate(`${VisualiserService.CALCULATE_DISTANCE}?overview=full`, { destinations: `${waypoints};${deliveryTargetCoords};${deliveryTargetCoords}` })).pipe(map(res => res)));
            }
        }


    
        return forkJoin(endpoints).pipe(
            catchError((err) => {
                this.notifierService.notify('warning', this.translate.instant('Cannot calculate distances, please try again!'));
                throw err;
            }),
            map(
                (results) => {
                    const routes = [];
                
                    _.forEach(_.values(results), (result: any, i: number) => routes.push(...(result['routes'][0]['legs'])));
                    _.forEach(deliveryExt, (dExt: DeliveryExtInterface, i: number) => dExt.activity['waypoint'] = routes[i * 2]);

                    const sortedResults = _.sortBy(deliveryExt, (d: DeliveryExtInterface) => d.activity['waypoint']['distance']);
                    console.log(sortedResults);
                    return sortedResults;
                }
            )
        );
    }

    public recalculate(shiftId: String, mustFit: Boolean): any {
        
        const endpoint = (mustFit) 
            ? this.interpolate(`${this.host}${this.prefix}/${VisualiserService.GENERATE_SOLUTIONS}`, { shiftId, mustFit })
            : this.interpolate(`${this.host}${this.prefix}/${VisualiserService.OPTIMIZE_ROUTES}`, { shiftId, mustFit })
    
        
        return this.http.post(endpoint, null).pipe(
            catchError(error => {
                return observableThrowError(error);
            })
        );
    }

    public shiftStatusChange(shiftId: String): any {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.SHIFT_STATUS_CHANGE}`, { shiftId });
        return this.http.get(endpoint).pipe(
            catchError(error => {
                return observableThrowError(error);
            }),
            map((response) => plainToClass(FinderEvent, response as FinderEvent[])),
            map((events: FinderEvent[]) => _.sortBy(events, (o: FinderEvent) => o.occurredAt))
        );
    }

    public getShiftStats(date: string, depot: string): Observable<ShiftSummaryStats[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.SHIFT_STATS}`, { date, warehouse: depot });
        return this.http.get<ShiftSummaryStats[]>(endpoint);
    }

    public reflow(routeId: string): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.REFLOW}`, { routeId });
        return this.http.put(endpoint, null).pipe(map(response => response));
    }

    public replan(routeId: string): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.REPLAN}`, { routeId });
        return this.http.put(endpoint, null).pipe(map(response => response));
    }

    public finalize(shiftId: String): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.FINALIZE}`, { shiftId });
        return this.http.post(endpoint, null);
    }

    public closeShift(shiftId: String): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.CLOSE_SHIFT}`, { shiftId });
        return this.http.post(endpoint, null);
    }

    public manualAndKeepSolution(shiftId: String): Observable<any> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${VisualiserService.MANUAL_KEEP_SOLUTION}`, { shiftId });
        return this.http.put(endpoint, null);
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
