import * as _ from 'lodash';

import { CustomerType } from '@enums/enum';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class Customer extends Entity implements Serializable<Customer> {
    public id: string;
    public firstName: string;
    public lastName: string;
    public locations: any[];
    public phoneNr: string;
    public ref: string;
    public status: string;
    public notes: string;

    public createdAt: string;
    public modifiedAt: string;

    get label() {
        const label = `${this.lastName} ${this.firstName}`;

        if (label.length > 1) {
            return label;
        }

        return 'Doe John';
    }

    get labelLong() {
        return `${this.label} | ${this.ref}`;
    }

    public deserialize(data: any) {
        this.id = _.get(data, 'id');
        this.firstName = _.get(data, 'firstName', '').trim();
        this.lastName = _.get(data, 'lastName', '').trim();
        this.locations = _.get(data, 'locations', []);
        this.phoneNr = _.get(data, 'phoneNr');
        this.notes = _.get(data, 'notes');

        this.ref = _.get(data, 'ref', '-');
        this.status = _.get(data, 'status', '');
        this.modifiedAt = _.get(data, 'modifiedAt');
        this.createdAt = _.get(data, 'createdAt');

        return this;
    }

    public serialize() {
        return {};
    }

    public get isVipCustomer(): boolean {
        return this.status === CustomerType.VIP;
    }

    public get isNewCustomer(): boolean {
        return this.status === CustomerType.NEW;
    }
}
