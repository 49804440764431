import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { User } from '@entities/user';
import { ShiftStatus } from '@enums/enum';
import { ShiftsService } from '@services/shifts.service';

import { AuthenticationService } from '../authentication.service';
import { DayComposite } from './entities/dayComposite';
import { ShiftConfig } from './entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { DailyShiftConfig } from './entities/dailyShiftConfig';
import { ShiftExt } from './interafces/shift-ext.interface';

@Component({
    selector: 'day-details',
    template: `
        <ng-container *ngIf="dayComposite">
            <div class="shifts-container" *ngIf="dayComposite.dailyShiftConfig">
                <div class="shift" *ngFor="let shiftConfig of dayComposite.dailyShiftConfig.shifts; let shiftIndex = index">
                    <div *ngIf="selectedShift == shiftConfig.fulfilment.shiftId.type">
                        <h2>{{dayComposite.date | amLocale: locale | amDateFormat:'dddd, MMMM DD'}} - {{selectedShift | translate}}</h2>

                        <div class="shift-details time-to-close-shift" *ngIf="!isConfiguring(shiftConfig.status) && timeToClose[shiftIndex]"
                             title="{{timeToClose[shiftIndex].title}}">{{timeToClose[shiftIndex].label}}</div>
                        <div class="shift-details time-to-close-shift" *ngIf="isConfiguring(shiftConfig.status) && timeToClose[shiftIndex]" title="{{timeToClose[shiftIndex].title}}">
                            {{'Shift was closed manually' | translate}}
                        </div>

                        <status-switcher 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></status-switcher>

                        <cutoff-details 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.shiftCutoffData"
                            [shiftId]="shiftConfig.integration.shiftId"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></cutoff-details>

                        <!--<deliveries-details 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment" 
                            [productLines]="shiftConfig.productLines"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            [skuCount]="shiftConfig.skuCount"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></deliveries-details>-->

                        <vans-details 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></vans-details>

                        <shift-edges 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-edges>

                        <shift-fulfilment
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_view]"
                            [shiftConfig]="shiftConfig.fulfilment"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-fulfilment>

                        <shift-integration
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_integration_view]"
                            [shiftConfig]="shiftConfig.integration"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-integration>

                        <shift-routing
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_routing_view]"
                            [shiftConfig]="shiftConfig.routing"
                            [shiftStatusData]="shiftConfig.shiftStatusData"
                            (shiftConfigChanged)="onShiftConfigChanged(shiftIndex, $event)"></shift-routing>
                        
                    </div>
                </div>
            </div>
        </ng-container>      `,
    styleUrls: [`./dayDetails.component.scss`]
})
export class DayDetailsComponent implements OnInit {

    @Input() public calculateTempResult: boolean = false;
    @Input() public dayComposite: any = null;
    @Input() public selectedShift: string;
    @Output() public dayCompositeChanged: EventEmitter<DayComposite> = new EventEmitter();

    private readonly tag = '[DayDetailsComponent]';

    public timeToClose: { label: string, title: string }[] = [];
    public locale = '';
    public user: User;
    public Permissions = Permissions;

    constructor(
        private shifts: ShiftsService, 
        private translate: TranslateService, 
        private authService: AuthenticationService) {}

    public ngOnInit() {
        this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.authService.currentUser.subscribe((user: User) => this.user = user);

        setInterval(() => {
            const now = moment();

            if (this.dayComposite.hasOwnProperty('dailyShiftConfig')) {
    
                _.forEach(this.dayComposite.dailyShiftConfig.shifts, (shift: ShiftExt, index: number) => {
                    const cutoff = moment(`${this.dayComposite.dateRaw} ${shift.integration.time}`, 'YYYY-MM-DD HH:mm');
                    cutoff.add(shift.integration.offset, 'days');
    
                    if (moment().isBefore(cutoff)) {
                        this.timeToClose[index] = {
                            label: `${this.translate.instant('Shift will be closed')} ${now.to(cutoff)}`,
                            title: `${cutoff.format('YYYY-MM-DD HH:mm')}`
                        };
                    } else {
                        this.timeToClose[index] = {
                            label: `${this.translate.instant('Shift was closed')} ${now.to(cutoff)}`,
                            title: `${cutoff.format('YYYY-MM-DD HH:mm')}`
                        };
                    }
                });
            }
        }, 1000);
    }

    public onShiftConfigChanged(index: number, shiftConfig: ShiftConfig) {
        this.dayComposite.dailyShiftConfig.shifts[index] = DailyShiftConfig.fromShiftSettings(shiftConfig);
        this.dayCompositeChanged.next(this.dayComposite);
    }

    public isConfiguring(status: ShiftStatus): boolean {
        return status === ShiftStatus.CONFIGURING;
    }
}
