import { serializeType } from '@shared/functions/serializeType.function';
import { ConfigKey } from './config-key';
import { Type } from 'class-transformer/decorators';

export class ConfigValue {
    
    @Type(serializeType(ConfigKey))
    public configKey: ConfigKey;
    
    public id: string;
    public partnerId: string;
    public regionId: string;
    public configKeyId: string;
    public scope: number;
    public value: string;
    public version: number;
    public isDefault: boolean;
}
