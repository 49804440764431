import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'totalDistancePipe',
    pure: true
})
export class TotalDistancePipe implements PipeTransform {
    public transform(distances: Array<any>): string {
        if (distances.length) {
            return `${(_.sumBy(distances, r => r.totalDistance) / 1000).toFixed(2)} km`;
        }
    }
}
