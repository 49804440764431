import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '@services/utils.service';
import { environment } from '@environment';
import { AuditLog } from '@interfaces/audit-log';
import { LocalStorageService } from './local-storage.service';
import { PageableResponse } from '@entities/pagable-response';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
    providedIn: 'root'
})
export class AuditLogService {

    private static DELIVERY_LOG: string = 'eventlog/v1/delivery/${deliveryId}';
    private static ROUTE_LOG: string = 'eventlog/v1/route/${routeId}';
    private static SHIFT_LOG: string = 'eventlog/v1/shift/${shiftId}';
    private static SHIFTPLANNING_LOG: string = 'eventlog/v1/shiftplanning/${depotId}/${shiftType}';
    private static BOX_BAG: string = 'eventlog/v1/boxbag/${boxbagId}/list?size=1000';


    constructor(
        private readonly http: HttpClient,
        private readonly utilsService: UtilsService,
        private readonly localStorageService: LocalStorageService
    ) { }

    public getDeliveryLogs(deliveryId: string): Observable<AuditLog[]> {
        const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${AuditLogService.DELIVERY_LOG}`, { deliveryId });
        return this.http.get<AuditLog[]>(endpoint);
    }

    public getRouteLogs(routeId: string): Observable<AuditLog[]> {
        const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${AuditLogService.ROUTE_LOG}`, { routeId });
        return this.http.get<AuditLog[]>(endpoint);
    }

    public getShiftLogs(shiftId: string): Observable<AuditLog[]> {
        const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${AuditLogService.SHIFT_LOG}`, { shiftId });
        return this.http.get<AuditLog[]>(endpoint);
    }

    public getShiftPlanningLogs(shiftType: string): Observable<AuditLog[]> {
        const depotId = this.localStorageService.getDepot();
        const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${AuditLogService.SHIFTPLANNING_LOG}`, { depotId, shiftType });
        return this.http.get<AuditLog[]>(endpoint);
    }

    public getBoxBaggLogs(id: string): Observable<any> {
        const depotId = this.localStorageService.getDepot();
        const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${AuditLogService.BOX_BAG}`, { boxbagId: id });
        return this.http.get<any>(endpoint).pipe(map((results: PageableResponse<AuditLog>) => results.content));
    }
}
