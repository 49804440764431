<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="activity"></i-feather> {{'Licensing' | translate}}
            </h4>
        </div>
        <div toolbar>
            <ng-container>
                <div class="d-flex justify-content-between align-items-baseline flex-grow-1">
                    <div class="flex-fill flex-grow-1">
                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Date
                            picker' | translate}}:</label>
                        <div class="d-flex"><date-range-picker [extendedOption]="false"
                                (changedDateRange)="changedDateRange($event)"></date-range-picker>

                            <button id="visualiser-btn-load-data"
                                class="menu__button button btn btn-white btn-sm btn-uppercase mg-l-10 flex-grow-1 mg-r-10"
                                (click)="load()">{{'Load Data' | translate}}</button>

                        </div>
                    </div>

                    <div class="flex-fill flex-grow-1">
                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Select
                            visualiser mode' | translate}}:</label>
                        <ul class="nav nav-tabs nav-justified">
                            <li class="pointer nav-item tx-11">
                                <a (click)="changeMode('deliveries')" [ngClass]="{'active': mode === 'deliveries'}"
                                    class="nav-link tx-uppercase">{{'Deliveries' | translate}}</a>
                            </li>
                            <li appCheckClient [client]="inpostClient" class="pointer nav-item tx-11">
                                <a (click)="changeMode('apm-deliveries')"
                                    [ngClass]="{'active': mode === 'apm-deliveries'}"
                                    class="nav-link tx-uppercase">{{'Apm deliveries' | translate}}</a>
                            </li>
                            <li class="pointer nav-item tx-11">
                                <a (click)="changeMode('hardware')" [ngClass]="{'active': mode === 'hardware'}"
                                    class="nav-link tx-uppercase">{{'Hardware' | translate}}</a>
                            </li>
                            <li class="pointer nav-item tx-11">
                                <a (click)="changeMode('users')" [ngClass]="{'active': mode === 'users'}"
                                    class="nav-link tx-uppercase">{{'Users' | translate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </div>
        <hr />
    </div>

    <div content>
        <section [@routeAnimationsLicensing]="prepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </section>
    </div>
</layout-default>