import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutesPlanningParams } from '../../interfaces/routes-planning-params.interface';
import { OptimizeResult } from '../../entities/optimize-result';
import { Observable, Subscription } from 'rxjs';
import { VisualiserService } from '../../services/visualiser.service';
import { tap, catchError } from 'rxjs/operators';
import { UtilsService } from '@services/utils.service';
import { DeliveryManagementSettings } from '../../interfaces/delivery-management-settings.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-visualiser-routes-planning',
  templateUrl: './visualiser-routes-planning.component.html',
  styleUrls: ['./visualiser-routes-planning.component.scss']
})
export class VisualiserRoutesPlanningComponent implements OnInit, OnDestroy {

  @Input() public deliveryManagementSettings: DeliveryManagementSettings;
  
  private params: RoutesPlanningParams;
  public routesPlanningResults$: Observable<OptimizeResult>;
  public distances$: Observable<any>;
  private activatedRoute$: Subscription;

  public loader = true;
  public noRoutes = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly visualiserService: VisualiserService,
    private readonly utilsService: UtilsService,
  ) { }


  public ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      console.log(params);
      this.params = { 
        y: params.y ? params.y : this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'y'),
        m: params.m ? params.m : this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'm'),
        d: params.d ? params.d : this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'd'),
        shiftId: params.shiftId ? params.shiftId : this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'shiftId'),
        depot: params.depot ? params.depot : this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'depot'),
        cutoff: params.cutoff ? params.cutoff : this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'cutoff'),

      } as RoutesPlanningParams;

      this.loadRoutesPlanning();
    });
  }


  public loadRoutesPlanning() {
    this.loader = true;
    this.noRoutes = false;

    this.routesPlanningResults$ = 
      this.visualiserService.getRoutePaths2(this.params.y, this.params.m, this.params.d, this.params.shiftId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.noRoutes = error.status === 404 ? true : false;
          this.loader = false;
          throw error;
        }),
        tap((optimizeResult: OptimizeResult) => {
          this.noRoutes = (!optimizeResult.routes[0].routes.length) ? true : false;
          this.loader = (!optimizeResult.routes[0].routes.length) ? false : true;
        })
      )
     
    this.distances$ = this.visualiserService.distances.pipe(
      tap(a => (a) ? this.loader = false : null)
    );
  }

  public reloadPlanningChanged() {
    this.loadRoutesPlanning();
  }

  public ngOnDestroy(): void {
    if (this.activatedRoute$) {
      this.activatedRoute$.unsubscribe();
    }
  }
  

}
