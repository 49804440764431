import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeliveryManagementSettings } from '../../interfaces/delivery-management-settings.interface';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
  selector: 'app-visualiser-crazy-deliveries',
  templateUrl: './visualiser-crazy-deliveries.component.html',
  styleUrls: ['./visualiser-crazy-deliveries.component.scss']
})
export class VisualiserCrazyDeliveriesComponent implements OnInit, OnDestroy {

  private static DRIVING_TIME: number = 30;
  private static DELIVERIES_COUNT: number = 10;
  private static TIME_PRECISION: number = 31;
  private static DRIVING_TO: number = 70;

  public form: FormGroup;
  public deliveryManagementSettings: DeliveryManagementSettings;
  private routerEvents$: Subscription;
  
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this.initComponentData();

    this.routerEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(a => this.initComponentData())
  }

  public initComponentData() {
    this.formInit();
  }

  public formInit(): void {
    this.form = this.formBuilder.group({
        drivingTime: [VisualiserCrazyDeliveriesComponent.DRIVING_TIME, Validators.required],
        deliveriesCount: [VisualiserCrazyDeliveriesComponent.DELIVERIES_COUNT, Validators.required],
        timePrecision: [VisualiserCrazyDeliveriesComponent.TIME_PRECISION, Validators.required],
        drivingToPercent: [VisualiserCrazyDeliveriesComponent.DRIVING_TO, Validators.required],
    });
  }

  public enableDeliveryManagementMode() {
      const raw = this.form.getRawValue() as DeliveryManagementSettings;
      raw.drivingTime = raw.drivingTime * 60;
      this.deliveryManagementSettings = raw;
  }

  public ngOnDestroy() {
    if (this.routerEvents$) {
      this.routerEvents$.unsubscribe();
    }
  }
}
