<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="database"></i-feather> {{'Orders history' | translate}}</h4>
        </div>
        <br>
        <div toolbar>
            <date-range-picker
                [extendedOption]="true"
                (changeDow)="changedDow($event)"
                (changedDateRange)="changedDateRange($event)"
                (changedShiftType)="changedShiftType($event)"
                (mapType)="changedMapType($event)">
            </date-range-picker>
        </div>
        <hr />
    </div>

    <div content class="bg-white">
            <gmap *ngIf="coordinates.length && !loader" [locations]="coordinates" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>

            <div *ngIf="loader" class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
            </div>

            <div *ngIf="!coordinates.length && !loader" class="load-spinner text-center">
                {{'No data to display' | translate}}!
            </div>
    </div>
</layout-default>
