
        <layout-default>
            <div header>
                <div toolbar>
                    <h4 *ngIf="mode == 'edit' || mode == 'show'">
                        <i-feather name="truck"></i-feather> {{ 'Vans' | translate }}: {{ van.label }}
                    </h4>
                    <h4 *ngIf="mode == 'create'">
                        <i-feather name="plus-square"></i-feather> {{ 'Add van' | translate }}
                    </h4>
                </div>
                <div toolbar>
                    <button id="fleet-edit-btn-cancel" class="btn btn-sm btn-uppercase btn-white" *ngIf="mode == 'edit'" [routerLink]="['/fleet']">{{ 'Cancel' | translate }}</button>

                    <button id="fleet-edit-btn-cancel" class="btn btn-sm btn-uppercase btn-white" *ngIf="mode == 'create'" [routerLink]="['/fleet']">{{ 'Cancel' | translate }}</button>

                    <button id="fleet-edit-btn-edit" *ngIf="mode == 'show'" class="btn btn-sm btn-uppercase btn-white" [routerLink]="['/fleet/' + this.van.id + '/edit']">{{ 'Edit' | translate }} </button>
                </div>
                <hr />
            </div>
            <div content class="bg-white">
                <div *ngIf="van" class="content">
                    <div class="card-content col-8 offset-2">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div id="flee-editt-form-owner" class="form-group row">
                                <label class="col-2" for="owner">{{ 'Owner'.toUpperCase() | translate }}:</label>
                                <div class="col-10">
                                    <select *ngIf="(hardwareOwner$ | async) as hardwareOwner" class="custom-select" formControlName="owner">
                                        <option *ngFor="let owner of hardwareOwner" [value]="owner">{{ owner | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('owner').hasError('required') && form.get('owner').touched" class="error-color">{{ 'Owner field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-registration" class="form-group row">
                                <label class="col-2" for="registration">{{ 'Registration' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="registration" class="form-control" />
                                    <div *ngIf="form.get('registration').hasError('required') && form.get('registration').touched" class="error-color">
                                        {{ 'Registration number is required' | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-brand" class="form-group row">
                                <label class="col-2" for="brand">{{ 'Brand' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="brand" class="form-control" />
                                    <div *ngIf="form.get('brand').hasError('required') && form.get('brand').touched" class="error-color">{{ 'Brand is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-vehicleTypeId" class="form-group row">
                                <label class="col-2" for="vehicleTypeId">{{ 'Vehicle type' | translate }}:</label>
                                <div class="col-10">
                                    <ng-container *ngIf="(vehicleTypes$ | async) as vehicleTypes">
                                        <select class="custom-select" formControlName="vehicleTypeId">
                                            <option *ngFor="let type of vehicleTypes" [value]="type.id">{{ type.code }}</option>
                                        </select>
                                    </ng-container>
                                    <div *ngIf="form.get('vehicleTypeId').hasError('required') && form.get('vehicleTypeId').touched" class="error-color">{{ 'Depot field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-status" class="form-group row">
                                <label class="col-2" for="status">{{ 'Status' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="status">
                                        <option *ngFor="let status of VanStatusOptions" [value]="status">{{ status | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Status field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div appCheckClient [client]="friscoClient" id="fleet-edit-form-status" class="form-group row">
                                <label class="col-2" for="status">{{ 'Check vehicle with SOFTRA' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="scanForDamage">
                                        <option [ngValue]="true">{{ 'Yes' | translate }}</option>
                                        <option [value]="false">{{ 'No' | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Status field is required' | translate }}!</div>
                                </div>
                            </div>
                            
                            
                            <ng-container formGroupName="properties">
                                <div id="fleet-edit-form-fuelCardNr" class="form-group row">
                                    <label class="col-2" for="fuelCardNr">{{ 'Fuel card number' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="fuelCardNr" class="form-control" />
                                    </div>
                                </div>
                                <div id="fleet-edit-form-fuelCardPin" class="form-group row">
                                    <label class="col-2" for="fuelCardPin">{{ 'Fuel card pin' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="fuelCardPin" class="form-control" />
                                    </div>
                                </div>
                            </ng-container>

                            <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                                <div class="col-10 offset-2">
                                    <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }} :</p>
                                    <ul>
                                        <li *ngFor="let error of formErrorsMessages" class="error-color">
                                            <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                            <strong>{{ error.field | translate }}</strong>
                                            <span>{{ error.value | translate }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group row justify-content-end">
                                <div class="col-2">
                                    <button id="fleet-edit-form-btn-submit" [disabled]="!form.valid || submitted || disabled" class="btn btn-sm btn-uppercase btn-brand-01 w-100">
                                        <span *ngIf="!submitted">{{ 'Save' | translate }}</span>

                                        <ng-container *ngIf="submitted">
                                            <div class="load-spinner text-center">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </layout-default>

        <ng-template #removeVanConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>
                    {{ 'Do you want to remove van:' | translate }} <strong>{{ van.label }}</strong
                    >?
                </p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate }}
                </button>
            </div>
        </ng-template>
    