import { Type } from 'class-transformer';

import { serializeType } from '@shared/functions/serializeType.function';

import { ShiftId } from './shiftid';

export class SimulationShiftConfigDto {
    
    public fleetSize: number;
    public ordersLimit: number;
    public shiftStartMarginMinutes: number;
    public slotEndMarginMinutes: number;
    public slotStartMarginMinutes: number;
    public recalculateFrequency: number;

    @Type(serializeType(ShiftId))
    public shiftId: ShiftId | string;


    get date(): string {
        return (typeof this.shiftId === 'string') ? this.shiftId.split(':')[0] : null;
    }

    get shift(): string {
        return (typeof this.shiftId === 'string') ? this.shiftId.split(':')[2] : null;
    }

    get warehouse(): string {
        return (typeof this.shiftId === 'string') ? this.shiftId.split(':')[1] : null;
    }
}
