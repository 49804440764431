<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="columns"></i-feather> {{'Sales dashboard' | translate}}
            </h4>
        </div>
        <div *ngIf="(depots$ | async)" toolbar>

            <date-range-picker 
                class="flex-grow-1 pic-apm-maint-slot" 
                [extendedOption]="false"
                (changedDateRange)="changedDateRange($event)" 
                [defaultDateRange]="dateRange"></date-range-picker>

            <div class="label">
                <label>{{ 'Aggregation' | translate }}:</label>
            </div>

            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.aggregation" (ngModelChange)="aggregationChanged($event)">
                    <option *ngFor="let value of aggregationValues" [ngValue]="value">{{'Delivery_sales.charts.comparison.' + value | translate}}</option>
                </select>
            </div> 

            <div class="label">
                <label>{{ 'Cumulative' | translate }}:</label>
            </div>
            
            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.cumulative">
                    <option [value]="true">{{'true' | translate}}</option>
                    <option [value]="false">{{'false' | translate}}</option>
                </select>
            </div> 

            <div class="label">
                <label>{{ 'Comparison' | translate }}:</label>
            </div>
            
            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.comparison" [disabled]="disabledField.comparison">
                    <option *ngFor="let value of comparisonValues" [ngValue]="value">{{'Delivery_sales.charts.comparison.' + value | translate}}</option>
                </select>
            </div> 

            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.comparisonCount">
                    <option *ngFor="let i of [0, 1,2,3,4,5,6,7,8,9,10,11,12]" [value]="i">{{i}}</option>
                </select>
            </div> 

            <button class="btn btn-brand-01 mg-l-15" target="_blank" (click)="loadData();">
                <i class="fas fa-download"></i> {{'Load data' | translate}}
            </button>

        </div>
    </div>

    <div content>

        <ng-container *ngIf="(dataSales$ | async) as data_v2 else loaderTmpl">
            <ng-container *ngFor="let data of data_v2">

                <div class="chart-container">
                    <div class="card bg-white">
                        <div class="chart">

                                <div class="inside">
                                    <h3 class="text-center">{{'Delivery_sales.charts.labels.' + data[0].category.toLowerCase() + '.title'  | translate}}</h3>
                                    <ngx-charts-line-chart
                                        [style.width.%]="100"
                                        
                                        [scheme]="chartSettings.colorScheme"
                                        [legend]="chartSettings.legend"
                                        [showXAxisLabel]="chartSettings.showXAxisLabel"
                                        [showYAxisLabel]="chartSettings.showYAxisLabel"
                                        [xAxis]="chartSettings.xAxis"
                                        [yAxis]="chartSettings.yAxis"
                                        [xAxisLabel]="'Day of month' | translate"
                                        [yAxisLabel]="showYAxisLabel[data[0].category]"
                                        [timeline]="chartSettings.timeline"
                                        [results]="data">
                                    
                                        <ng-template #seriesTooltipTemplate let-model="model">
                                            <ul>
                                                <li *ngFor="let el of model; let index = index">
                                                    <span [style.background-color]="el.color"></span>
                                                    <div class="pd-r-5">{{el.name}} 
                                                        <ng-container [ngSwitch]="queryParams.aggregation">
                                                            <ng-container *ngSwitchCase="dashboardSalesAggregation.Days">
                                                                {{ el.month }}
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="dashboardSalesAggregation.Months">
                                                                {{ el.year }}
                                                            </ng-container>
                                                        </ng-container>- </div>
                                                    <div class="pd-r-5">{{'Value' | translate}}:  <strong>{{el.value | number}}</strong></div>
                                                </li>
                                            </ul>
                                        </ng-template>

                                        <ng-template #tooltipTemplate let-model="model">
                                            <div class="pd-10">
                                                <div class="pd-r-5">{{model.name}} 
                                                    <ng-container [ngSwitch]="queryParams.aggregation">
                                                        <ng-container *ngSwitchCase="dashboardSalesAggregation.Days">
                                                            {{ model.month }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="dashboardSalesAggregation.Months">
                                                            {{ model.year }}
                                                        </ng-container>
                                                    </ng-container>- </div>
                                                <div class="pd-r-5">{{'Value' | translate}}:  <strong>{{model.value | number}}</strong></div>
                                            </div>
                                        </ng-template>
                                    </ngx-charts-line-chart>
                                </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #loaderTmpl>
            <div class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
            </div>
        </ng-template>
    </div>
</layout-default>


<!-- {{'Delivery_sales.charts.labels.delivery.title' | translate}} -->
<!-- {{'Delivery_sales.charts.labels.box.title' | translate}} -->
<!-- {{'Delivery_sales.charts.labels.sku.title' | translate}} -->
<!-- {{'Delivery_sales.charts.labels.price.title' | translate}} -->
<!-- {{'Delivery_sales.charts.labels.margin.title' | translate}} -->
<!-- {{'Delivery_sales.charts.labels.cart.title' | translate}} -->

<!-- {{'Delivery_sales.charts.comparison.DAYS' | translate}} -->
<!-- {{'Delivery_sales.charts.comparison.MONTHS' | translate}} -->
<!-- {{'Delivery_sales.charts.comparison.WEEKS' | translate}} -->
<!-- {{'Delivery_sales.charts.comparison.YEARS' | translate}} -->