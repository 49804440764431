import { Client, ORDER } from '@enums/enum';

export const environment = {
  production: true,
  stage: 'production',
  api: {
    url: 'https://frisco-api.open-routing.com',
    prefix: "/api"
  },
  assetsUrl: '/assets',
  envName: 'production',
  client: Client.FRISCO,
  localMenu: false,
  bugsnagApiKey: 'a7548fe8bfba2ad891fc9c3eeabf8ef6',
  googleMapApiKey: 'AIzaSyDvbQlU9HFiLjGEphsqu7GVPC4pByagwxg',
  publicFilesBucketUrl: 'https://prd-frisco-public-files.s3.eu-west-1.amazonaws.com',
  pagination: {
    page: 0, // how many pages is displayed at once
    size: 30, // how many items on a page
    sort: 'createdAt',
    order: ORDER.DESC, 
  }
};
