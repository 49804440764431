import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormArray } from '@angular/forms/forms';
import { ShiftCutoffData } from '@calendar/interafces/shift-cutoff-data.interface';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { ShiftSettingsService } from '@calendar/services/shift-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap';
import { mergeMap, take } from 'rxjs/operators';

@Component({
    selector: 'app-calendar-overview-shift-update-dialog',
    templateUrl: './calendar-overview-shift-update-dialog.component.html',
    styleUrls: ['./calendar-overview-shift-update-dialog.component.scss'],
})
export class CalendarOverviewShiftUpdateDialogComponent implements OnInit {
    shift: ShiftExt;
    cutoffid: number;
    limitsForm!: FormGroup;
    displayError: string;

    constructor(public readonly bsModalRef: BsModalRef, private readonly shiftSettingsService: ShiftSettingsService, private readonly notifierService: NotifierService, private readonly translate: TranslateService, private readonly fb: FormBuilder) {}

    get vanLimits(): FormArray {
        return this.limitsForm.get('vanLimits') as FormArray;
    }

    ngOnInit() {
        this.limitsForm = this.fb.group({
            vanLimits: this.fb.array([]),
            orderLimits: [0],
            orderToggle: [false],
            maximumRoutes: [0],
            maximumRoutesToggle: [false],
        });
        this.loadInitialValues();
    }

    addVanLimit(vanLimit?: ShiftFleet): void {
        const vanLimitGroup = this.fb.group({
            vehicleTypeId: [{ value: vanLimit ? vanLimit.vehicleTypeId : '', disabled: true }, Validators.required],
            max: [vanLimit ? vanLimit.max : null, [Validators.required, Validators.min(0)]],
        });
        this.vanLimits.push(vanLimitGroup);
    }

    loadInitialValues() {
        const cutoff: ShiftCutoffData = this.shift.shiftCutoffData.find((c) => c.id === this.cutoffid);
        const initialData = {
            vanLimits: [],
            orderLimits: cutoff.maximumDeliveries,
            orderToggle: cutoff.maximumDeliveries !== -1 ? false : true,
            maximumRoutes: cutoff.maximumRoutes,
            maximumRoutesToggle: cutoff.maximumRoutes !== -1 ? false : true,
        };

        this.limitsForm.setValue(initialData);

        if (this.shift.fulfilment.fleet.length > 0) {
            this.shift.fulfilment.fleet.forEach((vanLimit) => this.addVanLimit(vanLimit));
        }
    }

    toggleField(toggleFieldName: string, targetFieldName: string): void {
        const toggleValue = this.limitsForm.get(toggleFieldName).value;
        const targetField = this.limitsForm.get(targetFieldName);

        if (toggleValue) {
          targetField.setValue(-1);
          targetField.disable();
        } else {
          targetField.enable();
          targetField.setValue(1); 
        }
    }

    onSubmit() {
        if (this.limitsForm.valid) {
            this.displayError = '';
            const formData = this.limitsForm.getRawValue();
            this.shift.fulfilment.fleet = formData.vanLimits;

            const updatedCutoffData = this.updateShiftCutoffData(
              this.shift.shiftCutoffData, 
              { maximumDeliveries: formData.orderLimits, maximumRoutes: formData.maximumRoutes}, 
              this.cutoffid
            );

            this.shiftSettingsService
                .updateFulfilment(this.shift.fulfilment)
                .pipe(
                    mergeMap(() => this.shiftSettingsService.updateCutoffs(this.shift.fulfilment.shiftId, updatedCutoffData)),
                    take(1)
                )
                .subscribe(
                    () => {
                        this.bsModalRef.hide();
                        this.notifierService.notify('success', this.translate.instant('Shift settings has been changed successfully!'));
                    },
                    (error) => {
                        if (error.error.message) {
                            this.displayError = error.error.message;
                        }
                    }
                );
        }
    }

    updateShiftCutoffData(dataArray: ShiftCutoffData[], newValues: Partial<ShiftCutoffData>, id: number): ShiftCutoffData[] {
        return dataArray.map((item) => {
            if (item.id === id) {
                return { ...item, ...newValues };
            }
            return item;
        });
    }
}
