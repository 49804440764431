import * as _ from 'lodash';

import { RouteId } from '@entities/routeId';
import { DeliveryStatus } from '@enums/enum';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class DeliverySearchResult extends Entity implements Serializable<DeliverySearchResult> {
    public addressLabel: string;
    public customerLabel: string;
    public customerRef: string;
    public deliveryId: string;
    public orderNo: string;
    public orderId: string;
    public deliveryLabel: string;
    public deliveryStatus: DeliveryStatus;
    public rId: number;
    public routeId: RouteId = null;

    public deserialize(data: any) {
        this.addressLabel = _.get(data, 'addressLabel');
        this.customerLabel = _.get(data, 'customerLabel');
        this.customerRef = _.get(data, 'customerRef');
        this.deliveryId = _.get(data, 'deliveryId');
        this.orderId = _.get(data, 'orderId');
        this.deliveryLabel = _.get(data, 'deliveryLabel');
        this.deliveryStatus = _.get(data, 'deliveryStatus');
        this.rId = _.get(data, 'rId', 0);

        const [label, customerRef, orderNo] = this.deliveryId.split(':');

        this.orderNo = orderNo;
        this.routeId = RouteId.parse(_.get(data, 'routeId'));

        return this;
    }
}
