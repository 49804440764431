import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '@environment';
import { UtilsService } from './utils.service';
import { ShiftType } from '@enums/enum';

import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService implements OnDestroy {

    private static SHIFTS_TYPES: string = 'dictionary/v1/ShiftType';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    private dictionarySubscription: Subscription;

    public shiftTypesDictionary: Array<string> = _.keys(ShiftType);

    constructor(
        private http: HttpClient, 
        private utilsService: UtilsService)
    {
        //this.loadShiftTypes()
    }


    public loadShiftTypes() {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${DictionaryService.SHIFTS_TYPES}`, {});

        return this.http.get(endpoint).pipe(

            map((shiftTypes: Array<string>) => {
                this.shiftTypesDictionary = shiftTypes.filter((s) => s !== ShiftType.MORNING);
                return this.shiftTypesDictionary;
            }),
            publishReplay(1),
            refCount()
           
        ).subscribe();
    }

    public ngOnDestroy() {
        if (this.dictionarySubscription) {
            this.dictionarySubscription.unsubscribe();
        }
    }
}
