import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { DriverStatus, ShiftType, DateRange, DriversMode } from '@enums/enum';

import { AvailabilityRange, DriverAvailability } from '../entities/availability-range';
import { Driver } from '../entities/driver';
import { DriversService } from '../services/drivers.service';
import { AppService } from '@services/app.service';
import { LocalStorageService } from '@services/local-storage.service';

@Component({
    selector: 'app-print-drivers-schedule',
    templateUrl: './print-drivers-schedule.component.html',
    styleUrls: [`./print-drivers-schedule.component.scss`]
})
export class PrintDriversScheduleComponent implements OnInit, OnDestroy {
    private routingSubscription: Subscription;

    public driversAvailability: AvailabilityRange = null;
    public startDate: string;
    public endDate: string;
    public drivers: Driver[] = [];
    public locale = '';
    public loading = true;
    public driver: string;
    public driverName: string;
    public daysInMonth = [];

    constructor(
        private readonly translate: TranslateService, 
        private readonly driverService: DriversService, 
        private readonly activatedRoute: ActivatedRoute, 
        private readonly appService: AppService,
        private readonly localStorageService: LocalStorageService) {}
    
    private dateRange: DateRange;
    private getDriverAvailabilityRange$: Subscription;


    public ngOnInit() {
        this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.driverService.drivers.subscribe((drivers: Driver[]) => {
            this.drivers = drivers.filter((d: Driver) => d.status === DriverStatus.ACTIVE);
            if (this.drivers.length) {
                this.generate();
            }
        });
    }

    public generate() {
        const depot = this.localStorageService.getDepot();
        this.routingSubscription = this.activatedRoute.params
            .pipe(
                map((params: any) => {
                    this.startDate = params['startdate'];
                    this.endDate = params['enddate'];
                    return params;
                }),
                flatMap(params => {
                    return this.driverService.getDriverAvailabilityRange(this.startDate, this.endDate, depot); 
                })
            )
            .subscribe(driversAvailability => {
                this.driversAvailability = driversAvailability;
                this.drivers = this.drivers.filter((d: Driver) => this.driversAvailability.availabilityList.some((x: DriverAvailability) => x.driverId === d.id));
                console.log(this.drivers);
                this.loading = false;
            });
    }

    public print() {
        var css = '@page { size: A4 portrait; }',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        style.type = 'text/css';
        style.media = 'print';

        if (style['styleSheet']) {
            style['styleSheet'].cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
    }

    public driversNumberPerShift(date: string, type: string): string {
        const o = _.groupBy(_.filter(this.driversAvailability.availabilityList, (t: DriverAvailability) => t.date === date && t.shift === type), (g: DriverAvailability) => g.mode);
        return `${o[DriversMode.REGULAR] ? o[DriversMode.REGULAR].length : 0}, ${o[DriversMode.SERVICE] ? o[DriversMode.SERVICE].length : 0}, ${o[DriversMode.VACATION] ? o[DriversMode.VACATION].length : 0}, ${o[DriversMode.SICKLEAVE] ? o[DriversMode.SICKLEAVE].length : 0}, ${o[DriversMode.SUPPLY] ? o[DriversMode.SUPPLY].length : 0}`;
    }

    public isAvailable(driverId: string, date: string, shiftType: ShiftType) {
        return _.find(this.driversAvailability.availabilityList, o => o.driverId === driverId && o.date === date && o.shift === shiftType);
    }

    public sortDrivers(date: string, shift: ShiftType) {
        this.drivers = _.sortBy(this.drivers, (d: Driver) => !this.isAvailable(d.id, date, shift));
    }

    public ngOnDestroy() {
        if (this.routingSubscription) {
            this.routingSubscription.unsubscribe();
        }
    }

    public changedMapType(event): void {
        

    }

    public changedShiftType(event): void {
      
    }

    public changedDow(event): void {
       
    }

    public selectedDriver(driver) {
        this.driver = driver;
        const dr = this.drivers.find((d: Driver) => d.id === this.driver);
        this.driverName = (dr) ? `${dr.firstName} ${dr.lastname}` : '';
    }

    
    
    public changedDateRange(event): void {

        if(!this.startDate || !this.endDate) {
            return;
        }
      
        const depot = this.localStorageService.getDepot();
        this.startDate = moment(event.dateStart).format('YYYY-MM-DD');
        this.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;

        this.loading = true;
        
        if (this.getDriverAvailabilityRange$) {
            this.getDriverAvailabilityRange$.unsubscribe();
        }

        this.getDriverAvailabilityRange$ = this.driverService.getDriverAvailabilityRange(this.startDate, this.endDate, depot)
            .subscribe(driversAvailability => {
                this.driversAvailability = driversAvailability;
                this.drivers = this.drivers.filter((d: Driver) => this.driversAvailability.availabilityList.some((x: DriverAvailability) => x.driverId === d.id));
                this.loading = false;
            });
    }
}
