
<div class="legend">
    <table id="visualiser-table-shift-details" class="table">
        <thead class="thead-light">
            <tr>
                <th></th>
                <th>{{ 'Routes' | translate }}</th>
                <th>{{ 'Deliveries' | translate }}</th>
                <th>{{ 'Distance' | translate }}</th>
                <th>
                    <div>{{ 'Punctuality' | translate }}</div>
                    <div class="split-header">
                        <div>{{ 'Early' | translate }}</div>
                        <div>{{ 'Late' | translate }}</div>
                    </div>
                </th>
                <th>
                    <div>{{ 'Driving time' | translate }}</div>
                </th>
                <th>
                    <div>{{ 'Boxes' | translate }}</div>
                    <div class="split-header">
                        <div>{{ 'Total' | translate }}</div>
                        <div>{{ 'Dry' | translate }}</div>
                        <div>{{ 'Chilled' | translate }}</div>
                    </div>
                </th>
                <!-- <th appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]">Unoptimized</th>
                <th>{{'Cuttoff complete' | translate}}</th>
                <th width="16%">
                    {{'Maps' | translate}}
                </th> -->
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{'Shift' | translate}}</td>
                <td>{{ routesPlanningResult | countRealRoutesPipe }}</td>
                <td>
                    <div class="split-header">
                        <div>{{ routesPlanningResult.totalDeliveries }}</div>
                    </div>
                </td>
                <td>
                    <div class="split-header">
                        <div>
                            <span *ngIf="distances && routesPlanningResult.totalRoutes">{{ distances[routesPlanningResult.runtime] | totalDistancePipe }}</span>
                            <div *ngIf="!distances && routesPlanningResult.totalRoutes" class="load-spinner spinner">
                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="split-header">
                        <div>{{ routesPlanningResult.totalEarlyMarginDeliveries }}</div>
                        <div>{{ routesPlanningResult.totalLateMarginDeliveries }}</div>
                    </div>
                </td>
                <td>
                    <div class="split-header">
                        <div>{{ routesPlanningResult.totalDrivingTime | secToHoursPipe }}</div>
                    </div>
                </td>
                <td>
                    <div class="split-header">
                        <div>{{ routesPlanningResult.boxesSum }}</div>
                        <div>{{ routesPlanningResult.totalBoxes['dryBoxes'] }}</div>
                        <div>{{ routesPlanningResult.totalBoxes['chilledBoxes'] }}</div>
                    </div>
                </td>

                <!-- <td appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]">
                    {{ routesPlanningResult.totalErrors }}
                </td>
                <td>
                    <ng-container *ngIf="(shiftSettings$ | async) as shiftSettings">
                        <div *ngIf="shiftSettings | findByStatusShiftPipe; let state; else noData">
                            <span *ngIf="state">{{state.occurredAt | utcToLocalTimePipe | amLocale: locale | amDateFormat: 'D MMMM YYYY, HH:mm:ss'}}</span>
                        </div>
                        <ng-template #noData><span>{{'No data available' | translate}}</span></ng-template>
                    </ng-container>
                </td>
                <td>
                    <div class="split-header">
                        <button (click)="openShiftMap('HEATMAP', shiftMap)" class="btn btn-xs btn-uppercase btn-white">HEATMAP</button>
                        <button (click)="openShiftMap('CLUSTER', shiftMap)" class="btn btn-xs btn-uppercase btn-white">CLUSTER MAP</button>
                    </div> -
                </td> -->
            </tr>
            <tr>
                    <td>{{'Route' | translate}}</td>
                    <td></td>
                    <td>
                        <div class="split-header">
                            <div>{{ routesPlanningResult | deliveriesPerVansPipe}}</div>
                        </div>
                    </td>
                    <td>
                        <div class="split-header">
                            <div>
                                <span *ngIf="distances && routesPlanningResult.totalRoutes">{{ distances[routesPlanningResult.runtime] | distancePerRoutesPipe:routesPlanningResult }}</span>
                                <div *ngIf="!distances && routesPlanningResult.totalRoutes" class="load-spinner spinner">
                                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="split-header">
                            <div>{{ routesPlanningResult | dividePerVansPipe:routesPlanningResult.totalEarlyMarginDeliveries }}</div>
                            <div>{{ routesPlanningResult | dividePerVansPipe:routesPlanningResult.totalLateMarginDeliveries }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="split-header">
                            <div>{{ routesPlanningResult | drivingTimePerRoutesPipe }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="split-header">
                            <div>{{ routesPlanningResult | dividePerVansPipe:routesPlanningResult.boxesSum }}</div>
                            <div>{{ routesPlanningResult | dividePerVansPipe:routesPlanningResult.totalBoxes['dryBoxes'] }}</div>
                            <div>{{ routesPlanningResult | dividePerVansPipe:routesPlanningResult.totalBoxes['chilledBoxes'] }}</div>
                        </div>
                    </td>
                    <!-- <td appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"></td>
                    <td></td>
                    <td></td> -->
                </tr>
                <tr>
                    <td>{{'Delivery' | translate}}</td>
                    <td></td>
                    <td></td>
                    <td>
                        <div class="split-header">
                            <div>
                                <span *ngIf="distances && routesPlanningResult.totalRoutes">{{ distances[routesPlanningResult.runtime] | distancePerDeliveryPipe:routesPlanningResult.totalDeliveries }}</span>
                                <div *ngIf="!distances && routesPlanningResult.totalRoutes" class="load-spinner spinner">
                                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                    </td>
                    <td>
                        <div class="split-header">
                            <div>{{ routesPlanningResult | drivingTimePerDeliveryPipe }}</div>
                        </div>
                    </td>
                    <td>
                        <div class="split-header">
                            <div>{{ (routesPlanningResult.boxesSum / routesPlanningResult.totalDeliveries).toFixed(2)}}</div>
                            <div>{{ (routesPlanningResult.totalBoxes['dryBoxes'] / routesPlanningResult.totalDeliveries).toFixed(2)}}</div>
                            <div>{{ (routesPlanningResult.totalBoxes['chilledBoxes'] / routesPlanningResult.totalDeliveries).toFixed(2)}}</div>
                        </div>
                    </td>
                    <!-- <td appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"></td>
                    <td></td>
                    <td></td> -->
                </tr>
        </tbody>
    </table>

    <div class="d-flex justify-content-between align-items-baseline mg-t-20 mg-l-20 mg-r-20">
        <p class="tx-12 tx-spacing-1 text-center tx-color-01 tx-uppercase tx-sans mg-t-10 mg-b-20">
            <span id="visualiser-shiftname" *ngIf="routesPlanningResult.label">{{'Solution name' | translate}}: <span class="tx-bold">{{ routesPlanningResult.label }}</span></span>
        </p>
        <p id="visualiser-cutoff-time">
            {{'Cutoff' | translate}}:&nbsp;
            <ng-container *ngIf="(shiftSettings$ | async) as shiftSettings">
                <span *ngIf="shiftSettings | findByStatusShiftPipe; let state; else noData">
                    <span class="tx-bold" *ngIf="state">{{state.occurredAt | utcToLocalTimePipe | amLocale: locale | amDateFormat: 'D MMMM YYYY, HH:mm:ss'}}</span>
                </span>
                <ng-template #noData><span>{{'No data available' | translate}}</span></ng-template>
            </ng-container>
        </p>
    </div>

</div>


<!-- {{'FISH' | translate}} -->
<!-- {{'BREAD' | translate}} -->