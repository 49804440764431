import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersRoles } from '@enums/roles';
import { HardwareEditComponent } from './components/hardware-edit.component';
import { HardwareUsagesComponent } from './components/hardware-usages.component';
import { VansComponent } from './components/vans.component';
import { ProxyComponent } from '@shared/components/proxy.component';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';
import { RouterOutletComponent } from '@shared/components/router-outlet.component';
import { VehicleTypeComponent } from './components/vehicle-type/vehicle-type.component';
import { VehicleTypeEditComponent } from './components/vehicle-type-edit/vehicle-type-edit.component';
import { VehicleTypeResolver } from './resolvers/vehicle-type.resolver';

const hardwareRoutes: Routes = [
    {
        path: '',
        component: ProxyComponent,
       
        data: {
            expectedRole: [UsersRoles.ROLE_DISPATCHER, UsersRoles.ROLE_SYSADMIN]
        },
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full'},
            {
                path: 'list',
                component: VansComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.Hardware'
                }
            },
            {
                path: 'vehicle-types', 
                component: RouterOutletComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.VehicleTypes'
                },
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: VehicleTypeComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Hardware_view],
                            breadcrumb: 'Breadcrumbs.VehicleTypes'
                        }
                    },
                    { 
                        path: 'create', 
                        component: VehicleTypeEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Hardware_edit],
                            breadcrumb: 'Breadcrumbs.VehicleTypeCreate'
                        }
                    },
                    { 
                        path: ':vehicleTypeId', 
                        component: VehicleTypeEditComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            vehicleType: VehicleTypeResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Hardware_edit],
                            breadcrumb: 'Breadcrumbs.VehicleTypeEdit'
                        }
                    },
                ]
            },
            {
                path: 'edit/:hardwareId', 
                component: HardwareEditComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.HardwareEdit'
                }
            },
            {
                path: 'create-hardware', 
                component: HardwareEditComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_edit],
                    breadcrumb: 'Breadcrumbs.HardwareCreate'
                }
            },
            {
                path: ':vanId', 
                component: VansComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_edit],
                    breadcrumb: 'Breadcrumbs.VansEdit'
                }
            },
            {
                path: ':vanId/:action', 
                component: VansComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.VansCreate'
                }
            },
            {
                path: 'hardware-usages/:y/:m/:d/:shiftId', 
                component: HardwareUsagesComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.HardwareUsages'
                }
            },
            {
                path: 'hardware-usages/:y/:m/:d', 
                component: HardwareUsagesComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.HardwareUsages'
                }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(hardwareRoutes)],
    exports: [RouterModule],
    providers: []
})
export class HardwareRouting {}
