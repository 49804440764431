<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="layers"></i-feather> {{'Edit shift template' | translate}}
            </h4>
        </div>
        <hr />
    </div>

    <div content>

        <ng-container *ngIf="!loader">

            <div id="wizard1" role="application" class="wizard clearfix">
                <div class="steps clearfix">
                    <ul role="tablist">
                        <li [ngClass]="{'tx-bold': checkStep(1)}" class="first">
                            <a class="tx-color-01 tx-11 pd-10">
                                <span class="number">1</span> <span class="title">{{'Basic Information' |
                                    translate}}</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tx-bold': checkStep(2)}" class="second">
                            <a class="tx-color-01 tx-11 pd-10">
                                <span class="number">2</span> <span class="title">{{'Fulfilment' | translate}}</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tx-bold': checkStep(3)}" class="third">
                            <a class="tx-color-01 tx-11 pd-10">
                                <span class="number">3</span> <span class="title">{{'Integration' | translate}}</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tx-bold': checkStep(4)}" class="fifth">
                            <a class="tx-color-01 tx-11 pd-10">
                                <span class="number">4</span> <span class="title">{{'Cutoffs' | translate}}</span>
                            </a>
                        </li>
                        <li [ngClass]="{'tx-bold': checkStep(5)}" class="fourth">
                            <a class="tx-color-01 tx-11 pd-10">
                                <span class="number">5</span> <span class="title">{{'Routing' | translate}}</span>
                            </a>
                        </li>

                    </ul>
                </div>

                <router-outlet></router-outlet>




            </div>



        </ng-container>
    </div>
</layout-default>

<!-- {{'SHIFTNAME' | translate }} -->
<!-- {{'WAREHOUSE' | translate }} -->
<!-- {{'AUTOCREATEDAYS' | translate }} -->
<!-- {{'ENABLED' | translate }} -->