import { Pipe, PipeTransform } from '@angular/core';
import { FinderEvent } from '@analytics/entities/finder-event';
import { ShiftStatus } from '@enums/enum';

@Pipe({
    name: 'findByStatusShiftPipe',
    pure: true
})
export class FindByStatusShiftPipe implements PipeTransform {

    public transform(events: FinderEvent[]): FinderEvent {
        return events.find((o: FinderEvent) => o.data.status === ShiftStatus.FULLFILLMENT);
    }
}
