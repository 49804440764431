import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './components/notification/notification.component';
import { SystemRouting } from './system.routing';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationModule } from '../authentication/authentication.module';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap';
import { SharedModule } from '../shared.module';
import { NotificationService } from './services/notification.service';
import { CronjobSchedulerComponent } from './components/cronjob-scheduler/cronjob-scheduler.component';
import { CronJobSchedulerService } from './services/cronjob-scheduler.service';
import { CronjobSchedulerEditComponent } from './components/cronjob-scheduler-edit/cronjob-scheduler-edit.component';
import { CronJobSchedulerResolver } from './services/cronjob-scheduler.resolver';
import { CronEditorModule } from 'cron-editor';

@NgModule({
  declarations: [
    NotificationComponent,
    CronjobSchedulerComponent,
    CronjobSchedulerEditComponent
  ],
  imports: [
    CommonModule,
    SystemRouting,
    SharedModule, 
    TooltipModule.forRoot(),
    RouterModule, 
    AuthenticationModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    TranslateModule.forChild({}),
    CronEditorModule
  ],
   providers: [
    NotificationService,
    CronJobSchedulerService,
    CronJobSchedulerResolver
   ]
})
export class SystemModule { }
