
        <ng-container *ngIf="(data$ | async) else loader">
            <notifier-container id="notifier-container" class="d-print-none tx-uppercase"></notifier-container>
            <ngx-guided-tour 
                [skipText]="'ngx-guided-tour.skip' | translate" 
                [nextText]="'ngx-guided-tour.next' | translate" 
                [backText]="'ngx-guided-tour.back' | translate"
                [closeText]="'ngx-guided-tour.close' | translate"
                [doneText]="'ngx-guided-tour.done' | translate">
            </ngx-guided-tour>
            
           

            <app-navbar class="d-print-none"></app-navbar>
            <div class="container-fluid admin-container">
                <div class="row no-gutters">
                    <div class="col-12 outlet-container">
                        <nav class="d-print-none" aria-label="breadcrumb" *ngIf="breadcrumbs.length">
                            <ol class="breadcrumb breadcrumb-style1 mg-b-0">
                                <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let index = index">
                                   
                                    <a [ngClass]="{'active': index === breadcrumbs.length - 1}" class="tx-normal tx-9" [routerLink]="[breadcrumb.url]">{{ breadcrumb.label | translate}} 
                                    
                                        <span class="tx-semibold" *ngIf="breadcrumb.params['key']">[{{(breadcrumb.params['key']) }}]</span>
                                    
                                    </a>
                                </li>
                                <!--<li (click)="navigateBack()" class="breadcrumb-item tx-semibold c-pointer"><i-feather name="arrow-left" [style.width.px]="10" [style.height.px]="10"></i-feather> {{'Back' | translate}}</li>-->
                            </ol>
                        </nav>
                        <div [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')">
                            <router-outlet #outlet="outlet"></router-outlet>
                        </div>
                       
                    </div>
                </div>
            </div>

            <div class="col-12 footer outlet-container d-print-none d-flex justify-content-between align-items-center" [ngClass]="{'no-production': !isProd}">
                <div>Proudly Powered by&nbsp;<a title="Open Routing" href="http://open-routing.com"> Open Routing</a>&nbsp;|&nbsp;Copyright &copy; 2018-{{year}}&nbsp;<span *ngIf="!isProd">Version: {{version}}</span></div>
                <div class="d-flex justify-content-between align-items-center">
                    <button class="btn btn-xs btn-link" (click)="startTour()"><i-feather [style.height.px]="15" name="help-circle"></i-feather>{{'App tutorial' | translate}}</button>
                    <button class="btn btn-xs btn-link" (click)="startHelp()"><i-feather [style.height.px]="15" name="book"></i-feather>{{'User Guide' | translate}}</button>
                </div>
            </div>
        </ng-container>

        <!--<ng-container *ngIf="!loader">
            <div class="content content-fixed content-auth-alt" [style.height.vh]="100">
                <div class="container ht-100p tx-center">
                    <div class="ht-100p d-flex flex-column align-items-center justify-content-center">
                        <h1 class="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5"><i-feather name="activity"></i-feather> {{'Error occurred' | translate}}</h1>
                        <h5 class="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Oopps. {{'There was an error, please try again later' | translate}}.</h5>
                        <p class="tx-color-03 mg-b-30">{{error.error.message}}</p>
                        <div class="mg-b-40"><button class="btn btn-white pd-x-30" (click)="back()">{{'Back' | translate}}</button></div>
                    </div>
                </div>
            </div>
        </ng-container>-->

        <ng-template #loader>
            <div class="content content-fixed content-auth-alt" [style.height.vh]="100">
                <div *ngIf="loader" class="load-spinner text-center">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                </div>
            </div>
        </ng-template>

        <!-- {{'Breadcrumbs.Calendar' | translate}} -->
        <!-- {{'Breadcrumbs.Analytics' | translate}} -->
        <!-- {{'Breadcrumbs.Drivers' | translate}} -->
        <!-- {{'Breadcrumbs.FleetAndHardware' | translate}} -->
        <!-- {{'Breadcrumbs.Payments' | translate}} -->
        <!-- {{'Breadcrumbs.Deliveries' | translate}} -->
        <!-- {{'Breadcrumbs.Locations' | translate}} -->
        <!-- {{'Breadcrumbs.Visualiser' | translate}} -->
        <!-- {{'Breadcrumbs.Users' | translate}} -->
        <!-- {{'Breadcrumbs.RouteDetails' | translate}} -->
        <!-- {{'Breadcrumbs.Simulator' | translate}} -->
        <!-- {{'Breadcrumbs.Inpost' | translate}} -->
        <!-- {{'Breadcrumbs.RoutesList' | translate}} -->
        <!-- {{'Breadcrumbs.EditLocation' | translate}} -->
        <!-- {{'Breadcrumbs.Barcodes' | translate}} -->
        <!-- {{'Breadcrumbs.RouteMap' | translate}} -->
        <!-- {{'Breadcrumbs.DriverPunctuality' | translate}} -->
        <!-- {{'Breadcrumbs.DeliveryPunctuality' | translate}} -->
        <!-- {{'Breadcrumbs.RoutingEfficiency' | translate}} -->
        <!-- {{'Breadcrumbs.RoutePunctuality' | translate}} -->
        <!-- {{'Breadcrumbs.EventsMap' | translate}} -->
        <!-- {{'Breadcrumbs.PunctualityDashboard' | translate}} -->
        <!-- {{'Breadcrumbs.OrdersHistory' | translate}} -->
        <!-- {{'Breadcrumbs.AddUser' | translate}} -->
        <!-- {{'Breadcrumbs.EditUser' | translate}} -->
        <!-- {{'Breadcrumbs.HardwareEdit' | translate}} -->
        <!-- {{'Breadcrumbs.HardwareCreate' | translate}} -->
        <!-- {{'Breadcrumbs.Vans' | translate}} -->
        <!-- {{'Breadcrumbs.HardwareUsages' | translate}} -->
        <!-- {{'Breadcrumbs.Payments' | translate}} -->
        <!-- {{'Breadcrumbs.Locations' | translate}} -->
        <!-- {{'Breadcrumbs.Solution' | translate}} -->
        <!-- {{'Breadcrumbs.CreateDelivery' | translate}} -->
        <!-- {{'Breadcrumbs.UpdateDelivery' | translate}} -->
        <!-- {{'Breadcrumbs.DriversEdit' | translate}} -->
        <!-- {{'Breadcrumbs.DriversAvailability' | translate}} -->
        <!-- {{'Breadcrumbs.DriversCreate' | translate}} -->
        <!-- {{'Breadcrumbs.VansEdit' | translate}} -->
        <!-- {{'Breadcrumbs.VansCreate' | translate}} -->
        <!-- {{'Breadcrumbs.PlanningSequence' | translate}} -->
        <!-- {{'Breadcrumbs.EditPlanningSequence' | translate}} -->
        <!-- {{'Breadcrumbs.CreatePlanningSequence' | translate}} -->
        <!-- {{'Breadcrumbs.Apm' | translate}} -->
        <!-- {{'Breadcrumbs.Operations' | translate}} -->
        <!-- {{'Breadcrumbs.ApmUpdate' | translate}} -->
        <!-- {{'Breadcrumbs.ApmCreate' | translate}} -->
        <!-- {{'Breadcrumbs.ApmMap' | translate}} -->
        <!-- {{'Breadcrumbs.Partner' | translate }} -->
        <!-- {{'Breadcrumbs.PartnerCreate' | translate }} -->
        <!-- {{'Breadcrumbs.PartnerUpdate' | translate }} -->
        <!-- {{'Breadcrumbs.Region' | translate }} -->
        <!-- {{'Breadcrumbs.RegionCreate' | translate }} -->
        <!-- {{'Breadcrumbs.RegionUpdate' | translate }} -->
        <!-- {{'Breadcrumbs.AssignDriverToRegion' | translate }} -->
        <!-- {{'Breadcrumbs.Subscription' | translate }} -->
        <!-- {{'Breadcrumbs.SubscriptionCreate' | translate }} -->
        <!-- {{'Breadcrumbs.SubscriptionUpdate' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDelivery' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDeliveryCreate' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDeliveryUpdate' | translate }} -->
        <!-- {{'Breadcrumbs.Roles' | translate}} -->
        <!-- {{'Breadcrumbs.EditRoles' | translate}} -->
        <!-- {{'Breadcrumbs.AddRoles' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerUserCreate' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerUserEdit' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerUsers' | translate}} -->
        <!-- {{'Breadcrumbs.EditTimeslot' | translate}} -->
        <!-- {{'Breadcrumbs.CreateTimeslots' | translate}} -->
        <!-- {{'Breadcrumbs.Timeslots' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerDc' | translate}} -->
        <!-- {{'Breadcrumbs.DcCreate' | translate}} -->
        <!-- {{'Breadcrumbs.DcEdit' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerCouriers' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerCouriersCreate' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerCouriersEdit' | translate}} -->
        <!-- {{'Breadcrumbs.ApmMaintenance' | translate}} -->
        <!-- {{'Breadcrumbs.ApmMaintenanceCreate' | translate}} -->
        <!-- {{'Breadcrumbs.ApmMaintenanceEdit' | translate}} -->
        <!-- {{'Breadcrumbs.ConfigValue' | translate}} -->
        <!-- {{'Breadcrumbs.ConfigValueCreate' | translate}} -->
        <!-- {{'Breadcrumbs.ConfigValueUpdate' | translate}} -->
        <!-- {{'Breadcrumbs.ConfigKeyUpdate' | translate}} -->
        <!-- {{'Breadcrumbs.ConfigKey' | translate}} -->
        <!-- {{'Breadcrumbs.ConfigKeyCreate' | translate}} -->
        <!-- {{'Breadcrumbs.ShiftPlanning' | translate}} -->
        <!-- {{'Breadcrumbs.ShiftPlanningEdit' | translate}} -->
        <!-- {{'Breadcrumbs.Depot' | translate}} -->
        <!-- {{'Breadcrumbs.DepotMap' | translate}} -->
        <!-- {{'Breadcrumbs.DepotEdit' | translate}} -->
        <!-- {{'Breadcrumbs.Agency' | translate}} -->
        <!-- {{'Breadcrumbs.AgencyCreate' | translate}} -->
        <!-- {{'Breadcrumbs.AgencyEdit' | translate}} -->
        <!-- {{'Breadcrumbs.AgencyAssigningDrivers' | translate}} -->
        <!-- {{'Breadcrumbs.Salary' | translate}} -->
        <!-- {{'Breadcrumbs.SalarReport' | translate}} -->
        <!-- {{'Breadcrumbs.SalarReportCreate' | translate}} -->
        <!-- {{'Breadcrumbs.SalarReportEdit' | translate}} -->
        <!-- {{'Breadcrumbs.SalaryReportAssigningDrivers' | translate}} -->
        <!-- {{'Breadcrumbs.AssignApmToRegion' | translate}} -->
        <!-- {{'Breadcrumbs.AssignUserToDepot' | translate}} -->
        <!-- {{'Breadcrumbs.DepotCreate' | translate}} -->  
        <!-- {{'Breadcrumbs.Couriers' | translate}} -->  
        <!-- {{'Breadcrumbs.CouriersCreate' | translate}} -->  
        <!-- {{'Breadcrumbs.CouriersEdit' | translate}} -->  
        <!-- {{'Breadcrumbs.CouriersAvailability' | translate}} -->  
        <!-- {{'Breadcrumbs.CouriersSchedulePrint' | translate}} -->  
        <!-- {{'Breadcrumbs.Hardware' | translate}} -->  
        <!-- {{'Breadcrumbs.DriversList' | translate}} -->  
        <!-- {{'Breadcrumbs.CouriersList' | translate}} -->  
        <!-- {{'Breadcrumbs.CutoffProccess' | translate}} -->  
        <!-- {{'Breadcrumbs.SolutionDetails' | translate}} -->  
        <!-- {{'Breadcrumbs.ManageCrazyDeliveries' | translate}} -->
        <!-- {{'Breadcrumbs.VehicleTypes' | translate}} -->  
        <!-- {{'Breadcrumbs.VehicleTypeCreate' | translate}} -->  
        <!-- {{'Breadcrumbs.VehicleTypeEdit' | translate}} -->  
        <!-- {{'Breadcrumbs.ReportOfDeliveriesByDriver' | translate}} -->
        <!-- {{'Breadcrumbs.ShiftPlanningEditBasic' | translate}} -->
        <!-- {{'Breadcrumbs.ShiftPlanningEditFulfilment' | translate}} -->
        <!-- {{'Breadcrumbs.ShiftPlanningEditIntegration' | translate}} -->
        <!-- {{'Breadcrumbs.ShiftPlanningEditRouting' | translate}} -->
        <!-- {{'Breadcrumbs.ReportFiles' | translate}} -->
        <!-- {{'Breadcrumbs.PartnerReports' | translate }} -->
        <!-- {{'Breadcrumbs.DeliveryAuditLog' | translate }} -->
        <!-- {{'Breadcrumbs.PartnerReports' | translate }} -->
        <!-- {{'Breadcrumbs.AuditLog' | translate }} -->
        <!-- {{'Breadcrumbs.ApmsDeliveryInRoute' | translate }} -->
        <!-- {{'Breadcrumbs.ApmsDeliverySearchByApm' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDeliveryReschedule' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDeliveryConvertd2d' | translate }} -->
        <!-- {{'Breadcrumbs.ApmAvailability' | translate }} -->
        <!-- {{'Breadcrumbs.ApmMaintenanceOverbooking' | translate }} -->
        <!-- {{'Breadcrumbs.RoutingEfficiencyBoxes' | translate }} -->
        <!-- {{'Breadcrumbs.RoutingEfficiencyDistances' | translate }} -->
        <!-- {{'Breadcrumbs.ApmAssignRegions' | translate }} -->
        <!-- {{'Breadcrumbs.SmsTemplates' | translate }} -->
        <!-- {{'Breadcrumbs.SmsTemplatesCreate' | translate }} -->
        <!-- {{'Breadcrumbs.SmsTemplatesEdit' | translate }} -->
        <!-- {{'Breadcrumbs.Settings' | translate }} -->
        <!-- {{'Breadcrumbs.System' | translate }} -->
        <!-- {{'Breadcrumbs.Notifications' | translate }} -->
        <!-- {{'Breadcrumbs.OrderReport' | translate }} -->
        <!-- {{'Breadcrumbs.Licensing' | translate }} -->
        <!-- {{'Breadcrumbs.ApmMaintenanceList' | translate}} -->
        <!-- {{'Breadcrumbs.ApmMaintenanceWindowsList' | translate}} -->
        <!-- {{'Breadcrumbs.BookingAvailability' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDeliverySearch' | translate }} -->
        <!-- {{'Breadcrumbs.AssignDriversToDepot' | translate }} -->
        <!-- {{'Breadcrumbs.AssignVansToDepot' | translate }} -->
        <!-- {{'Breadcrumbs.AssignHardwareToDepot' | translate }} -->
        <!-- {{'Breadcrumbs.CreateRole' | translate }} -->
        <!-- {{'Breadcrumbs.EditRole' | translate }} -->
        <!-- {{'Breadcrumbs.RebookingOtherApmsInRegion' | translate }} -->
        <!-- {{'Breadcrumbs.ApmDeliveryDetails' | translate }} -->
        <!-- {{'Breadcrumbs.Dashboard' | translate }} -->
        <!-- {{'Breadcrumbs.DepotVirtualList' | translate }} -->
        <!-- {{'Breadcrumbs.DepotVirtualCreate' | translate }} -->
        <!-- {{'Breadcrumbs.DepotVirtualEdit' | translate }} -->
        <!-- {{'Breadcrumbs.RouteTracking' | translate }} -->
        <!-- {{'Breadcrumbs.ImportCatering' | translate }} -->
        <!-- {{'Breadcrumbs.ImportDeliveries' | translate }} -->
        <!-- {{'Breadcrumbs.CronJobScheduler' | translate }} -->
        <!-- {{'Breadcrumbs.CronJobSchedulerCreate' | translate }} -->
        <!-- {{'Breadcrumbs.CronJobSchedulerEdit' | translate }} -->
        <!-- {{'Breadcrumbs.PaymentReport' | translate }} -->
        <!-- {{'Breadcrumbs.ProfanityFilterList' | translate }} -->
        <!-- {{'Breadcrumbs.ProfanityFilterCreate' | translate }} -->
        <!-- {{'Breadcrumbs.ProfanityFilterEdit' | translate }} -->
        <!-- {{'Breadcrumbs.CommentList' | translate }} -->
        <!-- {{'Breadcrumbs.DashboardDeliverySales' | translate }} -->
        <!-- {{'Breadcrumbs.DashboardVehicleDistance' | translate }} -->
        <!-- {{'Breadcrumbs.DriversSchedulePrint' | translate }} -->
        <!-- {{'Breadcrumbs.ShiftPlanningEditCutoffs' | translate }} -->

     