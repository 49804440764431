import { Component, OnInit, Input } from '@angular/core';
import { OptimizeResult } from '../../entities/optimize-result';
import { environment } from '@environment';
import { Permissions } from '@enums/permissions';
import { ShiftSettings } from '@entities/shift-settings';
import { Observable } from 'rxjs';
import { VisualiserService } from '../../services/visualiser.service';
import { tap } from 'rxjs/operators';
import { OptimizeRoutes } from '../../entities/optimize-routes';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';

@Component({
  selector: 'app-visualiser-routes-planning-header',
  templateUrl: './visualiser-routes-planning-header.component.html',
  styleUrls: ['./visualiser-routes-planning-header.component.scss']
})
export class VisualiserRoutesPlanningHeaderComponent implements OnInit {

  @Input() public routesPlanningResult: OptimizeRoutes;
  @Input() public shift: ShiftSettings;
  @Input() public distances: any;

  public Permissions = Permissions;
  public locale;

  public shiftSettings$: Observable<any>;

  constructor(
    private readonly visualiserService: VisualiserService,
    private readonly translate: TranslateService
  ) { }
 
  public ngOnInit() {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.locale = event.lang);
    this.shiftSettings$ = this.visualiserService.shiftStatusChange(this.shift.id);
  }

}
