<div class="singleChart" *ngIf="chart">
    <div class="total" *ngIf="showTotals">
        <span [title]="chart.total + ' ' + quantityName">{{chart.total | number:'1.0-0'}}</span>
    </div>
    <div class="progress">
        <div class="progress-bar COMPLETE_BEFORE_SCHEDULE" role="progressbar" [style.width]="getWidth(chart.completeBeforeSchedule, chart)"
             [containerClass]="'pretty-tooltip'" placement="bottom" 
             [tooltip]="('DASHBOARD.CHART.Label.Complete before schedule %value%' | translate:{value: chart.completeBeforeSchedule})">
            {{(showPartials ? chart.completeBeforeSchedule : '') | number:'1.0-0'}}
        </div>
        <div class="progress-bar COMPLETE_ON_SCHEDULE" role="progressbar" [style.width]="getWidth(chart.completeOnSchedule, chart)"
             [containerClass]="'pretty-tooltip'" placement="bottom" 
             [tooltip]="('DASHBOARD.CHART.Label.Complete on schedule %value%' | translate:{value: chart.completeOnSchedule})">
            {{(showPartials ? chart.completeOnSchedule : '') | number:'1.0-0'}}
        </div>
        <div class="progress-bar COMPLETE_AFTER_SCHEDULE" role="progressbar" [style.width]="getWidth(chart.completeAfterSchedule, chart)"
             [containerClass]="'pretty-tooltip'" placement="bottom" 
             [tooltip]="('DASHBOARD.CHART.Label.Complete after schedule %value%' | translate:{value: chart.completeAfterSchedule})">
            {{(showPartials ? chart.completeAfterSchedule : '') | number:'1.0-0'}}
        </div>
        <div class="progress-bar INCOMPLETE_BEFORE_SCHEDULE" role="progressbar" [style.width]="getWidth(chart.incompleteBeforeSchedule, chart)"
             [containerClass]="'pretty-tooltip'" placement="bottom" 
             [tooltip]="('DASHBOARD.CHART.Label.Incomplete before schedule %value%' | translate:{value: chart.incompleteBeforeSchedule})">
            {{(showPartials ? chart.incompleteBeforeSchedule : '') | number:'1.0-0'}}
        </div>
        <div class="progress-bar UNKNOWN" role="progressbar" [style.width]="getWidth(getRemainder(chart), chart)"
             [containerClass]="'pretty-tooltip'" placement="bottom" 
             [tooltip]="('DASHBOARD.CHART.Label.Unknown' | translate)">
            {{(showPartials ? getRemainder(chart) : '') | number:'1.0-0'}}
        </div>
        <div class="progress-bar INCOMPLETE_AFTER_SCHEDULE" role="progressbar" [style.width]="getWidth(chart.incompleteAfterSchedule, chart)"
             [containerClass]="'pretty-tooltip'" placement="bottom" 
             [tooltip]="('DASHBOARD.CHART.Label.Incomplete after schedule %value%' | translate:{value: chart.incompleteAfterSchedule})">
            {{(showPartials ? chart.incompleteAfterSchedule : '') | number:'1.0-0'}}
        </div>
    </div>
</div>

