<ng-container *ngIf="($licencing | async) as licensing; else loaderTmpl">

    <div class="row">
        <div class="col-sm-6">
            <div class="mg-50">
                <h2 class="mg-b-20 tx-semibold tx-14">{{'Statusses' | translate}}:</h2>
                <ul class="statuses">
                    <li *ngFor="let status of deliveryStatus">
                        <button 
                            class="btn btn-white" 
                            (click)="selectedStatus(status)"
                            [class.btn-success]="selectedStatusses.includes(status)"
                            [class.btn-white]="!selectedStatusses.includes(status)">
                            {{selectedStatusses[status]}} {{status | translate}}
                        </button>
                    </li>  
                </ul>

                <ng-container *ngIf="(partners$ | async) as partners">
                    <h2 class="mg-b-20 tx-semibold tx-14">{{'Partners' | translate}}:</h2>
                    <ng-container *ngIf="partners.length; else noPartners">
                        <ul  class="statuses">
                            <li *ngFor="let partner of partners">
                                <button 
                                    class="btn btn-white" 
                                    (click)="selectedPartner(partner.id)"
                                    [class.btn-success]="selectedPartners.includes(partner.id)"
                                    [class.btn-white]="!selectedPartners.includes(partner)">
                                    {{partner.name}} {{partner.code}}
                                </button>
                            </li>  
                        </ul>
                    </ng-container>
                    <ng-template #noPartners>{{'No partners' | translate}}</ng-template>
                </ng-container>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="d-flex justify-content-center flex-column mg-50">
                <h2 class="mg-b-20 tx-semibold tx-14">{{'Billing' | translate}}:</h2>
                <div class="d-flex justify-content-center mg-b-10">
                    <div class="flex-grow-1">{{'Number of deliveries handled in OR' | translate}}</div>
                    <div>{{deliveriesCount | number:'1.0-0'}}</div>
                </div>
                <div class="d-flex justify-content-center mg-b-10">
                    <div class="flex-grow-1">{{'Transaction fee' | translate}}</div>
                    <div>{{deliveriesFee | number: '1.2-2' }} {{ CurrencyType.PLN | translate }}</div>
                </div>
                <div class="d-flex justify-content-center mg-t-0">
                    <div class="flex-grow-1 tx-bold">{{'Total' | translate}}</div>
                    <div class="tx-bold">{{deliveriesCount * deliveriesFee | number: '1.2-2' }} {{ CurrencyType.PLN | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngFor="let deliveries of licensing | keyvalue">
        <h3>{{'Depot' | translate}}: <span>{{deliveries.key}}</span>, {{'Sum' | translate}}: <span>{{deliveries.value | sumByPipe:'count'}}</span></h3>
        
        <ng-container *ngIf="!deliveries.value.length">
            <ng-container *ngTemplateOutlet="tableTmpl; context: { data: []}"></ng-container>
        </ng-container>

        <ng-container *ngIf="deliveries.value.length">
            <ng-container *ngTemplateOutlet="tableTmpl; context: { data: deliveries.value}"></ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template let-data="data" #tableTmpl>
    <ngx-datatable 
        id="distances" 
        class="bootstrap" 
        [rows]="data" 
        [columns]="columns" 
        [columnMode]="'flex'"
        [headerHeight]="50" 
        [footerHeight]="50" 
        [messages]="tableMessages"
        [sortType]="'single'"
        [rowHeight]="'auto'"
        [limit]="20"
        [externalPaging]="false"
        [rowClass]="rowClass"
        [scrollbarH]="scrollBarHorizontal">
    
    <ng-container *ngFor="let col of columns">
        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                <b (click)="sort()">{{ col.name | translate }}</b>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div [ngSwitch]="col.prop">
                    <div *ngSwitchCase="'status'">
                        {{value | translate}}
                    </div>
                    <div *ngSwitchDefault>{{value}}</div>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ng-container>
</ngx-datatable>
</ng-template>

<ng-template #loaderTmpl>
    <div class="load-spinner text-center">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>&nbsp;{{ 'Please, wait!' | translate }}
    </div>
</ng-template>
