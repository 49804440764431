import { Component, OnInit } from '@angular/core';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { PageableResponse } from '@entities/pagable-response';
import { BsModalService } from 'ngx-bootstrap';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { tap } from 'rxjs/operators';
import { CrudListComponent } from '@shared/components/cruds/crud-list.component';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss']
})
export class VehicleTypeComponent extends CrudListComponent<VehicleTypes, string> implements OnInit {

  public columns = [
    { id: 'code', prop: 'code', name: this.translateService.instant('Code'), flexGrow: 4, sortable: true },
    { id: 'chilledBoxes', prop: 'capacity.chilledBoxes', name: this.translateService.instant('CHILLED'), flexGrow: 2, sortable: true },
    { id: 'dryBoxes', prop: 'capacity.dryBoxes', name: this.translateService.instant('DRY'), flexGrow: 2, sortable: true },
    { id: 'frozenBoxes', prop: 'capacity.frozenBoxes', name: this.translateService.instant('FROZEN'), flexGrow: 2, sortable: true },
    { id: 'combinedBoxes', prop: 'capacity.combinedBoxes', name: this.translateService.instant('COMBINED'), flexGrow: 2, sortable: true },
    { id: 'weight', prop: 'capacity.weight', name: this.translateService.instant('WEIGHT'), flexGrow: 2, sortable: true },
    { id: 'active', prop: 'active', name: this.translateService.instant('Status'), flexGrow: 3, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false},
  ];



  constructor(
    protected readonly vehicleTypesService: VehicleTypesService,
    protected readonly translateService: TranslateService,
    protected readonly navigationRoute: ActivatedRoute,
    protected readonly modalService: BsModalService,
    protected readonly notifierService: NotifierService)  
  {
    super(vehicleTypesService, translateService, navigationRoute, modalService, notifierService)
  }

  public ngOnInit() {
    this.list$ = this.vehicleTypesService.list$.pipe(
      tap((list: PageableResponse<VehicleTypes>) => this.rows = list.content)
    );
    
    this.vehicleTypesService.fetchAll(this.queryParams);
    this.modifiedRow = this.navigationRoute.snapshot.params.modifiedRow;

    this.rowClass = row => {
      return { 'row-modified': row.id === this.modifiedRow };
    };

    this.loader = false;
  }
}
