
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CommentService } from '../services/comment.service';

@Injectable()
export class CommentResolver implements Resolve<any> {
    constructor(private service: CommentService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.service.findOne(params['id']);
    }
}
