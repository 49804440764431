import * as _ from 'lodash';

/**
 * Base Class for entities exchanged with API
 */
export class Entity {
    public serialize() {
        return {};
    }

    /**
     * Convert entity to JSON String
     * @param spaces
     */
    public toJson(spaces = 2) {
        const data = this.serialize();
        return JSON.stringify(data, null, spaces);
    }

    /**
     * Type Guard for patachable method
     * @param x
     */
    public isPatchable(x: any) {
        if (!x) {
            return false;
        }

        return x.patch !== undefined;
    }

    /**
     * Patch (fill) entity with given simple object.
     * Fills properties with new data, preserving missing values
     * @param x
     */
    public patch(data) {
        _.assignInWith(this, data, (objValue, srcValue) => {
            if (_.isUndefined(srcValue)) {
                return objValue;
            }

            if (this.isPatchable(objValue)) {
                return objValue.patch(srcValue);
            } else {
                return srcValue;
            }
        });
        return this;
    }
}
