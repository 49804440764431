<ng-container *ngIf="params">
    <div class="d-flex justify-content-between align-items-end">
        <!-- <div class="flex-fill"> -->


        <div class="d-flex justify-content-between align-items-end" [style.max-width.%]="60">

            <div class="mg-r-10" [style.width.px]="170">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                    {{'Select date' |translate}}:</label>
                <div [style.height.px]="38">
                    <ng-datepicker id="visualiser-date-selection" *ngIf="locale"
                        class="menu__select menu__select--date mg-r-10 flex-grow-1 w-100" [(ngModel)]="date"
                        [options]="datePickerOptions"></ng-datepicker>
                </div>
            </div>


            <ng-container *ngIf="(shiftsTypes$ | async) || [] as shiftsTypes">

                <div class="mg-r-10" [style.width.px]="170">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                        {{'Select shift' |translate}}:</label>
                    <select id="visualiser-shift-selection-new" class="menu__select custom-select"
                        [(ngModel)]="params.shiftId" (change)="onShiftChanged($event, shiftsTypes)">
                        <ng-container *ngFor="let shiftConfig of shiftsTypes">

                            <option [value]="shiftConfig.type">
                                {{shiftConfig.type | translate }}</option>

                        </ng-container>
                    </select>
                </div>

                <div class="mg-r-10" [style.width.px]="170">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                        {{'Select cutoff' |translate}}:</label>
                    <select [(ngModel)]="params.cutoff" class="menu__select custom-select"
                        [ngClass]="{'bd-1 bd-danger': ['CUTOFF', 'SIMULATOR'].includes(visualiserMode)}"
                        [disabled]="!['CUTOFF', 'SIMULATOR'].includes(visualiserMode)">
                        <ng-container *ngFor="let shiftConfig of (shiftsTypes$ | async)">
                            <ng-container *ngIf="['CUTOFF', 'SIMULATOR'].includes(visualiserMode)">
                                <ng-container *ngIf="shiftConfig.type === params.shiftId">
                                    <option *ngIf="['SIMULATOR'].includes(visualiserMode)" [value]="shiftConfig.type">
                                        {{'Shift' | translate}}</option>
                                    <option *ngFor="let cutoff of shiftConfig.cutoffs" [value]="cutoff.cutoff">
                                        {{cutoff.cutoff | date:'HH:mm'}}</option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </select>
                </div>
            </ng-container>

            <div class="mg-r-10" [style.width.px]="170">
                <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10"></label>
                <button id="visualiser-btn-load-data" [ngClass]="{'invisible': isDeliveryManagementMode}"
                    class="menu__button button btn btn-white btn-sm btn-uppercase flex-fill mg-r-10 flex-grow-1 w-100"
                    (click)="changedMode()">{{'Load Data' | translate}}</button>
            </div>
            <!-- </div> -->
        </div>

        <div appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]" class="flex-fill flex-grow-1">
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
                {{'Select visualiser mode' | translate}}:</label>
            <ul class="nav nav-tabs nav-justified">
                <li id="visualiser-btn-mode-viewing" appCheckFeaturesConfig
                    [featureName]="[Permissions.Visualiser_cutoff]"
                    (click)="visualiserMode = 'VIEWING'; changedMode('VIEWING')" class="pointer nav-item tx-11">
                    <a [ngClass]="{'active': visualiserMode === 'VIEWING'}" class="nav-link tx-uppercase">{{'VIEWING' |
                        translate}}</a>
                </li>

                <li id="visualiser-btn-mode-cutoff" appCheckFeaturesConfig
                    [featureName]="[Permissions.Visualiser_cutoff]"
                    (click)="visualiserMode = 'CUTOFF'; changedMode('CUTOFF')" class="pointer nav-item tx-11">
                    <a [ngClass]="{'active': visualiserMode === 'CUTOFF'}" class="nav-link tx-uppercase">{{'CUTOFF' |
                        translate}}</a>
                </li>
                <li id="visualiser-btn-mode-simulator" appCheckFeaturesConfig
                    [featureName]="[Permissions.Visualiser_cutoff]"
                    (click)="visualiserMode = 'SIMULATOR'; changedMode('SIMULATOR')" class="pointer nav-item tx-11">
                    <a [ngClass]="{'active': visualiserMode === 'SIMULATOR'}"
                        class="nav-link tx-uppercase">{{'SIMULATOR' | translate}}</a>
                </li>

                <li id="visualiser-btn-mode-simulator" appCheckFeaturesConfig
                    (click)="visualiserMode = 'AUDIT_LOG'; changedMode('AUDIT_LOG')" class="pointer nav-item tx-11">
                    <a [ngClass]="{'active': visualiserMode === 'AUDIT_LOG'}" class="nav-link tx-uppercase">
                        {{'AUDIT LOG' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
</ng-container>
<!-- {{'DELIVERY_MANAGEMENT' | translate}} -->