import { Pipe, PipeTransform } from '@angular/core';
import { ShiftStatus } from '@enums/enum';

@Pipe({
    name: 'isShiftManageablePipe',
    pure: true
})
export class IsShiftManageablePipe implements PipeTransform {

    public transform(status: string): boolean {
        return status === ShiftStatus.CONFIGURING || status === ShiftStatus.BOOKING || status === ShiftStatus.PLANNING;
    }
}
