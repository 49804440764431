import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { CutoffService } from '../../services/cutoff.service';
import { Observable, Subscription, from, of } from 'rxjs';
import { RoutesPlanningParams } from '../../interfaces/routes-planning-params.interface';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { RouteId } from '@entities/routeId';
import { switchMap, tap, filter, take } from 'rxjs/operators';
import { ShiftSettings } from '@entities/shift-settings';
import { CutoffSolutions } from '../../interfaces/cutoff-solutions.interface';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { SortType } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { UtilsService } from '@services/utils.service';
import { VisualiserService } from '../../services/visualiser.service';
import { OptimizeResult } from '../../entities/optimize-result';
import { Permissions } from '@enums/permissions';
import { TranslateService } from '@ngx-translate/core';
import { DailyShiftConfig } from '@calendar/entities/dailyShiftConfig';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { ShiftSettingsService } from '@calendar/services/shift-settings.service';
import { FinderEvent } from '@analytics/entities/finder-event';
import { ShiftStatus, Client } from '@enums/enum';
import * as moment from 'moment';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { HttpErrorResponse } from '@angular/common/http';
import { ShiftCutoffData } from '@calendar/interafces/shift-cutoff-data.interface';
import { FinalizeDialogComponent } from '../dialogs/finalize-dialog/finalize-dialog.component';

@Component({
  selector: 'app-visualiser-cutoff',
  templateUrl: './visualiser-cutoff.component.html',
  styleUrls: ['./visualiser-cutoff.component.scss']
})
export class VisualiserCutoffComponent implements OnInit, OnDestroy {
  @ViewChild('table', {static: false}) public table: any;

  public solutions$: Observable<CutoffSolutions[]>;
  private params: RoutesPlanningParams;
  public routesPlanningResults$: Observable<OptimizeResult>;
  public distances$: Observable<any>;

  public columns = [
    { prop: 'simulationKey', name: this.translate.instant('Key'), flexGrow: 2, sortable: true },
    { prop: 'routeCount', name: this.translate.instant('Routes') + '\n' + this.translate.instant('Deliveries'), flexGrow: 2, sortable: true },
    { prop: 'distance', name: this.translate.instant('Distance/Delivery'), flexGrow: 2, sortable: true },
    { prop: 'description', name: this.translate.instant('Description'), flexGrow: 4, sortable: true },
    { prop: 'errors', name: this.translate.instant('Errors'), flexGrow: 2, sortable: false },
    { prop: 'deliveryEarly', name: this.translate.instant('Early') + '\n' + this.translate.instant('Late'), flexGrow: 2, sortable: true },
    { prop: 'deliveryTime', name: this.translate.instant('Delivery time'), flexGrow: 2, sortable: true },
    { prop: 'shiftStart', name: this.translate.instant('Shift margin'), flexGrow: 2, sortable: true },
    { prop: 'slotStart', name: this.translate.instant('Slot margin'), flexGrow: 2, sortable: true },
    { prop: 'status', name: this.translate.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translate.instant('Options'), flexGrow: 2, sortable: false },
  ];

  public tableMessages = {
    emptyMessage: this.translate.instant('No data to display')
  };

  public SortType = SortType;
  public Permissions = Permissions;

  public  queryParams: QueryParams = Object.assign({}, environment.pagination);

  @ViewChild('finalizeShiftConfirmation', {static: true}) public finalizeShiftConfirmation: TemplateRef<any>;
  @ViewChild('promoteSolutionConfirmation', {static: true}) public promoteSolutionConfirmation: TemplateRef<any>;
  @ViewChild('finalizeSolutionConfirmation', {static: true}) public finalizeSolutionConfirmation: TemplateRef<any>;
  @ViewChild('autoPromoteSolutionConfirmation', {static: true}) public autoPromoteSolutionConfirmation: TemplateRef<any>;
  @ViewChild('autoFinalizeSolutionConfirmation', {static: true}) public autoFinalizeSolutionConfirmation: TemplateRef<any>;
  @ViewChild('cutoffTimeoutConfirmation', {static: true}) public cutoffTimeoutConfirmation: TemplateRef<any>;
  @ViewChild('forcefitConfirmation', {static: true}) public forcefitConfirmation: TemplateRef<any>;
  @ViewChild('manualKeepSolutionConfirmation', {static: true}) public manualKeepSolutionConfirmation: TemplateRef<any>;
  @ViewChild('forceCloseShiftConfirmation', {static: true}) public forceCloseShiftConfirmation: TemplateRef<any>;
  
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;
  public confirmPromoteObservable: Observable<any>;
  public confirmChangedAutoPromoteSolutionSettingsObservable: Observable<any>;
  public confirmAutoFinalizeSolutionSettingObservable: Observable<any>;
  public confirmcutoffTimeoutgObservable: Observable<any>;
  private confirmManualAndKeepSolutionObservable: Observable<any>;

  private shiftId: string;
  public shift: ShiftSettings;
  public selectedSolution: CutoffSolutions;
  public loader = undefined;
  public cutoffIsSet = false;
  public recalculateLoader: boolean = false;
  public forceitLoader: boolean = false;
  public reloadSolutionsLoader: boolean = false;
  public noRoutes = false;
  public finalizeLoader = false;
  public manualKeepSolutionLoader = false;

  private routerEvents$: Subscription;

  public autoPromoteSolution: boolean;
  public autoFinalizeSolution: boolean;
  public shiftStatusChanged$: FinderEvent[] = [];
  public shiftStatus: string[] = Object.values(ShiftStatus);
  public cutoffTimeout: number;
  public inpostClient: string = Client.INPOST;
  public automaticCutoff: boolean;
  public ShiftStatusType = ShiftStatus;
  public cutoff: ShiftCutoffData;

  public bsModalRef: BsModalRef;

  constructor(
    private readonly cutOffService: CutoffService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: BsModalService,
    private readonly notifierService: NotifierService,
    private readonly utilsService: UtilsService,
    private readonly translate: TranslateService,
    private readonly visualiserService: VisualiserService,
    private readonly router: Router,
    private readonly shiftSettingsService: ShiftSettingsService
  ) { }

  public ngOnInit() {

    this.initComponentData();

    this.routerEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(a => this.initComponentData())
  }

  public initComponentData() {

    this.solutions$ = undefined;
    this.params = { 
      y: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'y'),
      m: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'm'),
      d: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'd'),
      shiftId: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'shiftId'),
      depot: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'depot'),
      cutoff: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'cutoff'),
    } as RoutesPlanningParams;

    const regex = /\d{4}-\d{2}-\d{2}/;

    if (regex.test(this.params.cutoff)) {
      this.cutoffIsSet = true;
      this.loader = true;
      this.shiftId = RouteId.getShiftId3(this.params.y, this.params.m, this.params.d, this.params.shiftId);
      this.shiftStatusChanged$ = this.visualiserService.shiftStatusChange(this.shiftId);
      this.loadSolutions();
      this.loadOriginalPlanning();
    }
  }

  /**
   * To loading routes solutions.
   */
  public loadSolutions() {

    this.solutions$ = undefined;
    this.reloadSolutionsLoader = true;

    this.solutions$ = this.cutOffService.getShift(this.shiftId).pipe(
      switchMap((shift: ShiftSettings) => {
        this.shift = shift;
        const cutoff = this.shift.cutoffs.find((c: ShiftCutoffData) => c.cutoff === this.params.cutoff);
        this.autoPromoteSolution = (cutoff) ? cutoff.autoPromoteSolution : false;
        this.autoFinalizeSolution = (cutoff) ? cutoff.autoFinalizeSolution : false;
        this.automaticCutoff = (cutoff) ? cutoff.automaticCutoff : false;
        this.cutoffTimeout = (cutoff) ? cutoff.cutoffTimeout : 0;
        this.cutoff = (cutoff) ? cutoff : undefined;
        return this.cutOffService.getSolutionsList(shift, this.params.cutoff);
      }), 
      tap((solutions: CutoffSolutions[]) => {
        this.noRoutes = (!solutions.length) ? true : false;
        this.reloadSolutionsLoader = false;
      }),
    )
  }
  
  /**
   * To loading original solution.
   */
  public loadOriginalPlanning() {
    this.routesPlanningResults$ = 
      this.visualiserService.getRoutePaths3(this.params.depot, this.params.cutoff);

    this.distances$ = this.visualiserService.distances;
    
    setTimeout(() => {
      this.loader = false;
    }, 500)
  }

  /**
   * Copy from the set of solutions to the running solution
   *
   * @param solution CutoffSolutions interface.
   *
   */
  public promoteSolution(solution: CutoffSolutions): void {
    this.modalRef = this.modalService.show(this.promoteSolutionConfirmation, {class: 'modal-md'});
    this.confirmPromoteObservable = this.cutOffService.promoteSolution(this.shiftId, this.shift.version, solution.id, false);
  }

  /**
   * Mark solution as final. The solution will be selected during the cutoff.
   *
   * @param solution CutoffSolutions interface.
   *
   */
  public promoteAndFinalizeSolution(solution: CutoffSolutions): void {
    this.modalRef = this.modalService.show(this.finalizeSolutionConfirmation, {class: 'modal-md'});
    this.selectedSolution = solution;
    this.confirmObservable = this.cutOffService.promoteSolution(this.shiftId, this.shift.version, solution.id, true);
  }

  /**
   * Change autoPromoteSolution settings - confirmation popup 
   */
  public autoPromoteSolutionChanged(event): void {
    event.preventDefault();
    this.modalRef = this.modalService.show(this.autoPromoteSolutionConfirmation, {class: 'modal-md'});
    const shift: ShiftExt = DailyShiftConfig.fromShiftSettings(this.shift);
    shift.integration.autoPromoteSolution = !shift.integration.autoPromoteSolution;
    this.confirmChangedAutoPromoteSolutionSettingsObservable = this.shiftSettingsService.updateIntegration(shift.integration);
  }

  /**
   * Change cutoffTimeout settings - confirmation popup 
   */
  public cutoffTimeoutChanged(): void {
    this.modalRef = this.modalService.show(this.cutoffTimeoutConfirmation, {class: 'modal-md'});
  }

  /**
   * Change autoFinalizeSolution settings - confirmation popup
   */
  public autoFinalizeSolutionChanged(event): void {
    event.preventDefault();
    this.modalRef = this.modalService.show(this.autoFinalizeSolutionConfirmation, {class: 'modal-md'});
    const shift: ShiftExt = DailyShiftConfig.fromShiftSettings(this.shift);
    shift.integration.autoFinalizeSolution = !shift.integration.autoFinalizeSolution;
    this.confirmAutoFinalizeSolutionSettingObservable = this.shiftSettingsService.updateIntegration(shift.integration);
  }

  /**
   * Recalculate the solution in the backend. This just recalculates according to the backend
   * configuration, it ignores the shift configuration currently visible on this page.
   *
   * @param mustFit Whether to force the solution to fit by escalating the parameters.
   *
   */
  public recalculate(mustFit: boolean): void {
    if (mustFit) {
      this.modalRef = this.modalService.show(this.forcefitConfirmation, { class: 'modal-md' });
      this.confirmObservable = this.visualiserService.recalculate(this.buildShiftId(), mustFit)
    } else {
      this.visualiserService.recalculate(this.buildShiftId(), mustFit).subscribe(
        () => {
            this.recalculateLoader = false;
            this.notifierService.notify('success', this.translate.instant('Successfully forced it the solution!'));
            this.loader = true;
            this.loadSolutions();
            setTimeout(() => this.loader = false, 500);
        },
        () => {
            this.recalculateLoader = false;
            this.forceitLoader = false;
            this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
        }
      ); 
    }
    
  }

  public manualKeepSolution() {
    this.modalRef = this.modalService.show(this.manualKeepSolutionConfirmation, { class: 'modal-md' });
    this.confirmManualAndKeepSolutionObservable = this.visualiserService.manualAndKeepSolution(this.buildShiftId())
  }

  public manualKeepSolutionExecute() {
    this.confirmManualAndKeepSolutionObservable.subscribe(
      () => {
          this.modalRef.hide();
          this.manualKeepSolutionLoader = false;
          this.notifierService.notify('success', this.translate.instant('Action has been executed successfully!'));
          this.loader = true;
          this.loadSolutions();
          setTimeout(() => this.loader = false, 500);
      },
      () => {
          this.manualKeepSolutionLoader = false;
      }
  ); 
  }

  public recalculateExecute(): void {
    this.confirmObservable.subscribe(
        () => {
            this.modalRef.hide();
            this.forceitLoader = false;
            this.notifierService.notify('success', this.translate.instant('Successfully generated new solutions!'));
            this.loader = true;
            this.loadSolutions();
            setTimeout(() => this.loader = false, 500);
        },
        () => {
            this.recalculateLoader = false;
            this.forceitLoader = false;
            this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
        }
    ); 
  }


  /**
   * Finalize the solution in the backend. This just finalizes according to the backend
   * configuration, it ignores the shift configuration currently visible on this page.
   */
  public finalize(solutions: CutoffSolutions[]): void {

    this.visualiserService.getRoutePaths2(this.params.y, this.params.m, this.params.d, this.params.shiftId).pipe(
      take(1)
    ).subscribe((result: OptimizeResult) => {
      const initialState = {
        data: {
          shiftId: this.buildShiftId(),
          result,
          solutions,
          params: this.params
        }
      };

    this.bsModalRef = this.modalService.show(FinalizeDialogComponent, { initialState });
    
    this.bsModalRef.content.onClose
    .pipe(take(1))
    .subscribe(
      (result) => {
        this.loadSolutions();
        setTimeout(() => this.loader = false, 500);
        this.notifierService.notify('success', this.translate.instant('The solution has been promoted as final!'));
        this.confirmObservable = null;
      }, () => {
        this.finalizeLoader = false;
      });
    });
  }

  public closeShiftLoader(): void {
    this.modalRef = this.modalService.show(this.forceCloseShiftConfirmation, { class: 'modal-md' });
    this.confirmManualAndKeepSolutionObservable = this.visualiserService.manualAndKeepSolution(this.buildShiftId())
  }

  public closeShift(): void {
    this.visualiserService.closeShift(this.buildShiftId()).subscribe(
      () => {
        this.notifierService.notify('success', this.translate.instant('Shift settings has been changed successfully!'));
        this.modalRef.hide();
      }, 
      () => {
        this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
      }
    );
  }

  /**
   * Building ShiftId: 2020-01-01:1:MORNING
   */
  private buildShiftId(): string {
      return `${this.params.y}-${this.params.m}-${this.params.d}:${this.params.depot}:${this.params.shiftId}`;
  }

  public cutoffFinalization(): string {
    return moment(`${this.cutoff.cutoff}`).add(this.cutoff.cutoffTimeout, 'seconds').format("HH:mm");
  }

  public confirm(): void {
    this.loader = true;
    this.confirmObservable.subscribe(
      () => {
        this.modalRef.hide();
        this.loadSolutions();
        setTimeout(() => this.loader = false, 500);
        this.finalizeLoader = false;
        this.notifierService.notify('success', this.translate.instant('The solution has been promoted as final!'));
        this.confirmObservable = null;
      }, () => {
        this.finalizeLoader = false;
      });
  }

  public confirmPromote(): void {
    this.loader = true;
    this.confirmPromoteObservable.subscribe(
      () => {
        this.modalRef.hide();
        this.loader = true;
        this.loadSolutions();
        setTimeout(() => this.loader = false, 500);
        this.notifierService.notify('success', this.translate.instant('The solution has been promoted!'));
      }, 
      (error: HttpErrorResponse) => {
        this.modalRef.hide();
      });
  }

  public confirmChangedAutoPromoteSolutionSetting(): void {
    this.confirmChangedAutoPromoteSolutionSettingsObservable.subscribe(
      (shift: ShiftSettings) => {
        this.shift = shift;
        this.autoPromoteSolution = this.shift.autoPromoteSolution;
        this.modalRef.hide();
        this.notifierService.notify('success', this.translate.instant('Shift settings has been changed successfully!'));
      },
      (error: HttpErrorResponse) => {
        this.modalRef.hide();
      }
    )
  }

  public confirmAutoFinalizeSolutionSetting(): void {
    this.confirmAutoFinalizeSolutionSettingObservable.subscribe(
      (shift: ShiftSettings) => {
        this.shift = shift;
        this.autoFinalizeSolution = shift.autoFinalizeSolution;
        this.modalRef.hide();
        this.notifierService.notify('success', this.translate.instant('Shift settings has been changed successfully!'));
      },
      (error: HttpErrorResponse) => {
        this.modalRef.hide();
      }
    )
  }

  public confirmCutoffTimeout(): void {
    
    const shift: ShiftExt = DailyShiftConfig.fromShiftSettings(this.shift);
    shift.integration.cutoffTimeout = this.cutoffTimeout;
    
    this.shiftSettingsService.updateIntegration(shift.integration).subscribe(
      (shift: any) => {
        console.log(shift);
        this.shift = shift;
        this.autoFinalizeSolution = shift.autoFinalizeSolution;
        this.modalRef.hide();
        this.notifierService.notify('success', this.translate.instant('Shift settings has been changed successfully!'));
      },
      () => {
        this.modalRef.hide();
      }
    )
  }

  public toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group, this.table);
    this.table.groupHeader.toggleExpandGroup(group);
  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translate, `tooltips.shift.labels.${property}`);
  } 

  public onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  public decline(): void {
    this.confirmObservable = null;
    this.finalizeLoader = false;
    this.modalRef.hide();
  }

  public ngOnDestroy() {
    if (this.routerEvents$) {
      this.routerEvents$.unsubscribe();
    }
  }

}
