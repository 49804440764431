<div class="modal-header">
    <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
</div>

<div class="modal-body text-center">
    <p>
        <strong>{{'This action cannot be undone, is final_Are you sure you want to finalize the solution' |
            translate}}: {{data.shiftId}}?</strong>
    </p>

    <div>
        <strong>{{ 'Active solution' | translate}}:</strong>
        <p class="mg-b-0">{{ 'Errors' | translate }}: {{currentSolution.totalErrors}}</p>
        <p class="mg-b-0">{{ 'Routes' | translate }}: {{currentSolution.totalRoutes}}</p>
        <p>{{ 'Distance' | translate }}: {{currentSolution.totalDistance}}</p>
    </div>

    <div>
        <p *ngIf="betterSolutions.length" class="tx-danger"><strong>
                {{'There are solutions with fewer errors_Number of better solutions' | translate}}:
                {{ betterSolutions.length }}</strong></p>
    </div>

    <div class="mg-b-20">
        <p class="d-block tx-sans tx-10 tx-uppercase tx-color-03 tx-spacing-1 text-left mg-b-0 pd-b-0">
            {{'Please, enter total errors to confirm your choice' | translate}}</p>
        <input [(ngModel)]="confirmationTotalErrors" type="number" class="form-control" />
    </div>

    <button type="button" class="btn btn-brand-01" [disabled]="confirmationTotalErrors !== currentSolution.totalErrors"
        (click)="finalizeLoader = !finalizeLoader; confirm()">
        <span *ngIf="!finalizeLoader">{{'Confirm' | translate}}</span>
        <ng-container *ngIf="finalizeLoader">
            <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i></div>
        </ng-container>
    </button>
    <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
</div>