<div class="modal-header">
    <h4 class="modal-title">
        {{ shift.fulfilment.shiftId.date | date: 'dd.MM.yyyy' }}
        - {{ shift.fulfilment.label | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="limitsForm" class="modal-body">
    <form [formGroup]="limitsForm">
        <!-- Fleet Section -->
        <div formArrayName="vanLimits">
            <div *ngFor="let vanLimit of vanLimits.controls; let i = index" [formGroupName]="i"
                class="form-group d-flex align-items-center">
                <div class="flex-grow-1 mg-r-10">
                    <label>{{ 'Vehicle Type' | translate }} {{ i + 1 }}</label>
                    <input type="text" formControlName="vehicleTypeId" class="form-control"
                        placeholder="Vehicle Type ID">
                </div>
                <div>
                    <label>{{ 'Max Limit' | translate }}</label>
                    <input type="number" formControlName="max" class="form-control"
                        [ngClass]="{ 'is-invalid': vanLimit.get('max')?.invalid && vanLimit.get('max')?.touched }"
                        placeholder="Max Limit">
                </div>
            </div>
        </div>

        <!-- Deliveries Section -->
        <div class="form-group">
            <label for="orderLimits"><strong>{{ 'Maximum deliveries' | translate }}:</strong></label>
            <div class="d-flex align-items-center">
                <label class="switch">
                    <input type="checkbox" formControlName="orderToggle"
                        (change)="toggleField('orderToggle', 'orderLimits')">
                    <span class="slider round"></span>
                </label>
                <input id="orderLimits" *ngIf="!limitsForm.get('orderToggle')?.value" type="number"
                    formControlName="orderLimits" class="form-control"
                    [ngClass]="{ 'is-invalid': limitsForm.get('orderLimits')?.invalid && limitsForm.get('orderLimits')?.touched }" />
                <span *ngIf="limitsForm.get('orderToggle')?.value">{{'Unlimited' | translate}}</span>
            </div>
            <div *ngIf="limitsForm.get('orderLimits')?.invalid && limitsForm.get('orderLimits')?.touched"
                class="invalid-feedback">
                {{'Limit must be greater than 0' | translate}}
            </div>
        </div>

        <!-- Deliveries Section -->
        <div class="form-group">
            <label for="maximumRoutes"><strong>{{ 'Maximum routes' | translate }}:</strong></label>
            <div class="d-flex align-items-center">
                <label class="switch">
                    <input type="checkbox" formControlName="maximumRoutesToggle"
                        (change)="toggleField('maximumRoutesToggle', 'maximumRoutes')">
                    <span class="slider round"></span>
                </label>
                <input id="maximumRoutes" *ngIf="!limitsForm.get('maximumRoutesToggle')?.value" type="number"
                    formControlName="maximumRoutes" class="form-control"
                    [ngClass]="{ 'is-invalid': limitsForm.get('maximumRoutes')?.invalid && limitsForm.get('maximumRoutes')?.touched }" />
                <span *ngIf="limitsForm.get('maximumRoutesToggle')?.value">{{'Unlimited' | translate}}</span>
            </div>
            <div *ngIf="limitsForm.get('maximumRoutes')?.invalid && limitsForm.get('maximumRoutes')?.touched"
                class="invalid-feedback">
                {{'Limit must be greater than 0' | translate}}
            </div>
        </div>


        <!-- Products Linse -->
        <!-- <div class="form-group">
            <label for="maximumProductLines"><strong>{{ 'Products lines' | translate }}:</strong></label>
            <div class="d-flex align-items-center">
                <label class="switch">
                    <input type="checkbox" formControlName="maximumProductLinesToggle"
                        (change)="toggleField('maximumProductLinesToggle', 'maximumProductLines')">
                    <span class="slider round"></span>
                </label>
                <input id="maximumProductLines" *ngIf="!limitsForm.get('maximumProductLinesToggle')?.value"
                    type="number" formControlName="maximumProductLines" class="form-control"
                    [ngClass]="{ 'is-invalid': limitsForm.get('maximumProductLines')?.invalid && limitsForm.get('maximumProductLines')?.touched }" />
                <span *ngIf="limitsForm.get('maximumProductLinesToggle')?.value">{{'Unlimited' | translate}}</span>
            </div>
            <div *ngIf="limitsForm.get('maximumProductLines')?.invalid && limitsForm.get('maximumProductLines')?.touched"
                class="invalid-feedback">
                {{'Limit must be greater than 0' | translate}}
            </div>
        </div> -->

        <p class="text-danger" *ngIf="displayError">{{'errors.' + displayError | translate}}</p>

        <button type="cancel" (click)="cancelled = true; bsModalRef.hide()" class="btn btn-danger">{{ 'Cancel' |
            translate }}</button>
        <button type="submit" (click)="onSubmit()" class="btn btn-brand-01" [disabled]="limitsForm.invalid">
            {{ 'Save' | translate }}
        </button>
    </form>
</div>