import {Component, Input} from "@angular/core";

@Component({
    selector: 'sidebar-with-header',
    template: `
        <div class="card-header">
            <ng-content select="[header]"></ng-content>
        </div>
        <div class="card-body">
            <ng-content select="[content]"></ng-content>
        </div>
    `,
    styleUrls: ['sidebar-with-header.component.scss']
})

export class SidebarWithHeaderComponent {
    @Input() private title;
}
