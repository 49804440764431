import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DateRange, CurrencyType, Client } from '@enums/enum';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { Licensing } from '../../entities/licensing';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsService } from '../../services/payments.service';
import { LicensingRequest } from '../../entities/licensing-request';
import { routeAnimationsLicensing } from '@shared/animations/router.animation';

@Component({
  selector: 'app-licensing',
  templateUrl: './licensing.component.html',
  styleUrls: ['./licensing.component.scss'],
  animations: [routeAnimationsLicensing]
})
export class LicensingComponent implements OnInit, OnDestroy {

  public readonly deliveriesFee: number = 0.35;
  public readonly mobileAppFee: number = 79;
  public readonly usersFee: number = 199;

  public from;
  public to;
  public dateRange: DateRange;

  public mode: string = 'deliveries';

  public licensing: Licensing = new Licensing();
  private licensingSubscription: Subscription;
  public inpostClient: string = Client.INPOST;
  public friscoClient: string = Client.FRISCO;
  
  public get CurrencyType() {
    return CurrencyType;
  }

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private translate: TranslateService,
    private paymentService: PaymentsService,
    private notifierService: NotifierService) { }

  public ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params['from'] && params['to']) {
          this.from = moment(params['from']).format('YYYY-MM-DD');
          this.to = moment(params['to']).format('YYYY-MM-DD');
          this.changeMode(this.mode); 
        }
      });

      setTimeout(() => {
        this.load();
      }, 500)
  }

  public prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
  
  public changedDateRange(event) {
    this.from = moment(event.dateStart).format('YYYY-MM-DD');
    this.to = moment(event.dateEnd).format('YYYY-MM-DD');
    this.dateRange = event.dateRange;
  }

  public load() {
    this.router.navigate(['/payments/licensing'], { queryParams: { from: this.from, to: this.to }, queryParamsHandling: 'merge' });
  }

  private fetchLicensingBilling(): void {
    const body: LicensingRequest = new LicensingRequest(this.from, this.to, this.deliveriesFee, this.usersFee, this.mobileAppFee);
    
    if (!body.isValidDate) {
      if (body.dateTo && body.dateFrom) {
        this.notifierService.notify('warning', this.translate.instant('Date from needs to be before or equal date to'))
      }
      return;
    }

    this.licensingSubscription = this.paymentService.licensing(body)
      .subscribe((licensing: Licensing) => {
        this.licensing = licensing;
        return this.licensing;
      })
  }

  public changeMode(mode: string) {
    this.mode = mode;
    this.router.navigate(
      ['./', mode], 
      {
        relativeTo: this.route,
        queryParams: {from: this.from, to: this.to}, 
        queryParamsHandling: 'preserve', // remove to replace all query params by provided
      });
  }

  public ngOnDestroy() {
    if (this.licensingSubscription) {
      this.licensingSubscription.unsubscribe();
    }
  }
}
