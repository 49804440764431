import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { RouteExt } from '@entities/route-ext';

@Pipe({
    name: 'distancePerRoutesPipe',
    pure: true
})
export class DistancePerRoutesPipe implements PipeTransform {
    public transform(distances: Array<any>, optimizeRoute: OptimizeRoutes): string {
        const routes: number = optimizeRoute.routes.filter((r: RouteExt) => r.deliveriesCount).length;
        if (distances.length) {
            return (_.sumBy(distances, r => r.totalDistance) / routes / 1000).toFixed(2) + ' km';
        }
    }
}
