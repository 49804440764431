
        <div class="picker" *ngIf="dropdownList.length">
            <select id="data-range-select-range" class="custom-select" (change)="change()" [(ngModel)]="selectedRange">
                <option *ngFor="let range of dateRangeOptions" [value]="range">{{ range | translate }}</option>
            </select>

            <div>
                <label>{{ 'From' | translate }}:</label>
            </div>
            <ng-datepicker id="data-range-datepicker-from" *ngIf="locale" #datepicker class="datepicker-input datepicker-from" [(ngModel)]="startDate" [options]="datePickerOptions"></ng-datepicker>
            <div>
                <label>{{ 'To' | translate }}:</label>
            </div>
            <ng-datepicker id="data-range-datepicker-to"  *ngIf="locale" #datepicker class="datepicker-input datepicker-to" [(ngModel)]="endDate" [options]="datePickerOptions"></ng-datepicker>

            <ng-container *ngIf="extendedOption">
                <ng-multiselect-dropdown
                    id="data-range-select-days-of-week"
                    class="form-control"
                    [style.width.%]="49"
                    [placeholder]="'Select days of week' | translate"
                    [data]="dropdownList"
                    [(ngModel)]="selectedItems"
                    [settings]="dropdownSettings"
                    (onSelect)="onItemSelect()"
                    (onSelectAll)="onSelectAll()"
                    (onDeSelect)="onDeSelect()"
                >
                </ng-multiselect-dropdown>

                <div>
                    <label>{{ 'Shift' | translate }}:</label>
                </div>
                <select id="data-range-select-shift" class="custom-select" (change)="change()" [(ngModel)]="selectedShift">
                    <option *ngFor="let shift of ShiftTypes" [value]="shift">{{ shift | translate }}</option>
                    <option [value]="spreadShiftTypes">{{ 'All Shifts' | translate }}</option>
                </select>

                <div>
                    <label>{{ 'Map' | translate }}:</label>
                </div>
                <select id="data-range-select-map" class="custom-select" (change)="changeMapType()" [(ngModel)]="selectedMapType">
                    <option [value]="'HEATMAP'">{{ 'HEATMAP' | translate }}</option>
                    <option [value]="'CLUSTER_HISTORY'">{{ 'CLUSTER_HISTORY' | translate }}</option>
                </select>
            </ng-container>
        </div>
    