import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { plainToClass } from 'class-transformer';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DatepickerOptions } from 'ng2-datepicker';
import { Subscription } from 'rxjs';

import { DayComposite } from '@calendar/entities/dayComposite';
import { MonthlyShiftConfig } from '@calendar/entities/monthlyShiftConfig';
import { ShiftType, Warehouse } from '@enums/enum';
import { ShiftsService } from '@shared/services/shifts.service';

import { Simulation } from '../entities/simulation';
import { SimulatorService } from '../services/simulator.service';

@Component({
    selector: 'app-simulation-create',
    template: `
        <form *ngIf="form" [formGroup]="form">
            <div class="row">
                <ng-container formGroupName="simulationShiftConfigDto">
                    <ng-container formGroupName="shiftId">
                        <div class="form-group col-4">
                            <label for="date">{{ 'Date' | translate }}:</label>
                            <ng-datepicker class="datepicker-input" formControlName="date" [options]="options"></ng-datepicker>
                        </div>

                        <div class="form-group col-2">
                            <label for="type">{{ 'Shift type' | translate }}:</label>
                            <select class="form-control" formControlName="type">
                                <option *ngFor="let option of shiftType" [value]="option">{{ option | translate }}</option>
                            </select>
                        </div>

                        <div class="form-group col-2">
                            <label for="warehouse">{{ 'Warehouse' | translate }}:</label>
                            <select class="form-control" formControlName="warehouse">
                                <option value="SIM">{{ 'SIM' | translate }}</option>
                            </select>
                        </div>
                    </ng-container>

                    <div class="form-group col-2">
                        <label for="fleetSize">{{ 'Fleet size' | translate }}:</label>
                        <input type="number" min="1" formControlName="fleetSize" class="form-control" />
                    </div>

                    <div class="form-group col-2">
                        <label for="ordersLimit">{{ 'Orders limit' | translate }}:</label>
                        <input type="number" min="1" formControlName="ordersLimit" class="form-control" />
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <ng-container formGroupName="simulationShiftConfigDto">
                    <div class="form-group col-4">
                        <label for="recalculateFrequency">{{ 'Recalculate Frequency' | translate }}:</label>
                        <input type="number" min="1" formControlName="recalculateFrequency" class="form-control" />
                    </div>

                    <div class="form-group col-4">
                        <label for="shiftStartMarginMinutes">{{ 'Shift start margin minutes' | translate }}:</label>
                        <input type="number" min="1" formControlName="shiftStartMarginMinutes" class="form-control" />
                    </div>

                    <div class="form-group col-2">
                        <label for="slotEndMarginMinutes">{{ 'Slot end margin minutes' | translate }}:</label>
                        <input type="number" min="1" formControlName="slotEndMarginMinutes" class="form-control" />
                    </div>

                    <div class="form-group col-2">
                        <label for="slotStartMarginMinutes">{{ 'Slot start margin minutes' | translate }}:</label>
                        <input type="number" min="1" formControlName="slotStartMarginMinutes" class="form-control" />
                    </div>
                </ng-container>
            </div>

            <div class="row justify-content-end">
                <ng-template [ngIf]="isSetSimulation" [ngIfElse]="create" type="submit">
                    <button (click)="loadMonthConfiguration()" class="col-3 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{ 'Advanced settings' | translate }}</button>

                    <button (click)="update()" [disabled]="!valid" class="col-3 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{ 'Update simulation' | translate }}</button>
                </ng-template>

                <ng-template #create>
                    <button (click)="onSubmit()" [disabled]="!valid" type="submit" class="col-3 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{ 'Create simulation' | translate }}</button>
                </ng-template>
            </div>
        </form>

       <!-- <ng-sidebar-container [style.z-index]="_sidebarZIndex" [style.display]="_opened ? 'block' : 'none'">
            <ng-sidebar
                ng-sidebar--right
                [(opened)]="_opened"
                [position]="'right'"
                [autoCollapseHeight]="true"
                [autoCollapseWidth]="true"
                [closeOnClickOutside]="false"
                [closeOnClickBackdrop]="true"
                [showBackdrop]="false"
            >
                <div class="sidebar-header">
                    <h6 class="tx-uppercase">{{ 'Shift configuration' | translate }}</h6>
                    <button class="btn btn-xs btn-uppercase btn-white" (click)="_toggleSidebar()">Close</button>
                </div>

                <day-details *ngIf="_opened" [dayComposite]="selectedDay" [selectedShift]="selectedShift" [calculateTempResult]="false" (dayCompositeChanged)="onDayCompositeChanged($event)">
                </day-details>
            </ng-sidebar>

            <div ng-sidebar-content></div>
        </ng-sidebar-container>-->
    `,
    styleUrls: ['./create-simulation.component.scss']
})
export class CreateSimulationComponent implements OnInit {
    @Output() public addedSimulation: EventEmitter<Simulation> = new EventEmitter();
    @Input() public simulationId: string;

    @Input() set datasetId(datasetId) {
        this._datasetId = datasetId;
        if (this.form) {
            this.form.controls['datasetId'].setValue(datasetId);
        }
    }

    get isSetSimulation(): boolean {
        return this.simulationId !== null;
    }

    get shiftType(): Array<string> {
        return _.values(ShiftType);
    }

    get warehouse(): Array<string> {
        return _.values(Warehouse);
    }

    get valid(): boolean {
        return this.form.valid;
    }

    get _sidebarZIndex() {
        return this._opened ? 100 : -1;
    }
    private __selectedMonthShiftConfig$: Subscription;

    public _opened: boolean = false;
    public _datasetId: string;
    public form: FormGroup;
    public submitted: boolean = false;
    public locale;
    public simulation: Simulation;
    public selectedDay: DayComposite = null;
    public selectedShift = ShiftType.MORNING;
    public options: DatepickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'D MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1
    };

    constructor(
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private simulatorService: SimulatorService,
        private notifierService: NotifierService,
        private shiftsService: ShiftsService
    ) {
        this.locale = this.translate.currentLang === 'en' ? enLocale : plLocale;
        this.options.locale = this.locale;
    }

    public ngOnInit() {
        if (this.simulationId === null) {
            this.prepareForm();
        } else {
            this.getSimulation();
        }

        this.__selectedMonthShiftConfig$ = this.shiftsService.selectedMonthShiftConfig.subscribe((monthlyShiftConfig: MonthlyShiftConfig) => this.loadDataToShiftConfiguration(monthlyShiftConfig));
    }

    public getSimulation(): void {
        this.simulatorService.getSimulation(this.simulationId).subscribe((simulation: Simulation) => {
            this.simulation = simulation;
            this.prepareForm();
        });
    }

    public prepareForm(): void {
        this.form = this.formBuilder.group({
            datasetId: [this.isSetSimulation ? this.simulation.datasetId : this._datasetId, Validators.required],
            simulationId: [this.isSetSimulation ? this.simulation.simulationId : null],
            simulationShiftConfigDto: this.formBuilder.group({
                shiftId: this.formBuilder.group({
                    date: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.date : new Date(), Validators.required],
                    type: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.shift : ShiftType.MORNING, Validators.required],
                    warehouse: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.warehouse : Warehouse.SIM, Validators.required]
                }),
                fleetSize: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.fleetSize : 1, Validators.required],
                recalculateFrequency: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.recalculateFrequency : 1, Validators.required],
                ordersLimit: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.ordersLimit : 1, Validators.required],
                shiftStartMarginMinutes: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.shiftStartMarginMinutes : null, Validators.required],
                slotEndMarginMinutes: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.slotEndMarginMinutes : null, Validators.required],
                slotStartMarginMinutes: [this.isSetSimulation ? this.simulation.simulationShiftConfigDto.slotStartMarginMinutes : null, Validators.required]
            })
        });
    }

    public onSubmit() {
        if (!this.form.valid) {
            return;
        }

        this.submitted = true;
        const raw: Simulation = plainToClass(Simulation, this.form.getRawValue() as Simulation);

        raw.simulationShiftConfigDto.shiftId['date'] = moment(raw.simulationShiftConfigDto.shiftId['date']).format('YYYY-MM-DD');
        delete raw.simulationId;

        this.simulatorService.createSimulation(raw).subscribe(
            (simulation: Simulation) => {
                this.addedSimulation.emit(simulation);
                this.notifierService.notify('success', this.translate.instant('Simulation has been created'));
            },
            () => {},
            () => (this.submitted = false)
        );
    }

    public update() {
        if (!this.form.valid) {
            return;
        }

        this.submitted = true;
        const rawSimulationConfig = this.form.getRawValue();

        this.simulatorService.updateSimulation(rawSimulationConfig).subscribe(
            (simulation: Simulation) => {
                this.addedSimulation.emit(simulation);
                this.notifierService.notify('success', this.translate.instant('Simulation has been updated'));
            },
            () => {},
            () => (this.submitted = false)
        );
    }

    public _toggleSidebar() {
        this._opened = !this._opened;
    }

    public loadMonthConfiguration() {
        const warehouse = localStorage.getItem('depot');
        this.shiftsService.loadMonthConfiguration(this.simulation.simulationShiftConfigDto.date, warehouse, false);
    }

    public loadDataToShiftConfiguration = (monthlyShiftConfig: MonthlyShiftConfig) => {
        if (monthlyShiftConfig) {
            this.selectedDay = monthlyShiftConfig.getDay(this.simulation.simulationShiftConfigDto.date);
            this.selectedShift = <ShiftType>this.simulation.simulationShiftConfigDto.shift;
            this._toggleSidebar();
        }
    };
}
