import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrintableLandscapeComponent } from '@shared/components/printable-landscape.component';
import { DriverEditComponent } from './components/driver-edit.component';
import { DriversAvailabilityComponent } from './components/drivers-availability.component';
import { DriversBarcodesComponent } from './components/drivers-barcodes.component';
import { DriversComponent } from './components/drivers.component';
import { PrintDriversScheduleComponent } from './components/print-drivers-schedule.component';
import { PermissionGuardService } from '@guards/permission.guard';
import { ProxyComponent } from '@shared/components/proxy.component';
import * as P from '@enums/permissions';
import { environment } from '@environment';
import { Client } from '@enums/enum';

const driversRoutes: Routes = [

    {
        path: '',
        component: ProxyComponent,
        data: {
            expectedPermission: [P.Permissions.Driver_view],
            breadcrumb: environment.client === Client.FRISCO ? 'Breadcrumbs.Drivers' : 'Breadcrumbs.Couriers'
        },
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full'},

            {
                path: 'list', component: DriversComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_view],
                    breadcrumb: environment.client === Client.FRISCO ? 'Breadcrumbs.DriversList' : 'Breadcrumbs.CouriersList'
                }
            },
            {
                path: 'create', component: DriverEditComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_edit, P.Permissions.Driver_view],
                    breadcrumb: environment.client === Client.FRISCO ? 'Breadcrumbs.DriversCreate' : 'Breadcrumbs.CouriersCreate'
                }
            },
            {
                path: ':driverId/:action', component: DriverEditComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_edit, P.Permissions.Driver_view],
                    breadcrumb: environment.client === Client.FRISCO ? 'Breadcrumbs.DriversEdit' : 'Breadcrumbs.CouriersEdit'
                }
            },
            {
                path: 'drivers-barcodes', component: DriversBarcodesComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_view],
                    breadcrumb: 'Breadcrumbs.Barcodes'
                }
            },
            {
                path: 'availability', component: DriversAvailabilityComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_planning_view],
                    breadcrumb: environment.client === Client.FRISCO ? 'Breadcrumbs.DriversAvailability' : 'Breadcrumbs.CouriersAvailability'
                }
            },
            {
                path: 'print-l',
                component: PrintableLandscapeComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_planning_view],
                    breadcrumb: environment.client === Client.FRISCO ? 'Breadcrumbs.DriversSchedulePrint' : 'Breadcrumbs.CouriersSchedulePrint'
                },
                children: [{ path: 'drivers-schedule/print', component: PrintDriversScheduleComponent }]
            },
        
            { path: '**', redirectTo: 'list' }
        ]
    }
   

];

@NgModule({
    imports: [RouterModule.forChild(driversRoutes)],
    exports: [RouterModule],
    providers: []
})
export class DriversRouting {}
