import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeFormatterPipe',
  pure: true
})
export class TimeFormatterPipe implements PipeTransform {
  public transform(time: string, format: string): any {
    return moment(time).format(format);
  }
}
