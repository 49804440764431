import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserPanel } from '../entities/user-panel';
import { environment } from '@environment';
import { UtilsService } from '@services/utils.service';
import { DeliveriesSearchResponse } from '@entities/deliveries-search-response';
import { PageableResponse } from '@entities/pagable-response';
import { Customer } from '@entities/customer';

@Injectable()
export class UsersService {
    public static USERS: string = 'user/v1';
    public static GET_USER: string = 'user/v1/${id}';
    public static UPDATE_USER: string = 'user/v1/${id}';
    public static DELETE_USER: string = 'user/v1/${id}';
    public static MAKE_USER_SYADMIN: string = 'user/v1/${id}/sysadmin';
    public static SEND_TEST_SMS: string = 'user/v1/${id}/testSms';

    private static SEARCH_CUSTOMER: string = 'customer/v2/search';

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(
        private http: HttpClient,
        private readonly utilsService: UtilsService
        ) {}

    public getUsers(): Observable<UserPanel[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.USERS}`, {});

        return this.http
            .get(endpoint)
            .pipe(map(response => plainToClass(UserPanel, response as Object[])))
            .pipe(map((response: UserPanel[]) => response));
    }

    public getUser(id: string): Observable<UserPanel> {
        if (!id) {
            throw new Error('Empty UserId');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.GET_USER}`, { id: id });

        return this.http
            .get(endpoint)
            .pipe(map(response => plainToClass(UserPanel, response)))
            .pipe(map(user => user));
    }

    public create(user): Observable<UserPanel> {
        const endpoint = `${this.host}${this.prefix}/${UsersService.USERS}`;
        return this.http
            .post(endpoint, user)
            .pipe(map(response => plainToClass(UserPanel, response)))
            .pipe(map(response => response));
    }

    public update(user): Observable<UserPanel> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.UPDATE_USER}`, { id: user['id'] });

        return this.http
            .put(endpoint, user)
            .pipe(map(response => response))
            .pipe(map(response => plainToClass(UserPanel, response)))
            .pipe(
                map((response: UserPanel) => {
                    this.getUsers();
                    return response;
                })
            );
    }

    public makeUserSysadmin(userId: string): Observable<UserPanel> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.MAKE_USER_SYADMIN}`, { id: userId });
        return this.http.put(endpoint, null)
            .pipe(map(response => plainToClass(UserPanel, response)))
            .pipe(map(response => response));
    }

    public removeRoleSysadminForUser(userId: string): Observable<UserPanel> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.MAKE_USER_SYADMIN}`, { id: userId });
        return this.http.delete(endpoint)
            .pipe(map(response => plainToClass(UserPanel, response)))
            .pipe(map(response => response));
    }

    public sendTestSmsNotification(id: string): Observable<UserPanel> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.SEND_TEST_SMS}`, { id });
        return this.http.post<UserPanel>(endpoint, undefined);
    }

    public searchCustomer(phrase: string, type: string = 'CUSTOMER_REF'): Observable<Customer[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.SEARCH_CUSTOMER}?q=${phrase}&size=1000`, {});
        return this.http.get(endpoint).pipe(
            map((data: PageableResponse<Customer>) => {
                return data.content;
            })
        );
    }


    public delete(userId: string): Observable<boolean> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${UsersService.DELETE_USER}`, { id: userId });

        return this.http
            .delete(endpoint)
            .pipe(map(response => plainToClass(UserPanel, response)))
            .pipe(map(() => this.getUsers()))
            .pipe(map(() => true));
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
