
    <layout-default>
        <div header>
            <div toolbar>
                <h4>
                    <i-feather name="user-plus"></i-feather> {{'Agency' | translate}}
                </h4>
            </div>
    
            <div toolbar>
                <button class="btn btn-sm btn-uppercase btn-white"
                    [routerLink]="['/salary/agency/create']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add agency' | translate }}
                </button>
                <button class="btn btn-sm btn-uppercase btn-white"
                    [routerLink]="['/salary/report/create']">
                    <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="file-minus"></i-feather> {{ 'Salary reports' | translate }}
                </button>
                <div class="filter-container">
                    <div class="phrase-filter">
                        <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        
        <div content>
            <!-- <div *ngIf="!loader"> -->
                <div *ngIf="(agencySubscriptions$ | async) as collectionAgencies">
                    <div *ngIf="collectionAgencies.totalElements ; else notFound">
                        <ngx-datatable 
                            id="agencies" 
                            class="bootstrap" 
                            [rows]="agencies" 
                            [columns]="columns" 
                            [columnMode]="'flex'"
                            [headerHeight]="50" 
                            [footerHeight]="50" 
                            [rowHeight]="'auto'" 
                            [externalPaging]="true"
                            [count]="collectionAgencies.totalElements" 
                            [offset]="queryParams.page" 
                            [limit]="queryParams.size" 
                            [scrollbarH]="scrollBarHorizontal"
                            [externalSorting]="true"
                            [rowClass]="rowClass"
                            (sort)="onSort($event)"
                            (page)="setPage($event)">

                            <ng-container *ngFor="let col of columns">
                                <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                                    <ng-template let-column="column" class="header" let-sort="sortFn" ngx-datatable-header-template>
                                        <span class="tx-bold " (click)="sort($event)">{{col.name | translate}}</span>
                                    </ng-template>
                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    

                                        <ng-container [ngSwitch]="col.prop">
                                            <div *ngSwitchCase="'name'">
                                                {{ value }}
                                            </div>
                                            <div *ngSwitchCase="'driverIdSet.length'">
                                                {{ value }}
                                            </div>
                                            <div *ngSwitchCase="'options'">
                                                <div class="dropdown" dropdown>
                                                    <button
                                                        class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton">
                                                        {{ 'Select' | translate }}
                                                    </button>
                
                                                    <div class="dropdown-menu dropdown-menu-right">
            
                                                        <a 
                                                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                            (click)="goToDetails(row.id)">
                                                                {{'Edit' | translate }}
                                                        </a>

                                                        <a 
                                                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                            [routerLink]="['../', row.id, 'assigning-drivers']">
                                                                {{'Assign drivers' | translate }}
                                                        </a>
                
                                                        <a 
                                                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                            (click)="removeAgency(row.id)">
                                                                {{'Remove' | translate }}
                                                        </a> 
                
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngSwitchDefault>
                                                {{ value | number:'.2' }} {{'PLN' | translate}}
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ng-container>
                        </ngx-datatable>
                    </div>

                    <ng-template #notFound>
                        <section class="empty-list">
                        <figure>
                            <figcaption>
                                <p class="text-center tx-12 pd-30"><i-feather name="alert-circle"></i-feather>&nbsp;{{'No agencies found' | translate}}!</p>
                            </figcaption>
                        </figure>
                        </section>
                    </ng-template>
                <!-- </div> -->
            </div>
            <div *ngIf="loader" class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>&nbsp;{{ 'Please, wait!' | translate }}
            </div>
        </div>
        
        
    </layout-default>


<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm removal of the agency' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>