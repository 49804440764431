import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ReportFile } from '../../interfaces/report-file';
import { SalaryReportService } from '../../services/salary-report.service';

@Component({
  selector: 'app-report-files',
  templateUrl: './report-files.component.html',
  styleUrls: ['./report-files.component.scss']
})
export class ReportFilesComponent implements OnInit {

  public files: ReportFile;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly salaryReportService: SalaryReportService
  ) { }

  ngOnInit() {
    this.files = this.activatedRoute.snapshot.data.salaryReportFiles;
    console.log(this.files);
  }

  print(file: ReportFile) {
    this.salaryReportService.fetchReportFile(file.reportId, file.key).subscribe(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = `${file.driverLabel}.pdf`;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();    
      a.remove();
    });
  }

  view(file: ReportFile) {
    this.salaryReportService.fetchReportFile(file.reportId, file.key).subscribe(blob => {
      //var file = new Blob(blob, {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
    
  }

}
