<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode != 'create'" class="text-left mg-b-0 tx-spacing--1"><i-feather name="edit-3"></i-feather>
                {{'Edit user' | translate}}</h4>
            <h4 *ngIf="mode == 'create'" class="text-left mg-b-0 tx-spacing--1"><i-feather
                    name="plus-square"></i-feather> {{'Add user' | translate}}</h4>
        </div>
        <div toolbar>
            <button id="user-add-btn-cancel" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-white"
                *ngIf="mode == 'edit'" [routerLink]="['/users']">{{'Cancel' | translate}}
            </button>

            <button id="user-add-btn-cancel" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-white"
                *ngIf="mode == 'create'" [routerLink]="['/users']">{{'Cancel' | translate}}
            </button>

            <a id="user-add-btn-edit" *ngIf="mode == 'show'"
                class="btn btn-brand-01 btn-sm pd-x-15 btn-uppercase mg-l-5"
                [routerLink]="['/user/' + this.van.id + '/edit']">{{"Edit" | translate}}
            </a>
        </div>
    </div>
    <div content>
        <div *ngIf="!formLoader" class="content bg-white">


            <div class="card-content col-8 offset-2">

                <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">

                    <div id="user-add-form-username" class="form-group row">
                        <label class="col-3" for="username">{{'Email address' | translate}}:</label>
                        <div class="col-9">
                            <input formControlName="username" class="form-control" />
                            <div *ngIf="form.get('username').hasError('required') && form.get('username').touched"
                                class="error-color">
                                {{ "Username number is required" | translate }}!
                            </div>
                            <div *ngIf="form.get('username').hasError('pattern') && form.get('username').touched"
                                class="error-color">
                                {{ "Email address is incorrect" | translate }}!
                            </div>

                        </div>
                    </div>

                    <div id="user-add-form-firstName" class="form-group row">
                        <label class="col-3" for="firstName">{{'Firstname' | translate}}:</label>
                        <div class="col-9">
                            <input formControlName="firstName" class="form-control" />
                            <div *ngIf="form.get('firstName').hasError('required') && form.get('firstName').touched"
                                class="error-color">
                                {{ "Username number is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="user-add-form-lastName" class="form-group row">
                        <label class="col-3" for="lastName">{{'lastname' | translate}}:</label>
                        <div class="col-9">
                            <input formControlName="lastName" class="form-control" autocomplete="new-lastName" />
                            <div *ngIf="form.get('lastName').hasError('required') && form.get('lastName').touched"
                                class="error-color">
                                {{ "Username number is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="user-add-form-passwordHash" class="form-group row">
                        <label class="col-3" for="passwordHash">{{'Password' | translate}}:</label>
                        <div class="col-9">
                            <input type="password" formControlName="passwordHash" class="form-control"
                                autocomplete="new-password" />
                            <div *ngIf="form.get('passwordHash').hasError('required') && form.get('passwordHash').touched"
                                class="error-color">
                                {{ "Password is required" | translate }}!
                            </div>
                            <div *ngIf="form.get('passwordHash')?.hasError('minlength') && form.get('passwordHash')?.touched"
                                class="error-color">
                                {{ "The password should contain at least 8 characters" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="user-add-form-phoneNumber" class="form-group row">
                        <label class="col-3" for="phoneNumber">
                            {{'phoneNumer' | translate}}:
                            <span *ngIf="form.get('phoneNumber') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('phoneNumber')" [containerClass]="'pretty-tooltip'"
                                [tooltip]="tooltipLabel('phoneNumber')" [style.width.px]="13" [style.margin-left.px]="6"
                                name="info"></i-feather>
                        </label>
                        <div class="col-9">
                            <input formControlName="phoneNumber" [dropSpecialCharacters]="false" mask="+00000000000"
                                class="form-control" autocomplete="new-lastName" />
                            <div *ngIf="form.get('phoneNumber').hasError('required') && form.get('phoneNumber').touched"
                                class="error-color">
                                {{ "Phone number is required" | translate }}!
                            </div>
                        </div>
                    </div>

                    <div id="user-add-form-receiveAlerts" class="form-group row">
                        <label class="col-3" for="receiveAlerts">
                            {{'Receive alerts' | translate}}:
                            <span *ngIf="form.get('receiveAlerts') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('receiveAlerts')" [containerClass]="'pretty-tooltip'"
                                [tooltip]="tooltipLabel('receiveAlerts')" [style.width.px]="13" name="info"></i-feather>
                        </label>
                        <div class="col-9">
                            <div class="custom-control custom-checkbox pd-b-10 w-50">
                                <input formControlName="receiveAlerts" type="checkbox" class="custom-control-input"
                                    id="customCheck-receiveAlerts">
                                <label class="custom-control-label justify-content-start"
                                    for="customCheck-receiveAlerts">&nbsp;</label>
                            </div>
                        </div>
                    </div>

                    <div id="user-add-form-receiveEscalations" class="form-group row">
                        <label class="col-3" for="receiveEscalations">
                            {{'Receive escalations' | translate}}:
                            <span *ngIf="form.get('receiveEscalations') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('receiveEscalations')" [containerClass]="'pretty-tooltip'"
                                [tooltip]="tooltipLabel('receiveEscalations')" [style.width.px]="13"
                                name="info"></i-feather>
                        </label>
                        <div class="col-9">
                            <div class="custom-control custom-checkbox pd-b-10 w-50">
                                <input formControlName="receiveEscalations" type="checkbox" class="custom-control-input"
                                    id="customCheck-receiveEscalations">
                                <label class="custom-control-label justify-content-start"
                                    for="customCheck-receiveEscalations">&nbsp;</label>
                            </div>
                        </div>
                    </div>

                    <div id="user-add-form-dynamicRoles" class="form-group row">
                        <label class="col-3" for="dynamicRoles">{{'Dynamic roles' | translate}}:</label>
                        <div class="col-9 d-flex flex-wrap">
                            <ng-container formArrayName="dynamicRoles">
                                <ng-container *ngFor="let p of form.controls.dynamicRoles.controls; let i=index">

                                    <div formGroupName="{{i}}" class="custom-control custom-checkbox pd-b-10 w-50">
                                        <input formControlName="enabled" [checked]="p.value.enabled" type="checkbox"
                                            class="custom-control-input" id="customCheck{{i}}">
                                        <label class="custom-control-label justify-content-start"
                                            for="customCheck{{i}}">{{p.value.role.name | translate}}</label>
                                    </div>

                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="form-group row justify-content-end">
                        <div class="col-2">
                            <button id="user-add-form-submit" [disabled]="!form.valid || submitted || disabled"
                                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">

                                <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>

                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</layout-default>

<!--
{{ 'ROLE_SYSADMIN' | translate }}
{{ 'ROLE_DISPATCHER' | translate }}
{{ 'ROLE_CUSTOMER_CARE' | translate }}
{{ 'ROLE_WAREHOUSE' | translate }}
-->

<!-- {{'tooltips.user.labels.phoneNumber' | translate }} -->
<!-- {{'tooltips.user.labels.receiveAlerts' | translate }} -->
<!-- {{'tooltips.user.labels.receiveEscalations' | translate }} -->