
    <!--<ng-http-loader 
        [backdrop]="false"
        [backgroundColor]="'#ffcd00'"
        [debounceDelay]="2000"
        [extraDuration]="300"
        [minDuration]="300"
        [opacity]=".6"
        [spinner]="spinkit.skChasingDots">
    </ng-http-loader>-->
    <router-outlet></router-outlet>