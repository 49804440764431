{{mode}}

<ng-container *ngIf="!loader && optimizeResult">
        <ng-container *ngFor="let optimizeRoute of optimizeResult.routes">
            
            <p class="tx-12 tx-bold tx-spacing-1 text-center tx-color-01 tx-uppercase tx-sans mg-t-10 mg-b-20">{{'Solution name' | translate}}: {{ optimizeRoute.label }}</p>
    
            <div class="legend">
                <table class="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{ 'Routes' | translate }}</th>
                            <th>
                                <div>{{ 'Deliveries' | translate }}</div>
                            </th>
                            <th>
                                <div>{{ 'Distance' | translate }}</div>
                            </th>
                            <th>
                                <div>{{ 'Punctuality' | translate }}</div>
                                <div class="split-header">
                                    <div>{{ 'Early' | translate }}</div>
                                    <div>{{ 'Late' | translate }}</div>
                                </div>
                            </th>
                            <th>
                                <div>{{ 'Driving time' | translate }}</div>
                            </th>
                            <th>
                                <div>{{ 'Boxes' | translate }}</div>
                                <div class="split-header">
                                    <div>{{ 'Total' | translate }}</div>
                                    <div>{{ 'Dry' | translate }}</div>
                                    <div>{{ 'Chilled' | translate }}</div>
                                </div>
                            </th>
                            <th appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]">Unoptimized</th>
                            <th>{{'Cuttoff complete' | translate}}</th>
                            <th width="16%">
                                {{'Maps' | translate}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{'Shift' | translate}}</td>
                            <td>{{ optimizeRoute | countRealRoutesPipe }}</td>
                            <td>
                                <div class="split-header">
                                    <div>{{ optimizeRoute.totalDeliveries }}</div>
                                </div>
                            </td>
                            <td>
                                <div class="split-header">
                                    <div>
                                        <span *ngIf="distances && optimizeRoute.totalRoutes">{{ distances[optimizeRoute.runtime] | totalDistancePipe }}</span>
                                        <div *ngIf="!distances && optimizeRoute.totalRoutes" class="load-spinner spinner">
                                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="split-header">
                                    <div>{{ optimizeRoute.totalEarlyMarginDeliveries }}</div>
                                    <div>{{ optimizeRoute.totalLateMarginDeliveries }}</div>
                                </div>
                            </td>
                            <td>
                                <div class="split-header">
                                    <div>{{ secToHours(optimizeRoute.totalDrivingTime) }}</div>
                                </div>
                            </td>
                            <td>
                                <div class="split-header">
                                    <div>{{ optimizeRoute.boxesSum }}</div>
                                    <div>{{ optimizeRoute.totalBoxes['dryBoxes'] }}</div>
                                    <div>{{ optimizeRoute.totalBoxes['chilledBoxes'] }}</div>
                                </div>
                            </td>
    
                            <td appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]">
                                {{ optimizeRoute.totalErrors }}
                            </td>
                            <td>
                                <ng-container>
                                    <div *ngIf="shiftStatusChanged | findByStatusShiftPipe; let state; else noData">
                                        <span *ngIf="state">{{state.occurredAt | utcToLocalTimePipe | amLocale: locale | amDateFormat: 'D MMMM YYYY, HH:mm:ss'}}</span>
                                    </div>
                                    <ng-template #noData><span>{{'No data available' | translate}}</span></ng-template>
                                </ng-container>
                            </td>
                            <td>
                                <div class="split-header">
                                    <button (click)="openShiftMap('HEATMAP', shiftMap)" class="btn btn-xs btn-uppercase btn-white">HEATMAP</button>
                                    <button (click)="openShiftMap('CLUSTER', shiftMap)" class="btn btn-xs btn-uppercase btn-white">CLUSTER MAP</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                                <td>{{'Route' | translate}}</td>
                                <td></td>
                                <td>
                                    <div class="split-header">
                                        <div>{{ optimizeRoute | deliveriesPerVansPipe}}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="split-header">
                                        <div>
                                            <span *ngIf="distances && optimizeRoute.totalRoutes">{{ distances[optimizeRoute.runtime] | distancePerRoutesPipe:optimizeRoute }}</span>
                                            <div *ngIf="!distances && optimizeRoute.totalRoutes" class="load-spinner spinner">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="split-header">
                                        <div>{{ optimizeRoute | dividePerVansPipe:optimizeRoute.totalEarlyMarginDeliveries }}</div>
                                        <div>{{ optimizeRoute | dividePerVansPipe:optimizeRoute.totalLateMarginDeliveries }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="split-header">
                                        <div>{{ optimizeRoute | drivingTimePerRoutesPipe }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="split-header">
                                        <div>{{ optimizeRoute | dividePerVansPipe:optimizeRoute.boxesSum }}</div>
                                        <div>{{ optimizeRoute | dividePerVansPipe:optimizeRoute.totalBoxes['dryBoxes'] }}</div>
                                        <div>{{ optimizeRoute | dividePerVansPipe:optimizeRoute.totalBoxes['chilledBoxes'] }}</div>
                                    </div>
                                </td>
                                <td appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{{'Delivery' | translate}}</td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div class="split-header">
                                        <div>
                                            <span *ngIf="distances && optimizeRoute.totalRoutes">{{ distances[optimizeRoute.runtime] | distancePerDeliveryPipe:optimizeRoute.totalDeliveries }}</span>
                                            <div *ngIf="!distances && optimizeRoute.totalRoutes" class="load-spinner spinner">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <div class="split-header">
                                        <div>{{ optimizeRoute | drivingTimePerDeliveryPipe }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div class="split-header">
                                        <div>{{ (optimizeRoute.boxesSum / optimizeRoute.totalDeliveries).toFixed(2)}}</div>
                                        <div>{{ (optimizeRoute.totalBoxes['dryBoxes'] / optimizeRoute.totalDeliveries).toFixed(2)}}</div>
                                        <div>{{ (optimizeRoute.totalBoxes['chilledBoxes'] / optimizeRoute.totalDeliveries).toFixed(2)}}</div>
                                    </div>
                                </td>
                                <td appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]"></td>
                                <td></td>
                                <td></td>
                            </tr>
                    </tbody>
                </table>
            </div>
    
            <!-- <div class="header">
                <span [ngClass]="{ header__errors: optimizeRoute.totalErrors }" class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{ optimizeRoute.label | translate }}:</span>
            </div> -->
    
            <div class="bars mg-t-30">
                <div class="route slots-bg">
                    <div class="bar-legend"></div>
                    <div class="bars__bar">
                        <div [style.flex]="16" class="bars__bar__activity no-border">
                            <div *ngFor="let i of shiftHours(optimizeResult.shift); let index = index" [style.flex]="1" class="slot__line text-center">
                                <div class="slot__container">
                                    <div class="slot__inner-wrapper">
                                        <button (click)="openMapGroupedBySlot(index, mapSlotsTemplate)" class="slot__map-button btn btn-xs btn-white">
                                            <i-feather name="map-pin" class="slot__map-icon"></i-feather>
                                        </button>
                                        <label class="slot__label">{{ i }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bar-stats"></div>
                </div>
    
                <div *ngFor="let route of optimizeRoute.routes; let index = index" class="route route-line">
                    <ng-container *ngIf="route.deliveriesCount">
                        <div class="bar-legend">
                            <strong (click)="openMap(route, mapTemplate)">{{ route.routeNumber }}</strong>
                        </div>
    
                        <ng-container *ngTemplateOutlet="bar; context: { route: route }"></ng-container>
    
                        <div class="bar-stats">
                            <div class="bars__wrapper">
                                <button type="button" class="route__button btn btn-xs btn-white" (click)="openMap(route, mapTemplate)">
                                    <i-feather name="map-pin" class="slot__map-icon"></i-feather>
                                </button>
                                <div class="dropdown" appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]" dropdown>
                                    <button class="route__button btn btn-xs btn-white dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a class="btn btn-outline-secondary btn-sm dropdown-item btn-uppercase" (click)="goToRoute(route.routeId)">{{ 'Go to route' | translate }}</a>
                                        <a class="btn btn-outline-secondary btn-sm dropdown-item btn-uppercase" (click)="reflow(route.id)">{{ 'Reflow' | translate }}</a>
                                        <a class="btn btn-outline-secondary btn-sm dropdown-item btn-uppercase" (click)="replan(route.id)">{{ 'Replan' | translate }}</a>
                                    </div>
                                </div>
                            </div>
                            <span *ngIf="distances" [containerClass]="'pretty-tooltip'" [tooltip]="route.routeNumber | distanceLabelPipe:distances[route.runtime]" [style.width.px]="100" placement="left" class="custom-tooltip">
                                <strong>{{ route.deliveriesCount }}</strong>&nbsp;<span [style.font-size.px]="10">({{ route.sumAllBoxes }}: {{ route.sumDryBoxes }}, {{ route.sumChilledBoxes }})</span>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="legend text-right">
                <div class="legend__square legend__square--delivery-time"></div>
                {{ 'Delivery time' | translate }}
                <div class="legend__square legend__square--driving-time"></div>
                {{ 'Driving time' | translate }}
                <div class="legend__square legend__square--wait-time"></div>
                {{ 'Wait time' | translate }}
                <div class="legend__square legend__square--reload"></div>
                {{ 'Reload' | translate }}
            </div>

            <ng-container *ngIf="!loader && optimizeResult && optimizeRoute.countByslot">
                <div class="d-flex">
                    <div class="mg-r-60">
                        <span class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{ 'Count deliveries by slot' | translate }}:</span>
                        <ol>
                            <li *ngFor="let item of optimizeRoute.countByslot | keyvalue">{{ item.key }} - <strong [ngClass]="{'red': deliveriesOutOfLimit(item.key, item.value)}">{{ item.value }}</strong></li>
                        </ol>
                    </div>
                    <div *ngIf="shiftStatusChanged.length">
                        <span class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{ 'Shift statuses history' | translate }}:</span>
                        <ol>
                            <li *ngFor="let event of shiftStatusChanged">{{event.data.previousStatus}} <i-feather name="arrow-right" class="slot__map-icon"></i-feather> {{event.data.status}}: {{event.occurredAt | utcToLocalTimePipe | amLocale: locale | amDateFormat: 'D MMMM YYYY, HH:mm:ss'}}</li>
                        </ol>
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!loader && optimizeResult && optimizeRoute.log" appCheckPrivileges [permissions]="[Permissions.ROLE_SYSADMIN]">
                <span class="tx-11 tx-normal tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-t-10 mg-b-20 mg-r-10">{{ 'Optimization log' | translate }}:</span>
                <ol>
                    <li *ngFor="let logLine of optimizeRoute.log">{{ logLine }}</li>
                </ol>
            </ng-container>

            <hr />

        </ng-container>
    </ng-container>


    
    <div *ngIf="loader" class="load-spinner spinner">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        <p class="spinner__info">{{ 'Please, wait!' | translate }}</p>
    </div>
    
    <div *ngIf="!loader && !optimizeResult" class="empty-data load-spinner">
        <i-feather name="alert-circle" class="mg-r-10"></i-feather>
        <div>{{ 'No data to display' | translate }}</div>
    </div>
    
    <ng-template #bar let-route="route">
        <div class="bars__bar bar" [ngClass]="{'delivery-management-mode' : isDeliveryManagementMode}">
            <ng-container *ngFor="let activity of route.activities; let deliveryIndex = index">
                <div 
                    class="bars__bar__activity activity WAIT"
                    *ngIf="route.activities | findWaitingTimePipe:deliveryIndex"
                    [style.height.px]="12" 
                    [style.flex]="route.activities | findWaitingTimePipe:deliveryIndex"
                    tooltip="{{'Waiting time' | translate}}"></div>
                <div 
                    class="bars__bar__activity activity {{activity.type}}"
                    [style.height.px]="12" 
                    [style.flex]="activity.durationSeconds" 
                    [containerClass]="'pretty-tooltip'"
                    [ngClass]="{'crazy-delivery': route | isCrazyDeliveryPipe:activity:deliveryManagmentSettings}"
                    (click)="findClosestDeliveries(route, activity)"
                    [tooltip]="activity | deliveryTooltipPipe"></div>
            </ng-container>
                <div 
                    class="bars__bar__activity TIME_TO_END_SHIFT" 
                    [style.height.px]="12" 
                    [style.flex]="route | endShiftPipe:optimizeResult.shift" 
                    [containerClass]="'pretty-tooltip'" 
                    [tooltip]="route | endShiftPaddingPipe:optimizeResult.shift">
                </div>
        </div>
    </ng-template>
    
    <ng-template #mapTemplate>
        <div class="modal-header" *ngIf="path">
            <h4 class="modal-title pull-left">Route: {{ path.routeNumber }}</h4>
            <a class="btn btn-xs btn-white btn-uppercase go-to-g-maps" target="_blank" [href]="goToGMaps(path)">{{ 'Go to google maps' | translate }}</a>
            <!-- <pre>
                {{path | json}}
            </pre> -->
            <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
                <i-feather name="X"></i-feather>
            </button>
        </div>
    
        <div class="modal-body">
            <div class="bars" [style.margin.px]="0" [style.top.px]="0">
                <ng-container *ngTemplateOutlet="bar; context: { route: route }"></ng-container>
            </div>
            <agm-map *ngIf="path" [zoomControl]="true" [zoom]="zoom" [latitude]="path.latCenter" [longitude]="path.lngCenter" [usePanning]="true">
                <agm-polyline [strokeColor]="000000" strokeWeight="1" [strokeOpacity]="0.5">
                    <!-- Warehouse Frisco Marker -->
                    <ng-container *ngIf="path">
                        <agm-polyline-point *ngFor="let point of path.points" [latitude]="point.lat" [longitude]="point.lng"> </agm-polyline-point>
                    </ng-container>
                </agm-polyline>
    
                <ng-container *ngIf="path">
                    <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                        <agm-marker *ngFor="let point of path.points"
                            iconUrl="{{ markerPath }}/{{ point.icon }}"
                            [latitude]="point.lat"
                            [longitude]="point.lng"
                            [label]="point.label"
                            [title]="point.slot"
                            (markerClick)="isDeliveryManagementMode ? findClosestDeliveriesFromModal(route, point) : point.isOpen = !point.isOpen">
                            <agm-info-window [isOpen]="point.isOpen">{{ point.description }}</agm-info-window>
                        </agm-marker>
                      </agm-marker-cluster>
                </ng-container>
            </agm-map>
        </div>
    </ng-template>
    
    <ng-template #mapSlotsTemplate>
        <div class="modal-header" *ngIf="paths.length">
            <h4 class="modal-title pull-left">Slot: {{ selectedSlot }}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
                <i-feather name="X"></i-feather>
            </button>
        </div>
        <div class="modal-body">
            <gmap [locations]="paths" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
        </div>
    </ng-template>
    
    <ng-template #shiftMap>
        <div class="modal-header" *ngIf="paths.length">
            <h4 class="modal-title pull-left">{{ 'Shift' | translate }} : {{ optimizeResult.shift.date }} {{ optimizeResult.shift.type | translate }}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
                <i-feather name="X"></i-feather>
            </button>
        </div>
        <div class="modal-body">
            <gmap [locations]="paths" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
        </div>
    </ng-template>
    
    <ng-template #confirmation>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>
        </div>
    
        <div class="modal-body text-center">
            <p>
                <strong>Do you confirm {{ modalArgs.action | translate }} for route {{ modalArgs.route }}?</strong>
            </p>
            <button type="button" class="btn btn-brand-01" (click)="confirm()">{{ 'Confirm' | translate }}</button>
            <button type="button" class="btn btn-default" (click)="decline()">{{ 'Cancel' | translate }}</button>
        </div>
    </ng-template>

    <ng-template #crazyDeliveryWindow>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'Manual delivery management' | translate }}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="mapTemplateRef.hide()">
                <i-feather name="X"></i-feather>
            </button>
        </div>
    
        <div class="modal-body text-left modal-crazy-delivery">
            <div class="row">
                <div class="col">
                    <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 text-left d-block">{{'Target delivery' | translate}}</label>
                </div>
                <div class="col d-flex justify-content-between">
                    <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 text-left d-block">{{'Nearest delivery' | translate}} ({{closeDeliveryIndex + 1}} z {{numberOfCrazyDeliveries}})</label>
                    <div class="nav">
                        <button (click)="closeDeliveryIndexFun(-1)" class="btn btn-xs btn-white"><i-feather class="mg-t-4" name="arrow-left"></i-feather></button>
                        <button (click)="closeDeliveryIndexFun(1)" class="btn btn-xs btn-white"><i-feather class="mg-t-4" name="arrow-right"></i-feather></button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Customer' | translate}}</label>
                    <div>{{selectedCrazyDelivery.delivery?.customer.label}} | {{selectedCrazyDelivery.delivery?.customer.status}} </div>
                    <div>{{selectedCrazyDelivery.delivery?.orderId}}</div>

                    <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1 mg-t-10">{{'Location' | translate}}</label>
                    <div>{{selectedCrazyDelivery.delivery?.location.rawAddress}}</div>
                    <div>{{selectedCrazyDelivery.delivery?.location.coordinates.parseCoordinates}}</div>
                </div>
                
                <div class="col">
                    <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Route' | translate}}</label>
                    <div>{{'Route number' | translate}}: <strong>{{selectedCrazyDelivery.route?.routeNumber}}</strong></div>
                    <div>{{'Slot' | translate}}: <strong>{{selectedCrazyDelivery.delivery?.order.slot}}</strong></div>
                    <div>{{'Actual driving time' | translate}}: <strong>{{selectedCrazyDelivery.activity?.actualDrivingTime | secToHoursPipe}}</strong></div>
                    <div>{{'Deliveries' | translate}}: <strong>{{selectedCrazyDelivery.route?.deliveriesCount}}</strong></div>
                    <div>{{'Boxes' | translate}}: <strong>{{selectedCrazyDelivery.delivery?.order.boxesCount}}</strong></div>
                </div>


                <ng-container *ngIf="!closeDeliveryIndexLoader">
                    <div class="col">
                        <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Customer' | translate}}</label>
                        <div>{{closestDeliveries[this.closeDeliveryIndex].delivery?.customer?.label}} | {{closestDeliveries[this.closeDeliveryIndex].delivery?.customer.status}} </div>
                        <div>{{closestDeliveries[this.closeDeliveryIndex]?.orderId}}</div>

                        <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1 mg-t-10">{{'Location' | translate}}</label>
                        <div>{{closestDeliveries[this.closeDeliveryIndex]?.delivery?.location.rawAddress}}</div>
                        <div>{{closestDeliveries[this.closeDeliveryIndex]?.delivery?.location.coordinates.parseCoordinates}}</div>
                    </div>
                    <div class="col">
                        <label class="tx-sans tx-10 tx-normal tx-uppercase tx-color-03 tx-spacing-1">{{'Route' | translate}}</label>
                        <div>{{'Route number' | translate}}: <strong>{{closestDeliveries[this.closeDeliveryIndex]?.route.routeNumber}}</strong></div>
                        <div>{{'Slot' | translate}}: <strong>{{closestDeliveries[this.closeDeliveryIndex].delivery?.order.slot}}</strong></div>
                        <div>{{'Deliveries' | translate}}: <strong>{{closestDeliveries[this.closeDeliveryIndex].route?.deliveriesCount}}</strong></div>
                        <div>{{'Boxes' | translate}}: <strong>{{closestDeliveries[this.closeDeliveryIndex].delivery?.order.boxesCount}}</strong></div>
                        <div>{{'Distance' | translate}}: <strong>{{(closestDeliveries[this.closeDeliveryIndex].activity.waypoint?.distance / 1000).toFixed(2)}}km</strong></div>
                        <div>{{'Duration' | translate}}: <strong>{{closestDeliveries[this.closeDeliveryIndex].activity.waypoint?.duration | secToHoursPipe}}</strong></div>
                    </div>
                </ng-container>

                <app-loader class="col-6" [loader]="closeDeliveryIndexLoader" [text]="'Data loading' | translate"></app-loader>
                
            </div>

            <div class="row mg-t-20">
                <div class="col-6">
                    <button 
                        (click)="assignDelivery(closestDeliveries[this.closeDeliveryIndex], -1)" 
                        class="button btn btn-warning btn-sm btn-uppercase mg-t-20 w-100" 
                        [ngClass]="{'invisible': closeDeliveryIndexLoader}">{{ 'Assign before' | translate }}</button>
                </div>
                <div class="col-6">
                    <button 
                        (click)="assignDelivery(closestDeliveries[this.closeDeliveryIndex], 1)" 
                        class="button btn btn-primary btn-sm btn-uppercase mg-t-20 w-100" 
                        [ngClass]="{'invisible': closeDeliveryIndexLoader}">{{ 'Assign after' | translate }}</button>
                </div>
            </div>

            <div class="row mg-t-20" [style.height.vh]="50">
                <div class="col">
                    <agm-map *ngIf="!closeDeliveryIndexLoader" [zoomControl]="true" [zoom]="13" [latitude]="selectedCrazyDelivery.path?.points[0].lat" [longitude]="selectedCrazyDelivery.path?.points[0].lng" [usePanning]="true">
                        <agm-polyline [strokeColor]="000000" strokeWeight="1" [strokeOpacity]="0.5">
                            <!-- Warehouse Frisco Marker -->
                            <agm-polyline-point [latitude]="warehouseCoords.lat" [longitude]="warehouseCoords.lng"></agm-polyline-point>
                            <ng-container *ngIf="closestDeliveries[this.closeDeliveryIndex]?.routePath">
                                <agm-polyline-point *ngFor="let point of closestDeliveries[this.closeDeliveryIndex]?.routePath.points" [latitude]="point.lat" [longitude]="point.lng"> </agm-polyline-point>
                            </ng-container>
                            <agm-polyline-point [latitude]="warehouseCoords.lat" [longitude]="warehouseCoords.lng"></agm-polyline-point>
                        </agm-polyline>
            
                        <ng-container *ngIf="closestDeliveries[this.closeDeliveryIndex]?.routePath">
                            <agm-marker *ngFor="let point of closestDeliveries[this.closeDeliveryIndex]?.routePath.points"
                                        [iconUrl]="closestDeliveries[this.closeDeliveryIndex].activity.location.lat !== point.lat ? markerPath +'/'+ point.icon : markerPath +'/marker-crazy-delivery.png'"
                                        [latitude]="point.lat"
                                        [longitude]="point.lng"
                                        [label]="closestDeliveries[this.closeDeliveryIndex].activity.location.lat !== point.lat ? point.label : ''"
                                        [title]="point.slot"
                                        (markerClick)="point.isOpen = !point.isOpen ">
                                <agm-info-window [isOpen]="point.isOpen"><strong>{{ 'The nearest delivery' | translate }}!</strong> <br />{{ point.description }}</agm-info-window>
                            </agm-marker>
                        </ng-container>
            
                        <!-- Warehouse Frisco Marker -->
                        <agm-marker [latitude]="warehouseCoords.lat" [longitude]="warehouseCoords.lng" iconUrl="{{ markerPath }}/{{client}}Marker.png"></agm-marker>
                        
                        <agm-marker 
                            [latitude]="selectedCrazyDelivery.activity.location.lat" 
                            [longitude]="selectedCrazyDelivery.activity.location.lng" 
                            iconUrl="{{ markerPath }}/marker-crazy-delivery2.png"
                            [label]="''"
                            (markerClick)="selectedCrazyDelivery.path?.points[0].isOpen = !selectedCrazyDelivery.path?.points[0].isOpen">
                                <agm-info-window [isOpen]="selectedCrazyDelivery.path?.points[0].isOpen"><strong>{{ 'Crazy delivery which should be move' | translate }}!</strong> <br /> {{selectedCrazyDelivery.path?.points[0].description}}</agm-info-window>
                        </agm-marker>
                    </agm-map>
                    <app-loader [loader]="closeDeliveryIndexLoader" [text]="'Data loading' | translate"></app-loader>
                </div>
            </div>
        </div>
    </ng-template>


    <!-- {{'ActivityType.BREAK' | translate }} -->
    <!-- {{'ActivityType.DELIVERY' | translate }} -->
    <!-- {{'ActivityType.DEPARTURE' | translate }} -->
    <!-- {{'ActivityType.DRIVING' | translate }} -->
    <!-- {{'ActivityType.LOAD' | translate }} -->
    <!-- {{'ActivityType.RELOAD' | translate }} -->
    <!-- {{'ActivityType.RETURN' | translate }} -->
    <!-- {{'ActivityType.UNLOAD' | translate }} -->