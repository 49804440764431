<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="database"></i-feather> {{'Create agency' | translate}}
            </h4>
        </div>

        <hr />
    </div>
    
    <div content>

        <div *ngIf="!loader" class="content bg-white pd-40">

            <form [formGroup]="form">

                 <div class="form-group row">
                     <label class="col-2 tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'Name' | translate}}:&nbsp;</span>
                        <ng-container *ngIf="tooltipLabel('name') as label">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="label"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>
                     <div class="col-10">
                         <input formControlName="name" class="form-control" />
                         <div *ngIf="form.get('name').hasError('required') && form.get('name').touched" class="error-color">
                             {{ "The field is required" | translate }}!
                         </div>
                     </div>
                 </div>

                 <div class="row">
                    <div class="col-2 align-self-end pd-b-2">
                        <div class="form-group mg-b-2">
                            <label>{{'Base monthly salary' | translate}}</label></div>
                    </div>
                
                    <div *ngFor="let item of ['baseMonthlySalaryNet', 'baseMonthlySalaryGross']" class="col-5 form-horizontal">
                        <label  class="text-left tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans" [for]="item">
                            <span *ngIf="item === 'baseMonthlySalaryNet'">
                                {{'Net' | translate}}:
                                <ng-container *ngIf="tooltipLabel('baseMonthlySalaryNet') as label">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="label"
                                        [style.width.px]="13" [style.height.px]="13" name="info"></i-feather>
                                </ng-container>
                            </span>
                            <span *ngIf="item === 'baseMonthlySalaryGross'">
                                {{'Gross' | translate}}:
                                <ng-container *ngIf="tooltipLabel('baseMonthlySalaryGross') as label">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="label"
                                        [style.width.px]="13" [style.height.px]="13" name="info"></i-feather>
                                </ng-container>
                            </span>
                        </label>

                        <input [formControlName]="item" class="form-control" currencyMask />
                        <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                            {{ "The field is required" | translate }}!
                        </div>
                    </div>
                 </div>

                 <div class="row mg-t-20 mg-b-40">
                    <div class="col-2 align-self-end pd-b-2">
                        <div class="form-group mg-b-2"><label>{{'Delivery fee' | translate}}</label></div>
                    </div>
                
                    <div *ngFor="let item of ['baseDeliveryFeeNet', 'baseDeliveryFeeGross']" class="col-5 form-horizontal">
                        <label  class="text-left tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans" [for]="item">
                            <span *ngIf="item === 'baseDeliveryFeeNet'">
                                {{'Net' | translate}}:
                                <ng-container *ngIf="tooltipLabel('baseDeliveryFeeNet') as label">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="label"
                                        [style.width.px]="13" [style.height.px]="13" name="info"></i-feather>
                                </ng-container>
                            </span>
                            <span *ngIf="item === 'baseDeliveryFeeGross'">
                                {{'Gross' | translate}}:
                                <ng-container *ngIf="tooltipLabel('baseDeliveryFeeGross') as label">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="label"
                                        [style.width.px]="13" [style.height.px]="13" name="info"></i-feather>
                                </ng-container>
                            </span>
                        </label>
                        <input [formControlName]="item" class="form-control" currencyMask />
                        <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                            {{ "The field is required" | translate }}!
                        </div>
                    </div>
                 </div>


                <ng-container formArrayName="multiplierList">
                    <ng-container *ngFor="let type of multiplierTypes">

                        <header class="offset-2 col-10 d-flex align-items-baseline justify-content-center mg-b-20 mg-t-40">
                            <label class="d-block tx-sans tx-12 tx-medium tx-uppercase tx-color-01 tx-spacing-1 mg-r-20 flex-grow-1">{{'Multipliers' | translate}}: 
                                
                                <strong>{{'multipliersType.' + type | translate}}&nbsp;</strong>
                                <ng-container *ngIf="tooltipLabel(type) as label">
                                    <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="label"
                                        [style.width.px]="13" [style.height.px]="13" name="info"></i-feather>
                                </ng-container>

                            </label>
                            <button (click)="addMultiplier(type)" class="btn btn-xs btn-uppercase btn-info pd-l-5 pd-r-5">{{'Add multiplier' | translate}}</button>
                        </header>
                        

                        <ng-container *ngFor="let multiplier of form.controls.multiplierList.controls; let index=index">
                            <ng-container  formGroupName="{{index}}">
                              
                                <div *ngIf="multiplier.value.type === type" class="col-10 offset-2 form-horizontal d-flex mg-b-20">

                                    <div [style.flex-grow]="1" class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                        <label for="factor" class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{ 'Factor' | translate }}:</label>
                                        <input type="number" formControlName="factor" class="form-control" />
                                    </div>

                                    <div [style.flex-grow]="1" class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                        <label for="rangeFrom" class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{ 'rangeFrom' | translate }}:</label>
                                        <input type="number" formControlName="rangeFrom" class="form-control" />
                                    </div>

                                    <div [style.flex-grow]="1" class="item mg-r-10 ng-tns-c28-0 ng-star-inserted">
                                        <label for="rangeTo" class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{ 'rangeTo' | translate }}:</label>
                                        <input type="number" formControlName="rangeTo" class="form-control" />
                                    </div>

                                    <div class="d-flex align-items-end flex-column bd-highlight align-self-end">
                                        <button (click)="removeMultiplier(index)" class="btn btn-xs d-inline btn-warning pd-t-2 pd-b-2 h-38"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                                    </div>

                                </div>
                                </ng-container>
                            
                        </ng-container>
                    </ng-container>
                </ng-container>
                
                <!-- <button class="btn btn-success" (click)="showForm()">Show</button> -->

                <div class="d-flex justify-content-end mg-t-20">
                    <div class="col-3">
                        <!-- <button (click)="addMultiplier()" class="btn btn-sm btn-uppercase btn-info w-100">{{'Add multiplier' | translate}}</button> -->
                    </div>
                    <div class="col-2">
                        <button (click)="onSubmit()" [disabled]="!form.valid || submitted"
                            class="btn btn-sm btn-uppercase mg-l-5 btn-brand-01 w-100">

                            <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                            <ng-container *ngIf="submitted">
                                <div class="load-spinner text-center">
                                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                </div>
                            </ng-container>

                        </button>
                    </div>
                </div>

            </form>
        </div>
        
    </div>
</layout-default>


<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm removal of the apm assignment' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>


{{ 'tooltips.agency.labels.name' | translate }} 
{{ 'tooltips.agency.labels.baseMonthlySalaryNet' | translate }} 
{{ 'tooltips.agency.labels.baseMonthlySalaryGross' | translate }} 
{{ 'tooltips.agency.labels.baseDeliveryFeeNet' | translate }} 
{{ 'tooltips.agency.labels.baseDeliveryFeeGross' | translate }} 

{{ 'tooltips.agency.labels.EMPLOYMENT_PERIOD' | translate }} 
{{ 'tooltips.agency.labels.PUNCTUALITY' | translate }} 
{{ 'tooltips.agency.labels.NUMBER_OF_DELIVERIES' | translate }} 
{{ 'tooltips.agency.labels.DELIVERIES_ON_TIME' | translate }} 
{{ 'tooltips.agency.labels.DELIVERIES_EARLY' | translate }} 
{{ 'tooltips.agency.labels.DELIVERIES_LATE' | translate }} 


{{ 'multipliersType.EMPLOYMENT_PERIOD' | translate }}
{{ 'multipliersType.PUNCTUALITY' | translate }}
{{ 'multipliersType.NUMBER_OF_DELIVERIES' | translate }}
{{ 'multipliersType.DELIVERIES_ON_TIME' | translate }}
{{ 'multipliersType.DELIVERIES_EARLY' | translate }}
{{ 'multipliersType.DELIVERIES_LATE' | translate }}