import * as _ from 'lodash';

import { PaymentType } from '@enums/enum';

import { Entity } from './Entity';
import { Serializable } from './Serializable';
import { PaymentMethod } from '@interfaces/payment-method';

export class Payment extends Entity implements Serializable<Payment> {
    public id: string;
    public amountPaid: number;
    public amountDue: number;
    public currency: string;
    public methods: PaymentMethod[];
    public requestedType: PaymentType;
    public merchant: string;
    public terminalProfile: string;

    public deserialize(data: any) {
        this.id = _.get(data, 'id');

        const amountDue = _.get(data, 'amountDue');
        this.amountDue = amountDue ? amountDue : 0;

        const amountPaid = _.get(data, 'amountPaid');
        this.amountPaid = amountPaid ? amountPaid : 0;

        this.currency = _.get(data, 'currency', 'PLN');
        this.methods = _.get(data, 'methods');
        this.merchant = _.get(data, 'merchant');
        this.terminalProfile = _.get(data, 'terminalProfile');

        const requestedType = _.get(data, 'requestedType');
        this.requestedType = requestedType ? requestedType : PaymentType.NO_PAYMENT;

        return this;
    }

    public serialize() {
        return {};
    }
}
