
        <layout-default>
            <div header>
                <div toolbar>
                    <h4>
                        <i-feather name="map-pin"></i-feather>
                        {{ 'Location Edit' | translate }}</h4>
                </div>
                <hr>
            </div>

            <div content>
                <ng-container *ngIf="location">
                    <div class="d-flex flex-row">
                        <div class="card" style="flex-basis: 60%">
                            <div class="card-header">
                                <div class="card-header__row row">
                                    <div class="col-5">{{ 'Original address' | translate }}</div>
                                    <div class="col-4">{{ 'Initial result' | translate }}</div>
                                    <div class="col-3">
                                        <button class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-white w-100" (click)="revertToOriginal()">{{ 'Reset changes' | translate }}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body mb-4 row">
                                <div class="card-text col-5">
                                    <button class="btn btn-link btn-sm" (click)="useOriginal()">
                                        {{ originalLocation.getOriginalAddressAsRaw() }}
                                    </button>
                                </div>
                                <div class="card-text col-5">
                                    <button class="btn btn-link btn-sm" (click)="useInitial()">
                                        {{ originalLocation.getAddressAsRaw() }} <span class="badge badge-warning">{{ location.geocodingStatus | translate }}</span>
                                    </button>
                                </div>
                            </div>

                            <div>
                                <div class="card-header">
                                    <div class="card-header__row row">
                                        <div class="col-10">{{ 'Address form' | translate }}</div>
                                        <div class="col-2">
                                            <button class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100" type="button" (click)="geocodeAddress()">{{ 'Geocode' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <form [formGroup]="locationForm">
                                        <div formGroupName="address">
                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="street">{{ 'Street' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="street" formControlName="street"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="houseNO">{{ 'House NO' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="houseNO" formControlName="houseNO"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="town">{{ 'Town' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="town" formControlName="town"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="zip">{{ 'Zip' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="zip" formControlName="zip"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="district">{{ 'District' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="district" formControlName="district"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="voivoidship">{{ 'Voivodeship' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="voivoidship" formControlName="voivoidship"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div formGroupName="additional">
                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="companyName">{{ 'Company Name' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="companyName" formControlName="companyName"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="flatNO">{{ 'Flat NO' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="flatNO" formControlName="flatNO"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="lift">{{ 'Lift' | translate }}</label>
                                                    <select class="form-control form-control-sm" id="lift" formControlName="lift">
                                                        <option [value]=""></option>
                                                        <option [value]="true">{{ 'Yes' | translate }}</option>
                                                        <option [value]="false">{{ 'No' | translate }}</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="staircaseNO">{{ 'Staircase NO' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="staircaseNO" formControlName="staircaseNO"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="floor">{{ 'Floor' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="floor" formControlName="floor"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="contactName">{{ 'Contact Name' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="contactName" formControlName="contactName"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="contactPhoneNO">{{ 'Contact Phone Number' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="contactPhoneNO" formControlName="contactPhoneNO"/>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="domofonCode">{{ 'Domofon Code' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="domofonCode" formControlName="domofonCode"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div formGroupName="coordinates">
                                            <div class="row">
                                                <div class="form-group col-6">
                                                    <label for="lat">{{ 'Latitude' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="lat" formControlName="lat"/>
                                                </div>
                                                <div class="form-group col-6">
                                                    <label for="lng">{{ 'Longitude' | translate }}</label>
                                                    <input class="form-control form-control-sm" type="text" id="lng" formControlName="lng"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div appCheckClient [client]="inpostClient" formGroupName="additional" class="form-group">
                                            <label for="accessKey">{{ 'Access key' | translate }}</label>
                                            <input class="form-control form-control-sm" type="text" id="lng" formControlName="accessKey"/>
                                        </div>

                                        <div class="form-group">
                                            <label for="domofonCode">{{ 'Remarks' | translate }}</label>
                                            <textarea class="form-control form-control-sm" type="text" formControlName="remarks"></textarea>
                                        </div>

                                        <div class="form-group-inline d-flex">
                                            <!--FROM ROUTE-->
                                            <ng-container *ngIf="mode == 1">
                                                <a (click)="goToRoute()" class="btn btn-sm btn-uppercase btn-white w-100">{{ 'Cancel' | translate }}</a>
                                            </ng-container>

                                            <!--FROM Locations-->
                                            <ng-container *ngIf="mode == 2">
                                                <a class="btn btn-sm btn-uppercase btn-white w-100" [routerLink]="['locations']">
                                                    {{ 'Cancel' | translate }}
                                                </a>
                                            </ng-container>
                                            <button class="btn btn-sm btn-uppercase mg-l-10 btn-brand-01 w-100" (click)="onSubmit(true)">{{ 'Update address globally' | translate }}</button>
                                            <button class="btn btn-sm btn-uppercase mg-l-10 btn-brand-01 w-100" (click)="onSubmit()">{{ 'Update only delivery address' | translate }}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="flex-basis: 40%">
                            <div>
                                <div class="card-header">
                                    <h5 class="header-row card-header__heading">{{ 'Geocoding results' | translate }}</h5>
                                </div>
                                <div class="card-body">
                                    <ul style="list-style: none">
                                        <li *ngFor="let result of results">
                                            <button class="btn btn-link" (click)="useResult(result)">
                                                <ng-container *ngIf="result.geocoderResult && result.geocoderResult.partial_match">
                                                    <i-feather name="map-pin"></i-feather>
                                                    {{ result.address.raw }}
                                                    <span class="badge badge-warning">{{ 'PARTIAL_MATCH' | translate }}</span>
                                                </ng-container>

                                                <ng-container *ngIf="result.geocoderResult && !result.geocoderResult.partial_match">
                                                    <i-feather name="map-pin"></i-feather>
                                                    {{ result.address.raw }}
                                                    <span class="badge badge-success">{{ 'FULL_MATCH' | translate }}</span>
                                                </ng-container>
                                            </button>
                                        </li>
                                    </ul>

                                    <agm-map #map
                                             [latitude]="latCenter"
                                             [longitude]="lngCenter"
                                             [zoomControl]="true"
                                             [zoom]="zoom"
                                             [mapTypeControl]="true"
                                             [usePanning]="true"
                                             [fitBounds]="bounds">


                                        <ng-container *ngFor="let result of results">
                                            <agm-marker [latitude]="result.marker.latLng.lat" [longitude]="result.marker.latLng.lng" iconUrl="{{ result.marker.marker }}" [markerDraggable]="true"
                                            (dragEnd)="markerDragEnd($event)">
                                                <agm-info-window>
                                                    <div>{{ result.marker.label }}</div>
                                                </agm-info-window>
                                            </agm-marker>
                                        </ng-container>

                                        <!-- Warehouse Frisco Marker -->
                                        <!--<agm-marker [latitude]="friscoWarehouse.lat" [longitude]="friscoWarehouse.lng" iconUrl="{{ markerPath }}/friscoMarker.png"></agm-marker>-->
                                    </agm-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </layout-default>

        <!--{{"NEW"|translate}}-->
        <!--{{"ENCODED"|translate}}-->
        <!--{{"NOTFOUND"|translate}}-->
        <!--{{"PARTIAL"|translate}}-->
        <!--{{"MANUAL"|translate}}-->
        <!--{{"ERROR"|translate}}-->
    