import { Component, OnInit, Input, TemplateRef, ViewChild, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OptimizeResult } from '../../entities/optimize-result';
import { ShiftSettings } from '@entities/shift-settings';
import { Observable, Subscription } from 'rxjs';
import { VisualiserService } from '../../services/visualiser.service';
import { tap } from 'rxjs/operators';
import { NavService } from '@services/nav.sevice';
import { RoutePath, RoutePoint } from '@entities/route-path';
import { mapOptions, mapTypes, Roles, ActivityType, Client } from '@enums/enum';
import { MapPropertiesInterface } from '@interfaces/map-properties.interface';
import * as _ from 'lodash';
import { RouteId } from '@entities/routeId';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppService } from '@services/app.service';
import { Coordinates } from '@interfaces/coordinates.interface';
import { ShiftConstants } from '@shared/constants/shift.constants';
import { FinderEvent } from '@analytics/entities/finder-event';
import { DeliveryExtInterface } from '../../interfaces/delivery-ext.interface';
import { DManagementSettingInterface } from '../../interfaces/d-management-settings.interface';
import { environment } from '@environment';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { RouteExt } from '@entities/route-ext';
import { Activity } from '@entities/activity';
import { Delivery } from '@entities/delivery';
import { DeliveryClean } from '@entities/delivery-clean';
import { DeliveriesService } from '@deliveries/services/deliveries.services';
import { OptimizeRoutes } from '../../entities/optimize-routes';
import { RoutesService } from '@routes/services/routes.service';
import { DeliveryManagementSettings } from '../../interfaces/delivery-management-settings.interface';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { Permissions } from '@enums/permissions';
import { debug } from 'console';


@Component({
  selector: 'app-visualiser-routes-planning-bars',
  templateUrl: './visualiser-routes-planning-bars.component.html',
  styleUrls: ['./visualiser-routes-planning-bars.component.scss']
})
export class VisualiserRoutesPlanningBarsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('confirmation', { static: true }) public confirmation: TemplateRef<any>;
  @ViewChild('crazyDeliveryWindow', { static: true }) public crazyDeliveryWindow: TemplateRef<any>;
  
  @Input() public routesPlanningResult: OptimizeRoutes;
  @Input() public shift: ShiftSettings;
  @Input() public distances: any;
  @Input() public deliveryManagementSettings: DeliveryManagementSettings;
  @Output() public reloadPlanning: EventEmitter<void> = new EventEmitter();


  public shiftSettings$: Observable<any>;

  //@Input() public deliveryManagementSettings: Object;

  private readonly tag: string = '[GridComponent]';
  private readonly maxNoBoxes: number = 12;
  private assetsPath: string = '/assets/img';
  private confirmObservable: Observable<any>;
  private distancesSubscription: Subscription;
  private warehouseCoords;
  private getRoutePaths2Subscription: Subscription;
  private loadingActionButton: boolean = false;
  private mapOption: mapOptions;
  private mapProperties: MapPropertiesInterface;
  private mapTemplateRef: BsModalRef;
  private markerPath: string = `${this.assetsPath}/markers`;
  private modalArgs: object;
  private modalRef: BsModalRef;
  private path: RoutePath;
  private paths: Array<any> = [];
  private route: any;
  private selectedSlot: string;
  private slotCols = ShiftConstants.slotCols;
  private zoom: number = 11;
  public shiftStatusChanged: FinderEvent[] = [];

  private getDeliverySub: Subscription;
  private findClosestDeliveriesSub: Subscription;

  public loader: boolean = false;
  public optimizeResult: OptimizeResult = undefined;
  public locale = '';
  public mode: "REPLAN" | "DELIVERY_MANAGEMENT" | "REGULAR" = 'REGULAR';

  public Permissions = Permissions;

  private closestDeliveries: DeliveryExtInterface[] = [];
  public selectedCrazyDelivery: DeliveryExtInterface = {activity: null, delivery: null, route: null, path: null, routePath: null};
  public closeDeliveryIndex: number = 0;
  public closeDeliveryIndexLoader: boolean = false;
  private numberOfCrazyDeliveries: number = 10;
  private deliveryManagmentSettings: DManagementSettingInterface;
  public client = environment.client.toLowerCase();
  public inpostClient: string = Client.INPOST.toLowerCase();
  public friscoClient: string = Client.FRISCO.toLowerCase();
  
  private get roles() {
      return Roles;
  }

  public get isDeliveryManagementMode(): boolean {
      return this.mode === 'DELIVERY_MANAGEMENT';
  }
  
   constructor(
    private readonly visualiserService: VisualiserService,
    private readonly navService: NavService,
    private readonly modalService: BsModalService,
    private readonly appService: AppService,
    private readonly translate: TranslateService,
    private readonly notifierService: NotifierService,
    private readonly deliveriesService: DeliveriesService,
    private readonly routeService: RoutesService,
  ) { }

  public ngOnInit() {
    this.shiftSettings$ = this.visualiserService.shiftStatusChange(this.shift.id);
    this.warehouseCoords = this.appService.findWarehouseCoordinates(parseInt(localStorage.getItem('depot'), 0));
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('deliveryManagementSettings')) {
      this.changedDeliveryManagementSettings(changes.deliveryManagementSettings.currentValue);
    }
    
}

  private changedDeliveryManagementSettings(settings) {
    if (!settings || settings === null) {
        this.mode = 'REGULAR';
    } else {
        this.deliveryManagmentSettings = settings;
        this.mode = 'DELIVERY_MANAGEMENT';
    }
  }

  private confirm(): void {
      this.confirmObservable.subscribe(() => {
          this.modalRef.hide();
          this.confirmObservable = null;
          this.loadingActionButton = false;
          this.notifierService.notify('success', this.translate.instant('The action has executed successfully!'));
      });
  }

  private decline(): void {
      this.confirmObservable = null;
      this.modalRef.hide();

      if (this.getDeliverySub) {
          this.getDeliverySub.unsubscribe();
      }
      if (this.findClosestDeliveriesSub) {
          this.findClosestDeliveriesSub.unsubscribe();
      }
  }

  private goToGMaps(path: RoutePath): string {
      let url = `${this.warehouseCoords.lat}+${this.warehouseCoords.lng}/`;

      _.forEach(path.points, point => {
          url += `${point.lat}+${point.lng}/`;
      });

      url += `${this.warehouseCoords.lat}+${this.warehouseCoords.lng}`;

      return `https://www.google.pl/maps/dir/${url}`;
  }

  private goToRoute(route: RouteId): void {
      this.navService.goToPage(`/routes/${route.getRouteIdPath()}`);
  }

  private openMap(route: any, template: TemplateRef<any>) {
      this.path = new RoutePath().deserialize(route);

      this.route = route;
      this.mapTemplateRef = this.modalService.show(template, {
          class: 'modal-lg',
          animated: false
      });
  }

  private openMapGroupedBySlot(routeIndex: string, template: TemplateRef<any>) {
      this.mapOption = mapOptions.CLUSTER;
      this.mapProperties = {
          zoom: 11,
          center: {
              lat: 52.229676,
              lng: 21.012229
          },
          mapTypeId: mapTypes.ROADMAP
      };

      this.paths = [];
      this.selectedSlot = routeIndex;
      const optimizeRoutes = _.cloneDeep(this.routesPlanningResult);
      
      const routes = optimizeRoutes.extractDeliveriesBySlot(this.selectedSlot);

      _.forEach(routes, route => this.paths.push(RoutePath.fromRouteSummary(route)));

      this.mapTemplateRef = this.modalService.show(template, {
          class: 'modal-lg',
          animated: false
      });
  }

  public openShiftMap(type: string, template: TemplateRef<any>) {
      this.mapOption = type === mapOptions.HEATMAP ? mapOptions.HEATMAP : mapOptions.CLUSTER;
      this.mapProperties = {
          zoom: 11,
          center: {
              lat: 52.229676,
              lng: 21.012229
          },
          mapTypeId: mapTypes.ROADMAP
      };

      this.paths = [];

      if (type === mapOptions.HEATMAP) {
          this.mapOption = mapOptions.HEATMAP;
          _.forEach(this.routesPlanningResult.routes, route => this.paths.push(...this.visualiserService.getCoordinatesFromRoute(route)));
      } else {
          this.mapOption = mapOptions.CLUSTER;
          _.forEach(this.routesPlanningResult.routes, route => this.paths.push(RoutePath.fromRouteSummary(route)));
      }

      this.mapTemplateRef = this.modalService.show(template, {
          class: 'modal-lg',
          animated: false
      });
  }

  private reflow(routeId: string): void {
      this.modalArgs = { action: this.translate.instant('reflow action'), route: routeId };
      this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-md' });
      this.confirmObservable = this.visualiserService.reflow(routeId);
      this.loadingActionButton = true;
  }

  private replan(routeId: string): void {
      this.modalArgs = { action: this.translate.instant('replan action'), route: routeId };
      this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-md' });
      this.confirmObservable = this.visualiserService.replan(routeId);
      this.loadingActionButton = true;
  }

  private shiftHours(shift) {
      const startTime = moment(shift.startTime, 'hours');
      const endTime = moment(shift.endTime, 'hours');
      const slotDuration = shift.slotDuration;
      const hours = [];

      while (startTime.hour() !== endTime.hour()) {
          hours.push(startTime.format("HH:mm") + '-' + startTime.add(slotDuration, "minutes").format("HH:mm"))
      }

      return hours
  }

  private slotLabel(routeIndex) {
      let test: string = '';

      _.filter(this.slotCols, item => {
          if (item.id === this.shift.type) {
              test = item.hours[routeIndex];
          }
      });

      return test;
  }


  private secToHours(seconds: number) {
      let minutes: number = Math.floor(seconds / 60);
      let hours: number = 0;

      if (minutes > 59) {
          hours = Math.floor(minutes / 60);
          minutes = minutes - hours * 60;
      }

      seconds = Math.floor(seconds % 60);

      if (hours) {
          return hours + 'h ' + minutes + 'm ' + seconds + 's';
      }
      return minutes + 'm ' + seconds + 's';
  }


  public deliveriesOutOfLimit(key: string, value: number): boolean{
      const fleetMaximum = _.sumBy(this.shift.fleet, (f: ShiftFleet) => f.max);
      return (this.shift.slotLimits[key.split("-")[0]] * fleetMaximum) < value
  }

  public modeReplanRoutes() {
      this.mode = 'REPLAN';
  }

  public findClosestDeliveriesFromModal(route: RouteExt, point: RoutePoint) {
      this.findClosestDeliveries(route, route.activities[point.stopNo]);
      this.mapTemplateRef.hide();
      this.confirmObservable = null;
  }

  public findClosestDeliveries(route: RouteExt, activity: Activity): void {

      const timePrecise = this.deliveryManagmentSettings.timePrecision;
      this.closeDeliveryIndex = 0;

        const deliveries = Object.values(route.deliveryDict);
      const startSlot = moment(activity.planning.from).add(-timePrecise, 'minutes');
      const endSlot = moment(activity.planning.to).add(timePrecise, 'minutes');

      try {
          this.selectedCrazyDelivery.activity = activity.type === ActivityType.DELIVERY ? activity : route.activities.find((a: Activity) => a.type === ActivityType.DELIVERY && a.stepNo > activity.stepNo);
          this.selectedCrazyDelivery.activity['actualDrivingTime'] = activity.durationSeconds;
          this.selectedCrazyDelivery.delivery = new Delivery();
          this.selectedCrazyDelivery.delivery.id = this.selectedCrazyDelivery.activity.idList[0];

      } catch (err) {
          this.notifierService.notify('error', this.translate.instant('Cannot proceed, not found delivery for that driving activity item!'));
      }

      this.getDeliverySub = this.deliveriesService.getDelivery(this.selectedCrazyDelivery.delivery.id).subscribe(
          (delivery: Delivery) => {
              this.selectedCrazyDelivery.delivery = delivery;
              
              //_.forEach(this.routesPlanningResult.routes, (optRoutes: OptimizeRoutes) => {
                  this.selectedCrazyDelivery.route = this.routesPlanningResult.routes.find((r: RouteExt) => (r.activities.find((act: Activity) => act.id === this.selectedCrazyDelivery.activity.id)));
              //});

              this.closestDeliveries = [];

              //_.forEach(this.routesPlanningResult.routes, (optRoutes: OptimizeRoutes) => {
                  _.forEach(this.routesPlanningResult.routes, (routeExt:  RouteExt) => {
                      _.forEach(routeExt.activities, (a:  Activity) => {
                          if (a.type === ActivityType.DELIVERY && routeExt.routeNumber !== this.selectedCrazyDelivery.route.routeNumber && routeExt.deliveriesCount < this.deliveryManagmentSettings.deliveriesCount) {
                              const departureTime = moment(a.slot.to);
                            
                              if (departureTime.isBetween(startSlot, endSlot) && a.id !== this.selectedCrazyDelivery.activity.id) {
                                  const routeRaw = new RouteExt();
                                  routeRaw.id = routeExt.id;
                                  routeRaw.routeNumber = routeExt.routeNumber;
                                  this.closestDeliveries.push({activity: a, delivery: null, route: routeRaw, path: null, routePath: null});
                              }
                          }
                      });
                  });
             // });

              if (this.closestDeliveries.length > 0) {
                  this.findClosestDeliveriesPart2();
              } else {
                  this.notifierService.notify('warning', this.translate.instant('Cannot find any deliveries nearby!'));
              }

          }
      )
  }

  public findClosestDeliveriesPart2() {
      this.findClosestDeliveriesSub = this.visualiserService.findClosestDeliveries(this.selectedCrazyDelivery.activity, this.closestDeliveries).subscribe(
          (r: DeliveryExtInterface[]) => {
              r.filter((deliveryExt: DeliveryExtInterface) => deliveryExt.activity['waypoint']['distance'] <= this.deliveryManagmentSettings.drivingTime);
              r = _.uniqBy(r, (dExt: DeliveryExtInterface) => dExt.route.routeNumber);
              
              if (r.length > 1) {
                  this.numberOfCrazyDeliveries = (r.length > 9) ? 10 : r.length - 1;
                  this.closestDeliveries = r.slice(0, this.numberOfCrazyDeliveries);
                  this.addRoutePathToActivity(this.selectedCrazyDelivery.activity);
                  this.openCrazyDeliveryWindow();
              } else {
                  this.notifierService.notify('warning', this.translate.instant('Cannot find any deliveries nearby!'));
              }
          }
      );
  }

  public isCloseDelivery(activityId): boolean {
      return (this.closestDeliveries.length) 
          ? this.closestDeliveries.find((deliveryExt: DeliveryExtInterface) => deliveryExt.activity.id === activityId) !== undefined : false;
  }

  private openCrazyDeliveryWindow(): void {

      this.mapOption = mapOptions.CRAZY_DELIVERY_MAP;
      this.mapProperties = {
          zoom: 11,
          center: {
              lat: 52.229676,
              lng: 21.012229
          },
          mapTypeId: mapTypes.ROADMAP
      };

      this.mapTemplateRef = this.modalService.show(this.crazyDeliveryWindow, {
          class: 'modal-lg',
          animated: true
      });
  }

  private addRoutePathToActivity(crazyDelivery: Activity): void {

      this.closestDeliveries
          .map((deliveryExt: DeliveryExtInterface) => {
            deliveryExt.route = this.routesPlanningResult.routes.find((r: RouteExt) => r.activities.find((act: Activity) => act.id == deliveryExt.activity.id) !== undefined);
        

              deliveryExt.routePath = RoutePath.fromActivity(deliveryExt.route.activities);
              deliveryExt.path = RoutePath.fromActivity([deliveryExt.activity]);

              const id = deliveryExt.activity.idList[0];
              const sub: Subscription = this.deliveriesService.getDelivery(id)
                  .subscribe((delivery: Delivery) => {
                      deliveryExt.delivery = delivery;
                      sub.unsubscribe();
                  });
          })
              
          this.selectedCrazyDelivery.path = RoutePath.fromActivity([this.selectedCrazyDelivery.activity]);
  }

  public assignDelivery(deliveryExt: DeliveryExtInterface, movement: number): void {
      this.routeService.unassignDelivery(this.selectedCrazyDelivery.route.id, this.selectedCrazyDelivery.delivery).subscribe(() => {
          this.assignTest(deliveryExt.route, null, deliveryExt.activity.stepNo + movement, this.selectedCrazyDelivery.delivery);
      })
  }

  private assignTest(route, segment, pos, delivery) {
      this.routeService.assignDelivery(route, segment, pos, delivery).subscribe(
          (aaa) => {
              this.confirmObservable = null;
              this.mapTemplateRef.hide();
              this.reloadPlanning.emit();
      })
  }

  public closeDeliveryIndexFun(index: number): void {
      this.closeDeliveryIndexLoader = true;
      this.closeDeliveryIndex = (this.closeDeliveryIndex + index ) % this.numberOfCrazyDeliveries;

      setTimeout(() => {
          this.closeDeliveryIndexLoader = false;
      }, 500);
      
  }

  public ngOnDestroy() {
      if (this.getDeliverySub) {
          this.getDeliverySub.unsubscribe();
      }
      if (this.findClosestDeliveriesSub) {
          this.findClosestDeliveriesSub.unsubscribe();
      }
  }

}
