import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { secToHours } from '@shared/functions/secToHours.function';

@Pipe({
    name: 'drivingTimePerDeliveryPipe',
    pure: true
})
export class DrivingTimePerDeliveryPipe implements PipeTransform {
    public transform(route: OptimizeRoutes): string {
        return route.totalDrivingTime ? secToHours(route.totalDrivingTime / route.totalDeliveries) : "0";
    }
}
