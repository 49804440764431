import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router, UrlSerializer} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '@environment';
import {RoutesService} from '@routes/services/routes.service';
import {LocalStorageService} from '@services/local-storage.service';
import {UtilsService} from '@services/utils.service';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {DashboardMapVehicle} from '../interfaces/dashboard-map-vehicle';
import {DashboardDepotPunctuality} from '../interfaces/DashboardPunctuality';
import { DashboardHistoryQueryParams } from '../interfaces/dashboard-history-queryparams.interface';
import { DashboardHistory } from '../interfaces/dashboard-history.interface';
import { objectToString } from '@shared/functions/object-to-string.function';
import { DashboardHistoryPartitions, DashboardSalesAggregation, DashboardSalesMetrixEnum } from '@enums/enum';
import { ChartModel } from '@analytics/interfaces/chart.interface';
import { DashboardSalesMetrix, DashboardSalesRange, DashboardSalesResponse } from '../interfaces/dashboard-sales-response.interface';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { VehicleDistanceInterface } from '../interfaces/vehicle-distance.interface';
import { VehicleDistanceQueryParams } from '../interfaces/vehicle-distance-queryparams.interface';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  private readonly host = environment.api.url;
  private readonly prefix = environment.api.prefix;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private localStorage: LocalStorageService,
    private routes: RoutesService,
    private router: Router,
    private serializer: UrlSerializer,
  ) {
  }

  public getVehiclesPosition(depotId: string, settings?: DashboardComponent['settings']): Observable<DashboardMapVehicle[]> {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/dashboard/v1/vehiclePositions/${depotId}`, {});
    return this.http.get<DashboardMapVehicle[]>(endpoint).pipe(
      map(vehicles => {
        return vehicles.filter(v => {
          if (settings && settings.hideVehiclesWithoutLastStop && v.lastStop === null) {
            return false;
          }
          if (settings && settings.hideVehiclesWithoutNextStop && !v.nextStop) {
            return false;
          }
          return true;
        });
      }),
    )
  }

  public getPunctuality(depotId: string | null, settings?: DashboardComponent['settings']): Observable<DashboardDepotPunctuality[]> {
    const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/dashboard/v1/punctuality`, {});
    return this.http.get<DashboardDepotPunctuality[]>(endpoint + this.getPunctualityQueryParams(settings, 'startsWithin', 'endsWithin', depotId));
  }

  public getVehicleDetails(vehicle: DashboardMapVehicle) {
    return this.routes.getRoute(vehicle.route);
  }

  private getPunctualityQueryParams(settings: DashboardComponent['settings'], fieldNameStart: string, fieldNameEnd: string, depot?: string) {
    const startsWithin = settings[fieldNameStart] ? settings[fieldNameStart] : undefined;
    const endsWithin = settings[fieldNameEnd] ? settings[fieldNameEnd] : undefined;
    const depotId = depot ? depot : undefined;

    const tree = this.router.createUrlTree([''], {
      queryParams: {
        startsWithin, endsWithin, depotId,
      },
      relativeTo: null,
    });
    return this.serializer.serialize(tree).replace('/', '');
  }


  public getHistoryPunctuality(params: DashboardHistoryQueryParams): Observable<any> {
    let endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/dashboard/v2/punctuality`, {});

    const paramsString = objectToString(params);

    if (paramsString) {
      endpoint += '?' + paramsString;
    }

    return this.http.get<DashboardHistory[]>(endpoint).pipe(
      map((a: DashboardHistory[]) => {

        if (!a.length) {
          return undefined;
        }

        const groupedItems = _(a)
          .orderBy(item => [item.date])
          .groupBy(item => {
            const g = [];
            if (params.partitions.includes(DashboardHistoryPartitions.Date)) {
              g.push(item.date);
            } 
            if (
              params.partitions.includes(DashboardHistoryPartitions.Depot) &&
              params.partitions.includes(DashboardHistoryPartitions.Shift)) 
            {
              g.push(item.depotId);
            } 

            return g
          })
          .value()
          
        return groupedItems;
      }),
    );
     
  }

  public getSalsesDashboardReport(params): Observable<ChartModel[][]> {
    const endpoint = `${this.host}${this.prefix}/dashboard/v1/report?request=${encodeURIComponent(JSON.stringify(params))}`;
    
    return this.http.get<any>(endpoint).pipe(
      map(
        (data: DashboardSalesResponse) => {
          const response: ChartModel[][] = [];
          data.metrics.push(DashboardSalesMetrixEnum.CART);

          (data.metrics).forEach((metric: DashboardSalesMetrixEnum, index: number) => {
            const r: ChartModel[] = [];

            (data.ranges).forEach((range: DashboardSalesRange) => {
              const o: ChartModel = { 
                name: `${range.dates.from}-${range.dates.to}`, 
                category: metric.toString(), 
                series: this.convertSalesDataToChartV2(range.data, index, params.aggregation, metric) }
              r.push(o);
            });

            response.push(r);

          });

          return response;
        })
      );
  }

  public convertSalesDataToChartV2(data: DashboardSalesMetrix[], index: number, aggregation: DashboardSalesAggregation, metric: DashboardSalesMetrixEnum): {name: string, value: number, month: string, year: string}[] {
    const response: {name: string, value: number, month: string, year: string}[] = []
    const format = (aggregation === DashboardSalesAggregation.Days) ? 'D' : 
      (aggregation === DashboardSalesAggregation.Months) ? 'MMMM' : 'D MMMM'

    data.forEach((d: { date: string; metrics: number[] }) => response.push({ name: moment(d.date).format(format), value: (metric !== DashboardSalesMetrixEnum.CART) ? d.metrics[index] : (d.metrics[1] / d.metrics[0]) || 0, month: moment(d.date).format('MMMM'), year: moment(d.date).format('YYYY') }));
    return response;
  }

  public getVehiclesDistanceData(params: VehicleDistanceQueryParams): Observable<VehicleDistanceInterface[]> {
    let endpoint = `${this.host}${this.prefix}/dashboard/v1/vehicleDistanceHistory`;
    const paramsString = objectToString(params);

    if (paramsString) {
      endpoint += '?' + paramsString;
    }

    return this.http.get<VehicleDistanceInterface[]>(endpoint)
  }
}

