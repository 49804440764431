import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RoutesService } from '@routes/services/routes.service';
import { UtilsService } from '@services/utils.service';
import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { PaymentsDetailsComponent } from './components/payments-details.component';
import { PaymentFormComponent } from './components/payments-form.component';
import { PaymentsShiftListComponent } from './components/payments-shift-list.component';
import { PaymentsComponent } from './components/payments.component';
import { PaymentsRouting } from './payments.routing';
import { PaymentsService } from './services/payments.service';
import { LicensingComponent } from './components/licensing/licensing.component';
import { LicensingUsersComponent } from './components/licensing/licensing-users/licensing-users.component';
import { LicensingHardwareComponent } from './components/licensing/licensing-hardware/licensing-hardware.component';
import { LicensingDeliveriesComponent } from './components/licensing/licensing-deliveries/licensing-deliveries.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LicensingApmDeliveriesComponent } from './components/licensing/licensing-apm-deliveries/licensing-apm-deliveries.component';
import { PartnerService } from 'projects/inpost/src/app/operations/services/partner.service';
import { PaymentReportComponent } from './components/payment-report/payment-report.component';

@NgModule({
    imports: [CommonModule, SharedModule, ReactiveFormsModule, PaymentsRouting, AuthenticationModule, NgxDatatableModule],
    declarations: [PaymentsComponent, PaymentsShiftListComponent, PaymentFormComponent, PaymentsDetailsComponent, LicensingComponent, LicensingUsersComponent, LicensingHardwareComponent, LicensingDeliveriesComponent, LicensingApmDeliveriesComponent, PaymentReportComponent],
    providers: [PaymentsService, UtilsService, RoutesService, PartnerService]
})
export class PaymentsModule {}
