import { Component, OnInit } from '@angular/core';
import { Subscription, Observable, zip } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PlanningSequence, PlanningStep, PlanningSequenceClazz, PlannerSupported } from '../../../entities/planning-sequence';
import { PlanningSequenceService } from '../../../services/planning-sequence.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-planning-sequence',
  templateUrl: './edit-planning-sequence.component.html',
  styleUrls: ['./edit-planning-sequence.component.scss']
})
export class EditPlanningSequenceComponent implements OnInit {

  constructor(
    private navigationRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private translate: TranslateService,
    private navService: NavService,
    private ps: PlanningSequenceService) { }

  private routingSubscription: Subscription;
  private modifiedPlanningId: string;
  public planningSequence: PlanningSequence;
  public form: FormGroup;
  private steps: FormArray;
  public loader: boolean = true;
  public submitted: boolean = false;
  public mode: 'CREATE' | 'EDIT';
  
  public plannerSuported: PlannerSupported[];
  public async$: Observable<any>;
  public availableValidators;
  private controlConstParams = ['order', 'clazz', 'config', 'isSolver', 'forward', 'backward', 'isFinaliser']

  get planningSequenceClazz() {
    return _.keys(PlanningSequenceClazz);
  }

  public ngOnInit() {
    const params = this.navigationRoute.snapshot.params;
    const supported$ = this.ps.getPlannerSuported();
  
    if (params.id) {
      this.mode = 'EDIT';
      this.modifiedPlanningId = params.id;
      const planner$ = this.ps.getPlanningSequence(this.modifiedPlanningId);
      
      this.async$ = zip(supported$, planner$).pipe(
        tap((resp) => {
          this.plannerSuported = resp[0] as PlannerSupported[];
          this.planningSequence = resp[1] as PlanningSequence;
          this.initForm();
        })
      );

    } else {
      this.mode = 'CREATE';
      this.async$ = zip(supported$).pipe(
        tap((resp) => {
          this.plannerSuported = resp[0] as PlannerSupported[];
          this.initForm();
        })
      );
      this.initForm();
    }
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      id: [this.planningSequence ? this.planningSequence.id : null, Validators.required],
      name: [this.planningSequence ? this.planningSequence.name : null, Validators.required],
      steps: this.formBuilder.array([])
    });

    this.steps = <FormArray>this.form.controls['steps'];

    if (this.planningSequence) {
      this.planningSequence.steps.forEach((item) => this.steps.push(this.patchValues(item)));
    }

    this.loader = false;
  }

  private patchValues(item?: PlanningStep): AbstractControl {
    const config = item ? JSON.parse(item.config) : undefined;

    const form = this.formBuilder.group({
      // id: [item ? item.id : null, Validators.required],
      order: [item ? item.order : null],
      clazz: [item ? item.clazz : null, Validators.required],
      config: [item ? item.config : null],
      isSolver: [item ? item.isSolver : null, Validators.required],
      forward: [item ? item.forward : null, Validators.required],
      backward: [item ? item.backward : null, Validators.required],
      isFinaliser: [item ? item.isFinaliser : null, Validators.required],
    });

    const controls: FormControl[] = [];

    if (item) {

      this.availableValidators = this.plannerSuported.find(a => a.clazz === item.clazz);

      if (this.availableValidators && this.availableValidators.parameters) {
        this.availableValidators.parameters.forEach((control: {name: string, required: boolean}) => {
          
          const dCtrl = new FormControl({value: 1});
          const jobDataCtrl =  form as FormGroup;
      
          jobDataCtrl.addControl(control.name, dCtrl); 

          if (control.required) {
            dCtrl.setValidators(Validators.required);
          }
      
          dCtrl.updateValueAndValidity();
        })
      }

      form.patchValue(config);
    }
    return form;
  }

  public clazzChanged(value: PlanningSequenceClazz, index: number): void {
    this.loader = true;
    this.availableValidators = this.plannerSuported.find(a => a.clazz === value);
    if (this.availableValidators && this.availableValidators.parameters) {
      const jobDataCtrl =  this.steps.controls[index] as FormGroup;
      
      Object.keys(jobDataCtrl.controls).forEach(n => {
        if (!this.controlConstParams.includes(n)) {
          jobDataCtrl.removeControl(n);
        }
      });
      
      this.availableValidators.parameters.forEach((control: {name: string, required: boolean}) => {
        this.addDynamicForm(index, control);
      })
    }

    this.loader = false;
  }

  public addDynamicForm(index: number, control: {name: string, required: boolean}, config?: Object) {
    const dCtrl = new FormControl();
    const jobDataCtrl =  this.steps.controls[index] as FormGroup;

    jobDataCtrl.addControl(control.name, dCtrl);     

    if (control.required) {
      dCtrl.setValidators(Validators.required);
    }

    dCtrl.updateValueAndValidity();
  }

  public reorderStep(event: {dragData: number}, index) {
    const stepRef = this.steps.controls[event.dragData];
    this.removeStep(event.dragData);

    const arrayA = this.steps.controls.slice(0, index);
    const arrayB = this.steps.controls.slice(index, this.steps.controls.length);

    arrayA.push(stepRef);
    this.steps.controls = arrayA.concat(arrayB);
  }

  private getPlanningSequence(){

    this.ps.getPlanningSequence(this.modifiedPlanningId).subscribe((planner: PlanningSequence) => {
      this.planningSequence = planner;
      this.initForm();
    })
  }

  public removeStep(i): void {
    (<FormArray>this.form.controls['steps']).removeAt(i);
  }

  public addStep(): void {
    this.loader = true;
    this.steps.push(this.patchValues());
    this.loader = false;
  }

  public onSubmit() {
    this.submitted = true;

    console.log(this.form);

    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    this.steps.controls.forEach(element => {
      Object.values(element['controls']).forEach((el: AbstractControl) => {
        el.markAsDirty();
        el.markAsTouched();
        el.updateValueAndValidity();
      });
    });

    if (this.form.invalid) {
      this.submitted = false;
      this.notifierService.notify('error', this.translate.instant('The form is invalid'));
      return;
    }

    const body = this.form.getRawValue() as PlanningSequence;
    console.log('1', body);
    body.steps = body.steps.map((step: PlanningStep, index) => {
    
      // step.order = index;
      const config = _.cloneDeep(step);
      console.log('1', config);

      delete config.clazz;
      delete config.isSolver;
      delete config.forward;
      delete config.backward;
      delete config.isFinaliser;
      delete config.config;
      delete config.order;

     return {
      order: index,
      config: JSON.stringify(config),
      clazz: step.clazz,
      isSolver: step.isSolver,
      forward: step.forward,
      backward: step.backward,
      isFinaliser: step.isFinaliser
     } as PlanningStep;
  
    });



    if (this.mode === 'EDIT'){
      this.ps.updatePlanning(body).subscribe((response: PlanningSequence) => {
        this.submitted = false;
        this.notifierService.notify('success', this.translate.instant('Planning sequence has been updated'));
        this.navService.goToPage(`visualiser/planning-sequence/list`, { planningId: this.modifiedPlanningId });
  
      }, (error) => {
        this.submitted = false;
      });
    } else {
      this.ps.createPlanning(body).subscribe((response: PlanningSequence) => {
        this.submitted = false;
        this.notifierService.notify('success', this.translate.instant('Planning sequence has been created'));
        this.navService.goToPage(`visualiser/planning-sequence/list`, { planningId: response.id });
  
      }, (error) => {
        this.submitted = false;
      });
    }
  }

}


