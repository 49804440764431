<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="align-justify"></i-feather> {{'Map' | translate}}</h4>
        </div>
        <div toolbar>
            <button (click)="mode = 'REGULAR'" class="btn btn-white">Regular</button>
            <button (click)="mode = 'CLUSTER'" class="btn btn-white">CLUSTER</button>

        </div>
        <hr>
    </div>
    <div content>

        <ng-container *ngIf="mode === 'REGULAR'">
            <agm-map [zoomControl]="true" [scaleControl]="true" [zoom]="mapProperties.zoom" [latitude]="mapProperties.center.lat" [longitude]="mapProperties.center.lng" [usePanning]="true">            
                    <agm-marker *ngFor="let point of coordinates"
                        [latitude]="point[1]"
                        [longitude]="point[0]">
                    </agm-marker>
            </agm-map>

        </ng-container>

        <ng-container *ngIf="mode === 'CLUSTER'">

            <agm-map [zoomControl]="true" [scaleControl]="true" [zoom]="mapProperties.zoom" [latitude]="mapProperties.center.lat" [longitude]="mapProperties.center.lng" [usePanning]="true">
                <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">

                    <agm-marker *ngFor="let point of coordinates"
                        [latitude]="point[1]"
                        [longitude]="point[0]">
                    </agm-marker>
                </agm-marker-cluster>
            </agm-map>

        </ng-container>

    </div>
</layout-default>
    