export enum BoxType {
    DRY = 'DRY',
    CHILLED = 'CHILLED',
    FROZEN = 'FROZEN',
    BREAD = 'BREAD',
    FISH = 'FISH'
}

export const BoxTypeOptions = [BoxType.DRY, BoxType.CHILLED, BoxType.FROZEN, BoxType.BREAD, BoxType.FISH];

export const enum CalendarStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED'
}

export const enum AddressStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED'
}

export const enum CustomerType {
    NEW = 'NEW',
    VIP = 'VIP',
    REGULAR = 'REGULAR'
}

export enum DriverStatus {
    ACTIVE = 'ACTIVE',
    TERMINATED = 'TERMINATED',
    SUSPENDED = 'SUSPENDED'
}

export const DriverStatusOptions = [DriverStatus.ACTIVE, DriverStatus.TERMINATED, DriverStatus.SUSPENDED];

export const enum DriverExperience {
    JUNIOR = 'JUNIOR',
    MEDIOR = 'MEDIOR',
    SENIOR = 'SENIOR'
}

export const DriverExperienceOptions = [DriverExperience.JUNIOR, DriverExperience.MEDIOR, DriverExperience.SENIOR];

export const enum DriverAppLang {
    PL = 'pl-PL',
    GB = 'en-GB',
    UA = 'ua-UA'
}

export const DriverAppLangOptions = [DriverAppLang.PL, DriverAppLang.GB, DriverAppLang.UA];

export const enum DriverSocialNumberType {
    PESEL = 'PESEL',
    OTHER = 'OTHER'
}

export const DriverSocialNumberTypeOptions = [DriverSocialNumberType.PESEL, DriverSocialNumberType.OTHER];

export const enum DriverNationality {
    PL = 'PL',
    UA = 'UA'
}

export const DriverNationalityOptions = [DriverNationality.PL, DriverNationality.UA];

export const enum VanBodyType {
    FRISCO = 'FRISCO'
}

export const VanBodyTypeOptions = [VanBodyType.FRISCO];

export const enum VanStatus {
    AVAILABLE = 'AVAILABLE',
    MAINTENANCE = 'MAINTENANCE',
    RETIRED = 'RETIRED',
    REPLACEMENT = 'REPLACEMENT'
}

export const VanStatusOptions = [VanStatus.AVAILABLE, VanStatus.MAINTENANCE, VanStatus.RETIRED, VanStatus.REPLACEMENT];

export enum HardwareStatus {
    AVAILABLE = 'AVAILABLE',
    MAINTENANCE = 'MAINTENANCE',
    RETIRED = 'RETIRED',
    REPLACEMENT = 'REPLACEMENT'
}

export const HardwareStatusOptions = [HardwareStatus.AVAILABLE, HardwareStatus.MAINTENANCE, HardwareStatus.RETIRED, HardwareStatus.REPLACEMENT];

export const enum HardwareType {
    SCANNER = 'SCANNER',
    POS_TERMINAL = 'POS_TERMINAL',
    // VEHICLE = 'VEHICLE',
    // FORKLIFT = 'FORKLIFT',
    VAN = 'VAN'
    // OTHER = 'OTHER',
    // UNKNOWN = 'UNKNOWN'
}

export const HardwareTypeOptions = [
    HardwareType.SCANNER,
    HardwareType.POS_TERMINAL
    // HardwareType.VEHICLE,
    // HardwareType.FORKLIFT,
    // HardwareType.VAN,
    // HardwareType.OTHER,
    // HardwareType.UNKNOWN
];

export const enum HardwareOwner {
    FRISCO = 'FRISCO',
    PETIT_FORESTIER = 'PETIT_FORESTIER',
    FRAIKIN = 'FRAIKIN',
    ELAVON = 'ELAVON',
    OTHER = 'OTHER'
}

export const HardwareOwnerOptions = [HardwareOwner.FRISCO, HardwareOwner.PETIT_FORESTIER, HardwareOwner.FRAIKIN, HardwareOwner.ELAVON, HardwareOwner.OTHER];

export enum ShiftType {
    MORNING = 'MORNING',
    MORNING_1 = 'MORNING_1',
    MORNING_2 = 'MORNING_2',
    AFTERNOON = 'AFTERNOON',
    EVENING = 'EVENING'
}

export enum DeliveryStatus {
    RESERVED = 'RESERVED',
    NEW = 'NEW',
    FULFILLED = 'FULFILLED',
    PENDING = 'PENDING',
    ASSIGNED = 'ASSIGNED',
    RETRY = 'RETRY',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    FAILURE = 'FAILURE',
    ARCHIVED = 'ARCHIVED',
    ORDERED = 'ORDERED',
    CUTOFF = 'CUTOFF',
    FINALIZING = 'FINALIZING'
}

export enum ApmDeliveryStatus {
    RESERVED = 'RESERVED', 
    NEW = 'NEW', 
    COMPLETED = 'COMPLETED', 
    CANCELLED = 'CANCELLED', 
    STORED = 'STORED', 
    REMINDED = 'REMINDED', 
    EXPIRED = 'EXPIRED', 
    NOT_USED = 'NOT_USED', 
    USED = 'USED', 
    RETURNED = 'RETURNED', 
    CANCELLED_POST_CUTOFF = 'CANCELLED_POST_CUTOFF', 
    SUPERSEDED = 'SUPERSEDED'
}

export enum ShiftStatus {
    /**
     * Shift is being configured and can not yet be booked
     */
    CONFIGURING = 'CONFIGURING',

    /**
     * Shift is available for booking by customers
     */
    BOOKING = 'BOOKING',

    /**
     * Shift is closed for booking and being planned by the routing engine
     */
    PLANNING = 'PLANNING',

    /**
     * Shift is planned and fulfillment process is ongoing
     */
    FULLFILLMENT = 'FULLFILLMENT',

    /**
     * The shift is currently in the process of being delivered
     */
    DELIVERING = 'DELIVERING',

    /**
     * The shift is done
     */
    COMPLETED = 'COMPLETED'
}

export const ShiftStatusOptions = [ShiftStatus.BOOKING, ShiftStatus.COMPLETED, ShiftStatus.CONFIGURING, ShiftStatus.DELIVERING, ShiftStatus.FULLFILLMENT, ShiftStatus.PLANNING];

export const enum SlotStatus {
    AVAILABLE = 'AVAILABLE',
    FULL = 'FULL'
}

export const DeliveryStatusOptions = [
    DeliveryStatus.RESERVED,
    DeliveryStatus.NEW,
    DeliveryStatus.FULFILLED,
    DeliveryStatus.PENDING,
    DeliveryStatus.ASSIGNED,
    DeliveryStatus.RETRY,
    DeliveryStatus.IN_PROGRESS,
    DeliveryStatus.DONE,
    DeliveryStatus.FAILURE,
    DeliveryStatus.ARCHIVED,
    DeliveryStatus.ORDERED
];

/**
 * Order for api queries
 */
export const enum ORDER {
    DESC = 'DESC',
    ASC = 'ASC'
}

/**
 * Google Maps encoding statuses
 */
export const enum GeocodingStatus {
    /**
     * Location is new and no geocoding attempt has been made yet.
     */
    NEW = 'NEW',
    /**
     * Location is encoded.
     */
    ENCODED = 'ENCODED',
    /**
     * Location could not be found by the geocoding service. (unknown)
     */
    NOTFOUND = 'NOTFOUND',
    /**
     * The encoder could only find part of the address, not the whole address.
     */
    PARTIAL = 'PARTIAL',
    /**
     * The geocoding has been overruled manually.
     */
    MANUAL = 'MANUAL',
    /**
     * An  error (network?) occurred during geocoding.
     */
    ERROR = 'ERROR'
}

export const ReviewStatusOptions = [ReviewStatus.NOT_REVIEWED, ReviewStatus.NO_DISCREPANCIES, ReviewStatus.REVIEW_DISCREPANCIES, ReviewStatus.APPROVED_DISCREPANCIES, ReviewStatus.CHOOSE_STATUS];

export const enum ReviewStatus {
    NOT_REVIEWED = 'NOT_REVIEWED',
    NO_DISCREPANCIES = 'NO_DISCREPANCIES',
    REVIEW_DISCREPANCIES = 'REVIEW_DISCREPANCIES',
    APPROVED_DISCREPANCIES = 'APPROVED_DISCREPANCIES',
    CHOOSE_STATUS = 'CHOOSE_STATUS'
}

export enum CurrencyType {
    PLN = 'zł',
    EURO = 'EURO'
}

export enum RouteGeneratedBy {
    MANUAL = 'MANUAL',
    IMPORT = 'IMPORT'
}

export const CurrencyTypeOptions = [CurrencyType.PLN, CurrencyType.EURO];

export enum GeneratedBy {
    IMPORT = 'IMPORT',
    MANUAL = 'MANUAL'
}

export const GeneratedByOptions = [GeneratedBy.IMPORT, GeneratedBy.MANUAL];

export enum PaymentType {
    CASH = 'CASH',
    ONLINE = 'ONLINE',
    CARD = 'CARD',
    WIRETRANSFER = 'WIRETRANSFER',
    DEFERRED = 'DEFERRED',
    ACCOUNT = 'ACCOUNT',
    ONLINE_PENDING = 'ONLINE_PENDING',
    SODEXO = 'SODEXO',
    NO_PAYMENT = 'NO_PAYMENT'
}

export const PaymentTypeOptions = [
    PaymentType.CASH,
    PaymentType.ONLINE,
    PaymentType.CARD,
    PaymentType.WIRETRANSFER,
    PaymentType.DEFERRED,
    PaymentType.ACCOUNT,
    PaymentType.ONLINE_PENDING,
    PaymentType.SODEXO,
    PaymentType.NO_PAYMENT
];

export enum RouteStatus {
    INBOOKING = 'INBOOKING',
    PLANNED = 'PLANNED',
    FINALIZED = 'FINALIZED',
    INFULFILLMENT = 'INFULFILLMENT',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    NOTCOMPLETED = 'NOTCOMPLETED',
    INLOADING = 'INLOADING',
    FINISHED = 'FINISHED',
    INVOICING = 'INVOICING',
    CUTOFF = 'CUTOFF'
}

export enum SegmentStatus {
    INBOOKING = 'INBOOKING',
    PLANNED = 'PLANNED',
    FINALIZED = 'FINALIZED',
    INFULFILLMENT = 'INFULFILLMENT',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    NOTCOMPLETED = 'NOTCOMPLETED',
    INLOADING = 'INLOADING',
    FINISHED = 'FINISHED',
    INVOICING = 'INVOICING'
}

export const RouteStatusOptions = [
    RouteStatus.INBOOKING,
    RouteStatus.PLANNED,
    RouteStatus.FINALIZED,
    RouteStatus.INFULFILLMENT,
    RouteStatus.INPROGRESS,
    RouteStatus.COMPLETED,
    RouteStatus.NOTCOMPLETED,
    RouteStatus.INLOADING,
    RouteStatus.FINISHED,
    RouteStatus.INVOICING
];

export const enum ComplaintType {
    DAMAGED = 'DAMAGED',
    MISSING = 'MISSING',
    BAD = 'BAD',
    OTHER = 'OTHER'
}

export const enum DateRange {
    YESTERDAY = 'YESTERDAY',
    THIS_WEEK = 'THIS_WEEK',
    TWO_WEEKS = 'TWO_WEEKS',
    LAST_7_DAYS = 'LAST_7_DAYS',
    THIS_MONTH = 'THIS_MONTH',
    LAST_30_DAYS = 'LAST_30_DAYS',
    THIS_QUARTER = 'THIS_QUARTER',
    LAST_90_DAYS = 'LAST_90_DAYS',
    USER_VALUE = 'USER_VALUE'
}

export const DateRangeOptions = [
    DateRange.YESTERDAY,
    DateRange.TWO_WEEKS,
    DateRange.THIS_WEEK,
    DateRange.LAST_7_DAYS,
    DateRange.THIS_MONTH,
    DateRange.LAST_30_DAYS,
    DateRange.THIS_QUARTER,
    DateRange.LAST_90_DAYS,
    DateRange.USER_VALUE
];

export enum mapTypes {
    ROADMAP = 'roadmap',
    SATELLITE = 'satellite',
    HYBRID = 'hybrid',
    TERRAIN = 'terrain'
}

export enum mapOptions {
    CLUSTER = 'CLUSTER',
    POLLYLINE = 'POLLYLINE',
    HEATMAP = 'HEATMAP',
    CLUSTER_HISTORY = 'CLUSTER_HISTORY',
    REGULAR = 'REGULAR',
    CRAZY_DELIVERY_MAP = 'CRAZY_DELIVERY_MAP'
}

export enum DeliveryType {
    COOLOMAT = 'COOLOMAT',
    DELIVERY = 'DELIVERY',
    RELOAD = 'RELOAD',
    APM = 'APM',
    BULK_PICKUP = 'BULK_PICKUP'
}

export enum DriverNotificationFormatType {
    // Technical message
    DATA = 'DATA',
    // Text message
    NOTIFICATION = 'NOTIFICATION'
}

export enum DriverNotificationEventType {
    // Incoming notification
    INCOMING_NOTIFICATION = 'INCOMING_NOTIFICATION',
    // Route status change
    ROUTE_STATUS_CHANGE = 'ROUTE_STATUS_CHANGE',
    SEGMENT_STATUS_CHANGE = 'SEGMENT_STATUS_CHANGE',
    // Route alteration
    ROUTE_ALTERATION = 'ROUTE_ALTERATION',
    SEGMENT_ALTERATION = 'SEGMENT_ALTERATION',
    ROUTE_SYNCHRONIZATION = 'ROUTE_SYNCHRONIZATION'
}

export enum Roles {
    ROLE_SYSADMIN = 'ROLE_SYSADMIN',
    ROLE_DISPATCHER = 'ROLE_DISPATCHER',
    ROLE_CUSTOMER_CARE = 'ROLE_CUSTOMER_CARE',
    ROLE_WAREHOUSE = 'ROLE_WAREHOUSE'
}

export enum HardwareEnum {
    SCANNER = 'SCANNER',
    VAN = 'VAN'
}

export enum Warehouse {
    WAW = 'WAW',
    OMU = 'OMU',
    SIM = 'SIM',
    CAT = 'CAT'
}

export enum CRUD_MODE {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    READ = 'READ'
}

export enum SlotChoiceStrategy {
    FIRST_AVAILABLE_STRATEGY = 'FIRST_AVAILABLE_STRATEGY',
    LAST_AVAILABLE_STRATEGY = 'LAST_AVAILABLE_STRATEGY',
    RANDOM_AVAILABLE_STRATEGY = 'RANDOM_AVAILABLE_STRATEGY',
    FIXED_SLOT_STRATEGY = 'FIXED_SLOT_STRATEGY',
    SMALL_BIAS_STRATEGY = 'SMALL_BIAS_STRATEGY',
    LARGE_BIAS_STRATEGY = 'LARGE_BIAS_STRATEGY'
    // MANUAL_SLOT_STRATEGY = 'MANUAL_SLOT_STRATEGY'
}

export enum SimulationStepResult {
    INITIAL_ERROR = 'INITIAL_ERROR',
    MAPPING_ERROR = 'MAPPING_ERROR',
    ERROR = 'ERROR',
    NO_SLOTS_AVAILABLE = 'NO_SLOTS_AVAILABLE',
    RESERVED = 'RESERVED',
    CONFIRMED = 'CONFIRMED'
}

export enum SimulationType {
    PRECONFIG = 'PRECONFIG',
    AUTOINJECT = 'AUTOINJECT',
    MANUALINJECT = 'MANUALINJECT',
    FINALIZATION = 'FINALIZATION'
}

export enum SearchHardwareBy {
    DATE = 'DATE',
    ID = 'ID',
    DRIVER_ID = 'DRIVER_ID',
    DRIVER_NAME = 'DRIVER_NAME',
    SHIFT_ID = 'SHIFT_ID',
    INVENTORY_NO = 'INVENTORY_NO'
}

export const SearchHardwareByOptions = [SearchHardwareBy.DATE, SearchHardwareBy.INVENTORY_NO, SearchHardwareBy.DRIVER_NAME];

export enum DriverPlanningMode {
    REGULAR = 'REGULAR',
    SERVICE = 'SERVICE',
    VACATION = 'VACATION',
    SICKLEAVE = 'SICKLEAVE',
    SUPPLY = 'SUPPLY'
}

export enum CancellationReason {
    CUSTOMER_NOT_AT_HOME = 'CUSTOMER_NOT_AT_HOME',
    REJECTED_BY_CUSTOMER = 'REJECTED_BY_CUSTOMER',
    DELAYED_DELIVERY = 'DELAYED_DELIVERY',
    RESCHEDULED = 'RESCHEDULED',
    OTHER = 'OTHER'
}

export enum SortDeliveriesBy {
    REAL_ORDER = 'REAL_ORDER',
    ORIGINAL_ORDER = 'ORIGINAL_ORDER'
}

export enum DriversMode {
    REGULAR = 'REGULAR',
    SERVICE = 'SERVICE',
    VACATION = 'VACATION',
    SICKLEAVE = 'SICKLEAVE',
    SUPPLY = 'SUPPLY'
}

export enum PunctualityEventType {
    WAREHOUSE_DEPARTURE = 'WAREHOUSE_DEPARTURE',
    TRIP_TO_DELIVERY = 'TRIP_TO_DELIVERY',
    DELIVERY_STOP = 'DELIVERY_STOP'
}

export enum AppLang {
    PL = 'pl',
    EN = 'en'
}

export enum TimeAggregation {
    DAILY = 'DAILY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY'
}

export enum Client {
    INPOST = 'INPOST',
    FRISCO = 'FRISCO'
}

export enum VisualiserMode {
    VIEWING = 'VIEWING',
    SIMULATOR = 'SIMULATOR',
    CUTOFF = 'CUTOFF',
    AUDIT_LOG = 'AUDIT_LOG'
}

export enum SubscriptionsType {
    LIMITED = 'LIMITED',
    EXTENDABLE = 'EXTENDABLE'
}

export enum LogisticTypeEnum {
    INPOST = 'INPOST',
    OWN = 'OWN'
}

export enum ConfigKeyType {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    DURATION = 'DURATION',
    BOOLEAN = 'BOOLEAN'
}
export enum ActivityType {
    BREAK = 'BREAK',
    DELIVERY = 'DELIVERY',
    DEPARTURE = 'DEPARTURE',
    DRIVING = 'DRIVING',
    LOAD = 'LOAD',
    RELOAD = 'RELOAD',
    RETURN = 'RETURN',
    UNLOAD = 'UNLOAD'
}

export enum ActivityStatus {
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    INPROGRESS = 'INPROGRESS'
}

export enum CompartmentType {
    Cooler = 'L',
    Freezer = 'M',
    Ambient = 'N',
    Other = 'O'
}

export enum ShiftplanType {
    CENTRAL = "CENTRAL",
    PICKUP = "PICKUP"
}

export enum AuditLogType {
    SHIFT_STATUS_CHANGE = "SHIFT_STATUS_CHANGE",
    SHIFT_CHANGE = "SHIFT_CHANGE",
    ROUTE_STATUS_CHANGE = "ROUTE_STATUS_CHANGE",
    ROUTE_CHANGE = "ROUTE_CHANGE",
    DELIVERY_STATUS_CHANGE = "DELIVERY_STATUS_CHANGE",
    DELIVERY_CHANGE = "DELIVERY_CHANGE",
    DELIVERY_ASSIGN = "DELIVERY_ASSIGN",
    DELIVERY_UNASSIGN = "DELIVERY_UNASSIGN",
    APMDELIVERY_STATUS_CHANGE = "APMDELIVERY_STATUS_CHANGE",
    APMDELIVERY_CHANGE = "APMDELIVERY_CHANGE",
    SHIFTPLANNING_CHANGE = "SHIFTPLANNING_CHANGE",
    BOXBAG_SCAN = "BOXBAG_SCAN",
    SEGMENT_STATUS_CHANGE = "SEGMENT_STATUS_CHANGE"
}


export enum LogisticType {
    INPOST = 'INPOST',
    OWN = 'OWN',
    MIXED = 'MIXED',
    NOT_SET = 'NOT_SET'
}

export enum SmsTemplatesType {
    SMS = 'SMS', 
    EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
    INTERNAL_NOTIFICATION = 'INTERNAL_NOTIFICATION'
}

export enum ImportJobStatus {
    New = 'NEW',
    Inprogress = 'IN_PROGRESS',
    Cancelled = 'CANCELLED',
    Completed = 'COMPLETED'
}

export enum BoxScanningType {
    NEW = 'NEW',
    FAKE = 'FAKE',
    LOADED = 'LOADED',
    UNLOADED = 'UNLOADED',
    REGISTERED = 'REGISTERED',
    READY_FOR_PICKUP = 'READY_FOR_PICKUP',
    PICKED_UP = 'PICKED_UP',
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE',
    MERGED = 'MERGED'
}

export enum RouteType {
    ERROR = 'ERROR', 
    REGULAR = 'REGULAR', 
    OVERFLOW = 'OVERFLOW', 
    FROZEN = 'FROZEN'
}

export enum ShiftPlanningType {
    CENTRAL = 'CENTRAL',
    PICKUP = 'PICKUP',
    CUSTOM = 'CUSTOM'
}

export enum JobStatusType {
    Paused = 'PAUSED',
    Resumed = 'RESUMED',
    ScheduledStarted = 'SCHEDULED & STARTED',
}

export enum  DashboardHistoryPartitions {
    Date = 'DATE', 
    Depot = 'DEPOT', 
    Shift  = 'SHIFT'
}

export enum FilterStrategyType {
    Area = 'AREA', 
    Zip = 'ZIP', 
    Both = 'BOTH',
    Either = 'EITHER'
}

export enum RouteStrategyType {
    Count = 'COUNT', 
    Percentage = 'PERCENTAGE'
}

export enum PaymentReportType {
    Delivery = 'DELIVERY',
    Route = 'ROUTE'
}

export enum TimePeriodReportPaymentSummaryJob {
    THIS_DAY = 'THIS_DAY', 
    THIS_WEEK = 'THIS_WEEK', 
    THIS_MONTH = 'THIS_MONTH', 
    THIS_YEAR = 'THIS_YEAR', 
    PREVIOUS_DAY = 'PREVIOUS_DAY', 
    PREVIOUS_WEEK = 'PREVIOUS_WEEK', 
    PREVIOUS_MONTH = 'PREVIOUS_MONTH', 
    PREVIOUS_YEAR = 'PREVIOUS_YEAR', 
    NEXT_DAY = 'NEXT_DAY', 
    NEXT_WEEK = 'NEXT_WEEK', 
    NEXT_MONTH = 'NEXT_MONTH', 
    NEXT_YEAR = 'NEXT_YEAR'
};

export enum MerchantTypeFrisco {
    FRS = 'FRS',
    F24 = 'F24'
}

export enum MerchantTypeInpost {
    MAK = 'MAK',
    CAT = 'CAT'
}

export enum TerminalProfileTypeFrisco {
    FRISCO = 'FRISCO',
    FR_24 = 'FR_24'
}

export enum TerminalProfileTypeInpost {
    MAKRO_E_GROCERY = 'MAKRO_E_GROCERY',
    INPOST = 'INPOST'
}

export enum IntegrationShiftType {
    EXPRESS = 'EXPRESS',
    REGULAR = 'REGULAR'
}

export enum CommentStatus {
    NEW = 'NEW', 
    APPROVED = 'APPROVED', 
    REJECTED = 'REJECTED',
    APPROVED_WITH_EDITS = 'APPROVED_WITH_EDITS'
}

export enum CommentType {
    LOCATION_DRIVER_COMMENT = 'LOCATION_DRIVER_COMMENT', 
}

export enum LoginProviderType {
    AZURE = 'AZURE',
    GOOGLE = 'GOOGLE',
    INTERNAL = 'INTERNAL'
}

export enum DashboardSalesMetrixEnum {
    BOX = 'BOX', 
    DELIVERY = 'DELIVERY', 
    CART = 'CART',
    MARGIN = 'MARGIN', 
    PRICE = 'PRICE', 
    SKU = 'SKU'
};

export enum DashboardSalesAggregation {
    // Nanos = 'Nanos', 
    // Micros = 'Micros', 
    // Millis = 'Millis', 
    // Seconds = 'Seconds', 
    // Minutes = 'Minutes', 
    // Hours = 'Hours', 
    // HalfDays = 'HalfDays', 
    Days = 'DAYS', 
    Weeks = 'WEEKS', 
    Months = 'MONTHS', 
    // Years = 'YEARS', 
    // Decades = 'Decades', 
    // Centuries = 'Centuries', 
    // Millennia = 'Millennia', 
    // Eras = 'Eras', 
    // Forever = 'Forever'
}

export enum DashboardSalesComparision {
    Weeks = 'WEEKS', 
    Months = 'MONTHS', 
    Years = 'YEARS'
}
