import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DepotAssignHelper } from '../depot-assign-helper/depot-assign-helper.component';
import { remove } from 'lodash';
import { BsModalService } from 'ngx-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DepotService } from '../../services/depot.service';
import { NotifierService } from 'angular-notifier';
import { forEach, values } from 'lodash';
import { catchError } from 'rxjs/internal/operators/catchError';
import { HttpErrorResponse } from '@angular/common/http';
import { NavService } from '@services/nav.sevice';
import { Van } from '@hardware/entities/van';

@Component({
  selector: 'app-depot-assign-vans',
  templateUrl: './depot-assign-vans.component.html',
  styleUrls: ['./depot-assign-vans.component.scss']
})
export class DepotAssignVansComponent extends DepotAssignHelper<Van> implements OnInit {

  constructor(
    public readonly navigationRoute: ActivatedRoute,
    protected readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly translateService: TranslateService,
    private readonly depotService: DepotService,
    private readonly notifierService: NotifierService,
    private readonly navService: NavService,
    private readonly cdr: ChangeDetectorRef,

  ) {
    super(navigationRoute, formBuilder);
  }

  public ngOnInit() {
    this.loader = false;
  }

  public prepareEntities() {
    this.list.map((van: Van) => {
      const isExist = this.assignedEntities.find((v: Van) => v.id === van.id);

      if (isExist === undefined) {
        this.rawEntities.push({
          id: van.id,
          identifier: `${van.registration}`,
          additionalInfo: van.brand,
        });
      }
    });

    this.rawEntitiesCollection = this.rawEntities;
    this.loader = false;
    this.cdr.detectChanges();
  }

  public selectEvent(item) {

    const van: Van = this.list.find((v: Van) => v.id === item['id']);
    const num: number = this.idsControl.controls.length;

    this.idsControl.controls[num - 1].setValue(
      {
        id: van.id,
        identifier: `${van.registration}`,
        additionalInfo: van.brand,
      },
      { onlySelf: true }
    );

    remove(this.rawEntities, (v: Van) => v.id === van.id);

    this.addStep();
    this.cdr.detectChanges();
  }

  public onChangeSearch(phrase) {

    if (!this.rawEntitiesCollection.length) {
      this.prepareEntities();
    }

    this.rawEntities = this.rawEntitiesCollection;
    this.rawEntities = this.rawEntities.filter(a => a.identifier.toLowerCase().includes(phrase.toLowerCase()) || a.additionalInfo.toLowerCase().includes(phrase.toLowerCase()))
    this.cdr.detectChanges();
    return;
  }

  public remove(i): void {
    this.entityToRemove = this.idsControl.controls[i].value;
    this.entityToRemoveId = i;
    const id: string = (this.idsControl).controls[i].value['id'];
    this.modalRef = this.modalService.show(this.confirmation, { class: 'modal-sm' });
    this.confirmObservable = this.depotService.unassignVansFromDepot(this.depot.id, id);
    this.cdr.detectChanges();
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      (this.idsControl).removeAt(this.entityToRemoveId);

      this.rawEntities.push({
        id: this.entityToRemove.id,
        identifier: `${this.entityToRemove.registration}`,
        additionalInfo: this.entityToRemove.brand,
      });

      this.notifierService.notify(
        'warning',
        this.translateService.instant('The vehicle {{registration}} has been unassigned from {{depotName}}!', {
          registration: this.entityToRemove['identifier'],
          depotName: this.depot.name
        })
      );
      this.confirmObservable = null;
      this.navService.goToPage(`/locations/depots/list`, {modifiedRow: this.depot.id});
    });
  }

  public onSubmit() {

    const form = this.form.getRawValue();
    const body: string[] = [];

    forEach(values(form['ids']), (van: Van) => van.id && this.assignedEntities.find(v => van.id === v.id) === undefined ? body.push(van.id) : null)

    this.submitted = true;
    this.depotService.assignVansToDepot(this.depot.id, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.submitted = false;
          throw (error);
        })
      )
      .subscribe(
        (region: any) => {
          console.log(region);
          this.notifierService.notify('success', this.translateService.instant('The vehicles list has been updated successfully!'));
          this.navService.goToPage(`/locations/depots/list`, { modifiedRow: this.depot.id });
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }
}
