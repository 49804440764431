import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Location } from '@entities/location';
import { PageableResponse } from '@entities/pagable-response';
import { GeocodingStatus, ORDER } from '@enums/enum';
import { NavService } from '@services/nav.sevice';

import { LocationsService } from '../services/locations.service';

@Component({
    selector: 'locations',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h4><i-feather name="map-pin"></i-feather> {{ 'Locations' | translate }}</h4>
                </div>
                <hr>
            </div>

            <div content>
                <ngx-datatable class="bootstrap fullscreen"
                    #datatable
                    [rows]="rows"
                    [columns]="columns"
                    [columnMode]="'flex'"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [rowHeight]="'auto'"
                    [rowClass]="rowClass"
                    [externalPaging]="true"
                    [count]="pagable.totalElements"
                    [offset]="pagable.number"
                    [limit]="pagable.size"
                    (page)="setPage($event)"
                    (activate)="goToDetails($event)"
                >
                </ngx-datatable>

                <div *ngIf="isLoading" class="locations-loader" [style.top.px]="datatable.headerHeight" [style.height.px]="datatable.element.offsetHeight - datatable.headerHeight">
                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                    <p>{{ 'Please, wait!' | translate }}</p>
                </div>
            </div>
        </layout-default>

        <ng-template #addressHeader let-row="row" let-value="value">
            {{ 'Address' | translate }}
        </ng-template>
        <ng-template #addressItem let-row="row" let-value="value">
            <span class="address">{{ row.location.getAddressAsRaw() }}</span><br>
            <span class="address original">{{ row.location.getOriginalAddressAsRaw() }}</span>
        </ng-template>

        <ng-template #geocoding let-row="row" let-value="value">
            {{ row.location.geocodingStatus | translate }}
        </ng-template>
        <ng-template #status let-row="row" let-value="value">
            {{ row.location.status | translate }}
        </ng-template>

        <!--
        {{'PARTIAL' | translate}}
        {{'VERIFIED' | translate}}
        {{'UNVERIFIED' | translate}}
        -->
    `,
    styleUrls: ['locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy {
    private readonly TAG: string = '[LocationsComponent]';
    private routeQueryParams$: Subscription;

    @ViewChild('addressHeader', { static: true }) public addressHeader: TemplateRef<any>;
    @ViewChild('addressItem', { static: true }) public addressItem: TemplateRef<any>;
    @ViewChild('status', { static: true }) public status: TemplateRef<any>;
    @ViewChild('geocoding', { static: true }) public geocoding: TemplateRef<any>;
    @ViewChild('datatable', { static: true }) public datatable: DatatableComponent;

    public pagable: PageableResponse<Location> = new PageableResponse<Location>(Location);
    public modifiedId = '';
    public rows: { num: number; location: Location }[] = [];
    public columns;
    public size = 10;
    public isLoading = false;

    public rowClass = (row: { num: number; location: Location }) => {
        return {
            'row-modified': row.location.id === this.modifiedId
        };
    };

    constructor(private translate: TranslateService, private navService: NavService, private navigationRoute: ActivatedRoute, private locationService: LocationsService) {}

    public ngOnInit(): void {
        // todo mark recently modified record
        this.isLoading = true;

        this.routeQueryParams$ = this.navigationRoute.params.subscribe(params => {
            console.log(this.TAG, 'RouteQueryParams', params);
            this.locationService
                .getLocations(GeocodingStatus.PARTIAL, 0, 10, 'customerId', ORDER.DESC)
                .pipe(
                    map((pageableResponse: PageableResponse<Location>) => {
                        console.log(this.TAG, pageableResponse);

                        this.pagable = pageableResponse;

                        this.prepareRows(this.pagable.content);
                        this.isLoading = false;
                    })
                )
                .subscribe();
        });

        this.columns = [
            { prop: 'num', name: 'Num', flexGrow: 1, resizeable: false, sortable: false, draggable: false },
            { cellTemplate: this.addressItem, headerTemplate: this.addressHeader, flexGrow: 3, resizeable: false, sortable: false, draggable: false },
            { prop: 'location.additional.flatNO', name: this.translate.instant('FlatNO'), flexGrow: 1, resizeable: false, sortable: false, draggable: false },
            { cellTemplate: this.geocoding, name: this.translate.instant('GeocodingStatus'), flexGrow: 2, resizeable: false, sortable: false, draggable: false },
            { cellTemplate: this.status, name: this.translate.instant('Verifed'), flexGrow: 2, resizeable: false, sortable: false, draggable: false }
        ];
    }

    public ngOnDestroy() {
        this.routeQueryParams$.unsubscribe();
    }

    public setPage(pageInfo) {
        this.isLoading = true;

        this.locationService
            .getLocations(GeocodingStatus.PARTIAL, pageInfo.offset, this.size, 'customerId', ORDER.DESC)
            .pipe(
                map((pageableResponse: PageableResponse<Location>) => {
                    console.log(this.TAG, pageableResponse);

                    this.pagable = pageableResponse;

                    this.prepareRows(this.pagable.content);
                    this.isLoading = false;
                })
            )
            .subscribe();
    }

    public prepareRows(locations: Location[]) {
        this.rows = locations.map((location: Location, index: number) => {
            return { num: index + 1, location: location };
        });
    }

    public goToDetails(event): void {
        if (event.type === 'click') {
            this.navService.goToPage(`/locations/${event.row.location.id}/edit`);
        }
    }
}
