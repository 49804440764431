import { Component, OnInit, ViewChild } from '@angular/core';
import { RoutesPlanningParams } from '../../interfaces/routes-planning-params.interface';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { VisualiserService } from '../../services/visualiser.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { RouteId } from '@entities/routeId';
import { Observable, combineLatest } from 'rxjs';
import { Permissions } from '@enums/permissions';
import { ShiftsService } from '@services/shifts.service';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { map, tap, filter } from 'rxjs/operators';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { CutoffSolutions } from '../../interfaces/cutoff-solutions.interface';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';
import { SortType } from '@swimlane/ngx-datatable';
import { DailyShiftConfig } from '@calendar/entities/dailyShiftConfig';

@Component({
  selector: 'app-visualiser-simulator',
  templateUrl: './visualiser-simulator.component.html',
  styleUrls: ['./visualiser-simulator.component.scss']
})
export class VisualiserSimulatorComponent implements OnInit {
  
  @ViewChild('table', {static: false}) public table: any;

  private params: RoutesPlanningParams;
  private shiftId: string;

  public Permissions = Permissions;
  public recalculateLoader: boolean = false;
  public forceitLoader: boolean = false;
  public loader = false;
  public shift$: Observable<ShiftConfig>;
  public shiftExt: ShiftExt;

  public shiftSettingsSwitcher = false;
  public routesPlanningResults$: Observable<CutoffSolutions[]>;
  public routesSimulations: CutoffSolutions[];

  public distances$: Observable<any>;
  public noRoutes = true;

  public columns = [
    { prop: 'simulationKey', name: this.translateService.instant('Key'), flexGrow: 2, sortable: true },
    { prop: 'routeCount', name: this.translateService.instant('Routes') + '\n' + this.translateService.instant('Deliveries'), flexGrow: 2, sortable: true },
    { prop: 'distance', name: this.translateService.instant('Distance'), flexGrow: 2, sortable: true },
    { prop: 'description', name: this.translateService.instant('Description'), flexGrow: 4, sortable: true },
    { prop: 'errors', name: this.translateService.instant('Errors'), flexGrow: 2, sortable: false },
    { prop: 'deliveryEarly', name: this.translateService.instant('Early') + '\n' + this.translateService.instant('Late'), flexGrow: 2, sortable: true },
    { prop: 'deliveryTime', name: this.translateService.instant('Delivery time'), flexGrow: 2, sortable: true },
    { prop: 'shiftStart', name: this.translateService.instant('Shift margin'), flexGrow: 2, sortable: true },
    { prop: 'slotStart', name: this.translateService.instant('Slot margin'), flexGrow: 2, sortable: true },
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2, sortable: true },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 2, sortable: false },
  ];

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public  queryParams: QueryParams = Object.assign({}, environment.pagination);
  public SortType = SortType;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilsService: UtilsService,
    private readonly notifierService: NotifierService,
    private readonly visualiserService: VisualiserService,
    private readonly shiftService: ShiftsService,
    private readonly translateService: TranslateService,
    private readonly router: Router

  ) { }

  public ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(a => this.initComponentData());

    this.initComponentData();
  }
  
  public initComponentData() {

    this.params = { 
      y: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'y'),
      m: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'm'),
      d: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'd'),
      shiftId: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'shiftId'),
      cutoff: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'cutoff'),
      depot: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'depot'),
    } as RoutesPlanningParams;

    if (this.params.cutoff === 'not-selected') {
      return;
    }

    this.shiftId = RouteId.getShiftId3(this.params.y, this.params.m, this.params.d, this.params.shiftId);
    this.shift$ = this.shiftService.getShiftSettings(this.shiftId).pipe(
      tap((shift: ShiftConfig) => {
        shift.fleet.map((f: ShiftFleet) => f.vehicleTypeId = f.vehicleType.id);
        this.shiftExt = DailyShiftConfig.fromShiftSettings(shift);

        const shiftExt: ShiftUpdateDto = {
          shiftFulfillmentData: this.shiftExt.fulfilment,
          shiftIntegrationData: this.shiftExt.integration,
          shiftCutoffData: this.shiftExt.shiftCutoffData,
          shiftRoutingData: this.shiftExt.routing,
          shiftId: this.shiftExt.fulfilment.shiftId,
          shiftStatusData: this.shiftExt.shiftStatusData,
          version: this.shiftExt.version
        }

        this.loadRoutesPlanning();
       
        return shift;
      })
    );
  }

  public recalculateSimulator(mustFit: boolean): void {
    this.loader = true;

    mustFit ? (this.forceitLoader = true) : (this.recalculateLoader = true);

    const shift: ShiftUpdateDto = {
      shiftFulfillmentData: this.shiftExt.fulfilment,
      shiftIntegrationData: this.shiftExt.integration,
      shiftCutoffData: this.shiftExt.shiftCutoffData,
      shiftRoutingData: this.shiftExt.routing,
      shiftId: this.shiftExt.fulfilment.shiftId,
      shiftStatusData: this.shiftExt.shiftStatusData,
      version: this.shiftExt.version
    }

    this.calculate(shift);
  }

  public loadRoutesPlanning() {
   this.loader = true;


   const shiftExt: ShiftUpdateDto = {
    shiftFulfillmentData: this.shiftExt.fulfilment,
    shiftIntegrationData: this.shiftExt.integration,
    shiftRoutingData: this.shiftExt.routing,
    shiftId: this.shiftExt.fulfilment.shiftId,
    shiftStatusData: this.shiftExt.shiftStatusData,
    shiftCutoffData: this.shiftExt.shiftCutoffData,
    version: this.shiftExt.version
  }


    if (this.params.shiftId == this.params.cutoff) {
      this.routesPlanningResults$ = this.shiftService.getShiftSimulationsV2(shiftExt, this.shiftId).pipe(
        tap((simulations) => this.routesSimulations = simulations )
      )
    } else {
      this.routesPlanningResults$ = this.shiftService.getShiftSimulationsV3(this.params.cutoff, shiftExt).pipe(
        tap((simulations) => this.routesSimulations = simulations )
      )
    }
  }

  public openShiftManagement(open = true) {
    this.shiftService.getShiftSettings(this.shiftId);
  }

  public toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group, this.table);
    this.table.groupHeader.toggleExpandGroup(group);
  }

  public onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  public updatedData(shiftSettings: ShiftExt) {
    const shift: ShiftUpdateDto = {
      shiftFulfillmentData: shiftSettings.fulfilment,
      shiftIntegrationData: shiftSettings.integration,
      shiftRoutingData: shiftSettings.routing,
      shiftId: shiftSettings.fulfilment.shiftId,
      shiftStatusData: shiftSettings.shiftStatusData,
      shiftCutoffData: shiftSettings.shiftCutoffData,
      version: shiftSettings.version
    }

    this.calculate(shift, shiftSettings['key']);
    this.shiftSettingsSwitcher = false;
  }

  public closeShiftManagement() {
    this.shiftSettingsSwitcher = false;
  }

  public calculate(shift: ShiftUpdateDto, key?: string) {
    this.loader = true;

    this.routesPlanningResults$ = this.shiftService.calculateTemporaryResults(shift, key).pipe(
      map((result) => {
        this.loader = false;
        this.recalculateLoader = false;
        return result;
      })
    );
  }
}
