import { Pipe, PipeTransform } from '@angular/core';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { RouteExt } from '@entities/route-ext';

@Pipe({
    name: 'countRealRoutesPipe',
    pure: true
})
export class CountRealRoutesPipe implements PipeTransform {

    public transform(optimizeRoutes: OptimizeRoutes): number {
        return optimizeRoutes.routes.filter((r: RouteExt) => r.deliveriesCount).length;
    }
}
