import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuardService } from '@guards/permission.guard';
import { ProxyComponent } from '@shared/components/proxy.component';
import * as P from '@enums/permissions';
import { SmsTemplatesComponent } from './components/sms-templates/sms-templates.component';
import { OperationsMainComponent } from 'projects/inpost/src/app/operations/components/operations.component';
import { SmsTemplatesResolver } from './resolvers/sms-templates.resolver';
import { SmsTemplatesEditComponent } from './components/sms-templates-edit/sms-templates-edit.component';
import { SmsTemplateResolver } from './resolvers/sms-template.resolver';

const routes: Routes = [

    {
        path: 'settings',
        component: ProxyComponent,
        data: {
            breadcrumb: 'Breadcrumbs.Settings'
        },
        children: [
            { path: '', redirectTo: 'message-templates', pathMatch: 'full' },
            {
                path: 'message-templates',
                component: OperationsMainComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.SmsTemplates'
                },
                children: [
                    {
                        path: 'list',
                        component: SmsTemplatesComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Driver_edit],
                            breadcrumb: 'Breadcrumbs.SmsTemplates'
                        },
                        resolve: {
                            smsTemplates: SmsTemplatesResolver,
                        }
                    },
                    { 
                        path: 'create', 
                        component: SmsTemplatesEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Driver_edit],
                            breadcrumb: 'Breadcrumbs.SmsTemplatesCreate'
                        }
                    },
                    { 
                        path: 'edit/:id', 
                        component: SmsTemplatesEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Driver_edit],
                            breadcrumb: 'Breadcrumbs.SmsTemplatesEdit'
                        },
                        resolve: {
                            smsTemplate: SmsTemplateResolver,
                        }
                    },

                    { path: '**', redirectTo: 'list' }
                ]
            }
        ]
    }
];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class SettingsRouting { }
