<ng-container *ngIf="(routesPlanningResults$ | async) as routesPlanningResults;">
    <ng-container *ngIf="(distances$ | async) as distances">
        <ng-container *ngFor="let routesPlanningResult of routesPlanningResults.routes">

            <app-visualiser-routes-planning-header 
                [shift]="routesPlanningResults.shift" 
                [distances]="distances"
                [routesPlanningResult]="routesPlanningResult"></app-visualiser-routes-planning-header>
        
            <app-visualiser-routes-planning-bars 
                [shift]="routesPlanningResults.shift"
                [deliveryManagementSettings]="deliveryManagementSettings"
                [distances]="distances"
                (reloadPlanning)="reloadPlanningChanged()"
                [routesPlanningResult]="routesPlanningResult"></app-visualiser-routes-planning-bars>

        </ng-container>
    </ng-container>

    <div *ngIf="!loader && noRoutes" class="load-spinner spinner">
        <p class="text-center tx-12 pd-30"><i-feather name="alert-triangle"></i-feather>&nbsp;{{'No routes found for' | translate}}: <strong>{{params?.y}}-{{params?.m}}-{{params?.d}} {{params?.shiftId | translate}}</strong></p>
    </div>

</ng-container>

<div *ngIf="loader && !noRoutes" class="load-spinner spinner">
    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
    <p class="spinner__info">{{ 'Loading, routes' | translate }}: <strong>{{params?.y}}-{{params?.m}}-{{params?.d}} {{params?.shiftId | translate}}</strong></p>
</div>


