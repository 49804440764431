import * as _ from 'lodash';

import { ShiftType, SlotStatus, Warehouse } from '@enums/enum';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class Slot extends Entity implements Serializable<Slot> {
    public id: string;
    public startTime: string;
    public endTime: string;
    public status: SlotStatus;
    public slot: string;
    public availability: number;
    public date: string;
    public cutOff: string;
    public shift: ShiftType;
    public warehouse: Warehouse;
    public cost: number;

    public deserialize(data: any, index?: number) {
        if (data === null) {
            return;
        }

        this.id = _.get(data, 'id', index);
        this.startTime = _.get(data, 'startTime');
        this.endTime = _.get(data, 'endTime');
        this.status = _.get(data, 'status');
        this.availability = _.get(data, 'availability');
        this.slot = `${this.startTime}-${this.endTime}`;
        this.cutOff = _.get(data, 'cutOff');
        this.date = _.get(data, 'date');
        this.shift = _.get(data, 'shift');
        this.warehouse = _.get(data, 'warehouse');
        this.cost = _.get(data, 'cost');

        return this;
    }

    public serialize() {
        return {
            id: this.id,
            startTime: this.startTime,
            endTime: this.endTime,
            status: this.status,
            slot: this.slot,
            cutOff: this.cutOff,
            date: this.date,
            warehouse: this.warehouse,
            shift: this.shift
        };
    }
}
