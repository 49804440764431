import { Directive, ElementRef, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appMenuItemHasChildren]'
})
export class MenuItemHasChildrenDirective implements AfterContentChecked {

  constructor(private elementRef: ElementRef) { }

  public ngAfterContentChecked() {
    const exist = (this.elementRef.nativeElement as HTMLElement).childNodes;
    if (!(exist.length > 1 && exist[1].childNodes.length)) {
      this.elementRef.nativeElement.remove()
    }
  }

}
