import { Pipe, PipeTransform } from '@angular/core';

import {DashboardMapVehicle} from '../../dashboard/interfaces/dashboard-map-vehicle';

const pathNoTarget = `M 10.4245 20.849 c 5.7573 0 10.4245 -4.6672 10.4245 -10.4245 C 20.849 4.6672 16.1818 0 10.4245 0 S 0 4.6672 0 10.4245 C 0 16.1818 4.6672 20.849 10.4245 20.849 z z`;

const pathWithArrow = `M208.489,416.979c115.146,0,208.49-93.344,208.49-208.489C416.979,93.344,323.635,0,208.489,0S0,93.343,0,208.489
    C0,323.635,93.343,416.979,208.489,416.979z M127.24,219.452l68.259-118.21c2.68-4.641,7.632-7.499,12.99-7.499
    s10.31,2.858,12.99,7.499l68.258,118.21c2.682,4.642,2.682,10.359,0.002,15c-2.68,4.642-7.631,7.501-12.99,7.501h-33.26v66.282
    c0,8.284-6.715,15-15,15h-40c-8.284,0-15-6.716-15-15v-66.282H140.23c-5.359,0-10.312-2.859-12.991-7.501
    C124.56,229.812,124.56,224.094,127.24,219.452z`;

function getVehicleColor(punctuality: -1 | 0 | 1 | null, settings: {
    earlyVehicleColor: string,
    okVehicleColor: string,
    lateVehicleColor: string,
    unknownVehicleColor: string
}) {
    switch (punctuality) {
        case -1: return settings.earlyVehicleColor;
        case 0: return settings.okVehicleColor;
        case 1: return settings.lateVehicleColor;
        default: return settings.unknownVehicleColor;
    }
}

@Pipe({
    name: 'dashboardMapVehicleMarker',
    pure: true
})
export class DashboardMapVehicleMarkerPipe implements PipeTransform {
    public transform(vehicle: DashboardMapVehicle, position: DashboardMapVehicle['position'], isSelected: boolean, nextStop: DashboardMapVehicle['nextStop'], settings: any): any {
        return {
            path: nextStop ? pathWithArrow : pathNoTarget,
            fillColor: getVehicleColor(vehicle.lastStop as any, settings),
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 1,
            scale: nextStop ? (0.05 * settings.vehicleScale / 100) : (1 * settings.vehicleScale / 100),
            rotation: getHeading(vehicle),
            origin: {
                x: 0,
                y: 0
            },
            anchor: nextStop ? {
                x: 205,
                y: 205
            } : {
                x: 10,
                y: 10
            }
        };
    }
}

function getHeading(vehicle: DashboardMapVehicle) {
    try {
        const dx = vehicle.nextStop.lat - vehicle.position.lat;
        const dy = Math.cos(Math.PI / 180 * vehicle.position.lat) * (vehicle.nextStop.lng - vehicle.position.lng);
        const radians = Math.atan2(dy, dx);
        return radians * 180 / Math.PI;
    }
    catch (e) {
        return 0;
    }
}
