import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@entities/address';

@Pipe({
    name: 'addressLabelPipe'
})
export class AddressLabelPipe implements PipeTransform {
    public transform(address: Address): string {
        return `${address.street} ${address.houseNO}, ${address.zip} ${address.town}`;
    }
}
