import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Simulation } from '../entities/simulation';
import { SimulatorService } from './simulator.service';

@Injectable()
export class SimulationResolve implements Resolve<Simulation> {
    constructor(private simulationService: SimulatorService) {}

    public resolve(route: ActivatedRouteSnapshot) {
        return this.simulationService.getSimulation(route.params['simulationId']);
    }
}
