<ng-container *ngIf="(fulfilment$ | async) as fulfilment">
    <form [formGroup]="form">


        <div class="shift-settings">

            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Shift planning' | translate}}:</label>
            <div class="d-flex justify-content-start flex-wrap">
                <div [id]="'shift-plannin-edit-fulfilment-' + item" *ngFor="let item of shiftControls" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                        
                       
                    </label>

                    <input type="text" [formControlName]="item" class="form-control w-100" />

                    <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">{{ 'Proper format: [HH:mm:ss]' | translate }}</div>

                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>

                </div>

                <div id="shift-plannin-edit-fulfilment-shiftDateOffset" class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.shiftDateOffset' | translate}}:&nbsp;</span>
                        <ng-container *ngIf="'shiftDateOffset' | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>

                    <select id="shiftDateOffset" class="custom-select" formControlName="shiftDateOffset">
                        <option *ngFor="let i of daysLabel" [value]="i">
                            <ng-container [ngSwitch]="i">
                                <span *ngSwitchCase="0"> {{ 'Offset.'+i | translate }} </span>
                                <span *ngSwitchCase="1"> {{ 'Offset.'+i | translate }} </span>
                                <span *ngSwitchDefault>{{i}} {{ 'Offset.label' | translate }}</span>
                            </ng-container>
                        </option>
                        <option *ngFor="let i of monthsLabel" [value]="i | monthOffsetPipe">
                            <ng-container [ngSwitch]="i">
                                <span *ngSwitchCase="0"> {{ 'OffsetMonth.'+i | translate }} </span>
                                <span *ngSwitchCase="1"> {{ 'OffsetMonth.'+i | translate }} </span>
                                <span *ngSwitchCase="2"> {{ 'OffsetMonth.'+i | translate }} </span>
                                <span *ngSwitchCase="3"> {{ 'OffsetMonth.'+i | translate }} </span>
                                <span *ngSwitchCase="4"> {{ 'OffsetMonth.'+i | translate }} </span>
                                <span *ngSwitchCase="12"> {{ 'OffsetMonth.'+i | translate }} </span>
                                <span *ngSwitchDefault>{{i}} {{ 'OffsetMonth.label' | translate }}</span>
                            </ng-container>
                        </option>

                    </select>

                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>
                </div>

                <div id="shift-plannin-edit-fulfilment-lockDeliveryOrder" class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.lockDeliveryOrder' | translate}}:&nbsp;</span>
                        <ng-container *ngIf="'lockDeliveryOrder' | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>
                    <select [id]="item" class="custom-select" formControlName="lockDeliveryOrder">
                        <option [ngValue]="true">{{ 'True' | translate }}</option>
                        <option [ngValue]="false">{{ 'False' | translate }}</option>
                    </select>
                </div>
            </div>
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Shift timings' | translate}}:</label>
            <div class="d-flex justify-content-start flex-wrap">

                <div [id]="'shift-plannin-edit-fulfilment-' + item" *ngFor="let item of regularControlsNumber" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>

                    <input type="number" [formControlName]="item" class="form-control w-100" />


                    <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                        {{ "The field value is too long" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>

                </div>

            </div>
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Shift limits' | translate}}:</label>
            <div class="d-flex justify-content-start flex-wrap">

                <div [id]="'shift-plannin-edit-fulfilment-' + item" *ngFor="let item of deliveryLimits" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>

                    <input type="number" [formControlName]="item" class="form-control w-100" />


                    <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                        {{ "The field value is too long" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>

                </div>

                <div id="shift-plannin-edit-fulfilment-maximumProductLines" class="item mg-r-10">
                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                            <span>{{'shiftProperty.maximumProductLines' | translate}}:&nbsp;</span>
                            <ng-container *ngIf="'maximumProductLines' | tooltipLabelPipe:'shift' as tooltip">
                                <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                    [style.width.px]="13" name="info"></i-feather>
                            </ng-container>
                        </label>

                    <div class="d-flex align-items-center justify-content-between" [style.height.px]="36">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" [checked]="maxProductLinesCheckbox" id="customSwitch1" (change)="maxProductLinesCheckboxFun()">
                            <label class="custom-control-label" for="customSwitch1">&nbsp;</label>
                        </div>
                        <div *ngIf="maxProductLinesCheckbox">
                            <input type="number" formControlName="maximumProductLines" class="form-control w-100 flex-grow-1" />
                        </div>
                    </div>
                </div>
            </div>

            
            <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Departure wave informations' | translate}}:</label>
            <p *ngIf="'departureWaveInformations' | tooltipLabelPipe:'shift' as tooltip" class="tx-11 tx-color-03">{{tooltip}}</p>


            <div class="d-flex justify-content-start flex-wrap">
                <div [id]="'shift-plannin-edit-fulfilment-' + item" *ngFor="let item of departureControls" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>

                    <input type="number" [formControlName]="item" class="form-control w-100" />


                    <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                        {{ "The field value is too long" | translate }}!
                    </div>

                    <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                        {{ "The field is required" | translate }}!
                    </div>

                </div>
            </div>

            <ng-container formGroupName="deliveryTime">
                <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Delivery time informations' | translate}}:</label>
                <p *ngIf="'deliveryTime' | tooltipLabelPipe:'shift' as tooltip" class="tx-11 tx-color-03">{{tooltip}}</p>

                <div class="d-flex justify-content-start flex-wrap">
                    <div [id]="'shift-plannin-edit-fulfilment-' + item" *ngFor="let item of deliveryTimeControls" class="item mg-r-10">

                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                            <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <input type="number" [formControlName]="item" class="form-control w-100" />

                        <div *ngIf="form.get('deliveryTime').get(item).hasError('pattern') && form.get('deliveryTime').get(item).touched" class="error-color">
                            {{ "The field value is too long" | translate }}!
                        </div>

                        <div *ngIf="form.get('deliveryTime').get(item).hasError('required') && form.get('deliveryTime').get(item).touched" class="error-color">
                            {{ "The field is required" | translate }}!
                        </div>

                    </div>

                </div>
            </ng-container>

             <!-- Fleet size -->
            <div id="shift-plannin-edit-fulfilment-fleets" class="d-flex justify-content-start flex-fill" *ngIf="(vehicleTypes$ | async) as vehicleTypes">
                <div class="w-100">
                    <ng-container formArrayName="fleet">
                        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Fleet' | translate}}:</label>
                        <button (click)="addFleet()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0" [style.height.px]="20" [style.width.px]="20">
                            <i-feather [style.width.px]="18" [style.height.px]="20" name="plus"></i-feather>
                        </button>
                        <div class="d-flex justify-content-start align-items-center flex-wrap">
                            <ng-container *ngFor="let step of form.controls.fleet.controls; let i=index">
                                <ng-container formGroupName="{{i}}">
                                    <div 
                                        class="item-md mg-r-10">
                                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Vehicle type' | translate}}:</label>
                                            <select [id]="item" class="custom-select" formControlName="vehicleTypeId">
    
                                                <option *ngFor="let vehicle of vehicleTypes" [value]="vehicle.id">{{ vehicle.code }}</option>
                                            </select>
                                    </div>
                                    <div 
                                        class="item-md mg-r-10">
                                            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Max' | translate}}:</label>
                                            <input type="number" formControlName="max" class="form-control w-100" />
                                    </div>
                                    <button (click)="removeVanLimit(i)" class="btn btn-xs btn-outline-warning remove-van-limit align-self-end"><i-feather [style.height.px]="14" name="trash-2"></i-feather></button>
                                </ng-container>
                            </ng-container>
    
                        </div>
                    </ng-container>
                </div>
            </div>

            
        </div>


        <div class="actions d-flex justify-content-between">
            <button id="shift-plannin-edit-fulfilment-back" [routerLink]="['/calendar/shift-planning']" class="btn btn-white tx-11">{{'Back to shifts list' | translate}}</button>
            <div class="d-flex justify-content-between">
                <button id="shift-plannin-edit-fulfilment-previous" [routerLink]="['../step-1']" class="btn btn-sm btn-warning pd-x-15 btn-uppercase mg-l-5">{{'Previous step' | translate}}</button>
                <button id="shift-plannin-edit-fulfilment-next" type="submit" (click)="update()" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Next' | translate}}</button>
            </div>
        </div>
    </form>
</ng-container>

<!-- {{'Offset.0' | translate}} -->
<!-- {{'Offset.1' | translate}} -->
<!-- {{'Offset.label' | translate}} -->
<!-- {{'OffsetMonth.0' | translate}} -->
<!-- {{'OffsetMonth.1' | translate}} -->
<!-- {{'OffsetMonth.2' | translate}} -->
<!-- {{'OffsetMonth.3' | translate}} -->
<!-- {{'OffsetMonth.4' | translate}} -->
<!-- {{'OffsetMonth.12' | translate}} -->
<!-- {{'OffsetMonth.label' | translate}} -->


<!-- {{'OffsetBefore.0' | translate}} -->
<!-- {{'OffsetBefore.1' | translate}} -->
<!-- {{'OffsetBefore.label' | translate}} -->
<!-- {{'OffsetMonthBefore.0' | translate}} -->
<!-- {{'OffsetMonthBefore.1' | translate}} -->
<!-- {{'OffsetMonthBefore.2' | translate}} -->
<!-- {{'OffsetMonthBefore.3' | translate}} -->
<!-- {{'OffsetMonthBefore.4' | translate}} -->
<!-- {{'OffsetMonthBefore.12' | translate}} -->
<!-- {{'OffsetMonthBefore.label' | translate}} -->
