import * as _ from 'lodash';
import * as moment from 'moment';

import { Entity } from '@entities/Entity';
import { Slot } from '@entities/slot';
import { DeliveryStatus, ShiftStatus, ShiftType, Warehouse, ShiftplanType, IntegrationShiftType } from '@enums/enum';
import { VanLimitInterface } from '@calendar/interafces/vanLimit.interface';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { VirtualDepotConfig } from '@calendar/interafces/virtual-depot-config.interface';
import { ShiftCutoffData } from '@calendar/interafces/shift-cutoff-data.interface';

export class ShiftConfig extends Entity {
    public version: number;
    public createdAt: string;
    public modifiedAt: string;
    public label: string;
    public status: ShiftStatus;
    public unOptimizedDeliveries: number;
    public reloadPercentage: number;
    public automaticCutoff: boolean;
    public removePlanning: boolean;
    public routeStrategy: string;
    public routeNumberOffset: number;
    public segmentNumberOffset: number;
    public slotOverLapping: number;
    public startTime: string;
    public slotDuration: number;
    public endTime: string;
    public cutoffs: ShiftCutoffData[];
    public shiftStartMarginMinutes: number;
    public shiftEndMarginMinutes: number;
    public includeReturnInPlanning: boolean;
    public maximumDeliveries: number;
    public expensiveDeliveryThreshold: number;
    public slotEndMarginMinutes: number;
    public slotStartMarginMinutes: number;
    public routeMaximumReloads: number;
    public shiftMaximumSegments: number;
    public routeMargin: number;
    public offset: number;
    public time: number;
    public autoFulfill: boolean;
    public slotLimits: object;
    public vanLimits: VanLimitInterface[];
    public reloadTimeMinutes: number;
    public reloads: any;
    public date: string;
    public type: ShiftType;
    public warehouse: Warehouse;
    public id: string;
    public autoPromoteSolution: boolean;
    public autoFinalizeSolution: boolean;
    public fulfillmentIntegrationEnabled: boolean;
    public slots: Slot[];
    public autoGenerateSolutions: boolean;
    public statuses: any;
    public bookingWindowRestricted: boolean;
    public bookingWindowDayOffset: number;
    public bookingWindowTimeOfDay: number;
    public expensiveDeliveryThresholdTime: number;
    public maxCostFactor: number;
    public departureWaveInitialSize: number;
    public departureWaveInterval: number;
    public departureWaveSize: number;
    public planningSequence: string;
    public maximumProductLines: number;
    public productLines: number;
    public skuCount: number;
    public shiftplanType: ShiftplanType;
    public fleet: ShiftFleet[];
    public fleetMaximum: number;
    public shiftDateOffset: number;
    public lockDeliveryOrder: boolean;
    public allowDeliveryImport: boolean;
    public loadFactor: number;
    public deliveryTime: {
        boxGroupSize: number;
        boxGroupTime: number;
        initialTime: number;
        parkingTime: number;
        parkingTimeThreshold: number;
        paymentTime: number;
    };
    public syncDelay: number;
    public cutoffTimeout: number;
    public isServiceShift: boolean;
    public shiftType: IntegrationShiftType;
    public virtualDepotConfig: VirtualDepotConfig[];

    public deserialize(shift: any, statuses?: Object, productLines?: number, skuCount?: number) {
        this.version = _.get(shift, 'version');
        this.createdAt = _.get(shift, 'createdAt');
        this.modifiedAt = _.get(shift, 'modifiedAt');
        this.label = _.get(shift, 'label');
        this.status = _.get(shift, 'status');
        this.unOptimizedDeliveries = _.get(shift, 'unOptimizedDeliveries');
        this.reloadPercentage = _.get(shift, 'reloadPercentage');
        this.automaticCutoff = _.get(shift, 'automaticCutoff');
        this.removePlanning = _.get(shift, 'removePlanning');
        this.routeStrategy = _.get(shift, 'routeStrategy');
        this.routeNumberOffset = _.get(shift, 'routeNumberOffset');
        this.segmentNumberOffset = _.get(shift, 'segmentNumberOffset');
        this.slotOverLapping = _.get(shift, 'slotOverLapping');
        this.startTime = _.get(shift, 'startTime');
        this.slotDuration = _.get(shift, 'slotDuration');
        this.endTime = _.get(shift, 'endTime');
        this.shiftStartMarginMinutes = _.get(shift, 'shiftStartMarginMinutes');
        this.shiftEndMarginMinutes = _.get(shift, 'shiftEndMarginMinutes');
        this.includeReturnInPlanning = _.get(shift, 'includeReturnInPlanning');
        this.maximumDeliveries = _.get(shift, 'maximumDeliveries');
        this.expensiveDeliveryThreshold = _.get(shift, 'expensiveDeliveryThreshold');
        this.slotEndMarginMinutes = _.get(shift, 'slotEndMarginMinutes');
        this.slotStartMarginMinutes = _.get(shift, 'slotStartMarginMinutes');
        this.routeMaximumReloads = _.get(shift, 'routeMaximumReloads');
        this.shiftMaximumSegments = _.get(shift, 'shiftMaximumSegments');
        this.routeMargin = _.get(shift, 'routeMargin');
        this.offset = _.get(shift, 'offset');
        this.time = _.get(shift, 'time');
        this.reloadTimeMinutes = _.get(shift, 'reloadTimeMinutes');
        this.reloads = _.get(shift, 'reloads');
        this.date = _.get(shift, 'date');
        this.type = _.get(shift, 'type');
        this.warehouse = _.get(shift, 'warehouse');
        this.id = _.get(shift, 'id');
        this.autoFulfill = _.get(shift, 'autoFulfill');
        this.slotLimits = _.get(shift, 'slotLimits');
        this.vanLimits = _.get(shift, 'vanLimits');
        this.autoPromoteSolution = _.get(shift, 'autoPromoteSolution');
        this.autoFinalizeSolution = _.get(shift, 'autoFinalizeSolution');
        this.fulfillmentIntegrationEnabled = _.get(shift, 'fulfillmentIntegrationEnabled');
        this.bookingWindowRestricted = _.get(shift, 'bookingWindowRestricted');
        this.bookingWindowDayOffset = _.get(shift, 'bookingWindowDayOffset');
        this.bookingWindowTimeOfDay = _.get(shift, 'bookingWindowTimeOfDay');
        this.expensiveDeliveryThresholdTime = _.get(shift, 'expensiveDeliveryThresholdTime');
        this.maxCostFactor = _.get(shift, 'maxCostFactor');
        this.departureWaveInitialSize = _.get(shift, 'departureWaveInitialSize', 16);
        this.departureWaveInterval = _.get(shift, 'departureWaveInterval', 900);
        this.departureWaveSize = _.get(shift, 'departureWaveSize', 8);
        this.planningSequence = _.get(shift, 'planningSequence');
        this.shiftplanType = _.get(shift, 'shiftplanType');
        this.fleet = _.get(shift, 'fleet');
        this.fleetMaximum = _.sumBy(this.fleet, (f: ShiftFleet) => f.max);
        this.shiftDateOffset = _.get(shift, 'shiftDateOffset');
        this.loadFactor = _.get(shift, 'loadFactor');
        this.deliveryTime = _.get(shift, 'deliveryTime');
        this.autoGenerateSolutions = _.get(shift, 'autoGenerateSolutions');
        this.isServiceShift = _.get(shift, 'isServiceShift', false);
        this.lockDeliveryOrder = _.get(shift, 'lockDeliveryOrder', false);
        this.allowDeliveryImport = _.get(shift, 'allowDeliveryImport', true);
        this.virtualDepotConfig = _.get(shift, 'virtualDepotConfig', []);
        this.shiftType = _.get(shift, 'shiftType');
        this.cutoffs = _.get(shift, 'cutoffs', []);

        this.fleet.map((f: ShiftFleet) => f.vehicleTypeId = f.vehicleType.id);
        this.syncDelay = _.get(shift, 'syncDelay');
        this.cutoffTimeout = _.get(shift, 'cutoffTimeout');

        this.maximumProductLines = _.get(shift, 'maximumProductLines', -1)
        this.productLines = productLines ? productLines : 0;
        this.skuCount = skuCount ? skuCount : 0;

        this.statuses = {
            RESERVED: (statuses && statuses[DeliveryStatus.RESERVED]) ? statuses[DeliveryStatus.RESERVED] : 0,
            ORDERED: (statuses && statuses['ORDERED']) ? statuses['ORDERED'] : 0,
            ASSIGNED: (statuses && statuses[DeliveryStatus.ASSIGNED]) ? statuses[DeliveryStatus.ASSIGNED] : 0,
            DONE: (statuses && statuses[DeliveryStatus.DONE]) ? statuses[DeliveryStatus.DONE] : 0,
            FAILURE: (statuses && statuses[DeliveryStatus.FAILURE]) ? statuses[DeliveryStatus.FAILURE] : 0,
            RETRY: (statuses && statuses[DeliveryStatus.RETRY]) ? statuses[DeliveryStatus.RETRY] : 0,
            PENDING: (statuses && statuses[DeliveryStatus.PENDING]) ? statuses[DeliveryStatus.PENDING] : 0,
            ARCHIVED: (statuses && statuses[DeliveryStatus.ARCHIVED]) ? statuses[DeliveryStatus.ARCHIVED] : 0,
            NEW: (statuses && statuses[DeliveryStatus.NEW]) ? statuses[DeliveryStatus.NEW] : 0,
            CUTOFF: (statuses && statuses[DeliveryStatus.CUTOFF]) ? statuses[DeliveryStatus.CUTOFF] : 0,
            FINALIZING: (statuses && statuses[DeliveryStatus.FINALIZING]) ? statuses[DeliveryStatus.FINALIZING] : 0,
        };

        return this;
    }

    public serialize() {
        return {
            version: this.version,
            createdAt: this.createdAt,
            modifiedAt: this.modifiedAt,
            label: this.label,
            status: this.status,
            unOptimizedDeliveries: this.unOptimizedDeliveries,
            autoFulfill: this.autoFulfill,
            reloadPercentage: this.reloadPercentage,
            automaticCutoff: this.automaticCutoff,
            removePlanning: this.removePlanning,
            routeStrategy: this.routeStrategy,
            routeNumberOffset: this.routeNumberOffset,
            segmentNumberOffset: this.segmentNumberOffset,
            slotOverLapping: this.slotOverLapping,
            startTime: this.startTime,
            slotDuration: this.slotDuration,
            endTime: this.endTime,
            shiftStartMarginMinutes: this.shiftStartMarginMinutes,
            shiftEndMarginMinutes: this.shiftEndMarginMinutes,
            includeReturnInPlanning: this.includeReturnInPlanning,
            maximumDeliveries: this.maximumDeliveries,
            expensiveDeliveryThreshold: this.expensiveDeliveryThreshold,
            slotEndMarginMinutes: this.slotEndMarginMinutes,
            slotStartMarginMinutes: this.slotStartMarginMinutes,
            slotLimits: this.slotLimits,
            vanLimits: this.vanLimits,
            routeMaximumReloads: this.routeMaximumReloads,
            shiftMaximumSegments: this.shiftMaximumSegments,
            routeMargin: this.routeMargin,
            offset: this.offset,
            autoPromoteSolution: this.autoPromoteSolution,
            autoFinalizeSolution: this.autoFinalizeSolution,
            fulfillmentIntegrationEnabled: this.fulfillmentIntegrationEnabled,
            time: this.time,
            reloadTimeMinutes: this.reloadTimeMinutes,
            reloads: this.reloads,
            autoGenerateSolutions: this.autoGenerateSolutions,
            date: this.date,
            type: this.type,
            warehouse: this.warehouse,
            id: this.id,
            shiftplanType: this.shiftplanType,
            lockDeliveryOrder: this.lockDeliveryOrder,
            allowDeliveryImport: this.allowDeliveryImport,
            slots: this.slots,
            statuses: this.statuses,
            fleet: this.fleet,
            bookingWindowRestricted: this.bookingWindowRestricted,
            bookingWindowDayOffset: this.bookingWindowDayOffset,
            bookingWindowTimeOfDay: this.bookingWindowTimeOfDay,
            expensiveDeliveryThresholdTime: this.expensiveDeliveryThresholdTime,
            maxCostFactor: this.maxCostFactor,
            departureWaveInitialSize: this.departureWaveInitialSize,
            departureWaveInterval: this.departureWaveInterval,
            departureWaveSize: this.departureWaveSize,
            planningSequence: this.planningSequence,
            maximumProductLines: this.maximumProductLines,
            shiftDateOffset: this.shiftDateOffset,
            loadFactor: this.loadFactor,
            syncDelay: this.syncDelay,
            cutoffTimeout: this.cutoffTimeout,
            virtualDepotConfig: this.virtualDepotConfig,
            shiftType: this.shiftType
        };
    }

    public isOpen() {
        return this.status === ShiftStatus.BOOKING;
    }

    public isCutoff() { // TODO: this.date to zawsze dzień obecny z kalendarza, niezależnie czy cutoff jest tego samego dnia czy np. dzień wcześniej
        const data = moment(`${this.date} ${this.time}:00:00`, 'YYYY-MM-DD HH:mm:ss');
        return moment().isAfter(data);
    }

    get isShiftEditable() {
        const date = moment(this.date).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        return today <= date;
    }

    get startTimeLabel(): string {
        return `${this.startTime.split(':')[0]}:${this.startTime.split(':')[1]}`;
    }

    get endTimeLabel(): string {
        return `${this.endTime.split(':')[0]}:${this.endTime.split(':')[1]}`;
    }

    get shiftId(): string {
        return `${this.date}:${this.warehouse}:${this.type}`;
    }

    get cutOffLabel(): string {
        const data = moment(`2019-01-01 ${this.time}:00:00`, 'YYYY-MM-DD HH:mm:ss');
        return data.format('HH:mm');
    }
}
