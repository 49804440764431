
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SalaryReportService } from '../services/salary-report.service';
import { UtilsService } from '@services/utils.service';

@Injectable()
export class SalaryReportFilesResolver implements Resolve<any> {
    constructor(private salaryReportService: SalaryReportService, private readonly utilsService: UtilsService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const reportId = this.utilsService.findUpRouteParams(route, 'reportId')
        return this.salaryReportService.findSalaryReportFies(reportId);
    }
}
