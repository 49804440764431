import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Entity } from '@entities/Entity';
import { Fulfilment } from '@calendar/interafces/fulfilment.interface';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { Intergration } from '@calendar/interafces/integration.interface';
import { Shiftid } from '@calendar/interafces/shiftid.interface';
import { Routing } from '@calendar/interafces/routing.interface';
import { VanLimitInterface } from '@calendar/interafces/vanLimit.interface';
import { DeliveryStatus, ShiftStatus } from '@enums/enum';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { IShiftStatus } from '@calendar/interafces/shift-status.interface';
import { VirtualDepotConfig } from '@calendar/interafces/virtual-depot-config.interface';
import { ShiftCutoffData } from '@calendar/interafces/shift-cutoff-data.interface';

export class DailyShiftConfig extends Entity {
    public date: string;
    public shifts: ShiftExt[] = [];
    public mDay: Moment;
    public productLines: number;
    public skuCount: number;

    public static fromShiftSettings(shift): ShiftExt {
     
        const fulfilment: Fulfilment = {
            departureWaveInitialSize: shift.departureWaveInitialSize,
            departureWaveInterval: shift.departureWaveInterval,
            departureWaveSize: shift.departureWaveSize,
            endTime: shift.endTime,
            fleet: shift.fleet.map((s: ShiftFleet, index: number) => {
                return  {
                    max: s.max,
                    vehicleTypeId: s.vehicleType.id,
                    sortOrder: index,
                    vehicleType: {
                        code: s.vehicleType.code
                    }
                } as ShiftFleet;
            }),
            fleetMaximum: _.sumBy(shift.fleet, (f: ShiftFleet) => f.max),
            label: shift.label,
            maximumDeliveries: shift.maximumDeliveries,
            maximumProductLines: shift.maximumProductLines,
            reloadTimeMinutes: shift.reloadTimeMinutes,
            shiftEndMarginMinutes: shift.shiftEndMarginMinutes,
            shiftDateOffset: shift.shiftDateOffset,
            lockDeliveryOrder: shift.lockDeliveryOrder,
            shiftId: {
                warehouse: shift.warehouse,
                date: shift.date,
                type: shift.type
            } as Shiftid,
            shiftStartMarginMinutes: shift.shiftStartMarginMinutes,
            startTime: shift.startTime,
            deliveryTime: {
                boxGroupSize: shift.deliveryTime.boxGroupSize,
                boxGroupTime: shift.deliveryTime.boxGroupTime,
                initialTime: shift.deliveryTime.initialTime,
                parkingTime: shift.deliveryTime.parkingTime,
                parkingTimeThreshold: shift.deliveryTime.parkingTimeThreshold,
                paymentTime: shift.deliveryTime.paymentTime || 0
            },
            version: shift.version
        } as Fulfilment;

        const integration: Intergration = {
            bookingWindowDayOffset: shift.bookingWindowDayOffset,
            bookingWindowRestricted: shift.bookingWindowRestricted,
            bookingWindowTimeOfDay: shift.bookingWindowTimeOfDay,
            offset: shift.offset,
            routeNumberOffset: shift.routeNumberOffset,
            segmentNumberOffset: shift.segmentNumberOffset,
            shiftId: {
                warehouse: shift.warehouse,
                date: shift.date,
                type: shift.type
            } as Shiftid,
            autoPromoteSolution: shift.autoPromoteSolution,
            autoFinalizeSolution: shift.autoFinalizeSolution,
            fulfillmentIntegrationEnabled: shift.fulfillmentIntegrationEnabled,
            slotDuration: shift.slotDuration,
            slotOverLapping: shift.slotOverLapping,
            time: shift.time,
            isServiceShift: shift.isServiceShift,
            syncDelay: shift.syncDelay,
            shiftType: shift.shiftType,
            allowDeliveryImport: shift.allowDeliveryImport,
            cutoffTimeout: shift.cutoffTimeout,
            version: shift.version
        } as Intergration       

        shift.virtualDepotConfig = (shift.virtualDepotConfig && shift.virtualDepotConfig !== null) ? shift.virtualDepotConfig : []; 

        const routing: Routing = {
            automaticCutoff: shift.automaticCutoff,
            expensiveDeliveryThreshold: shift.expensiveDeliveryThreshold,
            expensiveDeliveryThresholdTime: shift.expensiveDeliveryThresholdTime,
            includeReturnInPlanning: shift.includeReturnInPlanning,
            maxCostFactor: shift.maxCostFactor,
            min: shift.min,
            planningSequence: shift.planningSequence,
            removePlanning: shift.removePlanning,
            routeMargin: shift.routeMargin,
            routeMaximumReloads: shift.routeMaximumReloads,
            routeStrategy: shift.routeStrategy,
            autoGenerateSolutions: shift.autoGenerateSolutions,
            shiftId: {
                warehouse: shift.warehouse,
                date: shift.date,
                type: shift.type
            } as Shiftid,
            shiftMaximumSegments: shift.shiftMaximumSegments,
            slotEndMarginMinutes: shift.slotEndMarginMinutes,
            slotLimits: shift.slotLimits,
            shiftDateOffset: shift.shiftDateOffset,
            slotStartMarginMinutes: shift.slotStartMarginMinutes,
            vanLimits: shift.vanLimits.map((v: VanLimitInterface) => {
                return {
                    usedPercentage: v.usedPercentage,
                    blockedSlots: v.blockedSlots             
                } as VanLimitInterface
            }),
            virtualDepotConfig: shift.virtualDepotConfig.map((v: VirtualDepotConfig) => {
                return {
                    routeStrategy: v.routeStrategy,
                    routes: v.routes,
                    virtualDepotId: v.virtualDepotId,
                    isExclusive: v.isExclusive 
                } as VirtualDepotConfig
            }),
            loadFactor: shift.loadFactor,
            shiftplanType: shift.shiftplanType,
            reloadPercentage: shift.reloadPercentage,
            autoFulfill: shift.autoFulfill,
            virtualDepotIds: shift.virtualDepotIds,
            version: shift.version
        } as Routing;

        const shiftCutoffData: ShiftCutoffData[] = [];
        if (shift.shiftCutoffData) {
            shift.shiftCutoffData.forEach((cutoff: ShiftCutoffData) => {
                shiftCutoffData.push(cutoff);
            });
        } else {
            shift.cutoffs.forEach((cutoff: ShiftCutoffData) => {
                shiftCutoffData.push(cutoff);
            });
        }
       
        const shiftStatus: IShiftStatus = {
            shiftId: {
                warehouse: shift.warehouse,
                date: shift.date,
                type: shift.type
            } as Shiftid,
            status: shift.status,
            version: shift.version
        }

        return {
            fulfilment: fulfilment,
            integration: integration,
            routing: routing,
            shiftCutoffData: shiftCutoffData,
            shiftStatusData: shiftStatus,
            version: shift.version
        }
    }


    public deserialize(data: any) {
        const path = _.get(data, 'date', {});
        const shiftsData = _.get(data, 'shifts', []);
        const shifts = [];

        this.mDay = moment(path, '/YYYY/MM/DD');
        this.date = moment(_.get(data, 'date'), 'YYYY-MM-DD').format('YYYY-MM-DD');

        _.forEach(shiftsData, (shiftData: any) => {
            const shift = _.get(shiftData, 'shift');
            const productLines = _.get(shiftData, 'productLines');
            const skuCount = _.get(shiftData, 'skuCount');
            //shifts.push(new ShiftConfig().deserialize(shift, statuses, productLines, skuCount));

            const statuses = _.get(shiftData, 'statusses');

            const shiftStatuses = {
                RESERVED: (statuses && statuses[DeliveryStatus.RESERVED]) ? statuses[DeliveryStatus.RESERVED] : 0,
                ORDERED: (statuses && statuses['ORDERED']) ? statuses['ORDERED'] : 0,
                ASSIGNED: (statuses && statuses[DeliveryStatus.ASSIGNED]) ? statuses[DeliveryStatus.ASSIGNED] : 0,
                DONE: (statuses && statuses[DeliveryStatus.DONE]) ? statuses[DeliveryStatus.DONE] : 0,
                FAILURE: (statuses && statuses[DeliveryStatus.FAILURE]) ? statuses[DeliveryStatus.FAILURE] : 0,
                RETRY: (statuses && statuses[DeliveryStatus.RETRY]) ? statuses[DeliveryStatus.RETRY] : 0,
                PENDING: (statuses && statuses[DeliveryStatus.PENDING]) ? statuses[DeliveryStatus.PENDING] : 0,
                ARCHIVED: (statuses && statuses[DeliveryStatus.ARCHIVED]) ? statuses[DeliveryStatus.ARCHIVED] : 0,
                NEW: (statuses && statuses[DeliveryStatus.NEW]) ? statuses[DeliveryStatus.NEW] : 0,
                CUTOFF: (statuses && statuses[DeliveryStatus.CUTOFF]) ? statuses[DeliveryStatus.CUTOFF] : 0,
                FINALIZING: (statuses && statuses[DeliveryStatus.FINALIZING]) ? statuses[DeliveryStatus.FINALIZING] : 0,

            };

            const shiftExt: ShiftExt = DailyShiftConfig.fromShiftSettings(shift);

            this.shifts.push({
                ...shiftExt,
                statuses: shiftStatuses,
                productLines: productLines,
                skuCount: skuCount
            })
        });

        

        return this;
    }

    public serialize() {
        return {};
    }
}
