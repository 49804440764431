import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import * as _ from 'lodash';
import { DatepickerOptions } from 'ng2-datepicker';
import { Observable } from 'rxjs';

import { ShiftType, Warehouse } from '@enums/enum';
import { AppService } from '@services/app.service';

import { Dataset } from '../entities/dataset';
import { SimulatorService } from '../services/simulator.service';
import { Boo } from '@entities/boo';
import { Depot } from '@interfaces/depot.interface';

@Component({
    selector: 'app-dataset-create',
    template: `
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-4">
                    <label for="name">{{ 'Label' | translate }}:</label>
                    <input currencyMask formControlName="name" class="form-control" />
                </div>

                <ng-container formGroupName="config">
                    <div class="form-group col-4">
                        <label for="originStartDate">{{ 'Origin start date' | translate }}:</label>
                        <ng-datepicker class="datepicker-input" formControlName="originStartDate" [options]="options"></ng-datepicker>
                    </div>

                    <div class="form-group col-4">
                        <label for="originEndDate">{{ 'Origin end date' | translate }}:</label>
                        <ng-datepicker class="datepicker-input" formControlName="originEndDate" [options]="options"></ng-datepicker>
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <ng-container formGroupName="config">
                    <div class="form-group col-4">
                        <label for="originShiftType">{{ 'Origin shift type' | translate }}:</label>
                        <select class="form-control" formControlName="originShiftType">
                            <option *ngFor="let option of shiftType" [value]="option">{{ option | translate }}</option>
                        </select>
                    </div>

                    <div class="form-group col-4">
                        <label for="originWarehouse">{{ 'Origin warehouse' | translate }}:</label>
                        <select class="form-control" formControlName="originWarehouse">
                            <option *ngFor="let option of depots$ | async" [value]="option">{{ option | translate }}</option>
                        </select>
                    </div>

                    <div class="form-group col-4">
                        <label for="poolSize">{{ 'Pool size' | translate }}:</label>
                        <input type="number" min="1" formControlName="poolSize" class="form-control" />
                    </div>
                </ng-container>
            </div>

            <div class="row justify-content-end">
                <button [disabled]="submitted" type="submit" class="col-2 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">
                    <span *ngIf="!submitted">{{ 'Create dataset' | translate }} </span>
                    <div class="load-spinner">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true" *ngIf="submitted"></i>
                    </div>
                </button>
            </div>
        </form>
    `,
    styleUrls: ['./create-dataset.component.scss']
})
export class CreateDatasetComponent implements OnInit {
    @Output() public addedDataset: EventEmitter<Dataset> = new EventEmitter();

    public form: FormGroup;
    public submitted: boolean = false;
    public depots$: Observable<Depot[]>;
    public locale;

    get shiftType() {
        return _.keys(ShiftType);
    }

    public options: DatepickerOptions = {
        minYear: 1970,
        maxYear: 2030,
        displayFormat: 'D MMMM YYYY',
        barTitleFormat: 'MMMM YYYY',
        dayNamesFormat: 'dd',
        firstCalendarDay: 1
    };

    constructor(
        private formBuilder: FormBuilder,
        private appService: AppService,
        private translate: TranslateService,
        private simulatorService: SimulatorService,
        private notifierService: NotifierService
    ) {
        this.locale = this.translate.currentLang === 'en' ? enLocale : plLocale;
        this.options.locale = this.locale;
    }

    public ngOnInit() {
        this.depots$ = this.appService.getWarehouses();

        this.form = this.formBuilder.group({
            name: [null, Validators.required],
            config: this.formBuilder.group({
                originEndDate: [new Date(), Validators.required],
                originShiftType: [ShiftType.MORNING, Validators.required],
                originStartDate: [new Date(), Validators.required],
                originWarehouse: [Warehouse.WAW, Validators.required],
                poolSize: [10, Validators.required]
            })
        });
    }

    public onSubmit() {
        if (!this.form.valid) {
            return;
        }

        this.submitted = true;
        const rawDatasetConfig = this.form.getRawValue();

        this.simulatorService.createDataset(rawDatasetConfig).subscribe(
            (dataset: Dataset) => {
                this.addedDataset.emit(dataset);
                this.notifierService.notify('success', this.translate.instant('Dataset has been created'));
            },
            error => {
                console.log(`Creating driver - error`, error);
            },
            () => {
                this.submitted = false;
            }
        );
    }
}
