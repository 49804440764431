import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HardwareService } from '@hardware/services/hardware.service';
import { Hardware } from '@hardware/entities/hardware';

@Injectable()
export class HardwareAllResolver implements Resolve<Hardware[]> {
    constructor(private hardwareService: HardwareService) {}
    public resolve() {
        return this.hardwareService.getAllHardware();
    }
}
