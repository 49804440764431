import { Type } from 'class-transformer';

import { serializeType } from '@shared/functions/serializeType.function';

import { FinderEventCategoryEnum } from '../enums/fe-category.enum';
import { FinderEventTypeEnum } from '../enums/fe-type.enum';
import { ShiftStatus } from '@enums/enum';

export class FinderEventData {
    //public type:   FinderEventDataTypeEnum;
    public latitude:  number;
    public longitude: number;
    public speed:     number;
    public heading:   number;
    public odometer:  number;
    public previousStatus?: ShiftStatus;
    public status?: ShiftStatus
}

export class FinderEvent {
    public id:            number;
    public category:      FinderEventCategoryEnum;
    public type:          FinderEventTypeEnum;
    public vanId:         string;
    public routeId:       string;
    public deliveryId:    null;
    
    @Type(serializeType(FinderEventData))
    public data:          FinderEventData;

    public processingLog: string;
    public occurredAt:    Date;
    public createdAt:     Date;
    public modifiedAt:    Date;
}
