<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="book-open"></i-feather> {{ 'Audit log' | translate }}</h4>
        </div>
        <hr>
    </div>

    <div content>
        <app-audit-log-content></app-audit-log-content>
    </div>


</layout-default>

<!-- 
    {{'CUSTOMER_NOT_AT_HOME' | translate}}
    {{'AuditLogType.SHIFT_STATUS_CHANGE' | translate }}
    {{'AuditLogType.SHIFT_CHANGE' | translate }}
    {{'AuditLogType.ROUTE_STATUS_CHANGE' | translate }}
    {{'AuditLogType.DELIVERY_STATUS_CHANGE' | translate }}
    {{'AuditLogType.DELIVERY_CHANGE' | translate }}
    {{'AuditLogType.DELIVERY_ASSIGN' | translate }}
    {{'AuditLogType.DELIVERY_UNASSIGN' | translate }}
    {{'AuditLogType.APMDELIVERY_STATUS_CHANGE' | translate }}
    {{'AuditLogType.SHIFTPLANNING_CHANGE' | translate }}
    {{'AuditLogType.BOXBAG_SCAN' | translate }}
    {{'AuditLogType.SEGMENT_STATUS_CHANGE' | translate }} 
    {{'AuditLogType.ROUTE_CHANGE' | translate }} 
    {{'routeProperty.scanBeforeDeparture' | translate}}
    {{'routeProperty.scanAfterReturn' | translate}}
-->