import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mapTypes, mapOptions } from '@enums/enum';
import { DepotService } from '../../services/depot.service';
import { PageableResponse } from '@entities/pagable-response';
import { Depot } from '@interfaces/depot.interface';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { QueryParams } from '@interfaces/query-params.interface';
import { environment } from '@environment';

@Component({
  selector: 'app-depot-map',
  template: `
  <layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="package"></i-feather> {{'Depots map' | translate}}
            </h4>
        </div>
        <hr />
    </div>
    <div content>
      <ng-container *ngIf="(depots$ | async) as depots">
          <gmap [locations]="depots" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
        </ng-container>
    </div>
  </layout-default>
  `,
  styles: 
  [`
    :host ::ng-deep gmap #map {
      height: 100%;
    }
    
  `]
})
export class DepotMapComponent implements OnInit {

  public depots$: Observable<Depot[]>;
  public mapProperties = {
    zoom: 11,
    // center: {
    //   lat: 52.229676,
    //   lng: 21.012229
    // },
    mapTypeId: mapTypes.ROADMAP
  };
  public mapOption = mapOptions.CLUSTER;

  protected queryParams: QueryParams = Object.assign({}, environment.pagination);

  
  constructor(
    private readonly depotService: DepotService
  ) { }

  public ngOnInit() {
    this.queryParams.size = 1000;

    this.depots$ = this.depotService.list$.pipe(
      map((response : PageableResponse<Depot>) => response.content)
    );

    this.depotService.fetchAll(this.queryParams);
  }

}
