import { Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';

import { ShiftType } from '@enums/enum';
import { AppService } from '@services/app.service';
import { ShiftsService } from '@services/shifts.service';

import { DayComposite } from './entities/dayComposite';
import { MonthlyShiftConfig } from './entities/monthlyShiftConfig';
import { ShiftConfig } from './entities/shiftConfig';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Permissions } from '@enums/permissions';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'calendar',
    template: `
        <layout-with-sidebar>
            <div header>
                <div class="d-flex align-items-center justify-content-between" toolbar>
                    <h4><i-feather name="calendar"></i-feather> {{ 'Calendar' | translate }}</h4>

                    <a [href]="['/calendar/overview']" class="btn btn-default tx-10 tx-medium tx-color-03 tx-sans">
                    {{ 'Go to new calendar' | translate }}</a>
                </div>
            </div>

            <div content class="calendar row no-gutters">
                <div class="col-12">
                    <div class="calendar__header">
                        <div class="calendar__nav previous" (click)="showPreviousMonth()"><i class="fas fa-angle-left"></i> {{ 'Previous' | translate }}</div>
                        <h5 class="calendar__nav">
                            {{ monthLabel | amLocale: locale | amDateFormat: 'MMMM YYYY' }} <i-feather (click)="reload()" name="refresh-cw" class="calendar__refresh" [ngClass]="{'calendar__refresh--rotate': rotate}"></i-feather>
                        </h5>
                        <div class="calendar__nav next" (click)="showNextMonth()">
                            {{ 'Next' | translate }}
                            <i class="fas fa-angle-right"></i>
                        </div>
                    </div>
                    <div class="calendar__weekdays">
                        <div class="weekdayLabel {{ weekday }} day{{ i + 1 }}" *ngFor="let weekday of weekLabels; let i = index">{{ weekday }}</div>
                    </div>
                    <ng-container *ngIf="(currentMonth$ | async) as currentMonth; else loaderRef;">
                        <ng-container *ngIf="!loader; else loaderRef">
                        
                            <div class="calendar__daysContainer">
                                <div class="day gap day{{ gap.weekday }}" *ngFor="let gap of currentMonth.gaps"
                                    [style.height.px]="dayDetailsHeight * 40 + 28"></div>
                                <div
                                    *ngFor="let dayComposite of currentMonth.days"
                                    class="day day{{ dayComposite.weekday }}"
                                    [style.height.px]="dayDetailsHeight * 40 + 28"
                                    [class.today]="dayComposite.isToday"
                                    [class.future-day]="dayComposite.isFutureDay"
                                    [class.tomorrow-day]="dayComposite.isTomorrow"
                                    [class.past-day]="dayComposite.isPastDay"
                                    [class.active]="dayComposite.dayOfMonth == selectedDay?.dayOfMonth">
                                    <day [dayComposite]="dayComposite" (click)="showDetailsForDay(dayComposite)"></day>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #loaderRef>
                        <div class="load-spinner spinner">
                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                            <p class="spinner__info">{{ 'Loading, shifts for' | translate }}: <strong>{{ monthLabel | amLocale: locale | amDateFormat: 'MMMM YYYY' }}</strong></p>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div sidebar>
                <sidebar-with-header>
                    <div *ngIf="selectedDay !== null && selectedDay.dailyShiftConfig else noShifts" header>
                        <div  class="shift-menu">
                            <ng-container *ngFor="let shift of selectedDay.dailyShiftConfig.shifts | sortShiftByStartTimePipe">
                                <div [ngClass]="{ active: shift.fulfilment.shiftId.type === selectedShift, shift: true }" (click)="setShift(shift.fulfilment.shiftId.type)">
                                    <i-feather *ngIf="shift.shiftStatusData.status | isShiftOpenPipe" name="unlock" [style.height.px]="15" [style.width.px]="15" [style.color]="'green'"></i-feather>
                                    <i-feather *ngIf="!(shift.shiftStatusData.status | isShiftOpenPipe)" name="lock" [style.height.px]="15" [style.width.px]="15" [style.color]="'red'"></i-feather>
                                    {{ shift.fulfilment.shiftId.type | translate }}
                                    <span *ngIf="selectedDay" [class.green]="shift.shiftStatusData.status | isShiftOpenPipe" [class.red]="!shift.shiftStatusData.status | isShiftOpenPipe"></span>
                                </div>
                            </ng-container>
                            <div appCheckPrivileges [permissions]="[Permissions.Shift_planning_edit]" *ngIf="selectedDay && !selectedDay.isPastDay" class="shift" (click)="addingShift()">
                                <i-feather name="plus" [style.height.px]="15" [style.width.px]="15" [style.color]="'green'"></i-feather> {{'Add' | translate}}
                            </div>
                        </div>
                    </div>

                    <ng-template #noShifts>
                        <div *ngIf="selectedDay && !selectedDay.isPastDay" class="shift-menu" header>
                            <div class="shift" (click)="addingShift()">
                                <i-feather name="plus" [style.height.px]="15" [style.width.px]="15" [style.color]="'green'"></i-feather> {{'Add shift' | translate}}
                            </div>
                        </div>
                    </ng-template>

                    <div content>
                        <day-details *ngIf="selectedDay" [dayComposite]="selectedDay" [selectedShift]="selectedShift" (dayCompositeChanged)="onDayCompositeChanged($event)"></day-details>
                    </div>
                </sidebar-with-header>
            </div>
        </layout-with-sidebar>

        <ng-template #addingShiftDialog>
            <div class="modal-header">
                <h4 class="modal-title">{{'Adding shift' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
            </div>

            <div class="modal-body text-center">
                <app-shift-create [date]="(currentMonth.days.length) ? selectedDay : null" (updatedData)="shiftPlanningForm($event)"></app-shift-create>

                <button [routerLink]="['/calendar/shift-planning']" type="button" class="btn btn-default" (click)="decline()">{{'Shift planning list' | translate }}</button>

                <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{'Create shift' | translate }}
                </button>
            </div>
        </ng-template>

        <!-- {{'SHIFT_STATUS_CONFIGURING' | translate}} -->
        <!-- {{'SHIFT_STATUS_BOOKING' | translate}} -->
        <!-- {{'SHIFT_STATUS_PLANNING' | translate}} -->
        <!-- {{'SHIFT_STATUS_FULLFILLMENT' | translate}} -->
        <!-- {{'SHIFT_STATUS_DELIVERING' | translate}} -->
        <!-- {{'SHIFT_STATUS_COMPLETED' | translate}} -->
    `,
    styleUrls: ['calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
    private tag = '[CalendarComponent]';

    private readonly today: string;
    private selectedShift = undefined;
    private changedMonthlyShiftConfig = false;
    private __selectedMonthShiftConfig$: Subscription;
    public rotate: boolean = false;

    private warehouse;
    private __selectedWarehouse$: Subscription;

    public currentMonth$: Observable<MonthlyShiftConfig>;
    private currentMonth: MonthlyShiftConfig;

    public selectedDay: DayComposite = null;
    public weekLabels: any[] = [];
    public locale = '';
    public dayDetailsHeight: number = 0;
    public monthLabel: any;
    public Permissions = Permissions;
    public loader = true;
    private newShiftData: ShiftConfig;

    @ViewChild('addingShiftDialog', {static: true}) public addingShiftDialog: TemplateRef<any>;
    public modalRef: BsModalRef;
    public confirmObservable: Observable<any>;

    constructor(
        private readonly shifts: ShiftsService, 
        private readonly modalService: BsModalService,
        private readonly notifierService: NotifierService,
        private readonly appService: AppService, 
        private readonly shiftService: ShiftsService,
        private readonly translate: TranslateService) 
    {
        this.initLabels();
        this.today = moment().format('YYYY-MM-DD');
        this.monthLabel = moment();
        moment.locale(localStorage.getItem('lang'));
    }

    public ngOnInit() {
        this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.currentMonth$ = this.shifts.selectedMonthShiftConfig.pipe(
            tap(() => this.loader = true),
            map((monthlyShiftConfig: MonthlyShiftConfig) => {
                
                console.log(this.tag, 'received date', monthlyShiftConfig);
                this.dayDetailsHeight = 0;

                this.currentMonth = monthlyShiftConfig;

                _.forEach(monthlyShiftConfig.days, (day: DayComposite) => this.dayDetailsHeight = 
                    (day.dailyShiftConfig !== undefined && this.dayDetailsHeight < day.dailyShiftConfig.shifts.length) ? day.dailyShiftConfig.shifts.length : this.dayDetailsHeight);

                try {
                    this.showDetailsForDay(this.currentMonth.getDay(this.today));
                } catch (e) {
                    console.log('', e);
                }

                return monthlyShiftConfig;
            }),
            tap(() => this.loader = false)
        );

        this.warehouse = localStorage.getItem('depot');

        this.__selectedWarehouse$ = this.appService.selectedWarehouse.subscribe(warehouse => {
            console.log(`${this.tag} Changed warehouse location ${warehouse}`);
            this.warehouse = warehouse;
            const startdate = moment().startOf('month').format('YYYY-MM-DD');
            const enddate = moment().endOf('month').format('YYYY-MM-DD');
            this.shifts.loadMonthConfiguration(startdate, enddate, this.warehouse);
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            moment.locale(event.lang);
            this.initLabels();
        });

        //this.shifts.loadMonthConfiguration(moment().format('YYYY-MM-DD'), this.warehouse);
    }

    public ngOnDestroy() {
        if (this.__selectedMonthShiftConfig$) {
            this.__selectedMonthShiftConfig$.unsubscribe();
        }

        if (this.__selectedWarehouse$) {
            this.__selectedWarehouse$.unsubscribe();
        }

        if (this.shifts) {
            this.shifts.selectedMonthShiftConfigJSON = '';
        }
    }

    public initLabels() {
        this.weekLabels = moment.weekdaysShort();
        this.weekLabels.push(this.weekLabels.shift());
    }

    public showNextMonth() {
        this.loader = true
        this.monthLabel = moment(this.currentMonth.date).add(1, 'months').format('YYYY-MM-DD');
        const startdate = moment(this.currentMonth.date).add(1, 'months').startOf('month').format('YYYY-MM-DD');
        const enddate = moment(this.currentMonth.date).add(1, 'months').endOf('month').format('YYYY-MM-DD');
        this.shifts.loadMonthConfiguration(startdate, enddate, this.warehouse);
    }

    public showPreviousMonth() {
        this.loader = true
        let currentMonth = moment(this.currentMonth.date, 'YYYY-MM-DD');
        currentMonth = currentMonth.subtract(1, 'months');
        this.monthLabel = currentMonth.format('YYYY-MM-DD');

        const startdate = currentMonth.startOf('month').format('YYYY-MM-DD');
        const enddate = currentMonth.endOf('month').format('YYYY-MM-DD');
        this.shifts.loadMonthConfiguration(startdate, enddate, this.warehouse);
    }

    public reload() {
        const startdate = moment(this.currentMonth.date).startOf('month').format('YYYY-MM-DD');
        const enddate = moment(this.currentMonth.date).endOf('month').format('YYYY-MM-DD');
        this.shifts.loadMonthConfiguration(startdate, enddate, this.warehouse);
        this.rotate = !this.rotate;
    }

    public showDetailsForDay(day) {
        console.log(this.tag, '[showDetailsForDay]', day);
        this.selectedDay = !this.changedMonthlyShiftConfig ? day : this.selectedDay;
        this.selectedShift = (!this.selectedShift) ? (this.selectedDay.dailyShiftConfig && this.selectedDay.dailyShiftConfig.shifts) 
            ? this.selectedDay.dailyShiftConfig.shifts[0].fulfilment.shiftId.type : ShiftType.MORNING_1 : this.selectedShift;
        this.changedMonthlyShiftConfig = false;
    }

    public onDayCompositeChanged(dayComposite: DayComposite) {
        console.log(this.tag, dayComposite);
        this.changedMonthlyShiftConfig = true;
        const startdate = moment(this.currentMonth.date).startOf('month').format('YYYY-MM-DD');
        const enddate = moment(this.currentMonth.date).endOf('month').format('YYYY-MM-DD');
        this.shifts.loadMonthConfiguration(startdate, enddate, this.warehouse);

        if (this.selectedDay) {
            this.selectedDay = dayComposite;
        }
    }

    public isActiveShift(shiftType: ShiftType): boolean {
        return this.selectedShift === shiftType;
    }

    public setShift(shift: ShiftType) {
        this.selectedShift = shift;
    }

    public addingShift(id: string): void {
        this.modalRef = this.modalService.show(this.addingShiftDialog, { class: 'modal-lg' });
    }

    public confirm(): void {
        this.shiftService.addShift(this.newShiftData.shiftId).subscribe(() => {
            this.modalRef.hide();
            this.notifierService.notify('success', this.translate.instant('The shift has been added!'));
            this.confirmObservable = null;
            const startdate = moment(this.currentMonth.date).startOf('month').format('YYYY-MM-DD');
            const enddate = moment(this.currentMonth.date).endOf('month').format('YYYY-MM-DD');
            this.shifts.loadMonthConfiguration(startdate, enddate, this.warehouse);
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }

    public shiftPlanningForm(shift: any) {
        shift.defaultSettings.shiftId = `${shift.date}:${shift.warehouse}:${shift.shiftName}`;
        this.newShiftData = shift.defaultSettings;
    }
}
