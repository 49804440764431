<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode != 'CREATE'" class="text-left mg-b-0 tx-spacing--1"><i-feather name="edit-3"></i-feather> {{'Edit users role' | translate}}</h4>
            <h4 *ngIf="mode == 'CREATE'" class="text-left mg-b-0 tx-spacing--1"><i-feather name="plus-square"></i-feather> {{'Add new role' | translate}}</h4>
        </div>
        <div toolbar>
            <button class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-white"
                [routerLink]="['/users/roles']">{{'Cancel' | translate}}
            </button>
        </div>
        <hr />
    </div>
    <div content>
        <div class="content bg-white">

                <div class="card-content col-8 offset-2">
                    
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        
                        <div id="role-from-name" class="form-group row">
                            <label class="col-2" for="name">{{'Role name' | translate}}:</label>
                            <div class="col-10">
                                <input formControlName="name" class="form-control" />
                                <div *ngIf="form.get('name').hasError('required') && form.get('name').touched" class="error-color">
                                    {{ "Role name is required" | translate }}!
                                </div>
                            </div>
                        </div>

                        <div  class="form-group row">
                            <label class="col-2" for="permissions">{{'Roles' | translate}}:</label>
                            <div class="col-10 d-flex flex-wrap">
                                <ng-container formArrayName="permissions">
                                    <ng-container *ngFor="let p of permissionsCtrl.controls; let i=index">
                                   
                                            <div id="role-from-permissions-{{i}}" formGroupName="{{i}}" class="custom-control custom-checkbox pd-b-10 w-50">
                                                <input formControlName="enabled" [checked]="p.value.enabled" type="checkbox" class="custom-control-input" id="customCheck{{i}}">
                                                <label class="custom-control-label justify-content-start" for="customCheck{{i}}">{{'Role_permissions.'+p.value.name | translate}}</label>
                                            </div>
                                 
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div id="role-from-btn-submit" class="form-group row justify-content-end">
                            <div class="col-2">
                                <button [disabled]="!form.valid || submitted" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01 w-100">
                                    
                                    <span *ngIf="!submitted">{{ "Save" | translate }}</span>

                                    <ng-container *ngIf="submitted">
                                        <div class="load-spinner text-center">
                                            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                        </div>
                                    </ng-container>

                                </button>
                            </div>
                        </div>
                        
                    </form>
                </div>
        </div>
    </div>
</layout-default>

<!-- {{'Role_permissions.CREATE_USER' | translate}} -->
<!-- {{'Role_permissions.MODIFY_USER' | translate}} -->
<!-- {{'Role_permissions.DEACTIVATE_USER' | translate}} -->
<!-- {{'Role_permissions.GET_USER' | translate}} -->
<!-- {{'Role_permissions.CREATE_ROLE' | translate}} -->
<!-- {{'Role_permissions.MODIFY_ROLE' | translate}} -->
<!-- {{'Role_permissions.REMOVE_ROLE' | translate}} -->
<!-- {{'Role_permissions.ASSIGN_ROLE' | translate}} -->
<!-- {{'Role_permissions.LIST_ROLES' | translate}} -->
<!-- {{'Role_permissions.MODIFY_DELIVERY' | translate}} -->
<!-- {{'Role_permissions.ASSIGN_DELIVERY' | translate}} -->
<!-- {{'Role_permissions.ANALYTICS_DRIVERPUNCTUALITY_VIEW' | translate}} -->
<!-- {{'Role_permissions.ANALYTICS_ORDERSHISTORY_VIEW' | translate}} -->
<!-- {{'Role_permissions.ANALYTICS_PUNCTUALITY_VIEW' | translate}} -->
<!-- {{'Role_permissions.ANALYTICS_ROUTINGEFFICIENCY_VIEW' | translate}} -->
<!-- {{'Role_permissions.ANALYTICS_STATS2_VIEW' | translate}} -->
<!-- {{'Role_permissions.BOOKING_AVAILABILITY' | translate}} -->
<!-- {{'Role_permissions.CALENDAR_ADVANCEDSETTINGS' | translate}} -->
<!-- {{'Role_permissions.CALENDAR_BASICSETTINGS' | translate}} -->
<!-- {{'Role_permissions.CALENDAR_VIEW' | translate}} -->
<!-- {{'Role_permissions.CONFIG_DEFAULT_EDIT' | translate}} -->
<!-- {{'Role_permissions.CONFIG_DEFAULT_VIEW' | translate}} -->
<!-- {{'Role_permissions.CONFIG_PLANNINGSEQUENCE_EDIT' | translate}} -->
<!-- {{'Role_permissions.CONFIG_PLANNINGSEQUENCE_VIEW' | translate}} -->
<!-- {{'Role_permissions.DELIVERY_EDIT' | translate}} -->
<!-- {{'Role_permissions.DELIVERY_VIEW' | translate}} -->
<!-- {{'Role_permissions.DRIVER_EDIT' | translate}} -->
<!-- {{'Role_permissions.DRIVER_LOGIN' | translate}} -->
<!-- {{'Role_permissions.DRIVER_NOTIFY' | translate}} -->
<!-- {{'Role_permissions.DRIVER_PLANNING_EDIT' | translate}} -->
<!-- {{'Role_permissions.DRIVER_PLANNING_VIEW' | translate}} -->
<!-- {{'Role_permissions.DRIVER_SALARIES_EDIT' | translate}} -->
<!-- {{'Role_permissions.DRIVER_SALARIES_VIEW' | translate}} -->
<!-- {{'Role_permissions.DRIVER_VIEW' | translate}} -->
<!-- {{'Role_permissions.HARDWARE_EDIT' | translate}} -->
<!-- {{'Role_permissions.HARDWARE_VIEW' | translate}} -->
<!-- {{'Role_permissions.LOCATION_EDIT' | translate}} -->
<!-- {{'Role_permissions.LOCATION_VIEW' | translate}} -->
<!-- {{'Role_permissions.PAYMENTS_VIEW' | translate}} -->
<!-- {{'Role_permissions.PAYMENTS_ACCEPT' | translate}} -->
<!-- {{'Role_permissions.ROLES_VIEW' | translate}} -->
<!-- {{'Role_permissions.ROLES_EDIT' | translate}} -->
<!-- {{'Role_permissions.ROLES_ASSIGN' | translate}} -->
<!-- {{'Role_permissions.ROUTE_DELIVERY_ASSIGN' | translate}} -->
<!-- {{'Role_permissions.ROUTE_DELIVERY_UNASSIGN' | translate}} -->
<!-- {{'Role_permissions.ROUTE_VIEW' | translate}} -->
<!-- {{'Role_permissions.ROUTE_DRIVER_ASSIGN' | translate}} -->
<!-- {{'Role_permissions.ROUTE_DRIVER_UNASSIGN' | translate}} -->
<!-- {{'Role_permissions.ROUTE_EDIT' | translate}} -->
<!-- {{'Role_permissions.ROUTE_VAN_ASSIGN' | translate}} -->
<!-- {{'Role_permissions.ROUTE_VAN_UNASSIGN' | translate}} -->
<!-- {{'Role_permissions.SIMULATOR_VIEW' | translate}} -->
<!-- {{'Role_permissions.SIMULATOR_EDIT' | translate}} -->
<!-- {{'Role_permissions.USER_EDIT' | translate}} -->
<!-- {{'Role_permissions.USER_VIEW' | translate}} -->
<!-- {{'Role_permissions.VISUALISER_CUTOFF' | translate}} -->
<!-- {{'Role_permissions.VISUALISER_VIEW' | translate}} -->
<!-- {{'Role_permissions.APM_VIEW' | translate}} -->
<!-- {{'Role_permissions.APM_EDIT' | translate}} -->
<!-- {{'Role_permissions.PARTNER_VIEW' | translate}} -->
<!-- {{'Role_permissions.PARTNER_EDIT' | translate}} -->
<!-- {{'Role_permissions.PARTNERUSER_VIEW' | translate}} -->
<!-- {{'Role_permissions.PARTNERUSER_EDIT' | translate}} -->
<!-- {{'Role_permissions.DEPOT_EDIT' | translate}} -->
<!-- {{'Role_permissions.SHIFT_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_FULFILLMENT_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_FULFILLMENT_VIEW' | translate }} -->
<!-- {{'Role_permissions.SHIFT_INTEGRATION_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_INTEGRATION_VIEW' | translate }} -->
<!-- {{'Role_permissions.SHIFT_ROUTING_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_ROUTING_VIEW' | translate }} -->
<!-- {{'Role_permissions.SHIFT_PLANNING_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_PLANNING_FULFILLMENT_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_PLANNING_INTEGRATION_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_PLANNING_ROUTING_EDIT' | translate }} -->
<!-- {{'Role_permissions.APMDELIVERY_RESCHEDULE' | translate }} -->
<!-- {{'Role_permissions.MAINTENANCE_EDIT' | translate }} -->
<!-- {{'Role_permissions.MESSAGE_TEMPLATE_VIEW' | translate }} -->
<!-- {{'Role_permissions.MESSAGE_TEMPLATE_EDIT' | translate }} -->
<!-- {{'Role_permissions.BILLING_VIEW' | translate }} -->
<!-- {{'Role_permissions.RECEIVE_IMPORTANT_CUSTOMER_NOTIFICATION' | translate }} -->
<!-- {{'Role_permissions.APMDELIVERY_REBOOK' | translate }} -->
<!-- {{'Role_permissions.IMPORTJOB_VIEW' | translate }} -->
<!-- {{'Role_permissions.CUSTOMER_DELETE' | translate }} -->
<!-- {{'Role_permissions.RECEIVE_DELIVERY_FAILURE_NOTIFICATION' | translate }} -->
<!-- {{'Role_permissions.SHIFT_PLANNING_VIEW' | translate }} -->
<!-- {{'Role_permissions.APMDELIVERY_BILLING_EDIT' | translate }} -->
<!-- {{'Role_permissions.DASHBOARD_VIEW' | translate }} -->
<!-- {{'Role_permissions.ROUTE_CONSOLIDATE' | translate }} -->
<!-- {{'Role_permissions.IMPORTJOB_EDIT' | translate }} -->
<!-- {{'Role_permissions.SHIFT_CUTOFF_FORCE' | translate }} -->
<!-- {{'Role_permissions.SCHEDULE_EDIT' | translate }} -->
<!-- {{'Role_permissions.FILTER_WORD_VIEW' | translate }} -->
<!-- {{'Role_permissions.FILTER_WORD_EDIT' | translate }} -->
<!-- {{'Role_permissions.DASHBOARD_SALES_VIEW' | translate }} -->