import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// todo lw fix typo

@Injectable()
export class NavService {

    constructor(private _router: Router, private location: Location) {}

    public goToPage(pageId: any = '/', params?: Object, queryParams?: Object): Promise<boolean> {
        return this._router.navigate(params ? [pageId, params] : [pageId], queryParams ? {queryParams: queryParams} : {});
    }

    public navigateBack() {
        this.location.back();
    }

    get router(): Router {
        return this._router;
    }
}
