import { Component, OnInit } from '@angular/core';
import { Intergration } from '@calendar/interafces/integration.interface';
import { Observable } from 'rxjs';
import { ShiftPlanningWizardService } from '@calendar/services/shift-planning-wizard.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { NUMERIC_PATTREN } from '@shared/constants/utils.constants';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationShiftType } from '@enums/enum';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-shift-planning-edit-integration',
  templateUrl: './shift-planning-edit-integration.component.html',
  styleUrls: ['./shift-planning-edit-integration.component.scss']
})
export class ShiftPlanningEditIntegrationComponent implements OnInit {

  public integration$: Observable<Intergration>;
  private shiftDefaultSettings: ShiftUpdateDto;

  public form: FormGroup;
  public loader = true;
  public daysLabel = Array.from(Array(31).keys());
  public monthsLabel = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  public regularControlsNumber: string[] = ['bookingWindowRestricted', 'bookingWindowDayOffset', 'bookingWindowTimeOfDay', 'offset', 'routeNumberOffset', 'segmentNumberOffset', 'slotDuration', 'slotOverLapping', 'time']
  
  public bookingControls: string[] = ['bookingWindowRestricted', 'bookingWindowDayOffset', 'bookingWindowTimeOfDay'];
  public cutoffControls: string[] = ['shiftType', 'cutoffTimeout', 'syncDelay'];
  public cutoffBooleanControls: string[] = ['autoPromoteSolution', 'autoFinalizeSolution', 'fulfillmentIntegrationEnabled', 'allowDeliveryImport'];

  public slotControls: string[] = ['slotDuration', 'slotOverLapping'];
  public routeControls: string[] = ['routeNumberOffset', 'segmentNumberOffset'];

  public integrationShiftType = Object.values(IntegrationShiftType);
  public mode: string;

  constructor(
    private readonly shiftPlanningWizardService: ShiftPlanningWizardService,
    private readonly formBuilder: FormBuilder,
    private readonly navigationRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly utilsService: UtilsService
  ) { }

  public ngOnInit() {
    this.integration$ = this.shiftPlanningWizardService.getModel().pipe(
      tap((shiftPlanning: ShiftPlanning) => this.shiftDefaultSettings = shiftPlanning.defaultSettings),
      map((shiftPlanning: ShiftPlanning) => shiftPlanning.defaultSettings.shiftIntegrationData),
      tap((integration: Intergration) => this.initForm(integration)),
    );

    this.mode = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'action');
  }

  public initForm(integration: Intergration) {
    this.form = this.formBuilder.group({

      bookingWindowDayOffset: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      bookingWindowRestricted: [null, [Validators.required]],
      bookingWindowTimeOfDay: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      // offset: [null, [Validators.pattern(NUMERIC_PATTREN)]],
      routeNumberOffset: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(0)]],
      segmentNumberOffset: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(0)]],
      slotDuration: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(10), Validators.max(600)]],
      slotOverLapping: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      autoPromoteSolution: [null, Validators.required],
      autoFinalizeSolution: [null, Validators.required],
      fulfillmentIntegrationEnabled: [null, Validators.required],
      allowDeliveryImport: [false, Validators.required],
      shiftType: [{value: null, disabled: this.mode !== 'create'}, [Validators.required]],
      isServiceShift: [false, Validators.required],
      // time: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN)]],
      cutoffTimeout: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(0)]],
      syncDelay: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(0)]],
      version: [],
    });
    
    this.form.patchValue(integration);
    console.log(integration);

    this.form.controls['shiftType'].valueChanges.subscribe((value: IntegrationShiftType) => {
      if (value === IntegrationShiftType.EXPRESS) {
        this.form.get('time').disable();
        this.form.get('offset').disable();
      } else {
        this.form.get('time').enable();
        this.form.get('offset').enable();
      }
    });


    this.loader = false;

  }

  public tooltipLabel(property: string): string {
    return tooltipLabel(this.translateService, `tooltips.shift.labels.${property}`);
  }

  public update() {


    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    console.log(this.form);
    if (this.form.invalid) {      
      return;
    }

    if (this.form.valid) {

      this.shiftPlanningWizardService.updateModel({
        defaultSettings: {
          shiftFulfillmentData: this.shiftDefaultSettings.shiftFulfillmentData,
          shiftIntegrationData: this.form.getRawValue() as Intergration,
          shiftRoutingData: this.shiftDefaultSettings.shiftRoutingData,
          shiftStatusData: this.shiftDefaultSettings.shiftStatusData,
          shiftCutoffData: this.shiftDefaultSettings.shiftCutoffData,
          shiftId: this.shiftDefaultSettings.shiftId,
          version: this.shiftDefaultSettings.version
        } as Partial<ShiftUpdateDto>
      } as Partial<ShiftPlanning>);

      this.router.navigate(['../step-4'], { relativeTo: this.navigationRoute });
    } else {
      console.log(this.form.errors);
    }
  }

}
