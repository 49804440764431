import { Pipe, PipeTransform } from '@angular/core';
import { UserPanel } from '../entities/user-panel';
import { Roles } from '@enums/enum';

@Pipe({
    name: 'isUserSysadminPipe'
})
export class IsUserSysadminPipe implements PipeTransform {
    public transform(user: UserPanel): boolean {
        return user.roles.find(i => i === Roles.ROLE_SYSADMIN) !== undefined;
    }
}
