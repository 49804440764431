import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideFadeinRight } from '@shared/animations/router.animation';
@Component({
    template: `
    <notifier-container id="notifier-container" class="d-print-none tx-uppercase"></notifier-container>
    <div [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')">
        <router-outlet #outlet="outlet"></router-outlet>         
    </div>
    `,
    animations: [slideFadeinRight]

})
export class ProxyComponent {

    public routeAnimation(outlet: RouterOutlet, animation: string) {
        if (animation === 'slideFadeinRight') {
            return outlet.isActivated && outlet.activatedRoute.routeConfig.path
        }
    }

}
