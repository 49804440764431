import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { TooltipModule } from 'ngx-bootstrap';
import { SettingsRouting } from './settings.routing';
import { SmsTemplatesComponent } from './components/sms-templates/sms-templates.component';
import { SmsTemplatesResolver } from './resolvers/sms-templates.resolver';
import { SmsTemplatesService } from './services/sms-templates.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SmsTemplatesEditComponent } from './components/sms-templates-edit/sms-templates-edit.component';
import { SmsTemplateResolver } from './resolvers/sms-template.resolver';

@NgModule({
    imports: [
        CommonModule, 
        SharedModule, 
        TooltipModule.forRoot(),
        RouterModule, 
        SettingsRouting,
        AuthenticationModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        TranslateModule.forChild({})
    ],
    declarations: [
        SmsTemplatesComponent,
        SmsTemplatesEditComponent
    ],
    providers: [
        SmsTemplatesResolver,
        SmsTemplateResolver,
        SmsTemplatesService
    ]
})
export class SettingsModule { }
