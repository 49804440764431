import { Component, OnInit } from '@angular/core';
import { DateRange } from '@enums/enum';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { VehicleDistanceInterface } from '../../interfaces/vehicle-distance.interface';
import { DashboardService } from '../../services/dashboard.service';
import { VehicleDistanceQueryParams } from '../../interfaces/vehicle-distance-queryparams.interface';
import { Depot } from '@interfaces/depot.interface';
import { DepotService } from 'src/app/locations/services/depot.service';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-dashboard-vehicle-distance',
  templateUrl: './dashboard-vehicle-distance.component.html',
  styleUrls: ['./dashboard-vehicle-distance.component.scss']
})
export class DashboardVehicleDistanceComponent implements OnInit {
  public depots$: Observable<Depot[]>;
  public data$: Observable<VehicleDistanceInterface[]>

  public dateRange: DateRange = DateRange.TWO_WEEKS;
  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings = {};
  private dataToExport: any[] = [];
 
  public columns = [
    { prop: 'code', name: this.translate.instant('Depot'), flexGrow: 3, sortable: true },
    { prop: 'date', name: this.translate.instant('Date'), flexGrow: 2, sortable: true },
    { prop: 'registration', name: this.translate.instant('Registration'), flexGrow: 4, sortable: true },
    { prop: 'routeNumber', name: this.translate.instant('Route number'), flexGrow: 2, sortable: true },
    { prop: 'shift', name: this.translate.instant('Shift'), flexGrow: 2, sortable: true },
    { prop: 'deliveries', name: this.translate.instant('Deliveries'), flexGrow: 2, sortable: true },
    { prop: 'travelTime', name: this.translate.instant('Travel time'), flexGrow: 2, sortable: true },
    { prop: 'parkingTime', name: this.translate.instant('Parking time'), flexGrow: 2, sortable: true },
    { prop: 'deliveryTime', name: this.translate.instant('Delivery time'), flexGrow: 2, sortable: true },
    { prop: 'departureDelay', name: this.translate.instant('Departure delay'), flexGrow: 3, sortable: true },
    { prop: 'km', name: this.translate.instant('Distance KM'), flexGrow: 2, sortable: true },
  ];

  public queryParams: VehicleDistanceQueryParams = {
    startDate: '',
    endDate: '',
    depotIds: [],
    shortRouteSkipThrehold: 5
  };

  public tableMessages = {
    'emptyMessage': this.translate.instant('No data to display')
  };

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly depotService: DepotService,
    private readonly translate: TranslateService,
  ) { }

  public ngOnInit() {
    this.depots$ = this.depotService.getDepotsForUser().pipe(tap((depots: Depot[]) => {
      this.initDropdownListValues(depots);
    }));
  }

  public changedDateRange(event) {
    this.queryParams.startDate = moment(event.dateStart).format('YYYY-MM-DD');
    this.queryParams.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
    this.dateRange = event.dateRange;
    this.loadData();

  }

  private loadData() {
    this.queryParams.depotIds = this.selectedItems.map((a) => a.item_id);
    this.data$ = this.dashboardService.getVehiclesDistanceData(this.queryParams).pipe(tap((data: VehicleDistanceInterface[]) => {
      
      this.dataToExport = [];
      this.dataToExport.push([
        this.translate.instant('Depot'),
        this.translate.instant('Date'),
        this.translate.instant('Registration'),
        this.translate.instant('Route number'),
        this.translate.instant('Shift'),
        this.translate.instant('Deliveries'),
        this.translate.instant('Travel time'),
        this.translate.instant('Parking time'),
        this.translate.instant('Delivery time'),
        this.translate.instant('Departure delay'),
        this.translate.instant('Distance KM'),
      ]);

      data.forEach((r) => {
          this.dataToExport.push([
            r.code,
            r.date,
            r.registration,
            r.routeNumber,
            r.shift,
            r.deliveries,
            r.travelTime,
            r.parkingTime,
            r.deliveryTime,
            r.departureDelay,
            r.km
          ]);
      });
      
    }));
  }

  public initDropdownListValues(depot: Depot[]): void {
    this.dropdownList = [];

    depot.forEach((d: Depot) => {
        this.dropdownList.push({ item_id: d.id, item_text: d.code });
    });

    this.selectedItems = this.queryParams.depotIds = this.dropdownList;

    this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: this.translate.instant('Select All'),
        unSelectAllText: this.translate.instant('UnSelect All'),
        itemsShowLimit: 3,
        allowSearchFilter: false,
    };
  }

  public export(): void {
    const filename: string = `Report-${this.queryParams.startDate}-${this.queryParams.endDate}`.substring(0, 20);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename + '.xlsx');
  }
}
