import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'foldable-container',
    styleUrls: ['foldable-container.component.scss'],
    template: `
        <div class="{{clazz}}"
             [class.folded]="isFolded && !showFoldedTitle"
             [class.foldedWithoutTitle]="isFolded && showFoldedTitle">
            <div class="titleContainer">
                <ng-content select=".title"></ng-content>

                <div class="actions clickable">
                    <!--*ngIf="toggleIcon"-->
                    <i (click)="toggleFold($event)" class="fa" [class.fa-chevron-circle-left]="isFolded" [class.fa-chevron-circle-down]="!isFolded" aria-hidden="true"></i>
                </div>
            </div>

            <div class="contentContainer">
                <ng-content select=".content"></ng-content>
            </div>

            <div class="buttonContainer">
                <ng-content select=".buttons"></ng-content>
            </div>
        </div>
    `
})

export class FoldableContainerComponent {
    @Input() private hostClass: string = '';

    @Input('class') public clazz: string = '';
    @Input() public showFoldedTitle: boolean = false;
    @Input() public toggleIcon: boolean = false;
    @Input() public isFolded: boolean = false;

    @Output('isFolded') private isFoldedEmitter: EventEmitter<boolean> = new EventEmitter();

    public toggleFold(event: Event) {
        event.stopPropagation();
        this.isFolded = !this.isFolded;
        this.isFoldedEmitter.next(this.isFolded);
    }
}
