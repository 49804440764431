import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { PlanningSequenceService } from 'src/app/visualiser/services/planning-sequence.service';
import { PlanningSequence } from 'src/app/visualiser/entities/planning-sequence';
import { NavService } from '@services/nav.sevice';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-planning-sequence',
  templateUrl: './planning-sequence.component.html',
  styleUrls: ['./planning-sequence.component.scss']
})
export class PlanningSequenceComponent implements OnInit, OnDestroy {

  constructor(
    private ps: PlanningSequenceService, 
    private modalService: BsModalService,
    private notifierService: NotifierService,
    private translateService: TranslateService,
    private navService: NavService, 
    private translate: TranslateService,
    private navigationRoute: ActivatedRoute) {
      this.locale = this.translate.currentLang === 'en' ? enLocale : plLocale;
  }

  private locale = plLocale;
  private getPlanningSequencesSubscription: Subscription;
  public planningSequence: PlanningSequence[];
  private routingSubscription: Subscription;
  private modifiedPlanningId: string;
  public loader: boolean = true;
  public error: boolean = false;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public ngOnInit() {
    this.getPlanningSequences();
    this.modifiedPlanningId = this.navigationRoute.snapshot.params.planningId;
    console.log(this.modifiedPlanningId, this.navigationRoute.snapshot);
  }

  private getPlanningSequences(): void {
    this.unSubscribe();
    this.planningSequence = [];
    this.error = false;
    this.getPlanningSequencesSubscription = this.ps.getPlanningSequences()
      .subscribe((planner: PlanningSequence[]) => {
        this.planningSequence = planner;
        this.loader = false;
      },
      (error) => {
        this.error = true;
        this.loader = false;
      })
  }

  public goToEdit(id: string): void {
    this.navService.goToPage(`visualiser/planning-sequence/edit/${id}`);
  }

  public removePlanning(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.ps.removePlanning(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe(() => {
      this.modalRef.hide();
      this.getPlanningSequences();
      this.notifierService.notify('success', this.translateService.instant('Planning sequence has been removed'));
      this.confirmObservable = null;
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  private unSubscribe(){
    if (this.getPlanningSequencesSubscription) {
      this.getPlanningSequencesSubscription.unsubscribe();
    }
  }

  public ngOnDestroy() {
    this.unSubscribe();
  }
}
