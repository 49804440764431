<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="users"></i-feather> {{'Drivers and schedule' | translate}}
            </h4>
        </div>

        <div toolbar>
            <a id="drivers-availability-btn-print" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01"
               [routerLink]="['/drivers/print-l/drivers-schedule/print', {'startdate': startDate, 'enddate': endDate}]" target="_blank">
                <i-feather name="printer"></i-feather> {{'Print' | translate}}
            </a>
            <div id="drivers-availability-btn-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>

        <hr>
    </div>

    <div content>
        <div class="cal-header">
            <div [ngClass]="{'invisible':!isBefore}" id="drivers-availability-btn-previous" class="cal-nav previous" (click)="showSchedule(-6)">
            <span>
              <i class="fas fa-angle-left"></i>{{'Previous' | translate}}
            </span>
            </div>
            <div>
                <h5 class="cal-nav">{{'Schedule availability of drivers' | translate}}</h5>
                <div id="drivers-availability-select-planning-mode" *ngFor="let mode of driversPlanningMode" class="form-check form-check-inline">
                    <input [(ngModel)]="driversPlanningModeValue" class="form-check-input" [id]="mode" type="radio" [value]="mode">
                    <label class="form-check-label" [for]="mode">{{mode + '_DRIVER' | translate}}</label>
                </div>
            </div>
            <div id="drivers-availability-btn-next" class="cal-nav next" (click)="showSchedule(6)">{{'Next' | translate}} <i class="fas fa-angle-right"></i></div>
        </div>

        <table *ngIf="driversAvailability && !loading" class="table table-bordered">
            <thead>
                <tr>
                    <th width="30%" scope="col">{{ 'Driver' | translate }}</th>
                    <th width="10%" scope="col" *ngFor="let day of driversAvailability.shiftSummaryList">
                        <p class="day-name text-center">
                            {{day.date | amLocale: locale | amDateFormat:'ddd, D MMM' }}
                        </p>
                        <div class="shifts">
                            <div class="shift-type text-center" *ngFor="let type of day.shifts"
                                [containerClass]="'pretty-tooltip'" [tooltip]="(type | translate)"
                                 (click)="sortDrivers(day.date, type)">
                                {{type.slice(0, 1) | translate}}
                                <strong class="d-block">({{driversNumberPerShift(day.date, type)}})</strong>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let driver of drivers">
                <td id="drivers-availability-table-col-name" width="30%" scope="col" class="driver-name">
                    <div class="label"><strong>{{driver.label}}</strong><span *ngIf="driver.phoneNumber">, {{'phone-short' | translate}}:
                        {{driver.phoneNumber}}</span>
                    </div>
                   
                    
                    <div id="drivers-availability-table-col-shifts" class="no-print">
                        <button class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-white"
                                (click)="showMonthSchedule(driver)">{{'Show monthly schedule' | translate}}</button>
                        <button *ngFor="let type of driversAvailability.shiftSummaryList[0].shifts" class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-white"
                                (click)="setShift(driver.id, type)">{{type | translate}}</button>
                    </div>
                </td>
                <td id="drivers-availability-table-col-availability-summary" *ngFor="let day of driversAvailability.shiftSummaryList" [style.height.%]="100">
                    <div class="shifts" [style.height.%]="100">
                        <div *ngFor="let type of day.shifts; let index = index" class="shift-type text-center"
                             (click)="change(driver.id, day.date, type, warehouse)" [style.height.%]="100">

                            <ng-container *ngTemplateOutlet="templateRef; context: {$implicit:isAvailable(driver.id, day.date, type)}"></ng-container>

                            <ng-template #templateRef let-default>
                                <div *ngIf="default && default.mode === 'SERVICE'" class="driver-availability active service">
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="settings"></i-feather>
                                </div>
                                <div *ngIf="default && (default.mode === 'REGULAR' || default.mode === null)"
                                     class="driver-availability active regular">
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="truck"></i-feather>
                                </div>
                                <div *ngIf="default && (default.mode === 'VACATION' || default.mode === null)"
                                     class="driver-availability vacation">
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="sun"></i-feather>
                                </div>
                                <div *ngIf="default && (default.mode === 'SICKLEAVE' || default.mode === null)"
                                     class="driver-availability sickleave">
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="thermometer"></i-feather>
                                </div>
                                <div *ngIf="default && (default.mode === 'SUPPLY' || default.mode === null)"
                                    class="driver-availability supply">
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="clipboard"></i-feather>
                                </div>
                                <div *ngIf="!default" class="driver-availability">
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="x"></i-feather>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <div *ngIf="!driversAvailability" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
        </div>

        <div *ngIf="showMonthlySchedule && monthSchedule" class="monthly-schedule mb-4">
            <div class="cal-header">
                <div class="cal-nav previous" (click)="monthlySchedulePrevMonth()">
                    <i class="fas fa-angle-left"></i>{{'Previous' | translate}}
                </div>

                <div>
                    <h5 class="cal-nav">{{selectedDriver.firstName}} {{selectedDriver.lastname}},
                        {{daysInMonth[0] | amLocale: locale | amDateFormat:'MMMM YYYY' }}
                    </h5>
                    <button (click)="removeFilters()" class="btn btn-xs pd-x-15 btn-uppercase mg-l-5 btn-white btn-hide-schedule">{{'Hide schedule' | translate}}</button>
                </div>
                <div class="cal-nav next" (click)="monthlyScheduleNextMonth()">
                    {{'Next' | translate}} <i class="fas fa-angle-right"></i>
                </div>
            </div>

            <table class="table text-center month-schedule">
                <ng-container *ngFor="let index of [0, 10, 20, 30]">
                    <thead>
                        <tr class="text-center">
                            <th *ngFor="let day of daysInMonth.slice(index, index + 10)">{{day | amLocale: locale | amDateFormat:'D.MM' }}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td *ngFor="let day of daysInMonth.slice(index, index + 10); let index = index">
                                <div class="shifts" [style.height.%]="100">
                                
                                <ng-container *ngIf="findShifts(day) as day">
                                    
                                    <div *ngFor="let type of day.shifts" class="shift-type text-center"
                                        (click)="change(selectedDriver.id, day.date, type, warehouse)"
                                        [style.height.%]="100">
                                        <!-- <ng-template [ngIf]="isAvailable2(selectedDriver.id, shiftWithStatusses.shift.date, shiftWithStatusses.shift.type)" [ngIfElse]="isNotAvailable">
                                            <div class="driver-availability active">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="check"></i-feather>
                                            </div>
                                        </ng-template>
                                        <ng-template #isNotAvailable>
                                            <div class="driver-availability">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="x"></i-feather>
                                            </div>
                                        </ng-template> -->



                                        <ng-container *ngTemplateOutlet="templateRef; context: {$implicit:isAvailable(selectedDriver.id, day.date, type)}"></ng-container>

                                        <ng-template #templateRef let-default>
                                            <div *ngIf="default && default.mode === 'SERVICE'" class="driver-availability active service">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="settings"></i-feather>
                                            </div>
                                            <div *ngIf="default && (default.mode === 'REGULAR' || default.mode === null)"
                                                class="driver-availability active regular">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="truck"></i-feather>
                                            </div>
                                            <div *ngIf="default && (default.mode === 'VACATION' || default.mode === null)"
                                                    class="driver-availability vacation">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="sun"></i-feather>
                                            </div>
                                            <div *ngIf="default && (default.mode === 'SICKLEAVE' || default.mode === null)"
                                                    class="driver-availability sickleave">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="thermometer"></i-feather>
                                            </div>
                                            <div *ngIf="default && (default.mode === 'SUPPLY' || default.mode === null)"
                                                class="driver-availability supply">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="clipboard"></i-feather>
                                            </div>
                                            <div *ngIf="!default" class="driver-availability">
                                                <i-feather [style.width.px]="18" [style.height.px]="18" name="x"></i-feather>
                                            </div>
                                        </ng-template>



                                    </div>
                                </ng-container>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
        </div>
    </div>
</layout-default>

<!-- 
  {{'M' | translate}}
  {{'A' | translate}}
  {{'E' | translate}}
  {{'SERVICE_DRIVER' | translate}}
  {{'REGULAR_DRIVER' | translate}}
  {{'VACATION_DRIVER' | translate}}
  {{'SICKLEAVE_DRIVER' | translate}}
  {{'SUPPLY_DRIVER' | translate}}

 -->