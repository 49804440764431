
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Fleet size' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">
                <div *ngFor="let fleet of shiftConfig.fleet" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>{{ fleet.vehicleType.code }}:</div> 
                    <div class="modify-value">{{ fleet.max  }}</div>
                </div>
              
            </div>
        </div>

        <div *ngIf="mode == 'edit' || mode == 'saving'" [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <h3 class="title">{{ 'Fleet size' | translate }}</h3>

                <div *ngIf="(vehicleTypes$ | async) as vehicleTypes" class="form">
                    
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <div *ngFor="let fleet of editedShiftConfig.fleet; let index = index">
                        
                        <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                            <div [style.width.%]="30">{{ 'Code' }}:</div> 
                            <div [style.width.%]="70" class="modify-value">
                                <select id="vehicleTypeCode" [(ngModel)]="editedShiftConfig.fleet[index].vehicleTypeId">
                                    <option *ngFor="let option of vehicleTypes" [value]="option.id">{{ option.code | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                            <div [style.width.%]="30">{{ 'Max' }}:</div> 
                            <input 
                                [style.margin-right.px]="0" 
                                [style.width.%]="70" id="fleet{{index}}" 
                                type="number" min="0" [(ngModel)]="editedShiftConfig.fleet[index].max" />
                        </div>

                        <button (click)="removeFleet(index)" class="btn btn-xs btn-info">{{'Remove' | translate}}</button>

                        <hr />
                        
                    </div>
                    <button (click)="addFleet()" class="btn btn-xs btn-primary">{{'Add' | translate}}</button>

                </div>
            </editor-window>
        </div>
    