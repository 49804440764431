
        <layout-default>
            <div header>
                <div toolbar>
                    <h4><i-feather name="smartphone"></i-feather> {{ 'Hardware usages' | translate }}</h4>
                </div>

                <div toolbar>
                    <search-hardware *ngFor="let option of searchHardwareBy" [mode]="option" [pageInfo]="pageInfo"></search-hardware>
                </div>
            </div>
            <div content class="content bg-white">
                <div *ngIf="!loading && hardwareUsages">
                    <ngx-datatable
                        id="hardware-table"
                        class="bootstrap"
                        [rows]="hardwareUsages"
                        [columns]="columns"
                        [columnMode]="'flex'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [messages]="tableMessages"
                        [rowHeight]="'auto'"
                        [externalPaging]="true"
                        [count]="pagable.totalElements"
                        [offset]="page"
                        [limit]="size"
                        [scrollbarH]="scrollBarHorizontal"
                        (page)="setPage($event)"
                    >
                        <ng-container *ngFor="let col of columns">
                            <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                                <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                    <b>{{ col.name | translate }}</b>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="isHardwareTypeColumn(col.prop)">{{ value | translate }}</span>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="isColumnShift(col.prop)">{{ isColumnShiftValue(row) }}</span>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="isTimeColumn(col.prop)">{{ formatDate(value) }}</span>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="!isHardwareTypeColumn(col.prop) && !isTimeColumn(col.prop) && !isColumnShift(col.prop)">{{ value }}</span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                    </ngx-datatable>
                </div>

                <div *ngIf="loading" class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}</div>
            </div>
        </layout-default>
    