import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateRange } from '@enums/enum';
import { Subject } from 'rxjs/internal/Subject';
import { AnalyticsService } from '@analytics/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import { Observable } from 'rxjs/internal/Observable';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { map } from 'rxjs/internal/operators/map';
import { OrdersReport } from '@analytics/interfaces/orders-report.interface';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit, OnDestroy {

  public loader: boolean = true;
  public startDate;
  public endDate;
  public dateRange: DateRange;
  public Permissions = Permissions;
  public locale = null;
  public defaultDateRange: DateRange = DateRange.LAST_30_DAYS;
  public aggregation: 'DAYS' | 'WEEKS' | 'MONTHS' = "DAYS";

  public lineChartSeries: {name: string, series: {name: string; value: number | string}[]}[] = [
    {name: this.translateService.instant('Average orders value'), series: [] },
    {name: this.translateService.instant('Average skus'), series: [] }

  ]

  public barChart: {name: string, value: number}[] = [];

  public view = [null, null];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public legendTitle = 'Legend';
  public legendPosition = 'right';
  public showXAxisLabel = false;
  public xAxisLabel = 'Country';
  public showYAxisLabel = true;
  public yAxisLabel = this.translateService.instant('Deliveries');
  public showGridLines = true;
  public innerPadding = '10%';
  public animations: boolean = true;

  public lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#5AA454', '#E6A400']
  };

  public comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#dfe3e9']
  };
  
  private destroy$ = new Subject<boolean>();
  public entity$: Observable<any[]>;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  public columns = [
    { prop: 'date', name: this.translateService.instant('Date'), flexGrow: 2 },
    { prop: 'deliveries', name: this.translateService.instant('Deliveries'), flexGrow: 2 },
    { prop: 'averageSkus', name: this.translateService.instant('Avarage skus'), flexGrow: 2 },
    { prop: 'averageValue', name: this.translateService.instant('Avarage value'), flexGrow: 2 },
    { prop: 'value', name: this.translateService.instant('Total value'), flexGrow: 2 },
  ];
  
  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly translateService: TranslateService,
    private readonly decimalPipe: DecimalPipe
  ) { }

  public ngOnInit() {
    this.locale = this.translateService.currentLang === 'en' ? enLocale : plLocale;
  }

  public changedDateRange(event) {
    this.loader = true;
    this.startDate = moment(event.dateStart).format('YYYY-MM-DD');
    this.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
    this.dateRange = event.dateRange;
    this.getStats();
  }

  public changedAggregation(aggregation: 'DAYS' | 'WEEKS' | 'MONTHS') {
    this.aggregation = aggregation;
    this.getStats();
  }

  private getStats() {
    this.entity$ = this.analyticsService.getOrdersReport(this.startDate, this.endDate, this.aggregation).pipe(
      takeUntil(this.destroy$),
      map((statsResults: OrdersReport[]) => {
        this.lineChartSeries[0].series = [];
        this.lineChartSeries[1].series = [];


        this.barChart = [];
        

        statsResults.map((s: OrdersReport) => {
          let name = '';

          switch (this.aggregation) {
            case 'MONTHS' :
              name = moment(s.date).format('MM-YYYY')
            break;
            case 'WEEKS' :
              name = `${s.date} - ${moment(s.date).add(7, 'days').format('YYYY-MM-DD')}`
            break;
            default: 
              name = s.date;
          }

          this.lineChartSeries[0].series.push({
            name: name,
            value: s.averageValue
          });

          this.lineChartSeries[1].series.push({
            name: name,
            value: s.averageSkus
          });

          this.barChart.push({name: name, value: s.deliveries});

          s.date = name;
          
        });
       
        this.loader = false;

        return statsResults;
      })
    );
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
