import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageableResponse } from '@entities/pagable-response';
import { SearchHardwareBy } from '@enums/enum';
import { UtilsService } from '@services/utils.service';

import { Hardware } from '../entities/hardware';
import { HardwareUsages } from '../entities/hardware-usages';
import { Van } from '../entities/van';
import { environment } from '@environment';

@Injectable()
export class HardwareService {
    public static LIST_VANS: string = 'van/v2';
    public static ALL_VANS_BY_DEPOT = 'van/v1/depot'
    public static CREATE_VAN: string = 'van/v2';
    public static UPDATE_VAN: string = 'van/v2/${vanId}';
    public static VAN: string = 'van/v2/${vanId}';
    public static REMOVE_VAN: string = 'van/v1/${vanId}';
    public static GET_HARDWARE: string = 'hardware/v1/${hardwareId}';
    public static UPDATE_HARDWARE: string = 'hardware/v1/${hardwareId}';
    public static LIST_HARDWARE: string = 'hardware/v1';
    public static REMOVE_HARDWARE: string = 'hardware/v1/${hardwareId}';
    public static GET_HARDAWARE_USAGES_BY_SHIFT: string = 'hardware/v1/usage/shift/${q}?q=${q}&size=${size}&sort=${sort}&order=${order}&page=${page}';
    public static GET_HARDAWARE_USAGES_BY_HARDWARE_ID: string = 'hardware/v1/usage/id/${hardwareId}';
    public static GET_HARDAWARE_USAGES_BY_DRIVER_ID: string = 'hardware/v1/usage/driver/${driverId}';
    public static GET_HARDAWARE_USAGES_BY_DATE: string = 'hardware/v1/usage/date/${q}?size=${size}&sort=${sort}&order=${order}&page=${page}';
    public static GET_HARDAWARE_USAGES_BY_INVENTORY_NO: string = 'hardware/v1/usage/inventoryno-search?q=${q}&size=${size}&sort=${sort}&order=${order}&page=${page}';
    public static GET_HARDAWARE_USAGES_BY_DRIVER_NAME: string = 'hardware/v1/usage/search?q=${q}&size=${size}&sort=${sort}&order=${order}&page=${page}';
    public static HARDWARE_OWNER_DICTIONARY: string = '/dictionary/v1/VanOwner';
    
    public isSearchingBy: SearchHardwareBy = null;
    public vansSource: BehaviorSubject<Van[]> = new BehaviorSubject<Van[]>([]);
    public vans: Observable<Van[]> = this.vansSource.asObservable();

    public hardwareUsageSource: BehaviorSubject<PageableResponse<HardwareUsages> | null> = new BehaviorSubject<PageableResponse<HardwareUsages> | null>(null);
    public hardwareUsage: Observable<PageableResponse<HardwareUsages> | null> = this.hardwareUsageSource.asObservable();

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(private http: HttpClient, private utilsService: UtilsService) {
        this.loadVans();
    }

    public loadVans(depotId?: string) {
        const depot = depotId ? depotId : localStorage.getItem('depot');
        this.getVans(depot)
            .pipe(
                map((vans: Van[]) => {
                    this.vansSource.next(vans);
                })
            )
            .subscribe();
    }

    public getHardwareOwner(): Observable<string[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}${HardwareService.HARDWARE_OWNER_DICTIONARY}`, { });
        return this.http.get<string[]>(endpoint);
    }

    public getVans(depot: string) {
        const endpointVan = `${this.host}${this.prefix}/${HardwareService.ALL_VANS_BY_DEPOT}/${depot}`;
        const endpointHardware = `${this.host}${this.prefix}/${HardwareService.LIST_HARDWARE}?depotId=${depot}`;

        const van = this.http.get(endpointVan).pipe(
            map((response: any[]) => {
                return response
                    .map((rawVan: any) => {
                        return new Van().deserialize(rawVan);
                    })
                    .sort((a: Van, b: Van) => {
                        return a.registration.localeCompare(b.registration);
                    });
            })
        );

        const hardware = this.http.get(endpointHardware).pipe(map((hardwareResponse: any[]) => plainToClass(Hardware, hardwareResponse)));

        return forkJoin([van, hardware]).pipe(
            map((responses: any) => {
                return _.flatten(responses);
            })
        );
    }

    public getAllVans(): Observable<Van[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.LIST_VANS}`, { });
        return this.http.get<Van[]>(endpoint);
    }

    public getAllHardware(): Observable<Hardware[]> {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.LIST_HARDWARE}`, { });
        return this.http.get<Hardware[]>(endpoint);
    }

    public updateVan(van: Van) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.UPDATE_VAN}`, { vanId: van.id });

        const vanData = van.serialize();
        // delete vanData.id;

        return this.http.put(endpoint, vanData).pipe(
            map(response => {
                this.loadVans();
                return response;
            })
        );
    }

    public createVan(van: Van) {
        const endpoint = `${this.host}${this.prefix}/${HardwareService.CREATE_VAN}`;

        const vanData = van.serialize();
        delete vanData.id;
        delete vanData.version;

        return this.http.post(endpoint, vanData).pipe(
            map(response => {
                const newVan = new Van().deserialize(response);
                this.loadVans();
                return newVan;
            })
        );
    }

    public createHardware(hardware: Hardware) {
        const endpoint = `${this.host}${this.prefix}/${HardwareService.LIST_HARDWARE}`;

        delete hardware.id;
        delete hardware.version;
        delete hardware.createdAt;
        delete hardware.modifiedAt;

        const body = JSON.stringify(hardware);

        return this.http.post(endpoint, body, this.utilsService.httpHeaders()).pipe(
            map(response => {
                this.loadVans();
                return plainToClass(Hardware, response);
            })
        );
    }

    public updateHardware(hardware: Hardware) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.UPDATE_HARDWARE}`, { hardwareId: hardware.id });

        return this.http.put(endpoint, hardware).pipe(
            map(response => {
                this.loadVans();
                return response;
            })
        );
    }

    public removeHardware(hardwareId: number) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.REMOVE_HARDWARE}`, { hardwareId: hardwareId });
        return this.http.delete(endpoint).pipe(
            map(response => {
                this.loadVans();
                return response;
            })
        );
    }

    public removeVan(vanId: string) {
        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.REMOVE_VAN}`, { vanId: vanId });
        return this.http.delete(endpoint).pipe(
            map(response => {
                this.loadVans();
                return response;
            })
        );
    }

    public getVan(vanId: string) {
        if (!vanId) {
            throw new Error('Empty VanId');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.VAN}`, { vanId: vanId });

        return this.http.get(endpoint).pipe(
            map(response => {
                const van = new Van().deserialize(response);
                return van;
            })
        );
    }

    public getHardware(hardwareId: string) {
        if (!hardwareId) {
            throw new Error('Empty hardwareId');
        }

        const endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.GET_HARDWARE}`, { hardwareId });

        return this.http.get(endpoint).pipe(
            map(response => {
                const hardware = plainToClass(Hardware, response);
                return hardware;
            })
        );
    }

    public searchBy(mode: SearchHardwareBy, q: string, page: number, size: number, sort: string, order: string): Observable<HardwareUsages[]> {
        if (_.isUndefined(mode) || _.isUndefined(q) || _.isUndefined(page) || _.isUndefined(size) || _.isUndefined(sort) || _.isUndefined(order)) {
            console.error(mode, q, page, size, sort, order);
            return;
        }

        let endpoint: string;

        switch (mode) {
            case SearchHardwareBy.DATE:
                endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.GET_HARDAWARE_USAGES_BY_DATE}`, {
                    q,
                    page,
                    size,
                    sort,
                    order
                });
                this.isSearchingBy = SearchHardwareBy.DATE;
                break;
            case SearchHardwareBy.DRIVER_NAME:
                endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.GET_HARDAWARE_USAGES_BY_DRIVER_NAME}`, {
                    q,
                    page,
                    size,
                    sort,
                    order
                });
                this.isSearchingBy = SearchHardwareBy.DRIVER_NAME;
                break;
            case SearchHardwareBy.INVENTORY_NO:
                endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.GET_HARDAWARE_USAGES_BY_INVENTORY_NO}`, {
                    q,
                    page,
                    size,
                    sort,
                    order
                });
                this.isSearchingBy = SearchHardwareBy.INVENTORY_NO;
                break;
            case SearchHardwareBy.SHIFT_ID:
                endpoint = this.interpolate(`${this.host}${this.prefix}/${HardwareService.GET_HARDAWARE_USAGES_BY_SHIFT}`, {
                    q,
                    page,
                    size,
                    sort,
                    order
                });
                this.isSearchingBy = SearchHardwareBy.SHIFT_ID;
                break;
        }

        return this.http.get(endpoint).pipe(
            map(response => {
                const pageableResponse = new PageableResponse<HardwareUsages>(HardwareUsages);
                pageableResponse.contentItem = new HardwareUsages();
                pageableResponse.deserialize(response);
                this.hardwareUsageSource.next(pageableResponse);
                return pageableResponse.content;
            })
        );
    }

    private interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }
}
