<ng-container *ngIf="(shiftPlanning$ | async) as shiftPlanning">
    <form [formGroup]="form">
        <div class="shift-settings">
            <div class="d-flex justify-content-start">
                <div *ngFor="let item of regularFormItems" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <ng-container *ngIf="item | tooltipLabelPipe:'shift' as tooltip">
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip"
                                [style.width.px]="13" name="info"></i-feather>
                        </ng-container>
                    </label>

                    <div [ngSwitch]="item">
                        <div id="shift-planning-edit-basic-shiftName" *ngSwitchCase="'shiftName'">
                            <input type="text" [disabled]="mode !== 'create'" [formControlName]="item" class="form-control w-100" />
                            <p *ngIf="mode === 'create'" class="tx-13 text-success">{{'Notice: The shift name cannot be changed in the future' | translate}}</p>
                        </div>

                        <div id="shift-planning-edit-basic-warehouse" *ngSwitchCase="'warehouse'">
                            <ng-container *ngIf="(depot$ | async) as depots" >
                                <select [id]="item" class="custom-select" [formControlName]="item">
                                    <option 
                                        *ngFor="let d of depots" 
                                        [ngValue]="d.id">{{ d.code }}</option>
                                        <ng-container *ngIf="mode !== 'create'">
                                            <option 
                                            *ngIf="(selectedDepot$ | async) as selectedDepot"
                                            [selected]="selectedDepot == form.get('warehouse').value"
                                            disabled>{{ selectedDepot | findWarehousePipe }}</option>   
                                        </ng-container>
                                </select>
                                <p *ngIf="!depots.length" class="tx-13 text-danger">                                                        
                                    <i-feather [style.width.px]="18" [style.height.px]="18" name="alert-triangle"></i-feather>
                                    {{'You have not assigned any depot for you account' | translate}}</p>
                            </ng-container>
                        </div>

                        <div id="shift-planning-edit-basic-enabled" *ngSwitchCase="'enabled'">
                            <select [id]="item" class="custom-select" [formControlName]="item">
                                <option [ngValue]="true">{{'True' | translate}}</option>
                                <option [ngValue]="false">{{'False' | translate}}</option>
                            </select>
                        </div>

                        <div id="shift-planning-edit-basic-autoCreateDays" *ngSwitchCase="'autoCreateDays'">
                            <div class="d-flex align-items-center justify-content-between" [style.height.px]="36">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" [checked]="autoCreateDaysCheckbox" id="customSwitch1" (change)="autoCreateDaysCheckboxFun()">
                                    <label class="custom-control-label" for="customSwitch1">&nbsp;</label>
                                </div>
                                <div class="flex-grow-1" *ngIf="autoCreateDaysCheckbox">
                                    <input type="number" formControlName="autoCreateDays" class="form-control w-100 flex-grow-1" />
                                </div>
                            </div>
                        </div>

                        <div *ngSwitchDefault>
                            <input type="text" [formControlName]="item" class="form-control w-100" />
                        </div>

                        <div *ngIf="form.get(item).hasError('pattern') && form.get(item).touched" class="error-color">
                            {{ "The field value is too long" | translate }}!
                        </div>
    
                        <div *ngIf="form.get(item).hasError('required') && form.get(item).touched" class="error-color">
                            {{ "The field is required" | translate }}!
                        </div>

                        <div *ngIf="form.get(item).hasError('min') && form.get(item).touched" class="error-color">
                            {{ "The entered value exceeds the defined range" | translate }}!
                        </div>
                    </div>
                </div>
            </div>

            <ng-container formGroupName="doWFilter">
                <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'On what weekdays shift are available?' | translate}}:</label>
                <div id="shift-planning-edit-basic-doWFilter" class="col-10 d-flex flex-wrap justify-content-between">
                    <div *ngFor="let day of weekDaysControls">
                        <div class="custom-control custom-checkbox pd-20">
                            <input [formControlName]="day" [checked]="form.get('doWFilter').get(day).value"
                                type="checkbox" class="custom-control-input" id="customCheck{{day}}">
                            <label class="custom-control-label justify-content-start"
                                for="customCheck{{day}}">{{'Weekdays.'+day | translate}}</label>
                        </div>
                    </div>
                </div>
            </ng-container>
             

        </div>

        <div class="actions d-flex justify-content-between">
            <button id="shift-planning-edit-basic-back" [routerLink]="['/calendar/shift-planning']" class="btn btn-white tx-11">{{'Back to shifts list' | translate}}</button>
            <button id="shift-planning-edit-basic-next" type="submit" (click)="update()" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Next' | translate}}</button>
        </div>
    </form>
</ng-container>