import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[dropdown-menu], .dropdown-menu',
})
export class DropdownMenuDirective {
    @HostBinding('attr.aria-expanded')
    @HostBinding('class.show')
    public opened = false;
}
