export class RouteId {
    public id: string;
    public date: string;
    public year: string;
    public month: string;
    public day: string;
    public shift: string;
    public routeNumber: number;
    public warehouseId: string;
    public idRaw: string;

    public static of(id: string, date: string, year: string, month: string, day: string, shift: string, routeNumber: number, warehouseId?: string): RouteId {
        const rid = new RouteId();

        rid.id = id;
        rid.date = date;
        rid.year = year;
        rid.month = month;
        rid.day = day;
        rid.shift = shift;
        rid.routeNumber = routeNumber;
        rid.warehouseId = warehouseId;
        rid.idRaw = `${date}:${(warehouseId)}:${routeNumber}`

        return rid;
    }

    /*
        todo this request to localstorage should be moved from this class, eventually it should not exists.
        Local storage should be used just as preference of warehouse
     */
    public static getRouteId3(year, month, day, routeNumber, warehouse?) {
        return `${year}-${month}-${day}:${(warehouse) ? warehouse : localStorage.getItem('depot')}:${routeNumber}`;
    }

    public static getShiftId3(year, month, day, shift, warehouse = null) {
        if (warehouse === null) {
            return `${year}-${month}-${day}:${localStorage.getItem('depot')}:${shift}`;
        } else {
            return `${year}-${month}-${day}:${warehouse}:${shift}`;
        }
       
    }

    public static parse(id): RouteId {
        if (id === null) {
            return null;
        }

        const parsedId = id.match(/(\d{4})-(\d{2})-(\d{2}):([a-zA-Z]+):([a-zA-Z]+)/);
        const parsedId2 = id.match(/(\d{4})-(\d{2})-(\d{2}):([a-zA-Z]+):(\d{1,})/);
        const parsedId3 = id.match(/(\d{4})-(\d{2})-(\d{2}):(\d{1,}):(\d{1,})/);

        let date;
        let year;
        let month;
        let day;
        let routeNumber;
        let defaultShift = 'MORNING'; // todo remove default shift
        let warehouseId;

        if (parsedId !== null) {

            date = `${parsedId[1]}-${parsedId[2]}-${parsedId[3]}`;
            year = parsedId[1];
            month = parsedId[2];
            day = parsedId[3];
            routeNumber = parsedId[4];

        } else if (parsedId2 !== null) {

            date = `${parsedId2[1]}-${parsedId2[2]}-${parsedId2[3]}`;

            year = parsedId2[1];
            month = parsedId2[2];
            day = parsedId2[3];

            defaultShift = parsedId2[4];

            warehouseId = parsedId2[4];
            routeNumber = parsedId2[5];

        } else if (parsedId3 !== null) {
            date = `${parsedId3[1]}-${parsedId3[2]}-${parsedId3[3]}`;

            year = parsedId3[1];
            month = parsedId3[2];
            day = parsedId3[3];

            defaultShift = null;

            warehouseId = parsedId3[4];
            routeNumber = parsedId3[5];

        } else {
            console.warn('Invalid routeId', id);
            return null;
        }

        return RouteId.of(id, date, year, month, day, defaultShift, routeNumber, warehouseId);
    }

    public getRouteId(separator: string = ':') {
        return [this.year, this.month, this.day, this.shift, (this.warehouseId) ? this.warehouseId : localStorage.getItem('depot')].join(separator);
    }

    public get routeId2() {
        return [this.year, this.month, this.day, this.routeNumber].join(':');
    }

    public getDate(separator: string = ':') {
        return [this.year, this.month, this.day].join(separator);
    }

    public getRouteIdPath() {
        return `${this.year}/${this.month}/${this.day}/${this.shift}/${localStorage.getItem('depot')}/route/${this.id}`;
    }

    public checkIfExistInRoute(routeId): boolean {
        return (this.id && this.id !== routeId) ;
    }
}
