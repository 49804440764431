import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap';

import { ShiftsService } from '@services/shifts.service';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { AdvancedSettingsComponent } from './advanced-settings.component';
import { CalendarComponent } from './calendar.component';
import { CalendarRouting } from './calendar.routing';
import { CutoffDetailsComponent } from './cutoff-details.component';
import { DayComponent } from './day.component';
import { DayDetailsComponent } from './dayDetails.component';
import { DeliveriesDetailsComponent } from './deliveries-details.component';
import { ShiftEdgesComponent } from './shift-edges.component';
import { StatusSwitcherComponent } from './status-switcher.component';
import { VansDetailsComponent } from './vans-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SortShiftByStartTimePipe } from './pipes/sort-shift-by-start-time.pipe';
import { FindPlanningSequencePipe } from './pipes/find-planning.pipe';
import { ShiftPlanningComponent } from './components/shift-planning/shift-planning.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ShiftPlanningEditComponent } from './components/shift-planning-edit/shift-planning-edit.component';
import { ShiftCreateComponent } from './components/shift-create/shift-create.component';
import { CheckShiftLimitsPipe } from './pipes/check-shifts-limits.pipe';
import { ShiftIntegrationComponent } from './shift-integration.component';
import { ShiftRoutingComponent } from './shift-routing.component';
import { ShiftFulfilmentComponent } from './shift-fulfilment.component';
import { ShiftPlanningEditFulfilmentComponent } from './components/shift-planning-edit-fulfilment/shift-planning-edit-fulfilment.component';
import { ShiftPlanningEditIntegrationComponent } from './components/shift-planning-edit-integration/shift-planning-edit-integration.component';
import { ShiftPlanningEditRoutingComponent } from './components/shift-planning-edit-routing/shift-planning-edit-routing.component';
import { ShiftPlanningEditNewComponent } from './components/shift-planning-edit-new/shift-planning-edit-new.component';
import { ShiftPlanningEditBasicComponent } from './components/shift-planning-edit-basic/shift-planning-edit-basic.component';
import { IsShiftManageablePipe } from './pipes/is-shift-manageable.pipe';
import { IsShiftOpenPipe } from './pipes/is-shift-open.pipe';
import { MonthOffsetPipe } from './pipes/month-offset.pipe';
import { ShitPlanningEditCutoffComponent } from './components/shit-planning-edit-cutoff/shit-planning-edit-cutoff.component';
import { CalendarOverviewComponent } from './components/overview/calendar-overview/calendar-overview.component';
import { SumValuesPipe } from './pipes/sum-values.pipe';
import { CalendarOverviewBarComponent } from './components/overview/calendar-overview-bar/calendar-overview-bar.component';
import { CalendarTitlePipe } from './pipes/calendar-title.pipe';
import { CalendarOverviewShiftUpdateDialogComponent } from './components/overview/calendar-overview-shift-update-dialog/calendar-overview-shift-update-dialog.component';
import { FindOpenCutoffPipe } from './pipes/find-first-open-cutoff.pipe';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        AuthenticationModule,
        CalendarRouting,
        TooltipModule.forRoot(),
        ReactiveFormsModule,
        NgxDatatableModule
    ],
    declarations: [
        CalendarComponent,
        DayComponent,
        DayDetailsComponent,
        CutoffDetailsComponent,
        VansDetailsComponent,
        StatusSwitcherComponent,
        DeliveriesDetailsComponent,
        ShiftEdgesComponent,
        AdvancedSettingsComponent,
        SortShiftByStartTimePipe,
        FindPlanningSequencePipe,
        ShiftPlanningComponent,
        ShiftPlanningEditComponent,
        ShiftCreateComponent,
        CheckShiftLimitsPipe,
        IsShiftManageablePipe,
        IsShiftOpenPipe,
        ShiftIntegrationComponent,
        ShiftRoutingComponent,
        ShiftFulfilmentComponent,
        ShiftPlanningEditFulfilmentComponent,
        ShiftPlanningEditIntegrationComponent,
        ShiftPlanningEditRoutingComponent,
        ShiftPlanningEditNewComponent,
        ShiftPlanningEditBasicComponent,
        MonthOffsetPipe,
        ShitPlanningEditCutoffComponent,
        CalendarOverviewComponent,
        SumValuesPipe,
        CalendarOverviewBarComponent,
        CalendarTitlePipe,
        CalendarOverviewShiftUpdateDialogComponent,
        FindOpenCutoffPipe,
    ],
    providers: [ShiftsService],
    exports: [
        CalendarComponent,
        DayComponent,
        DayDetailsComponent,
        CutoffDetailsComponent,
        VansDetailsComponent,
        StatusSwitcherComponent,
        DeliveriesDetailsComponent,
        ShiftEdgesComponent,
        AdvancedSettingsComponent
    ],
    entryComponents: [
        CalendarOverviewShiftUpdateDialogComponent
    ]
})
export class CalendarModule {}
