import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { MapsAPILoader } from '@agm/core';
import * as MarkerClusterer from '@google/markerclusterer';
import * as _ from 'lodash';

import { RoutePath } from '@entities/route-path';
import { mapOptions } from '@enums/enum';
import { MapPropertiesInterface } from '@interfaces/map-properties.interface';
import { Coordinates } from '@entities/coordinates';
import { Apm } from 'projects/inpost/src/app/operations/entities/apm';

@Component({
    selector: 'gmap',
    styles: [`#map {width:100%;height:70vh}`],
    template: `
        <div id="map" #gmap></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GMapComponent implements OnInit {
    @Input() public locations;
    @Input() public routePath: RoutePath = null;
    @Input() public mapOption: mapOptions;
    @Input() public mapProperties: MapPropertiesInterface;
    @Output() public coords: EventEmitter<Coordinates> = new EventEmitter();

    constructor(private mapsAPILoader: MapsAPILoader) {}

    public ngOnInit() {
        this.mapsAPILoader.load().then(() => {
            switch (this.mapOption) {
                case mapOptions.REGULAR:
                    this.mapRegularInit();
                    break;
                case mapOptions.CLUSTER:
                    this.mapClusterInit();
                    break;
                case mapOptions.HEATMAP:
                    this.heatmapInit();
                    break;
                case mapOptions.CLUSTER_HISTORY:
                    this.historyMapClusterInit();
                    break;
                case mapOptions.CRAZY_DELIVERY_MAP:
                    this.crazyDeliveryMapClusterInit();
                    break;
            }
        });
    }

    public mapClusterInit() {
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: this.mapProperties.zoom,
            center: {lat: this.mapProperties.center.lat, lng: this.mapProperties.center.lng}
        });

        const locations = [];
        const bounds = new google.maps.LatLngBounds();
        var loc;

        if (this.locations.length) {
            if (this.locations[0] instanceof RoutePath) {
                _.forEach(this.locations, (routePath: RoutePath) => {
                    _.forEach(routePath.points, (point) => locations.push({...point, label: `${point.description}`}));
                });
            } else if (this.locations[0] instanceof Apm) {
                _.forEach(this.locations, (apm: Apm) => {
                    locations.push({...apm.coordinates, label: `Name: ${apm.name}, Code: ${apm.code}`});
                });
            } else if ('locationCoordinates' in this.locations[0]) {
                _.forEach(this.locations, (apm) => {
                    locations.push({...apm.locationCoordinates, label: `Name: ${apm.name}, Code: ${apm.code}`});
                });
            }
        }

        const markers = locations.map((location) => {
            const marker = new google.maps.Marker({
                position: location,
                title: ''
            });

            const loc = new google.maps.LatLng(location.lat, location.lng);
            bounds.extend(loc);

            marker.addListener('click', () => {
                infowindow.setContent(location.label);
                infowindow.open(marker.get('map'), marker);
            });

            return marker;
        });

        map.fitBounds(bounds);
        map.panToBounds(bounds);  

        const infowindow = new google.maps.InfoWindow();

        const mcOptions = {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        };
        const markerCluster = new MarkerClusterer(map, markers, mcOptions);
    }

    public heatmapInit() {
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: this.mapProperties.zoom,
            center: {lat: this.mapProperties.center.lat, lng: this.mapProperties.center.lng}
        });

        const points = [];
console.log(this.locations);
        _.forEach(this.locations, (point) => {
            points.push(new google.maps.LatLng(point[0], point[1]));
        });

        const heatmap = new google.maps.visualization.HeatmapLayer({
            data: points,
            map: map,
            radius: 15
        });

        const gradient = [
            'rgba(0, 255, 255, 0)',
            'rgba(0, 255, 255, 1)',
            'rgba(0, 191, 255, 1)',
            'rgba(0, 127, 255, 1)',
            'rgba(0, 63, 255, 1)',
            'rgba(0, 0, 255, 1)',
            'rgba(0, 0, 223, 1)',
            'rgba(0, 0, 191, 1)',
            'rgba(0, 0, 159, 1)',
            'rgba(0, 0, 127, 1)',
            'rgba(63, 0, 91, 1)',
            'rgba(127, 0, 63, 1)',
            'rgba(191, 0, 31, 1)',
            'rgba(255, 0, 0, 1)'
        ];

        heatmap.set('gradient', gradient);
    }

    public historyMapClusterInit() {
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: this.mapProperties.zoom,
            center: {lat: this.mapProperties.center.lat, lng: this.mapProperties.center.lng}
        });

        const locations = [];

        _.forEach(this.locations, (point) => {
            locations.push(new google.maps.LatLng(point[0], point[1]));
        });

        const markers = locations.map((location) => {
            return new google.maps.Marker({
                position: location
            });
        });

        const mcOptions = {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        };

        // Add a marker clusterer to manage the markers.
        const markerCluster = new MarkerClusterer(map, markers, mcOptions);
    }

    public mapRegularInit() {

        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: this.mapProperties.zoom,
            center: {lat: this.locations.lat, lng: this.locations.lng}
        });

        const marker = new google.maps.Marker({
            position: this.locations,
            map: map,
            draggable: true
        });

        google.maps.event.addListener(marker, 'dragend', (m) => {
            this.coords.emit(new Coordinates().deserialize({
                'lat': m.latLng.lat(),
                'lng': m.latLng.lng()
            }));
        });
    }

    public crazyDeliveryMapClusterInit() {

        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: 4,
            center: {lat: -24.345, lng: 134.46}  // Australia.
          });
        
          const directionsService = new google.maps.DirectionsService;
          const directionsRenderer = new google.maps.DirectionsRenderer({
            draggable: true,
            map: map,
            //panel: document.getElementById('right-panel')
          });
        
        //   directionsRenderer.addListener('directions_changed', function() {
        //     computeTotalDistance(directionsRenderer.getDirections());
        //   });
        
          this.displayRoute('Perth, WA', 'Sydney, NSW', directionsService,
              directionsRenderer);



    }

    public displayRoute(origin, destination, service, display) {
        service.route({
          origin: origin,
          destination: destination,
          waypoints: [{location: 'Adelaide, SA'}, {location: 'Broken Hill, NSW'}],
          travelMode: 'DRIVING',
          avoidTolls: true
        }, function(response, status) {
          if (status === 'OK') {
            display.setDirections(response);
          } else {
            alert('Could not display directions due to: ' + status);
          }
        });
      }
}
