import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneComponent } from './components/one/one.component';



@NgModule({
  declarations: [OneComponent],
  imports: [
    CommonModule
  ]
})
export class TestModule { }
