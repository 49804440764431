import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@entities/activity';
import { ActivityType } from '@enums/enum';
import * as moment from 'moment';

@Pipe({
    name: 'activityLateEarlyMarkPipe',
    pure: true
})
export class ActivityLateEarlyMarkPipe implements PipeTransform {

    public transform(activity: Activity) {

        if (activity.type === ActivityType.DELIVERY) {
            
            if (moment(activity.planning.from).isBefore(activity.slot.from)) {
                return '2px solid #f44336';
            }
            
            if (moment(activity.planning.from).isAfter(activity.slot.to)) {
                return '2px solid #ff9800';
            }
            
        } 
    }
}
