import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SmsTemplatesService } from '../services/sms-templates.service';

@Injectable()
export class SmsTemplatesResolver implements Resolve<any> {
    constructor(private smsTemplatesService: SmsTemplatesService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.smsTemplatesService.findAll();
    }
}
