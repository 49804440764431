<div class="bars__bar" [ngClass]="{'limit-alert': shift | checkShiftLimitsPipe }" [tooltip]="tooltipLabel(shift)"
    [adaptivePosition]="true" [containerClass]="'pretty-tooltip'"
    [style.width.%]="!(shift.shiftStatusData.status | isShiftOpenPipe) ? 82 : 95">
    <div [style.flex]="shift.statuses['RESERVED']" class="bars__deliveries bars__deliveries--reserved"
        [style.background-color]="isLimitExceeded(shift) ? '#FF4136' : isEstimatedMaxNoDeliveriesExceeded(shift) && '#FFDC00'">
    </div>
    <div [style.flex]="shift.statuses['ORDERED']" class="bars__deliveries bars__deliveries--ordered"
        [style.background-color]="isLimitExceeded(shift) ? '#FF4136' : isEstimatedMaxNoDeliveriesExceeded(shift) && '#FFDC00'">
    </div>
    <div [style.flex]="shift.statuses['DONE']" class="bars__deliveries bars__deliveries--done"></div>
    <div [style.flex]="shift.statuses['FAILURE']" class="bars__deliveries bars__deliveries--failure"></div>
    <div [style.flex]="shift.statuses['ASSIGNED']" class="bars__deliveries bars__deliveries--assigned"></div>
    <div [style.flex]="shift.statuses['RETRY']" class="bars__deliveries bars__deliveries--retry"></div>
    <div [style.flex]="shift.statuses['PENDING']" class="bars__deliveries bars__deliveries--pending"></div>
    <div [style.flex]="shift.statuses['ARCHIVED']" class="bars__deliveries bars__deliveries--archived"></div>
    <div [style.flex]="shift.statuses['NEW']" class="bars__deliveries bars__deliveries--new"></div>
    <div [style.flex]="shift.statuses['CUTOFF']" class="bars__deliveries bars__deliveries--cutoff"></div>
    <div [style.flex]="shift.statuses['FINALIZING']" class="bars__deliveries bars__deliveries--finalizing"></div>
    <div *ngIf="shift.shiftStatusData.status | isShiftOpenPipe" [style.flex]="flexGrowEstimatedMaxNoDeliveries(shift)"
        class="bars__deliveries bars__deliveries--maximum-deliveries"></div>
</div>