<layout-default>
    <div header>
        <div toolbar>
            <h4 *ngIf="mode == 'show' || mode == 'edit'">
                <i-feather name="edit-3"></i-feather> {{ 'Edit driver' | translate }}: {{ driver.driverName }}
            </h4>
            <h4 *ngIf="mode == 'create'">
                <i-feather name="plus-square"></i-feather> {{ 'Add driver' | translate }}
            </h4>
        </div>

        <div toolbar>
            <a id="driver-edit-btn-edit" *ngIf="mode == 'show'" class="btn btn-sm btn-uppercase btn-brand-01" [routerLink]="['/drivers/' + this.driver.id + '/edit']">
                {{ 'Edit' | translate }}
            </a>

            <a id="driver-edit-btn-cancel" *ngIf="mode == 'edit'" class="btn btn-sm btn-uppercase btn-default" [routerLink]="['/drivers']">
                {{ 'Cancel' | translate }}
            </a>

            <button id="driver-edit-barcode"  (click)="showDriverBarcode()" class="btn btn-sm btn-uppercase btn-default"><i class="fas fa-barcode"></i> Barcode</button>
        </div>

        <hr>
    </div>

    <div content class="content" *ngIf="driver.id || mode == 'create'">

        <div class="col-8 offset-2">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div id="driver-edit-form-firstname"  class="form-group row">
                    <label class="col-3" for="firstName">{{ 'Firstname' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="firstName" class="form-control" />
                        <div *ngIf="form.get('firstName').hasError('required') && form.get('firstName').touched" class="error-color">{{ 'Firstname is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-lastname" class="form-group row">
                    <label class="col-3" for="lastname">{{ 'lastname' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="lastname" class="form-control" />
                        <div *ngIf="form.get('lastname').hasError('required') && form.get('lastname').touched" class="error-color">{{ 'lastname is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-employeeId" class="form-group row">
                    <label class="col-3" for="employeeId">{{ 'Employee ID' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="employeeId" class="form-control" />
                        <div *ngIf="form.get('employeeId').hasError('required') && form.get('employeeId').touched" class="error-color">{{ 'Employee ID is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-email" class="form-group row">
                    <label class="col-3" for="email">{{ 'Email address' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="email" class="form-control" />
                        <div *ngIf="form.get('email').hasError('pattern') && form.get('email').touched && form.get('email').invalid" class="error-color">
                            {{ 'Email address is incorrect' | translate }}!
                        </div>
                    </div>
                </div>

                <!-- <div class="form-group row">
                    <label class="col-3" for="depotId">{{ 'Depot' | translate }}:</label>
                    <div class="col-9">
                        <ng-container *ngIf="(depots$ | async) as depots">
                            <select class="custom-select" formControlName="depotId" disabled>
                                <option *ngFor="let depot of depots" [value]="depot.id">{{ depot.code }}</option>
                            </select>
                        </ng-container>
                        <div *ngIf="form.get('depotId').hasError('required') && form.get('depotId').touched" class="error-color">{{ 'Depot field is required' | translate }}!</div>
                    </div>
                </div> -->

                <div id="driver-edit-form-passwordHash" class="form-group row">
                    <!-- <label class="col-3" for="passwordHash">{{ 'Pin' | translate }}:</label> -->
                    <label for="passwordHash" class="col-3">
                            <span>{{'Pin' | translate}}:&nbsp;</span>
                            <span *ngIf="form.get('passwordHash') | isRequiredControlPipe">*</span>&nbsp;
                            <i-feather *ngIf="tooltipLabel('passwordHash')" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('passwordHash')"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>
                    <div class="col-9">
                        <input formControlName="pinCode" class="form-control" />
                        <div *ngIf="form.get('pinCode').hasError('required') && form.get('pinCode').touched" class="error-color">{{ 'Pin is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-phoneNumber" class="form-group row">
                    <label class="col-3" for="phoneNumber">{{ 'Driver private phone number' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="phoneNumber" class="form-control" />
                        <div *ngIf="form.get('phoneNumber').hasError('required') && form.get('phoneNumber').touched" class="error-color">{{ 'Phone number is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-driversLicenseId" class="form-group row">
                    <label class="col-3" for="driversLicenseId">{{ 'Driver licence ID' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="driversLicenseId" class="form-control" />
                        <div *ngIf="form.get('driversLicenseId').hasError('required') && form.get('driversLicenseId').touched" class="error-color">
                            {{ 'Driving licence number is required' | translate }}!
                        </div>
                    </div>
                </div>

                <div id="driver-edit-form-socialNumberType" class="form-group row">
                    <label class="col-3" for="socialNumberType">{{ 'Social number type' | translate }}:</label>
                    <div class="col-9">
                        <select class="custom-select" formControlName="socialNumberType">
                            <option *ngFor="let socialNumberType of socialNumberTypeOptions" [value]="socialNumberType">{{ socialNumberType | translate }}</option>
                        </select>
                        <div *ngIf="form.get('socialNumberType').hasError('required') && form.get('socialNumberType').touched" class="error-color">
                            {{ 'Social number type is required' | translate }}!
                        </div>
                    </div>
                </div>

                <div id="driver-edit-form-socialNumber" class="form-group row">
                    <label class="col-3" for="socialNumber">{{ 'Social number' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="socialNumber" class="form-control" />
                        <div *ngIf="form.get('socialNumber').hasError('required') && form.get('socialNumber').touched" class="error-color">{{ 'Social number is required' | translate }}!</div>
                    </div>
                </div>

                <!-- <div class="form-group row">
                    <label class="col-3" for="coolomatCode">{{ 'Coolomat code' | translate }}:</label>
                    <div class="col-9">
                        <input formControlName="coolomatCode" class="form-control" />
                    </div>
                </div> -->

                <div id="driver-edit-form-joinedAt" class="form-group row">
                    <label class="col-3" for="joinedAt">{{ 'Joined date' | translate }}:</label>
                    <div class="col-9">
                        <ng-datepicker class="datepicker-input form-group" formControlName="joinedAt" id="joinedAt" name="joinedAt" [headless]="disabled" [options]="options"> </ng-datepicker>

                        <input *ngIf="disabled" type="text" class="form-control" [disabled]="disabled" [value]="form.get('joinedAt').value | date: 'dd.MM.yyyy'" />

                        <div *ngIf="form.get('joinedAt').hasError('required') && form.get('joinedAt').touched" class="error-color">{{ 'Joined date is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-nationality" class="form-group row">
                    <label class="col-3" for="nationality">{{ 'Nationality' | translate }}:</label>
                    <div class="col-9">
                        <select class="custom-select" formControlName="nationality">
                            <option *ngFor="let nationality of nationalityOptions" [value]="nationality">{{ nationality | translate }}</option>
                        </select>
                        <div *ngIf="form.get('nationality').hasError('required') && form.get('nationality').touched" class="error-color">{{ 'Nationality is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-appLang" class="form-group row">
                    <label class="col-3" for="appLang">{{ 'Application lang' | translate }}:</label>
                    <div class="col-9">
                        <select class="custom-select" formControlName="appLang">
                            <option *ngFor="let appLang of appLangOptions" [value]="appLang">{{ appLang | translate }}</option>
                        </select>
                        <div *ngIf="form.get('appLang').hasError('required') && form.get('appLang').touched" class="error-color">{{ 'Application language is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-status" class="form-group row">
                    <label class="col-3" for="status">{{ 'Status' | translate }}:</label>
                    <div class="col-9">
                        <select class="custom-select" formControlName="status">
                            <option *ngFor="let status of statusOptions" [value]="status">{{ status | translate }}</option>
                        </select>
                        <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Status field is required' | translate }}!</div>
                    </div>
                </div>

                <div id="driver-edit-form-preferredVan" class="form-group row">
                    <label class="col-3" for="preferredVan">{{ 'Preferred van' | translate }}:</label>
                    <div class="col-9">
                        <select class="custom-select" formControlName="preferredVan" [compareWith]="compareFn">
                            <option [ngValue]="null">{{ 'No preferences' | translate }}</option>
                            <option *ngFor="let van of vans" [ngValue]="van">{{ van.brand }} {{ van.registration }}</option>
                        </select>
                    </div>
                </div>

                <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                    <div class="col-10 offset-2">
                        <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }}:</p>
                        <ul>
                            <li *ngFor="let error of formErrorsMessages" class="error-color">
                                <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                <strong>{{ error.field | translate }}</strong>
                                <span>{{ error.value | translate }}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="driver-profile" style="display: none;">
                    <div class="col-2 driver-barcode" style="padding: 10px 0;display:block;text-align:center;">
                        <ngx-barcode [bc-value]="driver.getDriverBarcodeValue" [bc-display-value]="true"></ngx-barcode>
                    </div>
                    <div style="text-align:center;">
                        <p>
                            <strong>{{ driver.driverName }}</strong>
                        </p>
                        <p>E-mail: {{ driver.email }}</p>
                        <p>Phone: {{ driver.phoneNumber }}</p>
                        <p>{{ driver.status | translate }}</p>
                    </div>
                </div>

                <div class="form-group row justify-content-end">
                    <div class="col-3">
                        <button id="driver-edit-form-btn-submit" [disabled]="!form.valid || submitted || disabled" class="btn btn-sm btn-uppercase btn-brand-01 w-100">
                            <span *ngIf="!submitted">{{ 'Save' | translate }}</span>
                            <ng-container *ngIf="submitted">
                                <div class="load-spinner text-center">
                                    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                </div>
                            </ng-container>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</layout-default>

<!--{{'UNACTIVE' | translate}}-->
<!--{{'SUSPENDED' | translate}}-->
<!--{{'TERMINATED' | translate}}-->
<!--{{'PESEL' | translate}}-->
<!--{{'PL' | translate}}-->
<!--{{'UA' | translate}}-->
<!--{{'pl-PL' | translate}}-->
<!--{{'en-GB' | translate}}-->
<!--{{'ua-UA' | translate}}-->
<!-- {{'tooltips.driver.edit.labels.passwordHash' | translate}} -->
