import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'isRequiredControlPipe',
  pure: true
})
export class IsRequiredControlPipe implements PipeTransform {
  public transform(control: AbstractControl): boolean {
    const validator = control.validator({} as AbstractControl);
    return (validator && validator.required) ? true : false;
  }
}
