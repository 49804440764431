
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ShiftType, VisualiserMode } from '@enums/enum';
import { ShiftsService } from '@services/shifts.service';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutesPlanningParams } from '../../interfaces/routes-planning-params.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { ShiftIdInterface } from '@interfaces/shiftId.interface';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';

import * as enLocale from 'date-fns/locale/en';
import * as plLocale from 'date-fns/locale/pl';
import * as _ from 'lodash';
import * as moment from 'moment';
import { VisualiserService } from '../../services/visualiser.service';
import { NotifierService } from 'angular-notifier';
import { AppService } from '@services/app.service';
import { RouteId } from '@entities/routeId';

@Component({
    selector: 'aurels-shift-picker',
    templateUrl: 'aurels-shift-picker.component.html',
    styleUrls: ['./aurels-shift-picker.component.scss']
})
export class AurelsShiftPickerComponent implements OnInit, OnDestroy {

    public Permissions = Permissions;
    public locale = null;
    public visualiserMode: VisualiserMode = VisualiserMode.VIEWING; 

    private _date: string;
    private datePickerOptions = Object.assign({}, datePickerOptions);

    public changeShiftSettingsMode: boolean = false;
    public changeShiftSettingsModeLoader: boolean = false;
    public shiftsTypes$: Observable<ShiftConfig[]>;
    public shiftSettings: ShiftConfig = null;
    public params: RoutesPlanningParams;
    public regex = /\d{4}-\d{2}-\d{2}/;


    set date(date) {
        this._date = moment(date).format('YYYY-MM-DD');
        this.shiftsTypes$ = this.shiftService.getShiftsTypesForShift(`${this.date}`, this.localStorageService.getDepot());
    }

    get date() {
        return this._date;
    }

    get visualiserModeOption() {
        return _.keys(VisualiserMode);
    }

    get isCutoffMode(): boolean {
        return this.visualiserMode === VisualiserMode.CUTOFF;
    }

    get isSimulatorMode(): boolean {
        return this.visualiserMode === VisualiserMode.SIMULATOR;
    }

    get isViewingMode(): boolean {
        return this.visualiserMode === VisualiserMode.VIEWING;
    }

    get isAuditLogMode(): boolean {
        return this.visualiserMode === VisualiserMode.AUDIT_LOG;
    }

    constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly shiftService: ShiftsService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly localStorageService: LocalStorageService,
        private readonly visuliserService: VisualiserService,
        private readonly notifierService: NotifierService,
        private readonly appService: AppService,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.locale = this.translate.currentLang;
        this.datePickerOptions.locale = this.translate.currentLang === 'en' ? enLocale : plLocale;
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.locale = event.lang;
            this.datePickerOptions.locale = this.locale === 'en' ? enLocale : plLocale;
        });

        const children = this.activatedRoute.snapshot.children;
        const prefferdShift: ShiftIdInterface = this.localStorageService.getPreferredShift();

        this.params = { 
            y: (children.length) ? children[0].params.y : prefferdShift.year,
            m: (children.length) ? children[0].params.m : prefferdShift.month,
            d: (children.length) ? children[0].params.d : prefferdShift.day,
            cutoff: (children.length) ? children[0].params.cutoff : 'not-selected',
            shiftId: (children.length) ? children[0].params.shiftId : prefferdShift.shiftType,
            depot: (children.length) ? children[0].params.depot : prefferdShift.depot
        } as RoutesPlanningParams;


        // if (this.regex.test(this.params.cutoff)) {
        //     this.params.cutoff = 'CUTOFF.' + this.params.shiftId + '.' + this.params.cutoff;
        // } else {
        //     this.params.cutoff = 'SHIFT.' + this.params.shiftId;
        // }

        this.date = `${this.params.y}-${this.params.m}-${this.params.d}`;

        if (this.localStorageService.getDepot() !== this.params.depot) {
            this.localStorageService.setDepot(this.params.depot);
            this.appService.__selectedWarehouseSource.next(this.params.depot);
            this.shiftsTypes$ = this.shiftService.getShiftsTypesForShift(`${this.date}`, this.localStorageService.getDepot());
        }

        if (children && !children.length) {
            this.router.navigate([`/visualiser/${this.params.y}/${this.params.m}/${this.params.d}/${this.params.shiftId}/${this.params.depot}`])
        }

        /*
        *   Detect if mode is equal cutoff. Switch to selected mode during the first loading.
        */
        if (location.href.includes(VisualiserMode.CUTOFF.toLowerCase())) {
            this.visualiserMode = VisualiserMode.CUTOFF;
        }

        /*
        *   Detect if mode is equal simulator. Switch to selected mode during the first loading.
        */
        if (location.href.includes(VisualiserMode.SIMULATOR.toLowerCase())) {
            this.visualiserMode = VisualiserMode.SIMULATOR;
        }

        /*
        *   Detect if mode is equal deliveries management. Switch to selected mode during the first loading.
        */
        if (location.href.includes('audit-log')) {
            this.visualiserMode = VisualiserMode.AUDIT_LOG;
        }
    }

    public loadData(): void {
        const depot = this.localStorageService.getDepot();
        const [y, m, d] = this.date.split('-');
        this.router.navigate([`/visualiser/${y}/${m}/${d}/${this.params.shiftId}/${depot}`])
    }

    public changedMode(selectedMode?: VisualiserMode) {
        const depot = this.localStorageService.getDepot();
        const [y, m, d] = this.date.split('-');

        const prefferdCutoff: string = this.localStorageService.getPreferedCutoff();
        const prefferdShiftIf: ShiftIdInterface = this.localStorageService.getPreferredShift();

        if (!selectedMode) {
            selectedMode = this.visualiserMode;
        } else {
            if (this.params.shiftId === prefferdShiftIf.shiftType) {
                this.params.cutoff = prefferdCutoff;
            } else {
                this.params.cutoff = '';
            }
        }

        switch (selectedMode) {
            case VisualiserMode.VIEWING:
                this.router.navigate([`/visualiser/${y}/${m}/${d}/${this.params.shiftId}/${depot}`])
                break;
            case VisualiserMode.SIMULATOR:
                this.router.navigate([`/visualiser/simulator/${y}/${m}/${d}/${this.params.shiftId}/${depot}/${this.params.cutoff}`])
                break;
            case VisualiserMode.CUTOFF:
                this.router.navigate([`/visualiser/cutoff/${y}/${m}/${d}/${this.params.shiftId}/${depot}/${this.params.cutoff}`])
                break;
            case VisualiserMode.AUDIT_LOG:
                const id = RouteId.getShiftId3(this.params.y, this.params.m, this.params.d, this.params.shiftId);
                this.router.navigate([`/visualiser/audit-log/${y}/${m}/${d}/${this.params.shiftId}/${depot}/SHIFT/list`])
                break;
            default:
                break;
        }

        this.localStorageService.setPreferredshift(`${y}/${m}/${d}/${this.params.shiftId}`);
        this.localStorageService.setPreferedCutoff(`${this.params.cutoff}`);
    }

    public onShiftChanged(event, shiftsTypes) {
        const selectedShift = event.target.value;
        const shiftConfig: ShiftConfig = shiftsTypes.find((shift: ShiftConfig) => shift.type === selectedShift);
        this.params.cutoff = (shiftConfig) ? shiftConfig.cutoffs[0].cutoff : '';
    }

    public ngOnDestroy(): void { }
}
