import { Type } from 'class-transformer/decorators';
import * as _ from 'lodash';

import { Additional } from '@entities/additional';
import { Address } from '@entities/address';
import { Coordinates } from '@entities/coordinates';
import { Entity } from '@entities/Entity';
import { Serializable } from '@entities/Serializable';
import { serializeType } from '@shared/functions/serializeType.function';

/**
 * Order delivery address
 */
export class Location extends Entity implements Serializable<Location> {
    public id: string = '';
    public createdAt: string = '';
    public modifiedAt: string = '';
    public customerId: string = '';
    public label: string = '';
    public rawAddress = '';
    public remarks: string = '';
    public status: StatusEnum.UNVERIFIED;
    public geocodingStatus = '';
    public areaId: string = '';
    public ref: string;

    @Type(serializeType(Additional))
    public additional: Additional = new Additional();

    @Type(serializeType(Address))
    public address: Address = new Address();

    @Type(serializeType(Address))
    public originalAddress: Address = new Address();

    @Type(serializeType(Coordinates))
    public coordinates: Coordinates = new Coordinates();

    get hasEncodingIssue() {
        if (this.geocodingStatus !== EncodingStatusEnum.ENCODED) {
            return true;
        }

        const houseNO = _.get(this, 'address.houseNO', '') || '';
        const houseNOOriginal = _.get(this, 'originalAddress.houseNO', '') || '';

        return houseNO.toLowerCase() !== houseNOOriginal.toLowerCase();
    }

    get encodingIssueStatus() {
        if (this.geocodingStatus !== EncodingStatusEnum.ENCODED) {
            return 'LOCATION_' + this.geocodingStatus;
        }

        const houseNO = _.get(this, 'address.houseNO', '') || '';
        const houseNOOriginal = _.get(this, 'originalAddress.houseNO', '') || '';

        if (houseNO.toLowerCase() !== houseNOOriginal.toLowerCase()) {
            return 'LOCATION_' + EncodingStatusEnum.PARTIAL;
        }

        if (this.geocodingStatus === EncodingStatusEnum.ENCODED) {
            return 'LOCATION_' + this.geocodingStatus;
        }
    }

    get statusClass() {
        const clazz: string[] = ['badge'];

        switch (this.geocodingStatus) {
            case EncodingStatusEnum.PARTIAL:
                clazz.push('badge-warning');
                break;
            case EncodingStatusEnum.MANUAL:
                clazz.push('badge-light');
                break;
            default:

                const houseNO = _.get(this, 'address.houseNO', '') || '';
                const houseNOOriginal = _.get(this, 'originalAddress.houseNO', '') || '';

                if (houseNO.toLowerCase() !== houseNOOriginal.toLowerCase()) {
                    clazz.push('badge-warning');
                } else {
                    return '';
                }
        }

        return clazz.join(' ');
    }

    public getOriginalAddressAsRaw() {
        return this.originalAddress.raw;
    }

    public getAddressAsRaw() {
        return this.address.raw;
    }

    public deserialize(data: any) {
        ['id', 'createdAt', 'modifiedAt', 'customerId', 'label', 'rawAddress', 'remarks', 'geocodingStatus', 'areaId', 'ref'].forEach((field) => {
            this[field] = _.get(data, `${field}`);
        });


        this.status = (<any>StatusEnum)[_.get(data, 'status')];
        this.additional = (new Additional()).deserialize(_.get(data, 'additional'));
        this.address = (new Address()).deserialize(_.get(data, 'address'));
        this.originalAddress = (new Address()).deserialize(_.get(data, 'originalAddress'));
        this.coordinates = (new Coordinates()).deserialize(_.get(data, 'coordinates'));

        return this;
    }

    public serialize() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            modifiedAt: this.modifiedAt,
            customerId: this.customerId,
            label: this.label,
            rawAddress: this.rawAddress,
            remarks: this.remarks,
            geocodingStatus: this.geocodingStatus,
            areaId: this.areaId,
            status: this.status,

            additional: this.additional.serialize(),
            address: this.address.serialize(),
            originalAddress: this.originalAddress.serialize(),
            coordinates: this.coordinates.serialize(),
        };
    }
}

enum EncodingStatusEnum {
    /**
     * Location is new and no geocoding attempt has been made yet.
     */
    NEW = 'NEW',
    /**
     * Location is encoded.
     */
    ENCODED = 'ENCODED',
    /**
     * Location could not be found by the geocoding service. (unknown)
     */
    NOTFOUND = 'NOTFOUND',
    /**
     * The encoder could only find part of the address, not the whole address.
     */
    PARTIAL = 'PARTIAL',
    /**
     * The geocoding has been overruled manually.
     */
    MANUAL = 'MANUAL',
    /**
     * An  error (network?) occurred during geocoding.
     */
    ERROR = 'ERROR',
}

enum StatusEnum {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED'
}
