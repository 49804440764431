import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { Delivery } from '@entities/delivery';
import { RouteExt, RouteSummary } from '@entities/route-ext';
import { PaymentType } from '@enums/enum';
import { RoutesService } from '@routes/services/routes.service';
import { NavService } from '@services/nav.sevice';
import { UtilsService } from '@services/utils.service';
import { Payment } from '@entities/payment';

@Component({
    selector: 'payments-details',
    template: `
    <ng-container *ngIf="hasDeliveries()">

        <div class="payments-summary">
            <h6>{{'Payments summary' | translate}}:</h6>
            <div class="payments-list">
                <div *ngFor="let type of paymentTypesOptions">{{type | translate}} - <strong>{{summaryPaymentDetailsByType(type) | number:'1.2-2'}} {{'PLN' | translate}}</strong></div>
            </div>
        </div>
        <hr />
        <div class="row thead">
            <div class="col-2">{{'Customer' | translate}}</div>
            <div class="col-2">{{'Invoice' | translate}}</div>
            <div class="col-1">{{'Method' | translate}}</div>
            <div class="col-1">{{'Amount due' | translate}}</div>
            <div class="col-1">{{'Collected' | translate}}</div>
            <div class="col-1">{{'Complaints' | translate}}</div>
            <div class="col-2">{{'Discrepancy' | translate}}</div>
            <div class="col-2">{{'Payment details' | translate}}</div>
        </div>
        <ng-container *ngFor="let delivery of deliveries">
            <div class="row tbody" *ngFor="let payment of delivery.paymentInfos; let index = index">
                <div class="col-2">
                    <button (click)="goToDeliveryPage(delivery)" class="btn btn-xs d-inline">
                        <i-feather name="external-link" [style.width.px]="12" [style.height.px]="12"></i-feather>
                    </button>
                    {{delivery.customer.labelLong}}
                </div>
                <div class="col-2">
                    <strong>{{payment.merchant}}</strong>
                    <p class="pd-0 mg-0">{{payment.id}}</p>
                </div>
                <div class="col-1">{{payment.requestedType | translate}}</div>
                <div class="col-1">{{payment.amountDue | number:'1.2-2'}} {{payment.currency | translate}}</div>
                <div class="col-1">{{payment.amountPaid | number:'1.2-2'}} {{payment.currency | translate}}</div>
                <div class="col-1">{{delivery.complaintsAmount[payment.merchant] | number:'1.2-2'}} {{payment.currency | translate}}
                
                </div>
                <div class="col-2 discrepancy">
                    <ng-container *ngIf="index === 0">
                        <span class="green" *ngIf="hasDiscrepancy(delivery, payment) == 0">
                            <i *ngIf="!delivery.isStatusAssigned && !delivery.isStatusRetry && !delivery.isStatusFailure" class="fa fa-check-circle"></i>
                            <span *ngIf="delivery.isStatusAssigned || delivery.isStatusRetry" class="badge badge-warning text-center">{{'Not delivered' | translate}}</span>
                            <span *ngIf="delivery.isStatusFailure" class="badge badge-info text-center">{{'FAILURE' | translate}}</span>
                        </span>
                        <span class="red" *ngIf="hasDiscrepancy(delivery, payment) < 0">
                            <i class="fa fa-times-circle"></i> {{ hasDiscrepancy(delivery, payment) | number:'1.2-2' }}{{payment.currency | translate}}
                        </span>
                        <span class="orange" *ngIf="hasDiscrepancy(delivery, payment) > 0">
                            <i class="fa fa-times-circle"></i> +{{ hasDiscrepancy(delivery, payment) | number:'1.2-2' }}{{payment.currency | translate}}
                        </span>
                    </ng-container>
                </div>
                <div class="col-2">
                    <ul>
                        <li *ngFor="let method of payment.methods">{{method.type | translate}} - {{method.amount | number:'1.2-2' }} {{method.currency}}</li>
                    </ul>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!hasDeliveries()">
        <div class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        </div>
    </ng-container>
    `,
    styleUrls: ['payments-details.component.scss']
})

export class PaymentsDetailsComponent implements OnInit, OnDestroy {
    @Input() public routeId: string;
    @Input() public routeSummary: RouteSummary;

    public route: RouteExt;
    public deliveries: Array<Delivery> | null = [];

    private readonly tag: string = '[PaymentsDetailsComponent]';
    private getRouteDeliveriesSub: Subscription;
    private selectedDeliveriesSub: Subscription;

    get paymentTypesOptions(): Array<PaymentType> { 
        return [PaymentType.CARD, PaymentType.CASH, PaymentType.SODEXO, PaymentType.ACCOUNT, PaymentType.WIRETRANSFER, PaymentType.DEFERRED];
    }

    constructor(private routeService: RoutesService, private utilsService: UtilsService, private navService: NavService) {}

    public ngOnInit(): void {
       this.getRouteDeliveries(this.routeId);

       this.selectedDeliveriesSub = this.routeService.selectedDeliveries
        .subscribe((route: RouteExt) => {
            this.route = route;
            this.deliveries = Object.values(this.route.deliveryDict);
            console.log(this.tag, '[Response] subscribeOnSelectedDeliveries', this.deliveries);
        });
    }

    public getRouteDeliveries(routeId: string) {
        this.getRouteDeliveriesSub = this.routeService.getRouteById(routeId).subscribe();
    }

    public hasDiscrepancy(delivery: Delivery, payment: Payment): number {

        if (delivery.isStatusAssigned || delivery.isStatusRetry || delivery.isStatusFailure) { return 0; }
        return Math.round(( payment.amountPaid + delivery.complaintsAmount[payment.merchant] - payment.amountDue) * 100) / 100;
    }

    public hasDeliveries(): boolean {
        return this.deliveries !== null;
    }

    public summaryPaymentDetailsByType(type: PaymentType): number {
        switch (type) {
            case PaymentType.ACCOUNT :
                return this.routeSummary.accountPayments;
            case PaymentType.CARD :
                return this.routeSummary.cardPayments;
            case PaymentType.CASH :
                return this.routeSummary.cashPayments;
            case PaymentType.DEFERRED :
                return this.routeSummary.deferredPayments;
            case PaymentType.SODEXO :
                return this.routeSummary.couponPayments;
            case PaymentType.WIRETRANSFER :
                return this.routeSummary.wirePayments;
        }
    }

    public goToDeliveryPage(delivery) {
        this.navService.goToPage(`/deliveries/update/${delivery.id}`); 
    }

    public ngOnDestroy(): void {
        if (this.getRouteDeliveriesSub) { this.getRouteDeliveriesSub.unsubscribe(); }
        if (this.selectedDeliveriesSub) { this.selectedDeliveriesSub.unsubscribe(); }
    }
}
