
import { Pipe, PipeTransform } from '@angular/core';
import { CutoffSolutions } from '../interfaces/cutoff-solutions.interface';
import * as _ from 'lodash';

@Pipe({
    name: 'findBestSolutionPipe',
    pure: true
})
export class FindBestSolutionPipe implements PipeTransform {

    constructor(){}

    public transform(solutions: CutoffSolutions[]): CutoffSolutions {
        const results = _.sortBy(solutions, (s: CutoffSolutions) => [s.errors, -s.routeCount, s.distance]);
        return results[0];
    }
}
