import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShiftStatus } from '@enums/enum';
import { ShiftConfig } from './entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { ShiftSettingsService } from './services/shift-settings.service';
import { Fulfilment } from './interafces/fulfilment.interface';
import { IShiftStatus } from './interafces/shift-status.interface';

@Component({
    selector: 'status-switcher',
    template: `
        <div class="editable-window shift-details">
            <div class="editable-content">
                <div class="">
                    <h3 class="shift-switcher">{{ 'Shift status' | translate }}</h3>
                    <div class="dropdown" dropdown>
                        <button
                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ shiftStatus | translate }}
                        </button>
                        <div 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]"
                            class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="btn btn-outline-secondary btn-sm btn-uppercase btn-edit-delivery dropdown-item" (click)="changeShiftStatus('BOOKING')">{{
                                'SHIFT_STATUS_LABEL_BOOKING' | translate
                            }}</a>

                            <a class="btn btn-outline-secondary btn-sm btn-uppercase btn-edit-delivery dropdown-item" (click)="changeShiftStatus('CONFIGURING')">{{
                                'SHIFT_STATUS_LABEL_COMPLETED' | translate
                            }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #modifiedShiftConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>{{ 'Do you want to modified the shift status' | translate }}?</p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate: modalArgs.values }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate: modalArgs.values }}
                </button>
            </div>
        </ng-template>
    `,
    styles: [
        `
            .dropdown button,
            .dropdown a {
                width: 100%;
                text-align: center;
            }
        `
    ]
})
export class StatusSwitcherComponent {

    get shiftStatus() {
        return this.shiftConfig.status === ShiftStatus.BOOKING ? 'SHIFT_STATUS_LABEL_BOOKING' : 'SHIFT_STATUS_LABEL_COMPLETED';
    }

    constructor(
        private readonly modalService: BsModalService,
        private readonly shiftSettingsService: ShiftSettingsService
    ) {}

    @Input()
    public shiftConfig: IShiftStatus = null;

    @Output()
    public shiftConfigChanged: EventEmitter<ShiftConfig> = new EventEmitter();

    @ViewChild('modifiedShiftConfirmation', { static: true }) public modifiedShiftConfirmation: TemplateRef<any>;
    public modalRef: BsModalRef;
    public message: string;
    public modalArgs = { values: {} };
    public confirmObservable: Observable<any>;
    public Permissions = Permissions;
    public locked = false;

    public editedShiftConfig: Fulfilment = null;

    public changeShiftStatus(value: string) {
        this.modalArgs.values = { status: value };
        this.modalRef = this.modalService.show(this.modifiedShiftConfirmation, { class: 'modal-md' });

        const shiftConfigCopy: IShiftStatus = cloneDeep(this.shiftConfig);
        shiftConfigCopy.status = <ShiftStatus>value;


        this.confirmObservable = this.shiftSettingsService.updateShiftStatus(shiftConfigCopy).pipe(
            map((updatedShiftConfig: ShiftConfig) => {
                this.locked = true;
                this.shiftConfigChanged.next(updatedShiftConfig);
                this.locked = false;
            })
        );

    }

    public confirm(): void {
        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.confirmObservable = null;
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }
}
