import { Component, OnInit } from '@angular/core';
import { DashboardSalesAggregation, DashboardSalesComparision, DashboardSalesMetrixEnum, DateRange } from '@enums/enum';
import { Depot } from '@interfaces/depot.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DepotService } from 'src/app/locations/services/depot.service';
import { DashboardService } from '../../services/dashboard.service';
import { ChartSettings } from './chart-settings';
import * as moment from 'moment';
import { ChartModel } from '@analytics/interfaces/chart.interface';
import { NotifierService } from 'angular-notifier';
import { DashboardSalesQueryParam } from '../../interfaces/dashboard-sales-queryparam.interface';

@Component({
    selector: 'app-dashboard-delivery-sales',
    templateUrl: './dashboard-delivery-sales.component.html',
    styleUrls: ['./dashboard-delivery-sales.component.scss'],
})
export class DashboardDeliverySalesComponent implements OnInit {
    public depots$: Observable<Depot[]>;

    public dataSales$: Observable<ChartModel[][]>;
    public dataCarts$: Observable<ChartModel[]>;

    public startDate: string;
    public endDate: string;
    public dateRange: DateRange = DateRange.THIS_MONTH;

    public dropdownList = [];
    public selectedItems = [];
    public dropdownSettings = {};

    public chartSettings = ChartSettings;
    public aggregationValues = Object.values(DashboardSalesAggregation);
    public comparisonValues = Object.values(DashboardSalesComparision);
    public dashboardSalesAggregation = DashboardSalesAggregation;

    public disabledField = {
      aggregation: false,
      comparison: false
    }

    public showYAxisLabel = {
      [DashboardSalesMetrixEnum.DELIVERY]: this.translate.instant('Delivery_sales.charts.labels.delivery.showYAxisLabel'),
      [DashboardSalesMetrixEnum.BOX]: this.translate.instant('Delivery_sales.charts.labels.box.showYAxisLabel'),
      [DashboardSalesMetrixEnum.SKU]: this.translate.instant('Delivery_sales.charts.labels.sku.showYAxisLabel'),
      [DashboardSalesMetrixEnum.PRICE]: this.translate.instant('Delivery_sales.charts.labels.price.showYAxisLabel'),
      [DashboardSalesMetrixEnum.MARGIN]: this.translate.instant('Delivery_sales.charts.labels.margin.showYAxisLabel'),
      [DashboardSalesMetrixEnum.CART]: this.translate.instant('Delivery_sales.charts.labels.cart.showYAxisLabel')
    }

    public queryParams: DashboardSalesQueryParam = {
        metrics: [DashboardSalesMetrixEnum.DELIVERY, DashboardSalesMetrixEnum.PRICE, DashboardSalesMetrixEnum.BOX, DashboardSalesMetrixEnum.SKU, DashboardSalesMetrixEnum.MARGIN],
        aggregation: DashboardSalesAggregation.Days,
        cumulative: false,
        dates: [ { from: '', to: '' } ],
        comparison: DashboardSalesComparision.Months,
        comparisonCount: 1
    };

    constructor(
        private readonly translate: TranslateService,
        private readonly depotService: DepotService,
        private readonly dashboardService: DashboardService,
        private readonly notifierService: NotifierService
    ) {}

    public ngOnInit() {
        this.depots$ = this.depotService.getDepotsForUser().pipe(tap((depots: Depot[]) => {}));
    }

    public changedDateRange(event) {
        if (this.queryParams.aggregation === DashboardSalesAggregation.Days && moment(event.dateStart).month() !== moment(event.dateEnd).month()) {
            this.notifierService.notify('warning', this.translate.instant('The date range selected is too large Please select dates from one month'));
            return;
        }

        if (moment(event.dateStart).year() !== moment(event.dateEnd).year()) {
          console.log('aaa');
          this.queryParams.comparisonCount = 0;
      }

        this.startDate = this.queryParams.dates[0].from = moment(event.dateStart).format('YYYY-MM-DD');
        this.endDate = this.queryParams.dates[0].to = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;

        this.loadData();
    }

    public aggregationChanged(event) {
      if ([DashboardSalesAggregation.Months, DashboardSalesAggregation.Weeks].includes(this.queryParams.aggregation)) {
        this.queryParams.comparison = DashboardSalesComparision.Years;
        this.disabledField.comparison = true;
      }
    }

    public loadData() {
        this.queryParams.dates = this.queryParams.dates.splice(0, 1);

        switch (this.queryParams.comparison) {
          case DashboardSalesComparision.Months:
            for (let index = 1; index <= this.queryParams.comparisonCount; index++) {
              const from = moment(this.queryParams.dates[0].from).add(-index, 'month').format('YYYY-MM-DD');
              const to = moment(this.queryParams.dates[0].to).add(-index, 'month').format('YYYY-MM-DD');
              this.queryParams.dates.push({from: from, to: to});
            }
            break;

          case DashboardSalesComparision.Years:
            for (let index = 1; index <= this.queryParams.comparisonCount; index++) {
              const from = moment(this.queryParams.dates[0].from).add(-index, 'year').format('YYYY-MM-DD');
              const to = moment(this.queryParams.dates[0].to).add(-index, 'year').format('YYYY-MM-DD');
              this.queryParams.dates.push({from: from, to: to});
            }
            break;
        }

        this.queryParams.cumulative = Boolean(this.queryParams.cumulative);
        this.dataSales$ = this.dashboardService.getSalsesDashboardReport(this.queryParams).pipe(tap((a) => console.log('sales: ', a)));
    }
}
