import { serializeType } from '@shared/functions/serializeType.function';
import { Coordinates } from './coordinates';
import { Type } from 'class-transformer/decorators';
import { ActivityStatus, ActivityType } from '@enums/enum';
import { TimeWindow } from './time-window';
import * as moment from 'moment';
import { Delivery } from './delivery';

export class Planning {
    public from: string;
    public to: string;

    public get slot(): string {
        return `${moment(this.from).format('HH:mm')}-${moment(this.to).format('HH:mm')}`;
    }
}

export class RequiredCapacity {
    public dryBoxes: number;
    public chilledBoxes: number;
    public frozenBoxes: number;
    public weight: number;

    public get sumOfBoxes(): number {
        return this.dryBoxes + this.chilledBoxes + this.frozenBoxes
    }

    public get label() {
        return `${this.dryBoxes}/${this.chilledBoxes}/${this.frozenBoxes}`;
    }
}

export class Activity {
    public id: number;
    public stepNo: number;
    public statusText: string;

    public type: ActivityType;
    public status: ActivityStatus;
    

    @Type(serializeType(Planning)) 
    public planning: Planning;

    @Type(serializeType(Planning)) 
    public slot: Planning;

    public actual: TimeWindow;
    public durationSeconds: number;
    public marginSeconds: number;

    @Type(serializeType(Coordinates)) 
    public location: Coordinates;

    @Type(serializeType(RequiredCapacity)) 
    public requiredCapacity: RequiredCapacity;

    public idList: string[] = [];
    public deliveries: Delivery[] = [];

    public warehouseNumber: number;
    public fulfillmentStatus: null;
    public ramp: any[];
    public dryRacks: any[];
    public chilledRacks: any[];

}
