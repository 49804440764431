import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Fulfilment } from '@calendar/interafces/fulfilment.interface';
import { UtilsService } from '@services/utils.service';
import { ShiftIdInterface } from '@interfaces/shiftId.interface';
import { Shiftid } from '@calendar/interafces/shiftid.interface';
import { environment } from '@environment';
import { DailyShiftConfig } from '@calendar/entities/dailyShiftConfig';
import { map } from 'rxjs/operators';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { Intergration } from '@calendar/interafces/integration.interface';
import { Routing } from '@calendar/interafces/routing.interface';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { IShiftStatus } from '@calendar/interafces/shift-status.interface';
import { ShiftCutoffData } from '@calendar/interafces/shift-cutoff-data.interface';

@Injectable({
  providedIn: 'root'
})
export class ShiftSettingsService {

  private static SHIFT_FULFILMENT: string = 'shifts/v4/${shiftId}/fulfillment';
  private static SHIFT_STATUS: string = 'shifts/v3/${shiftId}/status';
  private static SHIFT_INTEGRATION: string = 'shifts/v4/${shiftId}/integration';
  private static SHIFT_ROUTING: string = 'shifts/v4/${shiftId}/routing';
  private static SHIFT_CUTOFF: string = 'shifts/v3/${shiftId}/cutoffs';


  private __shiftExtSource: BehaviorSubject<ShiftExt> = new BehaviorSubject<ShiftExt>(undefined);
  public shiftExt: Observable<ShiftExt> = this.__shiftExtSource.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly utilsService: UtilsService
  ) { }

  public updateFulfilment(fulfilmentData: Fulfilment): Observable<ShiftConfig> {
    if (fulfilmentData.label == null) {
      fulfilmentData.label = '';
    }
    const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${ShiftSettingsService.SHIFT_FULFILMENT}`, 
      {shiftId: this.buildShiftId(fulfilmentData.shiftId)})

    return this.http.put<ShiftConfig>(endpoint, fulfilmentData).pipe(map((shift: any) => this.populateResult(shift)))
  }

  public updateShiftStatus(shiftStatusData: IShiftStatus): Observable<ShiftConfig> {
    const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${ShiftSettingsService.SHIFT_STATUS}`, 
      {shiftId: this.buildShiftId(shiftStatusData.shiftId)})

    return this.http.put<ShiftConfig>(endpoint, shiftStatusData).pipe(map((shift: any) => this.populateResult(shift)))
  }

  public updateIntegration(integration: Intergration): Observable<ShiftConfig> {
    const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${ShiftSettingsService.SHIFT_INTEGRATION}`, 
      {shiftId: this.buildShiftId(integration.shiftId)})

    return this.http.put<ShiftConfig>(endpoint, integration).pipe(
      map((shift: any) => this.populateResult(shift)))
  }

  public updateCutoffs(shiftId: Shiftid, cutoff: ShiftCutoffData[]): Observable<ShiftConfig> {
    const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${ShiftSettingsService.SHIFT_CUTOFF}`, 
      {shiftId: this.buildShiftId(shiftId)})

    return this.http.put<ShiftConfig>(endpoint, cutoff).pipe(
      map((shift: any) => this.populateResult(shift)))
  }

  public updateRouting(routing: Routing): Observable<ShiftConfig> {
    const endpoint = this.utilsService.interpolate(`${environment.api.url}${environment.api.prefix}/${ShiftSettingsService.SHIFT_ROUTING}`, 
      {shiftId: this.buildShiftId(routing.shiftId)})

    return this.http.put<ShiftConfig>(endpoint, routing).pipe(map((shift: any) => this.populateResult(shift)))
  }

  private populateResult(shift: any): ShiftConfig  {
    return (shift) ? new ShiftConfig().deserialize(shift) : null;
  }

  private buildShiftId(shiftId: Shiftid): string {
    return `${shiftId.date}:${shiftId.warehouse}:${shiftId.type}`;
  }
}
