import { Component, OnInit } from '@angular/core';
import { ShiftsService } from '@services/shifts.service';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { VisualiserService } from '../../services/visualiser.service';
import { RoutesPlanningParams } from '../../interfaces/routes-planning-params.interface';
import { RouteId } from '@entities/routeId';
import { Observable } from 'rxjs';
import { OptimizeResult } from '../../entities/optimize-result';
import { tap } from 'rxjs/operators';
import { OptimizeRoutes } from '../../entities/optimize-routes';

@Component({
  selector: 'app-visualiser-simulator-solution-view',
  templateUrl: './visualiser-simulator-solution-view.component.html',
  styleUrls: ['./visualiser-simulator-solution-view.component.scss']
})
export class VisualiserSimulatorSolutionViewComponent implements OnInit {

  private params: RoutesPlanningParams;
  private shiftId: string;
  private simulationKey: string;
  private solutionId: string;

  public routesPlanningResults$: Observable<OptimizeResult>;
  public distances$: Observable<any>;
  public loader = true;
  public noRoutes = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilsService: UtilsService,
    private readonly translate: TranslateService,
    private readonly notifierService: NotifierService,
    private readonly visualiserService: VisualiserService,
    private readonly shiftService: ShiftsService,
    private readonly translateService: TranslateService
  ) { }

  public ngOnInit() {

    this.params = { 
      y: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'y'),
      m: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'm'),
      d: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'd'),
      shiftId: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'shiftId'),
      depot: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'depot'),
    } as RoutesPlanningParams;

    this.distances$ = this.visualiserService.distances.pipe(
    //  tap(a => (a) ? this.loader = false : null)
    );

    this.simulationKey = this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'simulationKey');
    this.solutionId = this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'solutionId');
    this.shiftId = RouteId.getShiftId3(this.params.y, this.params.m, this.params.d, this.params.shiftId);

    this.routesPlanningResults$ = this.visualiserService.getSolution(this.shiftId, this.simulationKey, this.solutionId).pipe(
      tap((optimizeResult: OptimizeResult) => {
        console.log(optimizeResult);
        this.loader = false;
      })
    );

    

  }

  public back() {
    window.history.back();
  }

}
