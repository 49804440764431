import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { VanBodyType, VanStatus, VanStatusOptions, Client } from '@enums/enum';
import { NavService } from '@services/nav.sevice';

import { Van } from '../entities/van';
import { HardwareService } from '../services/hardware.service';
import { Depot } from '@interfaces/depot.interface';
import { AppService } from '@services/app.service';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { PageableResponse } from '@entities/pagable-response';

@Component({
    selector: 'edit-van',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h4 *ngIf="mode == 'edit' || mode == 'show'">
                        <i-feather name="truck"></i-feather> {{ 'Vans' | translate }}: {{ van.label }}
                    </h4>
                    <h4 *ngIf="mode == 'create'">
                        <i-feather name="plus-square"></i-feather> {{ 'Add van' | translate }}
                    </h4>
                </div>
                <div toolbar>
                    <button id="fleet-edit-btn-cancel" class="btn btn-sm btn-uppercase btn-white" *ngIf="mode == 'edit'" [routerLink]="['/fleet']">{{ 'Cancel' | translate }}</button>

                    <button id="fleet-edit-btn-cancel" class="btn btn-sm btn-uppercase btn-white" *ngIf="mode == 'create'" [routerLink]="['/fleet']">{{ 'Cancel' | translate }}</button>

                    <button id="fleet-edit-btn-edit" *ngIf="mode == 'show'" class="btn btn-sm btn-uppercase btn-white" [routerLink]="['/fleet/' + this.van.id + '/edit']">{{ 'Edit' | translate }} </button>
                </div>
                <hr />
            </div>
            <div content class="bg-white">
                <div *ngIf="van" class="content">
                    <div class="card-content col-8 offset-2">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div id="flee-editt-form-owner" class="form-group row">
                                <label class="col-2" for="owner">{{ 'Owner'.toUpperCase() | translate }}:</label>
                                <div class="col-10">
                                    <select *ngIf="(hardwareOwner$ | async) as hardwareOwner" class="custom-select" formControlName="owner">
                                        <option *ngFor="let owner of hardwareOwner" [value]="owner">{{ owner | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('owner').hasError('required') && form.get('owner').touched" class="error-color">{{ 'Owner field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-registration" class="form-group row">
                                <label class="col-2" for="registration">{{ 'Registration' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="registration" class="form-control" />
                                    <div *ngIf="form.get('registration').hasError('required') && form.get('registration').touched" class="error-color">
                                        {{ 'Registration number is required' | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-brand" class="form-group row">
                                <label class="col-2" for="brand">{{ 'Brand' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="brand" class="form-control" />
                                    <div *ngIf="form.get('brand').hasError('required') && form.get('brand').touched" class="error-color">{{ 'Brand is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-vehicleTypeId" class="form-group row">
                                <label class="col-2" for="vehicleTypeId">{{ 'Vehicle type' | translate }}:</label>
                                <div class="col-10">
                                    <ng-container *ngIf="(vehicleTypes$ | async) as vehicleTypes">
                                        <select class="custom-select" formControlName="vehicleTypeId">
                                            <option *ngFor="let type of vehicleTypes" [value]="type.id">{{ type.code }}</option>
                                        </select>
                                    </ng-container>
                                    <div *ngIf="form.get('vehicleTypeId').hasError('required') && form.get('vehicleTypeId').touched" class="error-color">{{ 'Depot field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="fleet-edit-form-status" class="form-group row">
                                <label class="col-2" for="status">{{ 'Status' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="status">
                                        <option *ngFor="let status of VanStatusOptions" [value]="status">{{ status | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Status field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div appCheckClient [client]="friscoClient" id="fleet-edit-form-status" class="form-group row">
                                <label class="col-2" for="status">{{ 'Check vehicle with SOFTRA' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="scanForDamage">
                                        <option [ngValue]="true">{{ 'Yes' | translate }}</option>
                                        <option [value]="false">{{ 'No' | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Status field is required' | translate }}!</div>
                                </div>
                            </div>
                            
                            
                            <ng-container formGroupName="properties">
                                <div id="fleet-edit-form-fuelCardNr" class="form-group row">
                                    <label class="col-2" for="fuelCardNr">{{ 'Fuel card number' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="fuelCardNr" class="form-control" />
                                    </div>
                                </div>
                                <div id="fleet-edit-form-fuelCardPin" class="form-group row">
                                    <label class="col-2" for="fuelCardPin">{{ 'Fuel card pin' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="fuelCardPin" class="form-control" />
                                    </div>
                                </div>
                            </ng-container>

                            <div *ngIf="formErrorsMessages && formErrorsMessages.length" class="form-group row">
                                <div class="col-10 offset-2">
                                    <p class="error-color">{{ 'Something has wrong! Please correct form' | translate }} :</p>
                                    <ul>
                                        <li *ngFor="let error of formErrorsMessages" class="error-color">
                                            <span *ngIf="error.value">{{ 'Field' | translate }}</span>
                                            <strong>{{ error.field | translate }}</strong>
                                            <span>{{ error.value | translate }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group row justify-content-end">
                                <div class="col-2">
                                    <button id="fleet-edit-form-btn-submit" [disabled]="!form.valid || submitted || disabled" class="btn btn-sm btn-uppercase btn-brand-01 w-100">
                                        <span *ngIf="!submitted">{{ 'Save' | translate }}</span>

                                        <ng-container *ngIf="submitted">
                                            <div class="load-spinner text-center">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </layout-default>

        <ng-template #removeVanConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>
                    {{ 'Do you want to remove van:' | translate }} <strong>{{ van.label }}</strong
                    >?
                </p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate }}
                </button>
            </div>
        </ng-template>
    `,
    styleUrls: ['van-edit.component.scss']
})
export class VanEditComponent implements OnInit {
    @ViewChild('removeVanConfirmation', { static: true }) private removeVanConfirmation: TemplateRef<any>;

    @Input('mode') public mode: string;

    @Input() set vanId(vanId) {
        if (!vanId) {
            return;
        }
        this.hardwareService
            .getVan(vanId)
            .pipe(
                map((van: Van) => {
                    console.log('input van', van);
                    this.van = van;
                    this.form.patchValue(this.van);
                })
            )
            .subscribe();
    }

    private readonly TAG: string = '[UpdateVansComponent]';
    private confirmObservable: Observable<any>;
    private modalRef: BsModalRef;
    public vanOwnerOptions: string[] = [];

    public formErrorsMessages;
    public van: Van = new Van();
    public submitted: boolean = false;
    public form: FormGroup;
    public depots$: Observable<Depot[]>;
    public vehicleTypes$: Observable<VehicleTypes[]>;
    public hardwareOwner$: Observable<string[]>;
    public friscoClient: string = Client.FRISCO;

    get disabled() {
        return this.mode !== 'create' && this.mode !== 'edit';
    }

    get VanStatusOptions() {
        return VanStatusOptions;
    }

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly hardwareService: HardwareService,
        private readonly notifierService: NotifierService,
        private readonly modalService: BsModalService,
        private readonly translate: TranslateService,
        private readonly navService: NavService,
        private readonly appService: AppService,
        private readonly vehicleTypesService: VehicleTypesService
    ) {}

    public ngOnInit(): void {

        this.vehicleTypesService.fetchAll();
        
        this.vehicleTypes$ = this.vehicleTypesService.list$.pipe(
            map((pageable: PageableResponse<VehicleTypes>) => {
                return pageable.content;
            })
        );

        this.hardwareOwner$ = this.hardwareService.getHardwareOwner();

        this.form = this.formBuilder.group({
            id: [null],
            registration: [null, Validators.required],
            brand: [null, Validators.required],
            owner: [null, Validators.required],
            status: [VanStatus.AVAILABLE, Validators.required],
            vehicleTypeId:  [null, Validators.required],
            scanForDamage: [false],
            properties: this.formBuilder.group({
                fuelCardNr: [null],
                fuelCardPin: [null]
            }),
            version: [null]
        });

        if (this.disabled) {
            this.form.disable();
        }
    }

    public onSubmit(): void {
        this.submitted = true;

        this.van.patch(this.form.getRawValue());
        console.log('SENDING ACTION: ', this.van);

        const rawVan = _.cloneDeep(this.van);

        if (this.mode === 'create') {
            delete rawVan.version;

            this.hardwareService
                .createVan(rawVan)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    (van: Van) => {
                        console.log(this.TAG, 'Creating van - done', van);
                        this.notifierService.notify('success', this.translate.instant('Vans list has been updated'));
                        this.navService.goToPage(`/fleet/${van.id}/list`);
                        this.submitted = false;
                    },
                    (error: any) => {
                        console.log(`${this.TAG} Create van - error`, error);
                        this.formErrorsMessages = error;
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        } else if (this.mode === 'edit') {
            this.hardwareService
                .updateVan(rawVan)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    (van: Van) => {
                        console.log(this.TAG, 'Updating van - done', van);
                        this.notifierService.notify('success', this.translate.instant('Vans list has been updated'));
                        this.navService.goToPage(`/fleet/${van.id}/list`);
                        this.submitted = false;
                    },
                    (error: any) => {
                        console.log(`${this.TAG} Update van - error`, error);
                        this.formErrorsMessages = error;
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        }
    }

    public confirm(): void {
        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.confirmObservable = null;
            this.notifierService.notify('success', this.translate.instant('The van has been removed!'));
            this.navService.goToPage('/fleet');
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }
}
