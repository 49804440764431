import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalaryEditComponent } from './components/salary-edit/salary-edit.component';
import { SalaryListComponent } from './components/salary-list/salary-list.component';
import { SalaryRoutingModule } from './salary.routing';
import { SharedModule } from 'src/app/shared.module';
import { AuthenticationModule } from 'src/app/authentication/authentication.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgDatepickerModule } from 'ng2-datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgencyListComponent } from './components/agency-list/agency-list.component';
import { AgencyEditComponent } from './components/agency-edit/agency-edit.component';
import { AgencyResolver } from './resolvers/agency.resolver';
import { AgencyAssignDriverComponent } from './components/agency-assign-driver/agency-assign-driver.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ReportListComponent } from './components/report-list/report-list.component';
import { ReportEditComponent } from './components/report-edit/report-edit.component';
import { SalaryReportResolver } from './resolvers/salary-report.resolver';
import { ReportCreateComponent } from './components/report-create/report-create.component';
import { ReportAssignDriverComponent } from './components/report-assign-driver/report-assign-driver.component';
import { SalaryReportFilesResolver } from './resolvers/salary-report-files.respolver';
import { ReportFilesComponent } from './components/report-files/report-files.component';
import { TooltipModule } from 'ngx-bootstrap';



@NgModule({
  declarations: [
    SalaryListComponent,
    SalaryEditComponent,
    AgencyListComponent,
    AgencyEditComponent,
    AgencyAssignDriverComponent,
    ReportListComponent,
    ReportCreateComponent,
    ReportEditComponent,
    ReportAssignDriverComponent,
    ReportFilesComponent
  ],
  providers: [
    AgencyResolver,
    SalaryReportResolver,
    SalaryReportFilesResolver
  ],
  imports: [
    CommonModule,
    SalaryRoutingModule,
    SharedModule,
    AuthenticationModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgDatepickerModule,
    AutocompleteLibModule,
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forChild({}),
  ]
})
export class SalaryModule { }
