import { Component, EventEmitter, Input, Output, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { ShiftConfig } from './entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { ShiftSettingsService } from './services/shift-settings.service';
import { Subscription, Observable } from 'rxjs';
import * as _ from 'lodash';
import { Routing } from './interafces/routing.interface';
import { ShiftsService } from '@services/shifts.service';
import { Planning } from '@entities/planning';
import { VanLimitInterface } from './interafces/vanLimit.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IShiftStatus } from './interafces/shift-status.interface';
import { ShiftStatus, RouteStrategyType } from '@enums/enum';
import { Depot } from '@interfaces/depot.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { DepotService } from '../locations/services/depot.service';
import { tap } from 'rxjs/operators';
import { DepotVirtual } from '@interfaces/depot-virtual.interface';
import { VirtualDepotConfig } from './interafces/virtual-depot-config.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment/moment';

@Component({
    selector: 'shift-routing',
    template: `
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Routing' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_routing_edit]" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">
                <div *ngFor="let control of numberControls()" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control] }}</div>
                </div>
                <div *ngFor="let control of booleanControls()" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control].toString() | translate }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.routeStrategy' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('routeStrategy')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'routeStrategy' }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig['routeStrategy'].toString() | translate }}</div>
                </div>
                <div class="slot-limits mg-b-15">
                    <div>
                        <span>{{ 'shiftProperty.vanLimits' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('vanLimits')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="slot-limits__container">
                        <div *ngFor="let limit of shiftConfig.vanLimits" class="modify-value modify-value--slots mg-b-5">
                            <span class="slot-limits__hour">{{'shiftProperty.RoutesUsed' | translate }}:<strong>{{ limit.usedPercentage }}%</strong>:</span> 
                            <span>{{'shiftProperty.MinimalUsage' | translate }}:</span>
                            <span class="slot-limits__limit"> {{ limit.blockedSlots }}</span>
                        </div>
                    </div>
                </div>
                <div class="slot-limits mg-b-15">
                    <div>
                        <span>{{ 'shiftProperty.slotLimits' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('slotLimits')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="slot-limits__container">
                        <div *ngFor="let key of _.keys(shiftConfig.slotLimits)" class="modify-value modify-value--slots mg-b-5">
                            <span class="slot-limits__hour">{{ key }} – {{ 1 * key.split(':')[0] + 1 | number: '2.0' }}:{{ key.split(':')[1] }}:</span>
                            <span class="slot-limits__limit">{{ shiftConfig.slotLimits[key] }}</span>
                        </div>
                    </div>
                </div>
                <div class="slot-limits mg-b-15">
                    <div>
                        <span>{{ 'shiftProperty.virtualDepotConfig' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('virtualDepotConfig')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.virtualDepotConfig' | translate}}:</i-feather>
                        </sup>
                    </div> 
                    <div class="slot-limits__container">
                        <div *ngFor="let virtualDepotConfig of shiftConfig.virtualDepotConfig" class="modify-value modify-value--slots mg-b-5">
                            ID: {{virtualDepotConfig.virtualDepotId}}, 
                            {{'Routes' | translate}}: {{virtualDepotConfig.routes}},
                            {{ 'shiftProperty.routeStrategy' | translate }}: {{virtualDepotConfig.routeStrategy | translate}},
                            {{ 'shiftProperty.isExclusive' | translate }}: {{virtualDepotConfig.isExclusive.toString() | translate}}

                        </div>
                    </div>
                </div>
                <div *ngIf="(planningSequence$ | async) as planningSequence" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.planningSequence' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('planningSequence')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'planningSequence' | translate }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ (planningSequence | findById:shiftConfig.planningSequence).name | translate }}</div>
                </div>

                <button [routerLink]="['/routes/auditlog', shiftId, 'SHIFT']" class="btn btn-outline-success w-100">{{'Audit log' | translate}}</button>

                
                <button appCheckFeaturesConfig [featureName]="[Permissions.Calendar_advancedsettings]" (click)="removeShiftConfirmation(shiftId)" class="mt-2 btn btn-outline-danger btn-red w-100">{{'Remove shift' | translate}}</button>

            </div>
        </div>

        <div *ngIf="mode == 'edit' || mode == 'saving'" [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <h3 class="title">{{ 'Routing' | translate }}</h3>

                <div class="form">
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <div *ngFor="let control of numberControls(); let index = index">
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                            </sup>
                        </div> 
                        <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedShiftConfig[control]" />
                    </div>
                    <div *ngFor="let control of booleanControls()"> 
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig[control]">
                            <option [ngValue]="true">{{ 'True' | translate }}</option>
                            <option [ngValue]="false">{{ 'False' | translate }}</option>
                        </select>
                    </div>
                    <div> 
                        <div>
                            <span>{{ 'shiftProperty.routeStrategy' | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('routeStrategy')">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'routeStrategy' }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig['routeStrategy']">
                            <option *ngFor="let strategy of (routeStrategies$ | async)" [ngValue]="strategy">{{ strategy | translate }}</option>
                        </select>
                    </div>
                    

                    <div>
                        <div class="mg-b-20" for="vanLimits">{{ 'shiftProperty.vanLimits' | translate | translate }}:</div>
                        <div class="container" *ngFor="let limit of editedShiftConfig.vanLimits">
                            <div class="row van-limits d-flex align-items-baseline">
                                <label>{{'shiftProperty.RoutesUsed' | translate }}:</label>&nbsp;
                                <input class="col-2" type="number" [min]="0" [max]="100" step="1" (change)="sortVanLimits()" [style.margin-right.px]="3" [(ngModel)]="limit.usedPercentage" />
                                <label>{{'shiftProperty.MinimalUsage' | translate }}:</label>&nbsp;
                                <input class="col-2" type="number" [min]="0" step="1" [style.margin-right.px]="3" [(ngModel)]="limit.blockedSlots" />
                                <button (click)="removeVanLimits(limit)" class="col-1 btn btn-xs"><i-feather name="delete" [style.height.px]="18"></i-feather></button>
                            </div>
                        </div>
                        <button (click)="addVanLimits()" class="btn btn-xs btn-white pd-5 mg-t-10 mg-b-20">{{'Add van limit' | translate}}</button>
                    </div>
                    <div>
                        <div for="slotLimits">{{ 'shiftProperty.slotLimits' | translate | translate }}:</div>
                        <div class="container" *ngFor="let key of _.keys(editedShiftConfig.slotLimits); let index">
                            <div class="row">
                                <div class="col-7 mt-2">{{ key }} – {{ 1 * key.split(':')[0] + 1 | number: '2.0' }}:{{ key.split(':')[1] }}:</div>
                                <input class="col-4" type="number" [min]="1" step="0.01" [(ngModel)]="editedShiftConfig.slotLimits[index]" />
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(depot$ | async)">
                        <div class="mg-b-10" for="slotLimits">{{ 'shiftProperty.virtualDepotConfig' | translate }}:</div>
                        <div class="container" *ngFor="let virtualDepotConfig of editedShiftConfig.virtualDepotConfig; let index">
                       
                            <label class="tx-10 tx-bold tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0"><strong>{{ virtualDepotConfig.name | translate }}</strong></label>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'Active' | translate }}:</div>
                                <select class="col-4" [(ngModel)]="virtualDepotConfig.enabled">
                                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'Routes' | translate }}:</div>
                                <input class="col-4" type="number" [(ngModel)]="virtualDepotConfig.routes" />
                            </div>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'shiftProperty.routeStrategy' | translate }}:</div>
                                <select class="col-4" [(ngModel)]="virtualDepotConfig.routeStrategy">
                                    <option *ngFor="let option of routeStrategyType" [value]="option">{{ option | translate }}</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'shiftProperty.isExclusive' | translate }}:</div>
                                <select class="col-4" [(ngModel)]="virtualDepotConfig.isExclusive">
                                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                                </select>
                            </div>
                            
                        </div>
                    </div>

                    <div *ngIf="(planningSequence$ | async) as planningSequence"> 
                        <div>
                            <span>{{ 'shiftProperty.planningSequence' | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('planningSequence')">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'planningSequence' }}:</i-feather>
                            </sup>
                        </div> 
                        <select id="planningSequence" [(ngModel)]="editedShiftConfig['planningSequence']">
                            <option *ngFor="let option of planningSequence" [value]="option.id">{{ option.name | translate }}</option>
                        </select>
                    </div>
                </div>
            </editor-window>
        </div>

        <ng-template #removeConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
            </div>

            <div class="modal-body text-center">
                <p>{{'Do you confirm removal of the shift' | translate }}?</p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{'Yes, remove' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
            </div>
        </ng-template>
    `
})
export class ShiftRoutingComponent implements OnInit, OnDestroy {
    
    @Input() public shiftConfig: Routing = null;

    @Input()
    public shiftStatusData: IShiftStatus = null;

    @Output() public shiftConfigChanged: EventEmitter<ShiftConfig> = new EventEmitter();

    public mode = 'view';
    public editedShiftConfig: Routing = null;
    public Permissions = Permissions;
    private updateFulfilmentSub$: Subscription;
    public shiftStatus = ShiftStatus;
    public depot$: Observable<Depot>;
    public routeStrategyType = Object.values(RouteStrategyType);

    public _: any = _;

    public planningSequence$: Observable<Planning[]>;
    public routeStrategies$: Observable<string[]>;
    public depot: Depot;

    @ViewChild('removeConfirmation', {static: true}) public confirmation: TemplateRef<any>;
    public modalRef: BsModalRef;
    public confirmObservable: Observable<any>;

    get shiftId () {
        return `${this.shiftConfig.shiftId.date}:${this.shiftConfig.shiftId.warehouse}:${this.shiftConfig.shiftId.type}`;
    }

    public booleanControls(): string[] {
        return ['automaticCutoff', 'includeReturnInPlanning', 'removePlanning'];
    }

    public numberControls(): string[] {
        return ['expensiveDeliveryThreshold', 'expensiveDeliveryThresholdTime', 'maxCostFactor', 'routeMargin', 'routeMaximumReloads', 'shiftDateOffset', 'shiftMaximumSegments', 'slotEndMarginMinutes', 'slotStartMarginMinutes', 'loadFactor', 'reloadPercentage'];
    }

    public stringControls(): string[] {
        return ['planningSequence', 'routeStrategy'];
    }

    constructor(
        private readonly shiftSettingsService: ShiftSettingsService,
        private readonly shiftService: ShiftsService,
        private readonly translateService: TranslateService,
        private readonly localStorageService: LocalStorageService,
        private readonly depotService: DepotService,
        private readonly modalService: BsModalService,
        private readonly cdr: ChangeDetectorRef,
        private readonly notifierService: NotifierService
    ) {}


    public ngOnInit() {
        this.planningSequence$ = this.shiftService.loadPlanningSequence();
        this.routeStrategies$ = this.shiftService.loadRouteStrategyDictionary();
        this.depot$ = this.depotService.findOne(this.localStorageService.getDepot()).pipe(
            tap((depot: Depot) => {
                this.depot = depot;
                this.virtualDepotParser();
            })
        );
    }

    public onModify() {
        this.editedShiftConfig = _.cloneDeep(this.shiftConfig);
        this.mode = 'edit';
        console.log(this.editedShiftConfig);
    }

    public onCancel() {
        this.editedShiftConfig = null;
        this.mode = 'view';
    }

    public onSave() {
        this.mode = 'saving';

        this.editedShiftConfig.virtualDepotConfig = this.editedShiftConfig.virtualDepotConfig.filter(v => v.enabled)

        this.updateFulfilmentSub$ = this.shiftSettingsService.updateRouting(this.editedShiftConfig)
            .subscribe((updatedShiftConfig: ShiftConfig) => {
                this.editedShiftConfig = null;
                this.mode = 'view';
                this.shiftConfigChanged.next(updatedShiftConfig);
            },
            (error: HttpErrorResponse) => {
                this.mode = 'view';
            });
    }

    public tooltipLabel(property: string): string {
        return tooltipLabel(this.translateService, `tooltips.shift.labels.${property}`);
    }

    public addVanLimits(): void {
        this.editedShiftConfig.vanLimits.push({ blockedSlots: 0, usedPercentage: 0 });

        if (!this.editedShiftConfig.vanLimits.find((a: VanLimitInterface) => a.usedPercentage === 100)) {
            this.editedShiftConfig.vanLimits.push({ blockedSlots: 0, usedPercentage: 100 });
        }

        this.sortVanLimits();
    }

    public removeVanLimits(vanLimit: VanLimitInterface): void {
        this.editedShiftConfig.vanLimits = this.editedShiftConfig.vanLimits.filter((a: VanLimitInterface) => a.usedPercentage !== vanLimit.usedPercentage );
    }

    public virtualDepotParser() {
        if (!this.editedShiftConfig || this.editedShiftConfig.virtualDepotConfig === null) {
            return;
        }

        const copiedVirtualDepotConfig = this.editedShiftConfig.virtualDepotConfig;
        this.editedShiftConfig.virtualDepotConfig = [];

        this.depot.virtualDepots.forEach((dv: DepotVirtual) => {
            const exist = (copiedVirtualDepotConfig.find((v: VirtualDepotConfig) => v.virtualDepotId === dv.id));
            if (!exist) {
                this.editedShiftConfig.virtualDepotConfig.push({
                    routeStrategy: RouteStrategyType.Count,
                    routes: 0,
                    virtualDepotId: dv.id,
                    isExclusive: false,
                    enabled: false,
                    name: dv.name
                } as VirtualDepotConfig)
            } else {
                this.editedShiftConfig.virtualDepotConfig.push({
                    routeStrategy: exist.routeStrategy,
                    routes: exist.routes,
                    virtualDepotId: exist.virtualDepotId,
                    isExclusive: exist.isExclusive,
                    enabled: true,
                    name: dv.name
                } as VirtualDepotConfig)
            }
        })
    }

    private sortVanLimits(): void {
        if (this.editedShiftConfig.vanLimits.length > 1) {
            this.editedShiftConfig.vanLimits = this.editedShiftConfig.vanLimits.filter((a: VanLimitInterface) => a.usedPercentage >= 0 && a.usedPercentage <= 100 );
            this.editedShiftConfig.vanLimits = _.sortBy(this.editedShiftConfig.vanLimits, (a: VanLimitInterface) => a.usedPercentage );
            if (!this.editedShiftConfig.vanLimits.find((a: VanLimitInterface) => a.usedPercentage === 100)) { 
                this.editedShiftConfig.vanLimits[this.editedShiftConfig.vanLimits.length - 1].usedPercentage = 100;
            }
        } else {
            this.editedShiftConfig.vanLimits = [];
        }
    }

    public removeShiftConfirmation(shiftId):void {
        console.log(this.shiftConfig);
        this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
        this.confirmObservable = this.shiftService.removeShift(shiftId);
    }

    public confirm(): void {
        this.confirmObservable.subscribe((test) => {
          this.modalRef.hide();
          this.notifierService.notify('success', this.translateService.instant('The shift has been removed!'));
          this.confirmObservable = null;
          const startdate = moment(this.shiftConfig.shiftId.date).startOf('month').format('YYYY-MM-DD');
          const enddate = moment(this.shiftConfig.shiftId.date).endOf('month').format('YYYY-MM-DD');
          this.shiftService.loadMonthConfiguration(startdate, enddate, this.shiftConfig.shiftId.warehouse);
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }

    public ngOnDestroy() {
        if (this.updateFulfilmentSub$) {
            this.updateFulfilmentSub$.unsubscribe()
        }
    }
}
