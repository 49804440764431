import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as en from 'date-fns/locale/en';
import * as pl from 'date-fns/locale/pl';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';

import { ShiftType } from '@enums/enum';
import { AppService } from '@services/app.service';

import { datePickerOptions } from '../constants/datepicker-options.constants';
import { DictionaryService } from '@services/dictionary.service';
import { ShiftsService } from '@services/shifts.service';
import { ShiftConfig } from '@calendar/entities/shiftConfig';

@Component({
    selector: 'shift-picker',
    template: `
        <ng-datepicker class="datepicker-input" *ngIf="!pickerLoader" (ngModelChange)="changeDate($event)" [ngModel]="model.date" [options]="datePickerOptions" datepicker></ng-datepicker>

        <button
            *ngFor="let shiftConfig of (shiftsTypes$ | async)"
            (click)="change(shiftConfig.type)"
            class="btn btn-sm btn-uppercase"
            [ngClass]="{ 'btn-brand-01 btn-type-shift': activeBtn(shiftConfig.type), 'btn-white': !activeBtn(shiftConfig.type) }"
        >
            {{ shiftConfig.type | translate }}
        </button>
    `,
    styleUrls: ['./shift-picker.component.scss']
})
export class ShiftPickerComponent implements OnInit, OnDestroy {
    @Output() private changedShift: EventEmitter<any> = new EventEmitter();

    @Input() private shift;

    private tag = '[ShiftPickerComponent]';
    private shiftId: any;
    private selectedShift: string;
    private selectedTimeOfDay: string;
    public locale;
    private datePickerOptions = datePickerOptions;
    private _selectedWarehouseSubscritpion: Subscription;

    public pickerLoader: boolean = false;

    public shiftsTypes$: Observable<ShiftConfig[]>;

    private model: { date: Date; timeOfDay: string } = {
        date: new Date(new Date()),
        timeOfDay: new Date().getHours() > 13 ? ShiftType.AFTERNOON : ShiftType.MORNING
    };

    private activeBtn = shiftId => this.selectedTimeOfDay.toUpperCase() === shiftId.toUpperCase();

    constructor(
        private translate: TranslateService, 
        private appService: AppService, 
        private dictionaryService: DictionaryService,
        private readonly shiftService: ShiftsService
    ) {
        this.locale = translate.currentLang === 'en' ? en : pl;
        this.datePickerOptions.locale = this.locale;
    }

    public ngOnInit() {

        if (this.shift) {
            this.shiftId = this.shift;
        }
        
        setTimeout(() => {
            if (!_.isUndefined(this.shiftId) && !_.isEmpty(this.shiftId)) {
                console.log(this.tag, `input shift ${this.shiftId}`);
                localStorage.setItem('preferredShiftId', this.shiftId);
                const [y, m, d, sh] = this.shiftId.split('/');
                this.getShiftsTypes();
                this.selectedTimeOfDay = sh;
                this.model.date = moment(`${y}-${m}-${d}`).toDate();
            } else if (localStorage.getItem('preferredShiftId')) {
                console.log(this.tag, `input shift ${this.shiftId}`);
                this.selectedShift = localStorage.getItem('preferredShiftId');
                const [y, m, d, sh] = this.selectedShift.split('/');
                this.model.date = moment(`${y}-${m}-${d}`).toDate();
                this.getShiftsTypes();
                this.selectedTimeOfDay = sh;
                this.changedShift.emit(this.selectedShift);
            } else {
                const today = moment().format('YYYY/MM/DD'); // todo use current date
                const initialShift = (this.selectedTimeOfDay = new Date().getHours() > 13 ? ShiftType.AFTERNOON : ShiftType.MORNING); // todo calculate dynamically
                this.selectedShift = `${today}/${initialShift}`;
                this.getShiftsTypes();
                localStorage.setItem('preferredShiftId', this.selectedShift);
                this.changedShift.emit(this.selectedShift);
            }
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.locale = this.translate.currentLang === 'en' ? en : pl;
            this.datePickerOptions.locale = this.locale;
        });

        this._selectedWarehouseSubscritpion = this.appService.selectedWarehouse.subscribe(warehouse => {
            console.log(`${this.tag} Changed warehouse location`);

            if (warehouse !== localStorage.getItem('depot')) {
                this.change(this.selectedShift);
            }
        });
    }

    public ngOnDestroy() {
        if (this._selectedWarehouseSubscritpion) {
            this._selectedWarehouseSubscritpion.unsubscribe();
        }
    }

    private getShiftsTypes() {
        this.shiftsTypes$ = this.shiftService.getShiftsTypesForShift(`${moment(this.model.date).format('YYYY-MM-DD')}`, localStorage.getItem('depot'));
    }

    private change(shiftId?: string) {
        const preferredShiftId = localStorage.getItem('preferredShiftId');
        const sh = preferredShiftId ? preferredShiftId.split('/')[3] : ShiftType.MORNING;

        const dateModel: Object = {
            day: ('0' + this.model['date'].getDate()).slice(-2),
            month: ('0' + (this.model['date'].getMonth() + 1)).slice(-2),
            year: this.model['date'].getFullYear(),
            timeOfDay: (shiftId && !shiftId.includes('/')) ? String(shiftId).toUpperCase() : sh
        };

        this.shiftsTypes$ = this.shiftService.getShiftsTypesForShift(`${moment(this.model.date).format('YYYY-MM-DD')}`, localStorage.getItem('depot'));

        this.selectedShift = `${dateModel['year']}/${dateModel['month']}/${dateModel['day']}/${dateModel['timeOfDay']}`;
        this.selectedTimeOfDay = dateModel['timeOfDay'];

        console.log(this.tag, '(changed)', this.selectedShift);

        localStorage.setItem('preferredShiftId', this.selectedShift);

        this.changedShift.emit(this.selectedShift);
    }

    public changeDate(event) {
        this.model.date = event;
        this.change();
    }

    private __translate() {
        this.translate.instant('AFTERNOON');
        this.translate.instant('MORNING');
        this.translate.instant('EVENING');
    }
}
