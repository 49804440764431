import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SalaryReportService } from '../../services/salary-report.service';
import { QueryParams } from '@interfaces/query-params.interface';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { NavService } from '@services/nav.sevice';
import { Observable } from 'rxjs';
import { SalaryReport } from '../../interfaces/salary-report';
import { environment } from '@environment';
import { PageableResponse } from '@entities/pagable-response';
import { tap } from 'rxjs/internal/operators/tap';
import * as _ from 'lodash';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  public reportsSubscriptions$: Observable<PageableResponse<SalaryReport>>;

  public reports: SalaryReport[];
  public reportsCollection: SalaryReport[];

  public sortedColumn: string | undefined;

  public columns = [
    { prop: 'name', name: this.translateService.instant('Name'), flexGrow: 3, sortable: true },
    { prop: 'baseMonthlySalaryNet', name: this.translateService.instant('Monthly salary net'), flexGrow: 2, sortable: true },
    { prop: 'baseMonthlySalaryGross', name: this.translateService.instant('Monthly salary gross'), flexGrow: 2, sortable: true },
    { prop: 'baseDeliveryFeeNet', name: this.translateService.instant('Delivery fee net'), flexGrow: 2, sortable: true },
    { prop: 'baseDeliveryFeeGross', name: this.translateService.instant('Delivery fee gross'), flexGrow: 2, sortable: true },
    { prop: 'driverIdSet.length', name: this.translateService.instant('Drivers'), flexGrow: 2 },
    { prop: 'status', name: this.translateService.instant('Status'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1 },
  ];


  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public pagination = {
    limit: 20,
    maxSize: 20
  };

  public queryParams: QueryParams = Object.assign({}, environment.pagination);

  constructor(
    private readonly salaryReportService: SalaryReportService,
    private readonly translateService: TranslateService,
    private readonly modalService: BsModalService,
    private readonly notifierService: NotifierService,
    private readonly navService: NavService
  ) { }

  public ngOnInit() {
    this.reportsSubscriptions$ = this.salaryReportService.list$.pipe(
      tap((report: PageableResponse<SalaryReport>) => {
        this.reports = this.reportsCollection = report.content;
      })
    );
    this.salaryReportService.fetchAll(this.queryParams);
  }

  public setPage(pageInfo?) {
    this.queryParams.page = pageInfo.offset;
    this.salaryReportService.fetchAll(this.queryParams);
  }

  public onSort(event) {
    this.queryParams.sort = event.sorts[0].prop;
    this.queryParams.order = event.sorts[0].dir;
    this.salaryReportService.fetchAll(this.queryParams);
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/salary/agency/${id}/edit`);
  }

  public removeSalaryreport(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.salaryReportService.delete(id);
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.notifierService.notify('success', this.translateService.instant('The salary report has been removed!'));
      this.confirmObservable = null;
      this.queryParams.page = 0;
      this.salaryReportService.fetchAll(this.queryParams);
    });
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.reportsCollection, (report: SalaryReport) => {
      const searchIn: string = report.name;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });

    this.reports = temp;
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  public generateRaport(id: string){
    this.salaryReportService.genereteSalaryReport(id).subscribe(
      (salaryReport: SalaryReport) => {
        this.notifierService.notify('success', this.translateService.instant('The salary report has been generated!'));
        this.salaryReportService.fetchAll(this.queryParams);
      },
      (error) => {
        console.log(`Error occurred, please try again!`, error);
      }
    )
  }

  public createNewRevision(id: string){
    this.salaryReportService.createNewRevision(id).subscribe(
      (salaryReport: SalaryReport) => {
        this.notifierService.notify('success', this.translateService.instant('The salary report has been generated!'));
        this.salaryReportService.fetchAll(this.queryParams);
      },
      (error) => {
        console.log(`Error occurred, please try again!`, error);
      }
    )
  }
  
}
