import * as moment from 'moment';
import * as _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { Activity } from '@entities/activity';
import { RouteExt } from '@entities/route-ext';

@Pipe({
    name: 'endShiftPipe',
    pure: true
})
export class EndShiftPipe implements PipeTransform {

    public transform(route: RouteExt, shift: ShiftConfig): number {
        const endShift = moment(shift.date + ' ' + shift.endTime).add(1, 'hours');
        const lastActivity: Activity = route.activities[route.activities.length - 1];
        const diff = endShift.diff(lastActivity.planning.to, 'seconds');
        return diff > 0 ? diff : 0;
    }
}
