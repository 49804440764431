import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DriversService } from '@drivers/services/drivers.service';

@Injectable()
export class DriversResolver implements Resolve<any> {
    constructor(private driversService: DriversService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        return this.driversService.getDrivers();
    }
}
