import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import {DashboardDepotPunctuality} from '../../interfaces/DashboardPunctuality';
import {DashboardShift} from '../../interfaces/DashboardShiftChart';
import {DashboardComponent} from '../dashboard/dashboard.component';

@Component({
  selector: 'app-dashboard-charts',
  templateUrl: './dashboard-charts.component.html',
  styleUrls: ['./dashboard-charts.component.scss']
})
export class DashboardChartsComponent implements OnInit {

  @Input() public settings: DashboardComponent['settings'];
  @Input() public punctuality: DashboardDepotPunctuality[];

  @ViewChild('chartsWrapper', {static: true}) public chartsWrapper: ElementRef;
  public fullscreen = false;

  constructor() { }

  public ngOnInit() {
  }

  public toggleFullscreen() {
    if (!document.fullscreenElement) {
      this.chartsWrapper.nativeElement.requestFullscreen().then(success => {
        this.fullscreen = true;
      });
    }
    else {
      document.exitFullscreen();
      this.fullscreen = false;
    }
  }
}

