<ng-container *ngIf="(depot$ | async) as depot">

  <table *ngIf="slotsResults && shiftSummaryStats && !loading" class="table ">
    <thead>
      <tr>
        <td id="booking-availability-table-shift"><strong>{{'Shift' | translate}}</strong></td>

        <td id="booking-availability-table-slot"><strong>Slot</strong></td>
        <!-- <td id="booking-availability-table-limit" width="5%"><strong>Limit</strong></td> -->

        <td id="booking-availability-table-customer-{{index}}" *ngFor="let customer of customers; let index = index"
          class="text-center customer">
          <strong>{{customer[4] + 1}}</strong>&nbsp;&nbsp;
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let slot of slots; let index = index;">
        <tr>
          <td>
            <ng-container *ngIf="findStats(slot) as stats">

              {{stats.shift.type | translate}}

              <sup placement="bottom" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(stats)">
                &nbsp;<i-feather [style.width.px]="10" [style.height.px]="10" name="info"></i-feather>
              </sup>
            </ng-container>
          </td>
          <td>{{slot}}:00 - {{slot + 1}}:00</td>
          <!-- <td>{{ (slotCounts.hasOwnProperty(((slot.toString().length === 2 ? slot : '0' + slot)) +':00')) ?
            slotCounts[((slot.toString().length === 2 ? slot : '0' + slot)) +':00'] : 0 }}</td> -->
          <ng-container *ngFor="let s of slotsResults, let i = index;">
            <ng-container>
              <td [style.color]="'#FFF'" [ngStyle]="{'background-color':getColor(i, slot+':00:00')}"
                class="available text-center">
                <span class="availability-factor" [innerHTML]="getAvailabilityFactor(i, slot+':00:00')"></span>
              </td>
            </ng-container>
          </ng-container>
        </tr>
        <tr>
          <td></td>
          <td>{{slot}}:30 - {{slot + 1}}:30</td>
          <!-- <td>{{ (slotCounts.hasOwnProperty(((slot.toString().length === 2 ? slot : '0' + slot)) +':30')) ?
            slotCounts[((slot.toString().length === 2 ? slot : '0' + slot)) +':30'] : 0 }}</td> -->
          <ng-container *ngFor="let s of slotsResults, let i = index;">
            <ng-container>
              <td [style.color]="'#FFF'" [ngStyle]="{'background-color':getColor(i, slot+':30:00')}"
                class="available text-center">
                <span class="availability-factor" [innerHTML]="getAvailabilityFactor(i, slot+':30:00')"></span>
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div class="legend">
    <div class="l-1">- {{'Everyone' | translate}}</div>
    <div class="l-0">- {{'Nobody' | translate}}</div>
  </div>

  <agm-map #map id="booking-availability-map" [latitude]="depot.locationCoordinates.lat"
    [longitude]="depot.locationCoordinates.lng" [zoomControl]="true" [zoom]="10" [mapTypeControl]="true"
    [usePanning]="true" (mapClick)="placeMarker($event)" [fitBounds]="bounds">

    <agm-marker [latitude]="depot.locationCoordinates.lat" [longitude]="depot.locationCoordinates.lng"
      iconUrl="{{markerPath}}/{{environment.client.toLowerCase()}}Marker.png">
    </agm-marker>

    <ng-container *ngFor="let marker of customers; let i = index;">
      <agm-marker [latitude]="marker[0]" [longitude]="marker[1]" iconUrl="{{markerPath}}/v2.png"
        [label]="(i + 1).toString()">
      </agm-marker>
    </ng-container>
  </agm-map>
</ng-container>



<div *ngIf="loading" class="load-spinner text-center">
  <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
</div>

<div *ngIf="!slotsResults && !loading" class="load-spinner text-center">
  {{'Please, select date to check slots availability' | translate}}.
</div>