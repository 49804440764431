import { Pipe, PipeTransform } from '@angular/core';
import { Permissions } from '@enums/permissions';
import { AuthenticationService } from 'src/app/authentication.service';

@Pipe({
  name: 'checkPermissionsPipe',
  pure: true
})
export class CheckPermissionsPipe implements PipeTransform {

  constructor(
    private readonly authService: AuthenticationService
  ) { }

  transform(permissions: Permissions[]): any {
    if (this.authService.checkPrivileges(permissions)) {
      return true;
    }
    return false;
  }
}
