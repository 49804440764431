<layout-default>
        <div header>
            <div toolbar>
                <h4>
                    <i-feather name="settings"></i-feather> {{'Planning sequence' | translate}}
                </h4>
            </div>
           
        </div>
    
        <div content>
            <div *ngIf="(async$ | async) as data" class="col-8 offset-2 pd-t-40" >
                <form *ngIf="!loader"  [formGroup]="form">
                    <div class="form-group row d-flex align-items-center">
                        <label class="col-3" for="id">{{ 'Id' | translate }}:</label>
                        <div class="col-9">
                            <input formControlName="id" class="form-control" />
                            <div *ngIf="form.get('id').hasError('required') && form.get('id').touched" class="error-color">{{ 'Id field is required' | translate }}!</div>
                        </div>
                    </div>
                    <div class="form-group row d-flex align-items-center">
                        <label class="col-3" for="name">{{ 'Name' | translate }}:</label>
                        <div class="col-9">
                            <input formControlName="name" class="form-control" />
                            <div *ngIf="form.get('name').hasError('required') && form.get('name').touched" class="error-color">{{ 'Name field is required' | translate }}!</div>
                        </div>
                    </div>
                    
                    <ng-container formArrayName="steps">
                        <div 
                            *ngFor="let step of form.controls.steps.controls; let i=index"
                            class="draggable-item-container step" dnd-draggable 
                            [dragEnabled]="true" [dropZones]="['zone']" [dragData]="i">
                                
                            <div
                                class="droppable" dnd-droppable [dropZones]="['zone']" 
                                (onDropSuccess)="reorderStep($event, i)"></div>


                                <hr />
                               
                                <ng-container formGroupName="{{i}}">

                                    <foldable-container class="foldable" [toggleIcon]="true" [showFoldedTitle]="true" [isFolded]="true">
                                        <div class="title">
                                            <div class="tx-bold mg-t-20 mg-b-20 text-center">{{'STEP' | translate}} {{i + 1}} </div>
                                      

                                       
                                    <!-- <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="id">{{ 'id' | translate }}:</label>
                                        <div class="col-8">
                                            <input formControlName="id" class="form-control" />
                                            <div *ngIf="steps.controls[i].get('id')?.hasError('required') && steps.controls[i].get('id')?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="clazz">{{ 'clazz' | translate }}:</label>
                                        <div class="col-8">
                                            <select id="clazz" (change)="clazzChanged($event.target.value, i)" class="custom-select" formControlName="clazz">
                                                <option *ngFor="let option of plannerSuported" [value]="option.clazz">{{ option.clazz }}</option>
                                            </select>
                                            <div *ngIf="steps.controls[i].get('clazz')?.hasError('required') && steps.controls[i].get('clazz')?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <div class="content">

                                    <!-- <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="order">{{ 'order' | translate }}:</label>
                                        <div class="col-9">
                                            <input formControlName="order" class="form-control" />
                                        </div>
                                    </div> -->
                                    
                                    <ng-container *ngIf="(plannerSuported | findValidatorPipe:steps.controls[i].get('clazz').value) as validators">
                                        <div *ngFor="let control of validators.parameters" class="form-group row">
                                            <label class="col-3" [for]="control.name">
                                                <span>{{ control.name | translate  }}:</span>
                                                <sup *ngIf="control.description as tooltip" [containerClass]="'pretty-tooltip'"
                                                [tooltip]="tooltip">&nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                                                </sup>
                                            </label>
                                            
                                            <div class="col-9">

                                                <ng-container [ngSwitch]="control.type">
                        
                                                    <!-- INTEGER:Control -->
                                                    <ng-container *ngSwitchCase="'INTEGER'">
                                                        <input type="number" [formControlName]="control.name" class="form-control" />
                                                    </ng-container>

                                                        <!-- DOUBLE:Control -->
                                                        <ng-container *ngSwitchCase="'DOUBLE'">
                                                        <input type="number" [formControlName]="control.name" class="form-control" />
                                                    </ng-container>

                                                    <!-- BOOLEAN:Control -->
                                                    <ng-container *ngSwitchCase="'BOOLEAN'">
                                                        <select class="custom-select" [id]="control" [formControlName]="control.name">
                                                        <option [ngValue]="true">{{ 'True' | translate }}</option>
                                                        <option [ngValue]="false">{{ 'False' | translate }}</option>
                                                        </select>
                                                    </ng-container>

                                                     <!-- ENUM:Control -->
                                                     <ng-container *ngSwitchCase="'ENUM'">
                                                         <select class="custom-select" [id]="control" [formControlName]="control.name">
                                                             <option *ngFor="let value of control.values; let i = index" [value]="value">{{ value | translate }}</option>
                                                         </select>
                                                     </ng-container>

                                                    <ng-container *ngSwitchDefault>
                                                        <ng-container [ngSwitch]="control.name">

                                                            <ng-container *ngSwitchDefault>
                                                                <input type="string" [formControlName]="control.name" class="form-control" />
                                                            </ng-container>

                                                        </ng-container>

                                                    </ng-container>

                                                    <div *ngIf="steps.controls[i].get(control.name)?.hasError('required') && steps.controls[i].get(control.name)?.touched" class="error-color">
                                                        {{ "The field is required" | translate }}!
                                                    </div>

                                                </ng-container>

                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="isSolver">{{ 'isSolver' | translate }}:</label>
                                        <div class="col-9">
                                            <select id="isSolver" class="custom-select" formControlName="isSolver" >
                                                <option value="true">{{ 'True' | translate }}</option>
                                                <option value="false">{{ 'False' | translate }}</option>
                                            </select>
                                            <div *ngIf="steps.controls[i].get('isSolver')?.hasError('required') && steps.controls[i].get('isSolver')?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="isFinaliser">{{ 'isFinaliser' | translate }}:</label>
                                        <div class="col-9">
                                            <select id="isFinaliser" class="custom-select" formControlName="isFinaliser" >
                                                <option value="true">{{ 'True' | translate }}</option>
                                                <option value="false">{{ 'False' | translate }}</option>
                                            </select>
                                            <div *ngIf="steps.controls[i].get('isFinaliser')?.hasError('required') && steps.controls[i].get('isFinaliser')?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="forward">{{ 'forward' | translate }}:</label>
                                        <div class="col-9">
                                            <select id="forward" class="custom-select" formControlName="forward" >
                                                <option value="true">{{ 'True' | translate }}</option>
                                                <option value="false">{{ 'False' | translate }}</option>
                                            </select>
                                            <div *ngIf="steps.controls[i].get('forward')?.hasError('required') && steps.controls[i].get('forward')?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row d-flex align-items-center">
                                        <label class="col-3" for="backward">{{ 'backward' | translate }}:</label>
                                        <div class="col-9">
                                            <select id="backward" class="custom-select" formControlName="backward" >
                                                <option value="true">{{ 'True' | translate }}</option>
                                                <option value="false">{{ 'False' | translate }}</option>
                                            </select>
                                            <div *ngIf="steps.controls[i].get('backward')?.hasError('required') && steps.controls[i].get('backward')?.touched" class="error-color">
                                                {{ "The field is required" | translate }}!
                                            </div>
                                        </div>
                                       
                                    </div>

                                  
                                </div>
                                <div class="buttons">

                                
                                    <div class="d-flex align-items-end flex-column bd-highlight mb-3">
                                        <button (click)="removeStep(i)" class="btn btn-xs d-inline btn-warning pd-t-2 pd-b-2"><i-feather [style.height.px]="16" name="trash-2"></i-feather></button>
                                    </div>
                                </div>
                                </foldable-container>
                            </ng-container>

                            <div *ngIf="i == form.controls.steps.controls.length - 1"
                                class="droppable" dnd-droppable [dropZones]="['zone']" 
                                (onDropSuccess)="reorderStep($event, i)"></div>

                        </div>
                    </ng-container>

                    <div class="form-group row justify-content-end mg-t-20">
                        <div class="col-3">
                            <button (click)="addStep()" class="btn btn-sm btn-uppercase btn-info w-100">{{'Add step' | translate}}</button>
                        </div>
                        <div class="col-3">
                            <button (click)="onSubmit()" type="submit" class="btn btn-sm btn-uppercase btn-brand-01 w-100">
                                <span *ngIf="!submitted">{{ 'Save' | translate }}</span>
                                <ng-container *ngIf="submitted">
                                    <div class="load-spinner text-center">
                                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                    </div>
                                </ng-container>
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </layout-default>
