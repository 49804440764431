import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { QueryParams } from '@interfaces/query-params.interface';
import { Collection } from '@interfaces/collection.interface';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { objectToString } from '@shared/functions/object-to-string.function';
import { environment } from '@environment';
import { UtilsService } from '@services/utils.service';
import { SalaryReport } from '../interfaces/salary-report';
import { CrudService } from '@services/crud.service';
import { SalaryReportFiles } from '../interfaces/salary-report-files';

@Injectable({
  providedIn: 'root'
})
export class SalaryReportService extends CrudService<SalaryReport, number> {

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/salary/v1/report`;

  constructor(
    protected readonly http: HttpClient,
    protected readonly utilsService: UtilsService
  ) {
    super(http, SalaryReportService.BASE_API, utilsService);
  }

  public assignDriversToSalaryReport(reportId: string, driversIdSet: string[]): Observable<SalaryReport> {
    return this.http.post<SalaryReport>(SalaryReportService.BASE_API + `/${reportId}/assign-driver`, driversIdSet);
  }

  public unassignDriversToSalaryReport(reportId: string, driversIdSet: string[]): Observable<SalaryReport> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: driversIdSet
   };
    return this.http.delete<SalaryReport>(SalaryReportService.BASE_API + `/${reportId}/unassign-driver`, httpOptions);
  }

  public genereteSalaryReport(id: string): Observable<SalaryReport> {
    const endpoint = SalaryReportService.BASE_API + `/${id}/generate`;
    return this.http.post<SalaryReport>(endpoint, this.utilsService.httpHeaders());
  }

  public createNewRevision(id: string): Observable<SalaryReport> {
    const endpoint = SalaryReportService.BASE_API + `/revision`;
    return this.http.post<SalaryReport>(endpoint, id, this.utilsService.httpHeaders());
  }

  public findSalaryReportFies(id: string): Observable<SalaryReportFiles[]> {
    const endpoint = SalaryReportService.BASE_API + `/${id}/files`;
    return this.http.get<SalaryReportFiles[]>(endpoint, this.utilsService.httpHeaders());
  }

  public fetchReportFile(id: string, key: string): Observable<any> {
    const endpoint = SalaryReportService.BASE_API + `/${id}/files/${key}`;
   // return this.http.get<any>(endpoint, this.utilsService.httpHeaders());

        
    const HTTPOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/pdf',
            'Accept': 'application/pdf'
        }),
        'responseType': 'blob' as 'json'
    }

    return this.http.get<any>(endpoint, HTTPOptions).pipe(
        map((signature: any) => signature)
    );
}

  }

