import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findById',
  pure: true
})
export class FindByIdPipe implements PipeTransform {
  transform(list: Object[], name: string): any {
    return list.find(o => o['id'] === name);
  }
}
