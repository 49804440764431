
        <div class="overlay">
            <div class="loader"></div>
        </div>
        <ng-container>
            <ng-content select=".title"></ng-content>
        </ng-container>

        <ng-container>
            <ng-content select=".form"></ng-content>
        </ng-container>

        <div class="actions">
            <button class="btn btn-link btn-xs" (click)="cancelEmitter.next()">{{'Cancel' | translate}}</button>
            <button class="btn btn-brand-01 btn-xs" [disabled]="!valid" (click)="saveEmitter.next()">{{'Save' | translate}}</button>
        </div>
    