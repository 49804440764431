import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { PageableResponse } from '@entities/pagable-response';
import { ORDER, SearchHardwareByOptions } from '@enums/enum';

import { HardwareUsages } from '../entities/hardware-usages';
import { HardwareService } from '../services/hardware.service';

@Component({
    selector: 'hardware-usages',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h4><i-feather name="smartphone"></i-feather> {{ 'Hardware usages' | translate }}</h4>
                </div>

                <div toolbar>
                    <search-hardware *ngFor="let option of searchHardwareBy" [mode]="option" [pageInfo]="pageInfo"></search-hardware>
                </div>
            </div>
            <div content class="content bg-white">
                <div *ngIf="!loading && hardwareUsages">
                    <ngx-datatable
                        id="hardware-table"
                        class="bootstrap"
                        [rows]="hardwareUsages"
                        [columns]="columns"
                        [columnMode]="'flex'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [messages]="tableMessages"
                        [rowHeight]="'auto'"
                        [externalPaging]="true"
                        [count]="pagable.totalElements"
                        [offset]="page"
                        [limit]="size"
                        [scrollbarH]="scrollBarHorizontal"
                        (page)="setPage($event)"
                    >
                        <ng-container *ngFor="let col of columns">
                            <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                                <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                    <b>{{ col.name | translate }}</b>
                                </ng-template>
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="isHardwareTypeColumn(col.prop)">{{ value | translate }}</span>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="isColumnShift(col.prop)">{{ isColumnShiftValue(row) }}</span>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="isTimeColumn(col.prop)">{{ formatDate(value) }}</span>
                                    <span id="hardware-table-col-{{col.id}}" *ngIf="!isHardwareTypeColumn(col.prop) && !isTimeColumn(col.prop) && !isColumnShift(col.prop)">{{ value }}</span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                    </ngx-datatable>
                </div>

                <div *ngIf="loading" class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}</div>
            </div>
        </layout-default>
    `,
    styleUrls: ['hardware-usages.component.scss']
})
export class HardwareUsagesComponent implements OnInit {
    public loading: boolean = false;

    protected page: number = 0;
    protected size = 20;
    protected order: ORDER = ORDER.DESC;
    protected sortBy: string = 'createdAt';
    protected pagable: PageableResponse<HardwareUsages> = new PageableResponse<HardwareUsages>(HardwareUsages);
    public pageInfo;

    public tableMessages = {
        emptyMessage: this.translateService.instant('No data to display')
    };

    get searchHardwareBy() {
        return SearchHardwareByOptions;
    }

    public hardwareUsages: HardwareUsages[] = [];

    public columns = [
        { id: 'label', prop: 'driver.label', name: this.translateService.instant('Driver'), flexGrow: 3 },
        { id: 'date', prop: 'date', name: this.translateService.instant('Date'), flexGrow: 2 },
        { id: 'shift', prop: 'shift', name: this.translateService.instant('Shift'), flexGrow: 2 },
        { id: 'inventoryNo', prop: 'hardware.inventoryNo', name: this.translateService.instant('Inventory no'), flexGrow: 3 },
        { id: 'hardwareType', prop: 'hardware.hardwareType', name: this.translateService.instant('Hardware type'), flexGrow: 3 },
        { id: 'brand', prop: 'hardware.brand', name: this.translateService.instant('Brand'), flexGrow: 2 },
        { id: 'deviceNo', prop: 'hardware.deviceNo', name: this.translateService.instant('Device no'), flexGrow: 3 },
        { id: 'receivedAt', prop: 'receivedAt', name: this.translateService.instant('Received at'), flexGrow: 2 }
    ];

    constructor(private translateService: TranslateService, private hardwareService: HardwareService) {}

    public ngOnInit() {
        this.hardwareService.hardwareUsage.subscribe((hardware: PageableResponse<HardwareUsages> | null) => {
            this.loading = true;
            if (hardware !== null) {
                this.pagable = hardware;
                this.hardwareUsages = this.pagable.content;
            } else {
                this.hardwareUsages = [];
            }
            setTimeout(() => {
                this.loading = false;
            }, 400);
        });
    }

    public setPage(pageInfo?) {
        this.page = pageInfo.offset;
        this.pageInfo = pageInfo;
    }

    public isColumnShiftValue(row: HardwareUsages) {
        return `${this.translateService.instant(row.shift)}, ${row.routeNumber}`;
    }

    public isColumnShift(status: string): boolean {
        return status === 'shift';
    }

    public isHardwareTypeColumn(status: string): boolean {
        return status === 'hardware.hardwareType';
    }

    public isTimeColumn(status: string): boolean {
        return status === 'receivedAt' || status === 'returnedAt';
    }

    public formatDate(date: string) {
        if (!date) {
            return '';
        }
        return moment(date).format('DD.MM.YYYY, HH:mm');
    }
}
