import * as _ from 'lodash';
import * as moment from 'moment';

import { Driver } from '../../drivers/entities/driver';
import { Entity } from '../../shared/entities/Entity';
import { Serializable } from '../../shared/entities/Serializable';
import { PunctualityEnum } from '../enums/punctuality.enum';
import { ChartModel } from '../interfaces/chart.interface';

export class Stats extends Entity implements Serializable<Stats> {
    public deliveredEarly: number;
    public deliveredLate: number;
    public deliveredOk: number;
    public deliveredTotal: number;
    public startDay: number;
    public startMonth: number;
    public startWeek: number;
    public startYear: number;
    public averageEarly: number;
    public averageLate: number;
    public startShift: string;

    public statusArchive: number;
    public statusAssigned: number;
    public statusCutoff: number;
    public statusDone: number;
    public statusFailure: number;
    public statusFulfilled: number;
    public statusInProgress: number;
    public statusNew: number;
    public statusOrdered: number;
    public statusPending: number;
    public statusReserved: number;
    public statusRetry: number;

    public deserialize(data: any) {
        this.deliveredEarly = _.get(data, 'deliveredEarly', 0);
        this.deliveredLate = _.get(data, 'deliveredLate', 0);
        this.deliveredOk = _.get(data, 'deliveredOk', 0);
        this.deliveredTotal = _.get(data, 'deliveredTotal', 0);
        this.startDay = _.get(data, 'startDay', 0);
        this.startMonth = _.get(data, 'startMonth', 0);
        this.startWeek = _.get(data, 'startWeek', 0);
        this.startYear = _.get(data, 'startYear', 0);
        this.averageEarly = _.get(data, 'averageEarly', 0);
        this.averageLate = _.get(data, 'averageLate', 0);
        this.startShift = _.get(data, 'startShift');

        this.statusArchive = _.get(data, 'statusArchive', 0);
        this.statusAssigned = _.get(data, 'statusAssigned', 0);
        this.statusCutoff = _.get(data, 'statusCutoff', 0);
        this.statusDone = _.get(data, 'statusDone', 0);
        this.statusFailure = _.get(data, 'statusFailure', 0);
        this.statusFulfilled = _.get(data, 'statusFulfilled', 0);
        this.statusInProgress = _.get(data, 'statusInProgress', 0);
        this.statusNew = _.get(data, 'statusNew', 0);
        this.statusOrdered = _.get(data, 'statusOrdered', 0);
        this.statusPending = _.get(data, 'statusPending', 0);
        this.statusReserved = _.get(data, 'statusReserved', 0);
        this.statusRetry = _.get(data, 'statusRetry', 0);

        return this;
    }

    public serialize() {
        const serialized = {
            deliveredEarly: this.deliveredEarly,
            deliveredLate: this.deliveredLate,
            deliveredOk: this.deliveredOk,
            deliveredTotal: this.deliveredTotal,
            startDay: this.startDay,
            startMonth: this.startMonth,
            startWeek: this.startWeek,
            startYear: this.startYear,
            averageEarly: this.averageEarly,
            averageLate: this.averageLate,
            startShift: this.startShift
        };

        return serialized;
    }

    public generateChartDataForDrivers(stats: Stats) {
        let series;

        series = [
            {
                name: PunctualityEnum.ON_TIME,
                value: stats.deliveredOk,
                percent: ((stats.deliveredOk * 100) / stats.deliveredTotal).toFixed(2),
                averageEarly: stats.averageEarly,
                averageLate: stats.averageLate,
                totalDeliveries: stats.deliveredEarly + stats.deliveredOk + stats.deliveredLate
            },
            {
                name: PunctualityEnum.EARLY,
                value: stats.deliveredEarly,
                percent: ((stats.deliveredEarly * 100) / stats.deliveredTotal).toFixed(2),
                averageEarly: stats.averageEarly,
                averageLate: stats.averageLate,
                totalDeliveries: stats.deliveredEarly + stats.deliveredOk + stats.deliveredLate
            },
            {
                name: PunctualityEnum.LATE,
                value: stats.deliveredLate,
                percent: ((stats.deliveredLate * 100) / stats.deliveredTotal).toFixed(2),
                averageEarly: stats.averageEarly,
                averageLate: stats.averageLate,
                totalDeliveries: stats.deliveredEarly + stats.deliveredOk + stats.deliveredLate
            }
        ];

        return series;
    }

    public generateChartDataForDelivries(stats: Stats, dateRange?: string) {
        const row: ChartModel = { name: '', series: [] };

        const date: string = `${stats.startYear}-${stats.startMonth}-${stats.startDay}`;

        row.name = dateRange ? dateRange : moment(date).format('dddd, DD MMMM');
        row.date = moment(date).format('dddd, DD MMMM');

        if (row.name === 'Invalid date') {
            row.name = '';
        }

        if (stats.startShift) {
            row.name = _.get(stats, 'startShift');
        }

        row.stats = stats;

        row.series = [
            {
                name: PunctualityEnum.ON_TIME,
                value: stats.deliveredOk,
                percent: ((stats.deliveredOk * 100) / stats.deliveredTotal).toFixed(2),
                date: moment(date).format('YYYY-MM-DD'),
                averageEarly: _.get(stats, 'averageEarly'),
                averageLate: _.get(stats, 'averageLate'),
                totalDeliveries: stats.deliveredEarly + stats.deliveredOk + stats.deliveredLate
            },
            {
                name: PunctualityEnum.EARLY,
                value: stats.deliveredEarly,
                percent: ((stats.deliveredEarly * 100) / stats.deliveredTotal).toFixed(2),
                date: moment(date).format('YYYY-MM-DD'),
                averageEarly: _.get(stats, 'averageEarly'),
                averageLate: _.get(stats, 'averageLate'),
                totalDeliveries: stats.deliveredEarly + stats.deliveredOk + stats.deliveredLate
            },
            {
                name: PunctualityEnum.LATE,
                value: stats.deliveredLate,
                percent: ((stats.deliveredLate * 100) / stats.deliveredTotal).toFixed(2),
                date: moment(date).format('YYYY-MM-DD'),
                averageEarly: _.get(stats, 'averageEarly'),
                averageLate: _.get(stats, 'averageLate'),
                totalDeliveries: stats.deliveredEarly + stats.deliveredOk + stats.deliveredLate
            }
        ];

        return row;
    }
}
