
        <ng-datepicker class="datepicker-input" *ngIf="!pickerLoader" (ngModelChange)="changeDate($event)" [ngModel]="model.date" [options]="datePickerOptions" datepicker></ng-datepicker>

        <button
            *ngFor="let shiftConfig of (shiftsTypes$ | async)"
            (click)="change(shiftConfig.type)"
            class="btn btn-sm btn-uppercase"
            [ngClass]="{ 'btn-brand-01 btn-type-shift': activeBtn(shiftConfig.type), 'btn-white': !activeBtn(shiftConfig.type) }"
        >
            {{ shiftConfig.type | translate }}
        </button>
    