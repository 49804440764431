<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="award"></i-feather> {{'Roles' | translate}}</h4>
        </div>

        <div toolbar>
            <a id="roles-btn-create" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-default" routerLink="/users/roles/new/create"><i-feather name="plus"> </i-feather>{{'Add new role' | translate}}</a>
            <div id="roles-btn-search" class="filter-container">
                <div class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}" (keyup)='applyFiltering($event)'/>
                </div>
            </div>
        </div>
        <hr>
    </div>

    <div content>
        <div *ngIf="!loader && roles.length">
            <ngx-datatable 
                id="roles-table"
                class="bootstrap"
                [rows]="roles"
                [columns]="columns"
                [columnMode]="'flex'"
                [headerHeight]="50"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [limit]="15"
                [rowClass]="rowClass"
                (activate)="goToDetails($event)">

                <ng-container *ngFor="let col of columns">
                    <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                            <b [ngClass]="{'d-block text-center': col.prop === 'options'}">{{ col.name | translate }}</b>
                        </ng-template>
                        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                            <span id="roles-table-col-{{col.prop}}" *ngIf="isColumnCreatedAt(col.prop)">{{value | amLocale: locale | amDateFormat:'D MMMM YYYY' }}</span>
                            <span id="roles-table-col-{{col.prop}}" *ngIf="isColumnId(col.prop)">{{ rowIndex + 1 }}.</span>
                            <div *ngIf="isColumnOptions(col.prop)">
                                <div class="dropdown" dropdown>
                                    <button class="btn btn-outline-secondary btn-xs dropdown-toggle tx-uppercase" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{'Select' | translate}}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right div-main-actions" aria-labelledby="dropdownMenuButton">
                                        <a id="roles-btn-btn-edit" class="btn btn-outline-secondary btn-xs btn-uppercase dropdown-item" [routerLink]="'/users/roles/' + row.id + '/edit'">
                                            {{'Edit' | translate}}
                                        </a>
                                        <a id="roles-btn-delete" class="btn btn-outline-secondary btn-xs btn-uppercase dropdown-item" (click)="delete(row)">
                                            {{'Delete' | translate}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <span id="roles-table-col-{{col.prop}}" *ngIf="isColumnRoles(col.prop)">
                                <ul class="roles">
                                    <li *ngFor="let role of value">{{'Role_permissions.'+role | translate}}</li>
                                </ul>
                            </span>
                            <span id="roles-table-col-{{col.prop}}" *ngIf="!isColumnCreatedAt(col.prop) && !isColumnOptions(col.prop) && !isColumnRoles(col.prop) && !isColumnId(col.prop)">{{ value }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                </ng-container>
            </ngx-datatable>
        </div>
    </div>

    <div *ngIf="loader" class="load-spinner spinner">
        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
        <p class="spinner__info">{{'Please, wait!' | translate}}</p>
    </div>
</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the role' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>
