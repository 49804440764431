import { Pipe, PipeTransform } from '@angular/core';
import { secToHours } from '@shared/functions/secToHours.function';

@Pipe({
    name: 'secToHoursPipe',
    pure: true
})
export class SecToHoursPipe implements PipeTransform {
    public transform(sec: number): string {
        return secToHours(sec);
    }
}
