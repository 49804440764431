import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HardwareService } from '@hardware/services/hardware.service';
import { Van } from '@hardware/entities/van';

@Injectable()
export class VansAllResolver implements Resolve<Van[]> {
    constructor(private hardwareService: HardwareService) {}
    public resolve() {
        return this.hardwareService.getAllVans();
    }
}
