
import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { Client } from '@enums/enum';
import { environment } from '@environment';

@Directive({
    selector: '[appCheckClient]'
})

export class CheckClientDirective implements OnInit {
    @Input() public client: Client;

    constructor(private elementRef: ElementRef) {}

    public ngOnInit() {
        if (environment.client !== this.client) {
            this.elementRef.nativeElement.remove();
        }
    }
}
