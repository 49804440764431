import { Type } from 'class-transformer';

import { serializeType } from '@shared/functions/serializeType.function';

import { DatasetConfig } from './dataset-config';
import { DatasetQueue } from './dataset-queue';

export class Dataset {

    @Type(serializeType(DatasetConfig))
    public config: DatasetConfig;
    public id: number;
    public name: string;

    @Type(serializeType(DatasetQueue))
    public queue: DatasetQueue[];
}
