import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { DepotVirtual } from '@interfaces/depot-virtual.interface';

@Injectable({
  providedIn: 'root'
})
export class DepotVirtualService extends CrudService<DepotVirtual, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/depot/v1/virtual`;  
  
  constructor(
    protected _http: HttpClient, 
    protected utilsService: UtilsService) 
    {
      super(_http, DepotVirtualService.BASE_API, utilsService);
    }
}
