<layout-default *ngIf="mode == 'list'">
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="truck"></i-feather> {{ 'Vans' | translate }}
            </h4>
        </div>

        <div toolbar>
            <button id="hardware-btn-create-fleet" class="btn btn-sm btn-uppercase btn-brand-01" [routerLink]="['/fleet/create']">{{ 'Add new van' | translate }}</button>
            <button id="hardware-btn-vehicle-types-list" class="btn btn-sm btn-uppercase btn-brand-01" [routerLink]="['/fleet/vehicle-types/list']">{{ 'Vehicle types' | translate }}</button>
            <button id="hardware-btn-create-hardware" class="btn btn-sm btn-uppercase btn-brand-01" [routerLink]="['/fleet/create-hardware']">{{ 'Add new hardware' | translate }}</button>
            <button id="hardware-btn-go-to-hardware-usages" class="btn btn-sm btn-uppercase btn-brand-01" (click)="goToHardwareUsages()">{{ 'Hardware usages' | translate }}</button>

            <div id="hardware-btn-select-hardware-type" class="filter-select">
                <select class="custom-select" (change)="applyFiltering()" [(ngModel)]="hardwareType">
                    <option value="ALL">{{ 'All' | translate }}</option>


                    <option *ngFor="let type of hardwareTypeOptions" [value]="type">{{ type | translate }}</option>
                    <option value="VAN">{{ 'VAN' | translate }}</option>
                </select>
            </div>

            <div id="hardware-btn-select-hardware-status" class="filter-select">
                <select class="custom-select" (change)="applyFiltering()" [(ngModel)]="hardwareStatus">
                    <option *ngFor="let status of hardwareStatuses" [value]="status">{{ status | translate }}</option>
                </select>
            </div>

            <div id="hardware-btn-search" class="filter-container" *ngIf="mode == 'list'">
                <div class="phrase-filter">
                    <input type="text" class="list-filter form-control" placeholder="{{ 'type to search' | translate }}..." (keyup)="applyFiltering($event)"/>
                </div>
            </div>
        </div>

        <hr>
    </div>
    <div content>
        <ngx-datatable class="bootstrap"
            id="hardware-table"
            *ngIf="!loader"
            [rows]="rowsDuplicated"
            [columns]="columns"
            (activate)="goToVanDetails($event)"
            [columnMode]="'flex'"
            [headerHeight]="50"
            [messages]="tableMessages"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="30"
            [reorderable]="true"
            [rowClass]="rowClass"
        >
            <ng-container *ngFor="let col of columns">
                <ngx-datatable-column [name]="col.name" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable" [resizeable]="false">
                    <b>{{ col.name | translate }}</b>

                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <span id="hardware-table-col-{{col.prop}}" *ngIf="isNum(col.prop)">{{ order(row) }}.</span>
                        <span id="hardware-table-col-{{col.prop}}" *ngIf="isColumnRegistration(col.prop)">{{ columnRegistrationValue(row) }}</span>
                        <span id="hardware-table-col-{{col.prop}}" *ngIf="isColumnBodyType(col.prop)">{{ columnBodyTypeValue(row) | translate }}</span>
                        <span id="hardware-table-col-{{col.prop}}" *ngIf="isColumnToTranslate(col.prop)">{{ value | translate }}</span>
                        <span id="hardware-table-col-{{col.prop}}" *ngIf="!isColumnBodyType(col.prop) && !isColumnRegistration(col.prop) && !isColumnToTranslate(col.prop) && !isNum(col.prop)">{{ value }}</span>
                    </ng-template>
                </ngx-datatable-column>
            </ng-container>
        </ngx-datatable>

        <div *ngIf="loader" class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}</div>
    </div>
</layout-default>

<ng-container *ngIf="mode != 'list'">
    <edit-van [vanId]="selectedVanId" [mode]="mode"></edit-van>
</ng-container>
