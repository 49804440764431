
        <ng-template #customItemTemplate let-model="item" let-index="index"></ng-template>

        <div class="filter-container input-group delivery-typeahead search-by-hardware-id">
            <ng-container *ngIf="!isDateMode">
                <div class="input-group-prepend">
                    <span class="input-group-text typeahead-loader" id="basic-addon1">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true" [class.notvisible]="!typeaheadLoading"></i>
                        <i class="fa fa-search" aria-hidden="true" [class.notvisible]="typeaheadLoading"></i>
                    </span>
                </div>
                <input
                    id="search-hardware-searchbar"
                    [(ngModel)]="asyncSelected"
                    [typeahead]="dataSource"
                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                    [typeaheadOptionsLimit]="7"
                    [typeaheadItemTemplate]="customItemTemplate"
                    typeaheadOptionField="customerLabel"
                    typeaheadWaitMs="1000"
                    [typeaheadMinLength]="1"
                    [placeholder]="searchLabel"
                    class="form-control tx-uppercase"
                    aria-label="Wyszukaj dostawę"
                    aria-describedby="basic-addon1"
                />
            </ng-container>

            <ng-container *ngIf="isDateMode">
                <div  class="input-group-prepend">
                    <span class="input-group-text typeahead-loader" id="basic-addon1">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true" [class.notvisible]="!typeaheadLoading"></i>
                        <i class="fa fa-calendar" aria-hidden="true" [class.notvisible]="typeaheadLoading"></i>
                    </span>
                </div>
                <ng-datepicker id="search-hardware-searcby-date" class="datepicker-input" [(ngModel)]="asyncSelected" (ngModelChange)="onChangeDate($event)" [options]="datePickerOptions"></ng-datepicker>
            </ng-container>
        </div>
    