
import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AppLang } from '@enums/enum';
import * as moment from 'moment';

@Component({
  selector: 'app-frisco-root',
  template: ` <router-outlet></router-outlet>`

})
export class FriscoAppComponent {
  constructor(private translateService: TranslateService) {

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
      localStorage.setItem('lang', event.lang);
      this.translateService.setDefaultLang(event.lang);
      this.translateService.use(event.lang);
    });

    const lang = localStorage.getItem('lang') || AppLang.PL;
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }
}
