import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocationEditComponent } from './components/location-edit.component';
import { LocationsComponent } from './components/locations.component';
import { ProxyComponent } from '@shared/components/proxy.component';
import { DepotComponent } from './components/depot/depot.component';
import { DepotEditComponent } from './components/depot-edit/depot-edit.component';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';
import { DepotMapComponent } from './components/depot-map/depot-map.component';
import { RouterOutletComponent } from '@shared/components/router-outlet.component';
import { DepotAssignUsersComponent } from './components/depot-assign-users/depot-assign-users.component';
import { DepotResolver } from './services/depot.resolver';
import { UsersResolver } from '../users/services/users.resolver';
import { DepotAssignedUsersResolver } from './services/depot-assigned-users.resolver';
import { DepotAssignDriversComponent } from './components/depot-assign-drivers/depot-assign-drivers.component';
import { DepotAssignedUDriversResolver } from './services/depot-assigned-drivers.resolver';
import { DriversAllResolver } from '@drivers/resolvers/drivers-all.resolver';
import { DepotAssignVansComponent } from './components/depot-assign-vans/depot-assign-vans.component';
import { DepotAssignedVansResolver } from './services/depot-assigned-vans.resolver';
import { VansAllResolver } from '@hardware/resolvers/vans-all.resolver';
import { DepotAssignHardwareComponent } from './components/depot-assign-hardware/depot-assign-hardware.component';
import { DepotAssignedHardwareResolver } from './services/depot-assigned-hardware.resolver';
import { HardwareAllResolver } from '@hardware/resolvers/hardware-all.resolver';
import { DepotVirtualListComponent } from './components/depot-virtual-list/depot-virtual-list.component';
import { DepotVirtualEditComponent } from './components/depot-virtual-edit/depot-virtual-edit.component';
import { ProfanityFilterComponent } from './components/profanity-filter/profanity-filter.component';
import { ProfanityFilterEditComponent } from './components/profanity-filter-edit/profanity-filter-edit.component';
import { ProfanityFilterResolver } from './resolvers/profanity-filter.resolver';
import { CommentListComponent } from './components/comment-list/comment-list.component';

const locationsRoutes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        children: [
            {
                path: '', component: LocationsComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Location_view],
                    breadcrumb: 'Breadcrumbs.Locations'
                }
            },
            {
                path: 'profanity-filter', 
                component: RouterOutletComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.ProfanityFilterList'
                },
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: ProfanityFilterComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Location_view, P.Permissions.Location_edit],
                            breadcrumb: 'Breadcrumbs.ProfanityFilterList'
                        }
                    },
                    {
                        path: 'create',
                        component: ProfanityFilterEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Location_view, P.Permissions.Location_edit],
                            breadcrumb: 'Breadcrumbs.ProfanityFilterCreate'
                        }
                    },
                    {
                        path: 'edit/:id',
                        component: ProfanityFilterEditComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            vehicleType: ProfanityFilterResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Location_view, P.Permissions.Location_edit],
                            breadcrumb: 'Breadcrumbs.ProfanityFilterEdit'
                        }
                    },
                ]
            },
            {
                path: 'comments', 
                component: RouterOutletComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Hardware_view],
                    breadcrumb: 'Breadcrumbs.CommentList'
                },
                children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    {
                        path: 'list',
                        component: CommentListComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Location_view, P.Permissions.Location_edit],
                            breadcrumb: 'Breadcrumbs.CommentList'
                        }
                    }
                ]
            },
            {
                path: ':locationId/edit',
                component: LocationEditComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Location_view, P.Permissions.Location_edit],
                    breadcrumb: 'Breadcrumbs.EditLocation'
                }
            },
            {
                path: 'depots',
                component: RouterOutletComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Depot'
                },
                children: [
                    { path: '', pathMatch: 'full', redirectTo: 'list' },
                    {
                        path: 'list',
                        component: DepotComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.Depot'
                        }
                    },
                    {
                        path: 'map',
                        component: DepotMapComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.DepotMap'
                        }
                    },
                    {
                        path: 'create',
                        component: DepotEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.DepotCreate'
                        },
                    },
                    {
                        path: 'assigned-users/:depotId',
                        component: DepotAssignUsersComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.AssignUserToDepot'
                        },
                        resolve: {
                            depot: DepotResolver,
                            users: UsersResolver,
                            assignedUsers: DepotAssignedUsersResolver
                        }
                    },
                    {
                        path: 'assigned-drivers/:depotId',
                        component: DepotAssignDriversComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.AssignDriversToDepot'
                        },
                        resolve: {
                            depot: DepotResolver,
                            list: DriversAllResolver,
                            assignedEntities: DepotAssignedUDriversResolver
                        }
                    },
                    {
                        path: 'assigned-vans/:depotId',
                        component: DepotAssignVansComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.AssignVansToDepot'
                        },
                        resolve: {
                            depot: DepotResolver,
                            list: VansAllResolver,
                            assignedEntities: DepotAssignedVansResolver
                        }
                    },
                    {
                        path: 'assigned-hardware/:depotId',
                        component: DepotAssignHardwareComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.AssignHardwareToDepot'
                        },
                        resolve: {
                            depot: DepotResolver,
                            list: HardwareAllResolver,
                            assignedEntities: DepotAssignedHardwareResolver
                        }
                    },
                    {
                        path: 'virtual/:id/list',
                        component: DepotVirtualListComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.DepotVirtualList'
                        }
                    },
                    {
                        path: 'virtual/:depotId/create',
                        component: DepotVirtualEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.DepotVirtualCreate'
                        },
                        resolve: {
                            depot: DepotResolver
                        }
                    },
                    {
                        path: 'virtual/:depotId/edit/:virtualDepotId',
                        component: DepotVirtualEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.DepotVirtualEdit'
                        },
                        resolve: {
                            depot: DepotResolver
                        }
                    },
                    {
                        path: ':action/:depotId',
                        component: DepotEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Depot_edit],
                            breadcrumb: 'Breadcrumbs.DepotEdit'
                        },
                        resolve: {
                            depot: DepotResolver,
                        }
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(locationsRoutes)],
    exports: [RouterModule],
    providers: []
})
export class LocationsRouting { }
