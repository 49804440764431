import * as _ from 'lodash';

import { Serializable } from '@entities/Serializable';

/**
 * Representation of spring PagableResponse
 */
export class PageableResponse<T> implements Serializable<PageableResponse<T>> {
    public content: T[];
    public pageable: Pageable;
    public last: boolean = false;
    public totalPages: number = 0;
    public totalElements: number = 0;
    public first: true;
    public numberOfElements: number = 0;
    public size: number = 0;
    public number: number = 0;

    public contentItem: T;

    constructor(private T) {}

    public deserialize(data: any) {
        this.pageable = new Pageable().deserialize(_.get(data, 'pageable'));
        this.last = _.get(data, 'last');
        this.totalPages = _.get(data, 'totalPages', 0);
        this.totalElements = _.get(data, 'totalElements', 0);
        this.first = _.get(data, 'first');
        this.numberOfElements = _.get(data, 'numberOfElements');
        this.size = _.get(data, 'size');
        this.number = _.get(data, 'number');

        this.content = data.content.map(data => {
            const instance = new this.T();

            instance.deserialize(data);
            return instance;
        });

        return this;
    }

    public serialize() {
        return {};
    }
}

export class Pageable implements Serializable<Pageable> {
    public sort: {
        unsorted: boolean;
        sorted: boolean;
    } = {
        unsorted: false,
        sorted: false
    };
    public pageSize: 10;
    public pageNumber: 0;
    public offset: 0;
    public paged: true;
    public unpaged: false;

    public deserialize(data: any) {
        this.sort.unsorted = _.get(data, 'sort.unsorted');
        this.sort.sorted = _.get(data, 'sort.sorted');

        this.pageSize = _.get(data, 'pageSize');
        this.pageNumber = _.get(data, 'pageNumber');
        this.offset = _.get(data, 'offset');
        this.paged = _.get(data, 'paged');
        this.unpaged = _.get(data, 'unpaged');

        return this;
    }

    public serialize() {
        return {};
    }
}
