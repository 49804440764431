import { Pipe, PipeTransform } from '@angular/core';
import { LoginProvider } from '@interfaces/login-provider.interface';
import { LoginProviderType } from '@enums/enum';

@Pipe({
  name: 'findProviderIdpUrlPipe',
  pure: true
})
export class FindProviderIdpUrlPipe implements PipeTransform {
  public transform(providers: LoginProvider[], type: LoginProviderType): any {
    const provider = providers.find((p: LoginProvider) => p.type === type);
    return provider ? provider : undefined;
  }
}
