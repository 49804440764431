
export function secToHours(seconds: number) {
    let minutes: number = Math.floor(seconds / 60);
    let hours: number = 0;

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (hours) {
        return hours + 'h ' + minutes + 'm ' + seconds + 's';
    } else if (minutes) {
        return minutes + 'm ' + seconds + 's';
    } else {
        return seconds + 's';
    }
    
}
