<button (click)="back()" class="menu__button btn btn-sm btn-uppercase btn-success mg-10">
    <span>{{ "Back to solutions list" | translate }}</span>
</button>

<ng-container *ngIf="(solution$ | async) as solution">

    <ng-container *ngIf="(distances$ | async) as distances">
        
        <ng-container *ngFor="let routesPlanningResult of solution.routes">


            <app-visualiser-routes-planning-header 
                [shift]="shift" 
                [distances]="distances"
                [routesPlanningResult]="routesPlanningResult"></app-visualiser-routes-planning-header>

            <app-visualiser-routes-planning-bars 
                [shift]="shift"
                [distances]="distances"
                [routesPlanningResult]="routesPlanningResult"></app-visualiser-routes-planning-bars>


        </ng-container>

    </ng-container>
</ng-container>

<div *ngIf="loader" class="load-spinner spinner">
    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
    <p class="spinner__info">{{ 'Loading, solution' | translate }}: <strong>{{shift?.date}} {{shift?.type | translate}}</strong></p>
</div>