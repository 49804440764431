
        <layout-default>
            <div header>
                <div toolbar>
                    <h4><i-feather name="credit-card"></i-feather> {{'Payments' | translate}}</h4>
                </div>
                
                <div toolbar>
                    <div style="flex-grow: 2">
                        <shift-picker class="text-center" (changedShift)="changedDate($event)" [shift]="shiftId"></shift-picker>
                    </div>
                </div>
                <hr />
            </div>

            <div content>
                <payments-shift-list></payments-shift-list>
            </div>
        </layout-default>
    