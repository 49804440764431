import { Type } from 'class-transformer';

import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { ShiftType } from '@enums/enum';
import { DriverPlanningMode } from '@enums/enum';
import { serializeType } from '@shared/functions/serializeType.function';

import { Driver } from './driver';

export class DriverAvailability {
    public date: string;
    public driver: Driver;
    public driverId: string;
    public id: string;
    public createdAt: string;
    public modifiedAt: string;
    public shift: ShiftType;
    public version: string;
    public warehouse: string;
    public mode: DriverPlanningMode = DriverPlanningMode.REGULAR;
}

export class ShiftConfigWithStatuses {
    public statuses: object;

    @Type(serializeType(ShiftConfig)) public shift: ShiftConfig;
}

export interface ShiftSummaryList {
    date: string;
    shifts: string[];
}



export class AvailabilityRange {
    public range: {
        startDate: string;
        endDate: string;
    };

    @Type(serializeType(DriverAvailability)) public availabilityList: DriverAvailability[] = [];
    @Type(serializeType(Driver)) public drivers: Driver[] = [];
    public shiftSummaryList: ShiftSummaryList[] = [];
}
