<div class="shift-settings">
    <form [formGroup]="form">

        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'Shift settings' | translate}} - {{'That operation wont store your settings in database only will show new calculated routes' | translate}}.</label>

            <div class="d-flex justify-content-start flex-wrap">

                <div class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.key' | translate}}:&nbsp;</span>
                        <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('key')"
                            [style.width.px]="13" name="info"></i-feather>
                    </label>

                    <input type="text" formControlName="key" class="form-control w-100" />
                    <div *ngIf="form.get('key').hasError('pattern') && form.get('key').touched" class="error-color">{{ 'Proper format: [HH:mm:ss]' | translate }}</div>
                    <div *ngIf="form.get('key').hasError('required') && form.get('key').touched" class="error-color">{{ 'The field is required' | translate }}</div>

                </div>
                <ng-container formGroupName="fulfilment">

                    <div *ngFor="let item of fulfilmentTimesControl" class="item mg-r-10">
                        
                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                            <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <input type="text" [formControlName]="item" class="form-control w-100" />
                        <div *ngIf="form.get('fulfilment').get(item).hasError('pattern') && form.get('fulfilment').get(item).touched" class="error-color">{{ 'Proper format: [HH:mm:ss]' | translate }}</div>
                        <div *ngIf="form.get('fulfilment').get(item).hasError('required') && form.get('fulfilment').get(item).touched" class="error-color">{{ 'The field is required' | translate }}</div>
                    </div>

                    <div *ngFor="let item of fulfilmentControl" class="item mg-r-10">

                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                            <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <input type="number" [formControlName]="item" class="form-control w-100" />
                        <div *ngIf="form.get('fulfilment').get(item).hasError('pattern') && form.get('fulfilment').get(item).touched" class="error-color">{{ 'The field have to a numeric value' | translate }}</div>
                        <div *ngIf="form.get('fulfilment').get(item).hasError('required') && form.get('fulfilment').get(item).touched" class="error-color">{{ 'The field is required' | translate }}</div>
                    
                    </div>

                </ng-container>
            

        </div>
        
        <ng-container formGroupName="fulfilment">

            <ng-container formGroupName="deliveryTime">
                <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-r-10">{{'Delivery time informations' | translate}}:
                    <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('deliveryTime')">
                        &nbsp;<i-feather [style.width.px]="10" name="info"></i-feather>
                    </sup>
                </label>
                <div class="d-flex justify-content-start flex-wrap">
                    <div *ngFor="let item of deliveryTimeControls" class="item mg-r-10">

                        <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                            <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                            <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                                [style.width.px]="13" name="info"></i-feather>
                        </label>

                        <input type="number" [formControlName]="item" class="form-control w-100" />

                        <div *ngIf="form.get('fulfilment').get('deliveryTime').get(item).hasError('pattern') && form.get('fulfilment').get('deliveryTime').get(item).touched" class="error-color">
                            {{ "The field value is too long" | translate }}!
                        </div>

                        <div *ngIf="form.get('fulfilment').get('deliveryTime').get(item).hasError('required') && form.get('fulfilment').get('deliveryTime').get(item).touched" class="error-color">
                            {{ "The field is required" | translate }}!
                        </div>

                    </div>
                </div>

            </ng-container>

        </ng-container>

        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'Routing' | translate}}:</label>

        <ng-container formGroupName="routing">
            <div class="d-flex justify-content-start flex-wrap">
                <div *ngFor="let item of routingControls" class="item mg-r-10">

                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">
                        <span>{{'shiftProperty.'+item | translate}}:&nbsp;</span>
                        <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(item)"
                            [style.width.px]="13" name="info"></i-feather>
                    </label>

                    <div [ngSwitch]="item">
                        <div *ngSwitchCase="'planningSequence'">
                            <select [id]="item" class="custom-select" [formControlName]="item">
                                <option *ngFor="let planning of (planningSequence$ | async); let i = index" [value]="planning.id">{{ planning.name | translate }}</option>
                            </select>
                        </div>
                        <div *ngSwitchDefault>
                            <input type="number" [formControlName]="item" class="form-control w-100" />
                        </div>
                    </div>

                    <div *ngIf="form.get('routing').get(item).hasError('pattern') && form.get('routing').get(item).touched" class="error-color">{{ 'The field have to a numeric value' | translate }}</div>
                    <div *ngIf="form.get('routing').get(item).hasError('required') && form.get('routing').get(item).touched" class="error-color">{{ 'The field is required' | translate }}</div>
                
                </div>
            </div>
        </ng-container>

        <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10">{{'Fleet size' | translate}}:</label>
        
        <ng-container formGroupName="fulfilment">
            <ng-container formArrayName="fleet">
                <label class="tx-11 tx-bold tx-spacing-1 tx-color-01 tx-uppercase tx-sans mg-t-20 mg-b-10 mg-r-10">{{'Fleet' | translate}}:</label>
                <button (click)="addFleet()" class="d-inline btn btn-sm btn-outline-info pd-0 mg-0" [style.height.px]="20" [style.width.px]="20">
                    <i-feather [style.width.px]="18" [style.height.px]="20" name="plus"></i-feather>
                </button>
                <div class="d-flex justify-content-start align-items-center flex-wrap">
                    <ng-container *ngFor="let step of fleet.controls; let i=index">
                        <ng-container formGroupName="{{i}}">
                            <div 
                                class="item-md mg-r-10">
                                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Vehicle type' | translate}}:</label>
                                    <select [id]="item" class="custom-select" formControlName="vehicleTypeId">
                                        <ng-container *ngIf="(vehicleTypes$ | async) as vehicleTypes">
                                            <option *ngFor="let vehicle of vehicleTypes" [value]="vehicle.id">{{ vehicle.code }}</option>
                                        </ng-container>
                                    </select>
                            </div>
                            <div 
                                class="item-md mg-r-10">
                                    <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0">{{'Max' | translate}}:</label>
                                    <input type="number" formControlName="max" class="form-control w-100" />
                            </div>
                            <button (click)="removeFleet(i)" class="btn btn-xs btn-outline-warning remove-van-limit align-self-end"><i-feather [style.height.px]="14" name="trash-2"></i-feather></button>
                        </ng-container>
                    </ng-container>

                </div>
            </ng-container>
        </ng-container>

        <div  class="form-group d-flex justify-content-end mg-t-40">

            <button (click)="resetForm()" [style.width.%]="20" class="btn btn-sm btn-uppercase btn-white mg-r-10">Reset custom settings</button>
            
            <!-- <button (click)="close()" [style.width.%]="20" class="btn btn-sm btn-outline-info btn-uppercase mg-r-10">{{'Close' | translate}}</button> -->

            <button (click)="onSubmit()" type="submit" [disabled]="submitted" class="btn btn-sm btn-uppercase btn-brand-01 flex-grow-1">
                <span *ngIf="!submitted">{{ 'Test shift settings parameters' | translate }}</span>
                <ng-container *ngIf="submitted">
                    <div class="load-spinner text-center">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                    </div>
                </ng-container>
            </button>


        </div>

    </form>
</div>