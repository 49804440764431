import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import { Subject, merge, Subscription } from 'rxjs';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { User } from '@entities/user';

@Component({
  selector: 'app-oauth-redirect',
  template: `
    <div class="load-spinner text-center">
      <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> <span class="pd-l-10">{{ 'Please, wait!' | translate }}</span>
    </div>
  `,
  styles: [`
    .load-spinner {
      height: 90vh;
    }
  `]
})
export class OauthRedirectComponent implements OnInit, OnDestroy {

  public destroy$ = new Subject<boolean>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly appService: AppService
  ) { }

  public ngOnInit() {
    merge(this.authenticationService.currentUser, this.appService.userAppSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: any) => {
          if (user instanceof User) {
            this.appService.getUserAppSettings().pipe(take(1), takeUntil(this.destroy$)).subscribe();
          }

          if (user && user.hasOwnProperty('adminAppSettings')) {
            const startPage = (user.adminAppSettings && user.adminAppSettings.startPage) ? user.adminAppSettings.startPage : undefined;
            this.authenticationService.redirectAfterLogin(startPage);
          }
        });

    const params = new URLSearchParams(this.activatedRoute.snapshot.fragment);
    const id_token = params.get('id_token');
    this.login(id_token);
  }

  private login(id_token: string) {
    this.authenticationService.loginViaExternalProvider(id_token)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.appService.getUserAppSettings())
      ).subscribe(
        () => {},
        () => {
          this.authenticationService.redirectToLoginPage();
        }
      );
  } 

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
