import * as _ from 'lodash';

import { Roles } from '@enums/enum';
import { UsersRoles } from '@enums/roles';

import { Token } from './token';

export class User {
    public id: number;

    get username() {
        return this.token ? this.token.sub : false;
    }

    get authorities() {
        return this.token ? this.token.authorities : [];
    }

    public token: Token;
    /**
     * Jwt session token
     */
    public sessionToken: string;

    public firstName: string;
    public lastName: string;

    public static fromSessionToken(sessionToken: string) {
        try {
            const token = new Token().parse(sessionToken);

            const user = new User();

            user.sessionToken = sessionToken;
            user.token = token;

            return user;
        } catch (exception) {
            return null;
        }
    }

    get isSysadmin() {
        return !_.isUndefined(this.authorities.find(role => role === Roles.ROLE_SYSADMIN));
    }

    get isSysadminRole() {
        return (this.authorities.indexOf(UsersRoles.ROLE_SYSADMIN) !== -1);
    }

    get isDispacherRole() {
        return (this.authorities.indexOf(UsersRoles.ROLE_DISPATCHER) !== -1);
    }

    get isCustomerCareRole() {
        return (this.authorities.indexOf(UsersRoles.ROLE_CUSTOMER_CARE) !== -1);
    }

    public serialize() {
        return this.sessionToken;
    }

    public deserialize(serialized) {
        return User.fromSessionToken(serialized);
    }

    get roleLabel() {
        if (this.isSysadmin) {
            return UsersRoles.ROLE_SYSADMIN;
        } else if (this.isDispacherRole){
            return UsersRoles.ROLE_DISPATCHER;
        } else if (this.isCustomerCareRole){
            return UsersRoles.ROLE_CUSTOMER_CARE;
        }
    }
}
