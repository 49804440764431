import { Injectable } from '@angular/core';

import { MapsAPILoader } from '@agm/core';
import { Observable, Observer } from 'rxjs';

/**
 * Service responsible for Google Maps API interaction
 */
@Injectable()
export class GeocodingService {
    private TAG = '[Geocoding2Service]';
    private client: any;

    constructor(private mapsAPILoader: MapsAPILoader) {
        this.mapsAPILoader.load().then(() => {
            this.client = new google.maps.Geocoder();
        });
    }

    /**
     * Geocode raw address
     * @param address The Raw Address
     * @return Observable<google.maps.GeocoderResult[]> Result of the coding
     */
    public geocode(address): Observable<google.maps.GeocoderResult[]> {
        return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.client.geocode({ address }, (response, status) => {

                const result: google.maps.GeocoderResult[] = response;

                if (status === 'OK') {
                    observer.next(result);
                    observer.complete();
                } else {
                    console.log(this.TAG, 'geocode was not successful for the following reason:', result);
                    observer.error(status);
                }
            });
        });
    }
}
