
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';

@Pipe({
    name: 'sortShiftByStartTimePipe',
    pure: true
})
export class SortShiftByStartTimePipe implements PipeTransform {

    public transform(shifts: ShiftExt[]): ShiftExt[] {
       return _.sortBy(shifts, (shift: ShiftExt) => shift.fulfilment.startTime);
    }
}
