<layout-default>
  <div header>
      <div toolbar>
          <h4>
              <i-feather name="message-square"></i-feather> {{'Comments' | translate}}
          </h4>
      </div>

      <div toolbar>
          <div id="profanity-filter-btn-search" class="filter-container">
              <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Type phrase to search comment in historical data' | translate}}:</label>
              <div class="phrase-filter">
                  <form [formGroup]="form">
                      <input formControlName="q" type='text' class="list-filter form-control"
                          placeholder="{{'type to search' | translate}}" />
                  </form>
              </div>
          </div>
          <div>
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Search by full word' | translate}}:</label>
            <div class="phrase-filter">
                <select class="custom-select" [(ngModel)]="fullWord">
                    <option [value]="true">{{'Yes' | translate}}</option>
                    <option [value]="false">{{'No' | translate}}</option>
                </select>
            </div>
          </div>
          <div>
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Show all depots' | translate}}:</label>
            <div class="phrase-filter">
                <select class="custom-select" [(ngModel)]="queryParams.showAllDepots" (ngModelChange)="modelChanged()">
                    <option [value]="true">{{'Yes' | translate}}</option>
                    <option [value]="false">{{'No' | translate}}</option>
                </select>
            </div>
          </div>
          <div>
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">{{'Status' | translate}}:</label>
            <div class="phrase-filter">
                <select class="custom-select" [(ngModel)]="queryParams.statusses" (ngModelChange)="modelChanged()">
                    <option *ngFor="let status of commentStatusOptions" [value]="status">{{'CommentStatus.'+status | translate | translate}}</option>
                </select>
            </div>
          </div>
          <div>
            <label class="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">&nbsp;</label>
            <button class="btn btn-sm btn-uppercase btn-white"
                [routerLink]="['/', 'locations', 'profanity-filter']">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="book-open"></i-feather> {{ 'Dictionary' | translate }}
            </button>
          </div>
      </div>
      <hr />
  </div>
  
  <div content>
      <div *ngIf="(list$ | async) as list">

            <ng-container *ngIf="isExternalPaging else searchTmpl">
                <ngx-datatable 
                    id="comments-table" 
                    class="bootstrap" 
                    [rows]="rows" 
                    [columns]="columns" 
                    [columnMode]="'flex'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [messages]="tableMessages" 
                    [rowHeight]="'auto'" 
                    [externalPaging]="true"
                    [count]="totalElements"
                    [offset]="queryParams.page"
                    [limit]="queryParams.size"
                    [scrollbarH]="scrollBarHorizontal"
                    (page)="setPage($event)">
                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                <b (click)="sort()">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    
                                <div [ngSwitch]="col.prop">
                                    <div *ngSwitchCase="'id'">
                                        {{rowIndex + 1 + (queryParams.size * queryParams.page)}} 
                                        <a class="map" 
                                        [routerLink]="['/', 'locations', row.source, 'edit']" 
                                        target="_blank"> <i class="fas fa-external-link-alt"></i></a>
                                    </div>
    
                                    <div *ngSwitchCase="'modifiedAt'">
                                        {{value | date:'d-MM-yyyy HH:mm'}}
                                    </div>
    
                                    <div *ngSwitchCase="'comment'">
                                        <div class="comment">
                                            <ng-container *ngIf="[commentStatus.NEW, commentStatus.REJECTED].includes(queryParams.statusses) else approvedCommentTmpl">
                                                <p><strong>{{'New' | translate}}:</strong> {{value}}</p>
                                                <p *ngIf="row.current"><strong>{{'Current' | translate}}:</strong> {{row.current}}</p>
                                            </ng-container>

                                            <ng-template #approvedCommentTmpl>
                                                <p>{{value}}</p>
                                            </ng-template>             
                                           
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'author'">
                                        [{{value}}] {{row.authorName}}
                                    </div>
    
                                    <div *ngSwitchCase="'status'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="commentStatus.NEW" class="badge badge-warning">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="commentStatus.REJECTED" class="badge badge-danger">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="commentStatus.APPROVED" class="badge badge-success">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="commentStatus.APPROVED_WITH_EDITS" class="badge badge-success">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-light">{{ value | translate }}</span>
                                    </ng-container>
                                    </div>
        
                                    <div *ngSwitchCase="'options'">
                                    
                                        <div *ngIf="queryParams.statusses === commentStatus.NEW" class="d-flex justify-content-center align-items-center">
                                            <button
                                                [containerClass]="'pretty-tooltip'" [tooltip]="'Click to accept the comment from driver without any changes' | translate"
                                                class="btn btn-sm btn-uppercase btn-success mg-r-10"
                                                type="button"
                                                (click)="reviewComment(row)"
                                                id="dropdownMenuButton">
                                                <i-feather name="check-circle"></i-feather>

                                            </button>

                                            <button
                                                class="btn btn-sm btn-uppercase btn-primary mg-r-10"
                                                type="button"
                                                (click)="markAsToEdit(row)"
                                                id="dropdownMenuButton">
                                                {{'Edit' | translate}}
                                            </button>

                                            <button
                                                [containerClass]="'pretty-tooltip'" [tooltip]="'Click to dismiss the driver comment The old comment will be left in the database' | translate"
                                                class="btn btn-sm btn-uppercase btn-danger"
                                                type="button"
                                                (click)="reviewComment(row, true)"
                                                id="dropdownMenuButton">
                                                <i-feather name="trash-2"></i-feather>
                                            </button>
                                        </div>
                                        
                                    </div>
    
                                    <div id="profanity-filter-table-col-{{col.id}}" *ngSwitchDefault>{{ value }}</div>
                                </div>
    
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </ng-container>
            <ng-template #searchTmpl>
                <ngx-datatable 
                    id="comments-table" 
                    class="bootstrap" 
                    [rows]="rows" 
                    [columns]="columns" 
                    [columnMode]="'flex'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [messages]="tableMessages" 
                    [rowHeight]="'auto'" 
                    [externalPaging]="false"
                    [limit]="20" 
                    [scrollbarH]="scrollBarHorizontal">
                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [name]="col.prop" [flexGrow]="col.flexGrow" [prop]="col.prop" [sortable]="col.sortable">
                            <ng-template let-column="column" ngx-datatable-header-template class="header" let-sort="sortFn">
                                <b (click)="sort()">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>

                                <div [ngSwitch]="col.prop">
                                    <div *ngSwitchCase="'id'">
                                        {{rowIndex + 1 + (queryParams.size * queryParams.page)}} 
                                        <a class="map" 
                                        [routerLink]="['/', 'locations', row.source, 'edit']" 
                                        target="_blank"> <i class="fas fa-external-link-alt"></i></a>
                                    </div>

                                    <div *ngSwitchCase="'modifiedAt'">
                                        {{value | date:'d-MM-yyyy HH:mm'}}
                                    </div>

                                    <div *ngSwitchCase="'comment'">
                                        <div class="comment">
                                            <p>{{value}}</p>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'status'">
                                    <ng-container [ngSwitch]="value">
                                        <span *ngSwitchCase="commentStatus.NEW" class="badge badge-warning">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="commentStatus.REJECTED" class="badge badge-danger">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="commentStatus.APROVED" class="badge badge-success">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchCase="commentStatus.APPROVED_WITH_EDITS" class="badge badge-success">{{ 'CommentStatus.'+value | translate }}</span>
                                        <span *ngSwitchDefault class="badge badge-light">{{ value | translate }}</span>
                                    </ng-container>
                                    </div>
        
                                    <div *ngSwitchCase="'options'">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <button
                                                class="btn btn-sm btn-uppercase btn-success mg-r-10"
                                                type="button"
                                                (click)="markAsToEdit(row)"
                                                id="dropdownMenuButton">
                                                {{ 'Edit' | translate }}
                                            </button>

                                            <button
                                                [containerClass]="'pretty-tooltip'" [tooltip]="'Click to dismiss the driver comment The old comment will be left in the database' | translate"
                                                class="btn btn-sm btn-uppercase btn-danger"
                                                type="button"
                                                (click)="reviewComment(row, true)"
                                                id="dropdownMenuButton">
                                                <i-feather name="trash-2"></i-feather>
                                            </button>
                                        </div>
                                    </div>

                                    <div id="profanity-filter-table-col-{{col.id}}" *ngSwitchDefault>{{ value }}</div>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </ng-template>

      </div>

      <div *ngIf="loader" class="load-spinner text-center">
          <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
      </div>
  </div>

</layout-default>

<ng-template #editCommentTmpl>
  <div class="modal-header">
      <h4 class="modal-title">{{'Moderation of comment' | translate }}</h4>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body text-center">
      <p>{{'Please, put your acceptable comment to the textarea' | translate }}.</p>
      <textarea [style.margin-bottom.px]="20" [(ngModel)]="modalArgs.values.comment.comment" class="form-control" [value]="value"></textarea> 

      <button type="button" class="btn btn-brand-01" (click)="reviewComment(modalArgs.values.comment)">
          {{'Save' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
  </div>
</ng-template>


<!-- {{'CommentStatus.NEW' | translate}} -->
<!-- {{'CommentStatus.APPROVED' | translate}} -->
<!-- {{'CommentStatus.REJECTED' | translate}} -->
<!-- {{'CommentStatus.APPROVED_WITH_EDITS' | translate}} -->
