
  <layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="package"></i-feather> {{'Depots map' | translate}}
            </h4>
        </div>
        <hr />
    </div>
    <div content>
      <ng-container *ngIf="(depots$ | async) as depots">
          <gmap [locations]="depots" [mapProperties]="mapProperties" [mapOption]="mapOption"></gmap>
        </ng-container>
    </div>
  </layout-default>
  