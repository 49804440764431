<layout-default>
    <div header>
        <h4 toolbar>
            <i-feather name="git-pull-request"></i-feather> {{'Solution' | translate}}
        </h4>

        <hr>
    </div>

    <div content class="no-border">
        <div class="card bg-white mb-4">
            <div class="card-body">
                <h4>{{'Simulation configuration' | translate}}:</h4>
                
                <app-simulation-create
                        [datasetId]="simulation.datasetId"
                        [simulationId]="simulation.simulationId"
                        (addedSimulation)="addedSimulation($event)">
                </app-simulation-create>
            </div>
        </div>

        <div class="card bg-white mb-4">
            <div class="card-body">
                <h4>{{'Simulation execute' | translate}}:</h4>
                <app-simulation-execute
                        [simulationId]="simulation.simulationId"
                        [simulation]="simulation"
                        [shift]="simulation.simulationShiftConfigDto.shift">
                </app-simulation-execute>
            </div>
        </div> 

        <div class="card bg-white mb-4">
            <foldable-container
                    class="card-body unfolded"
                    [toggleIcon]="true"
                    [showFoldedTitle]="true"
                    [isFolded]="visualiser">

                <div class="title"><h4>{{'Visualiser' | translate}}</h4></div>

                <div class="content">
                    <grid *ngIf="!loading" [dataset]="dataset"></grid>
                    <div *ngIf="loading" class="load-spinner text-center">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
                    </div>
                </div>

            </foldable-container>
        </div>

         <div class="card bg-white mb-4">
            <foldable-container
                    class="card-body"
                    [toggleIcon]="true"
                    [showFoldedTitle]="true"
                    [isFolded]="visualiser">

                <div class="title"><h4>{{'Simulation results' | translate}}:</h4></div>

                <div class="content">
                    <app-simulation-finalize></app-simulation-finalize>
                </div>

            </foldable-container>
        </div>

       <div class="card bg-white mb-4">
            <foldable-container
                    class="card-body"
                    [toggleIcon]="true"
                    [showFoldedTitle]="true"
                    [isFolded]="bookingAvailability">

                <div class="title"><h4>{{'Booking Availability' | translate}}:</h4></div>

                <div class="content">
                    <app-booking-availability-content *ngIf="!loading"
                                                      [date]="simulation.simulationShiftConfigDto.date"></app-booking-availability-content>
                    <div *ngIf="loading" class="load-spinner text-center">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
                    </div>
                </div>

            </foldable-container>
        </div>
    </div>
</layout-default>
