import * as _ from 'lodash';

import { Coordinates } from './coordinates';
import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class DeliveryClean extends Entity implements Serializable<DeliveryClean> {
    public id: number;
    public alteration: boolean;
    public alterationStatus: string;
    public createdAt: string;
    public delivery: string;
    public deliveryId: string;
    public estimatedArrivalTime: string;
    public estimatedDepartureTime: string;
    public estimatedDrivingDuration: string;
    public estimatedWaitDuration: string;
    public modifiedAt: string;
    public stopNO: number;
    public valid: boolean;
    public version: number;
    public detailWidthEstimatedWaitDuration: number;
    public detailWidthEstimatedDrivingDuration: number;
    public detailWidthEstimatedDeliveryDuration: number;
    public summary: {
        coordinates: Coordinates,
        estimatedDeliveryDuration: string,
        estimatedDryBoxCount: number,
        estimatedChilledBoxCount: number,
        estimatedFrozenBoxCount: number,
        slot: string,
        boxesSum: number
    };

    public deserialize(data: any) {
        if (!data) {
            return;
        }

        this.alteration = _.get(data, 'alteration');
        this.alterationStatus = _.get(data, 'alterationStatus');
        this.createdAt = _.get(data, 'createdAt');
        this.delivery = _.get(data, 'delivery');
        this.deliveryId = _.get(data, 'deliveryId');
        this.estimatedArrivalTime = _.get(data, 'estimatedArrivalTime');
        this.estimatedDepartureTime = _.get(data, 'estimatedDepartureTime');
        this.estimatedDrivingDuration = _.get(data, 'estimatedDrivingDuration');
        this.estimatedWaitDuration = _.get(data, 'estimatedWaitDuration');
        this.id = _.get(data, 'id');
        this.modifiedAt = _.get(data, 'modifiedAt');
        this.stopNO = _.get(data, 'stopNO');
        this.valid = _.get(data, 'valid');
        this.version = _.get(data, 'version');

        const rawSummary = _.get(data, 'summary');
        const estimatedDryBoxCount = _.get(rawSummary, 'estimatedDryBoxCount', 0);
        const estimatedChilledBoxCount = _.get(rawSummary, 'estimatedChilledBoxCount', 0);
        const estimatedFrozenBoxCount = _.get(rawSummary, 'estimatedFrozenBoxCount', 0);

        if (rawSummary !== null) {
            this.summary = {
                coordinates: (rawSummary['coordinates']) ? new Coordinates().deserialize(rawSummary['coordinates']) : new Coordinates(),
                estimatedDeliveryDuration: _.get(rawSummary, 'estimatedDeliveryDuration'),
                estimatedDryBoxCount: estimatedDryBoxCount,
                estimatedChilledBoxCount: estimatedChilledBoxCount,
                estimatedFrozenBoxCount: estimatedFrozenBoxCount,
                slot: _.get(rawSummary, 'slot'),
                boxesSum: estimatedDryBoxCount + estimatedChilledBoxCount + estimatedFrozenBoxCount
            };
        }

        this.detailWidthEstimatedWaitDuration = this.estimatedWaitDuration ? this.parseDuration(this.estimatedWaitDuration) : 0;
        this.detailWidthEstimatedDrivingDuration = this.estimatedDrivingDuration ? this.parseDuration(this.estimatedDrivingDuration) : 0;
        this.detailWidthEstimatedDeliveryDuration = (this.summary && this.summary.estimatedDeliveryDuration) ? this.parseDuration(this.summary.estimatedDeliveryDuration) : 0;
        
        return this;
    }

    private get visualiserDeliveryWidth() {
        const estimatedDrivingDuration = this.estimatedDrivingDuration;
        const estimatedDeliveryDuration = this.summary.estimatedDeliveryDuration;
        const estimatedWaitDuration = this.estimatedWaitDuration;

        const driving = estimatedDrivingDuration ? this.parseDuration(estimatedDrivingDuration) : 0;
        const delivering = estimatedDeliveryDuration ? this.parseDuration(estimatedDeliveryDuration) : 0;
        const waiting = estimatedWaitDuration ? this.parseDuration(estimatedWaitDuration) : 0;

        return driving + delivering + waiting;
    }

    public serialize() {
        return {
            alteration: this.alteration,
            alterationStatus: this.alterationStatus,
            createdAt: this.createdAt,
            delivery: this.delivery,
            deliveryId: this.deliveryId,
            estimatedArrivalTime: this.estimatedArrivalTime,
            estimatedDepartureTime: this.estimatedDepartureTime,
            estimatedDrivingDuration: this.estimatedDrivingDuration,
            estimatedWaitDuration: this.estimatedWaitDuration,
            id: this.id,
            modifiedAt: this.modifiedAt,
            stopNO: this.stopNO,
            valid: this.valid,
            version: this.version,
            summary: this.summary
        };
    }

    private parseDuration(e): number {
        let hours: number = 0;
        let minutes: number = 0;
        let seconds: number = 0;

        const h = e.match(/\d+H/);
        const m = e.match(/\d+M/);
        const s = e.match(/\d+S/);

        if (h) {
            hours = +h[0].replace(/H/, '');
        }

        if (m) {
            minutes = +m[0].replace(/M/, '');
        }

        if (s) {
            seconds = +s[0].replace(/S/, '');
        }

        return hours * 60 * 60 + minutes * 60 + seconds;
    }
}
