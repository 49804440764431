import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'distanceLabelPipe',
    pure: true
})
export class DistanceLabelPipe implements PipeTransform {

    constructor(private translate: TranslateService){}

    public transform(routeNumber: number, distances: Array<any>): string {
        if (distances && distances.length) {
            const route = distances.find(r => r.routeNumber === routeNumber);

            if (route) {
                const distance = route.totalDistance / 1000;
                const time = route.totalDuration;
                const duration = `${moment.duration(time, 'seconds').hours()} ${this.translate.instant('godz')} ${moment.duration(time, 'seconds').minutes()} min`;

                return `${this.translate.instant('Distance')}: ${distance.toFixed(2)} km\n` +
                        `${this.translate.instant('Duration')}: ${duration}\n` +
                        `${this.translate.instant('Km per delivery')}: ${(distance / route.deliveriesCount).toFixed(2)}`;
            } else {
                return `${this.translate.instant('Distance')}: 0 km` +
                        `${this.translate.instant('Duration')}: 0`;
            }
        }
    }
}
