import {Component, OnInit, Renderer2} from "@angular/core";
import { ProxyGuideTourService } from '@services/guidetour/proxy-guide-tor.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppService } from '@services/app.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'layout-with-sidebar',
    template: `
        <div class="wrapper">
            <div class="header">
                <ng-content select="[header]"></ng-content>
            </div>
            <div class="content">
                <ng-content select="[content]"></ng-content>
            </div>
        </div>
        <div class="sidebar">
            <ng-content select="[sidebar]"></ng-content>
        </div>
        <div *ngIf="help" class="sidebar sidebar-help" @animateSidebar>
            <sidebar-with-header>
                <div header class="vans-drivers-header pd-l-15 d-flex justify-content-between align-items-center">
                    <h6 class="tx-13 tx-spacng-1 tx-uppercase tx-semibold mg-b-0 d-inline btn-drivers active">{{'Help' | translate}}</h6>
                    <button (click)="close()" class="btn btn-link"><i-feather name="X"></i-feather></button>
                </div>
                <div content [innerHTML]="help"></div>
            </sidebar-with-header>
        </div>
    `,
    styleUrls: ['./layout-with-sidebar.component.scss'],
    animations: [
        trigger('animateSidebar', [
            transition(':enter', [
              style({ 'flex-basis': '0%' }),
              animate('100ms', style({ 'flex-basis': '25%' })),
            ]),
            transition(':leave', [
              animate('100ms', style({ 'flex-basis': 0 }))
            ])
          ]),
    ]
})

export class LayoutWithSidebarComponent implements OnInit {

    public help: SafeHtml;
    
    constructor(
        private readonly appService: AppService,
        private readonly domSanitizer: DomSanitizer
    ) {}
    
    public ngOnInit() {
        this.appService.help.subscribe(
            (text) => {
                this.help = this.domSanitizer.bypassSecurityTrustHtml(text);
            }
        )
    }

    public close() {
        this.help = undefined;
    }

}
