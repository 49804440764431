import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'editor-window',
    styles: [`
        :host {
            width: 95%;
            margin-bottom: 10px;
        }
    `],
    template: `
        <div class="overlay">
            <div class="loader"></div>
        </div>
        <ng-container>
            <ng-content select=".title"></ng-content>
        </ng-container>

        <ng-container>
            <ng-content select=".form"></ng-content>
        </ng-container>

        <div class="actions">
            <button class="btn btn-link btn-xs" (click)="cancelEmitter.next()">{{'Cancel' | translate}}</button>
            <button class="btn btn-brand-01 btn-xs" [disabled]="!valid" (click)="saveEmitter.next()">{{'Save' | translate}}</button>
        </div>
    `
})

export class EditorWindowsComponent {
    @Input('valid') public valid = true;
    @Output('cancel') public cancelEmitter: EventEmitter<null> = new EventEmitter();
    @Output('save') public saveEmitter: EventEmitter<null> = new EventEmitter();
}
