import * as plLocale from 'date-fns/locale/pl';

export let datePickerOptions = {
    minYear: 1970,
    maxYear: 2030,
    displayFormat: 'D MMMM YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 1,
    locale: plLocale
};
