import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { Partner } from '../entities/partner';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends CrudService<Partner, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/partner/v1`;

  constructor(
    protected _http: HttpClient,
    protected utilsService: UtilsService) {
    super(_http, PartnerService.BASE_API, utilsService);
  }

  public getPartnerService(): Observable<Partner> {
    const endpoint = this.utilsService.interpolate(`${PartnerService.BASE_API}/servicePartner`, {});
    return this._http.get<Partner>(endpoint);
  }

}
