import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ShiftSettings } from '@entities/shift-settings';

@Pipe({
  name: 'shiftWorkingHoursPipe',
  pure: true
})
export class ShiftWorkingHoursPipe implements PipeTransform {

  public transform(shift: ShiftSettings): string[] {
    const startTime = moment(shift.startTime, 'hours');
    const endTime = moment(shift.endTime, 'hours').add(1, 'hours');
    const slotDuration = shift.slotDuration;
    const hours = [];

    while (startTime.hour() !== endTime.hour()) {
        hours.push(startTime.format("HH:mm") + '-' + startTime.add(slotDuration, "minutes").format("HH:mm"))
    }
    return hours
  }
}
