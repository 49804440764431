<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="file"></i-feather> {{'Salary report files' | translate}}
            </h4>
        </div>
        <hr />
    </div>
    
    <div content>
        <div class="d-flex align-items-center flex-wrap">
            <div class="file" *ngFor="let file of files">
                <i-feather class="file__icon" name="file-text"></i-feather>
                <div class="file__driver">{{file.driverLabel}}</div>
                <div class="file__report">{{file.reportLabel}}</div>
                <div class="dropdown mg-t-10" dropdown>
                    <button
                        class="btn btn-outline-secondary btn-xs dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton">
                        {{ 'Select' | translate }}
                    </button>

                    <div class="dropdown-menu dropdown-menu-center">
                        <a 
                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                            (click)="view(file)">
                                {{'View' | translate }}
                        </a>
                        <a 
                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                            (click)="print(file)">
                                {{'Download' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</layout-default>
