import { Component, OnInit } from '@angular/core';
import { NavService } from '@services/nav.sevice';
import { AuthenticationService } from 'src/app/authentication.service';

@Component({
  selector: 'app-page-access-denied',
  template: `

    <div class="content content-fixed content-auth-alt" [style.height.vh]="100">
        <div class="container ht-100p tx-center">
            <div class="ht-100p d-flex flex-column align-items-center justify-content-center">
                <h1 class="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5"><i-feather name="activity"></i-feather> {{'Access denied' | translate}}</h1>
                <h5 class="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Oopps. {{'You have not right permission to permit to use that page, Please contact with your administrator' | translate}}.</h5>
                <div class="mg-b-40"><button class="btn btn-white pd-x-30" (click)="back()">{{'Back to home page' | translate}}</button></div>
            </div>
        </div>
    </div>

`
})
export class AccessDeniedComponent {

  constructor(private authenticationService: AuthenticationService) { }

  public back(): void {
    this.authenticationService.redirectAfterLogin();
  }
}
