import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryClean } from '@entities/delivery-clean';
import { Activity } from '@entities/activity';
import { secToHours } from '@shared/functions/secToHours.function';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '@enums/enum';

@Pipe({
    name: 'deliveryTooltipPipe',
    pure: true
})
export class DeliveryTooltipPipe implements PipeTransform {

    constructor(private readonly translate: TranslateService) {}

    public transform(activity: Activity): string {
        const timeFormat = "HH:mm";
        const slot = (activity.slot.from !== null && activity.slot.to !== null) ? `Slot: ${moment(activity.slot.from).format(timeFormat)}-${moment(activity.slot.to).format(timeFormat)}, ` : '';
        const requiredCapacity = (activity.type === ActivityType.DELIVERY && (activity.requiredCapacity.dryBoxes !== 0 || activity.requiredCapacity.chilledBoxes !== 0 || activity.requiredCapacity.frozenBoxes !== 0)) 
            ? `${this.translate.instant('Dry')}: ${activity.requiredCapacity.dryBoxes}, ${this.translate.instant('Chilled')}: ${activity.requiredCapacity.chilledBoxes}, ${this.translate.instant('FROZEN')}: ${activity.requiredCapacity.frozenBoxes}\n` : '';
        const sumOfBoxes = (activity.requiredCapacity.sumOfBoxes !== 0 && activity.type === ActivityType.DELIVERY) ? `${this.translate.instant('Sum of boxes')}: ${activity.requiredCapacity.sumOfBoxes}` : ''                            
        const planning = (activity.planning) ? `${this.translate.instant('Estimate')}: ${moment(activity.planning.from).format(timeFormat)}-${moment(activity.planning.to).format(timeFormat)}\n` : '';

       return   `Id: ${activity.id}, NO: ${activity.stepNo}, ${this.translate.instant('Type')}: ${this.translate.instant('ActivityType.' + activity.type)}\n` +
                `${slot}${this.translate.instant('Duration')}: ${secToHours(activity.durationSeconds)}\n` +
                `${planning}` +
                `${requiredCapacity}` + 
                `${sumOfBoxes}`;
    }
}
