
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-4">
                    <label for="name">{{ 'Label' | translate }}:</label>
                    <input currencyMask formControlName="name" class="form-control" />
                </div>

                <ng-container formGroupName="config">
                    <div class="form-group col-4">
                        <label for="originStartDate">{{ 'Origin start date' | translate }}:</label>
                        <ng-datepicker class="datepicker-input" formControlName="originStartDate" [options]="options"></ng-datepicker>
                    </div>

                    <div class="form-group col-4">
                        <label for="originEndDate">{{ 'Origin end date' | translate }}:</label>
                        <ng-datepicker class="datepicker-input" formControlName="originEndDate" [options]="options"></ng-datepicker>
                    </div>
                </ng-container>
            </div>

            <div class="row">
                <ng-container formGroupName="config">
                    <div class="form-group col-4">
                        <label for="originShiftType">{{ 'Origin shift type' | translate }}:</label>
                        <select class="form-control" formControlName="originShiftType">
                            <option *ngFor="let option of shiftType" [value]="option">{{ option | translate }}</option>
                        </select>
                    </div>

                    <div class="form-group col-4">
                        <label for="originWarehouse">{{ 'Origin warehouse' | translate }}:</label>
                        <select class="form-control" formControlName="originWarehouse">
                            <option *ngFor="let option of depots$ | async" [value]="option">{{ option | translate }}</option>
                        </select>
                    </div>

                    <div class="form-group col-4">
                        <label for="poolSize">{{ 'Pool size' | translate }}:</label>
                        <input type="number" min="1" formControlName="poolSize" class="form-control" />
                    </div>
                </ng-container>
            </div>

            <div class="row justify-content-end">
                <button [disabled]="submitted" type="submit" class="col-2 btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">
                    <span *ngIf="!submitted">{{ 'Create dataset' | translate }} </span>
                    <div class="load-spinner">
                        <i class="fa fa-spinner fast-pulse" aria-hidden="true" *ngIf="submitted"></i>
                    </div>
                </button>
            </div>
        </form>
    