import { Pipe, PipeTransform } from '@angular/core';
import { RequiredCapacity, Activity } from '@entities/activity';
import { ActivityType } from '@enums/enum';
import * as _ from 'lodash';

@Pipe({
  name: 'maxNoBoxesExceededPipe',
  pure: true
})
export class MaxNoBoxesExceededPipe implements PipeTransform {
  private static maxNoBoxes = 12;

  transform(requiredCapacity: RequiredCapacity, type: ActivityType): any {
    return (type === ActivityType.DELIVERY) ? _.sum(Object.values(requiredCapacity)) > MaxNoBoxesExceededPipe.maxNoBoxes : false;
  }
}
