import { Directive, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[dropdown-toggle], .dropdown-toggle',
})
export class DropdownToggleDirective {
    public onToggle = new EventEmitter();

    @HostListener('click') public toggle(): void {
        this.onToggle.emit();
    }
}
