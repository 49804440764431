import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';

@Injectable()
export class FormService {
    private currentForm: NgForm;

    constructor(currentForm: NgForm) {
        this.currentForm = currentForm;
    }
}
