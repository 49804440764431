import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersRoles } from '@enums/roles';
import { DriverPrintableReportComponent } from './components/punctuality/driver-printable-report.component';
import { DriverPunctualityComponent } from './components/punctuality/driver-punctuality.component';
import { RoutingEfficiencyComponent } from './components/routing-efficiency/routing-efficiency.component';
import { ProxyComponent } from '@shared/components/proxy.component';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';
import { StatsRoutingDistancesComponent } from './components/stats-routing-distances/stats-routing-distances.component';
import { OrderReportComponent } from './components/order-report/order-report.component';

const routes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        children: [
            { path: '', component: DriverPunctualityComponent },
            {
                path: 'drivers', 
                component: DriverPunctualityComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Analytics_driverpunctuality_view],
                    breadcrumb: 'Breadcrumbs.DriverPunctuality'
                }
            },
            {
                path: 'routing-efficiency',
                component: ProxyComponent,
                children: [
                    {
                        path: 'boxes', 
                        component: RoutingEfficiencyComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Analytics_routingefficiency_view],
                            breadcrumb: 'Breadcrumbs.RoutingEfficiencyBoxes'
                        }
                    },
                    {
                        path: 'distances', 
                        component: StatsRoutingDistancesComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Analytics_routingefficiency_view],
                            breadcrumb: 'Breadcrumbs.RoutingEfficiencyDistances'
                        }
                    },
                    { path: '**', pathMatch: 'full', redirectTo: 'boxes'}
                ]
            },
            {
                path: 'orders', 
                component: OrderReportComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Analytics_punctuality_view],
                    breadcrumb: 'Breadcrumbs.OrderReport'
                }
            }
        ]
    },
    {
        path: 'print-driver-report',
        component: DriverPrintableReportComponent,
        data: {
            expectedRole: [UsersRoles.ROLE_SYSADMIN]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AnalyticsRoutingModule { }
