import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { MonthlyShiftConfig } from '@calendar/entities/monthlyShiftConfig';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { plainToClass } from 'class-transformer';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Planning } from '@entities/planning';
import { VisualiserService } from '../../visualiser/services/visualiser.service';
import { UtilsService } from './utils.service';
import { environment } from '@environment';
import { OptimizeResult } from 'src/app/visualiser/entities/optimize-result';
import * as _ from 'lodash';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { ShiftFleet } from '@calendar/interafces/shift-fleet.interfae';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { CutoffSolutions } from 'src/app/visualiser/interfaces/cutoff-solutions.interface';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class ShiftsService {
    private static SHIFT_SETTINGS: string = 'shifts/v1/${shiftId}';

    private readonly tag = '[ShiftsService]';
    private readonly notifier: NotifierService;
    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;
    private selectedMonthShiftConfigSource = new Subject<MonthlyShiftConfig>();
    private endpoints = {
        month: `${this.prefix}/shifts/v5/{warehouse}/{startdate}/{enddate}`,
        day: `${this.prefix}/shifts/v1/{dayDate}`,
        shift: `${this.prefix}/shifts/v2/{path}/{shiftType}`,
        update: `${this.prefix}/shifts/v2/{shiftId}`,
        addShift: `${this.prefix}/shifts/v3/{shiftId}`,
        removeShift: `${this.prefix}/shifts/v3/{shiftId}`,
        routeStrategy: `${this.prefix}/dictionary/v1/RouteStrategy`,
        getShiftSimulations: `${this.prefix}/shifts/v2/solution/{shiftId}/simulations`,

        getShiftSimulationsv2: `${this.prefix}/shiftsolutions/v1/simulation/{shiftId}`,

        getShiftSimulationsv3: `${this.prefix}/shifts/v3/solution/{depotId}/{cutoff}`,
        getShiftSimulationsv4: `${this.prefix}/shifts/v3/solution/{shiftId}`,

        getSolution: `${this.prefix}/shifts/v1/solution/{shiftId}/simulations/id/{key}`,
        calculateShift: `${this.prefix}/shifts/v10/optimize/{shiftId}`,
        planningsequence: `${this.prefix}/planner/v1`,
        shiftTypesForShift: `${this.prefix}/shifts/v4/{date}/{warehouse}`,
        shiftsPlanning: `${this.prefix}/shiftplanning/v4`,
        shiftsPlanningCreate: `${this.prefix}/shiftplanning/v5`,
        shiftsPlanningUpdate: `${this.prefix}/shiftplanning/v5`

    };
    private routeStrategySource: BehaviorSubject<Array<string> | null> = new BehaviorSubject<Array<string> | null>(null);

    public selectedMonthShiftConfigJSON = {};
    public routeStrategy: Observable<Array<string> | null> = this.routeStrategySource.asObservable();
    public selectedMonthShiftConfig = this.selectedMonthShiftConfigSource.asObservable();
    public planningSequence: Observable<Planning[]>;

    public __temporarySolutionSource: BehaviorSubject<ShiftConfig | null> = new BehaviorSubject<ShiftConfig>(null);
    public temporarySolution: Observable<ShiftConfig | null> = this.__temporarySolutionSource.asObservable();

    constructor(
        private http: HttpClient,
        private utilsService: UtilsService,
        private visualiserService: VisualiserService,
        private notifierService: NotifierService,
        private translate: TranslateService,
        private ls: LocalStorageService
    ) {
        this.notifier = notifierService;
        this.loadPlanningSequence();
    }

    public loadRouteStrategyDictionary(): Observable<string[]> {
        const endpoint = `${this.host}${this.endpoints.routeStrategy}`;

        return this.http.get(endpoint).pipe(
            map((response: Array<string> | null) => {
                this.routeStrategySource.next(response);
                return response;
            })
        );
    }

    public loadPlanningSequence(): Observable<Planning[]> {
        const endpoint = `${this.host}${this.endpoints.planningsequence}`;

        return this.planningSequence = this.http
            .get(endpoint)
            .pipe(map(res => plainToClass(Planning, res as Planning[])))
            .pipe(
                map((planning: Planning[]) => {
                    return planning;
                })
            );
    }

    public loadMonthConfiguration(startdate, enddate, warehouse, interval = true) {

        const endpoint = this.nano(`${this.host}${this.endpoints.month}`, {
            startdate,
            enddate,
            warehouse: warehouse
        });

        return this.sendRequest(endpoint, interval);
    }

    public getShiftsTypesForShift(date: string, warehouse: string): Observable<ShiftConfig[]> {
        const endpoint = this.nano(`${this.host}${this.endpoints.shiftTypesForShift}`, { date, warehouse });
        return this.http.get<ShiftConfig[]>(endpoint).pipe(
            map((s: ShiftConfig[]) => {
                return _.sortBy(s, ['startTime'])
            })
        );
    }

    public sendRequest(endpoint, interval) {
        return this.http
            .get(endpoint, this.utilsService.httpHeaders())
            .pipe(
                map(response => {
                    const month = new MonthlyShiftConfig().deserialize(response);
                    if (this.selectedMonthShiftConfigJSON !== JSON.stringify(month) || !interval) {
                        this.selectedMonthShiftConfigSource.next(month);
                        this.selectedMonthShiftConfigJSON = JSON.stringify(month);
                    }
                })
            )
            .subscribe();
    }

    public getShiftSettings(shiftId: string): Observable<ShiftConfig> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${ShiftsService.SHIFT_SETTINGS}`, {shiftId: shiftId});
        return this.http.get(endpoint, this.utilsService.httpHeaders())
            .pipe(
                map((response) => plainToClass(ShiftConfig, response as ShiftConfig))
            );
    }

    public updateShift(shift: ShiftConfig): Observable<any> {

        if (!shift) {
            return;
        }

        const endpoint = this.nano(`${this.host}${this.endpoints.update}`, {
            shiftId: shift.shiftId
        });

        return this.http.put(endpoint, shift, this.utilsService.httpHeaders()).pipe(
            map(response => {
                console.log(this.tag, '[Response]', response);
                return new ShiftConfig().deserialize(response);
            })
        );
    }

    public addShift(shiftId: string) {
       
        if (!shiftId) {
            return;
        }

        const endpoint = this.nano(`${this.host}${this.endpoints.addShift}`, { shiftId: shiftId });

        return this.http.post(endpoint, null, this.utilsService.httpHeaders())
            .pipe(
                map(response =>  new ShiftConfig().deserialize(response))
            );
    }

    public removeShift(shiftId: string) {
       
        if (!shiftId) {
            return;
        }

        const endpoint = this.nano(`${this.host}${this.endpoints.removeShift}`, { shiftId: shiftId });

        return this.http.delete(endpoint);
    }


    public calculateTemporaryResults(shift: ShiftUpdateDto, key?): Observable<CutoffSolutions[]> {

        const shiftId = `${shift.shiftId.date}:${shift.shiftId.warehouse}:${shift.shiftId.type}`;
        const endpoint = this.nano(`${this.host}${this.endpoints.calculateShift}`, {
            shiftId: shiftId
        });

        const body = {
            key: key ? key : shiftId,
            shift: shift
        }

        return this.http.post<CutoffSolutions[]>(endpoint, body, this.utilsService.httpHeaders()).pipe(
            switchMap(() => this.getShiftSimulations(shift))
        );
    }

    public getShiftSimulationsV2(shift: ShiftUpdateDto, cutoff: string): Observable<CutoffSolutions[]> {
        const shiftId = `${shift.shiftId.date}:${shift.shiftId.warehouse}:${shift.shiftId.type}`;

        const endpoint = this.nano(`${this.host}${this.endpoints.getShiftSimulationsv2}`, {
            shiftId,
            cutoff,
        });

        return this.http.get<CutoffSolutions[]>(endpoint).pipe(
            map((solutions: CutoffSolutions[]) => _.sortBy(solutions, (s: CutoffSolutions) => [s.simulationKey, s.errors, s.distance]))
        );
    }

    public getShiftSimulationsV3(cutoff: string, shift: ShiftUpdateDto): Observable<CutoffSolutions[]> {
        const shiftId = `${shift.shiftId.date}:${shift.shiftId.warehouse}:${shift.shiftId.type}`;

        const endpoint = this.nano(`${this.host}${this.endpoints.getShiftSimulationsv2}?cutoff=${cutoff}`, {
            shiftId,
        });

        return this.http.get<CutoffSolutions[]>(endpoint).pipe(
            map((solutions: CutoffSolutions[]) => _.sortBy(solutions, (s: CutoffSolutions) => [s.simulationKey, s.errors, s.distance]))
        );
    }

    public getShiftSimulations(shift: ShiftUpdateDto): Observable<CutoffSolutions[]> {

        const shiftId = `${shift.shiftId.date}:${shift.shiftId.warehouse}:${shift.shiftId.type}`;
        const endpoint = this.nano(`${this.host}${this.endpoints.getShiftSimulations}`, {
            shiftId: shiftId
        });

        return this.http.get<CutoffSolutions[]>(endpoint).pipe(
            map((solutions: CutoffSolutions[]) => _.sortBy(solutions, (s: CutoffSolutions) => [s.simulationKey, s.errors, s.distance]))
        );
    }

    public getShiftsPlanning(): Observable<ShiftPlanning[]> {
        const endpoint = this.nano(`${this.host}${this.endpoints.shiftsPlanning}`, {});
        return this.http.get<ShiftPlanning[]>(endpoint);
    }

    public getOneShiftPlanning(shiftPlanningId: number): Observable<ShiftPlanning> {
        const endpoint = this.nano(`${this.host}${this.endpoints.shiftsPlanning}/${shiftPlanningId}`, {});
        return this.http.get<ShiftPlanning>(endpoint);
    }

    public updateShiftPlanning(formData: ShiftPlanning): Observable<ShiftPlanning> {
        const endpoint = this.nano(`${this.host}${this.endpoints.shiftsPlanningUpdate}/${formData['id']}`, {});
        return this.http.put<ShiftPlanning>(endpoint, formData);
    }

    public createShiftPlanning(formData: ShiftPlanning): Observable<ShiftPlanning> {
        const endpoint = this.nano(`${this.host}${this.endpoints.shiftsPlanningCreate}`, {});
        return this.http.post<ShiftPlanning>(endpoint, formData);
    }

    public deleteShiftPlanning(id: string): Observable<ShiftPlanning> {
        const endpoint = this.nano(`${this.host}${this.endpoints.shiftsPlanning}/${id}`, {});
        return this.http.delete<ShiftPlanning>(endpoint, this.utilsService.httpHeaders());
    }

    public nano(template, data) {
        return template.replace(/{([\w.]*)}/g, (str, key) => {
            const keys = key.split('.');
            let v = data[keys.shift()];
            for (let i = 0, l = keys.length; i < l; i++) {
                v = v[keys[i]];
            }
            return typeof v !== 'undefined' && v !== null ? v : '';
        });
    }
}
