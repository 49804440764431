import { Injectable } from '@angular/core';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { Store } from '@entities/store';
import { Observable } from 'rxjs';
import { ShiftStatus } from '@enums/enum';

const ERR_NOT_INITIALIZED = 'Please call create() method first!';

@Injectable({
  providedIn: 'root'
})
export class ShiftPlanningWizardService {

  private store: Store<ShiftPlanning>;

  public init: Object = {
    "id": 1,
    "date": "1970-01-01",
    "warehouse": 0,
    "shiftName": "",
    "defaultSettings": {
      "version": 2,
      "shiftStatusData": {
        "version": 2,
        "status": ShiftStatus.PLANNING,
      },
      "shiftFulfillmentData": { 
        "version": 2,
        "label": "",
        "fleet": [],
        "startTime": "06:00:00",
        "endTime": "14:00:00",
        "shiftDateOffset": 0,
        "maximumDeliveries": 0,
        "maximumProductLines": -1,
        "shiftStartMarginMinutes": 35,
        "shiftEndMarginMinutes": 20,
        "reloadTimeMinutes": 45,
        "departureWaveInitialSize": 16,
        "departureWaveInterval": 900,
        "departureWaveSize": 8,
        "deliveryTime": {
          "parkingTime": 120,
          "parkingTimeThreshold": 5,
          "initialTime": 300,
          "boxGroupTime": 240,
          "boxGroupSize": 5,
          "paymentTime": 240
        }
      },
      "shiftRoutingData": {
        "version": 2,
        "loadFactor": 0.95,
        "includeReturnInPlanning": false,
        "slotLimits": {},
        "routeStrategy": "MINIMAL_COST",
        "expensiveDeliveryThreshold": 1800,
        "slotEndMarginMinutes": 0,
        "slotStartMarginMinutes": 0,
        "routeMaximumReloads": 0,
        "shiftMaximumSegments": 90,
        "routeMargin": 0,
        "planningSequence": "00000000-0000-0000-0002-000000000000",
        "expensiveDeliveryThresholdTime": 0,
        "maxCostFactor": 0,
        "vanLimits": [],
        "automaticCutoff": true,
        "removePlanning": true,
        "reloadPercentage": 0,
        "shiftplanType": "CENTRAL",
        "autoGenerateSolutions": true
      },
      "shiftIntegrationData": {
        "version": 2,
        "routeNumberOffset": 0,
        "segmentNumberOffset": 100,
        "slotOverLapping": 60,
        "slotDuration": 60,
        "offset": -1,
        "time": 15,
        "bookingWindowRestricted": false,
        "bookingWindowDayOffset": -28,
        "bookingWindowTimeOfDay": 0,
        "cutoffTimeout": 900,
        "syncDelay": 180,
        "autoPromoteSolution": false,
        "autoFinalizeSolution": false,
        "fulfillmentIntegrationEnabled": true,
      },
      "shiftId": {},
      "shiftCutoffData": [
        {
          "version": 0,
          "id": 0,
          "status": "OPEN",
          "maximumDeliveries": 0,
          "maximumProductLines": 0,
          "offsetDays": 0,
          "time": "12:00:00",
          "cutoffTimeout": 0,
          "autoGenerateSolutions": true,
          "autoPromoteSolution": true,
          "autoFinalizeSolution": true,
          "modifiedAt": ""
        }
      ]
    },
    
    "autoCreateDays": -1,
    "doWFilter": {
      "mon": false,
      "tue": false,
      "wed": false,
      "thu": false,
      "fri": false,
      "sat": false,
      "sun": false
    },
    "enabled": false,
    "version": 1
  }

  public create(initialModel: ShiftPlanning | undefined): void {
    const model: ShiftPlanning = initialModel ? initialModel : this.init as ShiftPlanning;
    this.store = new Store<ShiftPlanning>(model);
  }

  public getModel(): Observable<ShiftPlanning> {
    if (!this.store) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    return this.store.state$;
  }

  public getModelState(): ShiftPlanning {
    if (!this.store) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    return this.store.state;
  }

  public updateModel(partial: Partial<ShiftPlanning>): ShiftPlanning {
    const current = this.store.state;
    this.store.setState({
      ...current,
      ...partial
    } as ShiftPlanning);

    console.log('UPDATE: ', this.store.state);

    return this.store.state;
  }

  public modelHasDefinedProperties(propertiesMap: string[]): boolean {
    if (!this.store) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    const model = this.store.state;
    return propertiesMap.every(prop => (model as any)[prop] !== undefined);
  }

  public destroy() {
    if (this.store) {
      this.store.destroy();
      delete (this.store);
    }
  }
}
