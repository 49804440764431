import { Component, OnInit } from '@angular/core';
import { ShiftPlanningWizardService } from '@calendar/services/shift-planning-wizard.service';
import { Observable } from 'rxjs';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { Form, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap, switchMap, map } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { Depot } from '@interfaces/depot.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { NUMERIC_PATTREN } from '@shared/constants/utils.constants';
import { DepotService } from 'src/app/locations/services/depot.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-shift-planning-edit-basic',
  templateUrl: './shift-planning-edit-basic.component.html',
  styleUrls: ['./shift-planning-edit-basic.component.scss']
})
export class ShiftPlanningEditBasicComponent implements OnInit {

  public shiftPlanning$: Observable<ShiftPlanning>;
  public depot$: Observable<Depot[]>;
  public selectedDepot$: Observable<string>;

  public form: FormGroup;
  public autoCreateDaysCheckbox: boolean = true;
  public mode: string;


  public regularFormItems: string[] = ['shiftName', 'warehouse', 'enabled', 'autoCreateDays'];
  public weekDaysControls: string[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  
  constructor(
    private readonly shiftPlanningWizardService: ShiftPlanningWizardService,
    private readonly formBuilder: FormBuilder,
    private readonly appServie: AppService,
    private readonly navigationRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly depotService: DepotService,
    private readonly utilsService: UtilsService
  ) { }

  public ngOnInit() {

    this.mode = this.utilsService.findUpRouteParams(this.navigationRoute.snapshot, 'action')

    this.shiftPlanning$ = this.shiftPlanningWizardService.getModel().pipe(
      tap((shiftPlanning: ShiftPlanning) => this.initForm(shiftPlanning))
    );

    this.depot$ = this.depotService.getDepotsForUser();
    this.selectedDepot$ = this.appServie.selectedWarehouse;
  }

  public initForm(shiftPlanning: ShiftPlanning) {
    this.form = this.formBuilder.group({

      shiftName: [null, Validators.required],
      warehouse: [null, Validators.required],
      date: [null, Validators.required],
      enabled: [null, Validators.required],
      version: [ null, Validators.required],
      autoCreateDays: [null, [Validators.required, Validators.pattern(NUMERIC_PATTREN), Validators.min(-1)]],

      doWFilter: this.formBuilder.group({
        mon: [null, Validators.required],
        tue: [null, Validators.required],
        wed: [null, Validators.required],
        thu: [null, Validators.required],
        fri: [null, Validators.required],
        sat: [null, Validators.required],
        sun: [null, Validators.required],
      }),

    });

    this.autoCreateDaysCheckbox = (shiftPlanning && shiftPlanning.autoCreateDays !== -1);
    this.form.patchValue(shiftPlanning);
  }

  public autoCreateDaysCheckboxFun(): void {
    this.autoCreateDaysCheckbox = !this.autoCreateDaysCheckbox;
    if (!this.autoCreateDaysCheckbox) {
      this.form.get('autoCreateDays').setValue(-1);
    } else {
      this.form.get('autoCreateDays').setValue(1);
    }
  }

  public update() {

    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    if (this.form.invalid) {
      return;
    }

    this.shiftPlanningWizardService.updateModel({ 
     ...this.form.getRawValue()
    } as Partial<ShiftPlanning>);

    this.router.navigate(['../step-2'], { relativeTo: this.navigationRoute });

  }
}
