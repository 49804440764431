import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { RouteSummary } from '@entities/route-ext';
import { CurrencyType, ReviewStatus, ReviewStatusOptions } from '@enums/enum';
import { RoutesService } from '@routes/services/routes.service';
import { NavService } from '@services/nav.sevice';

@Component({
    selector: 'payments-shift-list',
    template: `
        <div class="filter-bar" toolbar>
            <input class="form-control form-control-sm" [(ngModel)]="driverName" (change)="filterByDriverName()" placeholder="{{ 'Filter by driver name' | translate }}" />
            <select [(ngModel)]="selectedStatus" class="form-control form-control-xs text-center custom-select" (change)="changeStatus()">
                <option *ngFor="let status of reviewStatusOptions" [value]="status" [selected]="status == reviewStatusOptions.CHOOSE_STATUS">{{ status | translate }}</option>
            </select>
        </div>

        <table class="table table-hover" *ngIf="!loading">
            <thead class="table__head">
                <tr>
                    <th width="5%">
                        <div>{{ 'Route' | translate }}</div>
                    </th>
                    <th width="13%" scope="col">
                        <div>{{ 'Payment Driver' | translate }}</div>
                    </th>
                    <th width="10%" scope="col" class="text-center">
                        <div>{{ 'Amount due' | translate }}</div>
                    </th>
                    <th width="8%" scope="col" class="text-center">
                        <div>{{ 'Collected' | translate }}</div>
                    </th>
                    <th width="8%" scope="col" class="text-center">
                        <div>{{ 'Complaints' | translate }}</div>
                    </th>
                    <th width="8%" scope="col" class="text-center">
                        <div>{{ 'CASH' | translate }}</div>
                    </th>
                    <th width="8%" scope="col" class="text-center">
                        <div>{{ 'Discrepancy' | translate }}</div>
                    </th>
                    <th width="25%" scope="col" class="text-center">
                        {{ 'Comment' | translate }}
                    </th>
                    <th width="15%" class="text-center ">
                        <div>{{ 'Status payment' | translate }}</div>
                    </th>
                </tr>
            </thead>

            <tbody class="table__body" *ngIf="!routesSummary.length">
                <tr>
                    <td class="text-center no-data" colspan="10">
                        <i-feather name="alert-circle"></i-feather>
                        <span class="empty">{{ 'No data to display' | translate }}</span>
                    </td>
                </tr>
            </tbody>

            <tbody class="table__body">
                <ng-container *ngFor="let route of routesSummary; let i = index">
                    <tr class="table__row" [ngClass]="{ 'row-modified': expandRow[i] }">
                        <td (click)="expandRowToggle($event, i)">
                            <div class="datatable-number">
                                <span *ngIf="!expandRow[i]" class="datatable-icon datatable-icon-right"></span>
                                <span *ngIf="expandRow[i]" class="datatable-icon datatable-icon-down"></span>
                                <span>{{ route.routeNumber }}</span>
                            </div>
                        </td>
                        <td (click)="expandRowToggle($event, i)">{{ route.driver.driverName }}</td>
                        <td class="text-center" (click)="expandRowToggle($event, i)">
                            <strong>{{ route.amountDue | number: '1.2-2' }} {{ CurrencyType.PLN }}</strong>
                        </td>
                        <td class="text-center" (click)="expandRowToggle($event, i)">{{ route.totalPayments | number: '1.2-2' }} {{ CurrencyType.PLN }}</td>
                        <td class="text-center" (click)="expandRowToggle($event, i)">{{ route.totalComplaints | number: '1.2-2' }} {{ CurrencyType.PLN }}</td>
                        <td class="text-center" [style.color]="route.cashPayments ? '#9400d3' : ''" (click)="expandRowToggle($event, i)">
                            {{ route.cashPayments | number: '1.2-2' }} {{ CurrencyType.PLN }}
                        </td>
                        <td class="text-center discrepancy" (click)="expandRowToggle($event, i)">
                            <span class="green" *ngIf="hasDiscrepancy(route) == 0 && route.totalPayments"> <i class="fa fa-check-circle"></i></span>
                            <span class="red" *ngIf="hasDiscrepancy(route) < 0 && route.totalPayments">
                                <i class="fa fa-times-circle"></i> {{ hasDiscrepancy(route) | number: '1.2-2' }}{{ CurrencyType.PLN }}
                            </span>
                            <span class="orange" *ngIf="hasDiscrepancy(route) > 0 && route.totalPayments">
                                <i class="fa fa-times-circle"></i> <span class="orange">+{{ hasDiscrepancy(route) | number: '1.2-2' }}{{ CurrencyType.PLN }}</span>
                            </span>
                        </td>
                        <td class="text-left" (click)="expandRowToggle($event, i)">
                            <span class="review-comment" (click)="expandRowToggle($event, i)">{{ route.reviewComment }}</span>
                        </td>
                        <td>
                            <payments-form
                                [data]="[route.id, route.reviewStatus, route.cashPayments, route.couponPayments, route.cardPayments, route.reviewComment]"
                                (commentChanged)="commentChanged($event, route)"
                            >
                            </payments-form>
                        </td>
                    </tr>

                    <tr class="table__row table__row--secondary" *ngIf="expandRow[i]">
                        <td colspan="10">
                            <payments-details [routeSummary]="route" [routeId]="route.id"></payments-details>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <div *ngIf="loading" class="load-spinner spinner">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
            <p>{{ 'Please, wait!' | translate }}</p>
        </div>

        <div *ngIf="error" class="load-spinner spinner error">
            <i class="no-animate fas fa-exclamation-triangle"></i>
            <p>{{ 'Error occurred, please try again!' | translate }}</p>
        </div>
    `,
    styleUrls: ['payments-shift-list.component.scss']
})
export class PaymentsShiftListComponent implements OnInit, OnDestroy {
    private selectedRoutesSummaryLoaderSub: Subscription;
    private selectedRoutesSummaryErrorSub: Subscription;
    private selectedRoutesSummarySub: Subscription;
    private routeQueryParamsSub: Subscription;

    protected routesSummary: RouteSummary[];
    protected routesSummaryAll: RouteSummary[];
    protected modifiedRouteId = '';

    public expandRow: Array<boolean> = [];
    public selectedStatus: ReviewStatus | string = ReviewStatus.CHOOSE_STATUS;
    public driverName: string;
    public loading;
    public error = false;

    public CurrencyType = CurrencyType;

    constructor(private navService: NavService, private navigationRoute: ActivatedRoute, private routeService: RoutesService) {}

    public ngOnInit() {
        this.selectedRoutesSummaryLoaderSub = this.routeService.selectedRoutesSummaryLoader
            .pipe(
                map((loader: boolean) => {
                    this.loading = loader;
                })
            )
            .subscribe();

        this.selectedRoutesSummaryErrorSub = this.routeService.selectedRoutesSummaryError
            .pipe(
                map((error: boolean) => {
                    this.error = error;
                })
            )
            .subscribe();

        this.selectedRoutesSummarySub = this.routeService.selectedRoutesSummary
            .pipe(
                map((routes: RouteSummary[]) => {
                    this.routesSummary = this.routesSummaryAll = routes;
                    this.expandRow = [];
                    this.expandRow.length = this.routesSummaryAll.length + 1;
                })
            )
            .subscribe();

        this.routeQueryParamsSub = this.routeQueryParamsSub = this.navigationRoute.params.subscribe(params => {
            this.modifiedRouteId = _.get(params, 'routeId', '');
        });
    }

    public ngOnDestroy(): void {
        if (this.selectedRoutesSummaryLoaderSub) {
            this.selectedRoutesSummaryLoaderSub.unsubscribe();
        }
        if (this.selectedRoutesSummaryErrorSub) {
            this.selectedRoutesSummaryErrorSub.unsubscribe();
        }
        if (this.selectedRoutesSummarySub) {
            this.selectedRoutesSummarySub.unsubscribe();
        }
        if (this.routeQueryParamsSub) {
            this.routeQueryParamsSub.unsubscribe();
        }
    }

    get reviewStatusOptions() {
        return ReviewStatusOptions;
    }

    public changeStatus(): void {
        if (this.selectedStatus !== ReviewStatus.CHOOSE_STATUS) {
            this.routesSummary = this.routesSummaryAll.filter((route: RouteSummary) => route.reviewStatus === this.selectedStatus);
        } else {
            this.routesSummary = this.routesSummaryAll;
        }
    }

    public filterByDriverName(): void {
        this.routesSummary = this.routesSummaryAll.filter((route: RouteSummary) =>
            route.driver
                .driverName
                .toLowerCase()
                .includes(this.driverName.toLowerCase())
        );
    }

    public commentChanged(event, route): void {
        route.reviewComment = event.comment;
        route.reviewStatus = event.status;
    }

    public hasDiscrepancy(route: RouteSummary) {
        return Math.round((route.totalPayments - route.amountDue + route.totalComplaints) * 100) / 100;
    }

    public expandRowToggle(event, index): void {
        this.expandRow.forEach((e, i: number) => (i !== index ? (this.expandRow[i] = false) : null));
        const isFormEvent = _.find(event.path, n => n.nodeName === 'PAYMENTS-FORM');
        if (_.isUndefined(isFormEvent)) {
            this.expandRow[index] = !this.expandRow[index];
        }
    }
}
