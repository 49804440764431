import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { plainToClass } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UtilsService } from '@services/utils.service';

import { Dataset } from '../entities/dataset';
import { ExecuteSimulation } from '../entities/execute';
import { Finalize } from '../entities/finalize';
import { Simulation } from '../entities/simulation';
import { environment } from '@environment';

@Injectable()
export class SimulatorService {
    public static DATASET: string = 'dataset/v1';
    public static REMOVE_DATASET: string = 'dataset/v1/${datasetId}';
    public static SIMULATION: string = 'simulation/v1';
    public static GET_SIMULATIONFOR_DATASET: string = 'dataset/v1/${datasetId}/simulations';
    public static GET_SIMULATION: string = 'simulation/v1/${simulationId}';
    public static EXECUTE: string = 'simulation/v1/${simulationId}/execute';
    public static RESULTS: string = 'simulation/v1/${simulationId}/results';
    public static UPDATE_SIMULATION_CONFIGURATION: string = 'simulation/v2/${simulationId}/configure';
    public static RENAME_DATASET: string = 'dataset/v1/${datasetId}/rename';

    private simulationResultsSource: Subject<Finalize[]> = new Subject<Finalize[]>();

    public simulationResults: Observable<Finalize[]> = this.simulationResultsSource.asObservable();

    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    constructor(private http: HttpClient, private translate: TranslateService, private notifierService: NotifierService, private utilsService: UtilsService) {}

    public getDatasets(): Observable<Dataset[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.DATASET}`, {});
        return this.http.get(endpoint, this.utilsService.httpHeaders()).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(response => plainToClass(Dataset, response as Dataset[]))
        );
    }

    public createDataset(rawDatasetConfig): Observable<Dataset> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.DATASET}`, {});
        return this.http.post(endpoint, rawDatasetConfig).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(response => plainToClass(Dataset, response))
        );
    }

    public createSimulation(rawDatasetConfig): Observable<Simulation> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.SIMULATION}`, {});
        return this.http.post(endpoint, rawDatasetConfig).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(response => plainToClass(Simulation, response))
        );
    }

    public updateSimulation(simulation: Simulation): Observable<Simulation> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.UPDATE_SIMULATION_CONFIGURATION}`, { simulationId: simulation.simulationId });
        return this.http.put(endpoint, simulation.simulationShiftConfigDto).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(response => plainToClass(Simulation, response))
        );
    }

    public removeDataset(datasetId: string): Observable<Dataset> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.REMOVE_DATASET}`, { datasetId: datasetId });
        return this.http.delete(endpoint).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(response => plainToClass(Dataset, response))
        );
    }

    public getSimulationForDataset(datasetId: number): Observable<Simulation[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.GET_SIMULATIONFOR_DATASET}`, { datasetId: datasetId });
        return this.http.get(endpoint).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(res => plainToClass(Simulation, res as Simulation[]))
        );
    }

    public getSimulation(simulationId: string): Observable<Simulation> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.GET_SIMULATION}`, { simulationId: simulationId });
        return this.http.get(endpoint).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(res => plainToClass(Simulation, res as Simulation))
        );
    }

    public execute(simulationId: string, simulationExecute: ExecuteSimulation, shiftId: string): Observable<ExecuteSimulation> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.EXECUTE}`, { simulationId: simulationId });

        return this.http.post(endpoint, simulationExecute, this.utilsService.httpHeaders()).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(res => plainToClass(ExecuteSimulation, res as ExecuteSimulation)),
            map((results: ExecuteSimulation) => {
                this.updateResults(simulationId, shiftId);
                return results;
            })
        );
    }

    public updateResults(simulationId: string, shiftId: string) {
        this.getResults(simulationId).subscribe();
    }

    public getResults(simulationId: string): Observable<Finalize[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.RESULTS}`, { simulationId: simulationId });
        return this.http.get(endpoint).pipe(
            catchError((error: HttpErrorResponse) => {
                throw error;
            }),
            map(res => plainToClass(Finalize, res as Finalize[])),
            map((results: Finalize[]) => {
                this.simulationResultsSource.next(results);
                return results;
            })
        );
    }

    public renameDataset(datasetId: string, datasetName: string) {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${SimulatorService.RENAME_DATASET}`, { datasetId });
        return this.http.post(endpoint, datasetName, this.utilsService.httpHeaders()).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    this.notifierService.notify('error', this.translate.instant('Error occurred, please try again!'));
                }
                throw error;
            }),
            map((dataset: Dataset) => dataset)
        );
    }
}
