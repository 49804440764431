
        <div class="{{clazz}}"
             [class.folded]="isFolded && !showFoldedTitle"
             [class.foldedWithoutTitle]="isFolded && showFoldedTitle">
            <div class="titleContainer">
                <ng-content select=".title"></ng-content>

                <div class="actions clickable">
                    <!--*ngIf="toggleIcon"-->
                    <i (click)="toggleFold($event)" class="fa" [class.fa-chevron-circle-left]="isFolded" [class.fa-chevron-circle-down]="!isFolded" aria-hidden="true"></i>
                </div>
            </div>

            <div class="contentContainer">
                <ng-content select=".content"></ng-content>
            </div>

            <div class="buttonContainer">
                <ng-content select=".buttons"></ng-content>
            </div>
        </div>
    