import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { OptimizeRoutes } from '../entities/optimize-routes';
import { secToHours } from '@shared/functions/secToHours.function';
import { RouteExt } from '@entities/route-ext';

@Pipe({
    name: 'drivingTimePerRoutesPipe',
    pure: true
})
export class DrivingTimePerRoutesPipe implements PipeTransform {

        public transform(optimizeRoute: OptimizeRoutes): string {
            const routes: number = optimizeRoute.routes.filter((r: RouteExt) => r.deliveriesCount).length;
            return optimizeRoute.totalDrivingTime ? secToHours(optimizeRoute.totalDrivingTime / routes) : "0";
    }
}
