import { Component, Input, OnInit } from '@angular/core';

import { ChartModel } from '../../interfaces/chart.interface';

@Component({
    selector: 'driver-printable-report',
    template: `
        <div class="visible-print-block" *ngIf="data.length">
            <!--<h1>{{'Departure Sheet' | translate}} {{routeParams.y}}:{{routeParams.m}}:{{routeParams.d}}:{{routeParams.shiftId}} </h1>-->
            <table class="table table-striped">
                <thead>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'" [style.width.vh]="30">{{ 'Driver Name' | translate }}</td>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'">{{ 'Nationality' | translate }}</td>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'">{{ 'Total delivered' | translate }}</td>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'">{{ 'Successful delivered' | translate }}</td>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'">{{ 'Early delivered' | translate }}</td>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'">{{ 'Late delivered' | translate }}</td>
                    <td [style.text-align]="'center'" [style.font-weight]="'bold'">{{ 'Failure delivered' | translate }}</td>
                </thead>

                <tbody>
                    <tr *ngFor="let report of data">
                        <td [style.text-align]="'left'" [style.font-weight]="'bold'">
                            {{ report.name }}
                        </td>
                        <td [style.text-align]="'center'">
                            <span>{{ report.driver.nationality | translate }}</span>
                        </td>
                        <td [style.text-align]="'center'">{{ report.punctuality.total }}</td>
                        <td [style.text-align]="'center'">{{ report.punctuality.completeOnSchedule }} {{ calc(report.punctuality.total, report.punctuality.completeOnSchedule) }}</td>
                        <td [style.text-align]="'center'">{{ report.punctuality.completeBeforeSchedule }} {{ calc(report.punctuality.total, report.punctuality.completeBeforeSchedule) }}</td>
                        <td [style.text-align]="'center'">{{ report.punctuality.completeAfterSchedule }} {{ calc(report.punctuality.total, report.punctuality.completeAfterSchedule) }}</td>
                        <td [style.text-align]="'center'">{{ report.punctuality.incompleteBeforeSchedule +  report.punctuality.incompleteAfterSchedule }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    `,
    styleUrls: ['./driver-printable-report.component.scss']
})
export class DriverPrintableReportComponent {
    private TAG = '[DriverPrintableReportComponent]';
    public loading: boolean = false;

    @Input() public data: any[];

    constructor() {}

    public calc(total, delivered): string {
        return `(${(((100 * delivered) / total) || 0).toFixed(2)}%)`;
    }

    public print(): void {
        window.print();
    }
}
