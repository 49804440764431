import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ShiftsService } from '@services/shifts.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '@services/nav.sevice';
import { NotifierService } from 'angular-notifier';
import { tap, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppService } from '@services/app.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-shift-planning',
  templateUrl: './shift-planning.component.html',
  styleUrls: ['./shift-planning.component.scss']
})
export class ShiftPlanningComponent implements OnInit, OnDestroy {

  public shiftsPlanning$: Observable<ShiftPlanning[]>;
  public shiftsPlanningCollection: ShiftPlanning[];
  private shiftsPlanningCopy: ShiftPlanning[];

  public modifiedRow: string;
  private selectedWarehouse$: Subscription;
  public defaultShiftPlanningId;

  public tableMessages = {
    emptyMessage: this.translateService.instant('No data to display')
  };

  loader = false;

  @ViewChild('confirmation', {static: true}) public confirmation: TemplateRef<any>;
  public modalRef: BsModalRef;
  public confirmObservable: Observable<any>;

  public rowClass;

  public columns = [
    { prop: 'id', name: this.translateService.instant('ID'), flexGrow: 1 },
    { prop: 'shiftName', name: this.translateService.instant('Shift name'), flexGrow: 3 },
    { prop: 'warehouse', name: this.translateService.instant('Warehouse'), flexGrow: 2 },
    { prop: 'offset', name: this.translateService.instant('shiftProperty.routeNumberOffset'), flexGrow: 3 },
    { prop: 'autoCreateDays', name: this.translateService.instant('Auto create days'), flexGrow: 3 },
    { prop: 'enabled', name: this.translateService.instant('Enabled'), flexGrow: 2 },
    { prop: 'options', name: this.translateService.instant('Options'), flexGrow: 1 },
  ];

  constructor(
    private readonly shiftService: ShiftsService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly navService: NavService,
    private modalService: BsModalService,
    private notifierService: NotifierService,
    private appService: AppService
  ) { }

  public ngOnInit() {

    this.shiftsPlanning$ = this.shiftService.getShiftsPlanning().pipe(
      map((planning: ShiftPlanning[]) => planning.filter(planning => planning.warehouse.toString() === localStorage.getItem('depot'))),
      tap(planning => this.shiftsPlanningCollection = this.shiftsPlanningCopy = planning),
    )

    this.modifiedRow = this.route.snapshot.params.modifiedRow;
    this.rowClass = row => {
      return { 'row-modified': row.id == this.modifiedRow };
    };


    this.selectedWarehouse$ = this.appService.selectedWarehouse.subscribe(
      () => {
        this.loader = true;
        setTimeout(() => this.loader = false, 200)
      }
    )
  }

  public goToDetails(id: string): void {
    this.navService.goToPage(`/calendar/shift-planning/edit/${id}`);
  }

  public isOptionsColumn(status: string): boolean {
    return status === 'options';
  }

  public isColumnToTranslate(status: string): boolean {
    return status === 'type' || status === 'status';
  }

  public isColumnScope(status: string): boolean {
    return status === 'scope';
  }

  public isColumnPartner(status: string): boolean {
    return status === 'partnerId';
  }

  public isColumnDefault(status: string): boolean {
    return status === 'isDefault';
  }

  public removeShiftPlanning(id: string): void {
    this.modalRef = this.modalService.show(this.confirmation, {class: 'modal-sm'});
    this.confirmObservable = this.shiftService.deleteShiftPlanning(id);
  }

  public applyFiltering(event) {
    const filterPhrase = event.target.value.toLowerCase();
    const temp = _.filter(this.shiftsPlanningCopy, (shift: ShiftPlanning) => {
      const searchIn: string = shift.shiftName;
      return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
    });


    this.shiftsPlanningCollection = temp;
  }

  public confirm(): void {
    this.confirmObservable.subscribe((test) => {
      this.modalRef.hide();
      this.notifierService.notify('success', this.translateService.instant('The shift planning has been removed!'));
      this.confirmObservable = null;
      this.loader = true;
      setTimeout(() => this.loader = false, 500);
    });
  }

  public decline(): void {
    this.confirmObservable = null;
    this.modalRef.hide();
  }

  ngOnDestroy() {
    if (this.selectedWarehouse$) {
      this.selectedWarehouse$.unsubscribe();
    }
  }
}
