import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Activity } from '@entities/activity';

@Pipe({
    name: 'findWaitingTimePipe',
    pure: true
})
export class FindWaitingTimePipe implements PipeTransform {
    public transform(activities: Activity[], index: number): number {
        if (index === 0 || !activities[index].planning || !activities[index - 1].planning) {
            return 0;
        }
        return moment(`${activities[index].planning.from}`).diff(moment(`${activities[index - 1].planning.to}`), 'seconds');
    }
}
