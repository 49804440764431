import * as _ from 'lodash';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

/**
 * Street address
 */
export class Address extends Entity implements Serializable<Address> {
    public town: string = '';
    public zip: string = '';
    public district: string = '';
    public voivoidship: string = '';
    public country: string = '';
    public street: string = '';
    public houseNO: string = '';

    public isEmpty() {
        return _.values(this.serialize()).join('').length === 0;
    }

    /**
     * Return formated Raw Address
     */
    public get raw() {
        const dv = '';
        const streetHouse = `${this.street || dv} ${this.houseNO || dv}`.trim();
        const townZip = `${this.town || dv} ${this.zip || dv}`.trim();

        let raw = '';

        raw += streetHouse.length ? `${streetHouse}, ` : '';
        raw += townZip.length ? `${townZip} ` : '';
        return raw;
    }

    public getStreetHouse(): string {
        const streetHouse = `${this.street || ''} ${this.houseNO || ''}`.trim();
        return streetHouse.length ? `${streetHouse}` : '';
    }

    public getTownZip(): string {
        let townZip = `${this.town || ''}`;
        townZip += this.district && this.district.length > 0 ? ` ${this.district.trim()}` : '';
        townZip += this.zip.length && this.zip.length > 0 ? ` ${this.zip.trim()}` : '';
        return townZip.length ? `${townZip}` : '';
    }

    /**
     * Conversion from simple object
     * @param data
     */
    public deserialize(data: any) {
        this.town = _.get(data, 'town', '');
        this.zip = _.get(data, 'zip', '');
        this.district = _.get(data, 'district', '');
        this.voivoidship = _.get(data, 'voivodeship', '');
        this.country = _.get(data, 'country');
        this.street = _.get(data, 'street', '');
        this.houseNO = _.get(data, 'houseNO', '');

        return this;
    }

    /**
     * Conversion to simple object
     */
    public serialize() {
        return {
            town: this.town,
            zip: this.zip,
            district: this.district,
            voivodeship: this.voivoidship,
            country: this.country,
            street: this.street,
            houseNO: this.houseNO,
        };
    }
}
