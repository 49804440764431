import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftCutoffData } from '@calendar/interafces/shift-cutoff-data.interface';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { ShiftUpdateDto } from '@calendar/interafces/shift-update-dto.interface';
import { ShiftPlanningWizardService } from '@calendar/services/shift-planning-wizard.service';
import { ShiftStatus } from '@enums/enum';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-shit-planning-edit-cutoff',
  templateUrl: './shit-planning-edit-cutoff.component.html',
  styleUrls: ['./shit-planning-edit-cutoff.component.scss']
})
export class ShitPlanningEditCutoffComponent implements OnInit {

  public shiftCutoffData$: Observable<ShiftCutoffData[]>;
  private shiftDefaultSettings: ShiftUpdateDto; 
  private shift;
  public statusType = ShiftStatus;
  public maxProductLinesCheckbox = [];

  form: FormGroup;

  public cutoffBooleanControls: string[] = ['autoGenerateSolutions', 'autoPromoteSolution', 'autoFinalizeSolution'];
  public cutoffControls: string[] = ['status', 'time', 'offsetDays', 'cutoffTimeout'];
  public deliveryLimits: string[] = [ 'maximumDeliveries'];


  get formShiftCutoffData() {
    return this.form.get('shiftCutoffData') as FormArray;
  }

  constructor(    
    private readonly shiftPlanningWizardService: ShiftPlanningWizardService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly navigationRoute: ActivatedRoute,

    ) {}

  ngOnInit() {

    this.shiftCutoffData$ = this.shiftPlanningWizardService.getModel().pipe(
      tap((shiftPlanning: ShiftPlanning) => this.shiftDefaultSettings = shiftPlanning.defaultSettings),
      tap((shiftPlanning: ShiftPlanning) => this.shift = shiftPlanning),
      map((shiftPlanning: ShiftPlanning) => shiftPlanning.defaultSettings.shiftCutoffData),
      tap((cutoffs: ShiftCutoffData[]) => this.initForm(cutoffs))
    );

    this.form = this.fb.group({
      shiftCutoffData: this.fb.array([])
    });
   
  }

  public initForm(shiftCutoffData: ShiftCutoffData[]) {
    shiftCutoffData.forEach(elem => this.addNewCutoffElement(elem));
  }

  addNewCutoffElement(shiftCutoffData?: ShiftCutoffData) {
    const itemForm = this.fb.group({
      id: [],
      status: ['OPEN', Validators.required],
      overbooked: [false, Validators.required],
      maximumDeliveries: [0, Validators.required],
      maximumProductLines: [0, Validators.required],
      maximumRoutes: [-1, Validators.required],
      offsetDays: [0, Validators.required],
      time: ['12:00:00', [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]], 
      cutoffTimeout: [0, Validators.required],
      autoGenerateSolutions: [true, Validators.required],
      autoPromoteSolution: [true, Validators.required],
      autoFinalizeSolution: [true, Validators.required]
    });

    if (shiftCutoffData) {
      itemForm.patchValue(shiftCutoffData);
    }

    this.formShiftCutoffData.push(itemForm);
  }

  deleteCutoff(index: number) {
    this.formShiftCutoffData.removeAt(index);
  }

  maxProductLinesCheckboxFun(index, event) {
    const control = (this.form.get('shiftCutoffData') as FormArray).controls[index].get('maximumProductLines');
    if (control.value > 0) {
      control.setValue(-1);
    } else {
      control.setValue(1);
    }
  }

  maximumDeliveriesCheckboxFun(index) {
    const control = (this.form.get('shiftCutoffData') as FormArray).controls[index].get('maximumDeliveries');
    if (control.value > 0) {
      control.setValue(-1);
    } else {
      control.setValue(1);
    }
  }

  maximumRoutesCheckboxFun(index) {
    console.log(this.form);
    const control = (this.form.get('shiftCutoffData') as FormArray).controls[index].get('maximumRoutes');
    if (control.value > 0) {
      control.setValue(-1);
    } else {
      control.setValue(1);
    }
  }

  public update() {
    Object.values(this.form.controls).forEach(element => {
      element.markAsDirty();
      element.markAsTouched();
      element.updateValueAndValidity();
    });

    if (this.form.invalid) {      
      return;
    }

    if (this.form.valid) {

      this.shiftPlanningWizardService.updateModel({
        defaultSettings: {
          shiftFulfillmentData: this.shiftDefaultSettings.shiftFulfillmentData,
          shiftIntegrationData: this.shiftDefaultSettings.shiftIntegrationData,
          shiftRoutingData: this.shiftDefaultSettings.shiftRoutingData,
          shiftCutoffData: this.form.getRawValue()['shiftCutoffData'] as ShiftCutoffData[],
          shiftStatusData: this.shiftDefaultSettings.shiftStatusData,
          shiftId: this.shiftDefaultSettings.shiftId,
          version: this.shiftDefaultSettings.version
        } as Partial<ShiftUpdateDto>
      } as Partial<ShiftPlanning>);

      this.router.navigate(['../step-5'], { relativeTo: this.navigationRoute });
    } else {
      console.log(this.form.errors);
    }
  }

}
