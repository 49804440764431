import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { DriverPlanningMode, DriverStatus, ShiftType } from '@enums/enum';
import { AppService } from '@services/app.service';
import { DriversMode } from '@shared/enums/enum';

import { AvailabilityRange, ShiftConfigWithStatuses, ShiftSummaryList } from '../entities/availability-range';
import { Driver } from '../entities/driver';
import { DriverAvailability } from '../entities/driver-availability';
import { ShiftSummary } from '../entities/shift-summary';
import { DriversService } from '../services/drivers.service';

@Component({
    selector: 'app-drivers-availability',
    templateUrl: './drivers-availability.component.html',
    styleUrls: ['./drivers-availability.component.scss']
})
export class DriversAvailabilityComponent implements OnInit, OnDestroy {
    public TAG = '[DriversAvailabilityComponent]';

    constructor(
        private translate: TranslateService, 
        private driverService: DriversService, 
        private appService: AppService, 
        private router: Router) {}

    get driversPlanningMode() {
        return _.keys(DriverPlanningMode);
    }

    public locale = '';
    public ShiftType = ShiftType;
    public startDate;
    public endDate;

    public driversPlanningModeValue: DriverPlanningMode = DriverPlanningMode.REGULAR;

    public driversAvailability: AvailabilityRange = null;
    public monthSchedule: AvailabilityRange = null;

    public drivers: Driver[] = [];
    public driversCollection: Driver[] = [];

    private drivers$: Subscription;

    public warehouse;
    private selectedWarehouse$: Subscription;

    private mondayDate = moment().startOf('week')
    private currentMonth = moment().startOf('month');

    public daysInMonth = [];

    public selectedDriver: Driver;
    public showMonthlySchedule: boolean = false;

    public loading = false;

    public ngOnInit() {
        this.translate.onLangChange.subscribe((lang: LangChangeEvent) => {
            this.locale = lang.lang;
        });

        this.drivers$ = this.driverService.drivers.subscribe((drivers: Driver[]) => {
            this.drivers = drivers.filter((d: Driver) => d.status === DriverStatus.ACTIVE);
            this.drivers = _.orderBy(this.drivers, (d: Driver) => d.lastname);
            this.driversCollection = _.cloneDeep(this.drivers);
        });

        this.selectedWarehouse$ = this.appService.selectedWarehouse.subscribe(warehouse => {
            if (warehouse === null) {
                return;
            }

            this.warehouse = warehouse;
            this.driversAvailability = null;
            console.log(`${this.TAG} Changed warehouse location ${warehouse}`);
            this.endDate = moment(this.mondayDate)
                .add(6, 'days')
                .format('YYYY-MM-DD');
            this.startDate = moment(this.mondayDate).format('YYYY-MM-DD');
            this.getDriverAvailabilityRange(this.startDate, this.endDate, warehouse);
        });

        this.calcDaysInMonth();
        this.getMonthSchedule();
    }

    public getDriverAvailabilityRange(startDate: string, endDate: string, warehouse: string) {
        this.loading = true;

        this.driverService.getDriverAvailabilityRange(startDate, endDate, warehouse).subscribe(
            driversAvailability => {
                console.log(`${this.TAG} getDriverAvailabilityRange`, driversAvailability);
                this.driversAvailability = driversAvailability;
            },
            error => {
                console.log(`${this.TAG} getDriverAvailabilityRange - error`, error);
            },
            () => {
                this.loading = false;
                console.log(`${this.TAG} getDriverAvailabilityRange - complete!`);
            }
        );
    }

    public isAvailable(driverId: string, date: string, shiftType: ShiftType) {
        return _.find(this.driversAvailability.availabilityList, o => o.driverId === driverId && o.date === date && o.shift === shiftType);
    }

    public checkAvailabilityByDay(date: string, driver: Driver) {
        const parsedDate = moment(date).format('YYYY-MM-DD');
        const results = _.orderBy(
            _.filter(this.monthSchedule.availabilityList, {
                driverId: driver.id,
                date: parsedDate
            }).map(o => o.shift),
            o => o,
            ['desc']
        );

        return results.length ? results : [];
    }

    public driversNumberPerShift(date: string, type: string): string {
        const o = _.groupBy(_.filter(this.driversAvailability.availabilityList, (t: DriverAvailability) => t.date === date && t.shift === type), (g: DriverAvailability) => g.mode);
        return `${o[DriversMode.REGULAR] ? o[DriversMode.REGULAR].length : 0}, ${o[DriversMode.SERVICE] ? o[DriversMode.SERVICE].length : 0}, ${o[DriversMode.VACATION] ? o[DriversMode.VACATION].length : 0}, ${o[DriversMode.SICKLEAVE] ? o[DriversMode.SICKLEAVE].length : 0}, ${o[DriversMode.SUPPLY] ? o[DriversMode.SUPPLY].length : 0}`;
    }

    public change(driverId: string, date: string, shiftType: ShiftType, warehouse: string) {
        const index = _.findIndex(this.driversAvailability.availabilityList, o => o.driverId === driverId && o.date === date && o.shift === shiftType);
        if (index === -1) {
            this.driverService.saveDriverPlanning(`${date}:${warehouse}:${shiftType}:${driverId}`, this.driversPlanningModeValue).subscribe(res => {
                this.driversAvailability.availabilityList.push(res);
                this.monthSchedule.availabilityList.push(res);
                this.preventDuplicateActiveDrivers();
            });
        } else {
            this.driverService.deleteDriverPlanning(`${date}:${warehouse}:${shiftType}:${driverId}`).subscribe(() => {
                this.driversAvailability.availabilityList.splice(index, 1);

                const index2 = _.findIndex(this.monthSchedule.availabilityList, o => o.driverId === driverId && o.date === date && o.shift === shiftType);
                this.monthSchedule.availabilityList.splice(index2, 1);
            });
        }
    }

    public setShift(driverId: string, shiftType: ShiftType) {
        const warehouse = localStorage.getItem('depot');
        this.driverService.saveDriverPlanningMorningShifts(driverId, shiftType, warehouse, this.startDate).subscribe(response => {
            response.forEach(element => {
                this.driversAvailability.availabilityList.push(element);
                this.monthSchedule.availabilityList.push(element);
                this.preventDuplicateActiveDrivers();
            });
        });
    }

    public showSchedule(index: number) {
        this.driversAvailability = null;
        const warehouse = localStorage.getItem('depot');
        this.endDate = moment(this.endDate)
            .add(index, 'days')
            .format('YYYY-MM-DD');
        this.startDate = moment(this.startDate)
            .add(index, 'days')
            .format('YYYY-MM-DD');
        this.getDriverAvailabilityRange(this.startDate, this.endDate, warehouse);
    }

    public getMonthSchedule() {
        const startDate = this.currentMonth.startOf('month').format('YYYY-MM-DD');
        const endDate = this.currentMonth.endOf('month').format('YYYY-MM-DD');

        this.driverService.getDriverAvailabilityRange(startDate, endDate, this.warehouse).subscribe(
            monthSchedule => {
                console.log(`${this.TAG} got month schedule`, monthSchedule);
                this.monthSchedule = monthSchedule;
            },
            error => {
                console.log(`${this.TAG} got month schedule - error`, error);
            },
            () => {
                console.log(`${this.TAG} got month schedule - complete!`);
            }
        );
    }

    public preventDuplicateActiveDrivers() {
        this.driversAvailability.availabilityList = _.uniqBy(this.driversAvailability.availabilityList, (a: any) => a.id);
        this.monthSchedule.availabilityList = _.uniqBy(this.monthSchedule.availabilityList, (a: any) => a.id);
    }

    public sortDrivers(date: string, shift: ShiftType) {
        this.drivers = _.sortBy(this.drivers, (d: Driver) => !this.isAvailable(d.id, date, shift));
    }

    public applyFiltering(event) {
        const filterPhrase = event.target.value.toLowerCase();
        this.drivers = _.filter(this.driversCollection, (driver: Driver) => {
            const searchIn: string = driver.driverName;
            return searchIn.toLowerCase().indexOf(filterPhrase) !== -1 || !filterPhrase;
        });
    }

    get isBefore(): boolean {
        return !moment(this.startDate).isBefore();
    }

    public showMonthSchedule(driver: Driver) {
        this.selectedDriver = driver;
        this.showMonthlySchedule = true;

        this.getMonthSchedule();

        this.drivers = _.filter(this.driversCollection, (d: Driver) => {
            return d.id === driver.id;
        });
    }

    public monthlyScheduleNextMonth() {
        this.currentMonth = this.currentMonth.add(1, 'month').startOf('month');
        this.calcDaysInMonth();
        this.showMonthSchedule(this.selectedDriver);
    }

    public monthlySchedulePrevMonth() {
        this.currentMonth = this.currentMonth.add(-1, 'month').startOf('month');
        this.calcDaysInMonth();
        this.showMonthSchedule(this.selectedDriver);
    }

    public calcDaysInMonth() {
        this.daysInMonth = [];

        const days = this.currentMonth.daysInMonth();
        const startDate = this.currentMonth;

        for (let i = 0; i < days; i++) {
            this.daysInMonth.push(moment(startDate).add(i, 'days'));
        }
    }

    public removeFilters() {
        this.currentMonth = moment().startOf('month');
        this.drivers = this.driversCollection;
        this.selectedDriver = null;
        this.showMonthlySchedule = false;
    }

    public findShifts(day: string): any {
        const shift: any = _.find(this.monthSchedule.shiftSummaryList, (shiftSummary: ShiftSummaryList) => shiftSummary.date === moment(day).format('YYYY-MM-DD'));
        return !_.isUndefined(shift) ? shift : undefined;
    }

    public formatDate(day: string): any {
        return moment(day).format('YYYY-MM-DD');
    }

    public isAvailable2(driverId: string, date: string, shiftType: ShiftType) {
        return _.findIndex(this.monthSchedule.availabilityList, o => o.driverId === driverId && o.date === date && o.shift === shiftType) !== -1;
    }

    public ngOnDestroy() {
        if (this.drivers$) {
            this.drivers$.unsubscribe();
        }
        if (this.selectedWarehouse$) {
            this.selectedWarehouse$.unsubscribe();
        }
    }
}
