import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';

import { InpostRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestModule } from './test/test.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { OperationsModule } from './operations/operations.module';

const providers = [];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // BrowserModule,
    // BrowserAnimationsModule,
    InpostRoutingModule,
    TestModule,
    // OperationsModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class InpostSharedModule{
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}
