import * as _ from 'lodash';

import { BoxType } from '@enums/enum';

import { Bag } from './bag';
import { Box } from './box';
import { Entity } from './Entity';
import { Item } from './item';
import { Serializable } from './Serializable';
import { TimeWindow } from './time-window';

export class Order extends Entity implements Serializable<Order> {
    public id: string;
    public alcohol: boolean;
    public slot: string;
    public price: string;
    public boxes: Box[];
    public bags: Bag[];
    public items: Item[] = [];
    public notes: string;
    public slotStart: string;
    public slotEnd: string;
    public isComplaint: boolean;
    public extendedWindow: null | TimeWindow;
    public originalWindow: null | TimeWindow;

    public boxesGroupped: {
        [type: string]: Box[];
    } = {};

    public dry: number = 0;
    public chilled: number = 0;
    public frozen: number = 0;

    public get slotShort() {
        if (this.slot) {
            return this.slot.split('-')[0];
        }
        return '-';
    }

    public deserialize(data: any) {
        this.id = _.get(data, 'id');
        this.alcohol = _.get(data, 'alcohol', false);
        this.slot = _.get(data, 'slot', '');

        this.notes = _.get(data, 'notes', '');
        this.price = _.get(data, 'price');
        this.extendedWindow = _.get(data, 'extendedWindow');
        this.originalWindow = _.get(data, 'originalWindow');

        const boxesRaw = _.get(data, 'allBoxes', []);

        this.boxes = [];
        _.forEach(boxesRaw, (boxRaw: any) => {
            const box = new Box().deserialize(boxRaw);
            this.boxes.push(box);

            if (box.type === BoxType.DRY) {
                this.dry += 1;
            } else if (box.type === BoxType.CHILLED) {
                this.chilled += 1;
            } else if (box.type === BoxType.FROZEN) {
                this.frozen += 1;
            }
        });

        this.boxes = _.sortBy(this.boxes, ['id']);

        this.boxesGroupped = _.groupBy(this.boxes, (box: Box) => {
            return box.type;
        });

        this.bags = _.get(data, 'bags', []).map((rawBag) => {
            return new Bag().deserialize(rawBag);
        });

        this.items = _.get(data, 'items', []).map((rawItem) => {
            return new Item().deserialize(rawItem);
        });

        this.isComplaint = this.items.find((i) => i.complaints.length) !== undefined;

        return this;
    }

    public serialize() {
        return {};
    }

    get boxesCountLabel() {
        return `${this.dry}/${this.chilled}/${this.frozen}`;
    }

    get boxesCount() {
        return this.dry + this.chilled + this.frozen;
    }
}
