import { Injectable } from '@angular/core';
import { CrudService } from '@services/crud.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '@services/utils.service';
import { environment } from '@environment';
import { CommentUpdate } from '../interfaces/comment-update.interface';
import { Comment } from '../interfaces/comment.interface';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends CrudService<Comment, number>{

  protected static BASE_API: string = `${environment.api.url}${environment.api.prefix}/comment/v1`;
  private static SEARCH: string = `${environment.api.url}${environment.api.prefix}` + '/comment/v1/search/${commentType}?q=${q}&fullWord=${fullWord}';
  private static APPROVE: string = `${environment.api.url}${environment.api.prefix}` + '/comment/v1/${id}';
  private static APPROVE_DIRECT: string = `${environment.api.url}${environment.api.prefix}` + '/comment/v1/direct';

  
  constructor(
    protected _http: HttpClient,
    protected utilsService: UtilsService) {
    super(_http, CommentService.BASE_API, utilsService);
  }

  public search(q: string, commentType: string, fullWord: boolean) {
    const  endpoint = this.utilsService.interpolate(CommentService.SEARCH, {commentType, q, fullWord});
    return this._http.get(endpoint);
  }

  public approve(commentUpdate: CommentUpdate) {
    const  endpoint = this.utilsService.interpolate(CommentService.APPROVE, {id: commentUpdate.id});
    return this._http.put(endpoint, commentUpdate);
  }

  public approveDirect(commentUpdate: CommentUpdate) {
    const  endpoint = this.utilsService.interpolate(CommentService.APPROVE_DIRECT, {});
    return this._http.put(endpoint, commentUpdate);
  }
}
