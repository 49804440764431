import * as _ from 'lodash';

import { ReviewStatus } from '@enums/enum';

export class PaymentForm {
    public routeId: string;
    public status: ReviewStatus;
    public comment: string;
    public cash: number;
    public coupons: number;
    public card: number;
    public reviewComment: string;
    public amountDue: number;

    public collectedCashByDriver: number;
    public collectedCouponsByDriver: number;

    public parse(data: any): PaymentForm {
        if (data === null) {
            throw new Error('Invalid data');
        }

        this.routeId = _.get(data, 'routeId');
        this.status = _.get(data, 'status');
        this.comment = _.get(data, 'comment');
        this.cash = _.get(data, 'cash');
        this.coupons = _.get(data, 'coupons');
        this.card = _.get(data, 'card');
        this.amountDue = _.get(data, 'amountDue');
        this.reviewComment = _.get(data, 'reviewComment');
        this.collectedCashByDriver = _.get(data, 'collectedCashByDriver');
        this.collectedCouponsByDriver = _.get(data, 'collectedCouponsByDriver');

        if (this.collectedCashByDriver || this.collectedCouponsByDriver) {
            this.comment = this.comment !== null ? `${this.comment} - ` : '';
            this.comment += this.collectedCashByDriver ? `Kierowca zebrał: w gotówce: ${this.collectedCashByDriver} zł. ` : '';
            this.comment += this.collectedCouponsByDriver ? `Kierowca zebrał: w kuponach ${this.collectedCouponsByDriver} zł. ` : '';
        }

        return this;
    }
}
