import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ShiftConfig } from './entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { ShiftSettingsService } from './services/shift-settings.service';
import { Fulfilment } from './interafces/fulfilment.interface';
import { Subscription } from 'rxjs/internal/Subscription';
import * as _ from 'lodash';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { IShiftStatus } from './interafces/shift-status.interface';
import { ShiftStatus } from '@enums/enum';

@Component({
    selector: 'shift-edges',
    template: `
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Shift edges' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]" class="btn btn-xs btn-white" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">

                <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <label>{{ 'Start Time' | translate }}:</label>
                    <span class="modify-value">{{ shiftConfig.shiftId.date + ' ' + shiftConfig.startTime | timeFormatterPipe:'HH:mm' }}</span>
                </div>

                <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <label>{{ 'End Time' | translate }}:</label>
                    <span class="modify-value">{{ shiftConfig.shiftId.date + ' ' + shiftConfig.endTime | timeFormatterPipe:'HH:mm' }}</span>
                </div>
            </div>
        </div>

        <div [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'" *ngIf="mode == 'edit' || mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()" [valid]="validShiftStartAndFinishTime">
                <h3 class="title">{{ 'Shift edges' | translate }}</h3>
                <div>{{ timeToClose }}</div>

                <div class="form">
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>
                    <div class="row">
                        <div class="col-4">
                            <label>{{ 'Start Time' | translate }}:</label>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="startTime.hour">
                                <option *ngFor="let option of cutoffHoursOptionsForShift" value="{{ option.value }}">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="startTime.minutes">
                                <option value="00">00</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <label>{{ 'End Time' | translate }}:</label>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="endTime.hour">
                                <option *ngFor="let option of cutoffHoursOptionsForShift" value="{{ option.value }}">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-4">
                            <select [(ngModel)]="endTime.minutes">
                                <option value="00">00</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                    </div>

                    <p *ngIf="!validShiftStartAndFinishTime" class="alert alert-error">{{ 'Invalid start and finish hours' | translate }}!</p>
                </div>
            </editor-window>
        </div>
    `,
    styles: [
        `
            .alert-error {
                color: $red;
                padding: 2px 0 0 0;
                text-align: right;
            }
        `
    ]
})
export class ShiftEdgesComponent implements OnInit, OnDestroy {

    @Input()
    public shiftConfig: Fulfilment = null;

    @Input()
    public shiftStatusData: IShiftStatus = null;

    @Output()
    public shiftConfigChanged: EventEmitter<ShiftConfig> = new EventEmitter();

    public mode = 'view';

    public editedShiftConfig: Fulfilment = null;
    public cutoffDiffOptions: { value: number; label: string }[] = [];
    public cutoffHoursOptions: { value: string; label: string }[] = [];
    public cutoffMinutesOptions: { value: string; label: string }[] = [];
    public Permissions = Permissions;
    private updateFulfilmentSub$: Subscription;
    public shiftStatus = ShiftStatus;

    public startTime: {
        hour: string;
        minutes: string;
        nano: string;
        second: string;
    } = { hour: '0', minutes: '0', second: '0', nano: '0' };

    public endTime: {
        hour: string;
        minutes: string;
        nano: string;
        second: string;
    } = { hour: '0', minutes: '0', second: '0', nano: '0' };

    public timeToClose = '';

    constructor(
        private readonly shiftSettingsService: ShiftSettingsService) {
    }

    public ngOnInit() {
        [this.startTime.hour, this.startTime.minutes, this.startTime.second] = this.shiftConfig.startTime.split(':');
        [this.endTime.hour, this.endTime.minutes, this.endTime.second] = this.shiftConfig.endTime.split(':');

        for (let i = 0; i < 24; i++) {
            const hour: { value: string; label: string } = {
                value: padNumber(i),
                label: padNumber(i)
            };

            this.cutoffHoursOptions.push(hour);
        }

        for (let i = 0; i < 60; i++) {
            const minute: { value: string; label: string } = {
                value: padNumber(i),
                label: padNumber(i)
            };

            this.cutoffMinutesOptions.push(minute);
        }
    }

    public onModify() {
        this.editedShiftConfig = _.cloneDeep(this.shiftConfig);
        this.mode = 'edit';
    }

    public onCancel() {
        this.editedShiftConfig = null;
        this.mode = 'view';
    }

    get validShiftStartAndFinishTime(): boolean {
        return moment(`2019-01-01 ${this.startTimeLabel}`).isBefore(`2019-01-01 ${this.endTimeLabel}`);
    }

    get startTimeLabel(): string {
        return `${this.startTime.hour}:${this.startTime.minutes}:${this.startTime.second}`;
    }

    get endTimeLabel(): string {
        return `${this.endTime.hour}:${this.endTime.minutes}:${this.endTime.second}`;
    }

    public onSave() {
        this.editedShiftConfig.startTime = this.startTimeLabel;
        this.editedShiftConfig.endTime = this.endTimeLabel;
        this.mode = 'saving';

        this.updateFulfilmentSub$ = this.shiftSettingsService.updateFulfilment(this.editedShiftConfig)
            .subscribe((updatedShiftConfig: ShiftConfig) => {
                this.editedShiftConfig = null;
                this.mode = 'view';
                this.shiftConfigChanged.next(updatedShiftConfig);
            },
            (error: HttpErrorResponse) => {
                this.mode = 'view';
            });
    }

    get cutoffHoursOptionsForShift() {
        return this.cutoffHoursOptions.slice(0, 24);
    }

    ngOnDestroy() {
        if (this.updateFulfilmentSub$) {
            this.updateFulfilmentSub$.unsubscribe();
        }
    }
}

function padNumber(n) {
    return n > 9 ? '' + n : '0' + n;
}
