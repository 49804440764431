import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentsComponent } from './components/payments.component';
import { ProxyComponent } from '@shared/components/proxy.component';
import { PermissionGuardService } from '@guards/permission.guard';
import * as P from '@enums/permissions';
import { LicensingComponent } from './components/licensing/licensing.component';
import { LicensingUsersComponent } from './components/licensing/licensing-users/licensing-users.component';
import { LicensingDeliveriesComponent } from './components/licensing/licensing-deliveries/licensing-deliveries.component';
import { LicensingHardwareComponent } from './components/licensing/licensing-hardware/licensing-hardware.component';
import { LicensingApmDeliveriesComponent } from './components/licensing/licensing-apm-deliveries/licensing-apm-deliveries.component';
import { PaymentReportComponent } from './components/payment-report/payment-report.component';

const paymentsRoutes: Routes = [
    {
        path: '',
        component: ProxyComponent,
        canActivate: [PermissionGuardService],
        data: {
            expectedPermission: [P.Permissions.Payments_view],
        },
        children: [
            {
                path: '', component: PaymentsComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Payments'
                }
            },
            {
                path: ':y/:m/:d/:shiftId', component: PaymentsComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Payments'
                }
            },
            {
                path: 'report', component: PaymentReportComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.PaymentReport'
                }
            },
            {
                path: 'licensing', component: LicensingComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Licensing'
                },
                children: [
                    
                    {
                        path: 'deliveries', component: LicensingDeliveriesComponent, data: {animation: 'LicensingDeliveriesPage'}
                    },
                    {
                        path: 'apm-deliveries', component: LicensingApmDeliveriesComponent, data: {animation: 'LicensingApmDeliveriesPage'}
                    },
                    {
                        path: 'hardware', component: LicensingHardwareComponent, data: {animation: 'LicensingHardwarePage'}
                    },
                    {
                        path: 'users', component: LicensingUsersComponent, data: {animation: 'LicensingUsersPage'}
                    },
                    { path: '**', redirectTo: 'deliveries' },
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(paymentsRoutes)],
    exports: [RouterModule],
    providers: []
})
export class PaymentsRouting { }
