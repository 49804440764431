import * as _ from 'lodash';

import { Bag } from './bag';
import { Entity } from './Entity';
import { Serializable } from './Serializable';
import { BoxScanningType } from '@enums/enum';

export class Box extends Entity implements Serializable<Box> {
    public id: string;
    public shared: boolean = false;
    public type: string;
    public bags: any[];
    public version: string = '';
    public deliveryScanAt: string;
    public readyForPickupAt: string;
    public pickupScanAt: string;
    public loadedAt: string;
    public mergedTo: number;
    public createdAt: string;
    public modifiedAt: string;
    public status: BoxScanningType;

    public deserialize(data: any) {
        this.id = _.get(data, 'id', '');
        this.shared = _.get(data, 'shared', false);
        this.type = _.get(data, 'type', '');
        this.deliveryScanAt = _.get(data, 'deliveryScanAt');
        this.loadedAt = _.get(data, 'loadedAt');
        this.readyForPickupAt = _.get(data, 'readyForPickupAt');
        this.pickupScanAt = _.get(data, 'pickupScanAt');
        this.mergedTo = _.get(data, 'mergedTo');
        this.bags = _.get(data, 'bags', []).map((rawBag) => {
            return new Bag().deserialize(rawBag);
        });

        this.version = _.get(data, 'version');
        this.createdAt = _.get(data, 'createdAt');
        this.modifiedAt = _.get(data, 'modifiedAt');
        this.status = _.get(data, 'status');

        return this;
    }

    get isScanned(): boolean {
        return !!this.deliveryScanAt;
    }

    get isLoaded(): boolean {
        return !!this.loadedAt;
    }

    get isMerged(): boolean {
        return !!this.mergedTo;
    }

    public serialize() {
        return {
            id: this.id,
            shared: this.shared,
            type: this.type,
            bags: this.bags,
            deliveryScanAt: this.deliveryScanAt,
            loadedAt: this.loadedAt,
            mergedTo: this.mergedTo,
            version: this.version
        };
    }
}
