
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Routing' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_routing_edit]" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">
                <div *ngFor="let control of numberControls()" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control] }}</div>
                </div>
                <div *ngFor="let control of booleanControls()" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.'+control | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig[control].toString() | translate }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.routeStrategy' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('routeStrategy')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'routeStrategy' }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ shiftConfig['routeStrategy'].toString() | translate }}</div>
                </div>
                <div class="slot-limits mg-b-15">
                    <div>
                        <span>{{ 'shiftProperty.vanLimits' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('vanLimits')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="slot-limits__container">
                        <div *ngFor="let limit of shiftConfig.vanLimits" class="modify-value modify-value--slots mg-b-5">
                            <span class="slot-limits__hour">{{'shiftProperty.RoutesUsed' | translate }}:<strong>{{ limit.usedPercentage }}%</strong>:</span> 
                            <span>{{'shiftProperty.MinimalUsage' | translate }}:</span>
                            <span class="slot-limits__limit"> {{ limit.blockedSlots }}</span>
                        </div>
                    </div>
                </div>
                <div class="slot-limits mg-b-15">
                    <div>
                        <span>{{ 'shiftProperty.slotLimits' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('slotLimits')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="slot-limits__container">
                        <div *ngFor="let key of _.keys(shiftConfig.slotLimits)" class="modify-value modify-value--slots mg-b-5">
                            <span class="slot-limits__hour">{{ key }} – {{ 1 * key.split(':')[0] + 1 | number: '2.0' }}:{{ key.split(':')[1] }}:</span>
                            <span class="slot-limits__limit">{{ shiftConfig.slotLimits[key] }}</span>
                        </div>
                    </div>
                </div>
                <div class="slot-limits mg-b-15">
                    <div>
                        <span>{{ 'shiftProperty.virtualDepotConfig' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('virtualDepotConfig')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.virtualDepotConfig' | translate}}:</i-feather>
                        </sup>
                    </div> 
                    <div class="slot-limits__container">
                        <div *ngFor="let virtualDepotConfig of shiftConfig.virtualDepotConfig" class="modify-value modify-value--slots mg-b-5">
                            ID: {{virtualDepotConfig.virtualDepotId}}, 
                            {{'Routes' | translate}}: {{virtualDepotConfig.routes}},
                            {{ 'shiftProperty.routeStrategy' | translate }}: {{virtualDepotConfig.routeStrategy | translate}},
                            {{ 'shiftProperty.isExclusive' | translate }}: {{virtualDepotConfig.isExclusive.toString() | translate}}

                        </div>
                    </div>
                </div>
                <div *ngIf="(planningSequence$ | async) as planningSequence" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>
                        <span>{{ 'shiftProperty.planningSequence' | translate }}:</span>
                        <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('planningSequence')">
                            &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'planningSequence' | translate }}:</i-feather>
                        </sup>
                    </div> 
                    <div class="modify-value">{{ (planningSequence | findById:shiftConfig.planningSequence).name | translate }}</div>
                </div>

                <button [routerLink]="['/routes/auditlog', shiftId, 'SHIFT']" class="btn btn-outline-success w-100">{{'Audit log' | translate}}</button>

                
                <button appCheckFeaturesConfig [featureName]="[Permissions.Calendar_advancedsettings]" (click)="removeShiftConfirmation(shiftId)" class="mt-2 btn btn-outline-danger btn-red w-100">{{'Remove shift' | translate}}</button>

            </div>
        </div>

        <div *ngIf="mode == 'edit' || mode == 'saving'" [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <h3 class="title">{{ 'Routing' | translate }}</h3>

                <div class="form">
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <div *ngFor="let control of numberControls(); let index = index">
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                            </sup>
                        </div> 
                        <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedShiftConfig[control]" />
                    </div>
                    <div *ngFor="let control of booleanControls()"> 
                        <div>
                            <span>{{ 'shiftProperty.'+control | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig[control]">
                            <option [ngValue]="true">{{ 'True' | translate }}</option>
                            <option [ngValue]="false">{{ 'False' | translate }}</option>
                        </select>
                    </div>
                    <div> 
                        <div>
                            <span>{{ 'shiftProperty.routeStrategy' | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('routeStrategy')">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'routeStrategy' }}:</i-feather>
                            </sup>
                        </div> 
                        <select [id]="control" [(ngModel)]="editedShiftConfig['routeStrategy']">
                            <option *ngFor="let strategy of (routeStrategies$ | async)" [ngValue]="strategy">{{ strategy | translate }}</option>
                        </select>
                    </div>
                    

                    <div>
                        <div class="mg-b-20" for="vanLimits">{{ 'shiftProperty.vanLimits' | translate | translate }}:</div>
                        <div class="container" *ngFor="let limit of editedShiftConfig.vanLimits">
                            <div class="row van-limits d-flex align-items-baseline">
                                <label>{{'shiftProperty.RoutesUsed' | translate }}:</label>&nbsp;
                                <input class="col-2" type="number" [min]="0" [max]="100" step="1" (change)="sortVanLimits()" [style.margin-right.px]="3" [(ngModel)]="limit.usedPercentage" />
                                <label>{{'shiftProperty.MinimalUsage' | translate }}:</label>&nbsp;
                                <input class="col-2" type="number" [min]="0" step="1" [style.margin-right.px]="3" [(ngModel)]="limit.blockedSlots" />
                                <button (click)="removeVanLimits(limit)" class="col-1 btn btn-xs"><i-feather name="delete" [style.height.px]="18"></i-feather></button>
                            </div>
                        </div>
                        <button (click)="addVanLimits()" class="btn btn-xs btn-white pd-5 mg-t-10 mg-b-20">{{'Add van limit' | translate}}</button>
                    </div>
                    <div>
                        <div for="slotLimits">{{ 'shiftProperty.slotLimits' | translate | translate }}:</div>
                        <div class="container" *ngFor="let key of _.keys(editedShiftConfig.slotLimits); let index">
                            <div class="row">
                                <div class="col-7 mt-2">{{ key }} – {{ 1 * key.split(':')[0] + 1 | number: '2.0' }}:{{ key.split(':')[1] }}:</div>
                                <input class="col-4" type="number" [min]="1" step="0.01" [(ngModel)]="editedShiftConfig.slotLimits[index]" />
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(depot$ | async)">
                        <div class="mg-b-10" for="slotLimits">{{ 'shiftProperty.virtualDepotConfig' | translate }}:</div>
                        <div class="container" *ngFor="let virtualDepotConfig of editedShiftConfig.virtualDepotConfig; let index">
                       
                            <label class="tx-10 tx-bold tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-0"><strong>{{ virtualDepotConfig.name | translate }}</strong></label>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'Active' | translate }}:</div>
                                <select class="col-4" [(ngModel)]="virtualDepotConfig.enabled">
                                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'Routes' | translate }}:</div>
                                <input class="col-4" type="number" [(ngModel)]="virtualDepotConfig.routes" />
                            </div>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'shiftProperty.routeStrategy' | translate }}:</div>
                                <select class="col-4" [(ngModel)]="virtualDepotConfig.routeStrategy">
                                    <option *ngFor="let option of routeStrategyType" [value]="option">{{ option | translate }}</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-7 mt-2">{{ 'shiftProperty.isExclusive' | translate }}:</div>
                                <select class="col-4" [(ngModel)]="virtualDepotConfig.isExclusive">
                                    <option [ngValue]="true">{{ 'True' | translate }}</option>
                                    <option [ngValue]="false">{{ 'False' | translate }}</option>
                                </select>
                            </div>
                            
                        </div>
                    </div>

                    <div *ngIf="(planningSequence$ | async) as planningSequence"> 
                        <div>
                            <span>{{ 'shiftProperty.planningSequence' | translate }}:</span>
                            <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('planningSequence')">
                                &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'planningSequence' }}:</i-feather>
                            </sup>
                        </div> 
                        <select id="planningSequence" [(ngModel)]="editedShiftConfig['planningSequence']">
                            <option *ngFor="let option of planningSequence" [value]="option.id">{{ option.name | translate }}</option>
                        </select>
                    </div>
                </div>
            </editor-window>
        </div>

        <ng-template #removeConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
            </div>

            <div class="modal-body text-center">
                <p>{{'Do you confirm removal of the shift' | translate }}?</p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{'Yes, remove' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
            </div>
        </ng-template>
    