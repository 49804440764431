import * as _ from 'lodash';
import * as moment from 'moment';

import { BoxType, ShiftType, ActivityType } from '@enums/enum';
import { Entity } from '@shared/entities/Entity';
import { RouteExt } from '@shared/entities/route-ext';
import { Serializable } from '@shared/entities/Serializable';
import { Activity } from '@entities/activity';

export class OptimizeRoutes extends Entity implements Serializable<OptimizeRoutes> {
    public date: string;
    public label: string;
    public shift: ShiftType;
    public totalDrivingTime: number;
    public totalReloads: number;
    public runtime: number;
    public totalBoxes: BoxType;
    public totalEarlyMarginDeliveries: number;
    public totalErrors: number;
    public totalGeocodingErrors: number;
    public totalLateMarginDeliveries: number;
    public totalRoutes: number;
    public totalWaitTime: number;
    public totalDeliveries: number;
    public totalDistance: number;
    public routes: RouteExt[] = [];
    public deliveriesPerVans: number;
    public log: string[] = [];
    public countByslot;

    public deserialize(data: any) {
        if (data === null) { return; }

        this.date = _.get(data, 'date');
        this.label = _.get(data, 'label');
        this.shift = _.get(data, 'shift');
        this.totalDrivingTime = _.get(data, 'totalDrivingTime');
        this.totalReloads = _.get(data, 'totalReloads');
        this.runtime = _.get(data, 'runtime', 0);
        this.totalDeliveries = _.get(data, 'totalDeliveries', 0);
        this.totalBoxes = _.get(data, 'totalBoxes');
        this.totalEarlyMarginDeliveries = _.get(data, 'totalEarlyMarginDeliveries', 0);
        this.totalErrors = _.get(data, 'totalErrors', 0);
        this.totalGeocodingErrors = _.get(data, 'totalGeocodingErrors', 0);
        this.totalLateMarginDeliveries = _.get(data, 'totalLateMarginDeliveries', 0);
        this.totalRoutes = _.get(data, 'totalRoutes', 0);
        this.totalWaitTime = _.get(data, 'totalWaitTime', 0);
        this.log = _.get(data, 'log', []);
        this.totalDistance = _.sumBy(this.routes, (r: RouteExt) => r.travelDistance);


        (_.get(data, 'routes')).map((mappedData: any) => this.routes.push(new RouteExt().deserialize(mappedData)));

        const routes: number = this.routes.filter((r: RouteExt) => r.deliveryCleanList.length).length;
        this.deliveriesPerVans = this.totalDeliveries / routes;

        let routeDeliveryBySlot = [];

        _.forEach(this.routes, (route: RouteExt) => {
            routeDeliveryBySlot.push(...route.activities.filter((a: Activity) => a.type === ActivityType.DELIVERY));
        });

        this.countByslot = _.countBy(routeDeliveryBySlot, (a: Activity) => a.slot.slot);

        return this;
    }

    public get boxesSum(): number {
        let dryBoxes: number = this.totalBoxes['dryBoxes'];
        let chilledBoxes: number = this.totalBoxes['chilledBoxes'];
        let frozenBoxes: number = this.totalBoxes['frozenBoxes'];

        if (!dryBoxes) { dryBoxes = 0; }
        if (!chilledBoxes) { chilledBoxes = 0; }
        if (!frozenBoxes) { frozenBoxes = 0; }

        return dryBoxes + chilledBoxes + frozenBoxes;
    }

    public extractDeliveriesBySlot(slot: string) {
        const routes = _.cloneDeep(this.routes);
        const deliveries = [];

            _.forEach(routes, (route: RouteExt) => {
                const routeDeliveryBySlot = [];
                routeDeliveryBySlot.push(..._.filter(route.activities, (a: Activity) => {

                    if (a.type === ActivityType.DELIVERY) {
                        const slotEnd = a.slot.slot.split('-')[1];
                        const selectedSlot = slot.split('-')[1];
                        const selectedSlot2 = moment('2020-01-01 ' + selectedSlot).add(-30, 'minutes').format("HH:mm");

                        if (slotEnd === selectedSlot || slotEnd === selectedSlot2) {
                            return a;
                        }
                    }
                }));
                route.activities = routeDeliveryBySlot;
                if (routeDeliveryBySlot.length) {
                    deliveries.push(route);
                }

            });
     

        return _.cloneDeep(deliveries);
    }

    public serialize() {
        return {
            date: this.date,
            label: this.label,
            routes: this.routes,
            shift: this.shift,
            totalDrivingTime: this.totalDrivingTime,
            totalReloads: this.totalReloads,
            runtime: this.runtime,
            totalDeliveries: this.totalDeliveries,
            totalBoxes: this.totalBoxes,
            totalEarlyMarginDeliveries: this.totalEarlyMarginDeliveries,
            totalErrors: this.totalErrors,
            totalGeocodingErrors: this.totalGeocodingErrors,
            totalLateMarginDeliveries: this.totalLateMarginDeliveries,
            totalRoutes: this.totalRoutes,
            totalWaitTime: this.totalWaitTime,
            travelDistance: this.totalDistance
        };
    }
}
