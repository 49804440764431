<layout-default>
    <div header>
        <div toolbar>
            <h4><i-feather name="git-pull-request"></i-feather> {{'Simulator' | translate}}</h4>
        </div>

        <div toolbar>
            <button (click)="createDatasetMode = !createDatasetMode" class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01">{{'Add new dataset' | translate}}</button>
        </div>

        <hr>
    </div>

    <div content *ngIf="!loader" class="bg-white">
        <div *ngIf="createDatasetMode" [@slideInOut]>
            <app-dataset-create (addedDataset)="addedDataset($event)"></app-dataset-create>
        </div>

        <div *ngIf="createSimulationMode" [@slideInOut]>
            <app-simulation-create
                    [datasetId]="selectedDatasetId"
                    [simulationId]="null"
                    (addedSimulation)="addedSimulation($event)">
            </app-simulation-create>
        </div>

        <div class="bg-white">

            <div *ngIf="!loader">
                <ngx-datatable class="bootstrap"
                    #deliveriesTable
                    [rows]="dataset"
                    [columns]="columns"
                    [columnMode]="'flex'"
                    [headerHeight]="50"
                    [messages]="tableMessages"
                    [footerHeight]="50"
                    [rowClass]="rowClass"
                    [rowHeight]="'auto'"
                    [limit]="25"
                    (activate)="activateRow($event)">

                    <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow>
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                            <div class="center-flex">
                                <button *ngFor="let simulation of simulations; let index = index"
                                        (click)="goToSimulation(simulation.simulationId)"
                                        class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-white">
                                    <strong>{{simulation.simulationShiftConfigDto.date | amLocale: locale | amDateFormat:'DD MMMM YYYY'}}
                                        - {{simulation.simulationShiftConfigDto.shift | translate}} </strong>
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-row-detail>

                    <ngx-datatable-column
                            [width]="50"
                            [resizeable]="false"
                            [sortable]="false"
                            [draggable]="false"
                            [canAutoResize]="false">
                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                            <a href="javascript:void(0)"
                               [class.datatable-icon-right]="!expanded"
                               [class.datatable-icon-down]="expanded"
                               title="Expand/Collapse Row">
                            </a>
                        </ng-template>
                    </ngx-datatable-column>

                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <b class="tx-uppercase">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <ng-container *ngIf="!columnToTranslate(col.prop)">{{value}}</ng-container>
                                <ng-container *ngIf="columnToTranslate(col.prop) && !isOptionsColumn(col.prop)">{{value | translate}}</ng-container>


                                <ng-container *ngIf="isOptionsColumn(col.prop)">
                                    <button (click)="renameDataset(row.id, row.name)"
                                            class="btn btn-white btn-xs btn-uppercase remove-button"
                                            type="button">{{'Rename' | translate}}</button>
                                    <button (click)="removeDataset(row.id)"
                                            class="btn btn-white btn-xs btn-uppercase remove-button"
                                            type="button">{{'Delete' | translate}}</button>
                                    <button (click)="createSimulation(row.id, row.name)"
                                            class="btn btn-white btn-xs btn-uppercase remove-button"
                                            type="button">{{'Create simulation' | translate}}</button>
                                </ng-container>
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>

                </ngx-datatable>
            </div>
        </div>
    </div>

    <div content *ngIf="loader">
        <div class="load-spinner text-center">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                {{ 'Please, wait!' | translate }}
        </div>
    </div>
</layout-default>

<ng-template #removeDatasetConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center">
        <p>{{'Do you want to remove dataset from database' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">
            {{'Cancel' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #renameDatasetRef>
    <div class="modal-header">
        <h4 class="modal-title">{{'Rename dataset' | translate }}</h4>
    </div>

    <div class="modal-body text-center">
        <p>
            <strong>{{'Please, write new dataset name' | translate}}:</strong>
        </p>
        <input [(ngModel)]="datasetName" class="form-control"/>
        <br/>
        <button type="button" class="btn btn-brand-01" (click)="renameDatasetExecute(modalArgs.values.id)">
            {{'Rename dataset' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>
