<div class="typehead-filter filter-container">
    <div class="input-group-prepend">
        <span id="basic-addon1" class="typehead-filter__loader">
            <i class="typehead-filter__icon fa fa-spinner fast-pulse" aria-hidden="true" [class.typehead-filter__notvisible]="!typeaheadLoading"></i>
            <i class="typehead-filter__icon fa fa-search" aria-hidden="true" [class.typehead-filter__notvisible]="typeaheadLoading"></i>
            

        </span>

        <span *ngIf="asyncSelected" id="basic-addon1" class="typehead-filter__close">
            <i class="typehead-filter__icon fa fa-times" aria-hidden="true" (click)="removeResults()" [class.typehead-filter__notvisible]="typeaheadLoading"></i>
        </span>


    </div>
    <input class="typehead-filter__input form-control"
           placeholder="{{'type to search' | translate}}"
           aria-label="Wyszukaj"
           aria-describedby="basic-addon1"
           [(ngModel)]="asyncSelected"
           [typeahead]="dataSource"
           (typeaheadLoading)="changeTypeaheadLoading($event)"
           (typeaheadOnSelect)="typeaheadOnSelect($event)"
           [typeaheadOptionsLimit]="7"
           typeaheadOptionField="label"
           [typeaheadItemTemplate]="customItemTemplate"
           typeaheadWaitMs="1000"
           [disabled]="disabled"
           [typeaheadMinLength]="3">
</div>

<ng-template class="items" #customItemTemplate let-model="item" let-index="index">

    <div *ngIf="type === 'DRIVER'">
        <strong>[{{model.employeeId}}]</strong>, {{model.firstName}} {{model.lastname}}
    </div>

    <div *ngIf="type === 'CUSTOMER'">
        <strong>[{{model.ref}}]</strong>, {{model.firstName}} {{model.lastName}}
    </div>
</ng-template>



