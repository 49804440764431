import * as Jwt from 'jsonwebtoken';
import * as _ from 'lodash';
import * as moment from 'moment';

export class Token {
    public jwt: string;
    public authorities: string[] = [];
    public exp: number;
    public iat: number;
    public id: string;
    public iss: string;
    public sub: string;

    public parse(jwt: string): Token {
        if (!jwt) {
            throw new Error('Invalid jwt token');
        }
        this.jwt = jwt;

        const decoded: any = Jwt.decode(jwt);

        this.authorities = decoded.authorities;
        this.exp = decoded.exp;
        this.iat = decoded.iat;
        this.id = decoded.id;
        this.iss = decoded.iss;
        this.sub = decoded.sub;

        return this;
    }

    public getJwt() {
        return this.jwt;
    }

    public getExpiration() {
        return moment.unix(this.exp).utc();
    }
}
