
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SalaryReportService } from '../services/salary-report.service';

@Injectable()
export class SalaryReportResolver implements Resolve<any> {
    constructor(private salaryReportService: SalaryReportService) { }
    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.salaryReportService.findOne(params['reportId']);
    }
}
