import * as _ from 'lodash';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

export class Coordinates extends Entity implements Serializable<Coordinates> {

    public lat: number = 0;
    public lng: number = 0;

    public deserialize(data: any) {
        this.lat = parseFloat(_.get(data, 'lat', 0));
        this.lng = parseFloat(_.get(data, 'lng', 0));

        return this;
    }

    public serialize() {
        return {
            lat: this.lat,
            lng: this.lng,
        };
    }

    get parseCoordinates(): string {
        return `${this.lng},${this.lat}`;
    }
}
