import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { OptimizeRoutes } from 'src/app/visualiser/entities/optimize-routes';
import { CutoffSolutions } from 'src/app/visualiser/interfaces/cutoff-solutions.interface';
import { VisualiserService } from 'src/app/visualiser/services/visualiser.service';

@Component({
  selector: 'app-finalize-dialog',
  templateUrl: './finalize-dialog.component.html',
})
export class FinalizeDialogComponent implements OnInit {
  @Input() data: any;
  public onClose: Subject<boolean>;

  currentSolution: OptimizeRoutes;
  confirmationTotalErrors: number;
  betterSolutions: OptimizeRoutes[];
  finalizeLoader = false;

  confirmObservable: Observable<any>;

  constructor(
    private readonly visualiserService: VisualiserService,
    public readonly bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.currentSolution = this.data.result.routes[0];
    this.betterSolutions = this.data.solutions.filter((solution: CutoffSolutions) => solution.errors < this.currentSolution.totalErrors);
    this.confirmObservable = this.visualiserService.finalize(this.data.shiftId);
    this.onClose = new Subject();
  }

  public decline(): void {
    this.confirmObservable = null;
    this.finalizeLoader = false;
    this.bsModalRef.hide();
  }

  public confirm(): void {
    this.confirmObservable.subscribe(
      () => {
        this.onClose.next(true);
        this.bsModalRef.hide();
        this.confirmObservable = null;
      }, () => {
        this.finalizeLoader = false;
      });
  }

}
