<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="layers"></i-feather> {{'Shift planning list' | translate}}
            </h4>
        </div>

        <div toolbar>
            <button id="shift-planning-create" *ngIf="(shiftsPlanning$ | async) as shiftsPlanning"
                class="btn btn-sm btn-uppercase btn-white" [routerLink]="['create', -1]">
                <i-feather [style.height.px]="23" [style.width.px]="13" class="mg-r-10" name="plus"></i-feather> {{ 'Add
                shift planning' | translate }}
            </button>
            <div class="filter-container">
                <div id="shift-planning-search" class="phrase-filter">
                    <input type='text' class="list-filter form-control" placeholder="{{'type to search' | translate}}"
                        (keyup)='applyFiltering($event)' />
                </div>
            </div>
        </div>
        <hr />
    </div>

    <div content>
        <ng-container *ngIf="!loader">
            <div *ngIf="(shiftsPlanning$ | async) as shiftsPlanning">
                <ngx-datatable id="config-value" class="bootstrap" [rows]="shiftsPlanningCollection" [columns]="columns"
                    [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [messages]="tableMessages"
                    [rowClass]="rowClass" [rowHeight]="'auto'">
                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                            <ng-template let-column="column" ngx-datatable-header-template class="header"
                                let-sort="sortFn">
                                <b>{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>


                                <div [ngSwitch]="col.prop">

                                    <div *ngSwitchCase="'offset'">
                                        {{row.defaultSettings.shiftIntegrationData.routeNumberOffset}}
                                    </div>

                                    <div *ngSwitchCase="'options'">
                                        <div class="dropdown" dropdown>
                                            <button class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                                type="button" id="dropdownMenuButton">
                                                {{ 'Select' | translate }}
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right div-main-actions">
                                                <a id="shift-planning-edit"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                    (click)="goToDetails(row.id)">
                                                    {{'Edit' | translate }}
                                                </a>

                                                <a id="shift-planning-auditlog"
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item"
                                                    [routerLink]="['/calendar/shift-planning/auditlog', row.shiftName, 'SHIFTPLANNING']">
                                                    {{'Audit log' | translate }}
                                                </a>

                                                <!-- <a 
                                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item" 
                                                    (click)="removeShiftPlanning(row.id)">
                                                        {{'Remove' | translate }}
                                                </a> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div id="shift-planning-depot" *ngSwitchCase="'warehouse'">{{value |
                                        findWarehousePipe }} </div>
                                    <div id="shift-planning-id" *ngSwitchCase="'id'">{{value}}</div>
                                    <div id="shift-planning-autoCreateDays" *ngSwitchCase="'autoCreateDays'">
                                        <span [ngSwitch]="value">
                                            <span *ngSwitchCase="-1">{{'False' | translate}}</span>
                                            <span *ngSwitchDefault>
                                                <span [ngSwitch]="value">
                                                    <span *ngSwitchCase="1">{{value}} {{'day' | translate}}</span>
                                                    <span *ngSwitchDefault>{{value}} {{'days' | translate}}</span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div id="shift-planning-enabled" *ngSwitchCase="'enabled'">
                                        <span *ngIf="value" class="badge badge-success">{{'Yes' | translate}}</span>
                                        <span *ngIf="!value" class="badge badge-danger">{{'No' | translate}}</span>
                                    </div>

                                    <div id="shift-planning-title" *ngSwitchCase="'shiftName'">{{ value | translate }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ value | translate }}
                                    </div>
                                </div>


                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>
        </ng-container>

        <div *ngIf="loader" class="load-spinner text-center">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{ 'Please, wait!' | translate }}
        </div>

    </div>

</layout-default>

<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm removal of shift planning' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>


<!-- Shift properties -->
<!-- {{'shiftProperty.departureWaveInitialSize' | translate -->
<!-- {{'shiftProperty.departureWaveInterval' | translate -->
<!-- {{'shiftProperty.departureWaveSize' | translate -->
<!-- {{'shiftProperty.departureWaveInformation' | translate -->
<!-- {{'shiftProperty.fleet' | translate -->
<!-- {{'shiftProperty.label' | translate -->
<!-- {{'shiftProperty.maximumDeliveries' | translate -->
<!-- {{'shiftProperty.maximumProductLines' | translate -->
<!-- {{'shiftProperty.reloadTimeMinutes' | translate -->
<!-- {{'shiftProperty.shiftEndMarginMinutes' | translate -->
<!-- {{'shiftProperty.endTime' | translate -->
<!-- {{'shiftProperty.shiftStartMarginMinutes' | translate -->
<!-- {{'shiftProperty.startTime' | translate -->
<!-- {{'shiftProperty.status' | translate -->
<!-- {{'shiftProperty.deliveryTime' | translate -->
<!-- {{'shiftProperty.boxGroupSize' | translate -->
<!-- {{'shiftProperty.boxGroupTime' | translate -->
<!-- {{'shiftProperty.initialTime' | translate -->
<!-- {{'shiftProperty.parkingTime' | translate -->
<!-- {{'shiftProperty.parkingTimeThreshold' | translate -->
<!-- {{'shiftProperty.bookingWindowDayOffset' | translate -->
<!-- {{'shiftProperty.bookingWindowRestricted' | translate -->
<!-- {{'shiftProperty.bookingWindowTimeOfDay' | translate -->
<!-- {{'shiftProperty.offset' | translate -->
<!-- {{'shiftProperty.routeNumberOffset' | translate -->
<!-- {{'shiftProperty.segmentNumberOffset' | translate -->
<!-- {{'shiftProperty.slotDuration' | translate -->
<!-- {{'shiftProperty.slotOverLapping' | translate -->
<!-- {{'shiftProperty.time' | translate -->
<!-- {{'shiftProperty.autoFulfill' | translate -->
<!-- {{'shiftProperty.automaticCutoff' | translate -->
<!-- {{'shiftProperty.expensiveDeliveryThreshold' | translate -->
<!-- {{'shiftProperty.expensiveDeliveryThresholdTime' | translate -->
<!-- {{'shiftProperty.includeReturnInPlanning' | translate -->
<!-- {{'shiftProperty.maxCostFactor' | translate -->
<!-- {{'shiftProperty.planningSequence' | translate -->
<!-- {{'shiftProperty.removePlanning' | translate -->
<!-- {{'shiftProperty.routeMargin' | translate -->
<!-- {{'shiftProperty.routeMaximumReloads' | translate -->
<!-- {{'shiftProperty.routeStrategy' | translate -->
<!-- {{'shiftProperty.shiftDateOffset' | translate -->
<!-- {{'shiftProperty.shiftMaximumSegments' | translate -->
<!-- {{'shiftProperty.slotEndMarginMinutes' | translate -->
<!-- {{'shiftProperty.slotLimits' | translate -->
<!-- {{'shiftProperty.slotStartMarginMinutes' | translate -->
<!-- {{'shiftProperty.vanLimits' | translate -->
<!-- {{'shiftProperty.loadFactor' | translate -->
<!-- {{'shiftProperty.shiftplanType' | translate -->
<!-- {{'shiftProperty.reloadPercentage' | translate -->
<!-- {{'shiftProperty.autoCreateDays' | translate -->
<!-- {{'shiftProperty.warehouse' | translate -->
<!-- {{'shiftProperty.date' | translate -->
<!-- {{'shiftProperty.doWFilter' | translate -->
<!-- {{'shiftProperty.enabled' | translate -->
<!-- {{'shiftProperty.shiftName' | translate -->
<!-- {{'shiftProperty.usedPercentage' | translate -->
<!-- {{'shiftProperty.blockedSlots' | translate -->
<!-- {{'shiftProperty.limit' | translate -->
<!-- {{'shiftProperty.slot' | translate -->
<!-- {{'shiftProperty.key' | translate -->
<!-- {{'shiftProperty.cutoffTimeout' | translate -->
<!-- {{'shiftProperty.syncDelay' | translate -->
<!-- {{'shiftProperty.autoPromoteSolution' | translate -->
<!-- {{'shiftProperty.autoFinalizeSolution' | translate -->
<!-- {{'shiftProperty.fulfillmentIntegrationEnabled' | translate -->
<!-- {{'shiftProperty.autoGenerateSolutions' | translate -->
<!-- {{'shiftProperty.isServiceShift' | translate -->
<!-- {{'shiftProperty.lockDeliveryOrder' | translate -->
<!-- {{'shiftProperty.allowDeliveryImport' | translate -->
<!-- {{'shiftProperty.shiftType' | translate -->
<!-- {{'shiftProperty.paymentTime' | translate -->
<!-- {{'shiftProperty.scanBeforeDeparture' | translate -->
<!-- {{'shiftProperty.scanAfterReturn' | translate -->
<!-- {{'shiftProperty.overbooked' | translate -->
<!-- {{'shiftProperty.maximumRoutes' | translate -->
<!-- {{'shiftProperty.offsetDays' | translate -->


<!-- Shift tooltips -->
<!-- {{'tooltips.shift.labels.departureWaveInitialSize' | translate -->
<!-- {{'tooltips.shift.labels.departureWaveInterval' | translate -->
<!-- {{'tooltips.shift.labels.departureWaveSize' | translate -->
<!-- {{'tooltips.shift.labels.departureWaveInformation' | translate -->
<!-- {{'tooltips.shift.labels.fleet' | translate -->
<!-- {{'tooltips.shift.labels.label' | translate -->
<!-- {{'tooltips.shift.labels.maximumDeliveries' | translate -->
<!-- {{'tooltips.shift.labels.maximumProductLines' | translate -->
<!-- {{'tooltips.shift.labels.reloadTimeMinutes' | translate -->
<!-- {{'tooltips.shift.labels.shiftEndMarginMinutes' | translate -->
<!-- {{'tooltips.shift.labels.endTime' | translate -->
<!-- {{'tooltips.shift.labels.shiftStartMarginMinutes' | translate -->
<!-- {{'tooltips.shift.labels.startTime' | translate -->
<!-- {{'tooltips.shift.labels.status' | translate -->
<!-- {{'tooltips.shift.labels.deliveryTime' | translate -->
<!-- {{'tooltips.shift.labels.boxGroupSize' | translate -->
<!-- {{'tooltips.shift.labels.boxGroupTime' | translate -->
<!-- {{'tooltips.shift.labels.initialTime' | translate -->
<!-- {{'tooltips.shift.labels.parkingTime' | translate -->
<!-- {{'tooltips.shift.labels.parkingTimeThreshold' | translate -->
<!-- {{'tooltips.shift.labels.bookingWindowDayOffset' | translate -->
<!-- {{'tooltips.shift.labels.bookingWindowRestricted' | translate -->
<!-- {{'tooltips.shift.labels.bookingWindowTimeOfDay' | translate -->
<!-- {{'tooltips.shift.labels.offset' | translate -->
<!-- {{'tooltips.shift.labels.routeNumberOffset' | translate -->
<!-- {{'tooltips.shift.labels.segmentNumberOffset' | translate -->
<!-- {{'tooltips.shift.labels.slotDuration' | translate -->
<!-- {{'tooltips.shift.labels.slotOverLapping' | translate -->
<!-- {{'tooltips.shift.labels.time' | translate -->
<!-- {{'tooltips.shift.labels.autoFulfill' | translate -->
<!-- {{'tooltips.shift.labels.automaticCutoff' | translate -->
<!-- {{'tooltips.shift.labels.expensiveDeliveryThreshold' | translate -->
<!-- {{'tooltips.shift.labels.expensiveDeliveryThresholdTime' | translate -->
<!-- {{'tooltips.shift.labels.includeReturnInPlanning' | translate -->
<!-- {{'tooltips.shift.labels.maxCostFactor' | translate -->
<!-- {{'tooltips.shift.labels.planningSequence' | translate -->
<!-- {{'tooltips.shift.labels.removePlanning' | translate -->
<!-- {{'tooltips.shift.labels.routeMargin' | translate -->
<!-- {{'tooltips.shift.labels.routeMaximumReloads' | translate -->
<!-- {{'tooltips.shift.labels.routeStrategy' | translate -->
<!-- {{'tooltips.shift.labels.shiftDateOffset' | translate -->
<!-- {{'tooltips.shift.labels.shiftMaximumSegments' | translate -->
<!-- {{'tooltips.shift.labels.slotEndMarginMinutes' | translate -->
<!-- {{'tooltips.shift.labels.slotLimits' | translate -->
<!-- {{'tooltips.shift.labels.slotStartMarginMinutes' | translate -->
<!-- {{'tooltips.shift.labels.vanLimits' | translate -->
<!-- {{'tooltips.shift.labels.loadFactor' | translate -->
<!-- {{'tooltips.shift.labels.shiftplanType' | translate -->
<!-- {{'tooltips.shift.labels.reloadPercentage' | translate -->
<!-- {{'tooltips.shift.labels.autoCreateDays' | translate -->
<!-- {{'tooltips.shift.labels.warehouse' | translate -->
<!-- {{'tooltips.shift.labels.date' | translate -->
<!-- {{'tooltips.shift.labels.doWFilter' | translate -->
<!-- {{'tooltips.shift.labels.enabled' | translate -->
<!-- {{'tooltips.shift.labels.shiftName' | translate -->
<!-- {{'tooltips.shift.labels.usedPercentage' | translate -->
<!-- {{'tooltips.shift.labels.blockedSlots' | translate -->
<!-- {{'tooltips.shift.labels.limit' | translate -->
<!-- {{'tooltips.shift.labels.slot' | translate -->
<!-- {{'tooltips.shift.labels.key' | translate -->
<!-- {{'tooltips.shift.labels.cutoffTimeout' | translate -->
<!-- {{'tooltips.shift.labels.syncDelay' | translate -->
<!-- {{'tooltips.shift.labels.autoPromoteSolution' | translate -->
<!-- {{'tooltips.shift.labels.autoFinalizeSolution' | translate -->
<!-- {{'tooltips.shift.labels.fulfillmentIntegrationEnabled' | translate -->
<!-- {{'tooltips.shift.labels.autoGenerateSolutions' | translate -->
<!-- {{'tooltips.shift.labels.isServiceShift' | translate -->
<!-- {{'tooltips.shift.labels.slotLimitsPerVan' | translate -->
<!-- {{'tooltips.shift.labels.lockDeliveryOrder' | translate -->
<!-- {{'tooltips.shift.labels.allowDeliveryImport' | translate -->
<!-- {{'tooltips.shift.labels.shiftType' | translate -->
<!-- {{'tooltips.shift.labels.paymentTime' | translate -->
<!-- {{'tooltips.shift.labels.overbooked' | translate -->
<!-- {{'tooltips.shift.labels.maximumRoutes' | translate -->
<!-- {{'tooltips.shift.labels.offsetDays' | translate -->