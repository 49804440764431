
        <layout-default>
            <div header>
                <div toolbar>
                    <h4 *ngIf="mode == 'edit'">
                        <i-feather name="edit-3"></i-feather> {{ 'Edit hardware' | translate }}
                    </h4>
                    <h4 *ngIf="mode == 'create'">
                        <i-feather name="plus-square"></i-feather> {{ 'Add hardware' | translate }}
                    </h4>
                </div>
                <div toolbar>
                    <button *ngIf="mode == 'edit'" class="btn btn-sm btn-uppercase btn-white" [routerLink]="['/fleet']">{{ 'Cancel' | translate }}</button>
                </div>
                
                <hr>
            </div>

            <div content class="content">
                <div class="col-8 offset-2">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div id="hardware-edit-form-brand" class="form-group row">
                                <label class="col-2" for="brand">{{ 'Brand' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="brand" class="form-control" />
                                    <div *ngIf="form.get('brand').hasError('required') && form.get('brand').touched" class="error-color">{{ 'Brand is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-model" class="form-group row">
                                <label class="col-2" for="model">{{ 'Model' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="model" class="form-control" />
                                    <div *ngIf="form.get('model').hasError('required') && form.get('model').touched" class="error-color">{{ 'Model is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-deviceNo" class="form-group row">
                                <label class="col-2" for="lastname">{{ 'Device no' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="deviceNo" class="form-control" />
                                    <div *ngIf="form.get('deviceNo').hasError('required') && form.get('deviceNo').touched" class="error-color">{{ 'Device number is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-hardwareType" class="form-group row">
                                <label class="col-2" for="hardwareType">{{ 'Hardware type' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="hardwareType">
                                        <ng-container *ngFor="let hardwareType of hardwareTypeOptions">
                                            <option [value]="hardwareType">{{ 'hardwareType.' + hardwareType + '.' + client | translate }}</option>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="form.get('hardwareType').hasError('required') && form.get('hardwareType').touched" class="error-color">
                                        {{ 'Hardware Type field is required' | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-inventoryNo" class="form-group row">
                                <label class="col-2" for="inventoryNo">{{ 'Inventory No' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="inventoryNo" class="form-control" />
                                    <div *ngIf="form.get('inventoryNo').hasError('required') && form.get('inventoryNo').touched" class="error-color">
                                        {{ 'Inventory number is required' | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-phoneNumber" class="form-group row">
                                <label class="col-2" for="phoneNumber">{{ 'Phone number' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="phoneNumber" class="form-control" />
                                </div>
                            </div>

                            <ng-container formGroupName="properties">
                                <div id="hardware-edit-form-pin" class="form-group row">
                                    <label class="col-2" for="pin">{{ 'PIN number' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="pin" class="form-control" />
                                    </div>
                                </div>
                                <div id="hardware-edit-form-puk" class="form-group row">
                                    <label class="col-2" for="puk">{{ 'PUK number' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="puk" class="form-control" />
                                    </div>
                                </div>
                            </ng-container>


                            <div id="hardware-edit-form-owner" class="form-group row">
                                <label class="col-2" for="owner">{{ 'Owner' | translate }}:</label>
                                <div class="col-10">
                                    <select *ngIf="(hardwareOwner$ | async) as hardwareOwner" class="custom-select" formControlName="owner">
                                        <option *ngFor="let owner of hardwareOwner" [value]="owner">{{ owner | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('owner').hasError('required') && form.get('owner').touched" class="error-color">{{ 'Owner field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-status" class="form-group row">
                                <label class="col-2" for="status">{{ 'Hardware status' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="status">
                                        <option *ngFor="let status of hardwareStatusOptions" [value]="status">{{ status | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Hardware status field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div class="form-group row justify-content-end">
                                <div class="col-2">
                                    <button id="hardware-edit-form-btn-submit" class="btn btn-sm btn-uppercase btn-brand-01 w-100">
                                        <span *ngIf="!submitted">{{ 'Save' | translate }}</span>

                                        <ng-container *ngIf="submitted">
                                            <div class="load-spinner text-center">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
            </div>
            <div sidebar>
                <sidebar-with-header>
                    <div header>
                        <div class="item active"></div>
                    </div>
                    <div content></div>
                </sidebar-with-header>
            </div>
        </layout-default>

        <ng-template #removeHardwareConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>
                    {{ 'Do you want to remove hardware:' | translate }} <strong>{{ hardware.label }}</strong
                    >?
                </p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate }}
                </button>
            </div>
        </ng-template>

        <!-- {{'hardwareType.SCANNER.FRISCO' | translate}} -->
        <!-- {{'hardwareType.SCANNER.INPOST' | translate}} -->
        <!-- {{'hardwareType.POS_TERMINAL.FRISCO' | translate}} -->
        <!-- {{'hardwareType.POS_TERMINAL.INPOST' | translate}} -->
        <!-- {{'VEHICLE' | translate}} -->
        <!-- {{'FORKLIFT' | translate}} -->
        <!-- {{'VAN' | translate}} -->
        <!-- {{'OTHER' | translate}} -->
        <!-- {{'UNKNOWN' | translate}} -->
        <!-- {{'ELAVON' | translate }} -->
        <!-- {{'Hardware with this device number already exists' | translate}} -->
    