import { Component, OnInit } from '@angular/core';
import { AgencyService } from '../../services/agency.service';
import { Observable, combineLatest } from 'rxjs';
import { Collection } from '@interfaces/collection.interface';
import { Agency } from '../../interfaces/agency';
import { QueryParams } from '@interfaces/query-params.interface';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap, map, switchMap, catchError } from 'rxjs/operators';
import { datePickerOptions } from '@shared/constants/datepicker-options.constants';
import * as moment from 'moment';
import { SalaryReport } from '../../interfaces/salary-report';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NavService } from '@services/nav.sevice';
import { SalaryReportService } from '../../services/salary-report.service';
import { PageableResponse } from '@entities/pagable-response';

@Component({
  selector: 'app-report-create',
  templateUrl: './report-create.component.html',
  styleUrls: ['./report-create.component.scss']
})
export class ReportCreateComponent implements OnInit {

  public agencySubscriptions$: Observable<Agency[]>;
  public agencies: Agency[];

  public pagination = {
    limit: 1000,
    maxSize: 1000
  };

  public queryParams: QueryParams = {
    page: 0,
    size: this.pagination.limit,
    order: 'ASC',
    sort: 'createdAt'
  };

  public params;

  public form: FormGroup;
  public loader = true;
  public submitted = false;

  get options() {
    return datePickerOptions;
  }

  constructor(
    private readonly agencyService: AgencyService,
    private readonly salaryReportService: SalaryReportService,
    private readonly translateService: TranslateService,
    private readonly notifierService: NotifierService,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly navService: NavService
  ) { }

  public ngOnInit() {
    this.agencySubscriptions$ = this.route.params.pipe(
      tap(params => this.params = params),
      switchMap(() => this.agencyService.list$),
      tap(() => this.initForm()),
      map((respose: PageableResponse<Agency>) => respose.content),
      tap((agencies: Agency[]) => this.agencies = agencies)
    );
    this.agencyService.fetchAll(this.queryParams);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      dateRange: this.formBuilder.group({
        from: [moment().startOf('month'), Validators.required],
        to: [moment().endOf('month'), Validators.required],
      }),
      salaryPlanId: [null, Validators.required]
    });

    this.loader = false;
  }

  public onSubmit() {
    const rawForm: SalaryReport = this.form.getRawValue();
    rawForm.dateRange.from = moment(rawForm.dateRange.from).format('YYYY-MM-DDTHH:mm:ss') + '.000Z';
    rawForm.dateRange.to = moment(rawForm.dateRange.to).format('YYYY-MM-DDTHH:mm:ss') + '.000Z';

    const agency = this.agencies.find(a => a.id === rawForm.salaryPlanId);

    delete agency.version;
    delete agency.id;

    Object.assign(rawForm, agency);

    this.create(rawForm);
  }

  public create(form) {
    this.salaryReportService.save(form)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          throw (error);
        })
      )
      .subscribe(
        (report: SalaryReport) => {
          this.notifierService.notify('success', this.translateService.instant('Agency has been created!'));
          this.navService.goToPage(`/salary/report/list`, { modifiedRow: report.id });
          this.submitted = false;
        },
        error => {
          console.log(`Error occurred, please try again!`, error);
          this.submitted = false;
        },
        () => {
          this.submitted = false;
        }
      );
  }


}
