import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ShiftsService } from '@services/shifts.service';
import { ShiftConfig } from './entities/shiftConfig';
import { CutoffInterface } from './interafces/cutoff.interface';
import { Permissions } from '@enums/permissions';
import { Intergration } from './interafces/integration.interface';
import { ShiftSettingsService } from './services/shift-settings.service';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IShiftStatus } from './interafces/shift-status.interface';
import { ShiftStatus, IntegrationShiftType } from '@enums/enum';
import { parseInt } from 'lodash';
import { ShiftCutoffData } from './interafces/shift-cutoff-data.interface';
import { Shiftid } from './interafces/shiftid.interface';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { map } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';

@Component({
    selector: 'cutoff-details',
    template: `
        <div class="editable-window shift-details" *ngIf="mode == 'view'">


            <h3 class="d-flex justify-content-between align-items-center title">
                <div class="title">{{ 'Cut-off' | translate }}</div>
                <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white pd-t-4 pd-b-4 tx-11" (click)="addNewCutoff()">{{ 'Add new cutoff' | translate }}</button>
            </h3>

            <div class="alert alert-warning tx-12" *ngIf="shiftConfig.shiftType === integrationShiftType.EXPRESS">{{'Shift in type EXPRESS cannot be modify' | translate}}</div>


            <div class="editable-content">

                <ng-container *ngFor="let cutoff of shiftConfig; let index = index">

                <foldable-container [toggleIcon]="true" [showFoldedTitle]="true"
                        [isFolded]="true">

                        <div class="title pd-t-7 pd-b-7">
                            
                            <div class="d-flex align-items-center"> 
                                <div class="mg-r-8" placement="top" [containerClass]="'pretty-tooltip'" [tooltip]="('Maximum deliveries: %s% Maximum routes: %d%' | translate:{s: (cutoff.maximumDeliveries === -1) ? 'wyłączone' : cutoff.maximumDeliveries, d: (cutoff.maximumRoutes === -1) ? 'wyłączone' : cutoff.maximumRoutes})">
                                    <span>{{ cutoff.time.substr(0,5) }}</span>
                                    <span *ngIf="cutoff.offsetDays != 0"> ({{cutoff.offsetDays}} {{'day' | translate}})</span>
                                    <span *ngIf="cutoff.maximumDeliveries !== -1">, {{'deliveries' | translate }}: {{cutoff.maximumDeliveries}}</span>
                                    <span *ngIf="cutoff.maximumDeliveries === -1">, {{'deliveries' | translate }}: {{'disabled' | translate}}</span>

                                    <span *ngIf="cutoff.maximumRoutes !== -1">, {{'routes' | translate }}: {{cutoff.maximumRoutes}}</span>
                                    <span *ngIf="cutoff.maximumRoutes === -1">, {{'routes' | translate }}: {{'disabled' | translate}}</span>

                                <i-feather *ngIf="cutoff.status === 'COMPLETED'" [style.margin-left.px]="5" [style.width.px]="18" [style.color]="green" name="check"></i-feather>
                                </div>
                                <button *ngIf="cutoff.status === 'OPEN' && shiftConfig.shiftType !== integrationShiftType.EXPRESS" class="btn btn-xs btn-white tx-11 p-1" appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]" (click)="onModify(index)">{{ 'modify' | translate }}</button>
                            </div>
                        </div>

                        <div class="content">

                            <div *ngIf="cutoff.status === 'COMPLETED'" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div [style.height.px]="24">
                                    <span>{{ 'Cutoff completed at' | translate  }}:</span>
                                </div> 
                                <div class="modify-value">{{ cutoff.modifiedAt | date:'d.MM.yyyy, HH:mm:ss' }}</div>
                            </div>
                
                            <div *ngFor="let control of stringControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div [style.height.px]="24">
                                    <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                                    <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                        &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                                    </sup>
                                </div> 
                                <div class="modify-value">{{ cutoff[control].toString() | translate }}</div>
                            </div>

                            <div *ngFor="let control of booleanControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div [style.height.px]="24">
                                    <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                                    <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                        &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                                    </sup>
                                </div> 
                                <div class="modify-value">{{ cutoff[control].toString() | translate }}</div>
                            </div>

                          
                        <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div>{{ 'Day offset' | translate }}:</div> 
                                <div class="modify-value">
                                <ng-container [ngSwitch]="cutoff['offsetDays']">
                                    <ng-container *ngSwitchCase="-1">{{ 'Day before' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="-2">{{ 'Two days before' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="-3">{{ 'Three days before' | translate }}</ng-container>
                                    <ng-container *ngSwitchCase="0">{{ 'Same day' | translate }}</ng-container>
                                </ng-container>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div>{{ 'Maximum deliveries' | translate }}:</div> 
                                <div class="modify-value">
                                    <span *ngIf="cutoff.maximumDeliveries === -1">{{ 'Disabled' | translate }}</span>
                                    <span *ngIf="cutoff.maximumDeliveries !== -1">{{ cutoff.maximumDeliveries }}</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('maximumProductLines')">{{ 'Maximum product lines' | translate }}:</div>
                                <div class="modify-value">
                                    <span *ngIf="cutoff.maximumProductLines === -1">{{ 'Disabled' | translate }}</span>
                                    <span *ngIf="cutoff.maximumProductLines !== -1">{{ cutoff.maximumProductLines | number: '1.0-0' }}</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('maximumRoutes')">{{ 'Maximum routes' | translate }}:</div>
                                <div class="modify-value">
                                    <span *ngIf="cutoff.maximumRoutes === -1">{{ 'Disabled' | translate }}</span>
                                    <span *ngIf="cutoff.maximumRoutes !== -1">{{ cutoff.maximumRoutes | number: '1.0-0' }}</span>
                                </div>
                            </div>

                            <div *ngFor="let control of numberControls" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                                <div [style.height.px]="24">
                                    <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                                    <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                        &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                                    </sup>
                                </div> 
                                <div class="modify-value">{{ cutoff[control].toString() | translate }}</div>
                            </div>

                        </div>
                    </foldable-container>
                </ng-container>
            </div>
        </div>

        <div [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'" *ngIf="mode == 'edit' || mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <div class="title">
                    <h3 class="title">{{ 'Cut-off' | translate }}</h3>
                </div>

                <div class="form">
                    <div *ngIf="isNewCutoff" class="tx-danger tx-sans tx-13 tx-semibold mg-b-10">
                        {{'You are now in the process of adding a new cutoff_Please make sure to save it to complete the addition' | translate}}
                    </div>

                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <ng-container *ngIf="editedShiftConfig[indexCutoff] as editedCutoff">

                        <div *ngFor="let control of stringControls; let index = index">
                            <div>
                                <span>{{ 'shiftProperty.'+control | translate }}:</span>
                                <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                    &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                                </sup>
                            </div> 
                            <input [disabled]="initialFinalCutoff.time === editedCutoff.time && control == 'time'" id="control{{index}}" type="text" [(ngModel)]="editedCutoff[control]" />
                        </div>

                        <div *ngFor="let control of booleanControls; let index = index"> 
                            <div>
                                <span>{{ 'shiftProperty.'+control | translate  }}:</span>
                                <sup *ngIf="control | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                    &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.'+control | translate  }}:</i-feather>
                                </sup>
                            </div> 
                            <select [id]="control" [(ngModel)]="editedCutoff[control]">
                                <option [ngValue]="true">{{ 'True' | translate }}</option>
                                <option [ngValue]="false">{{ 'False' | translate }}</option>
                            </select>
                        </div>

                        <div *ngFor="let control of numberControls; let index = index">
                            <div>
                                <span>{{ 'shiftProperty.'+control | translate }}:</span>
                                <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel(control)">
                                    &nbsp;<i-feather [style.width.px]="10" name="info">{{ control }}:</i-feather>
                                </sup>
                            </div> 
                            <input id="control{{index}}" type="number" [min]="0" [(ngModel)]="editedCutoff[control]" />
                        </div>
                       
                        <div>
                            <div>
                                <span>{{ 'Day offset' | translate }}:</span>
                                <sup [containerClass]="'pretty-tooltip'" [tooltip]="tooltipLabel('offsetDays')">
                                    &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'offsetDays' }}:</i-feather>
                                </sup>
                            </div> 
                            <select [(ngModel)]="editedCutoff['offsetDays']" [id]="item" class="custom-select" [disabled]="initialFinalCutoff.time === editedCutoff.time">
                                <option value="-1">{{ 'Day before' | translate }}</option>
                                <option value="-2">{{ 'Two days before' | translate }}</option>
                                <option value="-3">{{ 'Three days before' | translate }}</option>
                                <option value="0">{{ 'Same day' | translate }}</option>
                            </select>
                        </div>
                        <div>
                            <label for="maximumRoutes">{{ 'Maximum routes' | translate }}:</label>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" [checked]="editedCutoff['maximumRoutes'] > 0 || !editedCutoff['maximumRoutes']" id="customSwitch3" (change)="maximumRoutesCheckboxFun($event)">
                                    <label class="custom-control-label" for="customSwitch3">&nbsp;</label>
                                </div>
                                <div class="flex-grow-1 mg-t-2">
                                    <input *ngIf="!editedCutoff['maximumRoutes'] || editedCutoff['maximumRoutes'] > 0" id="maximumRoutes" type="number" class="form-control" [(ngModel)]="editedCutoff.maximumRoutes" />
                                </div>
                            </div>
                            <p *ngIf="shiftConfig.length !== indexCutoff + 1 && ((!editedCutoff['maximumRoutes'] || editedCutoff['maximumRoutes'] <= 0) && (!editedCutoff['maximumDeliveries'] || editedCutoff['maximumDeliveries'] <= 0))"
                                class="tx-danger tx-sans tx-12 tx-semibold">{{'For all coutoffs except the last one, the number of routes or the number of deliveries must be set' | translate}}</p>
                        </div>
                        <div>
                        <label for="maximumDeliveries">{{ 'Maximum deliveries' | translate }}:</label>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" [checked]="editedCutoff['maximumDeliveries'] > 0 || !editedCutoff['maximumDeliveries']" id="customSwitch1" (change)="maximumDeliveriesCheckboxFun()">
                                    <label class="custom-control-label" for="customSwitch1">&nbsp;</label>
                                </div>
                                <div class="flex-grow-1 mg-t-2">
                                    <input *ngIf="!editedCutoff['maximumDeliveries'] || editedCutoff['maximumDeliveries'] > 0" id="maximumDeliveries" type="number" class="form-control" [(ngModel)]="editedCutoff.maximumDeliveries" />
                                </div>
                            </div>
                            <p *ngIf="shiftConfig.length !== indexCutoff + 1 && ((!editedCutoff['maximumRoutes'] || editedCutoff['maximumRoutes'] <= 0) && (!editedCutoff['maximumDeliveries'] || editedCutoff['maximumDeliveries'] <= 0))"
                                class="tx-danger tx-sans tx-12 tx-semibold">{{'For all coutoffs except the last one, the number of routes or the number of deliveries must be set' | translate}}</p>
                        </div>
                        <div>
                            <label for="maximumProductLines">{{ 'Maximum product lines' | translate }}:</label>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" [checked]="editedCutoff['maximumProductLines'] > 0 || !editedCutoff['maximumProductLines']" id="customSwitch2" (change)="maxProductLinesCheckboxFun($event)">
                                    <label class="custom-control-label" for="customSwitch2">&nbsp;</label>
                                </div>
                                <div class="flex-grow-1 mg-t-2">
                                    <input *ngIf="!editedCutoff['maximumProductLines'] || editedCutoff['maximumProductLines'] > 0" id="maximumProductLines" type="number" class="form-control" [(ngModel)]="editedCutoff.maximumProductLines" />
                                </div>
                            </div>
                        </div>

                        <div *ngIf="initialFinalCutoff.time !== editedCutoff.time && !isNewCutoff">
                            <button class="btn btn-xs btn-danger pd-t-4 mg-t-4 pd-b-4 tx-11" (click)="removeCutoff(editedCutoff)">{{ 'Remove cutoff' | translate }}</button>
                        </div>

                    </ng-container>

                </div>
            </editor-window>
        </div>
        <ng-template #modifiedShiftConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>{{ 'Do you want to reove the shift cutoff' | translate }}?</p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate: modalArgs.values }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate: modalArgs.values }}
                </button>
            </div>
        </ng-template>
    `,
    styles: [`
        foldable-container .contentContainer {
            padding: 0;
        }

        input:disabled {
            background-color: #fafafa;
            cursor: not-allowed;
            color: #7987a1;
        }
    `]
})
export class CutoffDetailsComponent implements OnInit, OnDestroy {

    @Input()
    public shiftConfig: ShiftCutoffData[] = null;

    @Input()
    public shiftId: Shiftid = null;

    @Input()
    public shiftStatusData: IShiftStatus = null;

    @Output()
    public shiftConfigChanged: EventEmitter<ShiftConfig> = new EventEmitter();

    public mode = 'view';

    public editedShiftConfig: ShiftCutoffData[] = null;
    public cutoffDiffOptions: CutoffInterface[] = [];
    public cutoffHoursOptions: CutoffInterface[] = [];
    public cutoffMinutesOptions: CutoffInterface[] = [];
    public Permissions = Permissions;
    public timeToClose = '';
    public cutOffTimeMinutes = '00';
    private updateFulfilmentSub$: Subscription;
    public shiftStatus = ShiftStatus;
    public integrationShiftType = IntegrationShiftType;

    public maxProductLinesCheckbox: boolean = false;
    public maxDeliveriesCheckbox: boolean = false;
    public indexCutoff = 0;

    public booleanControls: string[] = ['autoGenerateSolutions', 'autoPromoteSolution', 'autoFinalizeSolution'];
    public numberControls: string[] = ['cutoffTimeout'];
    public stringControls: string[] = ['time'];

    @ViewChild('modifiedShiftConfirmation', { static: true }) public modifiedShiftConfirmation: TemplateRef<any>;
    public modalRef: BsModalRef;
    public message: string;
    public modalArgs = { values: {} };
    public confirmObservable: Observable<any>;
    public initialFinalCutoff;
    public isNewCutoff = false;

    constructor(
        private shifts: ShiftsService, 
        private translate: TranslateService,
        private readonly shiftSettingsService: ShiftSettingsService,
        private readonly modalService: BsModalService,
        private readonly notifierService: NotifierService,

    ) {}

    public ngOnInit() {
        this.shiftConfig.forEach((cutoff: ShiftCutoffData) => {
            delete cutoff['shiftId'];
            delete cutoff['version'];
            delete cutoff['id'];
            delete cutoff['createdAt'];
            delete cutoff['cutoff'];
        });

        this.initialFinalCutoff = _.maxBy(this.shiftConfig, (cutoff) => this.timeToSeconds(cutoff.time) + (cutoff.offsetDays * 100000));
    }

    public switchMode(mode: string) {
        this.mode = mode;
    }

    public onModify(index: number) {
        this.editedShiftConfig = _.cloneDeep(this.shiftConfig);
        this.indexCutoff = index;
        this.mode = 'edit';
    }

    public onCancel() {
        this.editedShiftConfig = null;
        this.mode = 'view';

        if (this.isNewCutoff) {
            this.shiftConfig.shift();
        }
        this.isNewCutoff = false;
    }

    public addNewCutoff() {
        if (this.shiftConfig.length) {
            this.shiftConfig.unshift({
                ...this.shiftConfig[this.shiftConfig.length - 1],
                time: this.addHourToTime(this.shiftConfig[this.shiftConfig.length - 1].time),
                offsetDays: this.initialFinalCutoff.time == '00:00:00' && this.initialFinalCutoff.offsetDays == 0 ? -1 : this.initialFinalCutoff.offsetDays,
                maximumDeliveries: -1,
                maximumProductLines: -1,
                maximumRoutes: -1,
            });
        } else {
            this.shiftConfig.push({
                status: "OPEN",
                maximumDeliveries: -1,
                maximumProductLines: -1,
                maximumRoutes: -1,
                offsetDays: 0,
                time: '10:00:00',
                cutoffTimeout: 900,
                autoGenerateSolutions: true,
                autoPromoteSolution: true,
                autoFinalizeSolution: true,
                overbooked: false,
            } as ShiftCutoffData);
        }

        this.isNewCutoff = true;
        this.onModify(0);
    }

    public onSave() {
        this.mode = 'saving';

        //Check if after initial final cutoff
        let exist = this.editedShiftConfig.find((c) => {
            const seconds1 = this.timeToSeconds(c.time);
            const seconds2 = this.timeToSeconds(this.initialFinalCutoff.time);
            if (seconds1 > seconds2 && c.offsetDays >= this.initialFinalCutoff.offsetDays) {
                return true;
            }
        });

        if (exist) {
            this.notifierService.notify('error', this.translate.instant('You cannot create a cutoff after the initial final cutoff!'));
            this.mode = 'edit';
            return;
        }

        //Check if is the same cutoff
        const filteredArray = this.editedShiftConfig.map(obj => ({ time: obj.time, dayOffset: obj.offsetDays }));
        const uniqueArray = _.uniqWith(filteredArray, _.isEqual);
    
        if (uniqueArray.length !== filteredArray.length) {
            this.notifierService.notify('error', this.translate.instant('You cannot create a cutoff identical cut-off time already exists!'));
            this.mode = 'edit';
            return;
        }

        if (this.shiftConfig.length !== this.indexCutoff + 1) {
            const maximumDeliveries = this.editedShiftConfig[this.indexCutoff].maximumDeliveries;
            const maximumRoutes = this.editedShiftConfig[this.indexCutoff].maximumRoutes;

            if (!maximumDeliveries && !maximumRoutes) {
                this.notifierService.notify('error', this.translate.instant('For all coutoffs except the last one, the number of routes or the number of deliveries must be set'));
                this.mode = 'edit';
                return;
            }

            if (maximumDeliveries <= 0 && maximumRoutes <= 0) {
                this.notifierService.notify('error', this.translate.instant('For all coutoffs except the last one, the number of routes or the number of deliveries must be set'));
                this.mode = 'edit';
                return;
            }

            
        }

        this.editedShiftConfig.forEach((shift: ShiftCutoffData) => {
            if (!shift.maximumDeliveries) {
                shift.maximumDeliveries = -1;
            }
            if (!shift.maximumRoutes) {
                shift.maximumRoutes = -1;
            }
            if (!shift.maximumProductLines) {
                shift.maximumProductLines = -1;
            }
        })

        this.updateFulfilmentSub$ = this.shiftSettingsService.updateCutoffs(this.shiftId, this.editedShiftConfig)
            .subscribe((updatedShiftConfig: ShiftConfig) => {
                this.editedShiftConfig = null;
                this.mode = 'view';
                this.shiftConfigChanged.next(updatedShiftConfig);
                this.isNewCutoff = false;
            },
            (error: HttpErrorResponse) => {
                this.mode = 'edit';
            });

    }

    public addHourToTime(time: string): string {
        let timeString = this.shiftConfig[this.shiftConfig.length - 1].time;
        let timeParts = timeString.split(":");
        let hours = parseInt(timeParts[0]) - 1;

        let formattedHours;

        if (hours < 0) {
            hours = 23;
            formattedHours = hours.toString().padStart(2, '0');
        } else {
            hours = hours % 24;
            formattedHours = hours.toString().padStart(2, '0');
        }
    
        return `${formattedHours}:${timeParts[1]}:${timeParts[2]}`;
    }

    public timeToSeconds(time) {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return (hours * 3600) + (minutes * 60) + seconds;
    }

    public maxProductLinesCheckboxFun(event: Event): void {
        this.editedShiftConfig[this.indexCutoff].maximumProductLines = (this.editedShiftConfig[this.indexCutoff].maximumProductLines >= 0) ? -1 : 1;
    }

    public maximumRoutesCheckboxFun(index: number): void {
        this.editedShiftConfig[this.indexCutoff].maximumRoutes = (this.editedShiftConfig[this.indexCutoff].maximumRoutes >= 0) ? -1 : 1;
    }

    public maximumDeliveriesCheckboxFun(event): void {
        this.editedShiftConfig[this.indexCutoff].maximumDeliveries = (this.editedShiftConfig[this.indexCutoff].maximumDeliveries >= 0) ? -1 : 1;
    }

    public tooltipLabel(property: string): string {
        return tooltipLabel(this.translate, `tooltips.shift.labels.${property}`);
    }

    public removeCutoff(cutoff: ShiftCutoffData) {
        const payload = _.cloneDeep(this.editedShiftConfig);

        const indexToDelete = this.editedShiftConfig.findIndex((c: ShiftCutoffData) => c.time === cutoff.time && c.offsetDays === cutoff.offsetDays );
        if (indexToDelete !== -1) {
            payload.splice(indexToDelete, 1);
        }
        this.modalRef = this.modalService.show(this.modifiedShiftConfirmation, { class: 'modal-md' });

        this.confirmObservable = this.shiftSettingsService.updateCutoffs(this.shiftId, payload).pipe(
            map((updatedShiftConfig: ShiftConfig) => {
                this.editedShiftConfig = null;
                this.mode = 'view';
                this.shiftConfigChanged.next(updatedShiftConfig);
            })),
            (error: HttpErrorResponse) => {
                this.mode = 'view';
            };
        
    }

    public confirm(): void {
        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.confirmObservable = null;
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }

    ngOnDestroy() {
        if (this.updateFulfilmentSub$) {
            this.updateFulfilmentSub$.unsubscribe();
        }
    }
}

function padNumber(n) {
    return n > 9 ? '' + n : '0' + n;
}
