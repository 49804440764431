import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AgmCoreModule } from '@agm/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FormService } from '@services/form.service';
import { NavService } from '@services/nav.sevice';

import { AuthenticationModule } from '../authentication/authentication.module';
import { SharedModule } from '../shared.module';
import { LocationEditComponent } from './components/location-edit.component';
import { LocationsComponent } from './components/locations.component';
import { LocationsRouting } from './locations.routing';
import { GeocodingService } from './services/geocoding.service';
import { LocationsService } from './services/locations.service';
import { DepotComponent } from './components/depot/depot.component';
import { DepotEditComponent } from './components/depot-edit/depot-edit.component';
import { DepotMapComponent } from './components/depot-map/depot-map.component';
import { NgxMaskModule } from 'ngx-mask';
import { DepotAssignUsersComponent } from './components/depot-assign-users/depot-assign-users.component';
import { DepotResolver } from './services/depot.resolver';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DepotAssignedUsersResolver } from './services/depot-assigned-users.resolver';
import { TooltipModule } from 'ngx-bootstrap';
import { DepotAssignDriversComponent } from './components/depot-assign-drivers/depot-assign-drivers.component';
import { DepotAssignedUDriversResolver } from './services/depot-assigned-drivers.resolver';
import { DriversAllResolver } from '@drivers/resolvers/drivers-all.resolver';
import { DepotAssignVansComponent } from './components/depot-assign-vans/depot-assign-vans.component';
import { VansAllResolver } from '@hardware/resolvers/vans-all.resolver';
import { DepotAssignedVansResolver } from './services/depot-assigned-vans.resolver';
import { DepotAssignHardwareComponent } from './components/depot-assign-hardware/depot-assign-hardware.component';
import { DepotAssignedHardwareResolver } from './services/depot-assigned-hardware.resolver';
import { HardwareAllResolver } from '@hardware/resolvers/hardware-all.resolver';
import { DepotVirtualListComponent } from './components/depot-virtual-list/depot-virtual-list.component';
import { DepotVirtualService } from './services/depot-virtual.service';
import { DepotVirtualEditComponent } from './components/depot-virtual-edit/depot-virtual-edit.component';
import { ProfanityFilterComponent } from './components/profanity-filter/profanity-filter.component';
import { ProfanityFilterService } from './services/profanity-filter.service';
import { ProfanityFilterEditComponent } from './components/profanity-filter-edit/profanity-filter-edit.component';
import { ProfanityFilterResolver } from './resolvers/profanity-filter.resolver';
import { CommentService } from './services/comment.service';
import { CommentResolver } from './resolvers/comment.resolver';
import { CommentListComponent } from './components/comment-list/comment-list.component';
import { environment } from '@environment';

@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.googleMapApiKey,
        }),
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule,
        LocationsRouting,
        NgxDatatableModule,
        ReactiveFormsModule,
        AuthenticationModule,
        NgxMaskModule.forRoot(),
        AutocompleteLibModule,
        TooltipModule.forRoot(),
    ],
    declarations: [
        LocationsComponent,
        LocationEditComponent,
        DepotComponent,
        DepotEditComponent,
        DepotMapComponent,
        DepotAssignUsersComponent,
        DepotAssignDriversComponent,
        DepotAssignVansComponent,
        DepotAssignHardwareComponent,
        DepotVirtualListComponent,
        DepotVirtualEditComponent,
        ProfanityFilterComponent,
        ProfanityFilterEditComponent,
        CommentListComponent,
    ],
    providers: [
        FormService,
        FormBuilder,
        LocationsService,
        NavService,
        GeocodingService,
        DepotResolver,
        DepotAssignedUsersResolver,
        DepotAssignedUDriversResolver,
        DriversAllResolver,
        VansAllResolver,
        DepotAssignedVansResolver,
        DepotAssignedHardwareResolver,
        HardwareAllResolver,
        DepotVirtualService,
        ProfanityFilterService,
        ProfanityFilterResolver,
        CommentService,
        CommentResolver,
    ],
    exports: [],
})
export class LocationsModule {}
