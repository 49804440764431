<layout-default>
    <div class="d-print-none" header>
        <div toolbar>
            <h4>
                <i-feather name="cast"></i-feather> {{'Shift payment summary' | translate}}
            </h4>
        </div>
        <div *ngIf="(depots$ | async)" toolbar>

            <date-range-picker class="flex-grow-1 pic-apm-maint-slot" [extendedOption]="false"
                (changedDateRange)="changedDateRange($event)" [defaultDateRange]="dateRange"></date-range-picker>
 
            <div class="label">
                <label>{{ 'Depots' | translate }}:</label>
            </div>

            <div class="input-control">
                <ng-multiselect-dropdown  class="form-control"
                    [placeholder]="'Select depots' | translate" [data]="dropdownList" [(ngModel)]="selectedItems"
                    [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div> 

            <div class="label">
                <label>{{ 'Type' | translate }}:</label>
            </div>

            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.type">
                    <option *ngFor="let type of paymentReportType" [value]="type">{{'DeepLinkType.' + type | translate}}</option>
                </select>
            </div>

            <div class="label">
                <label>{{ 'Merchants' | translate }}:</label>
            </div>
            
            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.merchants">
                    <option [value]="''" selected>{{'All merchants' | translate}}</option>
                    <option *ngFor="let merchant of merchantsType" [value]="merchant">{{merchant | translate}}</option>
                </select>
            </div>

            <button class="btn btn-brand-01 mg-l-15" target="_blank" (click)="loadData();">
                <i class="fas fa-download"></i> {{'Load data' | translate}}
            </button>

        </div>
        <div toolbar>
            <div class="label">
                <label>{{ 'Cutoff missing' | translate }}:</label>
            </div>

            <div class="input-control">
                <select class="custom-select" [(ngModel)]="queryParams.cutoffMissing">
                    <option [value]="true">{{'Yes' | translate}}</option>
                    <option [value]="false">{{'No' | translate}}</option>
                </select>
            </div>

            <div class="label">
                <label>{{ 'Payments' | translate }}:</label>
            </div>

            <div class="input-control">
                <ng-multiselect-dropdown  class="form-control"
                    [placeholder]="'Select' | translate" [data]="dropdownListPaymentMethods" [(ngModel)]="selectedItemsPaymentMethods"
                    [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings">
                </ng-multiselect-dropdown>
            </div>

            <div class="label">
                <label>{{ 'Driver' | translate }}:</label>
            </div>

            <div class="input-control">
                <app-search-customer 
                    [disabled]="queryParams.type == 'ROUTE'" 
                    [type]="'DRIVER'" (result)="queryParams.driverId = $event"></app-search-customer>
            </div>

            <div class="label">
                <label>{{ 'Customer' | translate }}:</label>
            </div>

            <div class="input-control">
                <app-search-customer 
                    [disabled]="queryParams.type == 'ROUTE'" 
                    [type]="'CUSTOMER'" (result)="queryParams.customerId = $event"></app-search-customer>
            </div>

            <div class="label">
                <label>{{ 'OrderId' | translate }}:</label>
            </div>

            <div class="input-control">
                <input type="text" [disabled]="queryParams.type === 'ROUTE'"  [(ngModel)]="queryParams.orderId" class="form-control" />
            </div>

            <button class="btn btn-brand-01 mg-l-15" target="_blank" (click)="export();">
                <i class="fas fa-download"></i> {{'Export' | translate}}
            </button>

        </div>
        <hr />
    </div>
  
    <div content>

        <div class="visible-print-block" *ngIf="(list$ | async) as list">

            <div *ngIf="list.length" class="table-responsive">
                <ngx-datatable 
                    id="payment-report" 
                    class="bootstrap" 
                    [rows]="list" 
                    [columns]="(queryParams.type === 'ROUTE') ? columnsRoute : columnsDelivery" 
                    [columnMode]="'standard'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [rowHeight]="'auto'" 
                    [externalPaging]="false"
                    [scrollbarH]="true"
                    [limit]="queryParamsConst.size" 
                    [rowClass]="rowClass">

                    <ng-container *ngFor="let col of (queryParams.type === 'ROUTE') ? columnsRoute : columnsDelivery">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop">
                            <ng-template let-column="column" class="header" let-sort="sortFn" ngx-datatable-header-template>
                                <span class="tx-bold " (click)="sort($event)">{{col.name | translate}}</span>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            

                                <ng-container [ngSwitch]="col.prop">
                                    <div *ngSwitchCase="'cutoffNotDone'">
                                        {{ row.cardCount - row.cutoffCount }}
                                    </div>

                                    <ng-container *ngIf="(queryParams.type === 'ROUTE')">
                                        <div *ngSwitchCase="'routeNumber'">
                                            {{ value }} 
                                            <a class="map" [routerLink]="['/', 'routes', row.year, row.month, row.day, row.shift, row.depotId, 'route', row.id]" target="_blank"> <i class="fas fa-external-link-alt"></i></a>
                                        </div>
                                    </ng-container>

                                    <div *ngSwitchCase="'deliveryId'">
                                        {{ value }} 
                                        <a class="map" 
                                        [routerLink]="['/', 'routes', row.year, row.month, row.day, row.shift, row.depotId, 'route', row.route, {deliveryId: row.deliveryId}]" 
                                        target="_blank"> <i class="fas fa-external-link-alt"></i></a>
                                    </div>

                                    <div *ngSwitchCase="'shift'">
                                        {{ value | translate }}
                                    </div>
                                  
                                    <div *ngSwitchDefault>
                                        {{ value }}
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>


            <div *ngIf="!loading && !list.length" class="empty-data" [style.height.vh]="74">
                <i-feather name="alert-circle"></i-feather>
                <div class="tx-11 mg-l-5">{{ 'No data to display' | translate }}</div>
            </div>
            

        </div>

        <div *ngIf="loading" class="load-spinner spinner" [style.height.vh]="74">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
            <p class="spinner__info">{{'Please, wait!' | translate}}</p>
        </div>



    </div>
</layout-default>