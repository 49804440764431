import { Pipe, PipeTransform } from '@angular/core';
import { DailyShiftConfig } from '@calendar/entities/dailyShiftConfig';
import { DayComposite } from '@calendar/entities/dayComposite';
import { ShiftConfig } from '@calendar/entities/shiftConfig';
import { ShiftExt } from '@calendar/interafces/shift-ext.interface';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'calendarTitlePipe',
  pure: true,
})
export class CalendarTitlePipe implements PipeTransform {
    constructor(
        private readonly translateService: TranslateService
    ) {}

  transform(dailyShiftConfig: DailyShiftConfig): string {

    let result = 0;
    let capacity = 0;

    dailyShiftConfig.shifts.forEach((shift: ShiftExt) => {
        result += Object.values(shift.statuses).reduce((acc, val) => acc + (typeof val === 'number' ? val : 0), 0);
        capacity += shift.fulfilment.maximumDeliveries;
    })
    
    const percentage = ((result * 100) / capacity).toFixed(2);
    

    return this.translateService.instant('%orders% ordeers, %percentage% of capacity', {orders: result, percentage});
  }
}
