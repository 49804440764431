import { Type } from 'class-transformer/decorators';
import * as _ from 'lodash';
import * as moment from 'moment';

import { RouteId } from '@entities/routeId';
import { DeliveryStatus, DeliveryType, GeneratedBy } from '@enums/enum';
import { serializeType } from '@shared/functions/serializeType.function';

import { Bag } from './bag';
import { Box } from './box';
import { Complaint } from './complaint';
import { Customer } from './customer';
import { Entity } from './Entity';
import { Item } from './item';
import { Location } from './location';
import { Order } from './order';
import { Payment } from './payment';
import { Serializable } from './Serializable';
import { Files } from '@interfaces/files.interface';

export class Delivery extends Entity implements Serializable<Delivery> {
    public altered: boolean;
    public areBoxesScanned: boolean = false;
    public coolomatCode: string;

    @Type(serializeType(Customer)) 
    public customer: Customer;

    public createdAt: string;
    public date: string;
    public deliveryNumber: number;
    public deliveryTime: string;
    public deliveryType: DeliveryType;
    public dispatcherNotes: string;
    public driverNotes: string;
    public earlyDeliveryPermission: boolean;
    public electronicInvoiceOnly: boolean;
    public generatedBy: GeneratedBy;
    public id: string;
    public isDeliveryOnTime;
    public modifiedAt: string;
    public orderId: string;
    public ref: string;
    public files: Files[];
    public shift: string;
    public status: string;
    public statusText: string;
    public version: number;
    public warehouse: string;
    public isAlcoholInItems: boolean;
    public hasSignature: boolean;
    public deliveryIndex: number = 0;
    public importComplete: boolean;
    public estimatedChilledBoxCount: number;
    public estimatedDryBoxCount: number;
    public estimatedFrozenBoxCount: number;
    public partnerId: string;
    public partnerName: string;

    public properties: {
        externalCustomerId?: string,
        fulfillmentCode?: string,
        hasFish?: boolean
        isBread?: boolean
        pickupCode?: string,
        serviceType?: string,
    }
    public isBeforeLoad: boolean;

    @Type(serializeType(Location))
    public location: Location;


    @Type(serializeType(Order)) 
    public order: Order;


    @Type(serializeType(Payment)) 
    public paymentInfo: Payment;

    @Type(serializeType(Payment)) 
    public paymentInfos: Payment[] = [];

    @Type(serializeType(RouteId)) 
    public originalRouteId: RouteId;


    get originalRouteNumber() {
        if (this.originalRouteId !== null) {
            return this.originalRouteId.routeNumber;
        }
        return null;
    }

    get complaintsAmount() {
        return _.chain(this.order.items)
                .groupBy('merchant')
                .map((items: Item[], merchant) => {
                    return { merchant: merchant,
                             value: _.sumBy(items, (item: Item) => _.sumBy(item.complaints, (c: Complaint) => c.complaintAmount))};
                })
                .reduce((obj, param) => {
                    obj[param.merchant] = param.value
                    return obj;
                   }, {})
                .value();
    }

    get deliveryLabel() {
        return this.isManual ? this.customer.ref : this.orderId;
    }

    get isManual() {
        return (this.generatedBy === GeneratedBy.MANUAL);
    }

    get isStatusDone() {
        return (this.status === DeliveryStatus.DONE);
    }

    get isStatusAssigned() {
        return (this.status === DeliveryStatus.ASSIGNED);
    }

    get isStatusFailure() {
        return (this.status === DeliveryStatus.FAILURE);
    }

    get isStatusRetry() {
        return (this.status === DeliveryStatus.RETRY);
    }

    get isAddressDifferent(): boolean {
        if (this.locationLabel) {
            return !_.isEqual(this.location.address, this.location.originalAddress);
        }
        return false;
    }

    get locationLabel(): string {
        let location = `${this.location.address.street} ${this.location.address.houseNO}`;

        if (this.location.additional.flatNO) {
            location += `/${this.location.additional.flatNO}`;
        }

        return location;
    }

    get isCoolomatDelivery(): boolean {
        return this.deliveryType === DeliveryType.COOLOMAT;
    }

    get isApmDelivery(): boolean {
        return this.deliveryType === DeliveryType.APM;
    }

    get statusClass() {
        const clazz: string[] = ['badge'];

        switch (this.status) {
            case DeliveryStatus.FAILURE:
                clazz.push('badge-warning');
                break;
            case DeliveryStatus.DONE:
                clazz.push('badge-success');
                break;
            default:
                clazz.push('badge-light');
        }

        return clazz.join(' ');
    }


    get friscoAddress(): string {
        let location = `${this.location.originalAddress.street} ${this.location.originalAddress.houseNO}, ${this.location.originalAddress.town} ${this.location.originalAddress.zip}`;

        if (this.location.additional.flatNO) {
            location += `/${this.location.additional.flatNO}`;
        }

        return location;
    }

    public deserialize(data: any) {
        if (!data) {
            return;
        }
        this.altered = _.get(data, 'altered');
        this.coolomatCode = _.get(data, 'coolomatCode');
        this.createdAt = _.get(data, 'createdAt');
        this.customer = (new Customer()).deserialize(_.get(data, 'customer'));
        this.date = _.get(data, 'date');
        this.deliveryNumber = _.get(data, 'deliveryNumber');
        this.deliveryTime = _.get(data, 'deliveryTime');
        this.deliveryType = _.get(data, 'deliveryType');
        this.dispatcherNotes = _.get(data, 'dispatcherNotes');
        this.driverNotes = _.get(data, 'driverNotes');
        this.earlyDeliveryPermission = _.get(data, 'earlyDeliveryPermission');
        this.electronicInvoiceOnly = _.get(data, 'electronicInvoiceOnly');
        this.generatedBy = _.get(data, 'generatedBy');
        this.id = _.get(data, 'id');
        this.location = (new Location()).deserialize(_.get(data, 'location'));
        this.modifiedAt = _.get(data, 'modifiedAt');
        this.order = (new Order()).deserialize(_.get(data, 'order'));
        this.orderId = _.get(data, 'orderId');
        this.originalRouteId = RouteId.parse(_.get(data, 'originalRouteId'));
        this.paymentInfo = (new Payment()).deserialize(_.get(data, 'paymentInfo'));
        this.importComplete = _.get(data, 'importComplete');
        this.estimatedChilledBoxCount = _.get(data, 'estimatedChilledBoxCount');
        this.estimatedDryBoxCount = _.get(data, 'estimatedDryBoxCount');
        this.estimatedFrozenBoxCount = _.get(data, 'estimatedFrozenBoxCount');
        this.files = _.get(data, 'files');

        this.properties = _.get(data, 'properties', {});
        
        _.get(data, 'paymentInfos', []).map((payment: any) => {
            this.paymentInfos.push(new Payment().deserialize(payment))     
        });

        this.ref = this.id.split(':')[2];
        this.shift = _.get(data, 'shift');
        this.status = _.get(data, 'status');
        this.statusText = _.get(data, 'statusText');
        this.version = _.get(data, 'version');
        this.warehouse = _.get(data, 'warehouse');
        this.hasSignature = _.get(data, 'hasSignature');
        this.partnerId = _.get(data, 'partnerId');
        this.partnerName = _.get(data, 'partnerName');
        
        this.areBoxesScanned = this.areBoxesScannedFun();
        this.isDeliveryOnTime = this.isDeliveryOnTimeFun();
        this.isBeforeLoad = !this.order.boxes.find(b => b.isLoaded);

        this.isAlcoholInItems = ((this.order.items).find((item: Item) => item.age >= 18) !== undefined) ? true : false;

        return this;
    }

    public serialize() {
        return {};
    }

    public get isDelivered() {
        return this.status === DeliveryStatus.DONE;
    }

    public get isFailed() {
        return this.status === DeliveryStatus.FAILURE;
    }

    public isDeliveryOnTimeFun() {
        if (!this.deliveryTime || !this.order.slot || this.order.slot === null) {
            return false;
        }


        const date = this.date;

        const deliveryTime = moment(this.deliveryTime);
        const slotStart = moment(date + 'T' + this.order.slot.split('-')[0]);
        const slotEnd = moment(date + 'T' + this.order.slot.split('-')[1]);
        const diffBefore = deliveryTime.diff(slotStart);
        const diffAfter = deliveryTime.diff(slotEnd);
        const slotStartMargin: number = 300000; // todo: has to be fetched from backend
        const slotEndMargin: number = 480000;

        let diffInSeconds: number = 0;

        let resultDiff;

        if (diffBefore < 0) {
            if (Math.abs(moment.duration(diffBefore).hours()) > 0) {
                resultDiff = `${moment.duration(diffBefore).hours()}h ${Math.abs(moment.duration(diffBefore).minutes())}m`;
            } else {
                resultDiff = `${moment.duration(diffBefore).minutes()}m`;
            }
            diffInSeconds = diffBefore / 1000;
        } else if (diffAfter > 0) {
            if (Math.abs(moment.duration(diffAfter).hours()) > 0) {
                resultDiff = `+${moment.duration(diffAfter).hours()}h ${moment.duration(diffAfter).minutes()}m`;
            } else {
                resultDiff = `+${moment.duration(diffAfter).minutes()}m`;
            }
            diffInSeconds = diffAfter / 1000;
        }

        return {
            slotStart: slotStart,
            slotEnd: slotEnd,
            deliveryTime: deliveryTime,
            diff: resultDiff,
            isBefore: diffBefore < -slotStartMargin,
            onTime: (diffBefore >= -slotStartMargin && diffAfter <= slotEndMargin),
            diffInSeconds
        };
    }

    private areBoxesScannedFun(): boolean {
        const allBoxes: Box[] = this.order.boxes;
        const allBags: Bag[] = [];

        allBoxes.forEach((box: Box) => {
            if (box.bags) {
                allBags.push(...box.bags);
            }
        });

    
        if (this.status === DeliveryStatus.DONE) {
            const resBox = allBoxes.filter((box: Box) => !box.mergedTo && !box.isScanned && !box.bags.length);
            const resBag = allBags.filter((bag: Bag) => !bag.isScanned);
            return (resBox.length + resBag.length === 0);
        } else {
            const resBox = allBoxes.filter((box: Box) => !box.mergedTo && !box.isLoaded && !box.bags.length);
            const resBag = allBags.filter((bag: Bag) => !bag.isLoaded);
    
            return (resBox.length + resBag.length === 0);
        }

        
    }
}
