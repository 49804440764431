import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuardService } from '@guards/permission.guard';
import { ProxyComponent } from '@shared/components/proxy.component';
import * as P from '@enums/permissions';
import { OperationsMainComponent } from 'projects/inpost/src/app/operations/components/operations.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CronjobSchedulerComponent } from './components/cronjob-scheduler/cronjob-scheduler.component';
import { CronjobSchedulerEditComponent } from './components/cronjob-scheduler-edit/cronjob-scheduler-edit.component';
import { CronJobSchedulerResolver } from './services/cronjob-scheduler.resolver';

const routes: Routes = [
    {
        path: 'system',
        component: ProxyComponent,
        data: {
            breadcrumb: 'Breadcrumbs.System'
        },
        children: [
            { path: '', redirectTo: 'notifications', pathMatch: 'full' },
            {
                path: 'notifications',
                component: OperationsMainComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.Notifications'
                },
                children: [
                    {
                        path: 'list',
                        component: NotificationComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Driver_edit],
                            breadcrumb: 'Breadcrumbs.Notifications'
                        }
                    },
                    { path: '**', redirectTo: 'list' }
                ]
            },
            {
                path: 'cron-job-scheduler',
                component: OperationsMainComponent,
                data: {
                    breadcrumb: 'Breadcrumbs.CronJobScheduler'
                },
                children: [
                    {
                        path: 'list',
                        component: CronjobSchedulerComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Driver_edit],
                            breadcrumb: 'Breadcrumbs.CronJobScheduler'
                        }
                    },
                    { 
                        path: 'create', 
                        component: CronjobSchedulerEditComponent,
                        canActivate: [PermissionGuardService],
                        data: {
                            expectedPermission: [P.Permissions.Hardware_edit],
                            breadcrumb: 'Breadcrumbs.CronJobSchedulerCreate'
                        }
                    },
                    { 
                        path: ':jobId', 
                        component: CronjobSchedulerEditComponent,
                        canActivate: [PermissionGuardService],
                        resolve: {
                            vehicleType: CronJobSchedulerResolver
                        },
                        data: {
                            expectedPermission: [P.Permissions.Hardware_edit],
                            breadcrumb: 'Breadcrumbs.CronJobSchedulerEdit'
                        }
                    },
                    { path: '**', redirectTo: 'list' }
                ]
            }
        ]
    }
];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class SystemRouting { }
