
        <div class="editable-window shift-details">
            <div class="editable-content">
                <div class="">
                    <h3 class="shift-switcher">{{ 'Shift status' | translate }}</h3>
                    <div class="dropdown" dropdown>
                        <button
                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ shiftStatus | translate }}
                        </button>
                        <div 
                            appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]"
                            class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="btn btn-outline-secondary btn-sm btn-uppercase btn-edit-delivery dropdown-item" (click)="changeShiftStatus('BOOKING')">{{
                                'SHIFT_STATUS_LABEL_BOOKING' | translate
                            }}</a>

                            <a class="btn btn-outline-secondary btn-sm btn-uppercase btn-edit-delivery dropdown-item" (click)="changeShiftStatus('CONFIGURING')">{{
                                'SHIFT_STATUS_LABEL_COMPLETED' | translate
                            }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #modifiedShiftConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>{{ 'Do you want to modified the shift status' | translate }}?</p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate: modalArgs.values }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate: modalArgs.values }}
                </button>
            </div>
        </ng-template>
    