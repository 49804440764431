import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuardService } from '@guards/permission.guard';
import { ProxyComponent } from '@shared/components/proxy.component';
import * as P from '@enums/permissions';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardHistoryComponent } from './components/dashboard-history/dashboard-history.component';
import { DashboardDeliverySalesComponent } from './components/dashboard-delivery-sales/dashboard-delivery-sales.component';
import { DashboardVehicleDistanceComponent } from './components/dashboard-vehicle-distance/dashboard-vehicle-distance.component';

const dashboardRoutes: Routes = [

    {
        path: '',
        component: ProxyComponent,
        data: {
            expectedPermission: [P.Permissions.Driver_view],
            breadcrumb: 'Breadcrumbs.Dashboard'
        },
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full'},

            {
                path: 'list', component: DashboardComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_view],
                    breadcrumb: 'Breadcrumbs.Dashboard'
                }
            },

            {
                path: 'history', component: DashboardHistoryComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_view],
                    breadcrumb: 'Breadcrumbs.Dashboard'
                }
            },

            {
                path: 'delivery-sales', component: DashboardDeliverySalesComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Driver_view],
                    breadcrumb: 'Breadcrumbs.DashboardDeliverySales'
                }
            },

            {
                path: 'vehicle-distance-history', component: DashboardVehicleDistanceComponent,
                canActivate: [PermissionGuardService],
                data: {
                    expectedPermission: [P.Permissions.Dashboard_view],
                    breadcrumb: 'Breadcrumbs.DashboardVehicleDistance'
                }
            },
            
            { path: '**', redirectTo: 'list' }
        ]
    }
   

];

@NgModule({
    imports: [RouterModule.forChild(dashboardRoutes)],
    exports: [RouterModule],
    providers: []
})
export class DashboardRouting {}
