import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RolesService } from './roles.service';
import { UsersService } from './users.service';

@Injectable()
export class UsersResolver implements Resolve<any> {
    constructor(private readonly userService: UsersService) {}

    public resolve(route: ActivatedRouteSnapshot) {
        const params = route.params;
        return this.userService.getUsers();
    }
}
