import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { UtilsService } from '@services/utils.service';
import { environment } from '@environment';
import { Notification } from '../interfaces/notification';
import { map } from 'rxjs/operators';
import { orderBy } from 'lodash';

@Injectable()
export class NotificationService {
    constructor(
        private readonly http: HttpClient, 
        private readonly translate: TranslateService, 
        private readonly utilsService: UtilsService
    ) {}

    public static ALL: string = 'notification/v1/all';
    public static NEW: string = 'notification/v1/new';
    public static MARK_AS_READ: string = 'notification/v1/${notificationId}/read';


    private readonly host = environment.api.url;
    private readonly prefix = environment.api.prefix;

    public getAll(): Observable<Notification[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${NotificationService.ALL}`, { });
        return this.http.get<Notification[]>(endpoint, this.utilsService.httpHeaders())
    }

    public getNew(): Observable<Notification[]> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${NotificationService.NEW}`, { });
        return this.http.get<Notification[]>(endpoint, this.utilsService.httpHeaders()).pipe(
            map((notification: Notification[]) => orderBy(notification, (n) => n.date))
        )
    }

    public markAsRead(id: number): Observable<Notification> {
        const endpoint = this.utilsService.interpolate(`${this.host}${this.prefix}/${NotificationService.MARK_AS_READ}`, {notificationId: id});
        return this.http.post<Notification>(endpoint, null, this.utilsService.httpHeaders())
    }
}
