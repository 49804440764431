import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'closestTimePipe',
  pure: true,
})
export class ClosestTimePipe implements PipeTransform {

  transform(times: { cutoff: string }[], target: string): string | null {
    if (!times || times.length === 0 || !target) {
      return null;
    }

    const targetTime = new Date(target).getTime();

    const closest = times.reduce((prev, curr) => {
      const prevTime = new Date(prev.cutoff).getTime();
      const currTime = new Date(curr.cutoff).getTime();
      return Math.abs(currTime - targetTime) < Math.abs(prevTime - targetTime) ? curr : prev;
    });

    const closestTime = new Date(closest.cutoff);
    return closestTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

}
