import { Pipe, PipeTransform } from '@angular/core';
import { CutoffSolutions } from '../interfaces/cutoff-solutions.interface';
import { countBy } from 'lodash';

@Pipe({
    name: 'countBySolutionStatusPipe',
    pure: true
})
export class CountBySolutionStatusPipe implements PipeTransform {
    public transform(solutions: CutoffSolutions[], solutionKey: string): Object {
        const simulations = solutions.filter((s: CutoffSolutions) => s.simulationKey === solutionKey);
        return countBy(simulations, (s: CutoffSolutions) => s.status);
    }
}
