import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-loader',
  template: `
  <div *ngIf="loader" class="load-spinner spinner">
      <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
      <p class="tx-11 mg-t-10">{{ text | translate }}</p>
  </div>
  `,
  styles: [`
    p {
      font-size: 11px;
    }
  `]
})
export class LoaderComponent {

  @Input() public loader: boolean = false;
  @Input() public text: string = this.translateService.instant('Please, wait!');

  constructor(private translateService: TranslateService) { }


}
