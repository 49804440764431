import * as _ from 'lodash';

import { Entity } from '@entities/Entity';
import { Serializable } from '@entities/Serializable';
import { VanStatus } from '@enums/enum';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';

export class Van extends Entity implements Serializable<Van> {
    public clazz = 'VAN';

    public id: string = '';
    public registration: string = '';
    public brand: string = '';
    public owner: string;
    public status: string = VanStatus.AVAILABLE;
    public version: string = '';
    public vehicleType: VehicleTypes;
    public vehicleTypeId: string;
    public scanForDamage: boolean;
    public properties: {
        fuelCardNr?: string;
        fuelCardPin?: string;
    }

    get label() {
        return `${this.registration} ${this.brand}`;
    }

    public deserialize(data: any) {
        this.id = _.get(data, 'id', '');
        this.registration = _.get(data, 'registration', '');
        this.brand = _.get(data, 'brand', '');
        this.owner = _.get(data, 'owner');
        this.status = _.get(data, 'status');
        this.vehicleType = _.get(data, 'vehicleType');
        this.vehicleTypeId = (this.vehicleType) ? this.vehicleType.id : undefined;
        this.version = _.get(data, 'version');
        this.properties = _.get(data, 'properties', {});
        this.scanForDamage = _.get(data, 'scanForDamage', false);

        return this;
    }

    public serialize() {
        return {
            id: this.id,
            registration: this.registration,
            brand: this.brand,
            owner: this.owner,
            status: this.status,
            vehicleTypeId: this.vehicleTypeId,
            properties: this.properties,
            scanForDamage: this.scanForDamage,
            version: this.version
        };
    }
}
