<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="bar-chart-2"></i-feather> {{'Routing efficiency by order' | translate}}
            </h4>
        </div>
        <div toolbar>
            <div><label>{{'From' | translate}}:</label></div>
            <ng-datepicker *ngIf="locale" [style.flex-grow]="'4'" #datepicker class="datepicker-input" [(ngModel)]="startDate"
                [options]="datePickerOptions"></ng-datepicker>
            <div><label>{{'To' | translate}}:</label></div>
            <ng-datepicker *ngIf="locale" [style.flex-grow]="'4'" #datepicker class="datepicker-input" [(ngModel)]="endDate"
                [options]="datePickerOptions"></ng-datepicker>
            <button
                [style.flex-grow]="'2'"
                (click)="addNewTimePeriod()"
                class="btn btn-sm pd-x-15 btn-uppercase mg-l-5 btn-brand-01"><i-feather name="plus"></i-feather>  {{'Add new time period' | translate}}
            </button>
        </div>
        <hr />
    </div>

    <div content>
        <table class="table table-hover" *ngIf="routingEfficiencyResults">
            <thead class="table__head">
                <tr>
                    <th>{{'Time period' | translate}}</th>
                    <th width="15%" *ngFor="let shift of shiftType">{{shift | translate}}</th>
                    <th width="15%">{{'All shifts' | translate}}</th>
                </tr>
            </thead>
            <tbody class="table__body">
                <tr *ngFor="let period of periodOfTime">
                    <td>{{period.label}}</td>
                    <td *ngFor="let shift of shiftType">{{findResult(period.startDate, period.endDate, shift)}}</td>
                    <td>{{findResult(period.startDate, period.endDate, 'ALL SHIFTS')}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</layout-default>
