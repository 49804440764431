import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { ShiftConfig } from './entities/shiftConfig';
import { Permissions } from '@enums/permissions';
import { Fulfilment } from './interafces/fulfilment.interface';
import { ShiftSettingsService } from './services/shift-settings.service';
import { Subscription, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ShiftFleet } from './interafces/shift-fleet.interfae';
import { VehicleTypesService } from '@hardware/services/vehicle-types.service';
import { VehicleTypes } from '@hardware/interfaces/vehicle-types.interface';
import { PageableResponse } from '@entities/pagable-response';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { IShiftStatus } from './interafces/shift-status.interface';
import { ShiftStatus } from '@enums/enum';

@Component({
    selector: 'vans-details',
    template: `
        <div class="editable-window shift-details" *ngIf="mode == 'view'">
            <h3>
                {{ 'Fleet size' | translate }} <button *ngIf="(shiftStatusData.status | isShiftManageablePipe)" class="btn btn-xs btn-white" appCheckFeaturesConfig [featureName]="[Permissions.Shift_fulfillment_edit]" (click)="onModify()">{{ 'modify' | translate }}</button>
            </h3>

            <div class="editable-content">
                <div *ngFor="let fleet of shiftConfig.fleet" class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                    <div>{{ fleet.vehicleType.code }}:</div> 
                    <div class="modify-value">{{ fleet.max  }}</div>
                </div>
              
            </div>
        </div>

        <div *ngIf="mode == 'edit' || mode == 'saving'" [class.edit]="mode == 'edit'" [class.saving]="mode == 'saving'">
            <editor-window (cancel)="onCancel()" (save)="onSave()">
                <h3 class="title">{{ 'Fleet size' | translate }}</h3>

                <div *ngIf="(vehicleTypes$ | async) as vehicleTypes" class="form">
                    
                    <p *ngIf="shiftStatusData.status === shiftStatus.PLANNING" class="tx-12 tx-bold tx-color-01 tx-sans">{{'Changing this shift while it is being planned will disable automatic cutoffs You will need to cutoff this shift manually if you continue' | translate}}</p>

                    <div *ngFor="let fleet of editedShiftConfig.fleet; let index = index">
                        
                        <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                            <div [style.width.%]="30">{{ 'Code' }}:</div> 
                            <div [style.width.%]="70" class="modify-value">
                                <select id="vehicleTypeCode" [(ngModel)]="editedShiftConfig.fleet[index].vehicleTypeId">
                                    <option *ngFor="let option of vehicleTypes" [value]="option.id">{{ option.code | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center pd-t-7 pd-b-7">
                            <div [style.width.%]="30">{{ 'Max' }}:</div> 
                            <input 
                                [style.margin-right.px]="0" 
                                [style.width.%]="70" id="fleet{{index}}" 
                                type="number" min="0" [(ngModel)]="editedShiftConfig.fleet[index].max" />
                        </div>

                        <button (click)="removeFleet(index)" class="btn btn-xs btn-info">{{'Remove' | translate}}</button>

                        <hr />
                        
                    </div>
                    <button (click)="addFleet()" class="btn btn-xs btn-primary">{{'Add' | translate}}</button>

                </div>
            </editor-window>
        </div>
    `
})
export class VansDetailsComponent implements OnInit, OnDestroy {
    
    @Input() 
    public shiftConfig: Fulfilment = null;

    @Input()
    public shiftStatusData: IShiftStatus = null;

    @Output() public shiftConfigChanged: EventEmitter<ShiftConfig> = new EventEmitter();


    public mode = 'view';
    public editedShiftConfig: Fulfilment = null;
    public Permissions = Permissions;
    private updateFulfilmentSub$: Subscription;
    public vehicleTypes$: Observable<VehicleTypes[]>;
    public shiftStatus = ShiftStatus;

    constructor(
        private readonly shiftSettingsService: ShiftSettingsService,
        private readonly vehicleTypesService: VehicleTypesService
    ) {}

    public ngOnInit() {
        this.vehicleTypesService.fetchAll();
        this.vehicleTypes$ = this.vehicleTypesService.list$.pipe(
            map((pageable: PageableResponse<VehicleTypes>) => pageable.content)
        );
    }

    public onModify() {
        this.editedShiftConfig = _.cloneDeep(this.shiftConfig);
        this.mode = 'edit';
    }

    public onCancel() {
        this.editedShiftConfig = null;
        this.mode = 'view';
    }

    public onSave() {
        this.mode = 'saving';

        this.editedShiftConfig.fleet = this.editedShiftConfig.fleet.filter((s: ShiftFleet) => s.vehicleTypeId);
        this.editedShiftConfig.fleet.map((s: ShiftFleet) => {
            delete s.vehicleType;
            delete s.sortOrder;
        });

        this.updateFulfilmentSub$ = this.shiftSettingsService.updateFulfilment(this.editedShiftConfig)
            .subscribe((updatedShiftConfig: ShiftConfig) => {
                this.editedShiftConfig = null;
                this.mode = 'view';
                this.shiftConfigChanged.next(updatedShiftConfig);
            },
            (error: HttpErrorResponse) => {
                this.mode = 'view';
            });
    }

    public addFleet() {
        this.editedShiftConfig.fleet.push({vehicleTypeId: null, max: 0, sortOrder: 0});
        this.sortFleet();
    }

    public sortFleet() {
        _.sortBy(this.editedShiftConfig.fleet, (s: ShiftFleet) => s.sortOrder );
    }

    public removeFleet(index: number): void {
        this.editedShiftConfig.fleet.splice(index, 1);
        console.log(this.editedShiftConfig.fleet);
    }

    public ngOnDestroy() {
        if (this.updateFulfilmentSub$) {
            this.updateFulfilmentSub$.unsubscribe()
        }
    }
}
