import * as _ from 'lodash';

import { Entity } from './Entity';
import { Serializable } from './Serializable';

/**
 * Additional deliver data (non Street Address data)
 */
export class Additional extends Entity implements Serializable<Additional> {
    public companyName: string = '';
    public flatNO: string = '';
    public lift: boolean = false;
    public staircaseNO: string = '';
    public floor: number = 0;
    public contactName: string = '';
    public contactPhoneNO: string = '';
    public domofonCode: string = '';
    public instructions: string = '';
    public contactEmail: string = '';
    public accessKey?: string = '';

    /**
     * Conversion from simple object
     * @param data
     */
    public deserialize(data: any) {
        this.companyName = _.get(data, 'companyName');
        this.flatNO = _.get(data, 'flatNO');
        this.lift = _.get(data, 'lift');
        this.staircaseNO = _.get(data, 'staircaseNO');
        this.floor = _.get(data, 'floor');
        this.contactName = _.get(data, 'contactName');
        this.contactPhoneNO = _.get(data, 'contactPhoneNO');
        this.domofonCode = _.get(data, 'domofonCode');
        this.instructions = _.get(data, 'instructions');
        this.accessKey = _.get(data, 'accessKey');

        return this;
    }

    /**
     * Conversion to simple object
     */
    public serialize() {
        return {
            companyName: this.companyName,
            flatNO: this.flatNO,
            lift: this.lift,
            staircaseNO: this.staircaseNO,
            floor: this.floor,
            contactName: this.contactName,
            contactPhoneNO: this.contactPhoneNO,
            domofonCode: this.domofonCode,
            accessKey: this.accessKey
        };
    }
}
