<layout-default>
    <div header>
        <div toolbar>
            <h4>
                <i-feather name="settings"></i-feather> {{'Planning sequence' | translate}}
            </h4>
        </div>
        <div toolbar>
            <button class="btn btn-sm btn-uppercase btn-white"
                [routerLink]="['/visualiser/planning-sequence/create']">
                <i-feather name="plus"></i-feather> {{ 'Add planning' | translate }}
            </button>
        </div>
        <hr>
    </div>

    <div content>
        <table *ngIf="planningSequence.length && !loader" class="table table-hover">
            <thead class="ta2head">
                <tr>
                    <th>{{ 'Id' | translate }}</th>
                    <th>{{ 'Planning name' | translate }}</th>
                    <th>{{ 'Steps' | translate }}</th>
                    <th>{{ 'Options' | translate }}</th>
                </tr>
            </thead>

            <tbody class="tab3dy">
                <tr 
                    *ngFor="let planning of planningSequence"
                    [class.row-modified]="modifiedPlanningId == planning.id">
                        <td>{{planning.id}}</td>
                        <td>{{planning.name}}</td>
                        <td>{{planning.steps.length}}</td>
                        <td>
                            <button class="btn btn-outline-light btn-xs btn-uppercase d-inline mg-r-10" (click)="goToEdit(planning.id)">{{ 'Edit' | translate }}</button>
                            <button class="btn btn-outline-light btn-xs btn-uppercase d-inline" (click)="removePlanning(planning.id)">{{ 'Remove' | translate }}</button>
                        </td>
                </tr>
            </tbody>
        </table>
   
        <div *ngIf="loader" class="load-spinner spinner">
            <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
            <p class="spinner__info">{{'Please, wait!' | translate}}</p>
        </div>

        <div *ngIf="!loader && !planningSequence.length && !error" class="empty-data load-spinner spinner">
            <i-feather name="alert-circle"></i-feather>
            <div class="mg-l-5">{{ 'No data to display' | translate }}</div>
        </div>
        
        <div *ngIf="error" class="load-spinner spinner error">
            <i class="no-animate fas fa-exclamation-triangle"></i>
            <p>{{ 'Error occurred, please try again!' | translate }}</p>
        </div>

    </div>
</layout-default>


<ng-template #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm remove the planning sequence' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, remove' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>