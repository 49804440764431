import { Type } from 'class-transformer';

import { serializeType } from '@shared/functions/serializeType.function';

import { SimulationShiftConfigDto } from './simulation-shift-config';

export class Simulation {

    public datasetId: number;
    public simulationId: string;

    @Type(serializeType(SimulationShiftConfigDto))
    public simulationShiftConfigDto: SimulationShiftConfigDto;
}

