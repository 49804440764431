import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ShiftPlanning } from '@calendar/interafces/shift-planning';
import { ShiftsService } from '@services/shifts.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { ShiftConfig } from '@calendar/entities/shiftConfig';

@Component({
  selector: 'app-shift-create',
  templateUrl: './shift-create.component.html',
  styleUrls: ['./shift-create.component.scss']
})
export class ShiftCreateComponent implements OnInit {

  @Input() date;
  @Output() public updatedData: EventEmitter<ShiftConfig> = new EventEmitter();
  
  public shiftPlanning$: Observable<ShiftPlanning[]>;
  public shiftPlanning: ShiftPlanning[];
  public form: FormGroup;
  public loader: boolean = true;
  public submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private readonly shiftService: ShiftsService
  ) { }

  public ngOnInit() {
    this.shiftPlanning$ = this.shiftService.getShiftsPlanning().pipe(
      map((planning: ShiftPlanning[]) => planning.filter(p => p.warehouse.toString() === localStorage.getItem('depot'))),
      tap(planning => this.shiftPlanning = planning)
    );
    this.initForm();
  }

  private initForm() {

    this.form = this.formBuilder.group({
      date: [{value: (this.date !== null) ? this.date.dateRaw : null, disabled: (this.date !== null)}, Validators.required],
      warehouse: [{value: localStorage.getItem('depot'), disabled: true}, Validators.required],
      shiftPlanning: []
    });

    this.form.get('shiftPlanning').valueChanges.subscribe(
      () => {
        const formData = Object.assign(this.shiftPlanning.find(s => s.id === this.form.get('shiftPlanning').value), this.form.getRawValue());
        console.log(formData);
        this.updatedData.emit(formData);
      }
    )

    this.loader = false;
  }
}
