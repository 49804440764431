import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';

@Injectable()
export class UtilsService {

    constructor() {
    }


    public httpHeaders(): Object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    public httpHeadersFileUpload(): Object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            })
        };
    }



    public httpHeadersWithBody(body): Object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            'body': body
        };
    }

    public httpHeadersPlainText(): Object {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'text'
        };
      };

    public interpolate(template: string, params: {}) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return new Function(...names, `return \`${template}\`;`)(...vals);
    }

    /*
    *       
    *   
    * 
    * */
    public findUpRouteParams(route: ActivatedRouteSnapshot | ActivatedRoute, property: string) {

        while (route) {
            const params = route.params;
            
            if (params[property]) {
                return params[property];
            }

            if (!route.parent) {
                return null;
            }

            route = route.parent
        }

    }

    public findDeepRouteParams(route: ActivatedRouteSnapshot | ActivatedRoute, property: string) {

        while (route.children.length) {
            const params = route.children[0];
            
            if (params[property]) {
                return params[property];
            }

            if (!route.parent) {
                return null;
            }

            route = route.children[0]
        }

    }
}
