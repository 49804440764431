<div class="d-flex mg-t-15 mg-b-15 no-print">
    <button (click)="print();" class="btn btn-brand-01 no-print text-center btn-print mg-r-15"><i
            class="fas fa-print"></i> {{'Print' | translate}}</button>
    <div class="mg-l-15 flex-grow-1">
        <date-range-picker [extendedOption]="false" (changeDow)="changedDow($event)"
            (changedDateRange)="changedDateRange($event)" (changedShiftType)="changedShiftType($event)"
            (mapType)="changedMapType($event)">
        </date-range-picker>
    </div>

    <div class="label">
        <label>{{ 'Driver' | translate }}:</label>
    </div>
    <div class="input-control" [style.min-width.%]="25">
        <app-search-customer [type]="'DRIVER'" (result)="selectedDriver($event)"></app-search-customer>
    </div>
</div>


<h2>{{'Schedule availability of drivers' | translate}}: {{startDate | amLocale: locale | amDateFormat:'D MMMM YYYY'}} -
    {{endDate | amLocale: locale | amDateFormat:'D MMMM YYYY'}}</h2>
<table *ngIf="!loading && !driver" class="table table-bordered">
    <thead>
        <tr>
            <th class="driver-name">{{ 'Lp' | translate }}</th>
            <th class="driver-name">{{ 'Driver' | translate }}</th>
            <th *ngFor="let day of driversAvailability.shiftSummaryList">
                <p class="day-name text-center">
                    {{day.date | amLocale: locale | amDateFormat:'ddd, D MMM' }}
                </p>
                <div class="shifts">
                    <div class="shift-type text-center" *ngFor="let type of day.shifts"
                        (click)="sortDrivers(day.date, type)">
                        {{type.slice(0, 1) | translate}}
                        <strong>({{driversNumberPerShift(day.date, type)}})</strong>
                    </div>
                </div>
            </th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let driver of drivers; let i = index">
            <td class="text-center">{{i+1}}.</td>
            <td class="driver-name">
                <div>{{driver.label}}<span *ngIf="driver.phoneNumber">, {{'phone-short' | translate}}:
                        {{driver.phoneNumber}}</span></div>
            </td>
            <td *ngFor="let day of driversAvailability.shiftSummaryList" [style.height.%]="100">
                <div class="shifts" [style.height.%]="100">
                    <div class="shift-type text-center" *ngFor="let type of day.shifts; let index = index">

                        <ng-container
                            *ngTemplateOutlet="templateRef; context: {$implicit:isAvailable(driver.id, day.date, type)}">
                        </ng-container>


                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>

<div *ngIf="!loading && driver" class="driver-printout-schedule">
    <h5 class="tx-uppercase pt-4 pb-1"><strong>{{driverName}}</strong></h5>
    <div class="d-flex flex-wrap">
        <div *ngFor="let day of driversAvailability.shiftSummaryList" class="day">
            <p class="day-name text-center">
                {{day.date | amLocale: locale | amDateFormat:'ddd, D MMM' }}
            </p>
            <div class="shifts">
                <div class="shift-type text-center" *ngFor="let type of day.shifts; let index = index"
                    (click)="sortDrivers(day.date, type)">
                    {{type.slice(0, 3) | translate}}
                </div>
            </div>
            <div class="shifts">
                <div class="shift-type text-center" *ngFor="let type of day.shifts; let index = index">
                    <ng-container
                        *ngTemplateOutlet="templateRef; context: {$implicit:isAvailable(driver, day.date, type)}">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="loading" class="load-spinner text-center">
    <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i> {{'Please, wait!' | translate}}
</div>

<ng-template #templateRef let-default>
    <div *ngIf="default && default.mode === 'SERVICE'" class="driver-availability active service">
        <i-feather [style.width.px]="18" [style.height.px]="18" name="settings"></i-feather>
    </div>
    <div *ngIf="default && (default.mode === 'REGULAR' || default.mode === null)"
        class="driver-availability active regular">
        <i-feather [style.width.px]="18" [style.height.px]="18" name="truck"></i-feather>
    </div>
    <div *ngIf="default && (default.mode === 'VACATION' || default.mode === null)" class="driver-availability vacation">
        <i-feather [style.width.px]="18" [style.height.px]="18" name="sun"></i-feather>
    </div>
    <div *ngIf="default && (default.mode === 'SICKLEAVE' || default.mode === null)"
        class="driver-availability sickleave">
        <i-feather [style.width.px]="18" [style.height.px]="18" name="thermometer"></i-feather>
    </div>
    <div *ngIf="default && (default.mode === 'SUPPLY' || default.mode === null)" class="driver-availability supply">
        <i-feather [style.width.px]="18" [style.height.px]="18" name="clipboard"></i-feather>
    </div>
    <div *ngIf="!default && driver" class="driver-availability">
        <i-feather [style.width.px]="18" [style.height.px]="18" name="x"></i-feather>
    </div>
</ng-template>