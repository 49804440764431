<ng-container *ngIf="cutoffIsSet else selectCutoffTmpl">
    <foldable-container *ngIf="shift" [class]="'card-body'" [toggleIcon]="true" [showFoldedTitle]="true"
        [isFolded]="true">
        <div class="title">
            <div *ngIf="!autoPromoteSolution && !autoFinalizeSolution">
                <h3 class="manual tx-20 tx-bold tx-uppercase tx-sans mg-t-10 mg-b-8">{{'Manual cutoff' | translate}}
                </h3>
                <p class="tx-13 tx-color-03 tx-sans">{{'manual_cutoff_description' | translate}}</p>
            </div>

            <div
                *ngIf="(!autoPromoteSolution && autoFinalizeSolution)|| (autoPromoteSolution && !autoFinalizeSolution)">
                <h3 class="semi-automatic tx-20 tx-bold tx-uppercase tx-sans mg-t-10 mg-b-8">{{'Semi-automatic cutoff' |
                    translate}}</h3>
                <p class="tx-13 tx-color-03 tx-sans">{{'semi-automatic_cutoff_description' | translate}}</p>
            </div>

            <div *ngIf="autoPromoteSolution && autoFinalizeSolution">
                <h3 class="automatic tx-20 tx-bold tx-uppercase tx-sans mg-t-10 mg-b-8">{{'Automatic cutoff' |
                    translate}}</h3>
                <p class="tx-13 tx-color-03 tx-sans">{{'automatic_cutoff_description' | translate}}</p>
            </div>
        </div>

        <div class="content">
            <div class="d-flex justify-content-between">
                <div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [checked]="autoPromoteSolution"
                            id="autoPromoteSolutionSwitch" [disabled]="autoFinalizeSolution"
                            (click)="autoPromoteSolutionChanged($event)">
                        <label class="tx-14 tx-medium tx-color-01 tx-sans mg-b-0 custom-control-label"
                            for="autoPromoteSolutionSwitch">
                            <span>{{'shiftProperty.autoPromoteSolution' | translate}}:&nbsp;</span>
                            <ng-container *ngIf="'autoPromoteSolution' | tooltipLabelPipe:'shift' as tooltip">
                                <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                    name="info"></i-feather>
                            </ng-container>&nbsp;
                            <strong *ngIf="autoPromoteSolution" class="enabled">{{'Enabled' | translate}}</strong>
                            <strong *ngIf="!autoPromoteSolution" class="disabled">{{'Disabled' | translate}}</strong>
                        </label>
                    </div>
                    <div *ngIf="autoPromoteSolution && autoFinalizeSolution" class="mg-t-20">
                        {{'Automatic cutoff finalization will take place at' | translate}}
                        <strong>{{cutoffFinalization()}}</strong>
                    </div>
                </div>
                <div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [checked]="autoFinalizeSolution"
                            id="autoFinalizeSolutionSwitch" [disabled]="!autoPromoteSolution"
                            (click)="autoFinalizeSolutionChanged($event)">
                        <label class="tx-14 tx-medium tx-color-01 tx-sans mg-b-0 custom-control-label"
                            for="autoFinalizeSolutionSwitch">
                            <span>{{'shiftProperty.autoFinalizeSolution' | translate}}:&nbsp;</span>
                            <ng-container *ngIf="'autoFinalizeSolution' | tooltipLabelPipe:'shift' as tooltip">
                                <i-feather [containerClass]="'pretty-tooltip'" [tooltip]="tooltip" [style.width.px]="13"
                                    name="info"></i-feather>
                            </ng-container>&nbsp;
                            <strong *ngIf="autoFinalizeSolution" class="enabled">{{'Enabled' | translate}}</strong>
                            <strong *ngIf="!autoFinalizeSolution" class="disabled">{{'Disabled' | translate}}</strong>
                        </label>
                    </div>
                    <div *ngIf="autoPromoteSolution && autoFinalizeSolution" class="mg-t-20">
                        {{'Automatic finalization of cutoff after' | translate}}: <strong>{{(cutoff?.cutoffTimeout /
                            60).toFixed(0)}} min</strong> <button (click)="cutoffTimeoutChanged()"
                            class="btn btn-xs btn-white d-inline mg-l-15">{{'modify' | translate}}</button>
                    </div>
                </div>
                <div>
                    <div *ngIf="(shiftStatusChanged$ | async) as shiftStatusChanged">
                        {{'Cutoff initialization call' | translate}}:&nbsp;
                        <strong class="enabled"
                            *ngIf="(shiftStatusChanged | findFinderEventPipe:'PLANNING') as event; else notReceived">{{'Received
                            at' | translate}}: {{event.occurredAt | utcToLocalTimePipe | timeFormatterPipe:'D.MM.YYYY
                            HH:mm'}}</strong>
                        <ng-template #notReceived>
                            <strong *ngIf="cutoff.status === 'COMPLETED'">{{ 'Completed' | translate }}</strong>
                            <strong *ngIf="cutoff.status === 'OPEN'">{{'Not received' | translate}}</strong>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </foldable-container>


    <ng-container *ngIf="(solutions$ | async) as solutions">
        <foldable-container [class]="'card-body'" [toggleIcon]="true" [showFoldedTitle]="true" [isFolded]="false">
            <div class="title">
                <div class="">
                    <h3 class="tx-15 tx-bold tx-uppercase tx-sans mg-t-10 mg-b-8">{{'Calculated solutions' | translate}}
                    </h3>

                    <div class="d-flex justify-content-start align-items-baseline mg-t-20 mg-b-20">
                        <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]"
                            class="menu__button btn btn-sm btn-uppercase btn-white mg-r-10 flex-grogw-1"
                            (click)="loadSolutions()">
                            <span *ngIf="!reloadSolutionsLoader">{{ "Refresh" | translate }}</span>
                            <ng-container *ngIf="reloadSolutionsLoader">
                                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse"
                                        aria-hidden="true"></i>
                                </div>
                            </ng-container>
                        </button>
                        <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]"
                            (click)="recalculateLoader = true; recalculate(false)"
                            [disabled]="recalculateLoader || cutoff.status === 'COMPLETED'"
                            class="menu__button btn btn-sm btn-uppercase btn-success  mg-r-10 flegx-grow-1">
                            <span *ngIf="!recalculateLoader">{{ "Optimize now" | translate }}</span>
                            <ng-container *ngIf="recalculateLoader">
                                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse"
                                        aria-hidden="true"></i>
                                </div>
                            </ng-container>
                        </button>
                        <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]"
                            (click)="manualKeepSolution()"
                            [disabled]="manualKeepSolutionLoader || cutoff.status === 'COMPLETED' || !cutoff.autoFinalizeSolution"
                            class="menu__button btn btn-sm btn-uppercase btn-warning mg-r-10 flex-ggrow-1">
                            <span *ngIf="!forceitLoader">{{ "Manual & keep solutions" | translate }}</span>
                            <ng-container *ngIf="manualKeepSolutionLoader">
                                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse"
                                        aria-hidden="true"></i>
                                </div>
                            </ng-container>
                        </button>
                        <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]"
                            (click)="recalculate(true)" [disabled]="forceitLoader || cutoff.status === 'COMPLETED'"
                            class="menu__button btn btn-sm btn-uppercase btn-primary mg-r-10 flex-ggrow-1">
                            <span *ngIf="!forceitLoader">{{ "Generate solutions" | translate }}</span>
                            <ng-container *ngIf="forceitLoader">
                                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse"
                                        aria-hidden="true"></i>
                                </div>
                            </ng-container>
                        </button>

                        <button appCheckFeaturesConfig [featureName]="[Permissions.Shift_cutoff_force]"
                            (click)="closeShiftLoader()"
                            class="menu__button btn btn-sm btn-uppercase btn-primary mg-r-10 flegx-grow-1"
                            appCheckClient [client]="inpostClient"
                            [disabled]="![ShiftStatusType.BOOKING, ShiftStatusType.CONFIGURING].includes(shift.status) || !cutoff.automaticCutoff">
                            <span *ngIf="!forceitLoader">{{ "Close shift" | translate }}</span>
                            <ng-container *ngIf="forceitLoader">
                                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse"
                                        aria-hidden="true"></i>
                                </div>
                            </ng-container>
                        </button>


                        <button appCheckFeaturesConfig [featureName]="[Permissions.Visualiser_cutoff]"
                            class="menu__button btn btn-sm btn-uppercase btn-danger mg-r-10 flegx-grow-1"
                            (click)="finalize(solutions)" [disabled]="cutoff.status === 'COMPLETED'">
                            <span>{{'Finalize' | translate}}</span>
                            <pre>{{aaa | json}}</pre>
                        </button>

                    </div>
                </div>
            </div>

            <div class="content">
                <ngx-datatable #table id="solutions" class="bootstrap" [rows]="solutions" [columns]="columns"
                    [groupRowsBy]="'deliveryTime'" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50"
                    [messages]="tableMessages" [rowHeight]="'auto'" [externalPaging]="false" [limit]="queryParams.size"
                    [scrollbarH]="scrollBarHorizontal" [sortType]="SortType.multi" (page)="setPage($event)">

                    <!-- Group Header Template -->
                    <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader (toggle)="onDetailToggle($event)">
                        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                            <div class="d-flex group-header text-center">
                                <ng-container *ngIf="group.value | findBestSolutionPipe as row">

                                    <ng-container *ngFor="let col of columns">
                                        <div [style.width.%]="4.16 * col.flexGrow" class="text-center"
                                            [ngSwitch]="col.prop">
                                            <div *ngSwitchCase="'options'">

                                                <a class="btn btn-success btn-sm btn-uppercase mg-b-10"
                                                    (click)="promoteSolution(row)">
                                                    {{'Promote' | translate }}
                                                </a>

                                                <!-- <a 
                                    class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-edit" 
                                    (click)="promoteAndFinalizeSolution(row)">
                                        {{'Promote & finalize' | translate }}
                                </a> -->

                                                <a class="btn btn-primary btn-sm btn-uppercase"
                                                    [routerLink]="['../', row.id]">
                                                    {{'See routes' | translate }}
                                                </a>

                                            </div>

                                            <div *ngSwitchCase="'distance'">
                                                <strong>{{((row.distance / row.deliveryCount) / 1000).toFixed(2)}}
                                                    km</strong>
                                            </div>

                                            <div *ngSwitchCase="'completion'">
                                                {{row.completion | timeFormatterPipe: 'D.MM.YYYY, HH:mm'}}
                                            </div>

                                            <div *ngSwitchCase="'routeCount'">
                                                {{row.routeCount}} <br /> {{row.deliveryCount}}
                                            </div>

                                            <div *ngSwitchCase="'deliveryEarly'">
                                                {{row.deliveryEarly}} / {{row.deliveryLate}}
                                            </div>

                                            <div *ngSwitchCase="'simulationKey'">
                                                <a [class.datatable-icon-right]="!expanded"
                                                    [class.datatable-icon-down]="expanded" title="Expand/Collapse Group"
                                                    (click)="toggleExpandGroup(group)">
                                                    <strong>{{'Delivery time' |
                                                        translate}}:<br />{{group.value[0].deliveryTime}} min</strong>
                                                </a>
                                            </div>

                                            <div *ngSwitchCase="'errors'">
                                                <strong class="no-errors"
                                                    [ngClass]="{'red': value}">{{row.errors}}</strong>
                                            </div>

                                            <div *ngSwitchCase="'deliveryTime'">
                                                {{row.deliveryTime}} min
                                            </div>

                                            <div *ngSwitchCase="'shiftStart'">
                                                {{'Start' | translate}}: {{row.shiftStart}} min <br />
                                                {{'End' | translate}}: {{row.shiftEnd}} min
                                            </div>

                                            <div *ngSwitchCase="'slotStart'">
                                                {{'Start' | translate}}: {{row.slotStart}} min <br />
                                                {{'End' | translate}}: {{row.slotEnd}} min
                                            </div>

                                            <div *ngSwitchCase="'description'">
                                                <ul class="text-left">
                                                    <li *ngFor="let item of row.description">{{item}}</li>
                                                </ul>
                                            </div>

                                            <div *ngSwitchCase="'status'">
                                                <ng-container
                                                    *ngIf="(solutions | countBySolutionStatusPipe:row.simulationKey) as result">
                                                    <span [style.min-width.px]="100" *ngIf="result['COMPLETE']"
                                                        class="badge badge-success justify-content-center mg-b-6">{{
                                                        'SolutionStatus.COMPLETE' | translate }} -
                                                        {{result['COMPLETE']}}</span>
                                                    <span [style.min-width.px]="100" *ngIf="result['INITIALIZING']"
                                                        class="badge badge-info justify-content-center mg-b-6">{{
                                                        'SolutionStatus.INITIALIZING' | translate }} -
                                                        {{result['INITIALIZING']}}</span>
                                                    <span [style.min-width.px]="100" *ngIf="result['FAILED']"
                                                        class="badge badge-danger justify-content-center">{{
                                                        'SolutionStatus.FAILED' | translate }} -
                                                        {{result['FAILED']}}</span>
                                                </ng-container>
                                                <span class="tx-11 pd-t-10">{{row.completion | timeFormatterPipe:
                                                    'D.MM.YYYY, HH:mm'}}</span>
                                            </div>

                                            <div *ngSwitchCase="'address'">{{row.address | addressLabelPipe}}</div>
                                            <div *ngSwitchDefault>brak</div>
                                        </div>



                                    </ng-container>

                                </ng-container>
                            </div>
                        </ng-template>
                    </ngx-datatable-group-header>

                    <ng-container *ngFor="let col of columns">
                        <ngx-datatable-column [flexGrow]="col.flexGrow" [prop]="col.prop" [headerClass]="'text-center'">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <b (click)="sort()">{{ col.name | translate }}</b>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                                <div class="text-center" [ngSwitch]="col.prop">
                                    <div *ngSwitchCase="'options'">
                                        <div class="dropdown" dropdown>
                                            <button class="btn btn-outline-secondary btn-xs dropdown-toggle"
                                                type="button" id="dropdownMenuButton">
                                                {{ 'Select' | translate }}
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-right">

                                                <a class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-edit"
                                                    (click)="promoteSolution(row)">
                                                    {{'Promote' | translate }}
                                                </a>

                                                <!-- <a 
                                            class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-edit" 
                                            (click)="promoteAndFinalizeSolution(row)">
                                                {{'Promote & finalize' | translate }}
                                        </a> -->

                                                <a class="btn btn-outline-secondary btn-sm btn-uppercase dropdown-item btn-region-remove"
                                                    [routerLink]="['../', row.id]">
                                                    {{'See routes' | translate }}
                                                </a>

                                            </div>
                                        </div>
                                    </div>

                                    <div *ngSwitchCase="'distance'">
                                        <strong>{{((value / row.deliveryCount) / 1000).toFixed(2)}} km</strong>
                                    </div>

                                    <div *ngSwitchCase="'completion'">
                                        {{value | timeFormatterPipe: 'D.MM.YYYY, HH:mm'}}
                                    </div>

                                    <div *ngSwitchCase="'routeCount'">
                                        {{value}} <br /> {{row.deliveryCount}}
                                    </div>

                                    <div *ngSwitchCase="'deliveryEarly'">
                                        {{row.deliveryEarly}} / {{row.deliveryLate}}
                                    </div>

                                    <div *ngSwitchCase="'simulationKey'">
                                        <strong>{{value}}</strong>
                                    </div>

                                    <div *ngSwitchCase="'errors'">
                                        <strong class="no-errors" [ngClass]="{'red': value}">{{value}}</strong>
                                    </div>

                                    <div *ngSwitchCase="'deliveryTime'">
                                        {{value}} min
                                    </div>

                                    <div *ngSwitchCase="'shiftStart'">
                                        {{'Start' | translate}}: {{row.shiftStart}} min <br />
                                        {{'End' | translate}}: {{row.shiftEnd}} min
                                    </div>

                                    <div *ngSwitchCase="'slotStart'">
                                        {{'Start' | translate}}: {{row.slotStart}} min <br />
                                        {{'End' | translate}}: {{row.slotEnd}} min
                                    </div>

                                    <div *ngSwitchCase="'description'">
                                        <ul class="text-left">
                                            <li *ngFor="let item of value">{{item}}</li>
                                        </ul>
                                    </div>

                                    <div *ngSwitchCase="'status'">
                                        <ng-container [ngSwitch]="value">
                                            <span *ngSwitchCase="'COMPLETE'" class="badge badge-success">{{
                                                'SolutionStatus.'+value | translate }}</span>
                                            <span *ngSwitchCase="'INITIALIZING'" class="badge badge-info">{{
                                                'SolutionStatus.'+value | translate }}</span>
                                            <span *ngSwitchCase="'FAILED'" class="badge badge-danger">{{
                                                'SolutionStatus.'+value | translate }}</span>
                                            <span *ngSwitchDefault class="badge badge-info">{{ 'SolutionStatus.'+value |
                                                translate }}</span>
                                        </ng-container> <br />
                                        <span class="tx-11 pd-t-10">{{row.completion | timeFormatterPipe: 'D.MM.YYYY,
                                            HH:mm'}}</span>
                                    </div>

                                    <div *ngSwitchCase="'address'">{{value | addressLabelPipe}}</div>
                                    <div *ngSwitchDefault>{{value}}</div>
                                </div>


                            </ng-template>
                        </ngx-datatable-column>
                    </ng-container>
                </ngx-datatable>
            </div>
        </foldable-container>
    </ng-container>


    <foldable-container [class]="'card-body'" [toggleIcon]="true" [showFoldedTitle]="true" [isFolded]="false">
        <div class="title">
            <div class="">
                <h3 class="tx-15 tx-bold tx-uppercase tx-sans mg-t-10 mg-b-8">{{'Active solution' | translate}}</h3>
                <p class="tx-13 tx-color-01 tx-sans"></p>
            </div>
        </div>

        <div class="content">
            <ng-container *ngIf="!loader">
                <app-visualiser-routes-planning></app-visualiser-routes-planning>
            </ng-container>
            <div *ngIf="!solutions$ && !noRoutes" class="load-spinner spinner">
                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                <p class="spinner__info">{{ 'Loading, solutions' | translate }}:
                    <strong>{{params?.y}}-{{params?.m}}-{{params?.d}} {{params?.shiftId | translate}}</strong>
                </p>
            </div>
        </div>
    </foldable-container>
</ng-container>

<ng-template #selectCutoffTmpl>
    <div class="load-spinner spinner">
        <p class="spinner__info">
            <strong>{{'Please select the cutoff!' | translate}}</strong>
        </p>
    </div>
</ng-template>


<ng-template #cutoffTimeoutConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Change shift settings' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'Do you confirm change of cutoff timeout settings in shift' | translate }}
            {{buildShiftId()}}?</p>

        <div class="d-flex align-items-baseline form-group">
            <label class="mg-r-20" for="code">
                <span>{{ 'shiftProperty.cutoffTimeout' | translate }}:</span>
                <sup *ngIf="'cutoffTimeout' | tooltipLabelPipe:'shift' as tooltip" [containerClass]="'pretty-tooltip'"
                    [tooltip]="tooltipLabel('cutoffTimeout')">
                    &nbsp;<i-feather [style.width.px]="10" name="info">{{ 'shiftProperty.cutoffTimeout' | translate
                        }}:</i-feather>
                </sup>
            </label>
            <div class="flex-grow-1">
                <input type="number" [(ngModel)]="cutoffTimeout" class="form-control w-100" />
            </div>
        </div>

        <button type="button" class="btn btn-brand-01" (click)="confirmCutoffTimeout()">
            {{'Yes, change' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #autoPromoteSolutionConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Change shift settings' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'Do you confirm change of auto promote the best solution settings in shift' |
            translate }} {{buildShiftId()}}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirmChangedAutoPromoteSolutionSetting()">
            {{'Yes, change' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #autoFinalizeSolutionConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Change shift settings' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'Do you confirm change of automatic cutoff settings in shift' | translate }}
            {{buildShiftId()}}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirmAutoFinalizeSolutionSetting()">
            {{'Yes, change' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #promoteSolutionConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Promote solution' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'Do you confirm promote of selected solution' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="confirmPromote()">
            {{'Yes, promote solution' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #finalizeSolutionConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Mark solution as final' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()"><span
                aria-hidden="true">&times;</span></button>
    </div>

    <div class="modal-body text-center">
        <p class="tx-12 tx-bold">{{'This action cannot be undone, is final_Are you sure you want to finalize the
            solution' | translate }}?</p>
        <ul class="text-left solution-details-list">
            <li><strong>{{'Shift' | translate}}</strong>: {{selectedSolution.shiftId}}</li>
            <li><strong>{{'Routes' | translate}}</strong>: {{selectedSolution.routeCount}}</li>
            <li><strong>{{'Distance' | translate}}</strong>: {{(selectedSolution.distance / 1000).toFixed(2)}} km.</li>
            <li><strong>{{'Deliveries' | translate}}</strong>: {{selectedSolution.deliveryCount}}</li>
            <li><strong>{{'Early' | translate}}</strong>: {{selectedSolution.deliveryEarly}}</li>
            <li><strong>{{'Late' | translate}}</strong>: {{selectedSolution.deliveryLate}}</li>
        </ul>
        <button type="button" class="btn btn-brand-01" (click)="confirm()">
            {{'Yes, mark as final' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #forcefitConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
    </div>

    <div class="modal-body text-center">
        <p><strong>{{'Start generating solutions_It does not send them for fulfillment_Do you confirm the generation of
                new solutions for shift' | translate}}: {{buildShiftId()}}?</strong></p>
        <button type="button" class="btn btn-brand-01" (click)="forceitLoader = !forceitLoader; recalculateExecute()">
            <span *ngIf="!forceitLoader">{{'Confirm' | translate}}</span>
            <ng-container *ngIf="forceitLoader">
                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i></div>
            </ng-container>
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #finalizeShiftConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
    </div>

    <div class="modal-body text-center">
        <p><strong>{{'This action cannot be undone, is final_Are you sure you want to finalize the solution' |
                translate}}: {{buildShiftId()}}?</strong></p>

        <button type="button" class="btn btn-brand-01" (click)="finalizeLoader = !finalizeLoader; confirm()">
            <span *ngIf="!finalizeLoader">{{'Confirm' | translate}}</span>
            <ng-container *ngIf="finalizeLoader">
                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i></div>
            </ng-container>
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #manualKeepSolutionConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
    </div>

    <div class="modal-body text-center">
        <p><strong>{{'When used, the shift shall switch to manual cutoff and any solutions that exist will be visible' |
                translate}}. {{'Do you confirm that' | translate }}?</strong></p>
        <button type="button" class="btn btn-brand-01"
            (click)="manualKeepSolutionLoader = !manualKeepSolutionLoader; manualKeepSolutionExecute()">
            <span *ngIf="!manualKeepSolutionLoader">{{'Confirm' | translate}}</span>
            <ng-container *ngIf="manualKeepSolutionLoader">
                <div class="load-spinner text-center"><i class="fa fa-spinner fast-pulse" aria-hidden="true"></i></div>
            </ng-container>
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>

<ng-template #forceCloseShiftConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Confirm action' | translate }}</h4>
    </div>

    <div class="modal-body text-center">
        <p>{{'Do you confirm that' | translate }}?</p>
        <button type="button" class="btn btn-brand-01" (click)="closeShift()">
            <span>{{'Confirm' | translate}}</span>
        </button>
        <button type="button" class="btn btn-default" (click)="decline()">{{'Cancel' | translate }}</button>
    </div>
</ng-template>