import { Component, OnInit } from '@angular/core';
import { CutoffService } from '../../services/cutoff.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { RoutesPlanningParams } from '../../interfaces/routes-planning-params.interface';
import { RouteId } from '@entities/routeId';
import { switchMap, tap, map, subscribeOn } from 'rxjs/operators';
import { ShiftSettings } from '@entities/shift-settings';
import { VisualiserService } from '../../services/visualiser.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-visualiser-solution-details',
  templateUrl: './visualiser-solution-details.component.html',
  styleUrls: ['./visualiser-solution-details.component.scss']
})
export class VisualiserSolutionDetailsComponent implements OnInit {

  public solution$: Observable<any>;
  private params: RoutesPlanningParams;
  public shift: ShiftSettings;
  public distances$: Observable<any>;
  public loader = true;

  constructor(
    private readonly cutOffService: CutoffService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly modalService: BsModalService,
    private readonly notifierService: NotifierService,
    private readonly visualiserService: VisualiserService,
    private readonly utilsService: UtilsService

  ) { }

  public ngOnInit() {
    this.params = { 
      y: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'y'),
      m: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'm'),
      d: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'd'),
      shiftId: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'shiftId'),
      depot: this.utilsService.findUpRouteParams(this.activatedRoute.snapshot, 'depot'),
    } as RoutesPlanningParams;

    const solutionId = this.activatedRoute.snapshot.params.solutionId;
    const shiftId = RouteId.getShiftId3(this.params.y, this.params.m, this.params.d, this.params.shiftId);

    this.distances$ = this.visualiserService.distances.pipe(
      map((distances) => distances ? distances : undefined),
      tap(() => this.loader = false)
    );
 
    this.solution$ = this.cutOffService.getShift(shiftId).pipe(
      switchMap((shift: ShiftSettings) => {
        this.shift = shift;
        return this.cutOffService.getSolutionDetails(shift, shiftId, shift.version, solutionId);
      }),
      tap(result => this.visualiserService.calculateDistances(result)),
    )
  }

  public back() {
    window.history.back();
  }

}
