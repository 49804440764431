import * as _ from 'lodash';

import { Driver } from '../../drivers/entities/driver';
import { Entity } from '../../shared/entities/Entity';
import { Serializable } from '../../shared/entities/Serializable';
import { ChartModel } from '../interfaces/chart.interface';
import { Stats } from './stats';

export class DriverReport extends Entity implements Serializable<DriverReport> {
    public driver: Driver;
    public stats: Stats[] = [];

    public static generateDataToExport(data: ChartModel[]) {
        const dataToExports = [];

        _.forEach(data, (item: ChartModel) => {
            dataToExports.push({
                driverName: item.name,
                nationality: item.nationality,
                deliveredTotal: item.punctuality.total,
                deliveredOk: `${item.punctuality.completeOnSchedule}`,
                deliveredOk_percent: `${(((100 * item.punctuality.completeOnSchedule) / item.punctuality.total) || 0).toFixed(2)}`,
                deliveredEarly: `${item.punctuality.completeBeforeSchedule}`,
                deliveredEarly_percent: `${(((100 * item.punctuality.completeBeforeSchedule) / item.punctuality.total) || 0).toFixed(2)}`,
                deliveredLate: `${item.punctuality.completeAfterSchedule}`,
                deliveredLate_percent: `${(((100 * item.punctuality.completeAfterSchedule) / item.punctuality.total) || 0).toFixed(2)}`,
                statusFailure: item.punctuality.incompleteBeforeSchedule + item.punctuality.incompleteAfterSchedule
            });
        });

        return dataToExports;
    }

    public deserialize(data: any) {
        this.driver = new Driver().deserialize(_.get(data, 'driver'));

        const stats = _.get(data, 'stats');
        stats.forEach(stat => this.stats.push(new Stats().deserialize(stat)));

        return this;
    }

    public serialize() {
        const serialized = {
            stats: this.stats,
            driver: this.driver
        };

        return serialized;
    }

    public generateChartData(punctualityData: DriverReport): ChartModel {
        let exportChartData: ChartModel;

        punctualityData.stats.map((item: Stats) => {
            const row: ChartModel = { name: '', nationality: '', stats: null, series: [] };
            row.name = punctualityData.driver.driverName;
            row.stats = new Stats().deserialize(item);
            row.nationality = punctualityData.driver.nationality;
            row.series = item.generateChartDataForDrivers(item);
            exportChartData = row;
        });

        return exportChartData;
    }
}
