import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tooltipLabel } from '@shared/functions/tooltip-labels.function';

@Pipe({
    name: 'tooltipLabelPipe',
    pure: true
})
export class TooltipLabelPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}
    
    public transform(property: string, parent: string): string {
        return tooltipLabel(this.translateService, `tooltips.${parent}.labels.${property}`);
    }
}
