import { Component } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { AppLang } from '@enums/enum';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-root',
    template: `
    <!--<ng-http-loader 
        [backdrop]="false"
        [backgroundColor]="'#ffcd00'"
        [debounceDelay]="2000"
        [extraDuration]="300"
        [minDuration]="300"
        [opacity]=".6"
        [spinner]="spinkit.skChasingDots">
    </ng-http-loader>-->
    <router-outlet></router-outlet>`
})
export class AppComponent {
    constructor(private translateService: TranslateService) {
        const lang = localStorage.getItem('lang') || AppLang.PL;
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);
    }

    public spinkit = Spinkit;
}
