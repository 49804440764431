import { Component } from '@angular/core';

import * as moment from 'moment';

import { DateRange, mapOptions, mapTypes } from '@enums/enum';

import { VisualiserService } from '../services/visualiser.service';

@Component({
    selector: 'orders-history-map',
    templateUrl: 'orders-history-map.component.html',
    styles: [
        `
        [toolbar] {
            @include toolbar();
            margin-bottom: 10px;
        }
        `
    ]
})
export class OrdersHistoryMapComponent {
    private startDate;
    private endDate;
    private dateRange: DateRange;
    private mapProperties = {
        zoom: 11,
        center: {
            lat: 52.229676,
            lng: 21.012229
        },
        mapTypeId: mapTypes.ROADMAP
    };
    private mapOption = mapOptions.HEATMAP;
    private dow: string = 'MONDAY,TUESDAY,WEDNESDAY,THURSDAY,FRIDAY,SATURDAY,SUNDAY';
    private shifts: string = 'MORNING,AFTERNOON';

    public coordinates: Array<Array<number>> = [];
    public loader = true;

    constructor(private visualiserService: VisualiserService) {}

    private getCoordinatesForPastDeliveries(): void {
        this.visualiserService.getCoordinatesForPastDeliveries(this.startDate, this.endDate, this.shifts, this.dow).subscribe(
            (coordinates: Array<Array<number>>) => {
                this.coordinates = coordinates;
                this.loader = false;
            },
            () => {
                this.loader = false;
            }
        );
    }

    public changedMapType(event): void {
        this.coordinates = [];
        this.loader = true;
        this.mapOption = event;
        this.getCoordinatesForPastDeliveries();
    }

    public changedShiftType(event): void {
        this.coordinates = [];
        this.loader = true;
        this.shifts = event;
        this.getCoordinatesForPastDeliveries();
    }

    public changedDow(event): void {
        this.coordinates = [];
        this.loader = true;
        this.dow = event.map(d => `${d}`);
        this.getCoordinatesForPastDeliveries();
    }

    public changedDateRange(event): void {
        this.coordinates = [];
        this.loader = true;
        this.startDate = moment(event.dateStart).format('YYYY-MM-DD');
        this.endDate = moment(event.dateEnd).format('YYYY-MM-DD');
        this.dateRange = event.dateRange;
        this.getCoordinatesForPastDeliveries();
    }
}
