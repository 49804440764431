export enum UsersRoles {
    ROLE_DISPATCHER = 'ROLE_DISPATCHER',
    ROLE_SYSADMIN = 'ROLE_SYSADMIN',
    ROLE_CUSTOMER_CARE = 'ROLE_CUSTOMER_CARE'
}

export const UsersRolesOptions = [
    UsersRoles.ROLE_DISPATCHER,
    UsersRoles.ROLE_SYSADMIN,
    UsersRoles.ROLE_CUSTOMER_CARE
];

export enum PartnerUserRoles {
    ROLE_WEBPANEL = 'ROLE_WEBPANEL', 
    ROLE_IMPORTJOB = 'ROLE_IMPORTJOB'
}