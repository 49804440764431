import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { plainToClass } from 'class-transformer';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HardwareStatusOptions, HardwareTypeOptions, Client, HardwareType } from '@enums/enum';
import { NavService } from '@services/nav.sevice';

import { Hardware } from '../entities/hardware';
import { HardwareService } from '../services/hardware.service';
import { Depot } from '@interfaces/depot.interface';
import { AppService } from '@services/app.service';
import { environment } from '@environment';

@Component({
    selector: 'edit-hardware',
    template: `
        <layout-default>
            <div header>
                <div toolbar>
                    <h4 *ngIf="mode == 'edit'">
                        <i-feather name="edit-3"></i-feather> {{ 'Edit hardware' | translate }}
                    </h4>
                    <h4 *ngIf="mode == 'create'">
                        <i-feather name="plus-square"></i-feather> {{ 'Add hardware' | translate }}
                    </h4>
                </div>
                <div toolbar>
                    <button *ngIf="mode == 'edit'" class="btn btn-sm btn-uppercase btn-white" [routerLink]="['/fleet']">{{ 'Cancel' | translate }}</button>
                </div>
                
                <hr>
            </div>

            <div content class="content">
                <div class="col-8 offset-2">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div id="hardware-edit-form-brand" class="form-group row">
                                <label class="col-2" for="brand">{{ 'Brand' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="brand" class="form-control" />
                                    <div *ngIf="form.get('brand').hasError('required') && form.get('brand').touched" class="error-color">{{ 'Brand is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-model" class="form-group row">
                                <label class="col-2" for="model">{{ 'Model' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="model" class="form-control" />
                                    <div *ngIf="form.get('model').hasError('required') && form.get('model').touched" class="error-color">{{ 'Model is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-deviceNo" class="form-group row">
                                <label class="col-2" for="lastname">{{ 'Device no' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="deviceNo" class="form-control" />
                                    <div *ngIf="form.get('deviceNo').hasError('required') && form.get('deviceNo').touched" class="error-color">{{ 'Device number is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-hardwareType" class="form-group row">
                                <label class="col-2" for="hardwareType">{{ 'Hardware type' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="hardwareType">
                                        <ng-container *ngFor="let hardwareType of hardwareTypeOptions">
                                            <option [value]="hardwareType">{{ 'hardwareType.' + hardwareType + '.' + client | translate }}</option>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="form.get('hardwareType').hasError('required') && form.get('hardwareType').touched" class="error-color">
                                        {{ 'Hardware Type field is required' | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-inventoryNo" class="form-group row">
                                <label class="col-2" for="inventoryNo">{{ 'Inventory No' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="inventoryNo" class="form-control" />
                                    <div *ngIf="form.get('inventoryNo').hasError('required') && form.get('inventoryNo').touched" class="error-color">
                                        {{ 'Inventory number is required' | translate }}!
                                    </div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-phoneNumber" class="form-group row">
                                <label class="col-2" for="phoneNumber">{{ 'Phone number' | translate }}:</label>
                                <div class="col-10">
                                    <input formControlName="phoneNumber" class="form-control" />
                                </div>
                            </div>

                            <ng-container formGroupName="properties">
                                <div id="hardware-edit-form-pin" class="form-group row">
                                    <label class="col-2" for="pin">{{ 'PIN number' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="pin" class="form-control" />
                                    </div>
                                </div>
                                <div id="hardware-edit-form-puk" class="form-group row">
                                    <label class="col-2" for="puk">{{ 'PUK number' | translate }}:</label>
                                    <div class="col-10">
                                        <input formControlName="puk" class="form-control" />
                                    </div>
                                </div>
                            </ng-container>


                            <div id="hardware-edit-form-owner" class="form-group row">
                                <label class="col-2" for="owner">{{ 'Owner' | translate }}:</label>
                                <div class="col-10">
                                    <select *ngIf="(hardwareOwner$ | async) as hardwareOwner" class="custom-select" formControlName="owner">
                                        <option *ngFor="let owner of hardwareOwner" [value]="owner">{{ owner | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('owner').hasError('required') && form.get('owner').touched" class="error-color">{{ 'Owner field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div id="hardware-edit-form-status" class="form-group row">
                                <label class="col-2" for="status">{{ 'Hardware status' | translate }}:</label>
                                <div class="col-10">
                                    <select class="custom-select" formControlName="status">
                                        <option *ngFor="let status of hardwareStatusOptions" [value]="status">{{ status | translate }}</option>
                                    </select>
                                    <div *ngIf="form.get('status').hasError('required') && form.get('status').touched" class="error-color">{{ 'Hardware status field is required' | translate }}!</div>
                                </div>
                            </div>

                            <div class="form-group row justify-content-end">
                                <div class="col-2">
                                    <button id="hardware-edit-form-btn-submit" class="btn btn-sm btn-uppercase btn-brand-01 w-100">
                                        <span *ngIf="!submitted">{{ 'Save' | translate }}</span>

                                        <ng-container *ngIf="submitted">
                                            <div class="load-spinner text-center">
                                                <i class="fa fa-spinner fast-pulse" aria-hidden="true"></i>
                                            </div>
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
            </div>
            <div sidebar>
                <sidebar-with-header>
                    <div header>
                        <div class="item active"></div>
                    </div>
                    <div content></div>
                </sidebar-with-header>
            </div>
        </layout-default>

        <ng-template #removeHardwareConfirmation>
            <div class="modal-header">
                <h4 class="modal-title">{{ 'Confirm action' | translate }}</h4>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="decline()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <p>
                    {{ 'Do you want to remove hardware:' | translate }} <strong>{{ hardware.label }}</strong
                    >?
                </p>
                <button type="button" class="btn btn-brand-01" (click)="confirm()">
                    {{ 'Yes' | translate }}
                </button>
                <button type="button" class="btn btn-default" (click)="decline()">
                    {{ 'Cancel' | translate }}
                </button>
            </div>
        </ng-template>

        <!-- {{'hardwareType.SCANNER.FRISCO' | translate}} -->
        <!-- {{'hardwareType.SCANNER.INPOST' | translate}} -->
        <!-- {{'hardwareType.POS_TERMINAL.FRISCO' | translate}} -->
        <!-- {{'hardwareType.POS_TERMINAL.INPOST' | translate}} -->
        <!-- {{'VEHICLE' | translate}} -->
        <!-- {{'FORKLIFT' | translate}} -->
        <!-- {{'VAN' | translate}} -->
        <!-- {{'OTHER' | translate}} -->
        <!-- {{'UNKNOWN' | translate}} -->
        <!-- {{'ELAVON' | translate }} -->
        <!-- {{'Hardware with this device number already exists' | translate}} -->
    `,
    styleUrls: ['hardware-edit.component.scss']
})
export class HardwareEditComponent implements OnInit, OnDestroy {
    @ViewChild('removeHardwareConfirmation', { static: true }) private removeHardwareConfirmation: TemplateRef<any>;

    private readonly TAG: string = '[HardwareEditComponent]';
    private confirmObservable: Observable<any>;
    private hardwareQueryParams: Subscription;
    private modalRef: BsModalRef;
    public client: string = environment.client;
    public friscoClient: string = Client.FRISCO;
    public hardware: Hardware = new Hardware();
    public hardwareId: number;
    public mode: string = 'list';
    public form: FormGroup;
    public submitted: boolean = false;
    public formErrorsMessages;

    public hardwareOwner$: Observable<string[]>;

    get hardwareTypeOptions() {
        if (environment.client === Client.INPOST ) {
            return HardwareTypeOptions.filter(a => a !== HardwareType.POS_TERMINAL);
        }
        return HardwareTypeOptions;
    }

    get hardwareStatusOptions() {
        return HardwareStatusOptions;
    }

    constructor(
        private readonly hardwareService: HardwareService,
        private readonly navigationRoute: ActivatedRoute,
        private readonly notifierService: NotifierService,
        private readonly translateService: TranslateService,
        private readonly modalService: BsModalService,
        private readonly navService: NavService,
        private readonly formBuilder: FormBuilder,
        public readonly appService: AppService
    ) {}

    public ngOnInit() {

        this.hardwareOwner$ = this.hardwareService.getHardwareOwner();

        this.hardwareQueryParams = this.navigationRoute.params.subscribe(params => {
            this.hardwareId = _.get(params, 'hardwareId', null);

            if (this.hardwareId === null) {
                this.mode = 'create';
            } else {
                this.mode = 'edit';
                this.getHardware(this.hardwareId);
            }
        });

        this.form = this.formBuilder.group({
            id: [null],
            brand: [null, Validators.required],
            deviceNo: [null, Validators.required],
            hardwareType: [null, Validators.required],
            inventoryNo: [null, Validators.required],
            model: [null, Validators.required],
            owner: [null, Validators.required],
            status: [null, Validators.required],
            properties: this.formBuilder.group({
                pin: [null],
                puk: [null]
            }),
            phoneNumber: [null],
            modifiedAt: [null],
            version: [null]
        });
    }

    private getHardware(hardwareId) {
        this.hardwareService.getHardware(hardwareId).subscribe((hardware: Hardware) => {
            this.hardware = hardware;
            this.form.patchValue(hardware);
            console.log(this.TAG, ' model: ', this.form);
        });
    }

    public onSubmit(): void {
        this.submitted = true;
        const rawHardware = plainToClass(Hardware, this.form.getRawValue());

        console.log(`${this.TAG} mode: ${this.mode}`, rawHardware);

        if (this.mode === 'create') {
            this.hardwareService
                .createHardware(rawHardware)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    (hardware: Hardware) => {
                        console.log(this.TAG, 'Creating hardware', hardware);
                        this.notifierService.notify('success', this.translateService.instant('New hardware has been created!'));
                        this.navService.goToPage('/fleet');
                        this.submitted = false;
                    },
                    error => {
                        console.log(`${this.TAG} Creating hardware - error`, error);
                        this.formErrorsMessages = error;
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        } else if (this.mode === 'edit') {
            this.hardwareService
                .updateHardware(rawHardware)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.submitted = false;
                        return observableThrowError(error);
                    })
                )
                .subscribe(
                    (hardware: Hardware) => {
                        console.log(this.TAG, 'Updating hardware', hardware);
                        this.notifierService.notify('success', this.translateService.instant('The hardware has been updated!'));
                        this.navService.goToPage('/fleet');
                        this.submitted = false;
                    },
                    error => {
                        console.log(`${this.TAG} Updating driver - error`, error);
                        this.formErrorsMessages = error;
                        this.submitted = false;
                    },
                    () => {
                        this.submitted = false;
                    }
                );
        }
    }

    public confirm(): void {
        this.confirmObservable.subscribe(() => {
            this.modalRef.hide();
            this.confirmObservable = null;
            this.notifierService.notify('success', this.translateService.instant('The Hardware has been removed!'));
            this.navService.goToPage('/fleet');
        });
    }

    public decline(): void {
        this.confirmObservable = null;
        this.modalRef.hide();
    }

    public ngOnDestroy(): void {
        if (this.hardwareQueryParams) {
            this.hardwareQueryParams.unsubscribe();
        }
    }
}
